import { useFiltersDestroyMutation } from '@/state/api'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AlertModal } from '../alert-modal'
import { addSnack } from '@/eventbus/events/snackbar'

export const DeleteFilter = () => {
  const { templateId } = useParams()
  const navigate = useNavigate()
  const [
    deleteFile,
    {
      isLoading,
      isError: isDeleteFilterError,
      isSuccess: isDeleteFilterSuccess,
    },
  ] = useFiltersDestroyMutation()

  const onClick = () => {
    deleteFile({
      filterId: Number(templateId),
    })
      .unwrap()
      .then(() => navigate('/requests'))
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (isDeleteFilterError) {
      addSnack({
        type: 'error',
        eventName: 'delete-filter-error',
        text: `Не удалось удалить фильтр.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => onClick(),
      })
    }

    if (isDeleteFilterSuccess) {
      addSnack({
        type: 'default',
        eventName: 'delete-filter-success',
        text: `Шаблон удален`,
      })
      navigate('/requests')
    }
  }, [isDeleteFilterError, isDeleteFilterSuccess])

  return (
    <AlertModal
      onClick={onClick}
      isLoading={isLoading}
      title={'Вы хотите удалить выбранный шаблон?'}
      showBackButton={false}
    />
  )
}
