import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { UISizes } from "@/global-types/mods.types";
import { setCurrentCdrId, setCurrentIndex, setCurrentPlaylist, setPlayingState, setPreventPlaying } from "@/state/slice";
import { convertPhone } from "@/utils/convert-phone";
import { formatDuration } from "@/utils/format-duration";
import cn from "classnames";
import { type FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./audio-track-call-recording.module.scss";
import type { IAudioTrackCallRecordingProps } from "./audio-track-call-recording.types";

// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
export const AudioTrackCallRecording: FC<IAudioTrackCallRecordingProps> = ({ data, reqId }): JSX.Element => {
	const dispatch = useDispatch();
	const state = useSelector((state: any) => state.playlist);
	const [toggle, setToggle] = useState(false);
	const [isActiveTrack, setIsActiveTrack] = useState<boolean>(String(state.currentCdrId) === String(data.cdr_id));
	const [isPlaying, setIsPlaying] = useState<boolean>(state.isAudioPlaying && isActiveTrack);
	const [isError, setIsError] = useState<boolean>(false);

	const audioTrackClasses = cn(styles.audio_container, {
		[styles["audio_header-playing"]]: isActiveTrack && !isError,
		[styles["audio_header-error"]]: isError,
	});

	// const getNameOrPhone = (trigger: CallerOrCalled) => {
	// 	if (String(data?.[trigger]?.name) === String(data?.[trigger]?.phone)) {
	// 		return convertPhone(data[trigger]?.phone ?? null);
	// 	}
	// 	return data[trigger]?.name;
	// };

	useEffect(() => {
		setIsActiveTrack(String(state.currentCdrId) === String(data.cdr_id));
		setIsPlaying(state.isAudioPlaying && String(state.currentCdrId) === String(data.cdr_id));
		setIsError(state?.errorList.indexOf(String(data.cdr_id)) !== -1);
	}, [state.isAudioPlaying, state.currentCdrId, toggle, state.errorList.length]);

	return (
		<div aria-track-playing={isPlaying && !isError} className={audioTrackClasses}>
			<div key={data.cdr_id} className={styles.audio_header}>
				<div className={styles["btn-wrapper"]}>
					<Button
						disabled={isError}
						btnType={"button"}
						onClick={() => {
							if (String(state.currentCdrId) !== String(data.cdr_id)) {
								dispatch(setCurrentCdrId(String(data.cdr_id)));
								dispatch(setCurrentPlaylist(reqId));
								dispatch(setCurrentIndex(data.index));
								dispatch(setPlayingState(true));
								dispatch(setPreventPlaying(false));
								setToggle((prev: boolean) => !prev);
							} else if (state.isAudioPlaying) {
								dispatch(setPreventPlaying(false));
								dispatch(setPlayingState(false));
								setToggle((prev: boolean) => !prev);
							} else {
								dispatch(setPreventPlaying(false));
								dispatch(setPlayingState(true));
								setToggle((prev: boolean) => !prev);
							}
						}}
						iconLeft={<i className={`icon icon-${isPlaying && !isError ? "pause" : "play"}`} />}
						type={ButtonTypes[`${isPlaying && !isError ? "primary" : "secondary"}`]}
						size={UISizes.small}
					/>
				</div>
				<div style={{ width: "100%" }}>
					<div className={styles.audio_header_contact}>
						<div className={styles.audio_header_contact_block}>
							<div className={styles.audio_header_subtitle_type}>{data.caller?.type ?? "Неопределён"}:</div>
							<div className={styles.audio_header_subtitle_name}>{data.caller?.name || "Неизвестный"}</div>
							<div className={styles.audio_header_subtitle_name}>
								{data.caller?.phone && data.caller?.phone?.length > 3
									? convertPhone(data.caller?.phone)
									: data.caller?.phone}
							</div>
						</div>
						<div className={styles.audio_header_contact_block}>
							<div className={styles.audio_header_subtitle_type}>{data.called?.type ?? "Неопределён"}:</div>
							<div className={styles.audio_header_subtitle_name}>{data.called?.name || "Неизвестный"}</div>
							<div className={styles.audio_header_subtitle_name}>
								{data.called?.phone && data.called?.phone?.length > 3
									? convertPhone(data.called?.phone)
									: data.called?.phone}
							</div>
						</div>
					</div>
					<div className={styles.audio_header_time_wrapper}>
						<div className={styles.audio_header_date_callrec}>{data.call_time}</div>
						<div className={styles.audio_header_total_time}>{formatDuration(data.duration)}</div>
					</div>
				</div>
			</div>
		</div>
	);
};
