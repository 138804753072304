import { RequestHeaderFilters } from '@components/headers/requests-header/request-header-filters/request-header-filters'
import { ResetPageOnFilterChange } from '@global-types/reset-page.types'

export const RequestHeaderBottom = ({
  isFetching,
  resetPageOnFilterChange,
}: {
  resetPageOnFilterChange?: ({
    resetLocalStoragePage,
  }: ResetPageOnFilterChange) => void
  isFetching?: boolean
}) => {
  return (
    <RequestHeaderFilters
      resetPageOnFilterChange={resetPageOnFilterChange}
      isFetching={isFetching}
    />
  )
}
