import { DeclineCallIconButton } from '../../../../shared/components'
import styles from './decline.module.scss'
import cn from 'classnames'

type DeclineSecondLineCallProps = {
  isDisabled: boolean
  handleDeclineCall: () => void
}

export const DeclineSecondLineCall = ({
  isDisabled,
  handleDeclineCall,
}: DeclineSecondLineCallProps) => {
  const declineSecondLineButtonStyles = cn(styles['decline-second-call-btn'])
  return (
    <DeclineCallIconButton
    className={declineSecondLineButtonStyles}
      handleDeclineCallIcon={handleDeclineCall}
      isDeclineCallIconDisabled={isDisabled}
    />
  )
}
