export const shortFio = (fio?: string | undefined) => {
    if (!fio) return ''
    const preparedFio = fio
      .split(' ')
      .map(
        (word, idx) => `${idx === 0 ? word + ' ' : word ? `${word[0]}.` : ''} `,
      )
      .join('')
  
    return preparedFio
  }