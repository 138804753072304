import { useActor } from "@xstate/react";
import { useEffect } from "react";
import { useFormMachine } from "../../../../app/machines/form-factory-machine/useFormMachine";
import { useGlobalPhonerState } from "../../../../app/providers/xstate-provider";
import { DeclineCallIconButton } from "../../../../shared/components";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";
import { DECLINE_CURRENT_CALL } from "../../../../shared/event-bus/types/event-action.types";
import styles from "./decline.module.scss";

export const DeclineCurrentCall = () => {
	const { fastSearchService } = useGlobalPhonerState();

	const [fastSearchState, fastSearchSendEvents] = useActor(fastSearchService);
	const { endCall, sessions } = usePhoner();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { on } = useEventBus();
	const { sessionsDTO } = useSessionContext();

	const { resetField } = useFormMachine({
		state: fastSearchState,
		send: fastSearchSendEvents,
	});

	const handleDeclineCallIcon = () => {
		const sessionId = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0]?.sessionId || "";

		endCall(sessionId);

		resetField("", "fast-search");
	};

	const currentSessionState = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0];

	const isCurrentSessionOnHold = currentSessionState?.status === SessionStatus.HOLD;

	useEffect(() => {
		const unsubscribeDeclineCurrentCall = on(DECLINE_CURRENT_CALL, ({ sessionId }) => {
			endCall(sessionId, false);
			resetField("", "fast-search");
		});

		return () => unsubscribeDeclineCurrentCall();
	}, [isCurrentTabMasterTab, sessionsDTO, sessions]);

	return (
		<DeclineCallIconButton
			handleDeclineCallIcon={handleDeclineCallIcon}
			isDeclineCallIconDisabled={isCurrentSessionOnHold}
			className={styles["decline-btn"]}
		/>
	);
};
