import { ModalContainerProps } from './modal-container.types'
import styles from './modal-container.module.scss'
import { ModalBlackout } from './modal-blackout'
import { Portal } from '../portal'

export const ModalContainer = ({
  children,
  onClose,
  closeOnBlackoutClick,
  showBlackout,
  className,
}: Partial<ModalContainerProps>) => {
  return (
    <Portal>
      <div className={styles['modal-wrapper']}>
        {showBlackout && (
          <ModalBlackout onClose={closeOnBlackoutClick ? onClose : () => {}} />
        )}
        <div className={`${styles['modal-container']} ${className || ''}`}>
          {children}
        </div>
      </div>
    </Portal>
  )
}
