import { Icon, Panel, Typography } from "../../../shared";

import cn from "clsx";

import type { ColumnDef } from "@tanstack/react-table";
import { useActor } from "@xstate/react";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { nanoid } from "nanoid";
import { type ReactNode, useEffect } from "react";
import React, { useMemo, useState } from "react";
import { useFormMachine } from "../../../app/machines/form-factory-machine/useFormMachine";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { formatPhoneNumber } from "../../../features/fast-search/call/call";
import { type TCallLog, useRecordsHistory } from "../../../shared/api/useRecordsHistory";
import { Table } from "../../../shared/components";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { usePhoner } from "../../../shared/contexts/phoner/phoner";
import { useSharedWorker } from "../../../shared/contexts/shared-worker";
import { SessionStatus, useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";
import { SET_OUTGOING_CALL_INFO, START_SECOND_LINE } from "../../../shared/event-bus/types/event-action.types";
import { Loader } from "../../../shared/ui-kit/loader";
import { convertPhone } from "../../../shared/utils/convert-phone";
import { formatCallDuration } from "../lib/utils";
import styles from "./call-log.module.scss";
import { EmptyPlug } from "./empty-plug";
import { EmptySearchPlug } from "./empty-search-plug";
import { ErrorPlug } from "./error-plug";

type CallLogProps = {
	headerRightSlot?: ReactNode;
	children?: ReactNode;
};

export const CallLog = React.memo(({ headerRightSlot }: CallLogProps) => {
	const { fastSearchService, phonerIncomingCallService, phonerSecondLineService, phonerConnectionService } = useGlobalPhonerState();
	const [state, sendFastSearchEvents] = useActor(fastSearchService);
	const [, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);
	const { onChange } = useFormMachine({ state, send: sendFastSearchEvents });
	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);
	const { setSessionHold, userInternalNumber } = usePhoner();
	const { sendMessage } = useSharedWorker();
	const [searchNumber, setSearchNumber] = useState("");

	const { isCurrentTabMasterTab } = useMasterTab();
	const { phonerMenuService, phonerTreyService } = useGlobalPhonerState();
	const [, send] = useActor(phonerMenuService);
	const [, sendPhonerTreyEvent] = useActor(phonerTreyService);
	const [statePhonerIncomingCall, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);

	const { resetField } = useFormMachine({
		state: state,
		send: sendFastSearchEvents,
	});

	const isWithScroll = false;

	const panelClasses = cn(styles.container, {
		[styles["container-with-scroll"] as string]: isWithScroll,
	});
	const listOfContactsClasses = cn(styles["search-list"], {
		[styles["search-list-with-scroll"] as string]: isWithScroll,
	});

	const { makeCall } = usePhoner();

	const [lastPage, setLastPage] = useState(0);

	const [currentPage, setCurrentPage] = useState<number>(1);
	const { isLoading, data, isFetching, isError, isSuccess, refetch, isRefetching } = useRecordsHistory({ currentPage, searchNumber });
	const [recordsHistory, setRecordsHistory] = useState<TCallLog[] | []>([]);
	const [phonerConnectionState] = useActor(phonerConnectionService);
	const isConnectionEstablished = phonerConnectionState.context.isConnectionEstablished;

	useEffect(() => {
		if (!isLoading && !isFetching && !isError && data && data.data?.length) {
			setRecordsHistory((p) => {
				const incomingData = data?.data ? [...data.data] : [];
				if (p) return [...p, ...incomingData];
				return p;
			});
			setCurrentPage(data?.meta?.current_page || 1);
			setLastPage(data?.meta?.last_page || 1);
		}
	}, [isLoading, isError, isFetching, data]);

	useEffect(() => {
		setRecordsHistory([]);
		setCurrentPage(1);
		setLastPage(1);
	}, [searchNumber]);

	const handleScrollPage = () => {
		if (data && currentPage < lastPage && !isLoading && !isRefetching && !isError) {
			setCurrentPage((p) => {
				return p + 1;
			});
		}
	};

	const handleRetry = () => {
		refetch();
	};

	useEffect(() => {
		const searchValue = state?.context?.data["call-log-list"];

		const handler = debounce(() => {
			setSearchNumber(formatPhoneNumber(searchValue || ""));
		}, 500);

		handler();

		return () => handler.cancel();
	}, [state?.context?.data["call-log-list"]]);

	useEffect(() => {
		resetField("", "call-log-list");

		return () => {
			resetField("", "call-log-list");
		};
	}, []);
	const columns = useMemo<ColumnDef<TCallLog>[]>(
		() => [
			{
				accessorKey: "phone_number",
				header: "Номер",
				cell: (info) => {
					const direction = info.cell.getContext()?.row?.original?.direction;
					const formattedDuration = formatCallDuration(info.cell.getContext()?.row?.original?.duration);
					const preparedHours = `${formattedDuration.hours > 9 ? "" : "0"}${formattedDuration.hours}`;
					const preparedMinutes = `${formattedDuration.minutes > 9 ? "" : "0"}${formattedDuration.minutes}`;
					const preparedSeconds = `${formattedDuration.seconds > 9 ? "" : "0"}${formattedDuration.seconds}`;

					const preparedDurationString = `${preparedHours}:${preparedMinutes}:${preparedSeconds}`;
					const callInfo =
						direction === "OUT" ? info.cell.getContext()?.row?.original?.called : info.cell.getContext()?.row?.original?.caller;

					return (
						<div
							className={cn(styles["call-log-row-cell"], {
								[styles["call-not-available"] as string]:
									sessionsDTO.some(
										(session) => formatPhoneNumber(session.phoneNumber || "") === formatPhoneNumber(callInfo.phone),
									) ||
									formatPhoneNumber(callInfo.phone) === formatPhoneNumber(userInternalNumber || "") ||
									!isReadyToSecondCall,
							})}
						>
							<div
								onClick={() => {
									if (!isConnectionEstablished) return;

									if (!sessionsDTO.length) {
										if (!isCurrentTabMasterTab) {
											sendMessage({
												eventName: SET_OUTGOING_CALL_INFO,
												eventPayload: { phoneNumber: callInfo.phone },
											});
										}
										onChange(callInfo.phone, "fast-search");

										sendPhonerIncomingCallEvents({
											type: "outcoming call",
											callInfo: {
												id: nanoid(),
												phoneNumber: callInfo.phone,
											},
											makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
										});
										resetField("", "fast-search");
									} else {
										if (isCurrentTabMasterTab) {
											setSessionHold();
											setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
											sendMessage({
												eventName: START_SECOND_LINE,
												eventPayload: { phoneNumber: callInfo.phone },
											});
										} else {
											sendMessage({
												eventName: START_SECOND_LINE,
												eventPayload: { phoneNumber: callInfo.phone },
											});
											setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
										}
										onChange(callInfo.phone, "fast-search");

										sendPhonerSecondLineEvents({
											type: "start second line",

											callInfo: {
												id: nanoid(),
												phoneNumber: callInfo.phone,
												currentPhone: statePhonerIncomingCall.context.currentCall?.phoneNumber,
											},
											makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
										});

										resetField("", "fast-search");
									}
									sendPhonerTreyEvent("close menu");
									send("close menu");
								}}
								className={styles["phone-info"]}
							>
								<Icon icon={direction === "OUT" ? "icon-phone-outgoing" : "icon-phone-incoming"} />
								<Typography as={"caption"} decoration={"none"} tag={4} weight={2}>
									{convertPhone(callInfo.phone)}
								</Typography>
							</div>
							<Typography
								className={styles["phone-info__call-duration"]}
								as={"caption"}
								decoration={"none"}
								tag={5}
								weight={2}
							>
								{preparedDurationString}
							</Typography>
						</div>
					);
				},
				size: 190,
			},
			{
				accessorKey: "start_time",
				header: "Начало",
				cell: (info) => {
					const callDate = info.cell.getContext().row.original.call_date
						? dayjs(info.cell.getContext().row.original.call_date, { utc: true }).format("DD.MM.YYYY")
						: "";

					const callTime = info.cell.getContext().row.original.call_date
						? dayjs(info.cell.getContext().row.original.call_date, { utc: true }).format("HH:mm:ss")
						: "";

					return (
						<div className={styles["call-log-row-cell"]}>
							<Typography className={styles["phone-info__start-date"]} as={"caption"} decoration={"none"} tag={4} weight={2}>
								{callDate}
							</Typography>
							<Typography className={styles["phone-info__start-time"]} as={"caption"} decoration={"none"} tag={5} weight={2}>
								{callTime}
							</Typography>
						</div>
					);
				},
				size: 100,
				// size: 150,
			},
		],
		[sessionsDTO, isCurrentTabMasterTab, isReadyToSecondCall, recordsHistory, isConnectionEstablished],
	);

	return (
		<Panel className={panelClasses}>
			<Panel.Header
				withBackButton
				handleClickBackButton={() => {
					send("back");
					resetField("", "call-log-list");
				}}
				title={"Журнал звонков"}
				rightSlot={headerRightSlot}
			/>
			<Panel.Body className={listOfContactsClasses}>
				{(isLoading || isRefetching) && !recordsHistory.length && <Loader />}
				{isError && !isFetching && !isLoading && !recordsHistory.length && <ErrorPlug onRetry={handleRetry} />}
				{recordsHistory.length === 0 && isSuccess && !isFetching && !searchNumber && <EmptyPlug />}
				{recordsHistory.length === 0 && isSuccess && !isFetching && !!searchNumber && <EmptySearchPlug />}
				{recordsHistory.length > 0 && (
					<Table<TCallLog>
						columns={columns}
						// tableRows={fakeCallLogs}
						tableRows={recordsHistory}
						handleScrollPage={handleScrollPage}
					/>
				)}
				{(isLoading || isRefetching) && recordsHistory.length > 0 && (
					<div className={styles.preloader}>
						<Loader size={16} width={1} />
						<Typography as={"caption"} decoration="none" tag={4} weight={600} style={{ color: "var(--phoner-gray-500)" }}>
							Загрузка
						</Typography>
					</div>
				)}
			</Panel.Body>
		</Panel>
	);
});
