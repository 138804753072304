import { ModalContainer, Typography } from '@components/common'
import { ModalBody, ModalHeader } from '@/components/common/modal-container'
import { InstallmentHistoryHeader } from './installment-history-delete-header'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import okkStyles from '../../../okk-comment-modal/okk-components/okk-components.module.scss'
import style from '../../installment.module.scss'

//@ts-ignore
export const InstallmentConfirmDelete = ({ onConfirm, onCancel }) => {
  return (
    <ModalContainer closeOnBlackoutClick showBlackout className={style['installment-modal-confirm']}>
      <ModalHeader
        showRightContent
        showLeftContent
        contentLeft={
          <InstallmentHistoryHeader
            showBackButton={false}
            title={'Удаление платежа'}
            // onHandleBackButton={onHandleBackButton}
          />
        }
        showCloseButton
        onClose={() => onCancel()}
      />
      <ModalBody>
        <Typography as={'paragraph'} tag={3} decoration={'none'} style={{ paddingLeft: '16px' }}>
          Вы уверены, что хотите удалить данный платеж?
        </Typography>
        <div className={okkStyles['okk-confirm-button-container']}>
          <Button type={ButtonTypes.secondaryUncolored} onClick={() => onCancel()}>
            Отменить
          </Button>
          <Button type={ButtonTypes.dangerUncolored} onClick={() => onConfirm()}>
            Удалить
          </Button>
        </div>
      </ModalBody>
    </ModalContainer>
  )
}
