import styles from './input-container.module.scss'
import { InputContainerProps } from './input-container.types'
import cn from 'classnames'

export const InputContainer = ({
  children,
  className = '',
  orientation = 'row',
  width = 'content',
}: InputContainerProps) => {
  const inputContainerClasses = cn(
    styles['input-container'],
    styles[`orientation-${orientation}`],
    styles[`width-${width}`],
    className,
  )

  return <div className={inputContainerClasses}>{children}</div>
}
