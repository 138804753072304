import { useActor } from "@xstate/react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { FastSearchCallButton } from "../../../features/fast-search/call";

export const GetRightSlot = () => {
  const { phonerMenuService} = useGlobalPhonerState();
  const [stateMenu] = useActor(phonerMenuService);
  
  if (!stateMenu.matches("menu opened.call logs"))
    return <FastSearchCallButton />;
  return null;
};
