import { useActor } from "@xstate/react";
import cn from "classnames";
import { useEffect } from "react";
import { useFormMachine } from "../../../../app/machines/form-factory-machine/useFormMachine";
import { useGlobalPhonerState } from "../../../../app/providers/xstate-provider";
import { ReferCallToAutoInformerButton } from "../../../../shared/components/refer-call-to-autoinformer-button";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";
import { TRANSFER_TO_AUTOINFORMER } from "../../../../shared/event-bus/types/event-action.types";
import styles from "./refer-current-call-to-autoinformer.module.scss";

export const ReferCurrentCallToAutoInformer = () => {
	const { phonerIncomingCallService, fastSearchService, phonerSecondLineService } = useGlobalPhonerState();
	const [fastSearchState, fastSearchSendEvents] = useActor(fastSearchService);
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const showSecondLine = !statePhonerSecondLine.matches("idle") && !statePhonerSecondLine.matches("second call received");
	const showCurrentCall = statePhonerIncomingCall.matches("current call");
	const isCurrentCall = showCurrentCall && !showSecondLine;
	const { on } = useEventBus();

	const { resetField } = useFormMachine({
		state: fastSearchState,
		send: fastSearchSendEvents,
	});

	const { referToAutoInformer } = usePhoner();
	const { sessionsDTO } = useSessionContext();
	const currentSessionState = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0];
	const isCallStarted = currentSessionState?.isConnected;

	const isCurrentSessionOnHold = currentSessionState?.status === SessionStatus.HOLD;

	const referToAutoInformerButtonStyles = cn({
		[styles["current-call"] as string]: isCurrentCall,
	});

	useEffect(() => {
		const unsubscribeTransferToAutoInformer = on(TRANSFER_TO_AUTOINFORMER, ({ sessionId }) => {
			referToAutoInformer(sessionId, false);
			resetField("", "fast-search");
		});

		return () => unsubscribeTransferToAutoInformer();
	}, [sessionsDTO]);

	const referCurrentCallToAutoInformer = () => {
		const sessionId = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0]?.sessionId || "";

		referToAutoInformer(sessionId);
		resetField("", "fast-search");
	};

	return (
		<ReferCallToAutoInformerButton
			onClick={referCurrentCallToAutoInformer}
			disabled={!isCallStarted || isCurrentSessionOnHold}
			className={referToAutoInformerButtonStyles}
		/>
	);
};
