import styles from './add-call-modal.module.scss'
import { ModalContainer, Typography } from '@components/common'
import { ModalBody, ModalHeader } from '@common/modal-container'
import { ModalProps } from '@/global-types/modal.types'
import { AddCallBody } from './add-call-body'
import { useNavigate } from 'react-router'
import { Form } from 'react-final-form'
import { Col, Row } from 'react-grid-system'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import btnStyles from './add-call-body/add-call-body.module.scss'
import { AddCallFormType } from './add-call-form.types'
import { AddCallModalLeftContent } from './left-content'
import { useCallsStoreMutation } from '@/state/api'
import { AddCallNames } from '@constants/add-call'
import { ContentLoader } from '@/components'
import { REQUESTS } from '@/constants/routes'
import { useEffect, useState } from 'react'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'
import { CreateRequestModalFormTypes } from '@components/modals/create-request-modal/create-request-modal.types'

export const AddCallModal = ({
  title,
  subtitle,
  onClosePath,
  onClose,
  closeOnBlackoutClick,
}: Partial<ModalProps>) => {
  const navigate = useNavigate()

  const [addCall, { isLoading, isError, isSuccess }] = useCallsStoreMutation()
  const [lastSubmittedState, setLastSubmittedState] = useState<any>()

  const handleSubmit = (values: any) => {
    clearSnacks()
    setLastSubmittedState(values)

    addCall({
      requestsCallsStore: {
        [AddCallNames.comment]: values[AddCallNames.comment],
        [AddCallNames.denyReasons]: values[AddCallNames.denyReasons],
      },
    })

    // .unwrap()
    // .then(() => {
    //   navigate(-1)
    // })
  }

  const filters = localStorage.getItem('filters')
    ? JSON.parse(String(localStorage.getItem('filters')))
    : null

  useEffect(() => {
    if (isSuccess) {
      addSnack({
        type: 'default',
        eventName: 'add-call-success',
        text: 'Звонок успешно добавлен',
        withAction: false,
      })
      navigate({
        pathname: REQUESTS,
        search: new URLSearchParams(filters).toString(),
      })
    }

    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'add-call-error',
        text: 'Не удалось добавить звонок.',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleSubmit(lastSubmittedState),
      })
    }
  }, [isError, isSuccess])

  const defaultRowStyle = {
    margin: '-16px 0 0 0',
    padding: 0,
    flexFlow: 'row nowrap',
    width: '100%',
  }

  const defaultColStyle = {
    padding: 0,
    flex: 1,
  }


  return (
    <>
      <ModalContainer
        onClose={
          onClose
            ? onClose
            : onClosePath
            ? () => {
                navigate({
                  pathname: REQUESTS,
                  search: new URLSearchParams(filters).toString(),
                })
                clearSnacks()
              }
            : () => navigate('../')
        }
        showBlackout
        className={styles['add-call-modal']}
        closeOnBlackoutClick={closeOnBlackoutClick}
      >
        <ModalHeader
          showLeftContent
          contentLeft={
            <AddCallModalLeftContent title={title} subtitle={subtitle} />
          }
          showCloseButton
          onClose={
            onClose
              ? onClose
              : onClosePath
              ? () => {
                  navigate({
                    pathname: REQUESTS,
                    search: new URLSearchParams(filters).toString(),
                  })
                  clearSnacks()
                }
              : () => navigate('/requests')
          }
        />
        <Form onSubmit={handleSubmit}>
          {({ form }) => (
            <ModalBody className={styles['add-call-modal-body']}>
              {!isLoading && <AddCallBody />}
              {!isLoading && (
                <>
                  <Row
                    style={defaultRowStyle}
                    align={'center'}
                    justify={'between'}
                    gutterWidth={16}
                  >
                    <Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
                      <Button
                        btnType={'submit'}
                        type={ButtonTypes.primary}
                        onClick={() => {
                          form.submit()
                        }}
                        isLoading={isLoading}
                        disabled={!form.getState().valid || isLoading}
                        className={btnStyles.add_call_body__btn}
                      >
                        Добавить звонок
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    style={defaultRowStyle}
                    align={'center'}
                    justify={'between'}
                    gutterWidth={16}
                  >
                    {/*{isError && (*/}
                    {/*  <Col style={defaultColStyle} xs={12} xl={12} xxl={12}>*/}
                    {/*    <Typography*/}
                    {/*      as={'paragraph'}*/}
                    {/*      decoration={'none'}*/}
                    {/*      tag={3}*/}
                    {/*      className={styles['error-text']}*/}
                    {/*    >*/}
                    {/*      Не удалось добавить звонок: сервер не отвечает или*/}
                    {/*      временно не доступен, попробуйте еще раз.*/}
                    {/*    </Typography>*/}
                    {/*  </Col>*/}
                    {/*)}*/}
                  </Row>
                </>
              )}

              {isLoading && <ContentLoader />}
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*    <pre>*/}
              {/*      {*/}
              {/*        // @ts-ignore*/}
              {/*        JSON.stringify(form.getState().values, 0, 2)*/}
              {/*      }*/}
              {/*    </pre>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </ModalBody>
          )}
        </Form>
      </ModalContainer>
    </>
  )
}
