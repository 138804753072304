export const convertPhone = (phoneNumberString: string | null) => {
  if (phoneNumberString === null) return ''
  // const cleaned = ('' + phoneNumberString).replace(/\D*/g, '')
  // const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{4})$/)
  // Все валидации номера телефона делаются на беке!
  if (phoneNumberString !== null) {
    const clearPhone = phoneNumberString?.includes('+') ? phoneNumberString?.substring(1) : phoneNumberString
    const intlCode = clearPhone?.slice(0, 1) ? '+7 ' : ''

    return `${[intlCode, '(', clearPhone?.slice(1, 4), ') ', clearPhone?.slice(4, 7), '-', clearPhone?.slice(-4)]?.join('')} `
  }
  return null
}

export const clearPhoneForRequest = (phone: string) => (phone?.includes('+') ? phone?.substring(1) : phone)
