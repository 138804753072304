import { createSlice } from "@reduxjs/toolkit";
import { coreApi } from "../api/coreApi";
import { userInitialState } from "./initial-state";

const userSlice = createSlice({
  initialState: { ...userInitialState },
  name: "user",
  reducers: {
    logout: () => userInitialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      coreApi.endpoints.profile.matchFulfilled,
      (state, { payload }) => {
        state.profile = { ...payload.data };
      }
    );
  },
});

export const { reducer: userReducer } = userSlice;
export const { logout } = userSlice.actions;
