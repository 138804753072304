import { PanelBodyProps, PanelHeaderProps, PanelProps } from './panel.types'
import cn from 'clsx'
import { Icon } from '../icon'
import { Button } from '../button'
import { Title } from '../title'

import styles from './panel.module.scss'

export const Panel = ({ children, className, mRef, ...props }: PanelProps) => {
  const panelClassNames = cn(styles.container, className)

  return (
    <div ref={mRef} className={panelClassNames} {...props}>
      {children}
    </div>
  )
}

Panel.Header = ({
  title,
  withBackButton,
  className,
  rightSlot,
  handleClickBackButton = () => {},
  isBackButtonDisabled,
  ...props
}: PanelHeaderProps) => {
  
  return (
    <div className={cn(styles.header, className)} {...props}>
      {withBackButton && (
        <Button
          disabled={isBackButtonDisabled}
          onClick={handleClickBackButton}
          size="m"
          variant="secondary-uncolored"
          width="icon"
          renderIcon={<Icon icon={'icon-arrow-left'} />}
        />
      )}
      <div className={styles.header_content}>
        {title && (
          <div className={styles['header_title-block']}>
            <Title className={styles.header_title} as={'h5'}>
              {title}
            </Title>
          </div>
        )}
        {rightSlot && (
          <div className={styles['header_action-content']}>{rightSlot}</div>
        )}
      </div>
    </div>
  )
}

Panel.Body = ({ className, children, mRef, ...props }: PanelBodyProps) => {
  return (
    <div ref={mRef} className={cn(styles.body, className)} {...props}>
      {children}
    </div>
  )
}


