type GenerateDateRegexpType = {
  separator?: '.' | '/'
}

const anyNumber = /[0-9]/
const firstD = /[0-3]/
const firstM = /[0-1]/
const firstY = /[1-2]/
const firstH = /[0-2]/
const firstMin = /[0-5]/

export const generateDateRegexp = ({
  separator = '/',
}: GenerateDateRegexpType) => {
  return [
    firstD,
    anyNumber,
    separator,
    firstM,
    anyNumber,
    separator,
    firstY,
    anyNumber,
    anyNumber,
    anyNumber,
  ]
}

export const generateDateRangeRegexp = ({
  separator = '/',
}: GenerateDateRegexpType) => {
  return [
    ...generateDateRegexp({ separator: '.' }),
    ' ',
    firstH,
    anyNumber,
    ':',
    firstMin,
    anyNumber,
    ' ',
    '-',
    ' ',
    ...generateDateRegexp({ separator: '.' }),
    ' ',
    firstH,
    anyNumber,
    ':',
    firstMin,
    anyNumber,
  ]
}

export const generateDateString = () => {
   return [
     ...generateDateRegexp({ separator: '.' }),
     ' ',
     firstH,
     anyNumber,
     ':',
     firstMin,
     anyNumber,
   ]
}

export const generateDateDdMmYyyy = () => {
  return [
    ...generateDateRegexp({ separator: '.' }),
    ' ',
    firstH,
    anyNumber,
  ]
}
