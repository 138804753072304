import { Timer } from '@/components'
import { Snackbar } from '../../snackbar'
import styles from '../../snackbar.module.scss'
import React from 'react'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@/global-types/mods.types'
import { SnackbarDefaultType } from '@components/snackbar/snackbar.types'
import { Button } from '@components/button'
import { Typography } from '@components/common'

export const SnackbarDefault = ({
  maxTime,
  isMobile,
  withAction,
  timerSize = 'm',
  timerTime = 5000,
  text = '',
  handleClick,
  actionButtonText,
}: SnackbarDefaultType) => {
  return (
    <Snackbar type={'default'} isMobile={isMobile} withAction={withAction}>
      <div className={styles['snackbar-content']}>
        <div className={styles['snackbar-action-container']}>
          {withAction && (
            <Timer
              maxTime={maxTime}
              trackWidth={2}
              trackColor={'transparent'}
              indicatorWidth={2}
              indicatorColor={'#0F9CFA'}
              indicatorCap={'round'}
              size={timerSize}
              timerTime={timerTime}
            />
          )}
          <Typography as={'paragraph'} decoration={'none'} tag={4}>
            {text}
          </Typography>
        </div>
        {withAction && (
          <Button
            onClick={() => handleClick && handleClick()}
            size={UISizes.medium}
            btnType={'button'}
            type={ButtonTypes.primary}
          >
            {actionButtonText}
          </Button>
        )}
      </div>
    </Snackbar>
  )
}
