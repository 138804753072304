import { useEffect } from "react";
import { MicrophoneIconButton } from "../../../../shared/components";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { useSharedWorker } from "../../../../shared/contexts/shared-worker";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";
import { SET_MUTE_MICROPHONE, SET_UNMUTE_MICROPHONE } from "../../../../shared/event-bus/types/event-action.types";

export const ToggleMicrophoneButton = () => {
	const { sessions, muteMicro, unmuteMicro } = usePhoner();
	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { sendMessage } = useSharedWorker();
	const { on } = useEventBus();
	const currentContextSession = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0];

	const handleToggleMicrophone = () => {
		if (isCurrentTabMasterTab) {
			if (sessions?.filter((session) => session.id === currentContextSession?.sessionId)?.[0]?.isMuted().audio) {
				unmuteMicro(currentContextSession?.sessionId || "");
				sendMessage({ eventName: SET_UNMUTE_MICROPHONE, eventPayload: { sessionId: currentContextSession?.sessionId } });
			} else {
				muteMicro(currentContextSession?.sessionId || "");
				sendMessage({ eventName: SET_MUTE_MICROPHONE, eventPayload: { sessionId: currentContextSession?.sessionId } });
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId === currentContextSession?.sessionId
						? { ...session, isMuted: !currentContextSession?.isMuted }
						: session,
				),
			);

			return;
		}

		if (currentContextSession?.isMuted) {
			sendMessage({ eventName: SET_UNMUTE_MICROPHONE, eventPayload: { sessionId: currentContextSession.sessionId } });

			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId === currentContextSession.sessionId ? { ...currentContextSession, isMuted: false } : session,
				),
			);
		} else {
			sendMessage({ eventName: SET_MUTE_MICROPHONE, eventPayload: { sessionId: currentContextSession?.sessionId } });

			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId === currentContextSession?.sessionId ? { ...currentContextSession, isMuted: true } : session,
				),
			);
		}
	};

	useEffect(() => {
		const unsubscribeMuteMicrophone = on(SET_MUTE_MICROPHONE, ({ sessionId }) => {
			if (isCurrentTabMasterTab) {
				muteMicro(sessionId);

				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: true } : session)),
				);

				return;
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: true } : session)),
			);
		});

		const unsubscribeUnMuteMicrophone = on(SET_UNMUTE_MICROPHONE, ({ sessionId }) => {
			if (isCurrentTabMasterTab) {
				unmuteMicro(sessionId);

				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: true } : session)),
				);
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: false } : session)),
			);
		});

		return () => {
			unsubscribeMuteMicrophone();
			unsubscribeUnMuteMicrophone();
		};
	}, [isCurrentTabMasterTab, sessions, sessionsDTO]);

	return (
		<MicrophoneIconButton
			isDisabled={!!(currentContextSession?.status === SessionStatus.HOLD || !currentContextSession?.isConnected)}
			isMuted={currentContextSession?.isMuted || currentContextSession?.status === SessionStatus.HOLD}
			handleMicrophone={handleToggleMicrophone}
		/>
	);
};
