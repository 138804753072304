import type { FC } from 'react'
import type { LoadingSpinnerProps } from './loading-spinner.types'
import Spinner from './loading-spinner.svg?react'
import './loading-spinner.scss'
import classnames from 'classnames'

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className }) => {
  return (
    <div className={classnames('spinner-container', className)}>
      <Spinner />
    </div>
  )
}
