import React, { FC } from 'react'
import { LayoutTypes } from '@/layout/layout.types'
import styles from './layout.module.scss'
import { Navigation } from '@/layout/navigation/navigation'
import { RequestsHeader } from '@components/headers/requests-header/requests-header'

export const Layout: FC<LayoutTypes> = ({ children }) => {
  return (
    <div className={styles.layout_wrapper}>
      <div className={styles.layout_sidebar}>
        <Navigation />
      </div>
      <div className={styles.layout_content}>{children}</div>
    </div>
  )
}
