import { Button, Icon } from "../../../index";
import type React from "react";

type AcceptCallButtonProps = {
	handleAcceptCall: () => void;
	isAcceptCallButtonDisabled: boolean;
};
export const AcceptCallButton = ({ handleAcceptCall, isAcceptCallButtonDisabled }: AcceptCallButtonProps) => {
	return (
		<Button
			disabled={isAcceptCallButtonDisabled}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				handleAcceptCall();
			}}
			size="m"
			variant="success"
			width="icon"
			renderIcon={<Icon icon={"icon-phone"} />}
		/>
	);
};
