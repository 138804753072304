import { createMachine } from 'xstate'

let volumeSettingsLS = localStorage.getItem('settings')

const initAudioVolumeSettings = volumeSettingsLS ? parseInt(JSON.parse(volumeSettingsLS)?.audioVolume, 10) : 50

const initMicrophoneVolumeSettings = volumeSettingsLS ? parseInt(JSON.parse(volumeSettingsLS)?.microphoneVolume, 10) : 50

const handleLocalStorageSettings = (value: number, field: string) => {
  const settings = volumeSettingsLS ? JSON.parse(volumeSettingsLS) : { audioVolume: 50, microphoneVolume: 50 }

  localStorage.setItem('settings', JSON.stringify({ ...settings, [field]: value }))

  volumeSettingsLS = localStorage.getItem('settings')
}

export const phonerSettingsMachine = createMachine(
  {
    id: 'phoner settings',
    initial: 'settings',
    context: {
      isChanging: false,
      microphoneVolume: initMicrophoneVolumeSettings,
      audioVolume: initAudioVolumeSettings,
    },
    states: {
      settings: {
        on: {
          'change audio': {
            target: 'audio changed',
            actions: [
              {
                type: 'setChangingSettings',
                params: {},
              },
              {
                type: 'setAudioSettings',
                params: {},
              },
            ],
          },
          'change mic': {
            target: 'mic changed',
            actions: [
              {
                type: 'setChangingSettings',
                params: {},
              },
              {
                type: 'setMicSettings',
                params: {},
              },
            ],
          },
        },
      },
      'audio changed': {
        after: {
          //зачем задержка
          '0': [
            {
              target: '#phoner settings.settings',
              // cond: 'isNotChanging',
              actions: [],
            },
            {
              internal: false,
            },
          ],
        },
        // on: {
        //   'finish changing': {
        //     actions: {
        //       type: 'setChangingSettings',
        //       params: {},
        //     },
        //     internal: true,
        //   },
        // },
      },
      'mic changed': {
        after: {
          '10': [
            {
              target: '#phoner settings.settings',
              cond: 'isNotChanging',
              actions: [],
            },
            {
              internal: false,
            },
          ],
        },
        on: {
          'finish changing': {
            actions: {
              type: 'setChangingSettings',
              params: {},
            },
            internal: true,
          },
        },
      },
    },
    schema: {
      events: {} as { type: 'change audio'; value: number } | { type: 'change mic'; value: number } | { type: 'finish changing' },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    //tsTypes: {},
  },
  {
    actions: {
      setChangingSettings: (context, event) => {
        if (event.type === 'change mic' || event.type === 'change audio') {
          return (context.isChanging = true)
        }

        return (context.isChanging = false)
      },

      setMicSettings: (context, event) => {
        if (event.type === 'change mic') {
          context.microphoneVolume = event.value
          handleLocalStorageSettings(event?.value, 'microphoneVolume')
          return
        }
      },

      setAudioSettings: (context, event) => {
        if (event.type === 'change audio') {
          context.audioVolume = event.value
          handleLocalStorageSettings(event?.value, 'audioVolume')
          return
        }
      },
    },
    services: {},
    guards: {
      isNotChanging: (context, event) => {
        return !context.isChanging
      },
    },
    delays: {},
  },
)
