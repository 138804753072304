import styles from './request-comments.module.scss'
import { ModalContainer } from '@components/common'
import { ModalBody, ModalHeader } from '@common/modal-container'
import type { RequestCommentsModalProps } from '@components/modals/request-comments/request-comments.types'
import { RequestCommentsModalLeftContent } from '@components/modals/request-comments/left-content'
import { Route, useNavigate } from 'react-router-dom'
import { RequestComments } from '@/pages/request-page/log-card/left-content/comments'
import {COMMENT_ID, REQUESTS} from '@constants/routes'
import { DeleteComment } from '@components/modals'
import { Routes } from 'react-router'
import { clearSnacks } from '@/eventbus/events/snackbar'

export const RequestCommentsModal = ({
  onClose,
  title,
  subtitle,
  onClosePath,
  closeOnBlackoutClick,
}: Partial<RequestCommentsModalProps>) => {
  const navigate = useNavigate()
  const filters = localStorage.getItem('filters')
    ? JSON.parse(String(localStorage.getItem('filters')))
    : null

  return (
    <>
      <ModalContainer
        className={styles['request-comments-modal']}
        onClose={
          onClose
            ? onClose
            : onClosePath
            ? () => {
                navigate({
                  pathname: REQUESTS,
                  search: new URLSearchParams(filters).toString(),
                })
                clearSnacks()
              }
            : () => navigate('/')
        }
        showBlackout
        closeOnBlackoutClick={closeOnBlackoutClick}
      >
        <ModalHeader
          showLeftContent
          contentLeft={
            <RequestCommentsModalLeftContent
              title={title}
              subtitle={subtitle}
            />
          }
          showCloseButton
          onClose={
            onClose
              ? onClose
              : onClosePath
              ? () => {
                  navigate({
                    pathname: REQUESTS,
                    search: new URLSearchParams(filters).toString(),
                  })
                  clearSnacks()
                }
              : () => navigate('/')
          }
        />
        <ModalBody className={styles['request-comments-modal-body']}>
          <RequestComments
            className={styles['request-comments-fixed-height']}
          />
        </ModalBody>
      </ModalContainer>
      <Routes>
        <Route path={COMMENT_ID} element={<DeleteComment />} />
      </Routes>
    </>
  )
}
