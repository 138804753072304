import styles from './header-profile.module.scss'
import { Dropdown } from '@components/dropdown'
import { useState } from 'react'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { ProfileApiResponse, useProfileLogoutMutation, useUsersFeedQuery } from '@/state/api'
import { useNavigate } from 'react-router'
import { REQEST_DISPETCHER_CARD_BY_ID } from '@/constants/routes'

export const HeaderProfile = ({ data }: ProfileApiResponse) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [logout, { isLoading: logoutLoading }] = useProfileLogoutMutation()
  const navigate = useNavigate()

  const sendLogoutEvent = () => {
    const event = new CustomEvent('logout')
    window.dispatchEvent(event)
  }

  const trigger = (
    <div className={styles.profile_trigger}>
      <div className={styles.profile_trigger_image} />
      <div className={styles.profile_trigger_text}>{data?.title || ''}</div>
      {data?.inner_phone && <div className={styles.profile_trigger_text}>({data?.inner_phone})</div>}
      {!isVisible && <i className={'icon icon-cheveron-down'}></i>}
      {isVisible && <i className={'icon icon-cheveron-up'}></i>}
    </div>
  )

  const content = (
    <div>
      <Button
        type={ButtonTypes.secondaryUncolored}
        fullWidth={true}
        contentPosition={'left'}
        iconLeft={<i className={'icon-tiny icon-bar-chart'} />}
        onClick={() => data && navigate(REQEST_DISPETCHER_CARD_BY_ID(String(data.id)))}>
        Личная статистика
      </Button>
      <Button
        type={ButtonTypes.dangerUncolored}
        fullWidth={true}
        isLoading={logoutLoading}
        contentPosition={'left'}
        onClick={() => {
          logout()
          sendLogoutEvent()
        }}
        iconLeft={<i className={'icon-tiny icon-log-out'} />}>
        Выйти из аккаунта
      </Button>
    </div>
  )

  return (
    <Dropdown
      offsetCorrection={[-20, 0]}
      isVisible={isVisible}
      trigger={trigger}
      body={content}
      onClose={() => setIsVisible(false)}
      onOpen={() => setIsVisible(true)}
    />
  )
}
