import type { WindowSize } from "./hooks.types";

import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState<WindowSize>({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const rect = entries[0]?.contentRect;
      setSize({ width: rect?.width || 0, height: rect?.height || 0 });
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return size;
};
