export enum FilterNames {
	id = "id",
	page = "page",
	codeNumber = "codeNumber",
	phone = "phone",
	template = "template",
	dateRangeType = "dateRangeType",
	dateStart = "dateFrom",
	dateEnd = "dateTo",
	ads = "advertising",
	dispatcher = "dispatcher",
	partner = "partner",
	request = "partnerType",
	responsible = "responsible",
	status = "state",
	type = "type",
	typeOfWork = "work",
	worker = "master",
	workerSetter = "announced",
	contract = "contractType",
	paymentStatus = "cashlessState",
	cost = "cost",
	district = "district",
	street = "street",
	housing = "korp",
	house = "house",
	apartment = "flat",
	entrance = "entrance",
	beznal = "beznal",
	beznalOnline = "beznalOnline",
	beznalByAccount = "beznalByAccount",
	organisation = "organization",
	withFiles = "hasAttachment",
	withComments = "hasComments",
	fastFilters = "fastFilters",
	withWorkRecords = "hasOrderExecutions",
	onControl = "isControlledDispatcher",
	notSaved = "isTemp",
	duplicate = "isDublicate",
	is_installment = "is_installment",
	isCredit = "isCredit",
	period = "period",
	isEntity = "isLegalEntity",
	notGivenWorker = "notTransferredToMaster",
	hasNoMaster = "hasNoMaster",
	beznalNum = "beznal_num",
	perPage = "perPage",
	installments_state = "installments_state",
	inn = "ur_inn",
	announced = "announced",
	isDublicate = "isDublicate",
	hasOrderExecutions = "hasOrderExecutions",
	isControlledDispatcher = "isControlledDispatcher",
	isTemp = "isTemp",
	creditStake = "creditStake",
}
