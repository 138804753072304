import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import { ResourcesRequestMasterShow } from '@/state/api'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { useField } from 'react-final-form'
import { CreateRequestNames } from '@constants/create-request'
import { useNavigate } from 'react-router-dom'
import { MASTER_SELECTION } from '@constants/routes'

export const RequestModalMasterSelectionButton = <T extends ResourcesRequestMasterShow[] | undefined>({
  input,
  meta,
  title = 'Подобрать мастера',
  label,
  labelSide = 'left',
  inputChildren,
  requestId,
  modalView,
  ...props
}: RequestModalTextInputProps<T>) => {
  const districtField = useField(CreateRequestNames.districtId).input.value
  const workField = useField(CreateRequestNames.typeOfWork).input.value
  const autoSelectionField = useField(CreateRequestNames.autoSelection).input.value
  const statusField = useField(CreateRequestNames.status).input.value

  const navigate = useNavigate()
  const isDisabledOnEditModalView = !districtField || !workField || props.disabled || statusField === 1

  return (
    <>
      <Button
        btnType={'button'}
        disabled={modalView === 'edit' ? isDisabledOnEditModalView : isDisabledOnEditModalView || autoSelectionField}
        type={ButtonTypes.primary}
        size={UISizes.medium}
        onClick={() =>
          navigate(MASTER_SELECTION, {
            state: {
              work: workField,
              district: districtField,
            },
          })
        }>
        {title}
      </Button>
    </>
  )
}
