import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styles from './collapse.module.scss'
import { Icon } from '../../../../shared'
import cn from 'clsx'
import { useGlobalPhonerState } from '../../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import { useFormMachine } from '../../../../app/machines/form-factory-machine/useFormMachine'
import { IconsType } from '../../../../shared/types/icons-types'

type CollapseProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {iconType?:keyof IconsType}
export const Collapse = ({ className, iconType, ...props }: CollapseProps) => {
  const collapseClasses = cn(styles.container, className)
  const { phonerTreyService } = useGlobalPhonerState()
  const [, sendPhonerTreyEvent] = useActor(phonerTreyService)

  const handleCollapse = () => {
    sendPhonerTreyEvent('collapse')
  }

  return (
    <div onClick={handleCollapse} {...props} className={collapseClasses}>
      <Icon icon={iconType} />
    </div>
  )
}
