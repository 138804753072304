import { MONTH_MAX_INDEX } from "@/constants/date";
import { InputErrorContainer } from "@common/input-error-container";
import { InputDefault } from "@common/multi-select";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { getInputState } from "@components/form/utils/getFieldState";
import {
	dateRangesByFastFilter,
	endTimeDefault,
	formatDateRangeString,
	getDate,
	getTime,
	isContainsMaskDashes,
	isValidDate,
	startTimeDefault,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import { FilterPeriodDropdownMenu } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/dropdown-menu";
import type { FilterPeriodProps } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/filter-period.types";
import { UISizes } from "@global-types/mods.types";
import { StatusRequestTypes } from "@global-types/status-request.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useField, useFormState } from "react-final-form";

export const formatPeriodString = (value: string) => {
	const splittedPeriodFieldString = value?.split("-").flatMap((date) => date.trim().split(" "));

	if (
		isValidDate(0, splittedPeriodFieldString) &&
		isValidDate(2, splittedPeriodFieldString) &&
		!isContainsMaskDashes(splittedPeriodFieldString)
	) {
		return {
			start: {
				date: getDate(0, splittedPeriodFieldString),
				time: getTime(1, splittedPeriodFieldString),
			},
			end: {
				date: getDate(2, splittedPeriodFieldString),
				time: getTime(3, splittedPeriodFieldString),
			},
		};
	}

	return {
		start: {
			date: null,
			time: "",
		},
		end: {
			date: null,
			time: "",
		},
	};
};

function formatDate(dateValue: Date | null): string {
	const date = dateValue ? dateValue : new Date();

	return `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()} ${date
		.getHours()
		.toString()
		.padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
}

export function containsNumber(str: string | null) {
	if (!str) return null;

	return /\d/.test(str);
}

export function normalizeHyphens(str: string) {
	const hyphenRegex = /[-−–—]/g;
	const replacedStr = str.replace(hyphenRegex, "-");

	return replacedStr;
}

const parseTime = (time: string) => {
	if (!time) return [0, 0];
	const [hours, minutes] = time.split(":").map((part) => Number.parseInt(part, 10));

	return [hours, minutes];
};

export const validateDate = (dateStr?: string) => {
	if (!dateStr) return null;

	const [day, month, year, time] = dateStr.split(".").flatMap((chunk, index, arr) => {
		return index === arr.length - 1 ? chunk.split(" ") : chunk;
	});

	if (!day || !month || !year || !time) return null;

	if (day?.includes("_") || month?.includes("_") || year?.includes("_") || time?.includes("_")) return null;
	let validatedDay = Number.parseInt(day, 10);
	let validatedMonth = Number.parseInt(month, 10);
	const validatedYear = Number.parseInt(year, 10);
	let validatedTime = time;
	const daysInMonth = new Date(validatedYear, validatedMonth, 0).getDate();
	const daysInLastMonth = new Date(validatedYear, MONTH_MAX_INDEX, 0).getDate();

	if (validatedMonth > MONTH_MAX_INDEX) {
		validatedMonth = MONTH_MAX_INDEX;
		validatedDay = daysInLastMonth;
	}

	if (validatedDay > daysInMonth) {
		validatedDay = daysInMonth;
	}

	if (time) {
		const [hours, minutes] = time.split(":");
		validatedTime = `${hours}:${minutes}`;
	}

	return new Date(validatedYear, validatedMonth - 1, validatedDay, ...parseTime(validatedTime));
};

const validateFilterPeriod = (inputValue: string) => {
	if (!inputValue || !containsNumber(inputValue)) return null;

	const delimiter = /\s+-\s+|–|—/;
	const [startDateStr, endDateStr] = inputValue.split(delimiter);
	const formattedStartDate = validateDate(startDateStr);
	const formattedEndDate = validateDate(endDateStr);

	return {
		formattedStartDate,
		formattedEndDate,
	};
};
const formatPeriodToString = (startDate: Date, endDate: Date) => {
	if (!startDate && !endDate) return null;
	return startDate && endDate && `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const FilterPeriod = <T,>({ input, meta, label, disabled, ...props }: FilterPeriodProps<T>) => {
	const dateFromField = useField("dateFrom");
	const dateToField = useField("dateTo");
	const formState = useFormState();
	const [isDropdownMenuOpened, setDropdownMenuOpen] = useState<boolean>(false);
	const [hoveredTooltip, setHoveredTooltip] = useState<string>("");
	const [startValidatedDate, setStartValidatedDate] = useState<Date>();
	const [endValidatedDate, setEndValidatedDate] = useState<Date>();

	const handleSetHoveredTooltip = (val: string) => {
		setHoveredTooltip(val);
	};

	useEffect(() => {
		if (
			formState.values.fastFilters &&
			!(
				(formState.values.state && formState.values.state[0] === StatusRequestTypes.ClientWaiting) ||
				formState.values.hasNoMaster ||
				formState.values.notTransferredToMaster
			)
		) {
			const dateRangeStringByFastFilter =
				dateRangesByFastFilter[formState.values.fastFilters as keyof typeof dateRangesByFastFilter] ??
				formatDateRangeString({
					startDate: new Date(),
					startTime: startTimeDefault,
					endDate: new Date(),
					endTime: endTimeDefault,
				});
			props.onChange(dateRangeStringByFastFilter);
		}
	}, [formState.values.fastFilters]);

	useEffect(() => {
		if (getInputState(meta, input) === "active") {
			setDropdownMenuOpen(true);
		}
	}, [getInputState(meta, input)]);

	const handleResetDates = () => {
		props.onChange(undefined);
		dateFromField.input.onChange(undefined);
		dateToField.input.onChange(undefined);
		setDropdownMenuOpen(false);
		setStartValidatedDate(undefined);
		setEndValidatedDate(undefined);
	};

	const handleSetStartDate = useCallback(
		(date: Date | null) => {
			const curDate = input.value as string;
			const curEndDate = normalizeHyphens(curDate).split("-")[1];
			const curStartDate = formatDate(date);
			const maskDatePalceholder = "__.__.____ __:__";
			const dateRange = `${curStartDate?.trim()} - ${curEndDate ? curEndDate?.trim() : maskDatePalceholder}`;
			props.onChange(dateRange);
			input.onChange(dateRange);
		},
		[input.value],
	);

	const handleSetEndDate = useCallback(
		(date: Date | null) => {
			const curDate = input.value as string;
			const curStartDate = normalizeHyphens(curDate).split("-")[0];
			const curEndDate = formatDate(date);
			const maskDatePalceholder = "__.__.____ __:__";
			const dateRange = `${curStartDate ? curStartDate?.trim() : maskDatePalceholder} - ${curEndDate?.trim()}`;
			props.onChange(dateRange);
			input.onChange(dateRange);
		},
		[input.value],
	);

	const handleCloseDropDownMenu = useCallback(() => setDropdownMenuOpen(false), []);

	const formattedPeriod = useMemo(() => {
		return validateFilterPeriod(input.value as string);
	}, [input.value]);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;
		if (!formattedPeriod) return;

		if (formattedPeriod.formattedStartDate) {
			setStartValidatedDate(formattedPeriod.formattedStartDate);
		}

		if (formattedPeriod.formattedEndDate) {
			setEndValidatedDate(formattedPeriod.formattedEndDate);
		}

		if (formattedPeriod.formattedStartDate && formattedPeriod.formattedEndDate) {
			const startDate = formattedPeriod.formattedStartDate;
			const endDate = formattedPeriod.formattedEndDate;
			const validatedInputString = formatPeriodToString(startDate, endDate);

			timer = setTimeout(() => {
				input.onChange(validatedInputString);
			}, 0);
		}

		return () => clearInterval(timer);
	}, [formattedPeriod]);

	return (
		<InputErrorContainer className={"react-datepicker-ignore-onclickoutside"} anchorId={hoveredTooltip} errorMessage={meta.error}>
			<InputDefault
				disabled={disabled}
				inputProps={input}
				width={"full-w"}
				id={input.name}
				type={"text"}
				autoComplete="off"
				withRightButton={true}
				labelText={`${label}: `}
				state={getInputState(meta, input)}
				handleShowError={handleSetHoveredTooltip}
				{...props}
				renderDropdownMenu={() => (
					<FilterPeriodDropdownMenu
						startDate={startValidatedDate || new Date()}
						endDate={endValidatedDate || new Date()}
						isOpen={isDropdownMenuOpened}
						handleSetEndDate={handleSetEndDate}
						handleSetStartDate={handleSetStartDate}
						handleCloseDropDownMenu={handleCloseDropDownMenu}
					/>
				)}
				renderRightButton={() => (
					<Button
						btnType={"button"}
						disabled={disabled}
						type={ButtonTypes.secondaryUncolored}
						size={UISizes.medium}
						iconLeft={<i className={"icon icon-close"} />}
						onClick={() => handleResetDates()}
					/>
				)}
				{...meta}
			/>
		</InputErrorContainer>
	);
};
