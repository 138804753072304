import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'
import React, { useState } from 'react'
import { WatchPrevRequesdtModal } from './watch-prev-request'
import { WatchPrevReuqstModalButtonProps } from './watch-prev-reuqest.types'
import cn from 'classnames'
import styles from './watch-prev-request.module.scss'

export const WatchPrevReuqstModalButton = ({
  fullcode,
  requestId,
  isRequestInBlackList = false,
}: WatchPrevReuqstModalButtonProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const prevRequestClasses = cn({
    [styles['btn-interactive']]: !isRequestInBlackList,
    [styles['btn-blacklist']]: isRequestInBlackList,
  })

  return (
    <>
      <Button
        className={prevRequestClasses}
        btnType={'button'}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        onClick={() => setOpen(true)}
      >
        #{fullcode}
      </Button>
      {open && (
        <WatchPrevRequesdtModal
          requestId={requestId}
          onClose={() => setOpen(false)}
          closeOnBlackoutClick
          fullcode={fullcode}
          showBackButton
        />
      )}
    </>
  )
}
