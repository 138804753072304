import { Title } from "../../../ui-kit/title";
import styles from "./incoming-call-name.module.scss";
interface IncomingCallNameProps {
	name?: string;
}
export const IncomingCallName = ({ name }: IncomingCallNameProps) => {
	return (
		<Title className={styles["incoming-call-name"]} as={"h3"}>
			{name ?? "Неизвестный клиент"}
		</Title>
	);
};
