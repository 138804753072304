export const MAKE_CALL_EVENT_NAME = "make_call"

export type MakeCallEventT = {
  entityType: "request"
  app: "basa"
  entityId: number;
  callerPhone: string;
  callerId: number;
  calledId: number;
  calledPhone: string;
  callerType: "user";
  calledType: "client" | "master";
}