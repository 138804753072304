export const createRequestValidation = (values: Record<string, any>) => {
	const errors: Record<string, any> = {};
	if (values.typeId === undefined || values.typeId === null) {
		errors.typeId = "Поле не заполнено";
	}
	if (!values.dispatcher.id) {
		errors.dispatcherId = "Поле не заполнено";
	}
	if (!values.typeOfWork) {
		errors.typeOfWork = "Поле не заполнено";
	}
	if (!values.ads) {
		errors.ads = "Поле не заполнено";
	}

	if (values.status === 21 && !values.waitUntil) {
		errors.waitUntil = "Поле не заполнено";
	}

	if (!values.phone) {
		errors.phone = "Поле не заполнено";
	}
	if (!values.districtId) {
		errors.districtId = "Поле не заполнено";
	}
	if (!values.street) {
		errors.street = "Поле не заполнено";
	}
	if (!values.isLegalEntity && !values.clientFio) {
		errors.clientFio = "Поле не заполнено";
	}
	if (values.isLegalEntity && !values.clientFio) {
		values.clientFio = undefined;
	}
	if (values.isLegalEntity && !values.organization) {
		errors.organization = "Поле не заполнено";
	}
	if (values.isLegalEntity && !values.legalEntityClientFio) {
		errors.legalEntityClientFio = "Поле не заполнено";
	}

	return errors;
};
