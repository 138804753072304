import type { ChangeEvent, ClipboardEvent, FC, MutableRefObject } from "react";
import { Input } from "../../../../shared";
import type { InputStateType } from "../../../../shared/ui-kit/input/input.types";
type InputFieldProps = {
	inputValue: string;
	inputState: InputStateType;
	handleChange: (inputValue: string) => void;
	handleInputState: (state: InputStateType) => void;
	onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
	mask: string;
	placeholder?: string;
	errorMessage?: string;
	maxLength?: number;
	inputRef?: MutableRefObject<any>;
	id: string;
};

export const InputFieldMask: FC<InputFieldProps> = ({
	mask = "",
	inputValue,
	inputState,
	handleChange,
	handleInputState,
	onPaste,
	placeholder,
	errorMessage,
	inputRef,
	maxLength = 999,
	id,
}) => {
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value ? handleChange(e.target.value) : handleChange("");
	};
	const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value ? handleInputState("filled") : handleInputState("default");
	};

	const onFocus = () => {
		handleInputState("active");
	};

	const onReset = () => {
		handleChange("");
		handleInputState("default");
	};

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		onPaste?.(e);
	};

	return (
		<Input
			mask={mask}
			maxLength={maxLength}
			inputRef={inputRef}
			errorMessageTooltipOffset={[0, 25]}
			placeholder={placeholder}
			value={inputValue}
			state={inputState}
			errorMessage={errorMessage}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			onReset={onReset}
			onPaste={handlePaste}
			width={"full-w"}
			id={id}
			labelText={""}
			type={"text"}
			withRightButton={false}
			withResetButton
		/>
	);
};
