import React, { forwardRef, useState } from 'react'
import styles from './calendar-header.module.scss'
import { CalendarHeaderProps } from '@components/calendar/header/calendar-header.types'
import { CalendarDropdown } from '@components/calendar/header/header-dropdown'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import { range } from 'lodash'
import { getMonth, getYear } from 'date-fns'

export const CalendarHeader = forwardRef(
  (
    {
      decreaseMonth,
      increaseMonth,
      date,
      changeYear,
      changeMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      selected,
    }: CalendarHeaderProps,
    ref,
  ) => {
    const years = range(2000, getYear(new Date()) + 1, 1).sort((a: number, b: number) => b - a)
    const [selectedMonth, setsSelectedMonth] = React.useState<number>(new Date(selected || new Date()).getMonth())
    const [selectedYear, setsSelectedYear] = React.useState<number>(new Date(selected || new Date()).getFullYear())

    React.useEffect(() => {
      if (!selected) return
      const newSelectedMonth = new Date(selected).getMonth()

      if (selectedMonth !== newSelectedMonth) {
        changeMonth(newSelectedMonth)
        setsSelectedMonth(newSelectedMonth)
      }
    }, [selected, selectedMonth])

    React.useEffect(() => {
      if (!selected) return
      const newSelectedYear = new Date(selected).getFullYear()

      if (newSelectedYear !== selectedYear) {
        changeYear(newSelectedYear)
        setsSelectedYear(newSelectedYear)
      }
    }, [selected])

    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    return (
      <div className={styles['calendar-header']}>
        <div className={styles['calendar-header__months-controls-container']}>
          <CalendarDropdown onHandleChange={changeMonth} buttonText={`${months[getMonth(date)]}`} optionsType={'months'} options={months} />
          <CalendarDropdown buttonText={`${getYear(date)}`} onHandleChange={changeYear} optionsType={'years'} options={years} />
        </div>
        <div className={styles['calendar-header__arrows-controls-container']}>
          <Button
            disabled={prevMonthButtonDisabled}
            isActive={false}
            btnType={'button'}
            type={ButtonTypes.secondaryUncolored}
            size={UISizes.medium}
            iconRight={<i className={'icon icon-cheveron-left'} />}
            onClick={() => decreaseMonth()}
          />
          <Button
            disabled={nextMonthButtonDisabled}
            isActive={false}
            btnType={'button'}
            type={ButtonTypes.secondaryUncolored}
            size={UISizes.medium}
            iconRight={<i className={'icon icon-cheveron-right'} />}
            onClick={() => increaseMonth()}
          />
        </div>
      </div>
    )
  },
)
