import { SearchIconButton } from "../../../../../shared/components";
import { useGlobalPhonerState } from "../../../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";

export const SearchCurrentCallButton = () => {
	const { phonerIncomingCallService, phonerSecondLineService } = useGlobalPhonerState();
	const [, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);
	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const hasIncomingCallAtCurrent = statePhonerSecondLine.matches("second call received");

	const handleClickSearchIcon = () => {
		sendPhonerIncomingCallEvents("filter by phone");
	};

	return <SearchIconButton handleSearchIcon={handleClickSearchIcon} isSearchIconDisabled={hasIncomingCallAtCurrent} />;
};
