import { MessageDateDividerProps } from '@common/chat/message-date-divider/message-date-divider.types'
import { Typography } from '@components/common'
import styles from './message-date-divider.module.scss'

export const MessageDateDivider = ({
  date,
}: Partial<MessageDateDividerProps>) => {
  return (
    <div className={styles['message-date-divider']}>
      <Typography
        className={styles['message-date-divider_date']}
        as={'caption'}
        decoration={'none'}
        tag={4}
        weight={1}>
        {date}
      </Typography>
    </div>
  )
}
