import cn from 'clsx'
import styles from './input-range.module.scss'
import { useEffect, useState } from 'react'

type InputRangeProps = {
  className?: string
  onValueChange: (width: number) => void
  value: number
  minValue?: number
  maxValue?: number
  step?: number
}

export const InputRange = ({
  className = '',
  onValueChange,
  value,
  minValue = 0,
  maxValue = 100,
  step = 1,
}: InputRangeProps) => {
  const inputRangeClasses = cn(styles.container, className)

  const [rangeBackgroundStyleInput, setRangeBackgroundStyleInput] =
    useState<number>(value)

  useEffect(() => {
    const inputRange = (value - minValue) / (maxValue - minValue)
    setRangeBackgroundStyleInput(inputRange * 100)
  }, [value])

  return (
    <input
      className={inputRangeClasses}
      style={{
        borderRadius: '4px',
        background: `linear-gradient(to right, var(--phoner-gray-50) 0%, var(--phoner-gray-50) ${rangeBackgroundStyleInput}%, var(--phoner-gray-800) ${rangeBackgroundStyleInput}%, var(--phoner-gray-800) 100%)`,
      }}
      type="range"
      onChange={(e) => {
        onValueChange(Number(e.target.value))
        e.target.blur()
      }}
      min={minValue}
      max={maxValue}
      step={step}
      value={value}
    />
  )
}
