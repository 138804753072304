import type { FormFieldConfigValidationResult, FormMachineFactoryParams } from '../form-factory-machine/form-factory-machine'
import { checkIsCorrectInputNumber } from '../../../shared/utils/check-string-start-width-digits'

const phoneRelatedFieldConfig = (fieldName: string) => {
  return {
    field: fieldName,
    required: true,
    validator: (value: string): FormFieldConfigValidationResult => {
      return {
        result: checkIsCorrectInputNumber(value),
        errorMessage: '',
      }
    },
    parser: (value: string) => {
      return value
    },
  }
}

export const formConfig: FormMachineFactoryParams = {
  predictableActionArguments: true,
  fields: [phoneRelatedFieldConfig('fast-search')],
  onSubmit: (context) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(context.data['fast-search']), 1000)
    })
  },
  onDone: () => undefined,
}
