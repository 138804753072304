import { Typography } from "@components/common";
import type { RequestTableProps } from "./requests-statistic.types";
import { Title } from "@components/common/title";
import style from "./requests.module.scss";
import { getMonthNames } from "@/utils/get-month-names";
import cn from "clsx";

const titlesMap = {
	reject: "Отклонено",
	diag: "Диагностика",
	done: "Выполнено",
	worked: "В работе",
	total: "Всего",
} as const;

export const RequestsTable = ({ statistic }: RequestTableProps) => {
	const checkStatistic = statistic?.length;
	const currentMonth = new Date().getMonth() + 1;

	return (
		<>
			{checkStatistic ? (
				<div className={style["statistic-table"]}>
					<div className={style.title}>
						<Typography style={{ display: "flex", flex: "0.5" }} as={"paragraph"} decoration={"none"} tag={4} weight={2}>
							Месяц
						</Typography>
						<div className={style["title-list"]}>
							{Object.entries(titlesMap).map(([key, value]) => {
								return (
									<Typography key={key} as={"paragraph"} decoration={"none"} tag={4} weight={2}>
										{value}
									</Typography>
								);
							})}
						</div>
					</div>

					<div className={style.months}>
						<div className={style["title-list-container"]}>
							{statistic?.map((item) => {
								return (
									<div
										key={`${item.month.toString()}.${item.year.toString()}`}
										className={cn(style.row, {
											[style.current]: currentMonth === item.month,
										})}
									>
										<Typography
											style={{ display: "flex", flex: "0.5" }}
											key={item.month}
											as={"paragraph"}
											decoration={"none"}
											tag={3}
											weight={2}
										>
											{getMonthNames(item.month - 1)}
										</Typography>
										<div className={style["title-list"]}>
											<Typography as={"paragraph"} decoration={"none"} tag={3} weight={2}>
												{item.stats.reject}
											</Typography>
											<Typography as={"paragraph"} decoration={"none"} tag={3} weight={2}>
												{item.stats.diag}
											</Typography>
											<Typography as={"paragraph"} decoration={"none"} tag={3} weight={2}>
												{item.stats.done}
											</Typography>
											<Typography as={"paragraph"} decoration={"none"} tag={3} weight={2}>
												{item.stats.worked}
											</Typography>
											<Title as={"h4"} weight={1}>
												{item.stats.total}
											</Title>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			) : (
				<Typography as={"paragraph"} decoration={"none"} tag={3} weight={2}>
					Данные отсутствуют
				</Typography>
			)}
		</>
	);
};
