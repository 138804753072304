import { PhoneActionsDropdown } from "@/components";
import { clearReklamaAndObjectText, clearText } from "@/utils/clear-text";
import { newWindowOpen } from "@/utils/new-window";
import { RequestCardContentItem } from "@common/request-card";
import { Title } from "@common/title";
import { Typography } from "@components/common";
import styles from "@components/request-card/request-card.module.scss";
import type { RequestCardRightContentProps } from "@components/request-card/request-card.types";
import { OLD_CMS_URL } from "@constants/env";
import { RequestInfoBlock } from "@constants/request";

export const RequestCardRightContent = ({ isLegalEntity = false, card }: Partial<RequestCardRightContentProps>) => {
	const phones = [card?.phone, card?.phone2, card?.phone3];

	return (
		<>
			<Title as={"h5"}>О клиенте</Title>
			<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
				{isLegalEntity ? (
					<>
						{card?.ur_org !== null && (
							<>
								<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
									{RequestInfoBlock.organization}
								</Typography>
								<Typography
									className={styles["text-value-interactive"]}
									as={"caption"}
									weight={2}
									decoration={"none"}
									tag={4}
									onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/companies/detail/${card?.ur_id}`)}
								>
									{card?.ur_org}
								</Typography>
							</>
						)}
					</>
				) : (
					<>
						{card?.client_fio !== null && (
							<>
								<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
									{RequestInfoBlock.client}
								</Typography>
								<Typography
									className={styles["text-value-interactive"]}
									as={"caption"}
									weight={2}
									decoration={"none"}
									tag={4}
									onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/contacts/detail/${card?.client_id}`)}
								>
									{card?.client_fio}
								</Typography>
							</>
						)}
					</>
				)}
			</RequestCardContentItem>
			{isLegalEntity && (
				<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
					<>
						<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
							{RequestInfoBlock.inn}
						</Typography>
						<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
							{card?.ur_inn ?? "—"}
						</Typography>
					</>
				</RequestCardContentItem>
			)}
			{isLegalEntity && card?.ur_fio !== null && (
				<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.contactPerson}
					</Typography>
					<Typography
						className={styles["text-value-interactive"]}
						as={"caption"}
						weight={2}
						decoration={"none"}
						tag={4}
						onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/contacts/detail/${card?.client_id}`)}
					>
						{card?.ur_fio}
					</Typography>
				</RequestCardContentItem>
			)}
			{phones.length && (
				<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.phones}
					</Typography>
					<div className={styles["request-card-phones-container"]}>
						<PhoneActionsDropdown
							clientId={card?.client_id || 0}
							fullcodeId={card?.fullcode}
							requestId={Number(card?.id)}
							phones={phones}
						/>
					</div>
				</RequestCardContentItem>
			)}
			{card?.full_address !== null && (
				<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.address}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} decoration={"none"} weight={1} tag={4}>
						{clearText(card?.full_address)}
					</Typography>
				</RequestCardContentItem>
			)}
			{card?.reklama_name !== null && (
				<RequestCardContentItem className={`${styles["request-card_content__item-right"]}`}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.advertising}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={1} decoration={"none"} tag={4}>
						{clearReklamaAndObjectText(card?.reklama_name?.split("-").join("->"))}
					</Typography>
				</RequestCardContentItem>
			)}
		</>
	);
};
