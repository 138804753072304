import { useState } from "react";
import { ContentDivider, Title, Typography } from "@components/common";
import { TableMasterCard } from "./master-card-tables";
import type { MasterCardContentPropsType } from "./master-card-content.types";
import { DistrictsList } from "./districts-list";
import { extractTextArray } from "@utils/extract-text-array";
import { splitDate } from "@/utils/split-date";
import styles from "./master-card-content.module.scss";

export const MasterCardContent = ({ title, info, column, additionalColumn, isDefaultOpen = true }: MasterCardContentPropsType) => {
	const [isOpened, setIsOpened] = useState<boolean>(isDefaultOpen);

	return (
		<div className={styles["title-dropdown"]}>
			{title.id === 1 && (
				<div className={styles["title-dropdown_button"]} onClick={() => setIsOpened((prev) => !prev)}>
					<Title as={"h5"}>{title.name}</Title>
					<i className={isOpened ? "icon icon-cheveron-up" : "icon icon-cheveron-down"} />
				</div>
			)}
			{title.id === 2 && (!!info?.districts?.length || !!info?.comment || !!info?.comment2) && (
				<div className={styles["title-dropdown_button"]} onClick={() => setIsOpened((prev) => !prev)}>
					<Title as={"h5"}>{title.name}</Title>
					<i className={isOpened ? "icon icon-cheveron-up" : "icon icon-cheveron-down"} />
				</div>
			)}
			{title.id === 3 && (
				<div className={styles["title-dropdown_title"]}>
					<Title as={"h5"}>{title.name}</Title>
				</div>
			)}
			{title.id === 4 && (
				<div className={styles["title-dropdown_title"]}>
					<Title as={"h5"}>{title.name}</Title>
					<Typography className={styles["text-value-title"]} as={"paragraph"} decoration={"none"} tag={5}>
						{"(За последние 30 дней)"}
					</Typography>
				</div>
			)}
			{title.id === 5 && (
				<div className={styles["title-dropdown_button"]} onClick={() => setIsOpened((prev) => !prev)}>
					<Title as={"h5"}>{title.name}</Title>
					<Typography className={styles["text-value-title"]} as={"paragraph"} decoration={"none"} tag={5}>
						{"(За последние 6 месяцев)"}
					</Typography>
					<i className={isOpened ? "icon icon-cheveron-up" : "icon icon-cheveron-down"} />
				</div>
			)}

			{title.id === 1 && info && isOpened && (
				<div className={styles["master-card-table-content-container"]}>
					<div className={styles["master-card-table-content-container-content"]}>
						<TableMasterCard columns={column} info={info.works} />
					</div>
				</div>
			)}
			{title.id === 2 && info?.districts && (!!info?.districts?.length || !!info?.comment || !!info?.comment2) && isOpened && (
				<div className={styles["master-card-table-content-container"]}>
					<div className={styles["master-card-table-content-container-request_wrap_district"]}>
						<DistrictsList items={info.districts} />
						{(info.comment || info.comment2) && (
							<>
								<ContentDivider type={"district"} />
								<div className={styles["wrap-title"]}>
									<Title as={"h6"}>{"Примечания"}</Title>
									<div className={styles["wrap-title-comment"]}>
										{info.comment && (
											<Typography
												className={styles["list-item"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{info.comment}
											</Typography>
										)}
										{info.comment2 && (
											<Typography
												className={styles["list-item"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{info.comment2}
											</Typography>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{title.id === 3 && info && (
				<div className={styles["master-card-table-content-container"]}>
					<div className={styles["master-card-table-content-container-content"]}>
						<div className={styles["master-card-table-content-container-request_wrap_request"]}>
							<div className={styles["header-block"]}>
								<div className={styles["header-block-left"]}>
									<Title as={"h6"}>{"Лимиты по заявкам"}</Title>
									<div className={styles["header-block-left_inside"]}>
										<div className={styles.block}>
											<Typography
												className={styles["text-value-title"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{extractTextArray(info?.master_limit?.day_limit)[0]}
												{":"}
											</Typography>
											<Typography as={"caption"} decoration={"none"} weight={1} tag={4}>
												{extractTextArray(info?.master_limit?.day_limit)[1]}
											</Typography>
										</div>

										<div className={styles.block}>
											<Typography
												className={styles["text-value-title"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{extractTextArray(info?.master_limit?.total_limit)[0]}
												{":"}
											</Typography>
											<Typography as={"caption"} decoration={"none"} weight={1} tag={4}>
												{extractTextArray(info?.master_limit?.total_limit)[1]}
											</Typography>
										</div>
									</div>
								</div>
								<div className={styles["header-block-right"]}>
									<Title as={"h6"}>{"Отчеты по заявкам"}</Title>
									<div className={styles["header-block-right_inside"]}>
										<div className={styles.block}>
											<Typography
												className={styles["text-value-title"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{"Последний отчет:"}
											</Typography>
											<Typography as={"caption"} decoration={"none"} weight={1} tag={4}>
												{splitDate(info.last_report_date)[0]}
											</Typography>
										</div>

										<div className={styles.block}>
											<Typography
												className={styles["text-value-title"]}
												as={"caption"}
												decoration={"none"}
												weight={2}
												tag={4}
											>
												{"Следующий отчет:"}
											</Typography>
											<Typography as={"caption"} decoration={"none"} weight={1} tag={4}>
												{splitDate(info.next_report_date)[0]}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
						<TableMasterCard columns={column} info={info.requests_states} />
						<div className={styles["master-card-table-content-container-content_wrap"]}>
							<Title as={"h6"}>{"Заявок на сегодня"}</Title>
							<TableMasterCard columns={additionalColumn} info={info.districts_states_today} />
						</div>
					</div>
				</div>
			)}

			{title.id === 4 && info && info.last_month_complaints && info.last_month_complaints.length > 0 && (
				<div className={styles["master-card-table-content-container"]}>
					<div className={styles["master-card-table-content-container-content"]}>
						<TableMasterCard columns={column} info={info?.last_month_complaints} />
					</div>
				</div>
			)}
			{title.id === 5 && info && isOpened && (
				<div className={styles["master-card-table-content-container"]}>
					<div className={styles["master-card-table-content-container-content"]}>
						<TableMasterCard columns={column} info={info?.last_half_year_conditions} />
					</div>
				</div>
			)}
		</div>
	);
};
