import { TwoColumnsListTypeProps } from './districts-list.type'
import { DistrictBlock } from './district-block'
import styles from './districts-list.module.scss'

export const DistrictsList = ({ items }: TwoColumnsListTypeProps) => {
  return (
    <div className={styles['list-container']}>
      {items?.map((item) => {
        return <DistrictBlock child={item} />
      })}
    </div>
  )
}
