export const convertPhone = (phoneNumberString: string | null) => {
  if (!phoneNumberString) return ''

  if (phoneNumberString.length <= 4) return phoneNumberString

  const phoneRegex = /^(?:\+?[1-9]\d{0,13}|7\(\d{3}\)\d{7})$/

  if (!phoneRegex.test(phoneNumberString)) return phoneNumberString

  let clearPhone = phoneNumberString.replace(/\s/g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
  clearPhone = clearPhone.includes('+') ? clearPhone.substring(1) : clearPhone

  const intlCode = clearPhone.slice(0, 1) === '7' || clearPhone.slice(0, 1) === '8' || clearPhone.slice(0, 2) !== '+7' ? '+7 ' : ''

  if (clearPhone.slice(0, 1) === '8') {
    clearPhone = `7${clearPhone.slice(1)}`
  }

  return (
    `${[
      intlCode,
      '(',
      clearPhone.slice(1, 4),
      ') ',
      clearPhone.slice(4, 7),
      '-',
      clearPhone.slice(7, 9),
      clearPhone.slice(9, 11),
      clearPhone.slice(11, 13),
    ].join('')} `
  ).trim()
}

export const clearPhoneForRequest = (phone: string) => (phone.includes('+') ? phone.substring(1) : phone)

export const parsePhoneFromExternalSource = (phone: string) => {
  if (phone.length === 10) {
    return `7${phone}`
  }

  return phone
}
