import styles from './request-info-header-top.module.scss'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { HeaderCallSwitcher } from '@components/headers/requests-header/header-call-switcher/header-call-switcher'
import { HeaderProfile } from '@common/header/header-profile/header-profile'
import { Title } from '@common/title'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { DUPLICATE, EDIT, OKK_EMPLOYEE, REQUESTS } from '@constants/routes'
import { FC, useEffect } from 'react'
import { RequestResource, useProfileQuery, useRequestsDuplicationForInstallmentMutation } from '@/state/api'

import QualityPopup from '@components/request-card/quality-popup/quality-popup'
import { useComponentVisible } from '@/hooks'
import { Tooltip } from '@common/tooltip'
import { nanoid } from '@reduxjs/toolkit'
import cn from 'classnames'
import { DuplicateActions } from '@/components/duplicate-actions'
import { addSnack } from '@/eventbus/events/snackbar'

export const RequestInfoHeaderTop: FC<{ info: RequestResource }> = ({ info }): JSX.Element => {
  const navigate = useNavigate()
  const { data: profile } = useProfileQuery()
  const filters = localStorage.getItem('filters') ? JSON.parse(String(localStorage.getItem('filters'))) : null

  const [ref, qualityPopupOpen, setQualityPopupOpen] = useComponentVisible<HTMLDivElement>(false)

  const [
    duplicateRequestForInstallment,
    {
      isLoading: isDuplicateRequestForInstallmentLoading,
      isError: isDuplicateRequestForInstallmentError,
      isSuccess: isDuplicateRequestForInstallmentSuccess,
    },
  ] = useRequestsDuplicationForInstallmentMutation()

  const handleDuplicateForInstallment = () => {
    duplicateRequestForInstallment({ requestId: info.id ?? 0 })
  }

  useEffect(() => {
    if (isDuplicateRequestForInstallmentError) {
      addSnack({
        type: 'error',
        eventName: 'duplicate-request-error',
        text: `Не удалось дублировать заявку для рассрочки ${info.fullcode ? `#${info.fullcode}` : ''}`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleDuplicateForInstallment(),
      })
    }
  }, [isDuplicateRequestForInstallmentError])

  const isRequestInBlackList = info?.type_id === 2

  const fullCodeTitleClasses = cn(styles.content_title_main, {
    [styles['content_title_main--black-list']]: isRequestInBlackList,
  })

  return (
    <div className={styles.content}>
      <div className={styles.content_left_block}>
        <Button
          type={ButtonTypes.secondary}
          onClick={() =>
            filters
              ? navigate({
                  pathname: REQUESTS,
                  search: new URLSearchParams(filters).toString(),
                })
              : navigate(REQUESTS)
          }
          iconLeft={<i className={'icon-arrow-left'} />}>
          Назад
        </Button>
        <div className={styles.content_title__wrapper}>
          <Title as={'h3'} weight={1} className={styles.content_title_sub}>
            Заявка
          </Title>
          <Title as={'h3'} weight={2} className={fullCodeTitleClasses}>
            #{info.fullcode}
          </Title>
        </div>
        <div className={styles.content_buttons__wrapper}>
          <Tooltip
            show
            anchorId={'request-page-edit'}
            key={nanoid()}
            text={'Редактировать заявку'}
            tooltipOffset={[0, 15]}
            placement={'bottom'}>
            <Button
              disabled={!info.abilities?.update}
              iconLeft={<i className={'icon-edit-1'} />}
              onClick={() => navigate(EDIT)}
              type={ButtonTypes.primaryUncolored}
            />
          </Tooltip>
          <DuplicateActions
            triggerButtonType={ButtonTypes.primaryUncolored}
            canDuplicate={info.abilities?.dublication}
            onDuplicate={() => navigate(DUPLICATE)}
            onDuplicateForInstallment={() => handleDuplicateForInstallment()}
            canDuplicateForInstallment={info?.abilities?.installment?.duplicate_for_installment}
          />
          <div className={styles['content_buttons__wrapper-quality-block']}>
            <Tooltip
              show={!qualityPopupOpen}
              anchorId={'request-page-okk'}
              key={nanoid()}
              text={'Комментарии ОКК'}
              tooltipOffset={[0, 15]}
              placement={'bottom'}>
              <Button
                //disabled={!info.quality_controls}
                iconLeft={<i className={'icon-chat-quality'} />}
                onClick={() => setQualityPopupOpen((prev) => !prev)}
                type={ButtonTypes.primaryUncolored}
              />
            </Tooltip>
            {qualityPopupOpen && info && <QualityPopup isRequestPage className={styles['quality-popup']} card={info} ref={ref} />}
          </div>
        </div>
      </div>
      <div className={styles.content_right_block}>
        <HeaderCallSwitcher />
        <HeaderProfile data={profile?.data} />
      </div>
    </div>
  )
}
