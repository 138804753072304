import { DefaultSelectDataScheme } from '@common/select/select.types'

export const ViolationLabels = {
  category: 'Категория',
  comment: 'Комментарий',
  summ: 'Сумма',
  isRepaid: 'Погашен',
} as const

export const ViolationNames = {
  category: 'category',
  comment: 'comment',
  summ: 'summ',
  isRepaid: 'isRepaid',
} as const

export const violationOptions: DefaultSelectDataScheme[] = [
  {
    id: 0,
    title: `Нарушение`,
    data: 0,
  },
  {
    id: 1,
    title: `Штраф`,
    data: 1,
  },
  {
    id: 2,
    title: `Благодарность`,
    data: 2,
  },
  {
    id: 3,
    title: `Бонус`,
    data: 3,
  },
]
