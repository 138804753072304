import { RequestCardHeaderInfoItemProps } from '@common/request-card/request-card.types'
import styles from '../request-card.module.scss'
import cn from 'classnames'

export const RequestCardHeaderInfoItem = ({
  children,
  className,
}: RequestCardHeaderInfoItemProps) => {
  const infoItemClasses = cn(
    styles['request-card_header__info-item'],
    styles['request-card_header__info-item130'],
    className,
  )

  return <div className={infoItemClasses}>{children}</div>
}
