import { Typography } from "@/components/common";
import styles from "./textfield.module.scss";
import type { TextAreaProps } from "./textfield.types";

const Textfield = ({ value, onChange, label }: TextAreaProps) => {
	return (
		<label className={styles["textfield-wrapper"]}>
			{label && (
				<Typography className={styles["textfield-label"]} as={"caption"} decoration={"none"} tag={4} weight={2}>
					{label}
				</Typography>
			)}
			<textarea className={styles["textfield-input"]} value={value} onChange={(e) => onChange(e)} />
		</label>
	);
};

export default Textfield;
