export const userInitialState: UserInitialStateType = {
	profile: null,
};

interface UserInitialStateType {
	profile: {
		id?: number;
		title?: string;
		location_id?: number;
		inner_phone?: string;
		sip?: {
			sip?: number;
			password?: string;
		};
		permissions?: {
			CAN_REQUEST_VIEW_ALL_REQUESTS?: boolean;
			CAN_REQUEST_ADD?: boolean;
			CAN_REQUEST_EDIT?: boolean;
			CAN_REQUEST_CHANGE_ADVERT?: boolean;
			CAN_REQUEST_EDIT_FLAT?: boolean;
			CAN_REQUEST_SELECT_ANY_MASTER?: boolean;
			CAN_REQUEST_COMPANY_ADD_INN?: boolean;
			CAN_OKK?: boolean;
			CAN_PREMIUM_DISPATCHER?: boolean;
			CAN_PREMIUM_MASTER?: boolean;
			CAN_ADD_BONUS?: boolean;
		};
		available_menu?: {
			request?: boolean;
			master?: boolean;
			contacts?: boolean;
			statistic?: boolean;
			fin?: boolean;
			report?: boolean;
			constructor?: boolean;
			settings?: boolean;
			call?: boolean;
			repair?: boolean;
			test?: boolean;
			dotest?: boolean;
			bonus?: boolean;
			timetable?: boolean;
			blacklist?: boolean;
			phone_conformity?: boolean;
			master_messages?: boolean;
			okk?: boolean;
			telemarketing?: boolean;
			newstat?: boolean;
			sms?: boolean;
			callbacks?: boolean;
			master_rejects?: boolean;
			announcement?: boolean;
			mail?: boolean;
			ticket?: boolean;
			wiretapping?: boolean;
		};
	} | null;
}
