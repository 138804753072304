import { ReactNode } from "react";
import { Panel } from "../../../../shared";
import styles from "./main-panel.module.scss";

interface MainPanelProps {
  leftSlot: ReactNode;
  rightSlot: ReactNode;
}
export const MainPanel = ({
  leftSlot = <></>,
  rightSlot = <></>,
}: MainPanelProps) => {
  return (
    <Panel>
      <Panel.Body className={styles["main-panel-container"]}>
        {leftSlot}
        {rightSlot}
      </Panel.Body>
    </Panel>
  );
};
