import { useReactTable, getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";

import { Title } from "@/components/common";
import { WorkTable } from "./work-table";
import { Typography } from "@/components/common";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { REQUEST_PAGE_BY_REQUEST_ID } from "@/constants/routes";

import linkStyle from "@/components/request-card/request-card.module.scss";

export const WorkControl = ({ work, title }: any) => {
	const navigate = useNavigate();

	// Создание массива заголовков
	const columnHelper = createColumnHelper<any>();
	const columnsWorkControl: any = [
		columnHelper.accessor("type_title", {
			size: 130,
			id: "type_title",
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Тип
				</Typography>
			),
			cell: (info) => {
				const typeTitle = info.getValue();
				const badType = typeTitle === "Нарушение" || typeTitle === "Штраф";
				const color = badType ? "#951A20" : "#0D6B23";

				return (
					<Typography as={"caption"} decoration={"none"} tag={4} weight={2} style={{ color }}>
						{info.renderValue()}
					</Typography>
				);
			},
		}),
		columnHelper.accessor("request.fullcode", {
			size: 100,
			id: "fullcode",
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Заявка
				</Typography>
			),
			cell: (info) => {
				return (
					<Typography
						key={info.renderValue()}
						className={linkStyle["text-value-interactive"]}
						as={"caption"}
						weight={2}
						decoration={"none"}
						tag={4}
						onClick={() => navigate(REQUEST_PAGE_BY_REQUEST_ID(String(info.row.original.request_id)))}
					>
						{info.renderValue()}
					</Typography>
				);
			},
		}),
		columnHelper.accessor("date", {
			size: 144,
			id: "date",
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor("user_fio", {
			// size: 940,
			id: "user_fio",
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Кто назначил
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor("text", {
			id: "text",
			// size: 940,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Комментарий
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor("summ", {
			size: 100,
			id: "summ",
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Сумма
				</Typography>
			),
			cell: (info) => {
				const typeTitle = info.row.original.type_title;
				const badType = typeTitle === "Нарушение" || typeTitle === "Штраф";
				const color = badType ? "#951A20" : "#0D6B23";
				const result = Number(info.renderValue()) ? `${badType ? "-" : "+"} ${info.renderValue()}` : null;

				return (
					<Typography as={"caption"} decoration={"none"} tag={4} weight={2} style={{ color }}>
						{result}
					</Typography>
				);
			},
		}),
	];

	// Гидрация таблицы
	const pagination = {
		pageIndex: 0,
		pageSize: work?.length || 10,
	};
	const table = useReactTable({
		data: work || [],
		columns: columnsWorkControl,
		state: {
			pagination,
		},
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: true,
	});

	return (
		<>
			<Title as={"h5"}>{title}</Title>
			<WorkTable table={table} />
		</>
	);
};
