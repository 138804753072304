import { useMutation } from "react-query";
import type { MakeCallEventT } from "../phoner-events";

interface ICreateC2CLogRequest {
  app_name: "basa";
  caller: {
    phone: string;
    type: "user";
    id: number;
  };
  called: {
    phone: string;
    type: "client" | "master";
    id: number;
  };
  entity: {
    type: "request";
    id: number;
  };
}

export const useCreateC2CLog = () => {
  const results = document?.cookie?.match(/session_id=(.+?)(;|$)/);
  const cookie = results?.[1];

  const createC2CLog = async (callInfo: MakeCallEventT) => {
    const preparedData: ICreateC2CLogRequest = {
      app_name: callInfo.app,
      called: {
        phone: callInfo.calledPhone,
        type: callInfo.calledType,
        id: callInfo.callerId,
      },
      caller: {
        phone: callInfo.callerPhone,
        type: callInfo.callerType,
        id: callInfo.callerId,
      },
      entity: {
        type: callInfo.entityType,
        id: callInfo.entityId,
      },
    };

    const response = await fetch(
      `${import.meta.env.REACT_APP_PHONER_BACK}/api/v1/calls/outcome/fix`,
      {
        headers: {
          Authorization: `Bearer ${cookie}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        method: "POST",
        body: JSON.stringify(preparedData),
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result = await response.json();
    return result;
  };

  const mutation = useMutation({
    mutationFn: (callInfo: MakeCallEventT) => createC2CLog(callInfo),
    mutationKey: ["createC2CLog"],
  });

  return { ...mutation };
};
