import { SelectDropdownProps } from '@components/common/select/select-dropdown/select-dropdown.types'
import styles from './select-dropdown.module.scss'

export const SelectDropdown = ({
  children,
  containerChildren,
}: SelectDropdownProps) => {
  return (
    <div className={styles['select-dropdown-container']}>
      {containerChildren}
      <ul className={styles['select-dropdown-items']}>{children}</ul>
    </div>
  )
}
