import { RequestResource } from '@/state/api'
import { Header } from '@common/header/header'
import { RequestInfoHeaderTop } from '@components/headers/request-info-header/request-info-header-top/request-info-header-top'
import { FC } from 'react'

export const RequestInfoHeader: FC<{ info: RequestResource }> = ({
  info,
}): JSX.Element => {
  return <Header topSection={<RequestInfoHeaderTop info={info} />} />
}
