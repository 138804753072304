import { Typography } from '../../../shared'
import { InputRange } from '../../../shared/ui-kit/input-range'
import { memo, useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { useGlobalPhonerState } from '../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import styles from './volume-settings.module.scss'
import { usePhoner } from '../../../shared/contexts/phoner/phoner'

export const VolumeSettings = memo(() => {
  const { phonerSettingsService } = useGlobalPhonerState()

  const [statePhonerSettings, sendPhonerSettingsEvents] = useActor(
    phonerSettingsService,
  )

  const audioVolume = statePhonerSettings?.context?.audioVolume

  const [value, setValue] = useState<number>(audioVolume)
  const { handleVolumeChange } = usePhoner()

  const handleChangeValue = (value: number) => {
    setValue(value)
  }

  useEffect(() => {
    const handler = debounce(() => {
      sendPhonerSettingsEvents({ type: 'change audio', value })
      sendPhonerSettingsEvents('finish changing')
      handleVolumeChange(value)
    }, 250)

    handler()

    return () => handler.cancel()
  }, [value])

  useEffect(() => {
    handleChangeValue(audioVolume)
  }, [audioVolume])

  return (
    <div className={styles.container}>
      <Typography
        className={styles.naming}
        as={'caption'}
        decoration={'none'}
        tag={4}
        weight={2}>
        Громкость звука
      </Typography>
      <div className={styles['input-controls']}>
        <InputRange
          onValueChange={handleChangeValue}
          className={styles['input-range']}
          value={value}
        />

        <Typography
          className={styles.number}
          as={'caption'}
          decoration={'none'}
          tag={4}
          weight={2}>
          {value}
        </Typography>
      </div>
    </div>
  )
})
