import { CallRecordsModalProps } from './call-records-modal.types'
import { ModalContainer } from '@components/common'
import { ModalBody, ModalHeader } from '@common/modal-container'
import { CallRecordsModalLeftContent } from './left-content'
import styles from './call-records-modal.module.scss'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { AudioTracks } from '@/components/audio-track'
import { clearSnacks } from '@/eventbus/events/snackbar'
import { REQUESTS } from '@constants/routes'

export const CallRecordsModal = ({
  onClose,
  onClosePath,
  title,
  subtitle,
  onHandleBackButton,
  closeOnBlackoutClick,
  isRightActionButtonDisabled = false,
}: Partial<CallRecordsModalProps>) => {
  const [initialState, setInitialState] =
    useState<Partial<CallRecordsModalProps> | null>(null)
  const handleSubmit = (values: CallRecordsModalProps) => {}

  const navigate = useNavigate()

  const filters = localStorage.getItem('filters')
    ? JSON.parse(String(localStorage.getItem('filters')))
    : null

  return (
    <>
      <Form onSubmit={handleSubmit} initialValues={initialState}>
        {({ form }) => (
          <ModalContainer
            onClose={
              onClose
                ? onClose
                : onClosePath
                ? () => {
                    navigate({
                      pathname: REQUESTS,
                      search: new URLSearchParams(filters).toString(),
                    })
                    clearSnacks()
                  }
                : () => navigate('/')
            }
            showBlackout
            closeOnBlackoutClick={closeOnBlackoutClick}
            className={styles['call-records-modal']}
          >
            <ModalHeader
              showLeftContent
              contentLeft={
                <CallRecordsModalLeftContent
                  showBackButton={false}
                  title={title}
                  subtitle={subtitle}
                  onHandleBackButton={onHandleBackButton}
                />
              }
              showCloseButton
              onClose={
                onClose
                  ? onClose
                  : onClosePath
                  ? () => {
                      navigate({
                        pathname: REQUESTS,
                        search: new URLSearchParams(filters).toString(),
                      })
                      clearSnacks()
                    }
                  : () => navigate('/')
              }
            />
            <ModalBody>
              <AudioTracks className={styles['call-records-content']} />
            </ModalBody>
          </ModalContainer>
        )}
      </Form>
    </>
  )
}
