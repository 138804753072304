import NoCommetsImage from "@/assets/svg/NoData.svg";
import { ContentLoader, InputMessage } from "@/components";
import { NoData } from "@/components/common";
import { Checkbox } from "@/components/form/checkbox";
import type { RequestCommentsBodyContentProps } from "@/components/modals/request-comments/body-content/comments.types";
import { addSnack } from "@/eventbus/events/snackbar";
import {
	type RequestCommentIndexResource,
	type RequestsIndexApiResponse,
	type ResourcesRequestIndex,
	coreApi,
	useRequestsCommentsAllIndexQuery,
	useRequestsCommentsDestroyMutation,
	useRequestsCommentsStoreMutation,
	useRequestsCommentsUpdateMutation,
	useRequestsShowQuery,
} from "@/state/api";
import { useAppDispatch, useAppSelector } from "@/state/selector";
import { ChatContainer, Message, MessageBox, MessageDateDivider, MessageGroupContainer, MessageGroupContent } from "@common/chat";
import { Avatar } from "@common/chat/avatar";
import { getInputState } from "@components/form/utils/getFieldState";
import { InputMessageEdit } from "@components/input-message-edit";
import { COMMENTS } from "@constants/routes";
import { RequestCommentsActionButtons } from "@pages/request-page/log-card/left-content/comments/action-buttons/request-comments-action-buttons";
import type { SendCommentsFormType } from "@pages/request-page/log-card/left-content/comments/request-comments.types";
import { prepareFIO } from "@utils/prepare-comments-label";
import cn from "classnames";
import { format, parse } from "date-fns";
import ru from "date-fns/locale/ru";
import type { FormApi } from "final-form";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useLocation, useParams } from "react-router-dom";
import DefaultAvatar from "./default-avatar.png";
import { DeleteCommentModal } from "./delete-comment-modal";
import styles from "./request-comments.module.scss";

export const RequestComments = ({ className = "", id, isRequestPage }: Partial<RequestCommentsBodyContentProps>) => {
	const [postComments, { isError: isPostCommentsError }] = useRequestsCommentsStoreMutation();
	const [deleteComment, { isLoading }] = useRequestsCommentsDestroyMutation();
	const [updateComments, { isError: isUpdateCommentsError }] = useRequestsCommentsUpdateMutation();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [deletedCommentId, setDeletedCommentId] = useState<number>();
	const [editCommentId, setEditCommentId] = useState<number>();
	const lastMessageRef = useRef<HTMLDivElement>(null);
	const [lastSubmittedState, setLastSubmittedState] = useState<SendCommentsFormType | null>(null);
	const [commentsList, setCommentsList] = useState<RequestCommentIndexResource[]>([]);
	const { requestId } = useParams();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const { data: requestData } = useRequestsShowQuery({
		requestId: Number(requestId),
	});
	//получили данные

	const requestsStore = useAppSelector<Partial<RequestsIndexApiResponse>>((state) => {
		const data = state.api.queries.requestsIndex?.data as RequestsIndexApiResponse;
		if (data) return data;
		return {};
	});
	const {
		data: comments,
		isFetching,
		isError: isRequestsCommentsError,
		refetch,
	} = useRequestsCommentsAllIndexQuery({
		requestId: Number(requestId) || Number(id),
	});

	// добавление/изменение/удаление комментария
	const handleChangeCache = (newCommentsList: RequestCommentIndexResource[]) => {
		dispatch(
			coreApi.util.updateQueryData("requestsCommentsAllIndex", { requestId: Number(requestId) || 0 }, () => {
				return { data: { quality_controls: comments?.data?.quality_controls, comments: newCommentsList } };
			}),
		);
		dispatch(
			coreApi.util.updateQueryData("requestsIndex", {}, () => {
				const changeStore = [...(requestsStore.data || [])];
				const newStore = changeStore.map((item: ResourcesRequestIndex) => {
					if (item?.id !== Number(requestId)) return item;
					return {
						...item,
						comments_count: newCommentsList
							.map((it) => it.comments?.length || 0)
							.reduce((acc: number, cur: number) => acc + cur, 0),
						last_three_comments: newCommentsList
							.map((it) => it.comments)
							.filter(Boolean)
							.flat()
							.slice(-2),
					};
				}) as ResourcesRequestIndex[];

				return { ...requestsStore, data: newStore };
			}),
		);
	};

	const handleRefetchComments = () => {
		refetch();
	};

	const handleDeleteComment = async () => {
		if (deletedCommentId && requestId) {
			await deleteComment({ requestId: Number(requestId), commentId: deletedCommentId })
				.unwrap()
				.then(() => {
					setCommentsList((prev) => {
						const newComments = prev
							.map((it) => {
								if (it.comments && it.comments.filter((item) => item.id === deletedCommentId).length > 0) {
									const filteredComments = it.comments.filter((com) => com.id !== deletedCommentId);
									if (filteredComments.length > 0) {
										return { ...it, comments: filteredComments };
									}
									return { date: "" };
								}
								return it;
							})
							.filter((item) => item.date);

						// handleChangeCache(newComments);
						return newComments;
					});
					setDeletedCommentId(undefined);
				})
				.catch(() => {
					return;
				});
		}
	};

	const handleClickDelButton = (id?: number) => {
		setIsOpenModal(true);
		setDeletedCommentId(id);
	};

	useEffect(() => {
		if (comments?.data?.comments) setCommentsList(comments.data.comments);
	}, [comments]);

	//Начальные значения формы
	const initialValues: SendCommentsFormType = {
		show_to_master: true,
		text: "",
	};

	//Сабмит формы
	const handlePostComment = async (values: SendCommentsFormType, form?: FormApi<SendCommentsFormType, SendCommentsFormType>) => {
		setLastSubmittedState(values);
		if (values.text) {
			form?.reset();
			form?.restart();

			const result = await postComments({
				requestId: Number(requestId),
				requestsRequestCommentStore: values,
			})
				.unwrap()
				.then((payload) => {
					if (payload?.data) {
						const newComment = payload.data;
						const currentDate = new Date();
						const year = currentDate.getFullYear();
						const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
						const day = currentDate.getDate().toString().padStart(2, "0");
						const formattedDate = `${year}.${month}.${day}`;

						setCommentsList((prev) => {
							if (prev.length === 0) {
								// handleChangeCache([{ date: formattedDate, comments: [newComment] }]);
								return [{ date: formattedDate, comments: [newComment] }];
							}

							if (prev[prev.length - 1]?.date !== formattedDate && newComment) {
								// handleChangeCache([...prev, { date: formattedDate, comments: [newComment] }]);
								return [...prev, { date: formattedDate, comments: [newComment] }];
							}

							if (prev[prev.length - 1]?.date === formattedDate && newComment) {
								const newCommentsList = prev.map((it, idx) => {
									if (idx + 1 !== prev.length) return it;
									if (it.comments) return { ...it, comments: [...it.comments, newComment] };
									return it;
								});

								// handleChangeCache(newCommentsList);
								return newCommentsList;
							}

							// handleChangeCache(prev);
							return prev;
						});
					}
				})
				.catch((e) => {
					console.warn(e);
				});
			return result;
		}
	};

	const handleEditComment = (values: SendCommentsFormType, form?: FormApi<SendCommentsFormType, SendCommentsFormType>) => {
		setLastSubmittedState(values);
		form?.reset();
		form?.restart();
		setEditCommentId(undefined);
	};

	const handleUpdate = (id: number, textValue: string, showToMaster: boolean | undefined) => {
		requestId &&
			textValue &&
			updateComments({
				requestId: Number(requestId),
				commentId: id,
				requestsRequestCommentUpdate: {
					text: textValue,
					show_to_master: showToMaster,
				},
			})
				.unwrap()
				.then((payload) => {
					if (payload.data) {
						setCommentsList((prev) => {
							const newComments = prev.map((it) => {
								const comments = it.comments?.map((comment) => {
									if (payload.data && comment.id === payload.data.id) {
										const newComment = { ...payload.data };

										return newComment;
									}
									return comment;
								});

								return { ...it, comments: comments || [] };
							});

							// handleChangeCache(newComments);
							return newComments;
						});
					}
				});
	};

	useEffect(() => {
		if (isRequestsCommentsError) {
			addSnack({
				type: "error",
				eventName: "fetch-comments-error",
				text: "Не удалось загрузить комментарии.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleRefetchComments(),
			});
		}

		if (isPostCommentsError) {
			addSnack({
				type: "error",
				eventName: "comments-request-error",
				text: `Не удалось отправить комментарий: ${lastSubmittedState ? lastSubmittedState.text : ""}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => (lastSubmittedState ? handlePostComment(lastSubmittedState) : {}),
			});
		}

		if (isUpdateCommentsError) {
			addSnack({
				type: "error",
				eventName: "comments-update-error",
				text: `Не удалось обновить комментарий: ${lastSubmittedState ? lastSubmittedState.text : ""}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => (lastSubmittedState ? handleEditComment(lastSubmittedState) : {}),
			});
		}
	}, [isPostCommentsError, isRequestsCommentsError, isUpdateCommentsError]);

	//скролл к последнему сообщению
	useEffect(() => {
		const lastMessage = lastMessageRef.current;
		if (commentsList?.length && commentsList[commentsList.length - 1]?.comments?.length && lastMessage) {
			lastMessage.scrollIntoView({ block: "nearest", inline: "nearest" });
		}
	}, [commentsList, isFetching]);

	const editCommentClasses = cn(styles["comments-container"], {
		[styles["comments-container"]]: editCommentId,
		[styles["request-page"]]: isRequestPage,
	});

	return (
		<div className={editCommentClasses}>
			<Form onSubmit={editCommentId ? handleEditComment : handlePostComment} initialValues={initialValues}>
				{({ form, handleSubmit }) => (
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					<form
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onSubmit={handleSubmit}
						className={`${styles["request-comments"]} ${className}`}
					>
						<div className={`${styles["request-comments"]} ${className}`}>
							<ChatContainer
								className={`${styles["request-comments_chat-container"]} ${
									commentsList.length < 8 ? styles["justify-end"] : ""
								}`}
							>
								{isFetching && commentsList.length === 0 ? (
									<ContentLoader />
								) : (
									<>
										{Array.isArray(comments?.data?.comments) &&
										comments &&
										comments.data &&
										comments.data.comments.length > 0 ? (
											comments?.data?.comments.map((commentEntry) => {
												const date = commentEntry.date;
												const comments = commentEntry.comments;

												return (
													<React.Fragment key={`${commentEntry?.date}}`}>
														<MessageDateDivider
															date={
																date &&
																format(parse(date, "yyyy.MM.dd", new Date()), "dd MMMM", {
																	locale: ru,
																})
															}
														/>
														<MessageGroupContainer>
															{Array.isArray(comments) &&
																comments.map((comment, idx, comments) => {
																	return (
																		<React.Fragment key={`${idx}+${comment.id}`}>
																			<MessageGroupContent>
																				<MessageBox>
																					{({ showActionButtons }) => {
																						return (
																							<>
																								<div
																									className={styles["message-container"]}
																								>
																									{comments[comments.length - 1]?.id ===
																									comment.id ? (
																										<Avatar src={DefaultAvatar} />
																									) : (
																										<Avatar />
																									)}
																									<Message
																										showToMaster={
																											comment.show_to_master
																										}
																										showActionButtons={
																											showActionButtons
																										}
																										text={comment.text}
																										time={
																											comment.date
																												? format(
																														parse(
																															comment.date,
																															"dd.MM.yyyy HH:mm:ss",
																															new Date(),
																														),
																														"HH:mm",
																													)
																												: ""
																										}
																										updatedAt={comment.updated_at}
																										label={
																											comment.user_fio &&
																											prepareFIO(
																												comment.user_fio,
																												comment.show_to_master,
																											)
																										}
																										status={comment.state_name}
																										bgColor={
																											comment.author?.group
																												?.background_color
																										}
																									/>
																								</div>

																								{showActionButtons && (
																									<RequestCommentsActionButtons
																										comment={comment}
																										handleClickDelButton={() =>
																											handleClickDelButton(comment.id)
																										}
																										handleClickEditButton={() => {
																											setEditCommentId(comment.id);
																											form.change(
																												"text",
																												comment.text,
																											);
																											form.change(
																												"show_to_master",
																												comment.show_to_master,
																											);
																											form.change(
																												"commentId",
																												comment.id,
																											);
																										}}
																									/>
																								)}
																							</>
																						);
																					}}
																				</MessageBox>
																			</MessageGroupContent>
																		</React.Fragment>
																	);
																})}
														</MessageGroupContainer>
													</React.Fragment>
												);
											})
										) : (
											<NoData src={NoCommetsImage} alt="No comments" title="Комментарии отсутствуют" />
										)}
										<div ref={lastMessageRef} />
									</>
								)}
							</ChatContainer>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "max-content",
							}}
						>
							<Field<string> name={"text"} type={"text"}>
								{({ input, meta }) => {
									return editCommentId ? (
										<InputMessageEdit
											input={input}
											meta={meta}
											handleSubmit={() =>
												handleUpdate(
													editCommentId,
													input.value,
													form.getFieldState("show_to_master")?.value ?? false,
												)
											}
											state={getInputState(meta, input)}
											onClose={() => {
												setEditCommentId(undefined);
												form.change("commentId", undefined);
												input?.onChange(undefined);
											}}
										/>
									) : (
										<InputMessage
											disabled={!requestData?.data?.abilities?.comment_add}
											state={getInputState(meta, input)}
											{...input}
											btnType="submit"
										/>
									);
								}}
							</Field>
							<Field<boolean> name={"show_to_master"} type={"checkbox"}>
								{({ input, meta }) => {
									return (
										<Checkbox
											disabled={!requestData?.data?.abilities?.comment_add}
											state={getInputState(meta, input)}
											label={"Показывать комментарий мастеру"}
											showLabel
											{...input}
										/>
									);
								}}
							</Field>
						</div>
						{window!.enableFormLogger === true && (
							<Row>
								<Col>
									<pre>
										{
											// @ts-ignore
											JSON.stringify(form.getState().values, 0, 2)
										}
									</pre>
								</Col>
							</Row>
						)}
					</form>
				)}
			</Form>
			{!!comments?.data?.quality_controls?.length && location.pathname.includes(COMMENTS) && (
				<div className={styles["okk-container"]}>
					{comments.data.quality_controls.map((item) => (
						<Fragment key={item.id}>
							<MessageDateDivider
								date={
									item.date_add &&
									format(parse(item.date_add, "dd.MM.yyyy HH:mm:ss", new Date()), "dd MMMM", {
										locale: ru,
									})
								}
							/>
							<MessageBox isQualityControl>
								<div className={styles["message-container"]}>
									<Avatar src={DefaultAvatar} />
									<Message
										isQualityControls
										isShort
										badges={item.answer_groups}
										showActionButtons={false}
										text={item.comment}
										time={item.date_add ? format(parse(item.date_add, "dd.MM.yyyy HH:mm:ss", new Date()), "HH:mm") : ""}
										label={`${item.type} • ${item.user_fio} • ${item.request_state}`}
									/>
								</div>
							</MessageBox>
						</Fragment>
					))}
				</div>
			)}
			<DeleteCommentModal
				handleDeleteComment={handleDeleteComment}
				isOpen={isOpenModal}
				setIsOpen={setIsOpenModal}
				title={"Вы хотите удалить выбранный комментарий?"}
				isLoading={isLoading}
			/>
		</div>
	);
};
