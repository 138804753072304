import { CreateFilterTemplateFormTypes, CreateFilterTemplateModalProps } from './create-filter-template-modal.types'
import { ModalContainer } from '@components/common'
import { ModalBody, ModalHeader } from '@common/modal-container'
import { CreateFilterTemplateModalLeftContent } from './left-content'
import styles from './create-filter-template-modal.module.scss'
import React, { useEffect, useState } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { RequestModalTextInput } from '@components/modals/common-forms/request-modal-input'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { CreateFilterTemplateLabels, CreateFilterTemplateNames } from '@constants/create-filter-template'
import { useFiltersState } from '@/contexts/filters-state'
import { useFiltersStoreMutation } from '@/state/api'
import { composeValidators, required } from '@components/form/utils/validators'
import { ContentLoader } from '@/components'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'

export const CreateFilterTemplateModal = ({
  onClose,
  onClosePath,
  title,
  subtitle,
  onHandleBackButton,
  closeOnBlackoutClick,
  isRightActionButtonDisabled = false,
}: Partial<CreateFilterTemplateModalProps>) => {
  const [initialState, setInitialState] = useState<Partial<CreateFilterTemplateModalProps> | null>(null)
  const [lastSubmittedState, setLastSubmittedState] = useState<CreateFilterTemplateFormTypes | undefined>()

  const { filters, setSkipGetRequests, setFilters, templateFilters } = useFiltersState()
  const [error, setError] = useState('')
  const [addFilter, { isLoading, isError: isAddFilterError, isSuccess: isAddFilterSuccess, error: addError }] = useFiltersStoreMutation()

  const form = useForm()
  const navigate = useNavigate()
  const handleSubmit = (values: CreateFilterTemplateFormTypes) => {
    setLastSubmittedState(values)
    if (templateFilters && values.templateName) {
      addFilter({
        requestsFilterStore: {
          title: values.templateName,
          data: templateFilters,
        },
      })
        .unwrap()
        .then((data) => {
          addSnack({
            type: 'default',
            eventName: 'add-filter-success',
            text: 'Шаблон успешно создан',
          })
          form.change('template', {
            data: data?.data,
            id: data?.id,
            title: data?.title,
          })
          form.change('templateId', data?.id)
          onClose && onClose()
          form.submit()
        })
        .catch((e) => console.log(e))
    }
  }

  useEffect(() => {
    if (isAddFilterError) {
      setError('Шаблон с таким названием уже существует')
    }
  }, [isAddFilterError])

  useEffect(() => {
    if (isAddFilterError) {
      addSnack({
        type: 'error',
        eventName: 'add-filter-error',
        text: addError && 'status' in addError && addError.status === 422 ? 'Не удалось создать шаблон' : 'Не удалось сохранить фильтр',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => (lastSubmittedState ? handleSubmit(lastSubmittedState) : {}),
      })
    }

    if (isAddFilterSuccess) {
      addSnack({
        type: 'default',
        eventName: 'add-filter-success',
        text: 'Шаблон успешно создан',
      })
      navigate('/requests')
      clearSnacks()
    }
  }, [isAddFilterError, isAddFilterSuccess])

  const errorValidation = () => {
    if (error) {
      return error
    } else {
      return undefined
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} initialValues={initialState}>

        {({ form, valid, pristine }) => (

          <ModalContainer
            onClose={
              onClose
                ? onClose
                : onClosePath
                ? () => {
                    navigate(onClosePath)
                    clearSnacks()

                    setSkipGetRequests && setSkipGetRequests(false)
                  }
                : () => {
                    navigate('/')
                    setSkipGetRequests && setSkipGetRequests(false)
                  }
            }
            showBlackout
            closeOnBlackoutClick={closeOnBlackoutClick}
            className={styles['create-filter-template-modal']}>
            <ModalHeader
              showLeftContent
              contentLeft={
                <CreateFilterTemplateModalLeftContent
                  showBackButton={false}
                  title={title}
                  subtitle={subtitle}
                  onHandleBackButton={onHandleBackButton}
                />
              }
              showCloseButton
              onClose={
                onClose
                  ? onClose
                  : onClosePath
                  ? () => {
                      navigate(onClosePath)
                      clearSnacks()

                      setSkipGetRequests && setSkipGetRequests(false)
                    }
                  : () => {
                      navigate('/')

                      setSkipGetRequests && setSkipGetRequests(false)
                    }
              }
            />
            <ModalBody>
              {isLoading ? (
                <ContentLoader />
              ) : (
                <div className={styles['create-filter-template-modal-content']}>
                  <Field<CreateFilterTemplateFormTypes['templateName']>
                    name={CreateFilterTemplateNames.templateName}
                    type={'text'}
                    label={CreateFilterTemplateLabels.templateName}
                    validate={required}
                    component={RequestModalTextInput}>
                    {({ input, meta }) => {
                      return (
                        <RequestModalTextInput
                          label={CreateFilterTemplateLabels.templateName}
                          labelSide="top"
                          input={input}
                          textError={error}
                          inputContainerOrientation={'column'}
                          meta={meta}
                          onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            const regex = /^\s*$/
                            const isEmptyOrOnlyWhitespace = regex.test(e.currentTarget.value)
                            setError('')
                            form.change(CreateFilterTemplateNames.templateName, !isEmptyOrOnlyWhitespace ? e.currentTarget.value : '')
                          }}
                        />
                      )
                    }}
                  </Field>
                  <Button
                    btnType={'submit'}
                    type={ButtonTypes.primary}
                    onClick={() => {
                      form.submit()
                    }}
                    disabled={!valid || pristine}
                    className={styles['violation-btn']}>
                    Создать
                  </Button>
                </div>
              )}
            </ModalBody>
          </ModalContainer>
        )}
      </Form>
    </>
  )
}
