import { InputDefault } from '@/components/common/multi-select'
import styles from '../okk-comment-modal.module.scss'
import { Button } from '@/components/button'
import { UISizes } from '@/global-types/mods.types'
import { useRequestsInstallmentCreatePaymentMutation } from '@/state/api'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { InputErrorContainer } from '@/components/common/input-error-container'

export const InstallmentAddPayment = ({ havePermission, dept }: { havePermission: boolean; dept: number | undefined }) => {
  const { requestId, cardId } = useParams()

  const [addPayment, { isError, isLoading, error }] = useRequestsInstallmentCreatePaymentMutation()

  const [sumError, setSumError] = useState('')
  const [paymentSumm, setPaymentSumm] = useState('')

  useEffect(() => {
    if (isError) {
      if (error && 'status' in error && error.status === 200) {
        setSumError('Сумма не может быть меньше 100')
      }
    }
  }, [isError, error])

  const onReset = () => {
    setPaymentSumm('')
  }

  const handleAddPayment = () => {
    const summDigital = paymentSumm.replace(/[^0-9,]/g, '')
    const summToNumber = Number(summDigital.replace(/,/g, '.'))
    const regexp = new RegExp(/^(?=.*[1-9])\d+(,\d{1,2})?$/g)
    const validateSumm = regexp.test(summDigital)

    if (!validateSumm) {
      setSumError('Сумма введена некорректно')
      return
    }
    if (!paymentSumm || paymentSumm === '0') {
      setSumError('Введите сумму')
      return
    }
    if (summToNumber < 0) {
      setSumError('Сумма должна быть положительной')
      return
    }
    if (dept) {
      if (summToNumber > dept) {
        setSumError('Сумма должна быть меньше остатка')
        return
      }
    }
    addPayment({
      values: {
        sum: summToNumber,
        type: 'online_receipt',
        payment_type: 'prepayment',
        pay_type: new Date(),
      },
      requestId,
    })
    onReset()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles['input-wrapper']}>
        <InputErrorContainer anchorId={'sum'} errorMessage={sumError} className={styles['input-error-container']}>
          <InputDefault
            disabled={!havePermission}
            state={sumError ? 'error' : 'default'}
            withRightButton={false}
            id={'sum'}
            type={'text'}
            inputContainerOrientation="row"
            labelText={'Сумма:'}
            withResetButton
            onReset={onReset}
            inputProps={{
              value: paymentSumm.replace(/[^0-9,]/g, ''),
              onBlur: (e: any) => {
                setSumError('')
              },
              onChange: (e: any) => {
                setSumError('')
                setPaymentSumm(e.target.value)
              },
            }}
          />
        </InputErrorContainer>
      </div>
      <Button
        onClick={() => {
          handleAddPayment()
        }}
        size={UISizes.medium}
        className={styles['submit-button']}
        disabled={!havePermission || isLoading}
        isLoading={isLoading}>
        Добавить платеж
      </Button>
    </div>
  )
}
