import type { WorkFeedResource } from "@/state/api";
import { MultiSelect } from "@common/multi-select";
import { dateRangesByFastFilter } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import type { RequestModalTextInputProps } from "@components/modals/common-forms/request-modal-input/request-modal-text-input.types";
import { findItem } from "@utils/find-item";
import { useEffect, useState } from "react";
import { useField, useForm } from "react-final-form";

export const FilterStringWithQueryInput = <T,>({
	input,
	meta,
	label,
	enableSearch,
	useQuery = () => {
		return {
			data: null,
		};
	},
	isDataNested = false,
	dropdownMenuSelectionVariant = "single-select",
	dropdownMenuItemWithCheckbox = false,
	dropdownMenuContainerWidth,
	maxLenSelectedItemTitle,
	options = [],
	filterByQuery,
	inputContainerOrientation,
	isGroupSelectable = true,
	disableGroupsWithoutChilds,
	debounceSearchTime,
}: RequestModalTextInputProps<T>) => {
	const { data: serverData, isLoading, isFetching } = useQuery();
	const [initialSelectedItems, setInitialSelectedItems] = useState<{
		[key: string]: any;
	}>();

	const field = useField(input?.name)?.input?.value;
	const templateId = useField("templateId")?.input?.value;

	const selectedItemsOnChangeHandlerByName = <T extends { id?: number; title?: string; data?: any; items?: any }>(
		items: T[],
	): T | number | undefined => {
		if (input.name === FilterNames.template) {
			return items[0];
		}

		return items[0]?.id;
	};
	const form = useForm();
	const handleChange = <T extends { id?: number; title?: string; data?: any; items?: any }>(items: T[]) => {
		if (dropdownMenuSelectionVariant === "single-select" && items && !!items.length) {
			const singleSelectionItemId = selectedItemsOnChangeHandlerByName(items);

			input.onChange(singleSelectionItemId);
			if (
				input.name === "template" &&
				singleSelectionItemId &&
				typeof singleSelectionItemId !== "number" &&
				singleSelectionItemId?.data
			) {
				const entries = Object.entries(singleSelectionItemId.data);
				const resetKeys = Object.keys(form.getState().values);
				resetKeys.map((key) => (key === "template" || key === "page" ? null : form.change(key, undefined)));
				entries.map((arr) => form.change(arr[0], arr[1]));
			}

			if (input.name === "fastFilters" && singleSelectionItemId && typeof singleSelectionItemId !== "number") {
				const updatePeriod = dateRangesByFastFilter[singleSelectionItemId as unknown as keyof typeof dateRangesByFastFilter];
				const dateFrom = updatePeriod.split("-")[0]?.trim();
				const dateTo = updatePeriod.split("-")[1]?.trim();

				form.change("period", dateRangesByFastFilter[singleSelectionItemId as unknown as keyof typeof dateRangesByFastFilter]);

				form.change("dateFrom", dateFrom);
				form.change("dateTo", dateTo);
			}
			return;
		}

		if (dropdownMenuSelectionVariant === "multiple-select" && items && !!items.length) {
			const multipleSelectionItemsIds = items.map((item) => item?.id);
			input.onChange(multipleSelectionItemsIds);
			return;
		}

		input.onChange(undefined);
		form.change("templateId", undefined);
	};

	const handleInitialValue = (field: number | number[]) => {
		if (Array.isArray(field)) {
			return input.name === "district"
				? field.map(
						(id) =>
							findItem({
								id: id,
								arr: serverData?.data || options,
							})[0],
					)
				: field.map(
						(id) =>
							findItem({
								id: id,
								arr: serverData?.data || options,
								filterChildsOnly: !isGroupSelectable,
							})[0],
					);
		}

		return findItem({
			id: field,
			arr: serverData?.data || options,
			filterChildsOnly: !isGroupSelectable,
		});
	};

	useEffect(() => {
		if (input.name !== "template") {
			const initialValues = handleInitialValue(field);
			!isLoading && setInitialSelectedItems(initialValues);
		} else {
			const initialValues = handleInitialValue(templateId);

			!isLoading && setInitialSelectedItems(initialValues);
		}
	}, [field, isLoading, isFetching]);

	useEffect(() => {
		if (input.name === "template" && templateId) {
			const initialValues = handleInitialValue(templateId);
			setInitialSelectedItems(initialValues);

			const period = initialValues[0]?.data.period;
			const fastFiltersId = Object.entries(dateRangesByFastFilter).find(([, value]) => value === period)?.[0];
			form.change("fastFilters", fastFiltersId);
		}
	}, [templateId, isFetching]);

	return (
		<MultiSelect<WorkFeedResource>
			enableVirtualization={input?.name === FilterNames.worker}
			disableGroupsWithoutChilds={disableGroupsWithoutChilds}
			isGroupSelectable={isGroupSelectable}
			enableSearch={enableSearch}
			maxLenSelectedItemTitle={maxLenSelectedItemTitle}
			dropdownMenuContainerWidth={dropdownMenuContainerWidth}
			width={"full-w"}
			isDataNested={isDataNested}
			inputLabelText={label && `${label}: `}
			dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
			dropdownMenuSelectionVariant={dropdownMenuSelectionVariant}
			data={(serverData?.data as WorkFeedResource[]) || options}
			inputId={input.name}
			onChange={(items) => {
				handleChange(items);
			}}
			filterByQuery={filterByQuery}
			initialSelectedItems={initialSelectedItems as WorkFeedResource[]}
			externalInputProps={input}
			externalInputMetaProps={meta}
			isDataLoading={isLoading}
			inputContainerOrientation={inputContainerOrientation}
			debounceSearchTime={debounceSearchTime}
		/>
	);
};
