import type { ModalContainerProps } from './modal-container.types'
import styles from './modal-container.module.scss'
import { ModalBlackout } from '@common/modal-container/modal-blackout'
import { Portal } from '@/components'

export const ModalContainer = ({
  children,
  onClose,
  closeOnBlackoutClick,
  showBlackout,
  className,
  zIndex,
}: Partial<ModalContainerProps>) => {
  return (
    <Portal>
      <div className={styles['modal-wrapper']} style={{ zIndex: zIndex }}>
        {/* biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation> */}
        {showBlackout && <ModalBlackout onClose={closeOnBlackoutClick ? onClose : () => {}} />}
        <div className={`${styles['modal-container']} ${className || ''}`}>{children}</div>
      </div>
    </Portal>
  )
}
