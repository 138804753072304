import { UISizes } from "@/global-types/mods.types";
import { useProfileQuery } from "@/state/api";
import { HeaderProfile } from "@common/header/header-profile/header-profile";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { HeaderCallSwitcher } from "@components/headers/requests-header/header-call-switcher/header-call-switcher";
import { ADD_CALL, CALLS, CREATE_REQUEST, REQUESTS } from "@constants/routes";
import { useNavigate } from "react-router-dom";
import styles from "./request-header-top.module.scss";

export const RequestHeaderTop = () => {
	const navigate = useNavigate();
	const { data: user } = useProfileQuery();

	return (
		<div className={styles["request-header-top"]}>
			<div className={styles["request-header-top_left_block"]}>
				<h3 className={styles["request-header-top_title"]}>Заявки</h3>
				<Button
					type={ButtonTypes.primary}
					onClick={() => navigate(`${REQUESTS}/${CREATE_REQUEST}`)}
					disabled={!user?.data?.permissions?.CAN_REQUEST_ADD}
					iconLeft={<i className={"icon-plus"} />}
				>
					Создать заявку
				</Button>
				<Button
					btnType={"button"}
					type={ButtonTypes.primaryUncolored}
					size={UISizes.medium}
					onClick={() => navigate(`/${CALLS}/${ADD_CALL}`)}
				>
					Добавить звонок
				</Button>
			</div>
			<div className={styles["request-header-top_right_block"]}>
				<HeaderCallSwitcher />
				<HeaderProfile data={user?.data} />
			</div>
		</div>
	);
};
