import { RadioProps } from './radio.types'
import styles from './radio.module.scss'
import cn from 'classnames'
import React, { useId } from 'react'

export const Radio = ({
  state = 'default',
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  className = '',
  id,
  name,
  checked,
  disabled = false,
  onClick
}: RadioProps) => {
  const reactId = useId()
  const localId = id && id !== '' ? id : reactId
  const classes = {
    [styles['radio-default']]: state === 'default',
    [styles['radio-error']]: state === 'error',
    [styles['radio-active']]: state === 'active',
    [styles['radio-disabled']]: disabled,
  }
  const radioContainerClasses = cn(
    styles['radio-item'],
    className,
    classes,
  )

  return (
    <div className={radioContainerClasses}>
      <label className={styles.label} htmlFor={localId}>
        <input
          id={localId}
          name={name}
          disabled={disabled}
          value={value}
          checked={checked}
          type={'radio'}
          onFocus={(e) => onFocus && onFocus(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          onClick={(e) => onClick && onClick(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e)}
        />
        <span>{label}</span>
      </label>
    </div>
  )
}
