import { AnyObject } from '@global-types/utils-type'

export const isEmptyObject = (obj?: AnyObject): boolean => {
  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(i)) {
      return false
    }
  }
  return true
}
