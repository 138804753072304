import { Button, Icon } from '../../../index'
import React from 'react'

type SearchIconProps = {
  handleSearchIcon: () => void
  isSearchIconDisabled: boolean
}
export const SearchIconButton = ({
  handleSearchIcon,
  isSearchIconDisabled,
}: SearchIconProps) => {
  return (
    <Button
      disabled={isSearchIconDisabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        handleSearchIcon()
      }}
      size="m"
      variant="secondary"
      width="icon"
      renderIcon={<Icon icon={'icon-search'} />}
    />
  )
}
