import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table'
import { useReducer, useState } from 'react'

import { TableProps } from './table.types'
import { TableBody } from '@common/table-container/table-body'
import { TableHeader } from '@common/table-container/table-header'
import { TableRow } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableCol, TableColItem } from '@components/common'
import { TableRowItem } from '@common/table-container/table-row/table-row-item'
import { useParams } from 'react-router'
import { useRequestsLogsIndexQuery } from '@/state/api'
import { ContentLoader } from '@/components'

import styles from './table.module.scss'

export const TableRequestHistory = ({
  columns,
  className = '',
}: TableProps) => {
  const { requestId } = useParams()
  const { data: tableData, isFetching } = useRequestsLogsIndexQuery({
    requestId: Number(requestId),
  })
  const rerender = useReducer(() => ({}), {})[1]

  const pagination = {
    pageIndex: 0,
    pageSize: tableData?.data?.length || 10,
  }

  const table = useReactTable({
    data: tableData?.data || [],
    columns,
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  })

  return (
    <>
      {isFetching ? (
        <ContentLoader />
      ) : (
        <TableContainer className={className}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow className={styles['row-history']} key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCol
                      key={header.id}
                      style={{
                        maxWidth:
                          header.column.getSize() !== 150 // дуфолтное
                            ? // значение 150
                              `${header.column.getSize()}px`
                            : '100%',
                      }}>
                      {header.isPlaceholder ? null : (
                        <TableColItem>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableColItem>
                      )}
                    </TableCol>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!tableData?.data?.length ? (
              <TableRow className={styles['row-history']}>
                <TableRowItem>Заявку еще не изменяли</TableRowItem>
              </TableRow>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow
                      className={styles['row-history']}
                      key={row.id}
                      style={{}}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableRowItem
                            style={{
                              maxWidth:
                                cell.column.getSize() !== 150
                                  ? `${cell.column.getSize()}px`
                                  : '100%',
                            }}
                            key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableRowItem>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </>
            )}
          </TableBody>
        </TableContainer>
      )}
    </>
  )
}
