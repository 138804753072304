import { combineReducers } from '@reduxjs/toolkit'

import { userReducer, deviceReducer, windowsReducer, playlistReducer } from '../slice'
import { apiSlice } from '../api'
import { optionsReducer } from '../slice/options-slice'

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  device: deviceReducer,
  user: userReducer,
  windows: windowsReducer,
  options: optionsReducer,
  playlist: playlistReducer,
})
