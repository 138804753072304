import { RequestCardFinanceDropdownProps } from '@common/request-card/request-card-content/request-card-finance-dropdown/request-card-finance-dropdown.types'
import styles from './request-card-finance-dropdown.module.scss'
import { ContentDivider, Typography } from '@components/common'
import React, { useState } from 'react'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { formatSum } from '@/utils/formatSum'

export const RequestCardFinanceDropdown = ({
  card,
  isDefaultOpen = false,
}: Partial<RequestCardFinanceDropdownProps>) => {
  const [isOpened, setIsOpened] = useState<boolean>(isDefaultOpen)

  return (
    <div className={styles['finance-dropdown']}>
      <Button
        type={ButtonTypes.primaryUncolored}
        size={UISizes.small}
        onClick={() => setIsOpened((prev) => !prev)}
        iconRight={
          <i
            className={`icon ${
              isOpened ? 'icon-cheveron-up' : 'icon-cheveron-down'
            } icon-tiny`}
          />
        }>
        <Typography
          className={styles['text-value-interactive']}
          as={'paragraph'}
          decoration={'none'}
          tag={4}>
          {card?.fin_block?.fromClientWithDiscount !== null
            ? `${formatSum(card?.fin_block?.fromClientWithDiscount)}
                         (${card?.fin_block?.payment_type_caption})`
            : null}
        </Typography>
      </Button>

      {isOpened && (
        <div className={styles['finance-dropdown-list']}>
          <div className={styles['finance-dropdown-list__block']}>
            {card && card.fin_block?.fromClient !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  С клиента
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.fromClient)}
                </Typography>
              </div>
            )}
            {card?.fin_block?.discount !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Скидка
                </Typography>
                <Typography
                  style={{ whiteSpace: 'nowrap' }}
                  as={'paragraph'}
                  tag={4}
                  decoration={'none'}>
                  {formatSum(card?.fin_block?.discount)}
                </Typography>
              </div>
            )}
            <div className={styles['finance-dropdown-list__block-item']}>
              <Typography as={'paragraph'} tag={4} decoration={'none'}>
                Рекомендуемая скидка
              </Typography>
              <Typography
                style={{ whiteSpace: 'nowrap' }}
                as={'paragraph'}
                tag={4}
                decoration={'none'}>
                {typeof card?.fin_block?.rec_sale_type === 'number' &&
                card?.fin_block?.rec_sale_type === 0
                  ? formatSum(card?.fin_block?.sale_value)
                  : `${formatSum(card?.fin_block?.sale_value)} %`}
              </Typography>
            </div>
            {card?.fin_block?.fromClientWithDiscount !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  С клиента{' '}
                  <span
                    className={styles['finance-dropdown-with-discount-text']}>
                    (с учетом скидки)
                  </span>
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.fromClientWithDiscount)}
                </Typography>
              </div>
            )}
          </div>
          <ContentDivider />
          <div className={styles['finance-dropdown-list__block']}>
            {card?.fin_block?.detal !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Себестоимость
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.detal)}
                </Typography>
              </div>
            )}
            {card?.fin_block?.transport !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Транспорт
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.transport)}
                </Typography>
              </div>
            )}

            {card?.fin_block?.detalToFirm !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Детали в фирму
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.detalToFirm)}
                </Typography>
              </div>
            )}

            {card?.fin_block?.masterForWork !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Мастеру за работу
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.masterForWork)}
                </Typography>
              </div>
            )}
            {card?.fin_block?.master_total !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Итого мастеру
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.master_total)}
                </Typography>
              </div>
            )}
            {card?.fin_block?.workInFirm !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Работа в фирму
                </Typography>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  {formatSum(card?.fin_block?.workInFirm)}
                </Typography>
              </div>
            )}
          </div>
          <ContentDivider />
          <div className={styles['finance-dropdown-list__block']}>
            {card?.fin_block?.totalInFirm !== null && (
              <div className={styles['finance-dropdown-list__block-item']}>
                <Typography as={'paragraph'} tag={4} decoration={'none'}>
                  Итого в фирму
                </Typography>
                <Typography
                  as={'caption'}
                  tag={4}
                  decoration={'none'}
                  weight={1}>
                  {formatSum(card?.fin_block?.totalInFirm)}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
