import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table'

import { TableHeader } from '@common/table-container/table-header'
import { TableRow, TableRowItem } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableBody, TableCol, TableColItem } from '@components/common'
import { WorkTableProps } from './work-table.types'
import { TableFooter } from '../../../../common/table-container/table-footer'

export const WorkTable = ({ table, className = '' }: WorkTableProps) => {
  const tableCheck = table && table.getRowModel().rows.length

  return (
    <>
      <TableContainer className={''}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                return (
                  <TableCol
                    key={header.id}
                    style={{
                      maxWidth:
                        header.column.getSize() !== 150 // дуфолтное
                          ? // значение 150
                            `${header.column.getSize()}px`
                          : '100%',
                    }}>
                    {header.isPlaceholder ? null : (
                      <TableColItem>{flexRender(header.column.columnDef.header, header.getContext())}</TableColItem>
                    )}
                  </TableCol>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {!tableCheck ? (
            <TableRow>
              <TableRowItem>Данные отсутствуют</TableRowItem>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow key={row.id} style={{ background: 'transparent', height: 'auto' }}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <TableRowItem
                          style={{
                            maxWidth: cell.column.getSize() !== 150 ? `${cell.column.getSize()}px` : '100%',
                            boxShadow: 'var(--gray-50-border-bottom)',
                          }}
                          key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableRowItem>
                      )
                    })}
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>
      </TableContainer>
    </>
  )
}
