import { DefaultSelectDataScheme } from '@common/select/select.types'

export const PushMessageLabels = {
  category: 'Категория',
  message: 'Сообщение мастеру',
} as const

export const PushMessageNames = {
  category: 'category',
  message: 'message',
} as const

export const messageOptions: DefaultSelectDataScheme[] = [
  {
    id: 0,
    title: `Общая`,
    data: 0,
  },
  {
    id: 1,
    title: `ОКК`,
    data: 1,
  },
  {
    id: 2,
    title: `Кураторы`,
    data: 2,
  },
  {
    id: 3,
    title: `Важное`,
    data: 3,
  },
]
