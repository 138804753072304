import { SelectProps } from '@components/common/select/select.types'
import { InputContainer } from '@components/common'
import styles from './select.module.scss'
import { DropdownMenu } from '@components/common/select/select-dropdown'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { nanoid } from '@reduxjs/toolkit'
import { DropdownItem } from './select-dropdown-item'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import Downshift from 'downshift'
import { matchSorter } from 'match-sorter'
import { LoadingSpinner } from '@components/button/loading-spinner'

export const Select = <ItemScheme,>({
  state = 'default',
  name,
  value,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  placeholder,
  onReset,
  className = '',
  id,
  disabled = false,
  options = [],
  valueFormatter,
  enableSearch = false,
  searchKey = 'title',
  readOnly,
  itemsFormatter,
  itemToString,
  isLoading = false,
  inputWrapperClassName,
  withInnerItems = false,
}: SelectProps<ItemScheme>) => {
  const ref = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const localItemToString = useCallback(
    // (item as string) - наебка библиотеки, ибо она не умеет в понимание того, что мне в values нужен объект, а не строка
    // а морфим мы через localValueFormatter
    (item: SelectProps<ItemScheme>['value']) =>
      item !== undefined && item !== null ? (itemToString ? itemToString(item) : (item as string)) : '',
    [itemToString],
  )
  const localValueFormatter = useCallback(
    (item: SelectProps<ItemScheme>['value']): string => {
      if (valueFormatter) {
        if (withInnerItems) {
          const innerOptions = options
            // @ts-ignore
            .filter((opt) => opt.items && opt.items.length)
            // @ts-ignore
            .map((item) => item.items)
            .flat()

          return valueFormatter(item, innerOptions)
        } else {
          return valueFormatter(item, options)
        }
      }
      if (item !== undefined && item !== null && typeof item !== 'object') {
        return item.toString()
      }
      throw new Error('Your item is Object, please provide valueFormatter')
    },
    [valueFormatter, options],
  )
  const localItemsFormatter = useCallback(
    (value: SelectProps<ItemScheme>['value']) => (itemsFormatter ? itemsFormatter(value, options) : localValueFormatter(value)),
    [localValueFormatter],
  )

  const parentRef = React.useRef<HTMLDivElement>(null)
  const searchRef = React.useRef<HTMLInputElement>(null)

  // // The virtualizer
  // const rowVirtual = useVirtual({
  //   size: 10000,
  //   parentRef: parentRef,
  //   estimateSize: () => 35,
  // })
  useEffect(() => {
    searchRef.current && searchRef.current.focus()
  }, [searchRef.current])

  return (
    <div className={`${styles['select-container']} ${inputWrapperClassName}`}>
      <Downshift<SelectProps<ItemScheme>['value']>
        onInputValueChange={onChange}
        onSelect={onChange}
        itemToString={localItemToString}
        selectedItem={value}>
        {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, selectedItem, openMenu, toggleMenu, closeMenu }) => {
          const checkNeedFilter = () => {
            if (options) {
              if ((enableSearch && !withInnerItems) || (enableSearch && !search)) {
                return matchSorter<SelectProps<ItemScheme>['value']>(options, search, {
                  keys: Array.isArray(searchKey) ? searchKey : [searchKey],
                })
              }
              if (enableSearch && withInnerItems) {
                const result: any = options
                  .filter((opt: any) => opt.items && opt.items.length)
                  .reduce((a: any, b: any) => {
                    const filtered = b.items.filter((item: any) => item.title.toLowerCase().includes(search.toLocaleLowerCase()))
                    if (filtered.length) {
                      const newArr = { ...b, items: filtered }
                      const temp = [...a, newArr]

                      return temp
                    } else {
                      return a
                    }
                  }, [])

                return result
              }
              return options
            }
            return options
          }
          // я чето сделал, но не понял что, было бы неплохо разобраться и мб переделать
          const filteredItems = checkNeedFilter()
          // еще одна наебка библиотеки, потому что вместо DetailedInputProps
          // в ней используется HtmlFormProps
          const inputProps = getInputProps() as unknown as SelectProps<ItemScheme>

          return (
            <div
              className="downshift"
              style={{ position: 'relative', width: '100%' }}
              onBlur={(e) => {
                inputProps.onBlur && inputProps.onBlur(e as any)
              }}>
              <InputContainer
                {...inputProps}
                name={name}
                ref={ref}
                disabled={disabled}
                state={state}
                value={!isLoading ? localValueFormatter(inputValue) : ''}
                showChildren={true}
                onFocus={(e) => {
                  inputProps.onFocus && inputProps.onFocus(e)
                  onFocus(e)
                }}
                readOnly={true}
                onClick={(e) => {
                  inputProps.onClick && inputProps.onClick(e)
                  toggleMenu()
                  setSearch('')
                }}
                onChange={(e) => {
                  inputProps.onChange && inputProps.onChange(e)
                  const event = e as ChangeEvent

                  if (event?.nativeEvent && event?.nativeEvent.type === 'insertFromPaste') {
                    inputProps.onChange && inputProps.onChange(e)
                  }
                }}
                onBlur={(e) => {
                  onBlur?.(e)
                  // inputProps.onBlur && inputProps.onBlur(e)
                }}
                placeholder={placeholder}
                className={className}
                id={id}>
                <div className={styles['select-button']}>
                  {isLoading && <LoadingSpinner />}
                  {!isLoading && (
                    <Button
                      disabled={state === 'disabled' || disabled}
                      type={ButtonTypes.secondaryUncolored}
                      size={UISizes.medium}
                      iconLeft={
                        <i className={`icon ${isOpen ? 'icon-cheveron-up' : value !== '' ? 'icon-close' : 'icon-cheveron-down'}`} />
                      }
                      onClick={() => {
                        onReset && onReset()
                        value !== '' ? () => {} : toggleMenu()
                        setSearch('')
                      }}
                    />
                  )}
                </div>
              </InputContainer>

              {isOpen && (
                <div className={`${styles['select-dropdown-wrapper']} downshift-options`} data-options-for={id}>
                  <DropdownMenu
                    containerChildren={
                      enableSearch && (
                        <InputContainer
                          {...inputProps}
                          ref={searchRef}
                          placeholder={'Поиск'}
                          value={search}
                          onChange={(e) => {
                            const event = e as ChangeEvent<HTMLInputElement>
                            setSearch(event.target.value)
                          }}
                        />
                      )
                    }>
                    {isLoading && <DropdownItem data={'Загрузка'} onClick={() => {}} />}
                    {!isLoading && !filteredItems?.length && <DropdownItem data={'Нет подходящих элементов'} onClick={() => {}} />}
                    {!withInnerItems &&
                      !isLoading &&
                      !!filteredItems?.length &&
                      filteredItems.map((opt: ItemScheme, index: any) => {
                        // тут все по честному кажется, но все равно any,
                        // хз как типизировать и надо ли
                        const itemProps = getItemProps({
                          index: index,
                          item: opt,
                        })

                        return (
                          <DropdownItem<SelectProps<ItemScheme>['value']>
                            {...itemProps}
                            key={nanoid()}
                            data={opt}
                            valueFormatter={localItemsFormatter}
                            highlighted={highlightedIndex === index}
                            active={localItemToString(selectedItem) === localItemToString(opt)}
                          />
                        )
                      })}
                    {withInnerItems &&
                      !isLoading &&
                      !!filteredItems?.length &&
                      filteredItems
                        .filter((item: any) => item?.items && item.items.length)
                        .map((opt: any) => {
                          return (
                            <div key={opt.id}>
                              <p className={styles['group-title']}>{opt.title}</p>
                              {opt.items.map((item: any) => {

                                const itemProps = getItemProps({
                                  index: item.id,
                                  item: item,
                                })

                                return (
                                  <DropdownItem<SelectProps<ItemScheme>['value']>
                                    {...itemProps}
                                    key={item.id}
                                    data={item}
                                    valueFormatter={localItemsFormatter}
                                    // highlighted={highlightedIndex === index}
                                    active={localItemToString(selectedItem) === localItemToString(item)}
                                  />
                                )
                              })}
                            </div>
                          )
                        })}
                  </DropdownMenu>
                </div>
              )}
            </div>
          )
        }}
      </Downshift>
    </div>
  )
}
