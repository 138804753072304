import { InputContainer } from '@components/common/input-container'
import { InputProps } from '@common/input-container/input.types'
import styles from './input.module.scss'

export const InputDefault = ({
  state = 'default',
  value,
  showChildren = false,
  onChange = () => {},
  onFocus,
  onBlur,
  placeholder,
  onReset,
  className = '',
  id,
  disabled = false,
  mask,
  name,
  children,
  errorMessage,
  ...props
}: Omit<Partial<InputProps>, 'ref'>) => {
  return (
    <InputContainer
      state={state}
      value={value}
      showChildren={showChildren}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      className={className}
      id={id}
      disabled={disabled}
      mask={mask}
      {...props}
    >
      <div className={styles['input-button']}>{children}</div>
    </InputContainer>
  )
}
// showLabel={showLabel}
// labelSide={labelSide}
// label={label}
