import { formatSelectedDate } from "@components/calendar/api/calendar";
import type {
  FormatDateRangeStringType,
  FormatDateStringType,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/dropdown-menu/filter-period-dropdown-menu.types";
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
} from "date-fns";
import ru from "date-fns/locale/ru";

export const startTimeDefault = "00:00";
export const endTimeDefault = "23:59";

export const handleDateString = (date: Date | null) => {
  return formatSelectedDate(date, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const convertTimeToMs = () => {
  const timeArr = [""];
  const hours = Number.parseInt(timeArr[0] || "0", 10);
  const minutes = Number.parseInt(timeArr[1] || "0", 10);

  return hours * 60 * 60 + minutes * 60 * 1000;
};

export const isDateRangeValid = ({
  startDate,
  endDate,
}: FormatDateRangeStringType) => {
  const startDateMs = startDate?.getTime() || new Date().getTime();
  const endDateMs = endDate?.getTime() || new Date().getTime();

  const startTimeMs = convertTimeToMs();
  const endTimeMs = convertTimeToMs();

  return startDateMs + startTimeMs <= endDateMs + endTimeMs;
};

export const formatDateString = ({
  date,
  time,
  withSlashSeparator,
}: FormatDateStringType) => {
  return withSlashSeparator
    ? `${handleDateString(date).split(".").join("/")} ${time}`
    : `${handleDateString(date)} ${time}`;
};

export const formatDateRangeString = ({
  startDate,
  endDate,
  startTime,
  endTime,
}: FormatDateRangeStringType) => {
  return `${formatDateString({ date: startDate, time: startTime })} ${
    endDate ? `- ${formatDateString({ date: endDate, time: endTime })}` : ""
  }`;
};

export const fastFiltersTemplates = [
  {
    id: "today",
    title: "Сегодня",
  },
  { id: "not_selected", title: "Не выбрано" },
  {
    id: "yesterday",
    title: "Вчера",
  },
  { id: "curr_week", title: "Эта неделя" },
  {
    id: "last_week",
    title: "Предыдущая неделя",
  },
  {
    id: "curr_month",
    title: "Этот месяц",
  },
  {
    id: "last_month",
    title: "Предыдущий месяц",
  },
  {
    id: "curr_year",
    title: "Этот год",
  },
  {
    id: "last_year",
    title: "Предыдущий год",
  },
];

export const salePercent = [
  {
    id: 5,
    title: "5%",
  },
  {
    id: 7,
    title: "7%",
  },
  {
    id: 10,
    title: "10%",
  },
  {
    id: 15,
    title: "15%",
  },
  {
    id: 20,
    title: "20%",
  },
];

export const lastHour = `${
  new Date()
    .toLocaleDateString("ru", {
      hour: "2-digit",
      minute: "2-digit",
    })
    .split(" ")[1]
}`;

export const dateRangesByFastFilter = {
  today: formatDateRangeString({
    startDate: new Date(),
    startTime: startTimeDefault,
    endDate: new Date(),
    endTime: endTimeDefault,
  }),
  not_selected: formatDateRangeString({
    startDate: subDays(new Date(), 90),
    startTime: startTimeDefault,
    endDate: new Date(),
    endTime: endTimeDefault,
  }),
  yesterday: formatDateRangeString({
    startDate: subDays(new Date(), 1),
    startTime: startTimeDefault,
    endDate: subDays(new Date(), 1),
    endTime: endTimeDefault,
  }),
  curr_week: formatDateRangeString({
    startDate: startOfWeek(new Date(), { locale: ru }),
    startTime: startTimeDefault,
    endDate: new Date(),
    endTime: endTimeDefault,
  }),
  last_week: formatDateRangeString({
    startDate: startOfWeek(subDays(new Date(), 7), { locale: ru }),
    startTime: startTimeDefault,
    endDate: endOfWeek(subDays(new Date(), 7), { locale: ru }),
    endTime: endTimeDefault,
  }),
  curr_month: formatDateRangeString({
    startDate: startOfMonth(new Date()),
    startTime: startTimeDefault,
    endDate: new Date(),
    endTime: endTimeDefault,
  }),
  last_month: formatDateRangeString({
    startDate: startOfMonth(subDays(new Date(), 30)),
    startTime: startTimeDefault,
    endDate: endOfMonth(subDays(new Date(), 30)),
    endTime: endTimeDefault,
  }),
  curr_year: formatDateRangeString({
    startDate: startOfYear(new Date()),
    startTime: startTimeDefault,
    endDate: new Date(),
    endTime: endTimeDefault,
  }),
  last_year: formatDateRangeString({
    startDate: startOfYear(subDays(new Date(), 365)),
    startTime: startTimeDefault,
    endDate: endOfYear(subDays(new Date(), 365)),
    endTime: endTimeDefault,
  }),
} as const;

export const splitAndReverse = (date: string) =>
  date?.split(".")?.reverse()?.join("-");

export const getDate = (idx: number, arr: string[]) =>
  new Date(splitAndReverse(arr[idx] || ""));

export const getTime = (idx: number, arr: string[]) => arr[idx];

export const isValidDate = (idx: number, arr: string[]) =>
  !Number.isNaN(Date.parse(splitAndReverse(arr[idx] || "")));

export const isContainsMaskDashes = (arr: string[]) =>
  arr?.some((str) => str.match(/_+/gi));
