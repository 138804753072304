import { InputRange } from '../../../shared/ui-kit/input-range'

type ControlVolumeInputProps = {
  value: number
  onValueChange: (value: number) => void
}
export const ControlVolumeInput = ({
  value,
  onValueChange,
}: ControlVolumeInputProps) => {
  return <InputRange onValueChange={onValueChange} value={value} />
}
