import { ModalContainer } from '@components/common'
import { ModalHeader } from '@common/modal-container'
import styles from './delete-comment-modal.module.scss'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import { Dispatch, FC, SetStateAction } from 'react'
import { AlertModalLeftContent } from '@/components/modals/alert-modal/left-content'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  title: string
  subtitle?: string
  onHandleBackButton?: () => void
  showBackButton?: boolean
  isLoading?: boolean
  handleDeleteComment: () => Promise<void>
}

export const DeleteCommentModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  title,
  subtitle,
  showBackButton,
  onHandleBackButton,
  isLoading,
  handleDeleteComment,
}) => {
  if (!isOpen) return null
  return (
    <>
      <ModalContainer
        onClose={() => {
          setIsOpen(false)
        }}
        showBlackout
        closeOnBlackoutClick={true}
        className={styles['alert-modal']}>
        <ModalHeader
          className={styles['alert-modal__header']}
          showLeftContent
          contentLeft={
            <AlertModalLeftContent
              showBackButton={showBackButton}
              title={title}
              subtitle={subtitle}
              onHandleBackButton={onHandleBackButton}
            />
          }
          onClose={() => {
            setIsOpen(false)
          }}
        />
        <div className={styles['alert-modal__footer']}>
          {/* {showCheckbox && <Checkbox state={'default'} showLabel label={'Удалить для всех'} />} */}
          <div className={styles['alert-modal__footer-action-buttons']}>
            <Button
              btnType={'button'}
              type={ButtonTypes.secondaryUncolored}
              size={UISizes.medium}
              onClick={() => {
                setIsOpen(false)
              }}>
              Оставить
            </Button>
            <Button
              isLoading={isLoading}
              btnType={'button'}
              type={ButtonTypes.dangerUncolored}
              size={UISizes.medium}
              onClick={() => {
                setIsOpen(false)
                handleDeleteComment()
              }}>
              Удалить
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  )
}
