import type { MultiSelectDropdownMenuProps } from "@common/multi-select/multi-select/dropdown-menu/multi-select-dropdown-menu.types";
import { memo } from "react";

import styles from "./multi-select-dropdown-menu.module.scss";

import { Tooltip } from "@/components/common/tooltip";
import { DropdownMenuItem } from "@common/multi-select/multi-select/dropdown-menu/dropdown-menu-item";
import { TreeRecursive } from "@common/multi-select/multi-select/dropdown-menu/dropdown-menu-item/tree-recursive";
import { LoadingSpinner } from "@components/button/loading-spinner";
import { isObjectInMap } from "@utils/find-item";
import cn from "classnames";

export const MultiSelectDropdownMenuDefault = <Item extends { [key: string]: any }>({
	isGroupSelectable,
	menuProps,
	isLoading,
	getItemProps,
	items,
	isOpen,
	highlightedIndex,
	isDataNested,
	dropdownMenuItemVariant,
	dropdownMenuItemWithCheckbox,
	selectItem,
	selectedItems,
	handleCollapseNestedItems,
	openedNestedItems,
	dropdownMenuContainerWidth,
	withTooltip,
	disableGroupsWithoutChilds,
	allowOnlyChildSelection = false,
}: MultiSelectDropdownMenuProps<Item>) => {
	const dropdownWrapperClasses = cn(styles.wrapper, {
		[styles["is-hidden"]]: !isOpen,
		[styles["is-visible"]]: isOpen,
	});

	const dropdownItemsListContainer = cn(styles["multi-select__dropdown-container"], {
		[styles["container-with-checkboxes"]]: dropdownMenuItemWithCheckbox,
		[styles["container-with-nested-data"]]: isDataNested,
	});

	const dropdownMenuStyles = dropdownMenuContainerWidth
		? { minWidth: `${dropdownMenuContainerWidth}px`, width: "max-content" }
		: {
				right: 0,
				left: 0,
			};

	return isLoading ? (
		<div style={{ ...dropdownMenuStyles }} className={dropdownWrapperClasses}>
			<div className={cn([dropdownItemsListContainer, styles.loading])} {...menuProps}>
				<LoadingSpinner />
			</div>
		</div>
	) : (
		<div style={{ ...dropdownMenuStyles }} className={dropdownWrapperClasses}>
			<div className={dropdownItemsListContainer} {...menuProps}>
				{/*если структура данных плоская*/}
				{!isDataNested &&
					isOpen &&
					items.map((item) => {
						const itemProps = {
							...getItemProps({
								item,
								key: item.id,
								index: item.id,
								id: item.id,
							}),
						};

						return (
							<Tooltip
								placement="bottom"
								className={styles["tooltip-container"]}
								show={item.description && !!item.description.replaceAll(" ", "") && withTooltip}
								key={item.id}
								anchorId={item.id}
								text={item.description}
							>
								<DropdownMenuItem<Item>
									disableGroupsWithoutChilds={disableGroupsWithoutChilds}
									id={item.id}
									withCheckbox={dropdownMenuItemWithCheckbox}
									selectItem={selectItem}
									selectedItems={selectedItems}
									itemProps={itemProps}
									key={item.id}
									item={item}
									isActive={isObjectInMap(selectedItems, item.id, item.title)}
									isDataNested={false}
									variant={dropdownMenuItemVariant}
									handleCollapseNestedItems={handleCollapseNestedItems}
									openedNestedItems={openedNestedItems}
								/>
							</Tooltip>
						);
					})}

				{/*если структура данных вложенная */}
				{isDataNested && isOpen && !!items.length && (
					<TreeRecursive<Item>
						disableGroupsWithoutChilds={disableGroupsWithoutChilds}
						isGroupSelectable={isGroupSelectable}
						selectItem={selectItem}
						selectedItems={selectedItems}
						items={items}
						highlightedIndex={highlightedIndex}
						isDataNested={true}
						dropdownMenuItemVariant={dropdownMenuItemVariant}
						dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
						getItemProps={getItemProps}
						isOpen={isOpen}
						handleCollapseNestedItems={handleCollapseNestedItems}
						openedNestedItems={openedNestedItems}
						allowOnlyChildSelection={allowOnlyChildSelection}
					/>
				)}
			</div>
			{items.length === 0 && !isLoading && <div className={styles["empty-plug"]}>Не найдено совпадений</div>}
		</div>
	);
};

export const MultiSelectDropdownMenu = memo(MultiSelectDropdownMenuDefault);
