import { createSlice } from "@reduxjs/toolkit";
import { optionsInitialState } from "./initial-state";

const optionsSlice = createSlice({
  initialState: { ...optionsInitialState },
  name: "options",
  reducers: {
    toggleStatistics: (state, action) => {
      state.isStatisticsOpen = action.payload;
    },
  },
});

export const { reducer: optionsReducer } = optionsSlice;
export const { toggleStatistics } = optionsSlice.actions;
