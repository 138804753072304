import cn from 'classnames'
import type { ContentDividerProps } from '@common/content-divider/content-divider.types'
import styles from './content-divider.module.scss'
import type React from 'react'

export const ContentDivider = ({
  type = 'horizontal',
  height = '0',
  className = '',
  subType,
  width = '100%',
  margin,
}: Partial<ContentDividerProps>) => {
  const dividerClasses = cn(styles['content-divider'], {
    [styles['content-divider__vertical']]: type === 'vertical',
    [styles['content-divider__horizontal']]: type === 'horizontal',
    [styles['content-divider__header-simple']]: type === 'header',
    [styles['content-divider__header-entity']]: type === 'entity',
    [styles['content-divider__header-blacklist']]: type === 'header-blacklist',
    [styles['content-divider__header-vertical']]: subType === 'header-vertical',
  })

  return (
    // biome-ignore lint/style/useSelfClosingElements: <explanation>
<div
      style={
        {
          '--vertical-divider-height': height,
          '--vertical-divider-width': width,
          '--content-divider-margin': margin,
        } as React.CSSProperties
      }
      className={`${dividerClasses} ${className}`}></div>
  )
}
