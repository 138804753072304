import { useActor } from '@xstate/react'
import { useGlobalPhonerState } from '../../../app/providers/xstate-provider'
import { Search as CallLogSearch } from '../../../features/call-log/search'
import { Search } from '../../../features/fast-search/search'

export const GetMiddleSlot = () => {
  const { phonerMenuService } = useGlobalPhonerState()
  const [stateMenu] = useActor(phonerMenuService)

  return <>{stateMenu.matches('menu opened.call logs') ? <CallLogSearch /> : <Search />}</>
}
