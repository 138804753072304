import { Icon, Panel, Typography } from "../../../shared";
import { HotKeysItem } from "../hot-keys-item";
import { ControlButton } from "../../../shared/components/control-button";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import styles from "./hot-keys.module.scss";
export const HotKeys = () => {
	const { phonerMenuService } = useGlobalPhonerState();
	const [, send] = useActor(phonerMenuService);

	return (
		<Panel className={styles["top-container"]}>
			<Panel.Header withBackButton handleClickBackButton={() => send("back")} title={"Горячие клавиши"} />

			<Panel.Body className={styles["panel-body"]}>
				<HotKeysItem
					leftSlot={
						<Typography as={"caption"} decoration={"none"} tag={5} weight={2}>
							Принять звонок
						</Typography>
					}
					rightSlot={<ControlButton keyboardKeys={["Alt", "Q"]} />}
				/>

				<HotKeysItem
					leftSlot={
						<Typography as={"caption"} decoration={"none"} tag={5} weight={2}>
							Завершить звонок
						</Typography>
					}
					rightSlot={<ControlButton keyboardKeys={["Alt", "W"]} />}
				/>
				<HotKeysItem
					leftSlot={
						<Typography as={"caption"} decoration={"none"} tag={5} weight={2}>
							Увеличить громкость динамиков
						</Typography>
					}
					rightSlot={<ControlButton keyboardKeys={["Ctrl", <Icon key="1" icon={"icon-arrow-up"} />]} />}
				/>

				<HotKeysItem
					leftSlot={
						<Typography as={"caption"} decoration={"none"} tag={5} weight={2}>
							Уменьшить громкость динамиков
						</Typography>
					}
					rightSlot={<ControlButton keyboardKeys={["Ctrl", <Icon key="2" icon={"icon-arrow-down"} />]} />}
				/>
			</Panel.Body>
		</Panel>
	);
};
