import { INCLUDE_PHONER } from "@/constants/env";
import { useProfileQuery } from "@/state/api";
import { ExternalPhoner, PhonerEvents, type PhonerEventsT } from "@lenremont/phoner";

export const Phoner = () => {
	const { data: user } = useProfileQuery();

	const isPhonerAvailable = user?.data?.inner_phone !== "999" && user?.data?.permissions?.CAN_USE_PHONER && INCLUDE_PHONER;

	return isPhonerAvailable ? <ExternalPhoner /> : <></>;
};

export { PhonerEvents };
export type { PhonerEventsT };
