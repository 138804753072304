import { Title, Typography } from '@/components/common'
import { unpackingDistricts } from '@/utils/unpacking-districts'
import styles from '../districts-list.module.scss'

export const DistrictBlock = ({ child }: any) => {
  const result = unpackingDistricts(child)

  let leftItems: string[] = []
  let mediumItems: string[] = []
  let rightItems: string[] = []

  const halfLength = Math.ceil(result.path.length / 2)
  const thirdLength = Math.ceil(result.path.length / 3)

  if (result.path.length <= 9) {
    leftItems = result.path
  } else if (result.path.length >= 10 && result.path.length <= 19) {
    leftItems = result.path.slice(0, halfLength)
    mediumItems = result.path.slice(halfLength)
  } else if (result.path.length >= 20) {
    leftItems = result.path.slice(0, thirdLength)
    mediumItems = result.path.slice(thirdLength, thirdLength * 2)
    rightItems = result.path.slice(thirdLength * 2)
  }

  return (
    <div className={styles['district-block-header']}>
      <Title as={'h6'} className={styles['header-title']}>{result.title}</Title>
      <div className={styles['list-container-block']}>
        <div className={styles['left-column']}>
          {leftItems &&
            leftItems.map((item: string, index: number) => (
              <Typography
                key={index}
                className={styles['text-value']}
                as={'caption'}
                decoration={'none'}
                weight={2}
                tag={4}>
                {item.replace(/-&gt;/g, ' -> ')}
              </Typography>
            ))}
        </div>
        <div className={styles['medium-column']}>
          {mediumItems &&
            mediumItems.map((item: any, index: number) => (
              <Typography
                key={index}
                className={styles['text-value']}
                as={'caption'}
                decoration={'none'}
                weight={2}
                tag={4}>
                {item.replace(/-&gt;/g, ' -> ')}
              </Typography>
            ))}
        </div>
        <div className={styles['right-column']}>
          {rightItems &&
            rightItems.map((item: any, index: number) => (
              <Typography
                key={index}
                className={styles['text-value']}
                as={'caption'}
                decoration={'none'}
                weight={2}
                tag={4}>
                {item.replace(/-&gt;/g, ' -> ')}
              </Typography>
            ))}
        </div>
      </div>
    </div>
  )
}
