import { useState } from "react";
import styles from "./accordion.module.scss";
import type { AccordionProps } from "./accordion.types";

const Accordion = ({ header, content, open }: AccordionProps) => {
	const [isOpen, setIsOpen] = useState(open);

	return (
		<div className={styles.accordion}>
			<div className={styles["accordion-item"]}>
				<div className={styles["accordion-title"]} onClick={() => setIsOpen((prev) => !prev)}>
					<div>{header}</div>
					<div>
						<i className={`icon ${isOpen ? "icon-cheveron-up" : "icon-cheveron-down"}`} />
					</div>
				</div>
				{isOpen && <div className={styles["accordion-content"]}>{content}</div>}
			</div>
		</div>
	);
};

export default Accordion;
