import { WorkResourcesRequestIndex } from '@/state/api'

export const unpackingDistricts = (currentObj?: WorkResourcesRequestIndex) => {
  const resultObj: { title: string; path: string[] } = {
    title: '',
    path: [],
  }

  const extractLowPath = (items: WorkResourcesRequestIndex[]) => {
    items.forEach((item: WorkResourcesRequestIndex) => {
      if (item.items.length > 0) {
        resultObj.path.push(item.path.replace(resultObj.title + '-&gt;', ''))
        extractLowPath(item.items)
      } else if (item.items.length === 0) {
        resultObj.path.push(item.path.replace(resultObj.title + '-&gt;', ''))
      }
    })
  }

  if (
    currentObj &&
    currentObj.hasOwnProperty('title') &&
    currentObj.items.length > 0
  ) {
    resultObj.title = currentObj.title
    extractLowPath(currentObj.items)
  }

  return resultObj
}

// export const unpackingDistricts = (currentObj?: WorkResourcesRequestIndex) => {
//   const resultObj: { title: string; path: string[] } = {
//     title: '',
//     path: [],
//   }
//   let flag = 0

//   const extractLowPath = (items: WorkResourcesRequestIndex[]) => {
//     items.forEach((item: WorkResourcesRequestIndex) => {
//       if (item.items.length > 0 && flag === 0 ) {
//         flag++
//         resultObj.path.push(item.title)
//         extractLowPath(item.items)
//       } else if (item.items.length >  0 && flag === 1) {
//         flag++
//         resultObj.path.pop()
//         resultObj.path.push(item.path.replace(resultObj.title + '-&gt;', ''))
//         extractLowPath(item.items)
//       } else if (item.items.length === 0 ) {
//         flag = 0
//         resultObj.path.push(item.path.replace(resultObj.title + '-&gt;', ''))
//       }
//     })
//   }

//   if (
//     currentObj &&
//     currentObj.hasOwnProperty('title') &&
//     currentObj.items.length > 0
//   ) {
//     resultObj.title = currentObj.title
//     extractLowPath(currentObj.items)
//   }

//   return resultObj
// }

// export const unpackingDistricts = (currentObj?: WorkResourcesRequestIndex) => {
//   const resultObj: { title: string; path: string[] } = {
//     title: '',
//     path: [],
//   }

//   const extractLowPath = (items: WorkResourcesRequestIndex[]) => {
//     items.forEach((item: WorkResourcesRequestIndex) => {
//       if (item.items.length > 0) {
//         resultObj.path.push(item.title)
//         extractLowPath(item.items)
//       } else if (item.items.length === 0) {
//         resultObj.path.push(item.path.replace(resultObj.title + '-&gt;', ''))
//       }
//     })
//   }

//   if (
//     currentObj &&
//     currentObj.hasOwnProperty('title') &&
//     currentObj.items.length
//   ) {
//     resultObj.title = currentObj.title
//     extractLowPath(currentObj.items)
//   }

//   return resultObj
// }
