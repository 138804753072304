export const getRegion = (data: any) => {
  const addressData = {
    region: data.city_district_with_type ? `${data.city_district_with_type}` : '',
  }

  return `${addressData.region}`
}

export const getBlock = (data: any) => {
  const addressData = {
    type: data.block_type_full ? `${data.block_type_full}` : '',
    block: data.block ? ` ${data.block}` : '',
  }

  return `${addressData.type}${addressData.block}`
}

export const getFlat = (data: any) => {
  const addressData = {
    type: data.flat_type ? `${data.flat_type}` : '',
    flat: data.flat ? ` ${data.flat}` : '',
  }

  return `${addressData.type}${addressData.flat}`
}

export const getHouse = (data: any) => {
  const addressData = {
    house_type_full: data.house_type_full ? `${data.house_type_full}` : '',
    house: data.house ? ` ${data.house}` : '',
    block_type_full: data.block_type ? ` ${data.block_type}` : '',
    block: data.block ? ` ${data.block}` : '',
  }

  return `${addressData.house_type_full}${addressData.house}${addressData.block_type_full}${addressData.block}`
}

export const getStreet = (data: any) => {
  const addressData = {
    street_with_type: data.street_with_type ? `${data.street_with_type}` : '',
    house_type: data.house_type ? ` ${data.house_type}` : '',
    house: data.house ? ` ${data.house}` : '',
    block_type: data.block_type ? ` ${data.block_type}` : '',
    block: data.block ? ` ${data.block}` : '',
    flat_type: data.flat_type ? ` ${data.flat_type}` : '',
    flat: data.flat ? ` ${data.flat}` : '',
  }
  
  return `${addressData.street_with_type}${addressData.house_type}${addressData.house}${addressData.block_type}${addressData.block}${addressData.flat_type}${addressData.flat}`
}

export const getDistrict = (data: any) => {
  const addressData = {
    city_district_with_type: data.city_district_with_type
      ? `${data.city_district_with_type}`
      : '',
  }
  
  return `${addressData.city_district_with_type}`
}