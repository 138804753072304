import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table'

import { TableProps } from './table.types'
import { TableBody } from '@common/table-container/table-body'
import { TableHeader } from '@common/table-container/table-header'
import { TableRow } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableCol, TableColItem } from '@components/common'
import { TableRowItem } from '@common/table-container/table-row/table-row-item'
import { ContentLoader } from '@/components'
import { TableFooter } from '../../../../common/table-container/table-footer'

export const TableMasterCard = ({ columns, info, className = '' }: TableProps) => {
  const pagination = {
    pageIndex: 0,
    pageSize: info?.length || 10,
  }

  const table = useReactTable({
    data: info || [],
    columns,
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  })

  return (
    <>
      {!info ? (
        <ContentLoader />
      ) : (
        <TableContainer className={className}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCol
                      key={header.id}
                      style={{
                        maxWidth:
                          header.column.getSize() !== 150 // дуфолтное
                            ? // значение 150
                              `${header.column.getSize()}px`
                            : '100%',
                      }}>
                      {header.isPlaceholder ? null : (
                        <TableColItem>{flexRender(header.column.columnDef.header, header.getContext())}</TableColItem>
                      )}
                    </TableCol>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!info.length ? (
              <TableRow>
                <TableRowItem>Данные отсутствуют</TableRowItem>
              </TableRow>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} style={{ background: 'transparent', height: 'auto' }}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableRowItem
                            style={{
                              maxWidth: cell.column.getSize() !== 150 ? `${cell.column.getSize()}px` : '100%',
                              boxShadow: 'var(--gray-50-border-bottom)',
                            }}
                            key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableRowItem>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </>
            )}
          </TableBody>
          {!!columns[0].footer && (
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => {
                    const accessor = footer.column.id
                    const isCountColumn = accessor === 'count'
                    const sum = isCountColumn ? info.reduce((acc: number, row: { count: number }) => acc + row.count, 0) : null

                    return (
                      <TableCol
                        key={footer.id}
                        style={{
                          maxWidth: footer.column.getSize() !== 150 ? `${footer.column.getSize()}px` : '100%',
                        }}>
                        {footer.isPlaceholder ? null : (
                          <TableColItem>
                            {footer.column.id === 'count' ? (
                              <div>{sum}</div>
                            ) : (
                              flexRender(footer.column.columnDef.footer, footer.getContext())
                            )}
                          </TableColItem>
                        )}
                      </TableCol>
                    )
                  })}
                </TableRow>
              ))}
            </TableFooter>
          )}
        </TableContainer>
      )}
    </>
  )
}
