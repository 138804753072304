import { createSlice } from "@reduxjs/toolkit";
import { playlistInitialState } from "@/state/slice/initial-state/playlist";

const playlistSlice = createSlice({
  name: "playlist",
  initialState: playlistInitialState,
  reducers: {
    setIsAudioPlaying(state, action) {
      state.isAudioPlaying = action.payload;
    },
    setCurrentCdrId(state, action) {
      state.currentCdrId = action.payload;
    },
    setCurrentIndex(state, action) {
      state.currentIndex = action.payload;
    },
    setCurrentPlaylist(state, action) {
      state.currentPlaylist = action.payload;
    },
    addPlaylistForRequest(state: any, action) {
      state.playlists[`${action.payload.requestId}`] =
        action.payload.newAudioArray;
    },
    setPlayingState(state, action) {
      state.isAudioPlaying = action.payload;
    },
    setPreventPlaying(state, action) {
      state.preventPlaying = action.payload;
    },
    setErrorTracks(state, action) {
      if (!state.errorList.find((e) => String(e) === String(action.payload))) {
        state.errorList = [...state.errorList, String(action.payload)];
      }
    },
  },
  extraReducers: undefined,
});

export const { reducer: playlistReducer } = playlistSlice;
export const {
  setIsAudioPlaying,
  setCurrentCdrId,
  setCurrentIndex,
  setCurrentPlaylist,
  addPlaylistForRequest,
  setPlayingState,
  setErrorTracks,
  setPreventPlaying,
} = playlistSlice.actions;
