import type { SidebarMenuContainerProps } from "@common/sidebar-menu/sidebar-menu.types";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { UISizes } from "@global-types/mods.types";
import cn from "classnames";
import { forwardRef } from "react";
import styles from "../sidebar-menu.module.scss";

export const SidebarMenuContainer = forwardRef<HTMLDivElement, Partial<SidebarMenuContainerProps>>(
	({ children, isOpened, handleOpenSidebar }, ref) => {
		const sidebarMenuClasses = cn(styles["sidebar-menu"], {
			[styles["sidebar-menu__opened"]]: isOpened,
			[styles["sidebar-menu__closed"]]: !isOpened,
		});

		return (
			<div ref={ref} className={sidebarMenuClasses}>
				{children}
				<Button
					className={styles["sidebar-menu_close-btn"]}
					onClick={handleOpenSidebar}
					type={ButtonTypes.secondaryUncolored}
					size={UISizes.medium}
					iconLeft={<i className={`icon ${isOpened ? "icon-cheveron-left" : "icon-cheveron-right"}`} />}
				>
					{isOpened ? "Свернуть" : ""}
				</Button>
			</div>
		);
	},
);
