import { ContentLoader } from "@/components";
import { addSnack, clearSnacks } from "@/eventbus/events/snackbar";
import {
	type RequestCommentIndexResource,
	type RequestResource,
	type RequestsCommentsAllIndexApiResponse,
	type RequestsDuplicationApiResponse,
	type RequestsIndexApiResponse,
	type RequestsStoreApiResponse,
	type RequestsUpdateApiResponse,
	type ResourcesRequestIndex,
	coreApi,
	useProfileQuery,
	useRequestsChangeStateMutation,
	useRequestsDuplicationMutation,
	useRequestsShowQuery,
	useRequestsStoreMutation,
	useRequestsUpdateMutation,
} from "@/state/api";
import { useAppDispatch, useAppSelector } from "@/state/selector";
import { createRequestValidation } from "@/utils/create-request-validation";
import { validateOnlyNumbers } from "@/utils/validateOnlyNumbers";
import { ModalBody, ModalHeader } from "@common/modal-container";
import { ModalContainer, Title, Typography } from "@components/common";
import { Checkbox } from "@components/form/checkbox";
import { getInputState } from "@components/form/utils/getFieldState";
import { commentOnlySpecialSymbolsValidator, composeValidators, firstSymbolCantBeSpaceValidator } from "@components/form/utils/validators";
import { AutoSelectionSwitcher } from "@components/modals/common-forms/auto-selection-switcher";
import { CommonCreateEditForm } from "@components/modals/common-forms/create-edit-request-form";
import { RequestModalTextInput } from "@components/modals/common-forms/request-modal-input";
import { RequestModalMasterSelectionButton } from "@components/modals/common-forms/request-modal-master-selection-button/request-modal-master-selection-button";
import { cancelType } from "@components/modals/common-forms/status-label/api/status-label";
import { TableMastersSelection } from "@components/modals/common-tables";
import { CreateRequestModalRightContent } from "@components/modals/create-request-modal/right-content";
import { CreateRequestLabels, CreateRequestNames } from "@constants/create-request";
import { REQUESTS } from "@constants/routes";
import { createColumnHelper } from "@tanstack/react-table";
import {
	prepareFormDataCreateRequest,
	prepareFormDataEditRequest,
	prepareFormDataUpdateRequest,
} from "@utils/prepare-form-data-create-request";
import cn from "classnames";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { Outlet, useMatch } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { RequestModalFileInput } from "../common-forms/request-modal-input/request-modal-attachments/request-modal-attachments";
import styles from "./create-request-modal.module.scss";
import type { CreateRequestModalFormTypes, CreateRequestModalProps } from "./create-request-modal.types";
import { CreateRequestModalLeftContent } from "./left-content";

const initialValues = {
	isLegalEntity: false,
	masterSelectionFilter: "optimal",
	hasGuarantee: false,
	autoSelection: true,
	commonSelectedMasters: [],
	selectedMastersapproach: [],
	selectedMastersoptimal: [],
	paymentStatus: 0,
	showToMaster: true,
	status: 0,
	attachments: [],
	attachmentsOrigin: [],
};

const defaultColStyle = {
	padding: 1,
	flex: 1,
};

export const CreateRequestModal = ({
	modalView,
	requestData,
	onClosePath,
	onClose,
	subtitle,
	onHandleBackButton,
	closeOnBlackoutClick,
	isRightActionButtonDisabled = false,
}: CreateRequestModalProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [initialState, setInitialState] = useState<CreateRequestModalFormTypes | Partial<CreateRequestModalFormTypes>>(initialValues);
	const [lastSubmittedFormState, setLastSubmittedFormState] = useState<
		CreateRequestModalFormTypes | Partial<CreateRequestModalFormTypes> | null
	>(null);

	const [
		createNewRequest,
		{ isLoading: isCreateNewRequestLoading, isError: isCreateNewRequestError, isSuccess: isCreateNewRequestSuccess },
	] = useRequestsStoreMutation();
	const [editRequest, { isLoading: isEditRequestLoading, isError: isEditRequestError, isSuccess: isEditRequestSuccess }] =
		useRequestsUpdateMutation();
	const { data: user } = useProfileQuery(undefined, {
		skip: modalView === "edit",
	});

	const { requestId, fullcodeId } = useParams();
	const [
		duplicateRequest,
		{ isLoading: isDuplicateRequestLoading, isError: isDuplicateRequestError, isSuccess: isDuplicateRequestSuccess },
	] = useRequestsDuplicationMutation();

	//забираем из requestShow текущую заявку
	type RequestResourceState = { data: { data: RequestResource } };

	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const requestShowStore = useAppSelector<Partial<RequestResourceState>>((state) => {
		const data = state.api.queries[`requestsShow({"requestId":${requestId}})`] as RequestResourceState;
		if (data) return data;
		return {};
	});

	const requestsStore = useAppSelector<Partial<RequestsIndexApiResponse>>((state) => {
		const data = state.api.queries.requestsIndex?.data as RequestsIndexApiResponse;
		if (data) return data;
		return {};
	});

	//забираем из requestsCommentsAllIndex текущий список комментов
	const requestCommentsAllIndexStore = useAppSelector<Partial<RequestsCommentsAllIndexApiResponse>>((state) => {
		const data = state.api.queries[`requestsCommentsAllIndex({"requestId":${requestId}})`]?.data as RequestsCommentsAllIndexApiResponse;
		if (data) return data;
		return {};
	});

	const dispatchingToRequestsIndexStore = (response: RequestsStoreApiResponse | RequestsDuplicationApiResponse) => {
		// biome-ignore lint/correctness/noUnusedVariables: <explanation>
		const dispatchCash = dispatch(
			coreApi.util.updateQueryData("requestsIndex", {}, (draftPosts) => {
				draftPosts?.data ? draftPosts.data?.unshift(response.data || {}) : undefined;
			}),
		);
	};

	// логика добавления заявки в БД и в кэш
	const handleCreateNewRequest = async (values: CreateRequestModalFormTypes) => {
		const response = await createNewRequest({
			requestsRequestStore: prepareFormDataCreateRequest(values),
		}).unwrap();
		dispatchingToRequestsIndexStore(response);
	};

	// логика редактирования заявки в БД и в кэш
	const handleEditRequest = async (values: CreateRequestModalFormTypes) => {
		try {
			const response: RequestsUpdateApiResponse = await editRequest({
				requestId: requestData ? Number(requestData.id) : Number(requestId),
				updateRequest: prepareFormDataUpdateRequest(values),
			}).unwrap();

			// диспатч добавления комментария
			if (values.comment) {
				// biome-ignore lint/correctness/noUnusedVariables: <explanation>
				const patchCommentsResult = dispatch(
					coreApi.util.updateQueryData("requestsCommentsAllIndex", { requestId: Number(requestId) || 0 }, () => {
						const currentDate = new Date();
						const year = currentDate.getFullYear();
						const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
						const day = currentDate.getDate().toString().padStart(2, "0");
						const formattedDate = `${year}.${month}.${day}`;
						const currentComments = [...(requestCommentsAllIndexStore?.data?.comments as RequestCommentIndexResource[])];
						const lastComment = response?.data?.last_three_comments?.at(-1);
						// если нет созданных комментариев
						if (currentComments.length === 0 && lastComment) {
							currentComments.push({ date: formattedDate, comments: [lastComment] });
							return {
								data: {
									quality_controls: requestCommentsAllIndexStore.data?.quality_controls,
									comments: currentComments,
								},
							};
						}

						// когда нет комментариев за текущую дату
						if (currentComments.length > 0 && currentComments.at(-1)?.date !== formattedDate && lastComment) {
							return {
								data: {
									quality_controls: requestCommentsAllIndexStore.data?.quality_controls,
									comments: [...currentComments, { date: formattedDate, comments: [lastComment] }],
								},
							};
						}

						// когда есть комментарии за текущую дату
						if (currentComments.length > 0 && currentComments.at(-1)?.date === formattedDate && lastComment) {
							const check = [...currentComments];
							const doubleCheck = check.map((item) => {
								if (item.date === formattedDate && item.comments) {
									// if (item.comments) {
									return { ...item, comments: [...item.comments, lastComment] };
								}
								return item;
							});

							return {
								...requestCommentsAllIndexStore,
								data: {
									quality_controls: requestCommentsAllIndexStore.data?.quality_controls,
									comments: doubleCheck,
								},
							};
						}
					}),
				);
			}
			// диспатч обновления текущей заявке в глобалСтор
			// biome-ignore lint/correctness/noUnusedVariables: <explanation>
			const patchShowRequestResult = dispatch(
				coreApi.util.updateQueryData("requestsShow", { requestId: Number(requestId) || 0 }, (draft) => {
					draft?.data ? Object.assign(draft.data, response.data) : undefined;
				}),
			);

			// biome-ignore lint/correctness/noUnusedVariables: <explanation>
			const patchIndexRequests = dispatch(
				coreApi.util.updateQueryData("requestsIndex", {}, () => {
					const changeStore = [...(requestsStore.data || [])];
					const newStore = changeStore.map((item: ResourcesRequestIndex) => {
						if (item?.id !== Number(requestId)) return item;

						return {
							...item,
							...response.data,
						};
					});

					return { ...requestsStore, data: newStore };
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};

	// логика дублирования заявки в БД и в кэш
	const handleDuplicateRequest = async (values: CreateRequestModalFormTypes) => {
		try {
			const response = await duplicateRequest({
				requestId: requestData ? Number(requestData.id) : Number(requestId),
				requestsRequestDuplication: prepareFormDataCreateRequest(values),
			}).unwrap();
			dispatchingToRequestsIndexStore(response);
		} catch (e) {
			console.log(e);
		}
	};

	const handleAddModifiedFormFieldsToLocalStorage = (values: { [key: string]: unknown }) => {
		localStorage.setItem("modifiedForm", JSON.stringify(values));
	};

	const handleRemoveModifiedFormFieldsToLocalStorage = () => {
		localStorage.removeItem("modifiedForm");
	};

	const filters = localStorage.getItem("filters") ? JSON.parse(String(localStorage.getItem("filters"))) : null;

	const matchEdit = useMatch("requests/feed/:requestId/edit/:fullcodeId");
	const matchDuplicate = useMatch("requests/feed/:requestId/duplicate/:fullcodeId");
	const matchCreate = useMatch("requests/create");

	const isFeedPage = matchEdit || matchDuplicate || matchCreate;

	useLayoutEffect(() => {
		clearSnacks();
		if (isCreateNewRequestSuccess)
			isFeedPage
				? navigate({
						pathname: REQUESTS,
						search: new URLSearchParams(filters).toString(),
					})
				: navigate("../");
		if (isEditRequestSuccess)
			isFeedPage
				? navigate({
						pathname: REQUESTS,
						search: new URLSearchParams(filters).toString(),
					})
				: navigate("../");
		if (isDuplicateRequestSuccess)
			isFeedPage
				? navigate({
						pathname: REQUESTS,
						search: new URLSearchParams(filters).toString(),
					})
				: navigate("../");

		handleRemoveModifiedFormFieldsToLocalStorage();
	}, [isCreateNewRequestSuccess, isEditRequestSuccess, isDuplicateRequestSuccess]);

	useEffect(() => {
		if (isCreateNewRequestError) {
			addSnack({
				type: "error",
				eventName: "create-request-error",
				text: "Не удалось создать заявку",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => async () => {
					await handleCreateNewRequest(lastSubmittedFormState as CreateRequestModalFormTypes);
				},
			});
		}

		if (isEditRequestError) {
			addSnack({
				type: "error",
				eventName: "edit-request-error",
				text: `Не удалось сохранить заявку ${fullcodeId ? `#${fullcodeId}` : ""}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => async () => {
					await handleEditRequest(lastSubmittedFormState as CreateRequestModalFormTypes);
				},
			});
		}

		if (isDuplicateRequestError) {
			addSnack({
				type: "error",
				eventName: "duplicate-request-error",
				text: `Не удалось дублировать заявку ${fullcodeId ? `#${fullcodeId}` : ""}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => async () => {
					await handleEditRequest(lastSubmittedFormState as CreateRequestModalFormTypes);
				},
			});
		}
	}, [isCreateNewRequestError, isEditRequestError, isDuplicateRequestError]);

	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const checkedAdsValues = (values: CreateRequestModalFormTypes) => {
		if (modalView === "edit" && !requestData?.abilities?.advert) {
			const resultValues = { ...values };
			resultValues.ads = 4261;
			return resultValues;
		}
		return values;
	};

	const handleSubmit = (values: CreateRequestModalFormTypes) => {
		clearSnacks();
		setInitialState(values);
		setLastSubmittedFormState(values);
		const errors = createRequestValidation(values);
		const checkedValues = { ...values };
		// кейс легаси заявок с незаполненым полем реклама
		if (modalView === "edit" && !requestData?.abilities?.advert && !values.ads) {
			checkedValues.ads = 4261;
			errors.ads = undefined;
		}
		if (Object.keys(errors).length > 0) {
			return errors;
		}
		if (modalView === "create") {
			handleCreateNewRequest(values);
		}
		if (modalView === "edit") {
			handleEditRequest(checkedValues);
		}
		if (modalView === "duplicate") {
			handleDuplicateRequest(values);
		}
		handleRemoveModifiedFormFieldsToLocalStorage();
	};
	const [, { isLoading: isChangeStateLaoding }] = useRequestsChangeStateMutation({
		fixedCacheKey: "changeRequestsState",
	});

	const {
		data: serverData,
		isError,
		isFetching: isRequestCardInfoFetching,
		isLoading: isRequestCardInfoLoading,
	} = useRequestsShowQuery(
		{
			requestId: requestData ? Number(requestData.id) : Number(requestId),
		},
		{ skip: modalView === "create" },
	);

	const modalContainerStyles = cn(styles["request-container"], {
		[styles["processing-request"]]: (isRequestCardInfoFetching && !isRequestCardInfoLoading) || isChangeStateLaoding,
	});

	const handleInitialStateOnCreateDuplicateRequest = (
		initialValues?: CreateRequestModalFormTypes | Partial<CreateRequestModalFormTypes>,
	) => {
		if ((modalView === "create" || modalView === "duplicate") && user && user.data) {
			if (lastSubmittedFormState !== null) {
				setInitialState({
					...lastSubmittedFormState,
					dispatcher: {
						id: user.data.id,
						fio: user.data.title,
					},
				});
			} else {
				modalView === "create" &&
					setInitialState({
						...initialValues,
						showToMaster: true,
						saleType: 1,
						typeId: 0,
						dispatcher: {
							id: user.data.id,
							fio: user.data.title,
						},
						location_id: user.data.location_id,
					});

				modalView === "duplicate" &&
					setInitialState({
						...initialValues,
						showToMaster: true,
						saleType: 1,
						typeId: 0,
						ads: 27, // реклама - уже обращались
						dispatcher: {
							id: user.data.id,
							fio: user.data.title,
						},
					});
			}
		}
	};

	useLayoutEffect(() => {
		//Редирект на главную если не разрешена edit
		if (isError) navigate("/");
		if (
			!isRequestCardInfoLoading &&
			!isRequestCardInfoFetching &&
			((!serverData?.data?.abilities?.update && modalView === "edit") ||
				(!serverData?.data?.abilities?.dublication && modalView === "duplicate"))
		) {
			navigate("/");
		}
	}, [serverData, isError, isRequestCardInfoFetching, isRequestCardInfoLoading]);

	useEffect(() => {
		if (modalView === "create" && !user?.data?.permissions?.CAN_REQUEST_ADD) {
			navigate(-2);
		}
		handleInitialStateOnCreateDuplicateRequest(initialValues);
	}, [user]);

	useEffect(() => {
		if (serverData?.data) {
			const { data } = serverData;
			const lastModifiedFormState = localStorage.getItem("modifiedForm");
			const initialState = prepareFormDataEditRequest(data, modalView, lastModifiedFormState);
			modalView === "duplicate"
				? handleInitialStateOnCreateDuplicateRequest(initialState)
				: lastSubmittedFormState !== null
					? setInitialState({ ...lastSubmittedFormState })
					: setInitialState(initialState);
		}
	}, [isRequestCardInfoLoading, serverData?.data]);

	const columnHelper = createColumnHelper<any>();

	const columns = useMemo(
		() => [
			//указываем ключи объекта первым аргументом
			columnHelper.accessor((row) => row.original.fio, {
				id: "master",
				header: () => (
					<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
						Мастер
					</Typography>
				),
				cell: (info) => {
					const masterFio = info.row.original.fio;

					return (
						<Typography
							decoration={"none"}
							tag={4}
							weight={2}
							className={styles["edit-request-modal_page-link"]}
							as={"caption"}
						>
							{`${masterFio}`}
						</Typography>
					);
				},
				footer: (info) => info.column.id,
			}),
			columnHelper.accessor((row) => row.original?.appointed?.appointed_date, {
				id: "selectionDate",
				cell: (info) => info.row.original?.appointed?.appointed_date,
				header: () => (
					<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
						Дата подбора
					</Typography>
				),
				footer: (info) => info.column.id,
			}),
			columnHelper.accessor((row) => row.original?.appointed?.appointed_fio, {
				id: "dispatcher",
				header: () => (
					<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
						Диспетчер
					</Typography>
				),
				cell: (info) => info.row.original?.appointed?.appointed_fio,
				footer: (info) => info.column.id,
			}),
		],
		[],
	);

	const getModifiedFields = ({
		modifiedFormValues,
		formValues,
	}: {
		modifiedFormValues: { [key: string]: boolean };
		formValues: { [key: string]: unknown };
	}) => {
		const modifiedForm = localStorage.getItem("modifiedForm");

		let result = modifiedForm ? { ...JSON.parse(modifiedForm) } : {};

		for (const key in modifiedFormValues) {
			if (modifiedFormValues[key] === true) {
				result = {
					...result,
					[key]: formValues[key],
				};
			}
		}

		return {
			length: Object.keys(result).length,
			fields: result,
		};
	};

	return (
		<>
			<Form
				subscription={{ values: true }}
				onSubmit={handleSubmit}
				initialValues={initialState}
				mutators={{
					setStatus: async (args, state, utils) => {
						if (args.length) {
							utils.changeValue(state, "status", () => args[0]);
						}
					},
					setUserInfo: (args, state, utils) => {
						if (args.length) {
							utils.changeValue(state, "clientFio", () => args[0].fio);
							utils.changeValue(state, "districtId", () => args[0].address?.district_id);
							utils.changeValue(state, "street", () => args[0].address?.street);
							utils.changeValue(state, "house", () => args[0].address?.house);
							utils.changeValue(state, "housing", () => args[0].address?.korp);
							utils.changeValue(state, "entrance", () => args[0].address?.entrance);
							utils.changeValue(state, "apartment", () => args[0].address?.flat);
							!!args[0].address?.metro_id && utils.changeValue(state, "metroStation", () => args[0].address?.metro_id);
						}
					},
					setUrInfo: (args, state, utils) => {
						if (args[0].company) {
							utils.changeValue(state, "organization", () => args[0].company?.ur_org || "");
							utils.changeValue(state, "legalEntityClientFio", () => args[0].company?.ur_fio || "");
						}
					},
						changePrepay: (args, state, utils) => {
						if (validateOnlyNumbers(args[0])) {
							utils.changeValue(state, "prepayment", () => args[0]);
						}
					},
					changeSum: (args, state, utils) => {
						if (validateOnlyNumbers(args[0])) {
							utils.changeValue(state, "summ", () => args[0]);
						}
					},
					changeAccountNumber: (args, state, utils) => {
						if (validateOnlyNumbers(args[0])) {
							utils.changeValue(state, "accountPaymentNumber", () => args[0]);
						}
					},
					changePaymentOrder: (args, state, utils) => {
						if (validateOnlyNumbers(args[0])) {
							utils.changeValue(state, "paymentOrder", () => args[0]);
						}
					},
					changeStreet: (args, state, utils) => {
						if (args.length) {
							if (!args[0]) {
								utils.changeValue(state, "street", () => "");
							} else {
								const addressData = args[0];
								if (addressData.data) {
									utils.changeValue(state, "street", () => addressData.data?.street);
								} else {
									utils.changeValue(state, "street", () => addressData);
								}
							}
						}
					},
					changeHouse: (args, state, utils) => {
						if (args.length) {
							if (!args[0]) {
								utils.changeValue(state, "house", () => "");
								utils.changeValue(state, "housing", () => "");
							} else {
								if (args[0].data) {
									utils.changeValue(state, "house", () => args[0].data?.house);
									if (args[0].data?.block) {
										utils.changeValue(state, "housing", () => args[0].data?.block);
									}
								} else {
									utils.changeValue(state, "house", () => args[0]);
								}
							}
						}
					},
					changePhone: (args, state, utils) => {
						if (args[0]?.[0]) {
							utils.changeValue(state, "phone2", () => args[0][0].phone);
						} else {
							utils.changeValue(state, "phone2", () => "");
						}
						if (args[0]?.[1]) {
							utils.changeValue(state, "phone3", () => args[0][1].phone);
						} else {
							utils.changeValue(state, "phone3", () => "");
						}
					},
					changeAttachments: (args, state, utils) => {
						if (args[0] && !args[1]) {
							utils.changeValue(state, "attachments", () => args[0]);
						} else if (args[0] && args[1]) {
							utils.changeValue(state, "attachmentsOrigin", () => args[0]);
						}
					},
				}}
			>
				{({ form }) => (
					<div className={styles["create-request-modal"]}>
						<FormSpy
							subscription={{
								values: true,
								modified: true,
							}}
							onChange={(props) => {
								const modifiedFields = getModifiedFields({
									formValues: props.values,
									modifiedFormValues: props.modified as {
										[key: string]: boolean;
									},
								});
								modifiedFields.length > 0 ? handleAddModifiedFormFieldsToLocalStorage(modifiedFields.fields) : null;
							}}
						/>
						<ModalContainer
							className={styles["create-request-modal"]}
							zIndex={1}
							onClose={
								onClose
									? onClose
									: onClosePath
										? () => {
												isFeedPage
													? navigate({
															pathname: REQUESTS,
															search: new URLSearchParams(filters).toString(),
														})
													: navigate(onClosePath);
												clearSnacks();
												handleRemoveModifiedFormFieldsToLocalStorage();
											}
										: () => {
												handleRemoveModifiedFormFieldsToLocalStorage();
												navigate("/");
											}
							}
							showBlackout
							closeOnBlackoutClick={closeOnBlackoutClick}
						>
							<ModalHeader
								showRightContent
								showLeftContent
								contentLeft={
									<CreateRequestModalLeftContent
										showBackButton={false}
										title={modalView === "create" ? "" : fullcodeId ? `#${fullcodeId}` : ""}
										subtitle={subtitle}
										onHandleBackButton={onHandleBackButton}
									/>
								}
								contentRight={
									<CreateRequestModalRightContent
										modalView={modalView}
										isRightActionButtonDisabled={isRightActionButtonDisabled}
										isLoading={isCreateNewRequestLoading || isEditRequestLoading || isDuplicateRequestLoading}
										onHandleRightAction={() => {
											form.submit();
										}}
										// onHandleRightAction={() =>
										//   handleSubmit(form.getState().values)
										// }
									/>
								}
								showCloseButton
								onClose={
									onClose
										? onClose
										: onClosePath
											? () => {
													isFeedPage
														? navigate({
																pathname: REQUESTS,
																search: new URLSearchParams(filters).toString(),
															})
														: navigate(onClosePath);
													clearSnacks();
													handleRemoveModifiedFormFieldsToLocalStorage();
												}
											: () => {
													navigate("/");
													handleRemoveModifiedFormFieldsToLocalStorage();
												}
								}
							/>
							<ModalBody className={modalContainerStyles}>
								{isRequestCardInfoLoading || isEditRequestLoading || (user && !user?.data?.permissions?.CAN_REQUEST_ADD) ? (
									//user?.data?.permissions?.CAN_REQUEST_ADD добавил, будет вызываться только на модалке создания и если
									//у юзера нет пермишенов будет значек лоудера до момента пока сработает useeffect
									//is loading не подойдет, потому что мы берем стейт у этого query
									//ибо сам запрос идет в компоненте логина миддлвейр выше
									//и мы фактически используем кешированное значение
									<ContentLoader />
								) : (
									<div className={styles["create-request-modal-content"]}>
										<CommonCreateEditForm
											mutators={form.mutators}
											modalView={modalView}
											requestData={serverData ? serverData.data : {}}
										/>
										<div className={styles["create-request-modal-masters"]}>
											<Title as={"h5"}>Мастера</Title>
											<div className={styles["create-request-modal-btns"]}>
												<Field<CreateRequestModalFormTypes["selectedMasters"]>
													name={CreateRequestNames.selectedMasters}
													type={"text"}
													showLabel
													modalView={modalView}
													requestId={requestData ? Number(requestData.id) : Number(requestId)}
													title={
														form.getState().values?.commonSelectedMasters?.length
															? "Изменить подобранных мастеров"
															: "Подобрать мастера"
													}
													component={RequestModalMasterSelectionButton}
												/>
												<Field<CreateRequestModalFormTypes["autoSelection"]>
													name={CreateRequestNames.autoSelection}
													type={"checkbox"}
													label={CreateRequestLabels.autoSelection}
													showLabel
													disabled={cancelType.find((item) => item.id === serverData?.data?.state_id)}
													component={AutoSelectionSwitcher}
												/>
											</div>
											<TableMastersSelection
												modalView={modalView}
												isLoading={isRequestCardInfoLoading}
												columns={columns}
											/>
										</div>
										<div className={styles["create-request-modal-comments"]}>
											<Title as={"h5"}>Файлы</Title>
											<Field<CreateRequestModalFormTypes["attachments"]>
												name={CreateRequestNames.attachments}
												modalView={modalView}
												onChange={form.mutators.changeAttachments}
												component={RequestModalFileInput}
											/>
										</div>
										<div className={styles["create-request-modal-comments"]}>
											<Title as={"h5"}>Комментарии</Title>
											<div className={styles["create-request-modal-comments-block"]}>
												<Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
													<Field<CreateRequestModalFormTypes["comment"]>
														name={CreateRequestNames.comment}
														placeholder={"Ваш комментарий"}
														validate={composeValidators(
															firstSymbolCantBeSpaceValidator,
															commentOnlySpecialSymbolsValidator,
														)}
														type={"text"}
														component={RequestModalTextInput}
													/>
												</Col>
												<Field<boolean> name={"showToMaster"} type={"checkbox"}>
													{({ input, meta }) => {
														return (
															<Checkbox
																state={getInputState(meta, input)}
																label={"Показывать комментарий мастеру"}
																showLabel
																{...input}
															/>
														);
													}}
												</Field>
											</div>
										</div>
									</div>
								)}
							</ModalBody>
							<Outlet />
							{window.enableFormLogger === true && (
								<div
									style={{
										padding: "16px",
										borderRadius: "16px",
										border: "1px solid black",
										position: "absolute",
										right: 0,
										maxWidth: 368,
										top: 0,
										maxHeight: 800,
										overflowY: "auto",
										background: "var(--surface-front)",
									}}
								>
									<Row>
										<Col>
											<pre>
												{
													// @ts-ignore
													JSON.stringify(form.getState().values, 0, 2)
												}
											</pre>
										</Col>
									</Row>
								</div>
							)}
						</ModalContainer>
					</div>
				)}
			</Form>
		</>
	);
};
