import ReactDOM from 'react-dom/client'
import './variables.scss'
import './font/inter/inter.css'
import './font/icons/style.scss'
import './font/icons/icon-sizes.scss'
import './index.css'
import reportWebVitals from './reportWebVitals'
import ErrorBoundary from '@components/error-boundary'
import { router } from './routes'
import { PersistGate } from 'redux-persist/integration/react'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from '@/state/store'
import { SnackbarContainer } from '@components/snackbar'
import { AudioTrackPlayer } from './components/audio-track/audio-track-player'

setConfiguration({
  breakpoints: [576, 768, 992, 1340, 1800],
  containerWidths: [540, 740, 960, 1600, 1780],
  maxScreenClass: 'xxxl',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <ScreenClassProvider>
          <RouterProvider router={router} />
          <SnackbarContainer />
          <AudioTrackPlayer />
        </ScreenClassProvider>
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
)

reportWebVitals()
