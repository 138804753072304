import { Typography } from '@/components/common'
import { getColorByPaymentStatus } from '@/utils/get-color-by-payment-status'

export const TypographyHoc = ({ children, row }: { children: string; row?: any }) => {
  const color = getColorByPaymentStatus(row)
  const weight = color === '#0D6B23' || color === '#951A20' ? 1 : 2

  return (
    <Typography as={'caption'} decoration={'none'} tag={4} weight={weight} style={{ color }}>
      {children}
    </Typography>
  )
}
