import React, { useEffect, useLayoutEffect } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router'
import { SelectionOfMastersModal } from '../selection-of-masters'
import { NeedManualSelectionProps } from './need-manual-selection.types'
import { useRequestsShowQuery } from '@/state/api'

export const NeedManualSelection = ({
  fullcode,
  onClose,
  request,
  onClosePath,
}: NeedManualSelectionProps) => {
  const navigate = useNavigate()
  const { requestId, fullcodeId } = useParams()
  const { data, error, isLoading, isError } = useRequestsShowQuery({
    requestId: Number(requestId) || Number(request),
  })
  const initialValues = {
    masterSelectionFilter: 'optimal',
  }
  useEffect(() => {
    if (isError) navigate('../../')
    if (data && !data?.data?.abilities?.select_any_master) {
      navigate('../../')
    }
  }, [data, isError])

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {
        onClose ? onClose() : navigate(-1)
      }}
    >
      {({ form }) => (
        <>
          <SelectionOfMastersModal
            onClosePath={onClosePath}
            modalView={'manual-selection'}
            onClose={onClose}
            onHandleBackButton={() => (onClose ? onClose() : navigate(-1))}
            title={'Подбор мастеров'}
            subtitle={`Заявка #${fullcode || fullcodeId}`}
            requestId={request || requestId}
            enableMultiRowSelection={true}
          />
        </>
      )}
    </Form>
  )
}
