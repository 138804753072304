import { createMachine } from "xstate";
const initialState = {
  isConnectionEstablished: false,
};

const prepareInitialContext = () => {
  const persistState = JSON.parse(
    localStorage.getItem("phoner-connection-machine") || "null"
  );
  if (!persistState) return initialState;
  return { ...persistState.context };
};
export const phonerConnectionMachine = createMachine(
  {
    id: "connection",
    initial: "idle",
    context: prepareInitialContext(),
    states: {
      idle: {
        on: {
          isConnectionEstablished: {
            actions: {
              type: "setConnection",
            },
          },
        },
      },
    },
    schema: {
      events: {} as {
        type: "isConnectionEstablished";
        value: boolean;
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },

  {
    actions: {
      setConnection: (context, event) => {
        if (event.type === "isConnectionEstablished") {
          context.isConnectionEstablished = event.value;
        }
      },
    },
  }
);
