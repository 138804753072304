import { useAttachmentsDestroyMutation, useRequestsAttachmentsIndexQuery } from "@/state/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { AlertModal } from "../alert-modal";
import { addSnack, clearSnacks } from "@/eventbus/events/snackbar";

export const DeleteFile = () => {
	const { attachmentId, requestId } = useParams();
	const navigate = useNavigate();
	const [deleteFile, { isLoading, isError: isDeleteFileError, isSuccess: isDeleteFileSuccess }] = useAttachmentsDestroyMutation();
	const [lastSubmittedState, setLastSubmittedState] = useState<number>();
	const { data: files, isLoading: loadFiles } = useRequestsAttachmentsIndexQuery({
		requestId: Number(requestId),
	});

	useEffect(() => {
		if (files) {
			const foundedFile = files.data?.filter((file) => file.id === Number(attachmentId));
			if (!foundedFile?.length || !foundedFile[0]?.can_del) navigate("../../");
		}
	}, [files]);

	const onClick = (attachmentId: number) => {
		clearSnacks();
		setLastSubmittedState(attachmentId);
		deleteFile({
			attachmentId: attachmentId,
		});
	};
	// .unwrap()
	// .then(() => navigate(-1))
	// .catch((e) => navigate(-1))

	useEffect(() => {
		if (isDeleteFileSuccess) {
			navigate("../");
		}
		if (isDeleteFileError && lastSubmittedState) {
			addSnack({
				type: "error",
				eventName: "delete-file-error",
				text: "Не удалось удалить файл.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => onClick(lastSubmittedState),
			});
		}
	}, [isDeleteFileError, isDeleteFileSuccess]);

	return (
		<AlertModal
			conentLoading={loadFiles}
			onClick={() => onClick(Number(attachmentId))}
			isLoading={isLoading}
			title={"Вы хотите удалить выбранный файл?"}
			showBackButton={false}
		/>
	);
};
