import { getDeviceWidthObj } from '@utils/get-device-width-obj'
export const deviceInitialState: DeviceInitialStateType = getDeviceWidthObj(
  document.body.clientWidth,
)

interface DeviceInitialStateType {
  isMobile: boolean
  isDesktop: boolean
  isLargeDesktop: boolean
  isMobileOnly?: boolean
  isTablet?: boolean
  screenSize?: string
}
