import { AnyObject } from '@global-types/utils-type'

export const removeValuesFromObjectByKey = <
  T extends AnyObject | null | undefined,
  V extends AnyObject | null | undefined,
>(
  object: T,
  values: string[] | string,
): V => {
  //@ts-ignore
  if (!object) return object
  const obj = { ...object }
  if (values instanceof Array) {
    values.forEach((value) => {
      delete obj[value]
    })
  } else {
    delete obj[values]
  }
  //@ts-ignore
  return obj
}
