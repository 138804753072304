import React from 'react'
import { UISizes } from '@/global-types/mods.types'
import { type ButtonProps, ButtonTypes } from './button.types'
import { LoadingSpinner } from './loading-spinner/loading-spinner'
import styles from './button.module.scss'
import { buttonChildrenClassNames, buttonClassNames, buttonContentContainerClassNames } from './utils'

export const Button = React.memo(
  React.forwardRef<any, ButtonProps>(
    (
      {
        children,
        iconLeft,
        iconRight,
        disabled = false,
        type = ButtonTypes.primary,
        size = UISizes.medium,
        // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
        onClick = () => {},
        isLoading = false,
        fullWidth = false,
        isActive = false,
        className,
        contentPosition,
        btnType,
        href,
        squared = false,
        wrapText = false,
        overflowText = 'inherit',
        ...props
      },
      ref,
    ) => {
      return href ? (
        <a
          href={!disabled ? href : '#'}
          className={`
            ${buttonClassNames(type, size, isActive, fullWidth, !children || squared)}
            ${styles["button-link"]}
            ${className ? className : ''}
          `}
          {...props}>
          <div className={buttonContentContainerClassNames(contentPosition, isLoading)}>
            {iconLeft}
            {children ? <div className={buttonChildrenClassNames(wrapText, overflowText)}>{children}</div> : null}
            {iconRight}
          </div>
        </a>
      ) : (
        <button
          ref={ref}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => !disabled && onClick(e)}
          disabled={disabled}
          type={btnType}
          className={`
            ${buttonClassNames(type, size, isActive, fullWidth, !children || squared)}
            ${className ? className : ''}
          `}
          {...props}>
          <div className={buttonContentContainerClassNames(contentPosition, isLoading)}>
            {iconLeft}
            {children ? <div className={buttonChildrenClassNames(wrapText, overflowText)}>{children}</div> : null}
            {iconRight}
          </div>
          {isLoading && (
            <div className={styles['button-spinner-container']}>
              <LoadingSpinner className={styles['button-spinner']} />
            </div>
          )}
        </button>
      )
    },
  ),
)
