import { usePauseSetMutation, usePauseStatusQuery, usePauseUnsetMutation } from "@/state/api";
import type { ButtonSwitchProps } from "@common/button-switch-container/button-switch.types";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { ButtonSwitchContainer } from "@components/common";
import { UISizes } from "@global-types/mods.types";
import { useEffect } from "react";

export const HeaderCallSwitcher = ({ showLabel = false, label = "" }: Partial<ButtonSwitchProps>) => {
	const { data, isLoading, refetch } = usePauseStatusQuery();

	const [setPause, { isLoading: isLoadingSet }] = usePauseSetMutation();
	const [unsetPause, { isLoading: isLoadingUnet }] = usePauseUnsetMutation();
	const isPaused = data?.data?.is_paused;

	const setPauseStatus = (isPaused: boolean) => {
		if (isPaused) {
			setPause();
		} else {
			unsetPause();
		}
	};
	const handler = () => {
		if (!document.hidden) {
			refetch();
		}
	};

	useEffect(() => {
		window.onfocus = handler;
		return () => window.removeEventListener("onfocus", handler);
	}, []);

	useEffect(() => {
		if (!document.hidden) {
			refetch();
		}
		document.addEventListener("visibilitychange", handler);
		return () => {
			document.removeEventListener("visibilitychange", handler);
		};
	}, []);

	return (
		<ButtonSwitchContainer showLabel={showLabel} label={label}>
			{isLoading || isLoadingSet || isLoadingUnet ? (
				<>
					<Button
						isActive={false}
						btnType={"button"}
						disabled={true}
						type={ButtonTypes.secondary}
						size={UISizes.medium}
						isLoading={true}
					/>
					<Button isActive={false} btnType={"button"} disabled={true} type={ButtonTypes.secondary} size={UISizes.medium} />
				</>
			) : (
				<>
					<Button
						isActive={!isPaused}
						btnType={"button"}
						disabled={!isPaused}
						type={ButtonTypes.secondary}
						size={UISizes.medium}
						iconLeft={<i className={"icon icon-phone"} />}
						onClick={() => {
							setPauseStatus(false);
						}}
					/>
					<Button
						btnType={"button"}
						isActive={!!isPaused}
						disabled={!!isPaused}
						type={ButtonTypes.secondary}
						size={UISizes.medium}
						iconLeft={<i className={"icon icon-phone-off"} />}
						onClick={() => {
							setPauseStatus(true);
						}}
					/>
				</>
			)}
		</ButtonSwitchContainer>
	);
};
