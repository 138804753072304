import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { MetricsSidebarModalProps } from './metrics-sidebar-modal.types'

import { MetricsSidebarModalLeftContent } from './left-content'

import { addSnack } from '@/eventbus/events/snackbar'
import { ContentLoader } from '@/components/content-loader'
import { ModalBody, ModalHeader } from '@/components/common/modal-container'
import { ModalContainer, NoData } from '@/components/common'
import { MissedCallsContent } from './missed-calls-content'
import { AutoanswerContent } from './autoanswer-content'
import { useStatisticsIndexQuery } from '@/state/api'

import NoAudioImage from '@/assets/svg/phone-nodata.svg'

import styles from './metrics-sidebar-modal.module.scss'

export const MetricsSidebarModal = ({
  modalViewSidebar,
  onClosePath,
  onClose,
  subtitle,
  onHandleBackButton,
  closeOnBlackoutClick,
  isRightActionButtonDisabled = false,
}: MetricsSidebarModalProps) => {
  const { data: requestData, isLoading, isSuccess, isError, error } = useStatisticsIndexQuery()

  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-call-records-error',
        text: 'Не удалось загрузить записи.',
        withAction: false,
      })
    }
  }, [isError])

  return (
    <>
      <ModalContainer
        className={styles['mitrics-sidebar-modal']}
        onClose={
          onClose
            ? onClose
            : onClosePath
            ? () => {
                navigate(onClosePath)
              }
            : () => {
                navigate('/')
              }
        }
        showBlackout
        closeOnBlackoutClick={closeOnBlackoutClick}>
        <ModalHeader
          className={styles['mitrics-sidebar-modal-header']}
          showRightContent
          showLeftContent
          contentLeft={<MetricsSidebarModalLeftContent title={subtitle} />}
          showCloseButton
          onClose={
            onClose
              ? onClose
              : onClosePath
              ? () => {
                  navigate(onClosePath)
                }
              : () => {
                  navigate('/')
                }
          }
        />
        <ModalBody className={styles['mitrics-sidebar-modal-container']}>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <>
              {modalViewSidebar === 'missed' &&
                isSuccess &&
                requestData?.data?.missing_calls &&
                requestData?.data?.missing_calls?.length > 0 && (
                  <div className={styles['mitrics-sidebar-modal-content']}>
                    <MissedCallsContent missingCalls={requestData?.data?.missing_calls} />
                  </div>
                )}
              {modalViewSidebar === 'missed' &&
                isSuccess &&
                requestData?.data?.missing_calls &&
                requestData?.data?.missing_calls?.length === 0 && (
                  <NoData src={NoAudioImage} alt="Нет пропущенных звонков" title="Нет пропущенных звонков" />
                )}
              {modalViewSidebar === 'autoanswer' &&
                isSuccess &&
                requestData?.data?.answer_phone &&
                requestData?.data?.answer_phone?.length > 0 && (
                  <div className={styles['mitrics-sidebar-modal-content']}>
                    <AutoanswerContent autoAnswer={requestData?.data?.answer_phone} isSuccess={isSuccess} />
                  </div>
                )}
              {modalViewSidebar === 'autoanswer' &&
                isSuccess &&
                requestData?.data?.answer_phone &&
                requestData?.data?.answer_phone?.length === 0 && (
                  <NoData src={NoAudioImage} alt="Нет записей автоответчика" title="Нет записей автоответчика" />
                )}
            </>
          )}
        </ModalBody>
      </ModalContainer>
    </>
  )
}
