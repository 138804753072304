import type {
  ConnectingEvent,
  EndEvent,
  HoldEvent,
  IceCandidateEvent,
  IncomingAckEvent,
  IncomingEvent,
  OutgoingAckEvent,
  OutgoingEvent,
  RTCSession,
} from "jssip/lib/RTCSession";
import type { Dispatch, SetStateAction } from "react";
import type { Sender } from "xstate";
import type { CallInfo } from "../../../../../app/machines/phoner/types/call-related-types.types";
import {
  DECLINE_CURRENT_CALL,
  DECLINE_INCOMING_CALL,
  DECLINE_SECOND_LINE_CALL,
  SECOND_LINE_CALL_ANSWERED,
  UPDATE_SESSION_STATUS,
} from "../../../../event-bus/types/event-action.types";
import type { SharedWorkerMessage } from "../../../shared-worker/shared-worker";
import {
  type SessionDTO,
  type SessionContext,
  SessionStatus,
} from "../../../tab-session-context/tab-session-context";

export const callConnectingEventHandler = (
  e: ConnectingEvent,
  session: RTCSession,
  sendMessage: (data: SharedWorkerMessage) => void,
  sessionContext: SessionDTO[],
  setSessionsDTO: Dispatch<SetStateAction<SessionContext>>
) => {
  console.info("connectingEvent", e);

  setSessionsDTO((prevSessions) =>
    prevSessions.map((contextSession) =>
      contextSession.sessionId === session.id
        ? { ...contextSession, status: SessionStatus.CONNECTING }
        : contextSession
    )
  );

  const contextSession = sessionContext.find(
    (ses) => ses.sessionId === session.id
  );

  if (contextSession) {
    sendMessage({
      eventName: UPDATE_SESSION_STATUS,
      eventPayload: { ...contextSession, status: SessionStatus.CONNECTING },
    });
  }
};

export const callAcceptEventHandler = (
  e: OutgoingEvent | IncomingEvent,
  stopSound: () => void,
  session: RTCSession,
  sendMessage: (data: SharedWorkerMessage) => void,
  sessionContext: SessionDTO[],
  setSessionsDTO: Dispatch<SetStateAction<SessionContext>>
) => {
  console.info("acceptEvent", e);

  stopSound();

  const contextSession = sessionContext.find(
    (ses) => ses.sessionId === session.id
  );

  setSessionsDTO((prevSessions) =>
    prevSessions.map((contextSession) =>
      contextSession.sessionId === session.id
        ? { ...contextSession, status: SessionStatus.ACCEPTED }
        : contextSession
    )
  );

  if (contextSession) {
    sendMessage({
      eventName: UPDATE_SESSION_STATUS,
      eventPayload: { ...contextSession, status: SessionStatus.ACCEPTED },
    });
  }
};

export const iceCandidateCallHandler = (e: IceCandidateEvent) => {
  e.ready();
};

export const callConfirmEventHandler = (
  e: IncomingAckEvent | OutgoingAckEvent,
  playSound: (sound: string, loop: boolean) => void,
  callAnsweredMelody: string,
  remoteAudioControl: HTMLAudioElement | null,
  session: RTCSession,
  secondLineOutcomingConnection: boolean,
  setCurrentStream: (value: SetStateAction<MediaStream | null>) => void,
  setIncomingCallEvent: Sender<{ type: "success" }>,
  setSecondLineEvent: Sender<{ type: "success" }>,
  setSessions: Dispatch<SetStateAction<RTCSession[]>>,
  sendMessage: (data: SharedWorkerMessage) => void,
  sessionContext: SessionDTO[],
  setSessionsDTO: Dispatch<SetStateAction<SessionContext>>
) => {
  console.info("confirmedEvent", e);

  playSound(callAnsweredMelody, false);
  const remoteStreamTrack = session?.connection?.getReceivers()[0]?.track;

  if (!remoteStreamTrack) return;

  const remoteStream = new MediaStream([remoteStreamTrack]);
  setCurrentStream(remoteStream);

  const startedTime = Date.now();

  if (remoteAudioControl) {
    remoteAudioControl.srcObject = remoteStream;
    remoteAudioControl.muted = false;

    remoteAudioControl.onloadeddata = () => {
      remoteAudioControl.play();
    };
  }

  if (secondLineOutcomingConnection) {
    setSecondLineEvent("success");

    const targetSession = sessionContext.find(
      (contextSession) => contextSession.sessionId === session.id
    );

    setSessionsDTO((prevSessions) =>
      prevSessions.map((contextSession) =>
        contextSession.sessionId === session.id
          ? {
              ...contextSession,
              startedAt: startedTime,
              status: SessionStatus.STARTED_CONVERSATION,
              isConnected: true,
            }
          : contextSession
      )
    );

    sendMessage({
      eventName: SECOND_LINE_CALL_ANSWERED,
      eventPayload: {
        ...targetSession,
        startedAt: startedTime,
        status: SessionStatus.STARTED_CONVERSATION,
        isConnected: true,
      },
    });
    return;
  }

  setIncomingCallEvent("success");
  setSessions((prev) => prev);

  const targetSession = sessionContext.find(
    (contextSession) => contextSession.sessionId === session.id
  );

  setSessionsDTO((prevSessions) =>
    prevSessions.map((contextSession) =>
      contextSession.sessionId === session.id
        ? {
            ...contextSession,
            startedAt: startedTime,
            status: SessionStatus.STARTED_CONVERSATION,
            isConnected: true,
          }
        : contextSession
    )
  );

  sendMessage({
    eventName: UPDATE_SESSION_STATUS,
    eventPayload: {
      ...targetSession,
      startedAt: startedTime,
      status: SessionStatus.STARTED_CONVERSATION,
      isConnected: true,
    },
  });
};

export const endedCallEventHandler = (
  endEvent: EndEvent,
  stopSound: () => void,
  rejectSound: string,
  firstLineConnected: boolean,
  secondLineOutcomingConnected: boolean,
  secondLineOutcomingConnecting: boolean,
  secondLineOutcomingOnHold: boolean,
  session: RTCSession,
  playSound: (sound: string, loop: boolean) => void,
  setActiveSession: Dispatch<SetStateAction<RTCSession | null>>,
  sessions: RTCSession[],
  setSessions: Dispatch<SetStateAction<RTCSession[]>>,
  setIncomingCallEvent: Sender<
    | {
        type: "switch lines";
        callInfo: CallInfo;
        session: RTCSession | null;
      }
    | {
        type: "decline";
      }
    | {
        type: "setNewIncomingCallInfo";
        callInfo: CallInfo;
      }
  >,
  setSecondLineEvent: Sender<
    { type: "switch lines" } | { type: "decline" } | { type: "error" }
  >,
  setPhonerTrey: Sender<{ type: "hover"; isHovered: false }>,
  sendMessage: (data: SharedWorkerMessage) => void,
  setSessionsDTO: Dispatch<SetStateAction<SessionContext>>,
  setCurrentStream: (value: SetStateAction<MediaStream | null>) => void,
  remoteAudioControl: HTMLAudioElement | null,
  sessionContext: SessionContext
) => {
  console.info("endEvent", endEvent);
  const sessionId = session.id;

  const activeSession = sessions.find((session) => session.id !== sessionId);

  const contextActiveSession = sessionContext.find(
    (session) => session.sessionId !== sessionId
  );

  if (session.data.successTwoLinesConnection) {
    setTimeout(() => {
      setSessions((prev) => prev.filter((item) => item.id !== session.id));
    }, 300);

    return;
  }

  stopSound();
  playSound(rejectSound, false);

  if (
    secondLineOutcomingConnected ||
    secondLineOutcomingConnecting ||
    secondLineOutcomingOnHold
  ) {
    setSecondLineEvent("decline");

    setSessionsDTO((prevSessions) =>
      prevSessions
        .filter((session) => session.sessionId !== sessionId)
        .map((session) => ({
          ...session,
          isMuted: false,
          status:
            session.status === SessionStatus.HOLD
              ? SessionStatus.STARTED_CONVERSATION
              : session.status,
        }))
    );

    sendMessage({
      eventName: DECLINE_SECOND_LINE_CALL,
      eventPayload: { sessionId },
    });

    setSessions((prev) => {
      prev.find((item) => item.id !== session.id)?.unhold();
      prev.find((item) => item.id !== session.id)?.unmute();
      return prev.filter((item) => item.id !== session.id);
    });
    if (activeSession) {
      activeSession.unhold();
      activeSession.unmute();

      const remoteStreamTrack =
        activeSession.connection.getReceivers()[0]?.track;

      if (!remoteStreamTrack) return;

      const remoteStream = new MediaStream([remoteStreamTrack]);
      setCurrentStream(remoteStream);

      if (remoteAudioControl) {
        remoteAudioControl.srcObject = remoteStream;
        remoteAudioControl.muted = false;
        remoteAudioControl.onloadeddata = () => {
          remoteAudioControl.play();
        };
      }
      setActiveSession(activeSession || null);
    }

    setIncomingCallEvent({
      type: "setNewIncomingCallInfo",
      callInfo: {
        id: activeSession?.id || "",
        phoneNumber: contextActiveSession?.phoneNumber || "",
      },
    });

    return;
  }

  if (firstLineConnected) {
    setIncomingCallEvent("decline");
    setPhonerTrey({ type: "hover", isHovered: false });
    setSessions([]);
    setActiveSession(null);
    setSessionsDTO((prevSessions) =>
      prevSessions.filter(
        (sessionContext) => sessionContext.sessionId !== session.id
      )
    );

    sendMessage({
      eventName: DECLINE_CURRENT_CALL,
      eventPayload: { sessionId },
    });

    return;
  }

  setSessions((prev) => prev.filter((item) => item.id !== session.id));

  setSessionsDTO((prevSessions) =>
    prevSessions.filter(
      (sessionContext) => sessionContext.sessionId !== session.id
    )
  );

  sendMessage({
    eventName: DECLINE_INCOMING_CALL,
    eventPayload: { sessionId },
  });
};

export const failedCallEventHandler = (
  endEvent: EndEvent,
  stopSound: () => void,
  rejectSound: string,
  playSound: (sound: string, loop: boolean) => void,
  secondLineOutcomingConnection: boolean,
  setSecondLineEvent: Sender<{ type: "decline" }>,
  setIncomingCallEvent: Sender<
    { type: "decline" } | { type: "setNewIncomingCallInfo"; callInfo: CallInfo }
  >,
  session: RTCSession,
  setActiveSession: Dispatch<SetStateAction<RTCSession | null>>,
  setSessions: Dispatch<SetStateAction<RTCSession[]>>,
  sendMessage: (data: SharedWorkerMessage) => void,
  setSessionsDTO: Dispatch<SetStateAction<SessionContext>>,
  sessions: RTCSession[],
  setCurrentStream: (value: SetStateAction<MediaStream | null>) => void,
  remoteAudioControl: HTMLAudioElement | null,
  sessionContext: SessionContext
) => {
  console.error("error", endEvent);
  const sessionId = session.id;
  const activeSession = sessions.find((session) => session.id !== sessionId);

  const contextActiveSession = sessionContext.find(
    (session) => session.sessionId !== sessionId
  );

  stopSound();
  playSound(rejectSound, false);

  if (secondLineOutcomingConnection) {
    setSessionsDTO((prevSessions) =>
      prevSessions
        .filter((session) => session.sessionId !== sessionId)
        .map((session) => ({
          ...session,
          isMuted: false,
          status:
            session.status === SessionStatus.HOLD
              ? SessionStatus.STARTED_CONVERSATION
              : session.status,
        }))
    );

    setSecondLineEvent("decline");

    setSessions((prev) => {
      prev.find((item) => item.id !== session.id)?.unhold();
      prev.find((item) => item.id !== session.id)?.unmute();
      return prev.filter((item) => item.id !== session.id);
    });

    sendMessage({
      eventName: DECLINE_SECOND_LINE_CALL,
      eventPayload: { sessionId },
    });

    if (activeSession) {
      activeSession.unhold();
      activeSession.unmute();

      const remoteStreamTrack =
        activeSession.connection.getReceivers()[0]?.track;

      if (!remoteStreamTrack) return;

      const remoteStream = new MediaStream([remoteStreamTrack]);
      setCurrentStream(remoteStream);

      if (remoteAudioControl) {
        remoteAudioControl.srcObject = remoteStream;
        remoteAudioControl.muted = false;

        remoteAudioControl.onloadeddata = () => {
          remoteAudioControl.play();
        };
      }
      setActiveSession(activeSession || null);
    }

    setIncomingCallEvent({
      type: "setNewIncomingCallInfo",
      callInfo: {
        id: activeSession?.id || "",
        phoneNumber: contextActiveSession?.phoneNumber || "",
      },
    });

    return;
  }

  setIncomingCallEvent({
    type: "decline",
  });

  setSessionsDTO((prevSessions) =>
    prevSessions.filter(
      (sessionContext) => sessionContext.sessionId !== session.id
    )
  );

  setActiveSession(null);
  setSessions([]);

  sendMessage({
    eventName: DECLINE_INCOMING_CALL,
    eventPayload: { sessionId },
  });

  sendMessage({
    eventName: DECLINE_CURRENT_CALL,
    eventPayload: { sessionId },
  });
};

export const holdEventHandler = (holdEvent: HoldEvent) => {
  console.info("holdEvent", holdEvent);
};

export const unHoldEventHandler = (holdEvent: HoldEvent) => {
  console.info("holdEvent", holdEvent);
};
