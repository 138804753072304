import { PhoneActionsDropdownProps } from './phone-ations-dropdown.types'
import { Tooltip, Typography } from '@components/common'
import styles from './phone-actions-dropdown.module.scss'
import { convertPhone } from '@utils/convert-phone'
import cn from 'classnames'
import { useComponentVisible } from '@/hooks'

export const PhoneActionsDropdown = ({
  children,
  phone,
  className,
  renderActionsList,
  handleGetBlackListInfoByPhone,
}: PhoneActionsDropdownProps) => {
  const { isPhoneInBlackList, phone: phoneNumber, description = '', blacklistId } = phone

  const [phoneActionsRef, openPhoneActions, setOpenPhoneActions] = useComponentVisible(undefined, undefined, undefined, ['button', 'icon'])

  const phoneActionDropdownClasses = cn(styles['phone-actions-dropdown'], className)

  return (
    <div ref={phoneActionsRef} className={phoneActionDropdownClasses}>
      {phoneNumber && (
        <Tooltip
          show={isPhoneInBlackList && !!description}
          anchorId={`description_${phone}`}
          text={description}
          //tooltipOffset={[20, 15]}
          placement={'top'}>
          <Typography
            className={styles['text-value-interactive']}
            as={'caption'}
            weight={2}
            decoration={'none'}
            tag={4}
            onClick={() => {
              handleGetBlackListInfoByPhone(phoneNumber)
              setOpenPhoneActions((prev) => !prev)
            }}>
            {convertPhone(String(phoneNumber))}
          </Typography>
        </Tooltip>
      )}
      {openPhoneActions && (
        <div className={styles['phone-actions-dropdown__list-container']}>
          <div className={styles['phone-actions-dropdown__list']}>{renderActionsList(setOpenPhoneActions)}</div>
        </div>
      )}
    </div>
  )
}
