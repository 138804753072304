import type { MultiSelectDropdownMenuProps } from "@common/multi-select/multi-select/dropdown-menu-virtualized/multi-select-dropdown-menu.types";
import React, { type Ref, useRef } from "react";

import styles from "./multi-select-dropdown-menu.module.scss";

import { Tooltip } from "@/components/common/tooltip";
import { DropdownMenuItem } from "@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item";
import { TreeRecursive } from "@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item/tree-recursive";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isObjectInMap } from "@utils/find-item";
import cn from "classnames";

export const MultiSelectDropdownMenuVirtualized = <Item extends { [key: string]: any }>({
	isGroupSelectable,
	menuProps,
	getItemProps,
	items,
	isOpen,
	highlightedIndex,
	isDataNested,
	dropdownMenuItemVariant,
	dropdownMenuItemWithCheckbox,
	selectItem,
	selectedItems,
	handleCollapseNestedItems,
	openedNestedItems,
	dropdownMenuContainerWidth,
	dropdownMenuContainerHeight,
	withTooltip,
	disableGroupsWithoutChilds,
}: MultiSelectDropdownMenuProps<Item>) => {
	const dropdownWrapperClasses = cn(styles.wrapper, {
		[styles["is-hidden"]]: !isOpen,
		[styles["is-visible"]]: isOpen,
	});

	const dropdownItemsListContainer = cn(styles["multi-select__dropdown-container"], {
		[styles["container-with-checkboxes"]]: dropdownMenuItemWithCheckbox,
		[styles["container-with-nested-data"]]: isDataNested,
	});

	const parentRef = useRef<HTMLDivElement>(null);
	const parentOffsetRef = React.useRef(0);

	React.useLayoutEffect(() => {
		parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
	}, []);

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 32,
	});

	const { getVirtualItems, getTotalSize } = rowVirtualizer;
	const virtualRows = getVirtualItems();

	const mergeRefs = <T,>(...inputRefs: (React.Ref<T> | undefined)[]): React.Ref<T> | React.RefCallback<T> => {
		const filteredInputRefs = inputRefs.filter(Boolean);

		if (filteredInputRefs.length <= 1) {
			const firstRef = filteredInputRefs[0];

			return firstRef || null;
		}

		return function mergedRefs(ref) {
			for (const inputRef of filteredInputRefs) {
				if (typeof inputRef === "function") {
					inputRef(ref);
				} else if (inputRef) {
					(inputRef as React.MutableRefObject<T | null>).current = ref;
				}
			}
		};
	};

	const menuRef = "ref" in menuProps ? menuProps.ref : undefined;
	return (
		<div className={dropdownWrapperClasses} style={{ overflowY: "auto", padding: "8px" }}>
			<div
				className={dropdownItemsListContainer}
				ref={mergeRefs(parentRef, menuRef as Ref<any>)}
				style={{
					height: dropdownMenuContainerHeight || (items.length === 0 && "40px") || "350px",
					width: dropdownMenuContainerWidth,
					overflowY: "auto",
					contain: "strict",
				}}
			>
				<div
					style={{
						height: getTotalSize(),
						width: "100%",
						position: "relative",
					}}
				>
					<div
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							display: "flex",
							flexDirection: "column",
							gap: 20,
						}}
					>
						{/*если структура данных плоская*/}
						{!isDataNested &&
							isOpen &&
							virtualRows.map((virtualRow) => {
								const item = items[virtualRow.index];
								if (!item) return;
								const itemProps = {
									...getItemProps({
										item,
										key: item.id,
										index: item.id,
										id: item.id,
									}),
								};

								return (
									<Tooltip
										placement="bottom"
										className={styles["tooltip-container"]}
										show={item.description && !!item.description.replaceAll(" ", "") && withTooltip}
										key={item.id}
										anchorId={item.id}
										text={item.description}
									>
										<DropdownMenuItem<Item>
											style={{
												height: `${virtualRow.size}px`,
												position: "absolute",
												transform: `translateY(${virtualRow.start}px)`,
											}}
											dataIndex={virtualRow.index}
											rowVirtualizer={rowVirtualizer}
											disableGroupsWithoutChilds={disableGroupsWithoutChilds}
											id={item.id}
											withCheckbox={dropdownMenuItemWithCheckbox}
											selectItem={selectItem}
											selectedItems={selectedItems}
											itemProps={itemProps}
											key={item.id}
											item={item}
											isActive={isObjectInMap(selectedItems, item.id, item.title)}
											isDataNested={false}
											variant={dropdownMenuItemVariant}
											handleCollapseNestedItems={handleCollapseNestedItems}
											openedNestedItems={openedNestedItems}
										/>
									</Tooltip>
								);
							})}
						{items.length === 0 && <div className={styles["empty-plug"]}>Не найдено совпадений</div>}
						{/*если структура данных вложенная */}
						{isDataNested && isOpen && (
							<TreeRecursive<Item>
								disableGroupsWithoutChilds={disableGroupsWithoutChilds}
								isGroupSelectable={isGroupSelectable}
								selectItem={selectItem}
								selectedItems={selectedItems}
								items={items}
								highlightedIndex={highlightedIndex}
								isDataNested={true}
								dropdownMenuItemVariant={dropdownMenuItemVariant}
								dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
								getItemProps={getItemProps}
								isOpen={isOpen}
								handleCollapseNestedItems={handleCollapseNestedItems}
								openedNestedItems={openedNestedItems}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
