import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table'
import { InstallmentHistoryTable } from './installment-history-table'
import { createColumnHelper } from '@tanstack/react-table'
import { TypographyHoc } from '../installment-hoc/installment-typography-hoc'
import { useRequestsInstallmentDeletePaymentMutation, useRequestsInstallmentPaymentHistoryQuery } from '@/state/api'
import { useEffect, useState } from 'react'
import { addSnack } from '@/eventbus/events/snackbar'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'
import { Button } from '@/components/button'
import { InstallmentConfirmDelete } from './installment-confirm-delete'
import { ContentLoader } from '@/components/content-loader'
export interface ErrorCatchProps {
  text: string
  handleClick: () => void
}

export const InstallmentHistory = ({ requestId = 'norequestid', canDelete }: any): JSX.Element => {
  const { refetch, currentData, isError, isFetching, isLoading, isSuccess } = useRequestsInstallmentPaymentHistoryQuery({ requestId })
  // Пагинированный список
  const serverData = currentData?.data
  const [deletePayment, { isError: isErrorDelete, reset, isLoading: isLoadingDelete }] = useRequestsInstallmentDeletePaymentMutation()
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<number | undefined>(undefined)

  const errorCatch = ({ text, handleClick }: ErrorCatchProps): void => {
    addSnack({
      type: 'error',
      eventName: 'fetch-requests-error',
      text,
      withAction: true,
      actionButtonText: 'Повторить',
      handleClick: handleClick,
    })
  }

  const handleOnDeleteButton = (id: number) => {
    setCurrentId(id)
    setShowDeleteConfirmModal(true)
  }

  const onConfirm = () => {
    deletePayment({ currentId, requestId })
      .unwrap()
      .then(() => {
        setShowDeleteConfirmModal(false)
        refetch()
      })
      .catch(() => {
        errorCatch({ text: 'Не удалось удалить запись оплаты', handleClick: () => reset() })
      })
  }
  const onCancel = () => {
    setShowDeleteConfirmModal(false)
    setCurrentId(undefined)
  }

  // Создание массива заголовков
  const columnHelper = createColumnHelper<any>()

  const columnsWorkControl: any = [
    columnHelper.accessor('pay_time', {
      id: 'pay_time',
      header: 'Дата платежа',
      cell: (info) => {
        const children = info.renderValue()

        return <TypographyHoc children={children} />
      },
    }),
    columnHelper.accessor('sum', {
      id: 'sum',
      header: 'Сумма',
      cell: (info) => {
        const children = info.renderValue() ?? '\u2014'

        return <TypographyHoc children={children} />
      },
    }),
    columnHelper.accessor('type_name', {
      id: 'type_name',
      header: 'Тип платежа',
      cell: (info) => {
        const children = info.renderValue()

        return <TypographyHoc children={children} />
      },
    }),
    columnHelper.display({
      maxSize: 53,
      header: '',
      id: 'delete_action',
      cell: (info) => {
        const id = info.row.original.id
        const isOnline = info.row.original.type === 'online'

        return (
          <>
            {canDelete && !isOnline ? (
              <Button
                btnType={'button'}
                type={ButtonTypes.secondaryUncolored}
                size={UISizes.medium}
                iconLeft={<i className={'icon icon-delete'} />}
                onClick={() => {
                  handleOnDeleteButton(id)
                }}
              />
            ) : null}
          </>
        )
      },
    }),
  ]

  // Гидрация таблицы
  const pagination = {
    pageIndex: 0,
    pageSize: serverData?.length || 10,
  }
  const table = useReactTable({
    data: serverData || [],
    columns: columnsWorkControl,
    state: {
      pagination,
      columnVisibility: {
        delete_action: canDelete,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    enablePinning: true,
  })

  useEffect(() => {
    if (isError) {
      errorCatch({ text: 'Не удалось загрузить историю оплат', handleClick: () => refetch })
    }
  }, [isError])

  return (
    <>
      {isLoading ? <ContentLoader /> : <InstallmentHistoryTable table={table} />}
      {showDeleteConfirmModal && <InstallmentConfirmDelete onConfirm={onConfirm} onCancel={onCancel} />}
    </>
  )
}
