import { ReferCallToAutoInformerButton } from '../../../../shared/components/refer-call-to-autoinformer-button'
import styles from './refer-second-line-to-autoinformer.module.scss'
import cn from 'classnames'

export const ReferSecondLineToAutoInformer = ({referCallToAutoInformer, isDisabled}: {referCallToAutoInformer:() => void, isDisabled:boolean}) => {

  const referToAutoInfromerSecondLineButtonStyles = cn(styles['refer-second-line-to-autoinformer-btn'])

  return <ReferCallToAutoInformerButton onClick={referCallToAutoInformer} disabled={isDisabled} className={referToAutoInfromerSecondLineButtonStyles}/>
}
