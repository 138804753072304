import { HoldCallIconButton } from "../../../../shared/components";
import { SessionDTO, SessionContext, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";

type ToggleCallOnHoldProps = {
	isCallOnHold: boolean;
	handleToggleCall: () => void;
	currentSessionId: string;
};

export const ToggleCallOnHold = ({ isCallOnHold, handleToggleCall, currentSessionId }: ToggleCallOnHoldProps) => {
	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const currentSession = sessionsDTO.find((session: SessionDTO) => session.sessionId === currentSessionId);
	const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);
	return (
		<>
			{currentSession?.isConnected ? (
				<HoldCallIconButton
					handleCallOnHold={handleToggleCall}
					isCallOnHold={isCallOnHold}
					isDisabled={!currentSession?.isConnected || !isReadyToSecondCall}
				/>
			) : null}
		</>
	);
};
