import type { RequestModalTextInputProps } from "@components/modals/common-forms/request-modal-input/request-modal-text-input.types";
import { useEffect, useState } from "react";
import { useUsersFeedQuery, type WorkFeedResource } from "@/state/api";
import { CreateRequestNames } from "@constants/create-request";
import { MultiSelect } from "@common/multi-select";
import { useField } from "react-final-form";
import { findItem } from "@utils/find-item";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";

export const RequestModalFinanceResponsible = <T,>({
	input,
	meta,
	label,
	isGroupSelectable = true,
	options = [],
	disableGroupsWithoutChilds,
}: RequestModalTextInputProps<T>) => {
	const [initialSelectedItems, setInitialSelectedItems] = useState<{
		[key: string]: any;
	}>();

	const { data: serverData, isLoading } = useUsersFeedQuery();

	const field = useField(input?.name)?.input?.value;

	const handleInitialValue = () => {
		return Array.isArray(field)
			? field.map(
					(id) =>
						findItem({
							id: id,
							arr: serverData?.data || options,
							filterChildsOnly: !isGroupSelectable,
						})[0],
				)
			: findItem({
					id: field,
					arr: serverData?.data || options,
					filterChildsOnly: !isGroupSelectable,
				});
	};

	useEffect(() => {
		const initialValues = handleInitialValue();
		!isLoading && setInitialSelectedItems(initialValues);
	}, [field, isLoading]);

	const selectedItemsOnChangeHandlerByName = <T extends { id?: number; title?: string; data?: any; items?: any }>(items: T[]) => {
		if (input.name === FilterNames.template) {
			return items[0];
		}

		return items[0]?.id;
	};

	const handleChange = <T extends { id?: number; title?: string; data?: any; items?: any }>(items: T[]) => {
		if (items && !!items.length) {
			const singleSelectionItemId = selectedItemsOnChangeHandlerByName(items);
			input.onChange(singleSelectionItemId);
			return;
		}
		input.onChange(undefined);
	};

	return (
		<>
			<MultiSelect<WorkFeedResource>
				allowOnlyChildSelection={true}
				disableGroupsWithoutChilds={disableGroupsWithoutChilds}
				isGroupSelectable={isGroupSelectable}
				isDataLoading={isLoading}
				width={"full-w"}
				isDataNested={true}
				inputLabelText={`${label}: `}
				dropdownMenuSelectionVariant={"single-select"}
				data={(serverData?.data as WorkFeedResource[]) || []}
				inputId={CreateRequestNames.responsible}
				onChange={(items) => {
					handleChange(items);
				}}
				initialSelectedItems={initialSelectedItems as WorkFeedResource[]}
				externalInputProps={input}
				externalInputMetaProps={meta}
			/>
		</>
	);
};
