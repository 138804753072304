export const clearText = (_string?: string | null | undefined): string | null => {
	if (!_string) return null;
	const result = _string
		.replace(/<[^>]+>/g, "")
		.replace(/&|;|!|@|}|{/g, "")
		.replace(/[a-zA-Z]+/g, "")
		.replace(
			/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
			"",
		);

	return result;
};

export const clearReklamaAndObjectText = (_string?: string | null | undefined): string | null => {
	if (!_string) return null;
	const processedString = _string.split("-&gt").join("->");
	const result = processedString
		.replace(/<[^>]+>/g, "")
		.replace(/&|;|!|@|}|{/g, "")
		.replace(/quot/g, "")
		.replace(/gt/g, "")
		.replace(
			/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u221D\u221F-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
			"",
		);
	return result;
};
