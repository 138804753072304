import { TypographyProps } from '@common/typography/typography.types'
import styles from './typography.module.scss'
import { createElement } from 'react'

type GetClassNameType = {
  as: string
  tag: number
  weight?: number
  decoration: string
}

function getClassName({ as, tag, weight, decoration }: GetClassNameType) {
  if (as === 'caption') {
    return `caption-${tag}-${decoration}${
      tag === 3 || tag === 5 || tag === 6 ? '' : `-weight-${weight}`
    }`
  }
  if (as === 'paragraph') {
    return `paragraph-${tag}-${decoration}`
  }

  return ''
}

export const Typography = ({
  as,
  tag,
  weight,
  decoration = 'none',
  children,
  className,
  ...props
}: TypographyProps) => {
  const elementClasses = getClassName({ as, tag, weight, decoration })

  return createElement(
    'p',
    {
      className: `${styles[elementClasses]} ${className ? className : ''}`,
      ...props,
    },
    children,
  )
}
