import { useProfileQuery } from '@/state/api'
import { OLD_CMS_URL } from './env'

export const SidebarMenuButtonsContent = () => {
  const BASE_PATH = OLD_CMS_URL
  const { data: profile } = useProfileQuery()

  return {
    APPLICATIONS: {
      NAME: 'Заявки',
      ICON: <i className="icon icon-request icon-low" />,
      PATHNAME: '/requests',
      isSimpleLink: false,
      show: profile?.data?.available_menu?.request,
    },
    MASTERS: {
      NAME: 'Мастера',
      ICON: <i className="icon icon-group icon-low" />,
      PATHNAME: `${BASE_PATH}modules/master/master.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.master,
    },
    COMPANIES: {
      NAME: 'Компании',
      ICON: <i className="icon icon-briefcase icon-low" />,
      PATHNAME: `${BASE_PATH}modules/contacts/list/companies`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.contacts,
    },
    STATISTICS: {
      NAME: 'Статистика',
      ICON: <i className="icon icon-bar-chart icon-low" />,
      PATHNAME: `${BASE_PATH}modules/statistic/statistic.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.statistic,
    },
    REPORTS: {
      NAME: 'Отчеты',
      ICON: <i className="icon icon-file icon-low" />,
      PATHNAME: `${BASE_PATH}modules/fin/fin.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.report,
    },
    CONSTRUCTOR: {
      NAME: 'Конструктор',
      ICON: <i className="icon icon-constructor icon-low" />,
      PATHNAME: `${BASE_PATH}modules/constructor/`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.constructor,
    },
    CALLS: {
      NAME: 'Звонки',
      ICON: <i className="icon icon-phone-call icon-low" />,
      PATHNAME: `${BASE_PATH}modules/call/call.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.call,
    },
    SETTINGS: {
      NAME: 'Настройки',
      ICON: <i className="icon icon-settings icon-low" />,
      PATHNAME: `${BASE_PATH}modules/settings/settings.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.settings,
    },
    APARTMENTS_REPAIR: {
      NAME: 'Ремонт квартир',
      ICON: <i className="icon icon-paint-roll icon-low" />,
      PATHNAME: `${BASE_PATH}modules/repair/`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.repair,
    },
    TESTS: {
      NAME: 'Тесты',
      ICON: <i className="icon icon-edit-check icon-low" />,
      PATHNAME: `${BASE_PATH}modules/test/test.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.test,
    },
    TEST_PASSING: {
      NAME: 'Пройти тест',
      ICON: <i className="icon icon-edit-2 icon-low" />,
      PATHNAME: `${BASE_PATH}modules/test/dotest.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.dotest,
    },
    BONUSES: {
      NAME: 'Премии',
      ICON: <i className="icon icon-award icon-low" />,
      PATHNAME: `${BASE_PATH}modules/bonus/bonus.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.bonus,
    },
    SCHEDULE: {
      NAME: 'Расписание',
      ICON: <i className="icon icon-calendar icon-low" />,
      PATHNAME: `${BASE_PATH}modules/timetable/timetable.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.timetable,
    },
    BLACK_LIST: {
      NAME: 'Черный список',
      ICON: <i className="icon icon-list icon-low" />,
      PATHNAME: `${BASE_PATH}blacklist`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.blacklist,
    },
    PHONE_BOOK: {
      NAME: 'Телефоны',
      ICON: <i className="icon icon-book icon-low" />,
      PATHNAME: `${BASE_PATH}modules/phone_conformity/phone_conformity.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.phone_conformity,
    },
    MESSAGE_TO_MASTER: {
      NAME: 'Сообщение мастеру',
      ICON: <i className="icon icon-chat icon-low" />,
      PATHNAME: `${BASE_PATH}master/messages`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.master_messages,
    },
    CAN_MASTER_REJECTS: {
      NAME: 'Отказы мастеров',
      ICON: <i className="icon icon-bx-user-x icon-low" />,
      PATHNAME: `${BASE_PATH}modules/master_rejects/action.php`,
      isSimpleLink: true,
      show: true,
    },
    OKK: {
      NAME: 'ОКК',
      ICON: <i className="icon icon-check-shield icon-low" />,
      PATHNAME: `${BASE_PATH}modules/okk/okk.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.okk,
    },
    TELEMARKETING: {
      NAME: 'Телемаркетинг',
      ICON: <i className="icon icon-telephony icon-low" />,
      PATHNAME: `${BASE_PATH}modules/okk/telemarketing.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.telemarketing,
    },
    CLICKS: {
      NAME: 'Клики',
      ICON: <i className="icon icon-cursor icon-low" />,
      PATHNAME: `${BASE_PATH}modules/newstat/statistic.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.newstat,
    },
    SEND_SMS: {
      NAME: 'Отправить смс',
      ICON: <i className="icon icon-message-rounded-dots icon-low" />,
      PATHNAME: `${BASE_PATH}modules/sms/sms.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.sms,
    },
    INFORMING: {
      NAME: 'Информирование',
      ICON: <i className="icon icon-user-voice icon-low" />,
      PATHNAME: `${BASE_PATH}modules/announcement/announcement.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.announcement,
    },
    EMAIL: {
      NAME: 'Почта',
      ICON: <i className="icon icon-mail icon-low" />,
      PATHNAME: `${BASE_PATH}modules/basemail/basemail.php?folder=new&redir=1`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.mail,
    },
    TASKS: {
      NAME: 'Задачи',
      ICON: <i className="icon icon-task icon-low" />,
      PATHNAME: `${BASE_PATH}modules/ticket/ticket.php`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.ticket,
    },
    AUDITION: {
      NAME: 'Прослушка',
      ICON: <i className="icon icon-voicemail icon-low" />,
      PATHNAME: `${BASE_PATH}modules/Wiretapping/`,
      isSimpleLink: true,
      show: profile?.data?.available_menu?.wiretapping,
    },
    LENPEDIA: {
      NAME: 'Ленпедия',
      ICON: <i className="icon icon-book-open icon-low" />,
      PATHNAME: 'https://wiki.lenremont.ru/',
      isSimpleLink: true,
      show: true,
    },
    KNOWLEDGE_BASE: {
      NAME: 'База знаний',
      ICON: <i className="icon icon-base icon-low" />,
      PATHNAME: 'https://info.lenremont.ru/wp-login.php',
      isSimpleLink: true,
      show: true,
    },
    CLOUD_COMMON: {
      NAME: 'Облако общее',
      ICON: <i className="icon icon-cloud icon-low" />,
      PATHNAME: 'https://drive.google.com/drive/folders/1VmYXySNKgC8ZI4ZfD_YBthCJoVkdh9Ml',
      isSimpleLink: true,
      show: true,
    },
    CLOUD_WORKSHOPS: {
      NAME: 'Облако мастерские',
      ICON: <i className="icon icon-cloud-user icon-low" />,
      PATHNAME: 'https://drive.google.com/drive/folders/1ZmoC-5lcf0wS3oXdm1vbeBckX0MCpcPv',
      isSimpleLink: true,
      show: true,
    },
  }
}
