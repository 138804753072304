import { createRef, type FC, useEffect , useState } from 'react'
import type { IAudioTrackPlayerAutoanswerProps } from './audio-track-player-autoanswer.types'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import { isNull } from 'lodash'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'
import { formatDuration } from '@/utils/format-duration'
import './answ-rhap-player.scss'
import styles from './audio-track-player-autoanswer.module.scss'
import cn from 'classnames'
import { addSnack } from '@/eventbus/events/snackbar'

export const AudioTrackPlayerAutoanswer: FC<IAudioTrackPlayerAutoanswerProps> = ({
  data,
  autoPlay,
  isLoading,
  currAudioTrackIndex,
  handleEndTrack,
  handleSetCurrTrack,
  handleRemove,
// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
}): JSX.Element => {
  const audioTrackPlayerSpeed = localStorage.getItem('audioTrackPlayerSpeed')
  const audioTrackPlayerVolume = localStorage.getItem('audioTrackPlayerVolume')
  const initialSpeed = (): number => {
    if (audioTrackPlayerSpeed) return Number(audioTrackPlayerSpeed)
    return 1
  }
  const initialVolume = (): number => {
    if (audioTrackPlayerVolume) return Number(audioTrackPlayerVolume)
    return 0.5
  }
  const [speed, setSpeed] = useState(initialSpeed)
  const [volume, setVolume] = useState(initialVolume)
  const [checkSpeed, setCheckSpeed] = useState(false)
  const [isErrorAudioFile, setIsErrorAudioFile] = useState(false)

  const player = createRef<AudioPlayer>()

  const audioTrackClasses = cn(styles.audio, {
    [styles['audio-playing']]: autoPlay,
    [styles['audio-error']]: isErrorAudioFile,
    [styles['audio-disabled']]: isLoading,
  })

  const changeSpeed = () => {
    if (speed >= 2) {
      setSpeed(1)
    } else setSpeed(speed + 0.5)
  }

  useEffect(() => {
    audioTrackPlayerSpeed ? setSpeed(Number(audioTrackPlayerSpeed)) : setSpeed(1)
    audioTrackPlayerVolume ? setVolume(Number(audioTrackPlayerVolume)) : setVolume(0.5)
  }, [])

  useEffect(() => {
    localStorage.setItem('audioTrackPlayerSpeed', String(speed))
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.playbackRate = Number(speed)) : undefined
  }, [speed, checkSpeed])

  useEffect(() => {
    localStorage.setItem('audioTrackPlayerVolume', String(volume))
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.volume = Number(volume)) : undefined
  }, [volume])

  useEffect(() => {
    setSpeed(initialSpeed)
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.playbackRate = Number(audioTrackPlayerSpeed)) : undefined
    setVolume(initialVolume)
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.volume = Number(volume)) : undefined
  }, [currAudioTrackIndex])

  useEffect(() => {
    const audio = player?.current?.audio?.current as HTMLAudioElement
    if (currAudioTrackIndex === data.index) {
      if (audio?.currentTime === audio?.duration) {
        audio.src = ''
        audio.src = `/api/records/${data?.cdr_id}/listen`
      }

      typeof currAudioTrackIndex === 'number' &&
        !isNull(currAudioTrackIndex) &&
        player?.current?.audio?.current?.currentTime !== player?.current?.audio?.current?.duration &&
        player?.current?.audio?.current
          ?.play()
          // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
          ?.then(() => {})
          .catch((e) => console.log(e))
    } else {
      player?.current?.audio?.current?.pause()
    }
  }, [currAudioTrackIndex])

  const displayButtonBar = () => {
    return [
      // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
<div className={'speed-button-wrapper'}>
        <Button
          // biome-ignore lint/complexity/noUselessFragments: <explanation>
          // biome-ignore lint/correctness/noChildrenProp: <explanation>
                    children={<>{`х${speed}`}</>}
          btnType={'button'}
          disabled={isLoading}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          onClick={() => changeSpeed?.()}
        />
      </div>,
      // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
<Button
        btnType={'button'}
        disabled={isLoading}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        iconLeft={<i className={'icon icon-delete'} />}
        onClick={() => handleRemove?.(data.cdr_id)}
      />,
    ]
  }

  return (
    <div aria-track-playing={autoPlay} className={`aw ${audioTrackClasses}`} id={`aw${String(data.cdr_id)}`}>
      <div className={styles.audio_header_autoanswer}>
        <div className={styles.audio_header_subtitle_autoanswer}>{`Запись №${data.cdr_id}`}</div>
        <div className={styles.audio_header_date_autoanswer}>{data?.time}</div>
      </div>
      <AudioPlayer
        onEnded={() => {
          handleEndTrack()
        }}
        onPlay={() => {
          handleSetCurrTrack(data?.index)
          setCheckSpeed((prev) => !prev)
        }}
        onVolumeChange={() => setVolume(Number(player?.current?.audio.current?.volume.toFixed(2)))}
        onError={() => {
          addSnack({
            type: 'error',
            eventName: 'add-files-error',
            text: 'Не удалось воспроизвести аудиозапись',
            withAction: false,
          })
          setIsErrorAudioFile?.(true)
          handleEndTrack?.()
        }}
        ref={player}
        src={`/api/records/${data?.cdr_id}/listen`}
        volume={volume}
        defaultDuration={formatDuration(data?.duration)}
        autoPlay={autoPlay}
        preload={'none'}
        showJumpControls={false}
        showDownloadProgress={true}
        showFilledVolume={true}
        autoPlayAfterSrcChange={true}
        customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION, RHAP_UI.CURRENT_TIME]}
        customAdditionalControls={displayButtonBar()}
        customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
        customIcons={{
          play: <i className={'icon-play'} />,
          pause: <i className={'icon-pause'} />,
          volume: <i className={'icon-volume'} />,
          volumeMute: <i className={'icon-volume-mute'} />,
        }}
      />
    </div>
  )
}
