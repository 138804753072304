import React, { useEffect } from 'react'
import { usePhoner } from '../../../contexts/phoner/phoner'
import { useGlobalPhonerState } from '../../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'

//RemoteAudio named
export const AudioPlayer = () => {
  const remoteAudio = React.useRef<HTMLAudioElement | null>(null)
  const soundsControl = React.useRef<HTMLAudioElement | null>(null)
  const { setRemoteAudioControl, setSoundControl } = usePhoner()

  React.useEffect(() => {
    setRemoteAudioControl(remoteAudio.current)
    setSoundControl(soundsControl.current)
  }, [remoteAudio.current, soundsControl.current])

  const { phonerSettingsService } = useGlobalPhonerState()
  const [statePhonerSettings] = useActor(phonerSettingsService)
  const audioVolume = statePhonerSettings?.context?.audioVolume

  useEffect(() => {
    if (soundsControl.current && remoteAudio.current) {
      soundsControl.current.volume = audioVolume / 100
      remoteAudio.current.volume = audioVolume / 100
    }
  }, [remoteAudio.current, soundsControl.current, audioVolume])

  return (
    <>
      <audio
        className="audio"
        ref={remoteAudio}
        style={{ visibility: 'hidden', clip: 'rect(0,0,0,0)' }}
        autoPlay></audio>
      <audio
        ref={soundsControl}
        style={{ visibility: 'hidden', clip: 'rect(0,0,0,0)' }}
        autoPlay></audio>
    </>
  )
}
