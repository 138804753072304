import { SelectDropDownItemProps } from '@components/common/select/select-dropdown-item/select-dropdown-item.types'
import styles from './select-dropdown-item.module.scss'

export const SelectDropdownItem = <T,>({
  data,
  onClick = () => {},
  valueFormatter,
  highlighted = false,
  active = false,
  ...props
}: SelectDropDownItemProps<T, string>) => {
  return (
    <li
    className={`${styles['select-dropdown-item']} ${active ? styles['select-dropdown-item-highlighted'] : ''}
    ${highlighted && styles['select-dropdown-item-hover']}
    `}
      onClick={() => onClick(data)}
      {...props}>
      {valueFormatter ? valueFormatter(data) : <>{data}</>}
    </li>
  )
}
