import { InstallmentServerData } from "@/constants/installment";
import type { FieldInputProps, FieldMetaState } from "react-final-form";

export const getInputState = <T, Elem extends HTMLElement>(
	{ invalid, active, modifiedSinceLastSubmit, validating, error, submitFailed }: FieldMetaState<T>,
	{ type = "text", value, name }: FieldInputProps<T, Elem>,
	textError?: string,
): "active" | "error" | "filled" | "default" | "disabled" => {
	const errorState = { error: false };
	errorState.error = Boolean(invalid && submitFailed) || Boolean(invalid && modifiedSinceLastSubmit);

	if (name === "typeOfWork" || name === InstallmentServerData.agreement) {
		errorState.error = Boolean(!value && submitFailed) || Boolean(!value && modifiedSinceLastSubmit);
	}

	if (
		name === "clientFio" ||
		name === "districtId" ||
		name === "ads" ||
		name === "legalEntityClientFio" ||
		name === "organization" ||
		name === InstallmentServerData.date_begin ||
		name === InstallmentServerData.contract_date ||
		name === InstallmentServerData.period
	) {
		errorState.error = Boolean(submitFailed && !!error) || Boolean(submitFailed && !value);
	}

	if (name === "phone") {
		errorState.error = Boolean(!active && !!error) || Boolean(submitFailed && !!error) || Boolean(submitFailed && !value);
	}

	if (name === InstallmentServerData.period) {
		errorState.error = Boolean(!!error);
	}
	const isFocus = active;

	const isGood = !active && !validating && value !== undefined && value !== null && String(value) !== "";

	const isGoodSelect = type === "select" && String(value) !== "";

	if (isFocus) {
		return "active";
	}

	if (errorState.error || textError) {
		return "error";
	}

	if (isGood || isGoodSelect) {
		return "filled";
	}

	return "default";
};
