import React, { useEffect } from 'react'

import { Typography } from '@/components/common'
import { Button } from '@/components/button'
import { addSnack } from '@/eventbus/events/snackbar'

import { MissingCallsProps } from './missed-calls-content.type'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'

import { formatMissedPhoneNumber } from '@/utils/format-missed-call-num'
import { useDeleteMissingCallMutation, useMissedClientCallMutation } from '@/state/api'
import styles from './missed-calls-content.module.scss'
import { convertPhone } from '@/utils/convert-phone'

export const MissedCallsContent = ({ missingCalls }: MissingCallsProps) => {
  const [removeMissedCall, { isLoading, isError }] = useDeleteMissingCallMutation()
  const [callMissedCall, { isLoading: isCallRequestSuccess, isError: isCallRequestError }] = useMissedClientCallMutation()

  const handleCall = (phone?: string) => {
    if (phone) {
      callMissedCall({
        requestMasterCallCall: {
          phone,
        },
      })
        .unwrap()
        .catch((e) => console.warn(e))
    }
  }

  useEffect(() => {
    if (isCallRequestSuccess) {
      addSnack({
        type: 'default',
        eventName: 'call-success',
        text: 'Идет соединение...',
      })
    }

    if (isCallRequestError) {
      addSnack({
        type: 'error',
        eventName: 'call-error',
        text: 'Ошибка соединения',
      })
    }
  }, [isCallRequestSuccess, isCallRequestError])

  const handleRemoveCall = (id?: number) => {
    id && removeMissedCall({ id })
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-call-records-error',
        text: 'Не удалось удалить запись',
        withAction: false,
      })
    }
  }, [isError])

  return (
    <div className={styles['missed-calls-list']}>
      {missingCalls &&
        missingCalls.map((item) => (
          <React.Fragment key={item?.id}>
            <div className={styles['missed-calls-list_element']}>
              <div className={styles['missed-calls-list_element-number']}>
                <Typography as={'paragraph'} decoration={'none'} tag={3} onClick={() => handleCall(item.src)}>
                  {convertPhone(String(item.src))}
                </Typography>
              </div>
              <div className={styles['missed-calls-list-time-delete-block']}>
                <Typography as={'paragraph'} decoration={'none'} tag={3}>
                  {item.time}
                </Typography>
                <Button
                  btnType={'button'}
                  className={styles['delete-icon']}
                  disabled={isLoading}
                  type={ButtonTypes.secondary}
                  size={UISizes.medium}
                  iconLeft={<i className={'icon icon-delete'} />}
                  onClick={() => handleRemoveCall(item.id)}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}
