import { Phoner } from "../../../entities";
import { ControlVolumeInput } from "../../../features/snack-control/control-volume-input";
import { ControlMicrophoneButton } from "../../../features/snack-control/control-microphone-button";

import { Typography } from "../../../shared";
import { useEffect, useState } from "react";

import styles from "./snack-control.module.scss";
import { useActor } from "@xstate/react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { debounce } from "lodash";
import { ControlVolumeButton } from "../../../features/snack-control/control-volume-button";

export const SnackControlWidget = () => {
	const { phonerSnackControlService, phonerSettingsService } = useGlobalPhonerState();
	const [statePhonerSnackControl, sendPhonerSnackControlEvents] = useActor(phonerSnackControlService);
	const snackControlType = statePhonerSnackControl.context.snackControlType;

	const [statePhonerSettings, sendPhonerSettingsEvents] = useActor(phonerSettingsService);

	const audioVolume = statePhonerSettings?.context?.audioVolume;
	const micVolume = statePhonerSettings?.context?.microphoneVolume;

	const [audioValue, setAudioValue] = useState<number>(audioVolume);
	const [micValue, setMicValue] = useState<number>(micVolume);

	const handleChangeAudioValue = (value: number) => {
		setAudioValue(value);
	};

	const handleChangeMicValue = (value: number) => {
		setMicValue(value);
	};

	useEffect(() => {
		const handler = debounce(() => {
			sendPhonerSettingsEvents({ type: "change audio", value: audioValue });

			sendPhonerSettingsEvents("finish changing");
		}, 0);

		handler();

		return () => handler.cancel();
	}, [audioValue]);

	useEffect(() => {
		handleChangeAudioValue(audioVolume);
	}, [audioVolume]);

	useEffect(() => {
		const handler = debounce(() => {
			sendPhonerSettingsEvents({ type: "change mic", value: micValue });
			sendPhonerSettingsEvents("finish changing");
		}, 250);

		handler();

		return () => handler.cancel();
	}, [micValue]);

	useEffect(() => {
		handleChangeMicValue(micVolume);
	}, [micVolume]);

	return (
		<div className={styles["snack-bar-wrapper"]}>
			<Phoner
				className={styles["snack-control"]}
				leftSlot={snackControlType === "mic" ? <ControlMicrophoneButton /> : <ControlVolumeButton />}
				middleSlot={
					<ControlVolumeInput
						value={snackControlType === "mic" ? micValue : audioValue}
						onValueChange={snackControlType === "mic" ? handleChangeMicValue : handleChangeAudioValue}
					/>
				}
				rightSlot={
					<Typography className={styles.number} as={"caption"} decoration={"none"} tag={4} weight={2}>
						{snackControlType === "mic" ? micValue : audioValue}
					</Typography>
				}
				onMouseLeave={() => sendPhonerSnackControlEvents("hide snackbar")}
				onMouseEnter={() => sendPhonerSnackControlEvents("show")}
			/>
		</div>
	);
};
