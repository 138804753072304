import { CheckboxProps } from './checkbox.types'
import styles from './checkbox.module.scss'
import cn from 'classnames'
import React, { useId } from 'react'

export const Checkbox = ({
  state = 'default',
  label,
  showLabel,
  value,
  onChange = () => {},
  onBlur,
  onFocus,
  className = '',
  id,
  name,
  disabled = false,
  checked,
}: Partial<CheckboxProps>) => {
  const reactId = useId()
  const localId = id && id !== '' ? id : reactId
  const classes = {
    [styles['checkbox-default']]: state === 'default',
    [styles['checkbox-error']]: state === 'error',
    [styles['checkbox-active']]: state === 'active',
    [styles['checkbox-disabled']]: disabled,
  }
  const checkboxContainerClasses = cn(
    styles['checkbox-container'],
    className,
    classes,
  )

  return (
    <label htmlFor={localId} className={checkboxContainerClasses}>
      <div className={styles['checkbox-wrapper']}>
        <input
          id={localId}
          name={name}
          disabled={disabled}
          // value={value as any}
          checked={checked}
          className={styles.checkbox}
          type={'checkbox'}
          onFocus={(e) => onFocus && onFocus(e)}
          onBlur={(e) => onBlur && onBlur(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
        />
        <div className={styles['checkbox-icon']} />
      </div>
      {showLabel && (
        <div className={styles['checkbox-label-wrapper']}>
          <p className={styles['checkbox-label']}>{label}</p>
        </div>
      )}
    </label>
  )
}
