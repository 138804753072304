import { ButtonSwitchContainer, ModalContainer } from '@/components/common'
import styles from './okk-employee-modal.module.scss'
import { clearSnacks } from '@/eventbus/events/snackbar'
import { useNavigate, useParams } from 'react-router'
import { ModalBody, ModalHeader } from '@/components/common/modal-container'
import { CreateRequestModalLeftContent } from '../create-request-modal/left-content'
import { useState } from 'react'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'
import OkkDispatcher from '../okk-comment-modal/okk-steps/okk-dispatcher'
import OkkMaster from '../okk-comment-modal/okk-steps/okk-master'
import { useRequestsShowQuery } from '@/state/api'
import { REQUESTS } from '@constants/routes'
import { useMatch } from 'react-router-dom'
import { useUserProfileInfo } from '@/state/selector'

const OkkEmployeeModal = () => {
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState<'master' | 'dispatcher'>('master')

  const params = useParams()
  const profile = useUserProfileInfo()
  const { data, isLoading: isRequestShowQueryLoading } = useRequestsShowQuery({
    requestId: Number(params.cardId ?? params.requestId),
  })
  const filters = localStorage.getItem('filters') ? JSON.parse(String(localStorage.getItem('filters'))) : null

  const matchFeed = useMatch('requests/feed/okk-employee/:requestId')

  const isFeedPage = matchFeed
  const createDispatcherAnalysisBtnDisabled = !profile?.permissions?.CAN_PREMIUM_DISPATCHER || !(profile?.id !== data?.data?.dispatcher?.id)

  return (
    <ModalContainer
      onClose={() => {
        isFeedPage
          ? navigate({
              pathname: REQUESTS,
              search: new URLSearchParams(filters).toString(),
            })
          : navigate('../')
        clearSnacks()
      }}
      showBlackout
      closeOnBlackoutClick
      className={styles['modal-container']}>
      <ModalHeader
        showRightContent
        showLeftContent
        contentLeft={<CreateRequestModalLeftContent title={"Анализ работы сотрудников"} />}
        contentRight={
          <ButtonSwitchContainer label="" showLabel={false}>
            <Button
              isActive={currentTab === 'master'}
              btnType={'button'}
              type={ButtonTypes.secondary}
              size={UISizes.medium}
              onClick={() => {
                setCurrentTab('master')
              }}>
              Мастер
            </Button>
            <Button
              disabled={createDispatcherAnalysisBtnDisabled}
              btnType={'button'}
              isActive={currentTab === 'dispatcher'}
              type={ButtonTypes.secondary}
              size={UISizes.medium}
              onClick={() => {
                setCurrentTab('dispatcher')
              }}>
              Диспетчер
            </Button>
          </ButtonSwitchContainer>
        }
        showCloseButton
        onClose={() => {
          isFeedPage
            ? navigate({
                pathname: REQUESTS,
                search: new URLSearchParams(filters).toString(),
              })
            : navigate('../')
          clearSnacks()
        }}
      />
      <ModalBody className={styles['okk-modal-body']}>
        <div className={styles['okk-modal-content']}>
          {currentTab === 'dispatcher' && (
            <OkkDispatcher
              isRequestShowQueryLoading={isRequestShowQueryLoading}
              dispatcherOnRequest={data ? data?.data?.dispatcher : undefined}
              onAdd={() =>
                isFeedPage
                  ? navigate({
                      pathname: REQUESTS,
                      search: new URLSearchParams(filters).toString(),
                    })
                  : navigate('../')
              }
            />
          )}
          {currentTab === 'master' && (
            <OkkMaster
              isRequestShowQueryLoading={isRequestShowQueryLoading}
              masterOnRequest={data?.data?.approved_master ? data?.data?.approved_master : undefined}
              onAdd={() =>
                isFeedPage
                  ? navigate({
                      pathname: REQUESTS,
                      search: new URLSearchParams(filters).toString(),
                    })
                  : navigate('../')
              }
            />
          )}
        </div>
      </ModalBody>
    </ModalContainer>
  )
}

export default OkkEmployeeModal
