export const RequiredFormTextFieldsParsers = {
  entrance: {
    length: 9,
  },
  house: {
    length: 9,
  },
  apartment: {
    length: 9,
  },
  object: {
    length: 80,
  },
  clientFio: {
    length: 52,
  },
  street: {
    length: 52,
  },
  organization: {
    length: 52,
  },
  smsMessage: {
    length: 140,
  },
  installmentPeriod: {
    length: 6,
  },
} as const
