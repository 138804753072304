import { ModalContainer, Title, Typography } from "@/components/common";
import { ModalBody, ModalHeader } from "@/components/common/modal-container";
import { ContentLoader } from "@/components/content-loader";
import {
	type District,
	type RequestComment,
	useClientCallMutation,
	useDistrictsFeedQuery,
	useProfileQuery,
	useRequestsCommentsAllIndexQuery,
	useRequestsPresetsTypesQuery,
	useRequestsShowQuery,
} from "@/state/api";
import { clearReklamaAndObjectText, clearText } from "@/utils/clear-text";
import { convertPhone } from "@/utils/convert-phone";
import { flattenData } from "@/utils/flatten-request-selection-data";
import { newWindowOpen } from "@/utils/new-window";
import { shortFio } from "@/utils/short-fio";
import { NeedManualSelection } from "@components/modals";
import { CreateRequestModal } from "@components/modals";
import { ViolationDispatcherModal } from "@components/modals";
import { useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { PushMessageModal } from "../push-message";
import { ViolationModal } from "../violation-modal";
import { WatchPrevRequestLeft } from "./left-content";
import { WatchPrevRequestData } from "./watch-prev-request.data";
import styles from "./watch-prev-request.module.scss";
import type { WatchPrevRequestProps } from "./watch-prev-reuqest.types";

import { RequestCardContentItem } from "@/components/common/request-card/request-card-content";
import { INCLUDE_PHONER, OLD_CMS_URL } from "@/constants/env";
import { addSnack } from "@/eventbus/events/snackbar";
import { PhonerEvents, type PhonerEventsT } from "@/phoner";
import { formatCommentTime } from "@components/common/request-card/request-card-content/request-card-content";
import { nanoid } from "@reduxjs/toolkit";
import cn from "classnames";

export const WatchPrevRequesdtModal = ({
	onClose,
	closeOnBlackoutClick,
	title,
	subtitle,
	requestId,
	fullcode,
	showBackButton,
}: Partial<WatchPrevRequestProps>) => {
	const [district, setDisctdict] = useState<District>({});
	const defaultRowStyle = {
		margin: 0,
		padding: 0,
		gap: "16px",
		flexFlow: "column nowrap",
	};
	const defaultColStyle = {
		padding: 0,
		display: "flex",
		flexFlow: "row nowrap",
		flex: "unset",
		width: "auto",
		gap: "8px",
	};
	const { data, isLoading } = useRequestsShowQuery({
		requestId: Number(requestId),
	});

	const { data: commentsData } = useRequestsCommentsAllIndexQuery({
		requestId: Number(requestId),
	});

	const [commentsForRender, setCommentsForRender] = useState<(RequestComment | undefined)[]>([]);

	const [openModal, setOpenModal] = useState<"manual" | "edit" | "message" | "violate" | null | "violate-dispatcher">(null);
	const { data: presetsRequestTypes, isLoading: loadPresets } = useRequestsPresetsTypesQuery();
	const { data: districts, isLoading: loadDistricts } = useDistrictsFeedQuery();

	const info = data?.data || {};

	const { data: user } = useProfileQuery();
	const [call, { isError: isCallError, originalArgs, isSuccess: isCallSuccess }] = useClientCallMutation();
	const isPhonerAvailable = user?.data?.inner_phone !== "999" && user?.data?.permissions?.CAN_USE_PHONER && INCLUDE_PHONER;

	const handleCallToClient = (phone: string) => {
		if (!isPhonerAvailable) {
			call({ requestId: Number(info?.id), requestMasterCallCall: { phone } });
			return;
		}

		const event = new CustomEvent<PhonerEventsT.MakeCallEventT>(PhonerEvents.MAKE_CALL_EVENT_NAME, {
			detail: {
				entityType: "request",
				app: "basa",
				entityId: info?.id || 0,
				calledPhone: phone,
				callerId: user?.data?.id || 0,
				calledId: info.client_id || 0,
				calledType: "client",
				callerPhone: user?.data?.inner_phone || "",
				callerType: "user",
			},
		});
		window.dispatchEvent(event);
	};

	useEffect(() => {
		if (isCallError) {
			addSnack({
				type: "error",
				eventName: "fetch-requests-error",
				text: "Не удалось позвонить.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleCallToClient(originalArgs?.requestMasterCallCall?.phone ?? ""),
			});
		}
		if (isCallSuccess) {
			addSnack({
				type: "default",
				eventName: "fetch-requests-error",
				text: "Ожидайте, звонок скоро начнется.",
			});
		}
	}, [isCallError, isCallSuccess]);

	const requestType = presetsRequestTypes?.data?.length && presetsRequestTypes?.data.find((item) => item.id === info.type_id);
	const phones = [info?.phone, info?.phone2, info?.phone3];

	const infoWorkPath = info?.work?.path.replace("-&gt", "->");

	useEffect(() => {
		if (districts?.data && info) {
			const flatData = flattenData({ arr: districts.data });
			const foundDisctirct = flatData.find((item: any) => item.id === info.address_district_id);
			setDisctdict(foundDisctirct);
		}
	}, [districts, info]);

	// распаковывает комментарии за различные дни в единый массив для рендера
	useEffect(() => {
		if (commentsData?.data?.comments) {
			const allComments = commentsData?.data?.comments.flatMap((objComment) => objComment.comments);
			setCommentsForRender(allComments);
		}
	}, [commentsData]);

	return (
		<>
			<div className={styles["watch-prev-request"]}>
				<ModalContainer
					onClose={onClose}
					showBlackout
					closeOnBlackoutClick={closeOnBlackoutClick}
					className={styles["watch-prev-request"]}
				>
					<ModalHeader
						showLeftContent
						showRightContent
						contentLeft={
							<WatchPrevRequestLeft
								title={title}
								subtitle={subtitle || `Просмотр заявки #${fullcode || ""}`}
								showBackButton={showBackButton}
								onHandleBackButton={onClose}
							/>
						}
						showCloseButton
						onClose={onClose}
					/>
					<ModalBody className="modal-body-start">
						{isLoading || loadPresets || loadDistricts || !district ? (
							<ContentLoader />
						) : (
							<div className={styles["watch-prev-request-content"]}>
								<Row style={defaultRowStyle} gutterWidth={16}>
									<Title as={"h5"}>Описание заявки</Title>
									<div className={styles["watch-prev-request__fields-container"]}>
										{info.state_name !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.request}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{info.state_name}
												</Typography>
											</Col>
										)}
										{requestType && requestType?.title !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.request_type}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{requestType?.title}
												</Typography>
											</Col>
										)}
										{info?.dispatcher?.fio !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.accepted}
												</Typography>
												<Typography
													className={styles["request-card-text"]}
													as={"caption"}
													decoration={"none"}
													tag={4}
												>
													{shortFio(info?.dispatcher?.fio)}
												</Typography>
											</Col>
										)}
										{phones?.length && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.phone}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{phones.map((item) => {
														return (
															<Typography
																key={`${item}`}
																className={styles["info-card-text-value"]}
																as={"paragraph"}
																decoration={"none"}
																tag={3}
																onClick={() =>
																	!item?.toString().includes("*") && handleCallToClient(String(item))
																}
															>
																{item && convertPhone(String(item))}
															</Typography>
														);
													})}
												</Typography>
											</Col>
										)}
									</div>
									<div className={styles["watch-prev-request__fields-container"]}>
										{info?.work?.id && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.work_type}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{clearText(infoWorkPath)}
												</Typography>
											</Col>
										)}
										{info?.obj !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.object}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{info?.obj}
												</Typography>
											</Col>
										)}
										{info?.reklama_name !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.reaklama_name}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{clearReklamaAndObjectText(info?.reklama_name)}
												</Typography>
											</Col>
										)}
									</div>
									<div className={styles["watch-prev-request__fields-container"]}>
										{info?.location_name !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.accepted}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{info?.location_name}
												</Typography>
											</Col>
										)}
										<Col style={defaultColStyle}>
											<Typography
												className={styles["request-card-text"]}
												as={"paragraph"}
												decoration={"none"}
												tag={4}
											>
												{WatchPrevRequestData.face}
											</Typography>
											<Typography
												className={styles["info-card-text-value"]}
												as={"paragraph"}
												decoration={"none"}
												tag={4}
											>
												{info.ur_org ? "Юридическое" : "Физическое"}
											</Typography>
										</Col>
										{(info.client_fio !== null || info.ur_fio !== null) && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.fio}
												</Typography>
												<Typography
													className={styles["text-value-interactive"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
													onClick={() =>
														newWindowOpen(`${OLD_CMS_URL}modules/contacts/detail/${info?.client_id}`)
													}
												>
													{info?.client_fio ? info?.client_fio : shortFio(info.ur_fio)}
												</Typography>
											</Col>
										)}
									</div>
								</Row>
								<Row style={defaultRowStyle} gutterWidth={16}>
									<Title as={"h5"}>Адрес</Title>

									<div className={styles["watch-prev-request__fields-container"]}>
										{district && district?.title !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.district}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{district?.title}
												</Typography>
											</Col>
										)}
										{info.address_street !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.street}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{clearText(info.address_street)}
												</Typography>
											</Col>
										)}
										{info.address_house !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.house}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{clearText(info.address_house)}
												</Typography>
											</Col>
										)}
										{info.address_korp !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.korp}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{clearText(info.address_korp)}
												</Typography>
											</Col>
										)}
										{info?.address_entrance !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.entrence}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{info?.address_entrance}
												</Typography>
											</Col>
										)}
										{info.address_flat !== null && (
											<Col style={defaultColStyle}>
												<Typography
													className={styles["request-card-text"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{WatchPrevRequestData.apt}
												</Typography>
												<Typography
													className={styles["info-card-text-value"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
												>
													{info.address_flat}
												</Typography>
											</Col>
										)}
									</div>
								</Row>

								<Row style={defaultRowStyle} gutterWidth={16}>
									<Title as={"h5"}>Мастера</Title>
									<div className={styles["watch-prev-request__content-container"]}>
										<div className={styles["watch-prev-request__fields-container"]}>
											<Typography
												className={styles["request-card-text"]}
												as={"paragraph"}
												decoration={"none"}
												tag={4}
											>
												{WatchPrevRequestData.master}
											</Typography>
											{info?.masters?.length === 0 ? (
												<Typography
													className={styles["text-value-manual"]}
													as={"paragraph"}
													decoration={"none"}
													tag={4}
													onClick={() => {
														return;
													}}
												>
													Необходим ручной подбор мастера
												</Typography>
											) : (
												<>
													{info?.approved_master?.fio ? (
														<Typography
															className={styles["text-value-manual"]}
															as={"paragraph"}
															decoration={"none"}
															tag={4}
														>
															{info?.approved_master?.fio}
														</Typography>
													) : (
														// <MasterActionsDropdown
														//   canMessage={info?.abilities?.master_message}
														//   canViolate={info?.abilities?.premium_master}
														//   canCall={info?.abilities?.master_message}
														//   master={info?.approved_master?.fio}
														//   requestId={String(info?.id)}
														//   onMessage={() => setOpenModal('message')}
														//   onViolate={() => setOpenModal('violate')}
														//   phone={info?.approved_master?.phone_mobile}
														//   masterId={String(info?.approved_master?.id)}
														// />
														<Typography
															className={styles["text-value-manual"]}
															as={"paragraph"}
															decoration={"none"}
															tag={4}
															// onClick={() => setOpenModal('edit')}
														>
															Доставка в приложение мастерам
														</Typography>
													)}
												</>
											)}
										</div>
									</div>
								</Row>
								{commentsData?.data?.comments && commentsData?.data?.comments !== null && commentsForRender.length > 0 ? (
									<div className={styles["okk-container"]}>
										<Title as={"h5"}>Комментарии</Title>
										<div className={styles["request-card__comments-wrapper"]}>
											{commentsForRender.map((comment) => {
												const commentClasses = cn(styles["request-card__comment-container"], {
													[styles["request-card__comment-container--hide-from-master"]]: !comment?.show_to_master,
												});

												return (
													<div key={nanoid()} className={commentClasses}>
														<Typography
															className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
															as={"paragraph"}
															decoration={"none"}
															tag={4}
														>
															{comment?.date ? formatCommentTime(comment?.date) : ""}
														</Typography>
														<RequestCardContentItem className={styles["request-card__comment"]}>
															<Typography
																className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
																as={"paragraph"}
																decoration={"none"}
																tag={4}
															>
																{`${shortFio(comment?.user_fio)} [${comment?.state_name}] ${
																	!comment?.show_to_master ? " [Не виден мастеру] " : ""
																}`}
																:
															</Typography>
															<Typography
																className={styles["request-card-text-value"]}
																as={"paragraph"}
																decoration={"none"}
																tag={4}
															>
																{comment?.text}
															</Typography>
														</RequestCardContentItem>
													</div>
												);
											})}
										</div>
									</div>
								) : null}
							</div>
						)}
					</ModalBody>
					{openModal === "manual" && (
						<NeedManualSelection fullcode={info.fullcode} request={String(info.id)} onClose={() => setOpenModal(null)} />
					)}
					{openModal === "edit" && (
						<CreateRequestModal
							onClosePath={"../"}
							modalView={"edit"}
							requestData={info}
							onHandleBackButton={() => setOpenModal(null)}
							onClose={() => setOpenModal(null)}
							title={String(info.id)}
							subtitle={"Редактирование заявки"}
							closeOnBlackoutClick
						/>
					)}
					{openModal === "violate" && (
						<ViolationModal
							onClosePath={"../"}
							onClose={() => setOpenModal(null)}
							master={String(info.approved_master?.id)}
							request={String(info.id)}
							title={"Оценка сотрудника"}
						/>
					)}
					{openModal === "violate-dispatcher" && (
						<ViolationDispatcherModal
							onClosePath={"../"}
							onClose={() => setOpenModal(null)}
							dispatcher={String(info.dispatcher?.id)}
							request={String(info.id)}
							title={"Оценка сотрудника"}
						/>
					)}
					{openModal === "message" && (
						<PushMessageModal
							onClosePath={"../"}
							onClose={() => setOpenModal(null)}
							master={String(info.approved_master?.id)}
							request={String(info.id)}
							subtitle={"Сообщение по заявке"}
						/>
					)}
				</ModalContainer>
			</div>
		</>
	);
};
