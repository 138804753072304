import type React from 'react'
import type { ReactElement } from 'react'
import type { UISizes } from '@global-types/mods.types'

export enum ButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  primaryUncolored = 'uncolored_primary',
  secondaryUncolored = 'uncolored_secondary',
  secondaryUncoloredPagination = 'uncolored_secondary_pagination',
  secondaryUncoloredHeaderHistory = 'uncolored_secondary_header-history',
  dangerUncolored = 'uncolored_danger',

}
export enum ButtonSize {
  tiny = 'tiny',
  low = 'low',
  middle = 'middle',
  big = 'big',
}

export interface ButtonProps {
  children?: React.ReactNode
  iconLeft?: ReactElement
  iconRight?: ReactElement
  disabled?: boolean
  type?: ButtonTypes
  size?: UISizes
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isLoading?: boolean
  fullWidth?: boolean
  isActive?: boolean
  squared?: boolean
  className?: string
  contentPosition?: 'left' | 'right'
  btnType?: 'button' | 'submit' | 'reset'
  href?: string
  wrapText?: boolean
  overflowText?: 'ellipsis' | 'inherit'
  id?: string
}
