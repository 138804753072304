import { FC } from 'react'
import styles from './info-card-container.module.scss'
import { IInfoCardContainerProps } from './info-card-container.types'

export const InfoCardContainer: FC<IInfoCardContainerProps> = ({
  children,
}): JSX.Element => {
  return (
    <div className={styles.info_wrapper}>
      <div className={styles.info_list}>{children}</div>
    </div>
  )
}
