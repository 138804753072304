import { LabelProps } from './label.types'
import styles from './label.module.scss'
import { Typography } from '../../../typography'
import cn from 'clsx'

export const Label = ({
  className,
  labelText = '',
  htmlFor = '',
  mRef,
  disabled,
}: LabelProps) => {
  const labelClasses = cn(styles.label, className)

  return (
    <label
      aria-disabled={disabled ? 'true' : 'false'}
      ref={mRef}
      className={labelClasses}
      htmlFor={htmlFor}>
      {labelText && (
        <Typography as={'paragraph'} tag={4} decoration={'none'}>
          {labelText}
        </Typography>
      )}
    </label>
  )
}
