import { getInputState } from '@/components/form/utils/getFieldState'
import { required } from '@/components/form/utils/validators'
import { Field } from 'react-final-form'
import { Col, Row } from 'react-grid-system'
import styles from './add-call-body.module.scss'
import { TextArea } from '@/components/form/textarea'

import { AddCallLabels, AddCallNames } from '@/constants/add-call'
import {
  useCallTypesFeedQuery,
} from '@/state/api'
import { FilterStringWithQueryInput } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-states-input/filter-states-input'
import { AddCallFormType } from '@components/modals/add-call/add-call-form.types'
export const AddCallBody = () => {
  const defaultRowStyle = {
    margin: 0,
    padding: 0,
    gap: '10px',
    flexFlow: 'row nowrap',
    width: '100%',
  }
  const mainRowStyle = {
    margin: 0,
    padding: 0,
    flexFlow: 'column nowrap',
    gap: '10px',
  }

  const defaultColStyle = {
    padding: 0,
    flex: 1,
  }

  return (
    <Row
      style={mainRowStyle}
      align={'center'}
      justify={'between'}
      gutterWidth={16}
    >
      <Row
        style={defaultRowStyle}
        align={'center'}
        justify={'between'}
        gutterWidth={16}
      >
        <Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
          <Field<AddCallFormType[AddCallNames.denyReasons]>
            name={AddCallNames.denyReasons}
            validate={required}
            type={'select'}
            labelSide={'top'}
            inputContainerOrientation='column'
            label={AddCallLabels.denyReasons}
            useQuery={useCallTypesFeedQuery}
            component={FilterStringWithQueryInput}
          />
        </Col>
      </Row>

      <Row
        style={defaultRowStyle}
        align={'center'}
        justify={'between'}
        gutterWidth={16}
      >
        <Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
          <Field<AddCallFormType[AddCallNames.comment]>
            name={AddCallNames.comment}
            type={'text'}
            validate={required}
          >
            {({ input, meta }) => {
              return (
                <TextArea
                  cols={40}
                  rows={10}
                  inputWrapperClassName={styles.add_call_body__textarea}
                  state={getInputState(meta, input)}
                  label={AddCallLabels.comment}
                  labelSide="top"
                  onReset={() => input.onChange(undefined)}
                  {...input}
                />
              )
            }}
          </Field>
        </Col>
      </Row>
    </Row>
  )
}
