import { createPortal } from 'react-dom'
import { PortalProps } from './portal.types'
import { useDomElement } from '../../hooks'

export const Portal = <T,>({
  selector = '#portal',
  children,
}: Partial<PortalProps>) => {
  const target = useDomElement<T>(selector)

  return target && createPortal(children, target)
}
