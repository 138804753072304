import { nanoid } from '@reduxjs/toolkit'
import { Button } from '../button'
import { Tooltip } from '../common'
import { Dropdown } from '../dropdown'
import { ButtonTypes } from '../button/button.types'
import { FC } from 'react'

export interface IDuplicateActionsProps {
  canDuplicate?: boolean
  canDuplicateForInstallment?: boolean
  onDuplicate?: () => void
  onDuplicateForInstallment?: () => void
  triggerButtonType?: ButtonTypes
}

export const DuplicateActions: FC<IDuplicateActionsProps> = ({
  canDuplicate,
  canDuplicateForInstallment,
  onDuplicate,
  onDuplicateForInstallment,
  triggerButtonType = ButtonTypes.secondaryUncolored,
}) => {
  return (
    <Dropdown
      shouldCloseOnSelect
      trigger={
        <Tooltip
          show
          anchorId={'request-page-duplicate'}
          key={nanoid()}
          text={'Дублировать заявку'}
          tooltipOffset={[0, 15]}
          placement={'bottom'}>
          <Button
            disabled={!canDuplicate && !canDuplicateForInstallment}
            iconLeft={<i className={'icon-copy'} />}
            type={triggerButtonType}
          />
        </Tooltip>
      }
      body={
        <>
          <Button
            type={ButtonTypes.secondaryUncolored}
            disabled={!canDuplicate}
            fullWidth={true}
            contentPosition={'left'}
            onClick={onDuplicate}>
            Дублировать
          </Button>
          <Button
            disabled={!canDuplicateForInstallment}
            type={ButtonTypes.secondaryUncolored}
            fullWidth={true}
            contentPosition={'left'}
            onClick={onDuplicateForInstallment}>
            Дублировать для рассрочки
          </Button>
        </>
      }
      key={nanoid()}
    />
  )
}
