export enum FilterLabels {
	id = "Номер заявки",
	codeNumber = "Номер заявки",
	phone = "Телефон",
	template = "Шаблон",
	dateSelection = "Дата",
	dateStart = "С",
	dateEnd = "По",
	ads = "Реклама",
	dispatcher = "Диспетчер",
	partner = "Партнер",
	request = "Заявка",
	responsible = "Ответственный",
	status = "Статус",
	type = "Тип",
	beznal = "Безнал",
	beznalOnline = "Безнал (онлайн)",
	beznalByAccount = "Безнал (по счёту)",
	typeOfWork = "Вид работ",
	worker = "Мастер",
	workerSetter = "Назначил мастера",
	contract = "Договор",
	paymentStatus = "Состояние оплаты",
	cost = "Стоимость",
	district = "Район",
	street = "Улица",
	housing = "Корп",
	house = "Дом",
	entrance = "Подъезд",
	apartment = "Кв.",
	organisation = "Организация",
	withFiles = "С файлами",
	withComments = "С комментариями",
	fastFilters = "Быстрые фильтры",
	withWorkRecords = "Содержат записи работы",
	period = "Период",
	onControl = "На контроле",
	notSaved = "Не сохраненные",
	duplicate = "Дубли",
	isCredit = "Кредитная заявка",
	is_installment = "Рассрочка",
	isEntity = "Юр. лицо",
	notGivenWorker = "Не передана мастеру",
	beznalNum = "Счет",
	installmentStatus = "Статус рассрочки",
	inn = "ИНН",
	hasNoMaster = "Мастер не подобран",
}
