import type { TextAreaProps } from './text-area.types'
import styles from './text-area.module.scss'
import cn from 'classnames'
import { useId, forwardRef } from 'react'

export const TextAreaContainer = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      state = 'default',
      value,
      showChildren = false,
      onChange,
      onFocus,
      onBlur,
      placeholder,
      className = '',
      id,
      disabled = false,
      children,
      mask,
      onReset,
      inputWrapperClassName,
      ...props
    },
    ref,
  ) => {
    const reactId = useId()
    const localId = id && id !== '' ? id : reactId

    const inputClasses = cn(styles.input, {
      [styles['input-default']]: state === 'default',
      [styles['input-active']]: state === 'active',
      [styles['input-error']]: state === 'error',
      [styles['input-disabled']]: disabled,
      [styles['input-filled']]: state === 'filled',
    })

    return (
      <div className={`${styles['input-container']}`}>
        <div
          className={`${styles['input-wrapper']} ${
            inputWrapperClassName || ''
          }`}
        >
          <textarea
            ref={ref}
            id={localId}
            placeholder={placeholder}
            disabled={disabled}
            className={`${inputClasses} ${className}`}
            value={value ? value : ''}
            onFocus={(e) => onFocus?.(e)}
            onBlur={(e) => onBlur?.(e)}
            onChange={(e) => onChange(e)}
            {...props}
          />
          {children}
        </div>
      </div>
    )
  },
)
