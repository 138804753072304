import styles from '@common/table-container/table.module.scss'
import { TableRowItemProps } from '@common/table-container/table-row/table-row.types'

export const TableRowItem = ({
  children,
  style,
  rowVirtualizer,
  dataIndex,
}: TableRowItemProps) => {
  return (
    <div
      data-index={dataIndex}
      ref={rowVirtualizer?.measureElement}
      style={style}
      className={styles['table-row-item']}>
      {children}
    </div>
  )
}
