import React, { Component, ErrorInfo } from 'react'
import ErrorIndicator from '../error-indicator/error-indicator'
import { IProps } from '@components/error-boundary/error-boundary.types'

export default class ErrorBoundary extends Component {
  state = {
    error: false,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error: true })
  }

  reloadAfterError = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    this.setState({ error: false })
  }

  render() {
    const { children } = this.props as unknown as IProps

    if (this.state.error) {
      return <ErrorIndicator reloadAfterError={this.reloadAfterError} />
    }
    return children
  }
}
