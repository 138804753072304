import { ReactElement } from 'react'
import { Typography } from '../../../ui-kit/typography'
import styles from './current-call-phone.module.scss'

interface CurrentCallPhoneProps {
  number?: string
  timer?: ReactElement
}
export const CurrentCallPhone = ({ number, timer }: CurrentCallPhoneProps) => {
  return (
    <div className={styles.container}>
      <Typography as={'caption'} className={styles['current-call-phone-line']} decoration={'u'} tag={5} weight={700}>
        {number}
      </Typography>
      <Typography as={'caption'} className={styles['current-call-phone']} decoration={'u'} tag={6}>
        {timer}
      </Typography>
    </div>
  )
}
