export type MasterCardType = {
  id?: number;
  name?: string;
};

export const MasterCardBlock: MasterCardType[] = [
  {
    id: 1,
    name: "Виды работ",
  },
  {
    id: 2,
    name: "Районы",
  },
  {
    id: 3,
    name: "Заявки",
  },
  {
    id: 4,
    name: "Штрафы и бонусы",
  },
  {
    id: 5,
    name: "Состояние мастера",
  },
];
