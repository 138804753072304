export const SuccessConnectionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none">
      <path
        d="M22.9999 3.83325C12.4314 3.83325 3.83325 12.4314 3.83325 22.9999C3.83325 33.5684 12.4314 42.1666 22.9999 42.1666C33.5684 42.1666 42.1666 33.5684 42.1666 22.9999C42.1666 12.4314 33.5684 3.83325 22.9999 3.83325ZM22.9999 38.3333C14.5455 38.3333 7.66659 31.4543 7.66659 22.9999C7.66659 14.5455 14.5455 7.66659 22.9999 7.66659C31.4543 7.66659 38.3333 14.5455 38.3333 22.9999C38.3333 31.4543 31.4543 38.3333 22.9999 38.3333Z"
        fill="#78B318"
      />
      <path
        d="M19.1648 26.0419L14.7583 21.6431L12.052 24.3571L19.1686 31.4584L32.0218 18.6052L29.3116 15.895L19.1648 26.0419Z"
        fill="#78B318"
      />
    </svg>
  )
}
