import { ResourcesRequestMasterShow } from "@/state/api";

export const countMasters = (masters: ResourcesRequestMasterShow[] | undefined) => {
  if (!masters) {
    return `0/0`
  }
  const total = masters.length;
  const appointedTotal = masters.filter(
    (item) => item.appointed && item.appointed.sended_to_app,
  ).length
  
  return `${appointedTotal}/${total}`;
}