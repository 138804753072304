import styles from './tabs-switcher.module.scss'
import { TabsSwitherProps } from '@components/tabs-switcher/tabs-switcher.types'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

const stepScroll = 60 // px

export const TabsSwitcher = ({ tabs, activeTab = 0, onChange, showNavButtons }: TabsSwitherProps) => {
  const [prevTab, setPrevTab] = useState(0)
  const prevButtonClasses = cn(styles['nav-btn'], {
    [styles['hide-nav-btn']]: activeTab === 0 || activeTab < tabs.length - 1,
  })

  const nextButtonClasses = cn(styles['nav-btn'], {
    [styles['hide-nav-btn']]: !(activeTab < tabs.length - 1),
  })

  const tabsSwitcherContainerClasses = cn(styles['tabs-switcher-container'], {
    [styles['first-item-active']]: activeTab !== tabs.length - 1,
  })

  const tabSwitcherRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (tabSwitcherRef && tabSwitcherRef?.current) {
      const isLastTab = activeTab === tabs.length - 1
      const isFirstTab = activeTab === 0
      if (isLastTab) {
        tabSwitcherRef.current.scrollLeft = tabSwitcherRef.current.scrollWidth
        return
      }
      if (isFirstTab) {
        tabSwitcherRef.current.scrollLeft = 0
        return
      }
      tabSwitcherRef.current.scrollLeft = stepScroll * (activeTab - prevTab)
    }
  }, [tabSwitcherRef?.current, activeTab])

  return (
    <div className={styles['tabs-switcher-wrapper']}>
      {showNavButtons && (
        <Button
          className={prevButtonClasses}
          btnType={'button'}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          iconLeft={<i className={'icon icon-cheveron-left'} />}
          onClick={() => {
            if (activeTab > 0) {
              onChange(activeTab - 1)
              setPrevTab(activeTab)
            }
          }}
        />
      )}
      <div ref={tabSwitcherRef} className={tabsSwitcherContainerClasses}>
        {Array.isArray(tabs) &&
          tabs.length > 0 &&
          tabs.map((tab) => {
            return (
              <Button
                key={tab.id}
                isActive={activeTab === tab.id}
                btnType={'button'}
                type={ButtonTypes.secondary}
                size={UISizes.medium}
                onClick={(e) => {
                  onChange(tab.id)
                  setPrevTab(activeTab)
                }}>
                {tab.text}
              </Button>
            )
          })}
      </div>
      {showNavButtons && (
        <Button
          className={nextButtonClasses}
          btnType={'button'}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          iconLeft={<i className={'icon icon-cheveron-right'} />}
          onClick={() => {
            if (activeTab < tabs.length - 1) {
              onChange(activeTab + 1)
              setPrevTab(activeTab)
            }
          }}
        />
      )}
    </div>
  )
}
