import { createMachine } from "xstate";

export enum States {
  dataEntry = "dataEntry",
  awaitingResponse = "awaitingResponse",
  dataEntryError = "dataEntryError",
  serviceError = "serviceError",
  success = "success",
}

export enum Events {
  ENTER_DATA = "ENTER_DATA",
  BLUR_DATA = "BLUR_DATA",
  SUBMIT = "SUBMIT",
  RESET = "RESET",
}

export enum Actions {
  setField = "setField",
  resetField = "resetField",
}

export interface FormFieldConfigValidationResult {
  result: boolean;
  errorMessage?: string;
}

export interface FormFieldConfig {
  required: boolean;
  field: string;
  validator?: (value: any) => FormFieldConfigValidationResult;
  parser?: (value: any) => void;
}

export interface FormErrors {
  [field: string]: {
    message?: string;
  };
}

export interface FormInputState {
  [field: string]: "default" | "disabled" | "active" | "error" | "filled";
}

export type FormMachineContext = {
  data: any;
  dataEntryErrors: FormErrors;
  serviceErrors: any;
  fields: FormFieldConfig[];
  canSubmit: boolean;
};

export interface FormMachineFactoryParams {
  predictableActionArguments?: boolean;
  fields: FormFieldConfig[];
  onSubmit: (context: any) => any; //(context: FormMachineContext) => Promise<any>
  onDone: (data: any) => any;
}

function canSubmit(context: FormMachineContext) {
  if (Object.keys(context.fields).length === 0) {
    return true;
  }
  return context.fields.reduce((acc, val) => {
    const fieldValue = context.data[val.field];
    const reqPredicate = val.required ? !!fieldValue : true;

    return (
      acc &&
      (typeof val.validator === "function"
        ? val.validator(context.data[val.field]).result
        : true) &&
      reqPredicate
    );
  }, true);
}

export const formMachineFactory = ({
  fields,
  onSubmit,
  onDone,
}: FormMachineFactoryParams) => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QDMD2AnAtgWmQQwGMAXDAT200IAsBLAOzADoI8i8BROo9UgYnYByAFXYAlAPoARAIJDpAbQAMAXUSgADqlg0iNVHTUgAHomwB2AJyMALAA4LARgCsigMzWL1xdYcAaEKSIThYAbIxOIQ5RAEwhtiEhbg4Avsn+aFi4hCQ8FNT0TCxsnNx8AEIAMgCqEjJySqpIIJrauvqGJgjRDtaMttauZk79tg4hA9H+gQiu0dGMiqG2Zh6urg6KzqnpGDj4xGR5BLQMzKwcXDy8AMpVZQCyAJJCDYYtOnoGTZ3dvf2DwzsYwmU0QXjCrmWQNsTi8rk2Zm2IAye2yh0oxwKZ2Klz4onY13YLxUby0H3a31MEUYzmsZjMiQc9gc7gSoIQG16rkcHmirgiDhWPiRKKyB1yGJOTDwAHc8B86FBRHBNHRYGBeBB9Ex6AA3VAAayYov2OXIkqxsvlukVytgqvVCD1qAIrE+DVeTXebS+oE6CSsFkUIXWiTM-xCkwCYPhNkW7gsMM8tj5It2YrNRyljCtCqVKv06t4YHQ6AwjHUABtWCjGCa0RL8qdczb8-bC2AnXR9a6fR6SV6yT6OogA4wgyGxopwwNI+zok55mZogzLImV-CQmnMqb0U3CucSjx2KWMPxhGIpLIFAONEPPiOOT5GHEzN54YpootFMH2WMzH0QyMrMIROEyFiuNuqLiua+7YhcpQnmW6C8PihLEo0d6tA+lIILCtiAd4fJ0jCwy2OynjhNOYEuGYgyftEUEZnumKnOq6C6jQBBgEhZ63A8zyelh5K+sYiBvmEoQRCsIbrtY1jspCiiMLMtiLC4dh8p+TG7o2rFMOxnHcbxKGCCItTXkJzT3hSfqIFEVhzMubjLP0n5DPOXJeIKLhqRYdH+TpDawfpjCGVxPGnihaFElZ3o4XZCD9GE0TWHMIT0sGYbkdGHKWAs4xOE49Lcj+rhbmkyLprpIXZrAACuBDcbAsCatqYVsEQxrVcFWZYg1TVwLAcU2aJnQjDSLjTmpdiKPEZiKZCCz2EVDgWC4CbWBVlV0KgEBwIY9YwX1DCkthtliQg2AOCuNjMhtHheD47LYJGjBDPy4ZmGMEFOO41hBcdFqnEUCE8GdImPkVfTEWsP4eCEFipSEikQTSawOWlFihKEgOZsD0pynmdoOmAEPDrhW0AUV6wrM4tj2OtCm5ZCrgqf8b7rtEowZXjLHZqDR6kCZ5MJZdVPhH9go+GR2OwopqV9BOayI2BdEA5VR343B4XGVFosXeNt1DD+KwRKbnjzvlwTDD0CSzD4jGaz1QM641zXwIO51jYgKYASb07WOb06W7lAxOC+X6jFEPiKMVTupEAA */
      predictableActionArguments: true,
      id: "form-factory-machine",
      initial: States.dataEntry,
      context: {
        data: {},
        dataEntryErrors: {} as FormErrors,
        serviceErrors: {} as FormErrors,
        fields,
        canSubmit: false,
      } as FormMachineContext,
      states: {
        [States.dataEntry]: {
          on: {
            [Events.ENTER_DATA]: {
              actions: Actions.setField,
            },
            [Events.BLUR_DATA]: [
              { cond: "isInvalid", target: States.dataEntryError },
            ],
            [Events.SUBMIT]: {
              cond: "canSubmitGuard",
              target: States.awaitingResponse,
            },
            [Events.RESET]: {
              actions: Actions.resetField,
            },
          },
        },
        [States.awaitingResponse]: {
          id: "submit",
          invoke: {
            src: (context) => {
              return onSubmit(context);
            },
            onDone: {
              target: States.success,
            },
            onError: [
              {
                actions: (context, event) => {
                  context.serviceErrors[event.type] = event.data;
                },
                target: States.serviceError,
              },
            ],
          },
        },
        [States.dataEntryError]: {
          on: {
            [Events.ENTER_DATA]: {
              // При вводе данных при ошибке нам нужно как установить данные, так и переключить state
              actions: Actions.setField,
              target: States.dataEntry,
            },
            [Events.RESET]: {
              actions: Actions.resetField,
            },
          },
        },
        [States.serviceError]: {
          on: {
            [Events.SUBMIT]: {
              target: States.awaitingResponse,
            },
            [Events.ENTER_DATA]: {
              // При вводе данных при ошибке нам нужно как установить данные, так и переключить state
              actions: Actions.setField,
              target: States.dataEntry,
            },
            [Events.RESET]: {
              actions: Actions.resetField,
            },
          },
        },
        [States.success]: {
          //кидаем done event
          type: "final",
          onDone: {
            actions: onDone,
          },
        },
      },
    },
    {
      actions: {
        [Actions.setField]: (context, event) => {
          const field = context.fields.find(
            (f) => f.field === event.data.field
          );

          context.data[event.data.field] = field?.parser
            ? field.parser(event.data.value)
            : event.data.value;
          delete context.dataEntryErrors[event.data.field];
          context.canSubmit = canSubmit(context);
        },
        [Actions.resetField]: (context, event) => {
          const initErrors = {} as FormErrors;
          context.data[event.data.field] = "";
          context.dataEntryErrors[event.data.field] = initErrors;
          context.serviceErrors[event.data.field] = initErrors;
          context.canSubmit = canSubmit(context);
        },
      },
      guards: {
        isInvalid: (context, event) => {
          if (!event.data.value) {
            return false;
          }

          const field = context.fields.find(
            (f) => f.field === event.data.field
          );

          if (!field) {
            return false;
          }

          const result: FormFieldConfigValidationResult = field.validator
            ? field.validator(event.data.value)
            : {
                result: true,
              };

          if (!result.result) {
            context.dataEntryErrors[event.data.field] = {
              message: result.errorMessage,
            };
          }
          return !result.result;
        },
        canSubmitGuard: (context) => {
          return context.canSubmit;
        },
      },
    }
  );
};
