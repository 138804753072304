import JSSIP from "jssip";
import type { CallOptions } from "jssip/lib/UA";

JSSIP.debug.disable();

const callOptions: CallOptions = {
  mediaConstraints: {
    audio: true,
    video: false,
  },

  rtcOfferConstraints: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: false,
  },
  sessionTimersExpires: 7200,

  pcConfig: {
    iceServers: [
      {
        urls: [
          "stun:stun3.l.google.com:19302",
          "stun:stun4.l.google.com:19302",
        ],
      },
    ],
  },
};

const url = new URL(import.meta.env.REACT_APP_WSS_URL || "");

const wssUrl = `${url.protocol}//${url.hostname}${
  url.port ? `:${url.port}` : ""
}${url.pathname}`;

const socketInterface = new JSSIP.WebSocketInterface(wssUrl);

const createJSSipConfiguration = (password: string, phone: string) => {
  const uri = `sip:${phone}@${url.hostname}`;

  return {
    uri: uri,
    password: password,
    display_name: phone,
    sockets: [socketInterface],
  };
};

export const PhonerConfig = {
  createJSSipConfiguration,
  url,
  wssUrl,
  callOptions,
};
