import { FiltersStateProvider } from '@/contexts/filters-state'
import AppLayout from '@/app/app-layout'
import { FullCodeRequestProvider } from '@/contexts/scroll-pos'
import { useEffect, useRef, useState } from 'react'

function App() {
  return (
    <>
      <FiltersStateProvider>
        <FullCodeRequestProvider>
          <AppLayout />
        </FullCodeRequestProvider>
      </FiltersStateProvider>
    </>
  )
}

export default App
