import { Row } from 'react-grid-system'
import { InstallmentFields, InstallmentServerData } from '@/constants/installment'
import { Typography } from '@/components/common'
import style from '../../installment.module.scss'

export const InstallmentInfoLeftColumn = ({ data }: any): JSX.Element => {
  return (
    <>
      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.contractNumber}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-data']}>
          {data?.[InstallmentServerData.agreement] ?? '\u2014'}
        </Typography>
      </Row>
      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.dateOfCommencementOfInstallment}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-data']}>
          {data?.[InstallmentServerData.date_begin] ?? '\u2014'}
        </Typography>
      </Row>
      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.dateOfSigningTheContract}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-data']}>
          {data?.[InstallmentServerData.contract_date] ?? '\u2014'}
        </Typography>
      </Row>
      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.installmentPeriod}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-data']}>
          {data?.[InstallmentServerData.period] ?? '\u2014'}
        </Typography>
      </Row>
    </>
  )
}
