import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { formatObjectToQueryString } from "@utils/format-object-to-qs";
import { API_BASE_URL, OLD_AUTH_URL } from "@/constants/env";

const BASE_URL = API_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    // headers.set(
    //   'Content-Type', 'application/json; charset=UTF-8',
    // );
    headers.set("X-Requested-With", "XMLHttpRequest");

    // const token = (getState() as RootState).user.token.accessToken
    // if (token) {
    //   headers.set('Authorization', `Bearer ${token}`)
    // }
    return headers;
  },
  paramsSerializer: (params) => formatObjectToQueryString(params),
});

const mutex = new Mutex();

export const authCheckQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        //@ts-ignore
        const redirectURL = result?.error?.data?.data?.redirect_url as string;

        window.location.replace(redirectURL ? redirectURL : OLD_AUTH_URL);
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
export const apiSlice = createApi({
  baseQuery: authCheckQuery,

  endpoints: () => ({}),
});
