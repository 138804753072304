import { useEffect } from "react";
import { DeclineCallButton } from "../../../../shared/components";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";
import { DECLINE_INCOMING_CALL } from "../../../../shared/event-bus/types/event-action.types";

export const DeclineIncomingCall = () => {
	const { endCall, sessions } = usePhoner();
	const { on } = useEventBus();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { sessionsDTO } = useSessionContext();
	const incomingCallSession = sessionsDTO.filter((session) => session.status === SessionStatus.RINGING)?.[0];

	const handleDeclineCall = () => {
		const sessionId = incomingCallSession?.sessionId || "";
		endCall(sessionId);
	};

	useEffect(() => {
		const unsubscribeDeclineIncomingCall = on(DECLINE_INCOMING_CALL, ({ sessionId }) => {
			endCall(sessionId, false);

			return () => unsubscribeDeclineIncomingCall();
		});
	}, [isCurrentTabMasterTab, sessionsDTO, sessions]);

	return <DeclineCallButton handleDeclineCall={handleDeclineCall} isDeclineCallButtonDisabled={false} />;
};
