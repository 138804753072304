import { TabsSwitcher } from "@/components";
import { Typography } from "@components/common";
import { RequestComments } from "@pages/request-page/log-card/left-content/comments";
import type { LogCardLeftContentProps } from "@pages/request-page/log-card/left-content/logcard-left-content.types";
import { TableRequestHistory, TableRequestScheduler } from "@pages/request-page/log-card/left-content/tables";
import { createColumnHelper } from "@tanstack/react-table";
import { clearReklamaAndObjectText } from "@utils/clear-text";
import { useState } from "react";
import styles from "../request-page-logcard.module.scss";
import QualityControlComments from "./comments/quality-control-comments";

export const LogCardLeftContent = ({}: Partial<LogCardLeftContentProps>) => {
	const [active, setActive] = useState(0);
	const handleClick = (id: number) => {
		setActive(id);
	};
	const columnHelper = createColumnHelper<any>();

	const columnsScheduler = [
		//указываем ключи объекта первым аргументом
		columnHelper.accessor("master", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Мастер
				</Typography>
			),
			cell: (info) => {
				return info.cell.getContext().row.original.master.fio;
			},
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("date", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата с
				</Typography>
			),
			cell: (info) => info.renderValue(),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("date_to", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата по
				</Typography>
			),
			cell: (info) => info.renderValue(),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("comment", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Комментарий
				</Typography>
			),
			footer: (info) => info.column.id,
		}),
	];

	const columnsHistory = [
		//указываем ключи объекта первым аргументом
		columnHelper.accessor("date", {
			size: 140,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата и время
				</Typography>
			),
			cell: (info) => info.getValue(),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor((row) => row.user_fio, {
			id: "user_fio",
			cell: (info) => info.renderValue(),
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					ФИО
				</Typography>
			),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("action", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Действие
				</Typography>
			),
			cell: (info) => info.renderValue(),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("field", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Поле{" "}
				</Typography>
			),
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("before", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Было{" "}
				</Typography>
			),
			cell: (info) => {
				return clearReklamaAndObjectText(info.renderValue());
			},
			footer: (info) => info.column.id,
		}),
		columnHelper.accessor("after", {
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Стало
				</Typography>
			),
			cell: (info) => {
				return clearReklamaAndObjectText(info.renderValue());
			},
			footer: (info) => info.column.id,
		}),
	];

	return (
		<div className={styles["request-page-logcard-content"]}>
			<TabsSwitcher
				showNavButtons
				onChange={(id) => handleClick(id)}
				tabs={[
					{ id: 0, text: "Комментарии" },
					{ id: 1, text: "Результаты анализа" },
					{ id: 2, text: "Планировщик" },
					{ id: 3, text: "История заявки" },
				]}
				activeTab={active}
			/>

			{active === 0 && <RequestComments isRequestPage />}
			{active === 1 && <QualityControlComments />}
			{active === 2 && <TableRequestScheduler columns={columnsScheduler} />}
			{active === 3 && (
				<div className={styles["request-page-logcard-content_history-table-container"]}>
					<div className={styles["request-page-logcard-content_history-table-container-content"]}>
						<TableRequestHistory className={styles["request-history-table"]} columns={columnsHistory} />
					</div>
				</div>
			)}
		</div>
	);
};
