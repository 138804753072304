import type { FC } from 'react'
import styles from './files-item.module.scss'
import { FilesItemContent } from '@components/files/files-item/files-item-content'
import type { AttachmentIndexResource } from '@/state/api'
import { newWindowOpen } from '@/utils/new-window'

// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
export const FilesItem: FC<{ data: AttachmentIndexResource }> = ({ data }): JSX.Element => {
  const imageTypes = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/webp', 'image/png', 'image/svg+xml']
  const checkType = (type: string) => {
    switch (type) {
      case 'pdf':
        return 'icon-pdf'
      case 'docx':
      case 'doc':
        return 'icon-doc'
      case 'plain':
        return 'icon-txt'
      default:
        return 'icon-doc'
    }
  }
  const classForIcon = data?.type && checkType(data?.type)
  const imagePath = data.path_thumbnail || data.path

  return (
    <div className={styles.files_item}>
      {data?.type && imageTypes.includes(data?.type) ? (
        <img
          onClick={() => {
            newWindowOpen(`/api/attachments/image?path=${data.path}`)
          }}
          src={`/api/attachments/image?path=${imagePath}`}
          className={styles.files_item_img}
        />
      ) : (
        <div className={styles.files_item_icon}>
          <i className={classForIcon} />
        </div>
      )}
      <FilesItemContent data={data} />
    </div>
  )
}
