import { flexRender } from '@tanstack/react-table'
import { TableRow, TableRowItem } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableBody, TableCol, TableColItem } from '@components/common'
import { InstallmentJournalTableHeader } from './installment-journal-table-header'

// @ts-ignore
export const InstallmentJounalTable = ({ table, className = '' }) => {
  const tableCheck = table && table.getRowModel().rows.length

  return (
    <>
      <InstallmentJournalTableHeader />
      <TableContainer style={{ paddingRight: '0' }} className={''}>
        <TableBody>
          {!tableCheck ? (
            <TableRow>
              <TableRowItem>Данные отсутствуют</TableRowItem>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow key={row.id} style={{ background: 'transparent', height: 'auto' }}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <TableRowItem
                          style={{
                            maxWidth: cell.column.getSize() !== 150 ? `${cell.column.getSize()}px` : '100%',
                            boxShadow: 'var(--gray-50-border-bottom)',
                          }}
                          key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableRowItem>
                      )
                    })}
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>
      </TableContainer>
    </>
  )
}
