import { LoadingSpinner } from '@components/button/loading-spinner'
import { Snackbar } from '../../snackbar'
import styles from '../../snackbar.module.scss'
import React from 'react'
import { SnackbarLoadingType } from '@components/snackbar/snackbar.types'
import { Typography } from '@components/common'

export const SnackbarLoading = ({
  text = '',
  isMobile,
}: SnackbarLoadingType) => {
  return (
    <Snackbar type={'loading'} isMobile={isMobile}>
      <div className={styles['snackbar-content']}>
        <div className={styles['snackbar-action-container']}>
          <div className={styles['snackbar-spinner']}>
            <LoadingSpinner />
          </div>
          <Typography as={'paragraph'} decoration={'none'} tag={4}>
            {text}
          </Typography>
        </div>
      </div>
    </Snackbar>
  )
}
