import { useButtonSwitch } from '@/hooks'
import { ButtonSwitchContainer } from '@common/index'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { FilterCheckboxTypes } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-checkbox/filter-checkbox.types'
import { useField } from 'react-final-form'
import { CreateRequestNames } from '@constants/create-request'

export const RequestModalEntitySwitcher = <T extends boolean>({
  showLabel = false,
  label = '',
  activeButton = 0,
  ...props
}: FilterCheckboxTypes<T>) => {
  const legalEntityField = useField(CreateRequestNames.isLegalEntity).input
    .value
  const { activeButton: currActiveButton, toggleActiveButton } =
    useButtonSwitch(legalEntityField ? 0 : 1)

  return (
    <ButtonSwitchContainer showLabel={showLabel} label={label}>
      <Button
        isActive={!legalEntityField}
        btnType={'button'}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        onClick={() => {
          toggleActiveButton()
          props.input.onChange(false)
        }}
      >
        Физическое
      </Button>
      <Button
        btnType={'button'}
        isActive={legalEntityField}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        onClick={() => {
          toggleActiveButton()
          props.input.onChange(true)
        }}
      >
        Юридическое
      </Button>
    </ButtonSwitchContainer>
  )
}
