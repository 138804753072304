import { Button } from '../../../button'
import { Snackbar } from '../../snackbar'
import styles from '../../snackbar.module.scss'
import React from 'react'
import { UISizes } from '@/global-types/mods.types'
import { ButtonTypes } from '@components/button/button.types'
import { SnackbarErrorType } from '@components/snackbar/snackbar.types'
import { Typography } from '@components/common'
import { CloseButton } from '@/components'

export const SnackbarError = ({
  isMobile,
  withAction,
  handleClick,
  handleClose = () => {},
  text = '',
  actionButtonText,
}: SnackbarErrorType) => {
  return (
    <Snackbar type={'error'} isMobile={isMobile} withAction={withAction}>
      <div className={styles['snackbar-content']}>
        <Typography as={'paragraph'} decoration={'none'} tag={4}>
          {text}
        </Typography>
        <div className={styles['snackbar-action-container']}>
          {withAction && (
            <Button
              onClick={() => handleClick && handleClick()}
              size={UISizes.medium}
              btnType={'button'}
              type={ButtonTypes.secondary}
            >
              {actionButtonText}
            </Button>
          )}
          <CloseButton
            size={UISizes.medium}
            btnType={ButtonTypes.secondary}
            onClick={handleClose}
            iconClassName={'icon icon-close'}
          />
        </div>
      </div>
    </Snackbar>
  )
}
