import { createMachine } from "xstate";
import {
  ANIMATION_DIRECTIONS,
  TRANSLATE_DIRECTION,
} from "../../../../shared/constants/animation-direction/animation-direction";

const initialState = {
  isDragging: false,
  position: { x: window.innerWidth / 2, y: 0 },
  animationTransitionConfig: {
    animationDirection: ANIMATION_DIRECTIONS.TOP,
    translateDirection: TRANSLATE_DIRECTION.CENTER,
  },
};

const prepareInitialContext = () => {
  const persistState = JSON.parse(
    localStorage.getItem("phoner-dragging-state") || "null"
  );

  if (!persistState) return initialState;

  return { ...persistState.context };
};

const getAnimationTransitionDirection = (position: {
  x: number;
  y: number;
}) => {
  const animationTransitionConfig = {
    animationDirection: "",
    translateDirection: "",
  };

  const firstSquareBorder = window.innerWidth * 0.25;
  const secondSquareBorder = window.innerWidth * 0.75;

  const isPhonerAboveViewPortMiddle =
    Math.abs(position.y) > window.innerHeight / 2;

  const isPhonerOnLeftEdge = Math.abs(position.x) < firstSquareBorder;

  animationTransitionConfig.animationDirection = isPhonerAboveViewPortMiddle
    ? ANIMATION_DIRECTIONS.BOTTOM
    : ANIMATION_DIRECTIONS.TOP;

  animationTransitionConfig.translateDirection = isPhonerOnLeftEdge
    ? TRANSLATE_DIRECTION.LEFT
    : TRANSLATE_DIRECTION.RIGHT;

  if (
    Math.abs(position.x) > firstSquareBorder &&
    Math.abs(position.x) < secondSquareBorder
  ) {
    animationTransitionConfig.translateDirection = TRANSLATE_DIRECTION.CENTER;
  }

  return animationTransitionConfig;
};

export const phonerDraggingMachine = createMachine(
  {
    id: "dragging",
    initial: "idle",
    context: prepareInitialContext(),
    states: {
      idle: {
        on: {
          draggingStarted: {
            actions: {
              type: "setDraggingStart",
            },
          },
          draggingEnd: {
            actions: {
              type: "setDraggingEnd",
            },
          },
          positionChanged: {
            actions: {
              type: "setNewPosition",
            },
          },
          changeAnimationDirection: {
            actions: {
              type: "setAnimationDirection",
            },
          },
        },
      },
    },
    schema: {
      events: {} as
        | {
            type: "draggingStarted";
          }
        | {
            type: "draggingEnd";
          }
        | {
            type: "positionChanged";
            value: { x: number; y: number };
          }
        | {
            type: "changeAnimationDirection";
            value: "top" | "bottom";
          },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },

  {
    actions: {
      setDraggingStart: (context, event) => {
        if (event.type === "draggingStarted") {
          context.isDragging = true;
        }
      },
      setDraggingEnd: (context, event) => {
        if (event.type === "draggingEnd") {
          context.isDragging = false;
        }
      },
      setNewPosition: (context, event) => {
        if (event.type === "positionChanged") {
          const shiftX = event.value.x;
          const shiftY = event.value.y;

          context.position = { x: shiftX, y: shiftY };
          context.animationTransitionConfig = getAnimationTransitionDirection(
            event.value
          );
        }
      },
      setAnimationDirection: (context, event) => {
        if (event.type === "changeAnimationDirection") {
          context.animationTransitionConfig = event.value;
        }
      },
    },
  }
);
