import { useEffect, useRef, useState } from 'react'
import { Icon, Panel, Typography } from '../../../shared'
import { usePhoner } from '../../../shared/contexts/phoner/phoner'
import styles from './panel-tray.module.scss'
import { DraggableArea } from '../../../shared/components/draggable-area'

type PanelTrayProps = {
  handleClick: () => void
  handleMouseLeave?: () => void
}
export const PanelTray = ({
  handleClick,
  handleMouseLeave = () => {},
}: PanelTrayProps) => {
  const refTrey = useRef(null)
  const {setCurrentActiveWidget} = usePhoner();

  useEffect(() => {
    if (refTrey.current) {
      setCurrentActiveWidget(refTrey.current)
    }
   
  }, [refTrey.current])

  return (
    <DraggableArea>
    <Panel
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      className={styles.container}>
      <Panel.Body className={styles['panel-tray']}>
        <Icon icon={'icon-phone'} />
        <Typography as={'caption'} decoration={'none'} tag={5}>
          Фонер
        </Typography>
      </Panel.Body>
    </Panel>
    </DraggableArea>

  )
}


