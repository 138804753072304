import { useComponentVisible } from "@/hooks";
import { type RequestStateResource, type RequestsChangeStateApiResponse, coreApi, useRequestsChangeStateMutation } from "@/state/api";
import { getDate } from "@/utils/get-date";
import { format } from "date-fns";
import { type FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button } from "../button";
import { ButtonTypes } from "../button/button.types";
import { Calendar } from "../calendar";
import { InputDefault } from "../common/multi-select";
import { generateDateString } from "../headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import styles from "./status-button.module.scss";

import { StatusRequestTypes } from "@/global-types/status-request.types";
import { useAppDispatch } from "@/state/selector";
import { validateDateForCreateEditRequest } from "@components/create-status-button/validateDateForCreateEditRequest";
import { useForm } from "react-final-form";

const StatusButton = ({ state, statusId }: { state: RequestStateResource; statusId?: number }) => {
	const dispatch = useAppDispatch();
	const params = useParams();
	const form = useForm();

	const [inputDateValue, setInputDateValue] = useState(getDate());
	const [stateComment, setStateComment] = useState("");
	const [commentRef, showComment, setShowComment] = useComponentVisible();

	const [ref, isVisible, setIsVisible] = useComponentVisible(undefined, undefined, undefined, ["input", "button", "calendar"]);
	const [changeState, { isLoading, reset }] = useRequestsChangeStateMutation({
		fixedCacheKey: "changeRequestsState",
	});

	const [date, setDate] = useState(new Date());

	const [dateRef, showCalendar, setShowCalendar] = useComponentVisible(undefined, undefined, undefined, ["input", "button", "calendar"]);
	const [isInvalid, setIsInvalid] = useState(false);

	useEffect(() => {
		const {
			inputDateValue: validatedInputDateValue,
			isInvalid: validatedIsInvalid,
			date: validatedDate,
		} = validateDateForCreateEditRequest(inputDateValue);
		setInputDateValue(validatedInputDateValue);
		setIsInvalid(validatedIsInvalid);
		setDate(validatedDate);
	}, [inputDateValue]);

	const dispatchingToRequestsIndexStore = (data: RequestsChangeStateApiResponse) => {
		dispatch(
			coreApi.util.updateQueryData("requestsIndex", {}, (draftIndex) => {
				if (draftIndex) {
					const targetRequestData = draftIndex.data?.find((item) => String(item.id) === String(params.requestId)) || {};

					Object.assign(targetRequestData, data?.data);
				} else {
					return draftIndex;
				}
			}),
		);

		dispatch(
			coreApi.util.updateQueryData("requestsShow", { requestId: Number(params.requestId) || 0 }, (draft) => {
				draft?.data ? Object.assign(draft.data, data?.data) : undefined;
			}),
		);
	};

	const handleClick = async () => {
		if (params.requestId) {
			const data = {
				requestId: Number(params.requestId),
				requestsRequestChangeState: {
					state_id: state.id,
					state_comment: state.is_need_set_comment ? state.title : undefined,
					wait_until: state.is_need_set_date ? inputDateValue : undefined,
				},
			};
			try {
				const response = await changeState(data);
				const dataResponse = "data" in response ? response.data : {};
				form.change("status", dataResponse?.data?.state_id);
				showCalendar && setShowCalendar(false);
				showComment && setShowComment(false);
				if ("data" in response) dispatchingToRequestsIndexStore(dataResponse);
			} catch (e) {
				console.error(e);
				reset();
			} finally {
				reset();
			}
		}
	};

	const handleClickWrapper = () => {
		handleClick();
	};
	const openPopup = () => {
		if (state.id === StatusRequestTypes.ClientWaiting) {
			handleClickWrapper();
			return;
		}
		if (state.is_need_set_date) {
			setShowCalendar(true);
			return;
		}
		if (state.is_need_set_comment) {
			setShowComment(true);
			return;
		}
		handleClickWrapper();
	};

	return (
		<div className={styles["status-container"]}>
			<Button
				disabled={!!isLoading}
				className={showCalendar || showComment ? styles["button-active"] : ""}
				onClick={() => (state.id === 15 ? handleClickWrapper() : openPopup())}
				type={ButtonTypes.secondary}
			>
				{state.title}
			</Button>
			{showCalendar && (
				<div ref={dateRef} className={statusId && statusId === 15 ? styles["status-popup-right"] : styles["status-popup"]}>
					<InputDefault
						width={"full-w"}
						id={"calendar"}
						type={"text"}
						withRightButton={true}
						labelText={"Ожидание до:"}
						state={`${isInvalid && !isVisible ? "error" : "default"}`}
						mask={generateDateString()}
						inputProps={{
							value: inputDateValue,
							onChange: (e: FormEvent<HTMLInputElement>) => setInputDateValue(e.currentTarget.value),
							onFocus: () => setIsVisible(true),
						}}
						renderDropdownMenu={() => (
							<div ref={ref} className={styles["calendar-container"]}>
								<Calendar
									className={styles.calendar}
									open={isVisible}
									selectedTime={format(date, "HH:mm")}
									showTimeInput
									popperPlacement={"bottom"}
									minDate={new Date()}
									selected={date}
									popperModifiers={[
										{
											name: "offset",
											options: {
												offset: [150, 25],
											},
										},
									]}
									timeActionButton={() => {
										return (
											<Button
												disabled={isInvalid}
												className={styles["status-button"]}
												onClick={() => handleClickWrapper()}
											>
												Сохранить
											</Button>
										);
									}}
									withoutTriggerButton
									onChange={(date) => date && setInputDateValue(format(date, "dd.MM.yyyy HH:mm"))}
								/>
							</div>
						)}
					/>
				</div>
			)}
			{showComment && (
				<div ref={commentRef} className={styles["status-popup"]}>
					<InputDefault
						withResetButton
						onReset={() => setStateComment("")}
						inputContainerOrientation="column"
						withRightButton={false}
						width={"full-w"}
						id={"error-description"}
						type={"text"}
						maxLength={80}
						labelText={"Описание ошибки:"}
						state={"default"}
						placeholder="Введите описание ошибки..."
						inputProps={{
							value: stateComment,
							onChange: (e: FormEvent<HTMLInputElement>) => setStateComment(e.currentTarget.value),
							onClick: () => setIsVisible(true),
						}}
					/>
					<Button disabled={!stateComment} className={styles["status-button"]} onClick={() => handleClickWrapper()}>
						Изменить статус
					</Button>
				</div>
			)}
		</div>
	);
};

export default StatusButton;
