import {
  RequestCardActionsProps
} from '@common/request-card/request-card.types'
import styles from '../request-card.module.scss'

export const RequestCardActions = ({children}: Partial<RequestCardActionsProps>) => {
  return (
    <div className={styles['request-card_actions']}>
      {children}
    </div>
  )
}
