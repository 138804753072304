import { Typography } from '../../../../shared'
import styles from './empty-plug.module.scss'

export const EmptyPlug = () => {
  return (
    <div className={styles.wrapper}>
      <Typography as={'caption'} weight={600} style={{ color: 'var(--phoner-gray-200)' }} decoration="none" tag={4}>
        Звонков еще не было
      </Typography>
      <Typography as={'caption'} weight={400} style={{ color: 'var(--phoner-gray-500)' }} decoration="none" tag={5}>
        Здесь будет отображаться история входящих и исходящих звонков
      </Typography>
    </div>
  )
}
