import { SidebarMenuItemsContainerProps } from '@common/sidebar-menu/sidebar-menu.types'
import styles from '../sidebar-menu.module.scss'
import { SidebarMenuDivider } from '@common/sidebar-menu'

export const SidebarMenuItemsContainer = ({
  children,
  showDivider,
}: Partial<SidebarMenuItemsContainerProps>) => {
  return (
    <>
      <div className={styles['sidebar-menu_items-container']}>{children}</div>
      {showDivider && <SidebarMenuDivider />}
    </>
  )
}
