import { Button, Icon } from "../../../index";
import type React from "react";

type DeclineCallIconProps = {
	handleDeclineCallIcon: () => void;
	isDeclineCallIconDisabled: boolean;
	className?: string;
};
export const DeclineCallIconButton = ({ handleDeclineCallIcon, isDeclineCallIconDisabled, className }: DeclineCallIconProps) => {
	return (
		<Button
			className={className}
			disabled={isDeclineCallIconDisabled}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				handleDeclineCallIcon();
			}}
			size="m"
			variant="danger"
			width="icon"
			renderIcon={<Icon icon={"icon-terminate"} />}
		/>
	);
};
