import React, { type FC, useEffect } from "react";
import { formatTime } from "../../../utils/time-utils";

interface ITimer {
	isStarted: boolean;
	startFrom?: number;
	isPaused: boolean;
	onChange?: (value: number) => void;
	placeholder?: string;
}

export const Timer: FC<ITimer> = ({ isStarted, startFrom, isPaused, onChange, placeholder }) => {
	const [elapsedTimeForFirstLine, setElapsedTimeForFirstLine] = React.useState(startFrom || 0);

	useEffect(() => {
		let intervalForFirstCall: ReturnType<typeof setInterval>;

		if (isStarted || isPaused) {
			intervalForFirstCall = setInterval(() => {
				setElapsedTimeForFirstLine((elapsedTimeForFirstLine) => elapsedTimeForFirstLine + 1);
			}, 1000);
		}

		return () => {
			clearInterval(intervalForFirstCall);
		};
	}, [isStarted, isPaused]);

	useEffect(() => {
		if (onChange) onChange(elapsedTimeForFirstLine);
	}, [elapsedTimeForFirstLine]);

	useEffect(() => {
		setElapsedTimeForFirstLine(startFrom || 0);
	}, [startFrom]);
	return <>{placeholder ? placeholder : formatTime(elapsedTimeForFirstLine)}</>;
};
