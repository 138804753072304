import type { ReactNode } from "react";
import { Panel } from "../../../shared";
import styles from "./incoming-call.module.scss";
interface IncomingCallProps {
	topSlot?: ReactNode;
	middleSlot: ReactNode;
	bottomSlot?: ReactNode;
	incomingCallWidgetPosition?: string;
	withDivider?: boolean;
}
export const IncomingCall = ({ middleSlot = <></>, incomingCallWidgetPosition, withDivider = true }: IncomingCallProps) => {
	const dividerStyle = `${
		incomingCallWidgetPosition === "top" ? styles["incoming-call-bottom-divider"] : styles["incoming-call-top-divider"]
	}`;

	return (
		<Panel className={styles["custom-panel-styles"]}>
			<Panel.Body>
				<div className={`${styles["incoming-call-wrapper"]} ${withDivider ? dividerStyle : ""}`}>{middleSlot}</div>
			</Panel.Body>
		</Panel>
	);
};
