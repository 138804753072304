import { createMachine } from 'xstate'

export const phonerMenuMachine = createMachine(
  {
    id: 'menu',
    initial: 'menu closed',
    states: {
      'menu closed': {
        on: {
          'open menu': {
            target: 'menu opened',
          },
        },
      },
      'menu opened': {
        description:
          '`Фонер находится в развернутом состоянии. Меню открыто.\n\nМожем: \n - Перейти в Список контактов\n - Перейти в Журнал звонков\n-  Перейти в Настройки аудио\n- Перейти в Завершающие записи\n- Перейти в Горячие клавиши\n- Закрыть меню\n\n- Начать быстрый поиск по контактам;\n- Выбрать контакт для звонка из списка.\n- Начать звонок с выбранным контактом при нажатии по кнопке звонка справа от input`',
        initial: 'menu opened',
        states: {
          'menu opened': {
            on: {
              'call logs': {
                target: 'call logs',
              },
              settings: {
                target: 'settings',
              },
              'hot keys': {
                target: 'hot keys',
              },
              contacts: {
                target: 'contacts',
              },
              'final call recordings': {
                target: 'New state 4',
              },
            },
          },
          'call logs': {},
          settings: {},
          'hot keys': {},
          contacts: {
            description:
              '`Фонер находится в развернутом состоянии. Открыты контакты.\n\n(отображается инпут соответсвующий дизайн макету для поиска по контактам и список контактов)\nМожем: \n- Начать поиск по контактам;\n- Добавить контакт в избранное\n- Начать звонок с выбранным контактом при нажатии по кнопке звонка справа от контакта\n- Вернуться в открытое меню\n-  Закрыть меню`',
          },
          'New state 4': {},
        },
        on: {
          back: {
            target: '.menu opened',
            internal: true,
          },
          'close menu': {
            target: 'menu closed',
          },
        },
      },
    },
    schema: {
      events: {} as
        | { type: 'back' }
        | { type: 'call logs' }
        | { type: 'settings' }
        | { type: 'hot keys' }
        | { type: 'contacts' }
        | { type: 'final call recordings' }
        | { type: 'open menu' }
        | { type: 'close menu' },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    //tsTypes: {},
  },
  {
    actions: {},
    services: {},
    guards: {},
    delays: {},
  },
)
