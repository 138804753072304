import React from 'react'
import { Button } from '../../../ui-kit/button'
import { Icon } from '../../../ui-kit/icon'

type HoldCallIconButtonProps = {
  handleCallOnHold: () => void
  isCallOnHold: boolean
  isDisabled: boolean
}
export const HoldCallIconButton = ({
  handleCallOnHold,
  isDisabled,
  isCallOnHold,
}: HoldCallIconButtonProps) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        handleCallOnHold()
      }}
      size="m"
      variant={isCallOnHold ? 'primary' : 'secondary'}
      width="icon"
      renderIcon={<Icon icon={isCallOnHold ? 'icon-play' : 'icon-pause'} />}
    />
  )
}
