import { useComponentVisible } from "@/hooks";
import type { TypeSelectableEnum } from "@/state/api";
import { getDate } from "@/utils/get-date";
import { validateDateForCreateEditRequest } from "@components/create-status-button/validateDateForCreateEditRequest";
import { StatusRequestTypes } from "@global-types/status-request.types";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import type { FormEvent } from "react";
import { useForm } from "react-final-form";
import { Button } from "../button";
import { ButtonTypes } from "../button/button.types";
import { Calendar } from "../calendar";
import { InputDefault } from "../common/multi-select";
import { generateDateString } from "../headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import styles from "./create-status-button.module.scss";

type CreateRequestStatusButtonProps = {
	state: TypeSelectableEnum;
};
export const CreateRequestStatusButton = ({ state }: CreateRequestStatusButtonProps) => {
	const form = useForm();
	const statusField = form.getState().values?.status;
	const [inputDateValue, setInputDateValue] = useState(getDate());
	const [date, setDate] = useState(new Date());
	const [isInvalid, setIsInvalid] = useState(false);

	const handleChangeState = () => {
		if (state?.id === StatusRequestTypes.ClientWaiting && isInvalid) {
			return;
		}

		form.change("status", state.id);
		state?.id === StatusRequestTypes.OrderError && form.change("stateComment", "*");
		state?.id === StatusRequestTypes.ClientWaiting ? form.change("waitUntil", inputDateValue) : form.change("waitUntil", undefined);
	};

	const [dateRef, showCalendar, setShowCalendar] = useComponentVisible(undefined, undefined, undefined, ["input", "button", "calendar"]);
	const [ref, isVisible, setIsVisible] = useComponentVisible(true, handleChangeState, undefined, [
		"input",
		"button",
		"refusal-status-popup",
		"calendar",
	]);

	useEffect(() => {
		const {
			inputDateValue: validatedInputDateValue,
			isInvalid: validatedIsInvalid,
			date: validatedDate,
		} = validateDateForCreateEditRequest(inputDateValue);
		setInputDateValue(validatedInputDateValue);
		setIsInvalid(validatedIsInvalid);
		setDate(validatedDate);
	}, [inputDateValue]);

	const openPopup = () => {
		if (state?.id === StatusRequestTypes.ClientWaiting) {
			handleChangeState();
			return;
		}

		handleChangeState();
	};

	useEffect(() => {
		if (statusField !== state.id) {
			setInputDateValue(getDate());
			setShowCalendar(false);
		}
	}, [statusField]);

	if (statusField === state?.id) {
		return null;
	}

	return (
		<div className={styles["status-container"]}>
			<Button className={showCalendar ? styles["button-active"] : ""} onClick={openPopup} type={ButtonTypes.secondary}>
				{state.title}
			</Button>
			{showCalendar && (
				<div ref={dateRef} className={styles["status-popup"]}>
					<InputDefault
						width={"full-w"}
						id={"calendar"}
						type={"text"}
						withRightButton={true}
						labelText={"Ожидание до:"}
						state={`${isInvalid ? "error" : "default"}`}
						mask={generateDateString()}
						inputProps={{
							value: inputDateValue,
							onChange: (e: FormEvent<HTMLInputElement>) => {
								setInputDateValue(e.currentTarget.value);
							},
							onClick: () => setIsVisible(true),
							onFocus: () => setIsVisible(true),
						}}
						renderDropdownMenu={() => (
							<div ref={ref} className={styles["calendar-container"]}>
								<Calendar
									className={styles.calendar}
									open={isVisible}
									selectedTime={format(date, "HH:mm")}
									showTimeInput
									showTimeSelect
									popperPlacement={"bottom"}
									minDate={new Date()}
									selected={date}
									popperModifiers={[
										{
											name: "offset",
											options: {
												offset: [150, 25],
											},
										},
									]}
									timeActionButton={() => {
										return (
											<Button
												disabled={isInvalid}
												className={styles["status-button"]}
												onClick={() => {
													handleChangeState();
												}}
											>
												Сохранить
											</Button>
										);
									}}
									withoutTriggerButton
									onChange={(date) => {
										date && setInputDateValue(format(date, "dd.MM.yyyy HH:mm"));
									}}
								/>
							</div>
						)}
					/>
				</div>
			)}
		</div>
	);
};
