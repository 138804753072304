import type { ReactNode } from "react";
import styles from "./current-call.module.scss";
import { DraggableArea } from "../../../shared/components/draggable-area";

interface CurrentCallProps {
	topSlot?: ReactNode;
	bottomLeftSlot?: ReactNode | null;
	bottomMiddleSlot?: ReactNode;
	bottomRightSlot?: ReactNode;
	bottomSlot?: ReactNode;
	wrapTopSlot?: boolean;
	wrapBottomSlot?: boolean;
}

export const CurrentCall = ({
	topSlot = <></>,
	bottomLeftSlot = null,
	bottomMiddleSlot = <></>,
	bottomRightSlot = <></>,
	bottomSlot = <></>,
	wrapTopSlot = false,
	wrapBottomSlot = false,
}: CurrentCallProps) => {
	return (
		<div className={styles.container}>
			{!wrapTopSlot ? topSlot : <></>}
			<DraggableArea>
				{wrapTopSlot ? topSlot : <></>}
				<div className={styles["bottom-slot"]}>
					{bottomLeftSlot}
					{bottomMiddleSlot}
					{bottomRightSlot}
				</div>
				{wrapBottomSlot ? bottomSlot : <></>}
			</DraggableArea>
			{!wrapBottomSlot ? bottomSlot : <></>}
		</div>
	);
};
