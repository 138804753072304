import { CallIconButton } from "../../../shared/components";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { nanoid } from "nanoid";
import { useFormMachine } from "../../../app/machines/form-factory-machine/useFormMachine";
import { usePhoner } from "../../../shared/contexts/phoner/phoner";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { useSharedWorker } from "../../../shared/contexts/shared-worker";
import { SET_OUTGOING_CALL_INFO } from "../../../shared/event-bus/types/event-action.types";
import { useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";

export function formatPhoneNumber(str: string) {
	if (!str || str.length <= 4) return str;

	let newStr = str.replace(/[\s\(\)\-\+]/g, "");

	if (newStr.startsWith("8")) {
		newStr = `7${newStr.slice(1)}`;
	}

	return newStr;
}

export const FastSearchCallButton = () => {
	const { fastSearchService, phonerIncomingCallService, phonerTreyService, phonerConnectionService } = useGlobalPhonerState();
	const { makeCall, userInternalNumber } = usePhoner();
	const { sessionsDTO } = useSessionContext();
	const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);

	const [fastSearchState, fastSearchSendEvents] = useActor(fastSearchService);
	const [_, fastTreyServiceEvents] = useActor(phonerTreyService);
	const [, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);
	const { isCurrentTabMasterTab } = useMasterTab();
	const { sendMessage } = useSharedWorker();

	const { resetField } = useFormMachine({
		state: fastSearchState,
		send: fastSearchSendEvents,
	});

	const [phonerConnectionState] = useActor(phonerConnectionService);
	const isConnectionEstablished = phonerConnectionState.context.isConnectionEstablished;

	const handleCallToPhone = () => {
		if (!isConnectionEstablished) return;

		if (!isCurrentTabMasterTab) {
			sendMessage({
				eventName: SET_OUTGOING_CALL_INFO,
				eventPayload: {
					phoneNumber: fastSearchState.context.data["fast-search"],
				},
			});
		}

		sendPhonerIncomingCallEvents({
			type: "outcoming call",
			callInfo: {
				id: nanoid(),
				phoneNumber: fastSearchState.context.data["fast-search"],
			},
			makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
		});

		resetField("", "fast-search");
		fastTreyServiceEvents({ type: "active input", isActiveInput: false });
	};

	const isCallButtonDisabled = !fastSearchState.context.canSubmit;

	return (
		<CallIconButton
			handleCallToPhone={handleCallToPhone}
			isCallButtonDisabled={
				!isReadyToSecondCall ||
				isCallButtonDisabled ||
				formatPhoneNumber(userInternalNumber) === formatPhoneNumber(fastSearchState.context.data["fast-search"])
			}
		/>
	);
};
