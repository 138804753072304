import type { EventMap, EventBus, EventBusConfig, Bus } from './eventbus.types'

export const eventbus = <T extends EventMap>(config?: EventBusConfig) => {
  const bus: Partial<Bus<T>> = {}

  const off: EventBus<T>['off'] = (key, handler) => {
    const index = bus[key]?.indexOf(handler) ?? -1

    if (index > -1) {
      bus[key]?.splice(index, 1)
    }
  }

  const offAll: EventBus<T>['offAll'] = (key) => {
    if (bus[key]) bus[key] = []
  }

  const on: EventBus<T>['on'] = (key, handler) => {
    if (!bus[key]) bus[key] = []

    bus[key]?.push(handler)

    return {
      off() {
        off(key, handler)
      },
    }
  }

  const once: EventBus<T>['once'] = (key, handler) => {
    const handleOnce = (payload: Parameters<typeof handler>) => {
      handler(payload)
      off(key, handleOnce as typeof handler)
    }

    on(key, handleOnce as typeof handler)
  }

  const emit: EventBus<T>['emit'] = (key, payload) => {
    bus[key]?.forEach((fn) => {
      try {
        fn(payload)
      } catch (e) {
        config?.onError(e)
      }
    })
  }

  return { on, off, once, emit, offAll }
}
