import { RequestsTable } from './requests-table'
import { ContentDivider, Title, Typography } from '@components/common'

export const RequestsStatistic = ({ statistic, title }: any) => {
  return (
    <>
      <Title as={'h5'}>{title}</Title>
      <RequestsTable statistic={statistic} />
    </>
  )
}
