import type { RequestModalTextInputProps } from "@components/modals/common-forms/request-modal-input/request-modal-text-input.types";
import type React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { InputDefault } from "@common/multi-select";
import { type FieldRenderProps, useField } from "react-final-form";
import { InputErrorContainer } from "@common/input-error-container";
import { getInputState } from "@components/form/utils/getFieldState";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { UISizes } from "@global-types/mods.types";
import { Calendar } from "@/components";
import styles from "./request-modal-finance-account-payment-from.module.scss";
import { format, isValid, parse } from "date-fns";
import { containsNumber } from "@/components/headers/requests-header/request-header-filters/filter-inputs/filter-period/filter-period";
import type { InputDefaultProps } from "@/components/common/multi-select/input-default/input-default.types";
import { MONTH_MAX_INDEX } from "@/constants/date";

type CustomInputProps<T extends React.ElementType, P> = InputDefaultProps<T> &
	FieldRenderProps<P> & {
		hoveredTooltip: string;
	};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps<any, HTMLInputElement>>((prop, ref) => {
	return (
		<InputErrorContainer anchorId={prop.hoveredTooltip} errorMessage={prop.meta.error}>
			<InputDefault inputProps={{ ref }} {...prop} />
		</InputErrorContainer>
	);
});

export const RequestModalFinanceAccountPaymentFrom = <T,>({
	input,
	meta,
	label,
	labelSide = "left",
	inputChildren,
	disabled,
	...props
}: RequestModalTextInputProps<T>) => {
  const field = useField(input?.name)?.input?.value
  const [startDate, setStartDate] = useState<Date | undefined | null>(null)
  const [hoveredTooltip, setHoveredTooltip] = useState<string>('')

  const handleSetHoveredTooltip = (val: string) => {
    setHoveredTooltip(val)
  }
  const calendarRef = useRef<any>(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    const parsedDate = parse(field, "dd.MM.yyyy", new Date());

		if (!isValid(parsedDate)) return;

		setStartDate(parsedDate);
		input.onChange(field);
	}, [field]);

	return (
		<Calendar
			ref={calendarRef}
			className={styles["date-picker-full-w"]}
			popperModifiers={[
				{
					name: "offset",
					options: {
						offset: [-50, 20],
					},
				},
			]}
			selected={startDate}
			showTimeSelect={false}
			customInput={
				<CustomInput
					inputProps={{
						value: field,
					}}
					width={"full-w"}
					meta={meta}
					input={input}
					id={input.name}
					type={"text"}
					withRightButton={true}
					labelText={`${label}: `}
					state={getInputState(meta, input)}
					handleShowError={handleSetHoveredTooltip}
					hoveredTooltip={hoveredTooltip}
					{...props}
					renderRightButton={() =>
						startDate ? (
							<Button
								btnType={"button"}
								disabled={disabled}
								type={ButtonTypes.secondaryUncolored}
								size={UISizes.medium}
								iconLeft={<i className={"icon icon-close"} />}
								onClick={() => {
									setStartDate(null);
									input.onChange(undefined);
								}}
							/>
						) : (
							<Button
								btnType={"button"}
								disabled={disabled}
								type={ButtonTypes.secondaryUncolored}
								size={UISizes.medium}
								iconLeft={<i className={"icon icon-calendar"} />}
								onClick={() => {
                  setIsCalendarOpen(prev => !prev)
                  isCalendarOpen ?  calendarRef?.current?.setOpen(false) : calendarRef?.current?.setOpen(true)
                }}
							/>
						)
					}
				/>
			}
			onChange={(date) => {
				input.onChange(date && format(date, "dd.MM.yyyy"));
				setStartDate(date);
			}}
			onChangeRaw={(e) => {
				const inputValue = e.target.value;

				if (!containsNumber(inputValue)) return;

				const parsedDateParts = inputValue.split(".");
				const [day, month, year] = parsedDateParts;
				const daysInSelectedMonth = new Date(Number(year), Number(month), 0).getDate();
				if (Number(day) > daysInSelectedMonth || Number(month) > MONTH_MAX_INDEX) {
					const validateMonth = Number(month) > MONTH_MAX_INDEX ? MONTH_MAX_INDEX : month;
					const validateDay =
						Number(month) > MONTH_MAX_INDEX
							? new Date(Number(year), Number(MONTH_MAX_INDEX), 0).getDate()
							: daysInSelectedMonth;
					const ISOFormattedDateString = `${year}-${validateMonth}-${validateDay}T00:00:00`;
					const isoFormattedDate = new Date(ISOFormattedDateString);
					input.onChange(isoFormattedDate && format(isoFormattedDate, "dd.MM.yyyy"));
					setStartDate(isoFormattedDate);
				}
			}}
		/>
	);
};
