export const AddCallLabels = {
  typeOfWork: 'Вид работ',
  denyReasons: 'Тип обращения',
  comment: 'Комментарии',
} as const

export enum AddCallNames {
  denyReasons = 'type_id',
  comment = 'comment',
}
