import styles from '@common/table-container/table.module.scss'
import { TableBodyProps } from '@common/table-container/table-body/table-body.types'

export const TableBody = ({ children, style }: TableBodyProps) => {
  return (
    <div style={style} className={styles['table-body']}>
      {children}
    </div>
  )
}
