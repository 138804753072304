import cn from "clsx";
import { useState } from "react";
import { Button, Icon, Typography } from "../../index";
import { InputContainer, Input as InputElement } from "./common";
import { Label } from "./common";
import { InputErrorContainer } from "./common/error-container";
import styles from "./input.module.scss";
import type { InputProps } from "./input.types";

export const Input = ({
	readonly,
	inputContainerClasses,
	inputElementClasses,
	withRightButton = false,
	type = "text",
	labelText = "",
	renderRightButton,
	renderDropdownMenu,
	renderSelectedItems,
	inputContainerOrientation = "row",
	id,
	width = "content",
	placeholder = "",
	labelProps,
	state = "default",
	value,
	disabled,
	maxLength,
	withResetButton,
	onReset,
	errorMessage,
	inputRef,
	errorMessageTooltipOffset,
	withMaxLenghLabel,
	...props
}: InputProps) => {
	const withRightBtnContainerClasses = cn(styles["with-right-btn-container"], styles[`width-${width}`], {
		[styles["input-default"] as string]: state === "default",
		[styles["input-active"] as string]: state === "active",
		[styles["input-error"] as string]: state === "error" || errorMessage,
		[styles["input-disabled"] as string]: disabled,
		[styles["input-filled"] as string]: state === "filled",
		[styles["input-readonly"] as string]: readonly,
	});
	const [hoveredTooltip, setHoveredTooltip] = useState<string>("");
	const handleSetHoveredTooltip = (val: string) => {
		setHoveredTooltip(val);
	};

	return (
		<InputContainer width={width} orientation={inputContainerOrientation} className={inputContainerClasses}>
			{labelText && <Label disabled={disabled} {...labelProps} htmlFor={id} labelText={labelText} />}
			<InputErrorContainer
				tooltipOffset={errorMessageTooltipOffset}
				placement={"left"}
				anchorId={hoveredTooltip}
				errorMessage={errorMessage}
			>
				<div
					onMouseLeave={() => handleSetHoveredTooltip("")}
					onMouseEnter={() => {
						handleSetHoveredTooltip(String(id));
					}}
					className={withRightBtnContainerClasses}
				>
					{renderSelectedItems && <div className={styles["selected-items__container"]}>{renderSelectedItems()}</div>}
					<InputElement
						ref={inputRef}
						value={value}
						readOnly={readonly}
						id={id}
						name={id}
						withRightButton={withRightButton}
						type={type}
						placeholder={placeholder}
						className={inputElementClasses}
						disabled={disabled}
						maxLength={maxLength}
						{...props}
					/>
					{!!maxLength && withMaxLenghLabel && (
						<div className={styles["max-length-container"]}>
							<Typography as={"caption"} decoration={"none"} tag={6}>
								{value?.length}
							</Typography>
							/
							<Typography as={"caption"} decoration={"none"} tag={6}>
								{maxLength}
							</Typography>
						</div>
					)}
					{withResetButton && value && (
						<Button
							disabled={state === "disabled" || disabled}
							variant={"secondary-uncolored"}
							width={"icon"}
							renderIcon={<Icon icon={"icon-close"} />}
							onClick={() => onReset?.()}
						/>
					)}
					{withRightButton && renderRightButton && renderRightButton({ inputState: disabled ? "disabled" : state })}
					{renderDropdownMenu?.()}
				</div>
			</InputErrorContainer>
		</InputContainer>
	);
};
