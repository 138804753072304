import styles from './content-container.module.scss'
import { ContentContainerProps } from './content-container.types'
import cn from 'classnames'

export const ContentContainer = ({
  children,
  className,
  ...props
}: ContentContainerProps) => {
  const contentContainerClasses = cn(styles.content_wrapper, className)

  return (
    <div className={contentContainerClasses} {...props}>
      {children}
    </div>
  )
}
