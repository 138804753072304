import { InputProps } from './input.types'
import styles from './input.module.scss'
import cn from 'classnames'
import { useId, forwardRef } from 'react'
import InputMask from 'react-input-mask'
// import { IMaskInput } from 'react-imask'

export const InputContainer = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      state = 'default',
      value,
      showChildren = false,
      onChange,
      onFocus,
      onBlur,
      placeholder,
      className = '',
      id,
      disabled = false,
      children,
      mask,
      onReset,
      inputWrapperClassName = '',
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    const reactId = useId()
    const localId = id && id !== '' ? id : reactId

    const inputClasses = cn(styles['input-container'], {
      [styles['input-default']]: state === 'default',
      [styles['input-active']]: state === 'active',
      [styles['input-error']]: state === 'error',
      [styles['input-disabled']]: disabled,
      [styles['input-filled']]: state === 'filled',
    })

    return (
      <div className={`${inputClasses} ${className} ${inputWrapperClassName}`}>
        <div className={`${styles["input-wrapper"]}`}>
          {mask ? (
            <InputMask
              mask={mask}
              value={value !== null && value !== undefined ? value : ""}
              onChange={onChange}
              onFocus={(e) => onFocus && onFocus(e)}
              onBlur={(e) => onBlur && onBlur(e)}
              disabled={disabled}
              id={localId}
              placeholder={placeholder}
              className={`${styles.input}`}
            >
              <input ref={ref} {...props} />
            </InputMask>
          ) : (
            <input
              onKeyDown={onKeyDown}
              ref={ref}
              id={localId}
              placeholder={placeholder}
              disabled={disabled}
              className={`${styles.input}`}
              value={value ? value : ""}
              onFocus={(e) => onFocus && onFocus(e)}
              onBlur={(e) => onBlur && onBlur(e)}
              onChange={(e) => {
                onChange(e);
              }}
              {...props}
            />
          )}
          {(showChildren || value !== "") && children}
        </div>
      </div>
    );
  },
)
