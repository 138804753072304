type FlattenRequestSelectionData = {
  arr: any[]
  depth?: number
  enableFilterWithoutParentId?: boolean
}
export const flattenData = ({
  arr,
  depth = 2,
  enableFilterWithoutParentId = false,
}: FlattenRequestSelectionData) => {
  const flatMap = (arr: any[]) =>
    arr.reduce((prevValue, currValue) => {
      if ('items' in currValue && currValue?.items.length > 0) {
        const intermediate = currValue.items.map((item: any) => ({
          ...item,
          parentId: currValue.id,
          title: `${item.title} - ${currValue.title}`,
          itemTitle: item.title,
        }))

        return prevValue.concat([
          {
            id: currValue.id,
            title: currValue.title,
            items: [],
          },
          ...intermediate,
        ])
      }
      return prevValue.concat({
        ...currValue,
        id: currValue.id,
        title: currValue.title,
        items: [],
      })
    }, [])

  let intermediateResult = flatMap(arr)
  for (let i = 0; i <= depth; i += 1) {
    intermediateResult = flatMap(intermediateResult)
  }
  return enableFilterWithoutParentId
    ? intermediateResult.filter((item: any) => 'parentId' in item)
    : intermediateResult
}
