import { Tooltip } from "@components/common";
import { nanoid } from "@reduxjs/toolkit";
import cn from "classnames";
import styles from "./input-error-container.module.scss";
import type { InputErrorContainerProps } from "./input-error-container.types";

export const InputErrorContainer = ({ children, errorMessage = "", anchorId = "", className }: Partial<InputErrorContainerProps>) => {
	const inputErrorContainer = cn(styles["input-error-container"], className);

	return (
		<div className={inputErrorContainer}>
			{children}
			{anchorId !== "" && !!errorMessage && (
				<div style={{ position: "absolute", right: "50%", bottom: 0, zIndex: 5 }}>
					<Tooltip
						externalHoverId={anchorId}
						show
						anchorId={anchorId}
						key={nanoid()}
						text={errorMessage}
						tooltipOffset={[0, 15]}
						placement={"bottom"}
					/>
				</div>
			)}
		</div>
	);
};
