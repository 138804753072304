import { useLazyReklamasFilterByNameQuery } from "@/state/api";
import { InputDefault } from "@common/multi-select/input-default";
import type { InputStateType } from "@common/multi-select/input-default/input-default.types";
import { MultiSelectDropdownMenu } from "@common/multi-select/multi-select/dropdown-menu";
import { MultiSelectDropdownMenuVirtualized } from "@common/multi-select/multi-select/dropdown-menu-virtualized";
import type { MultiSelectProps } from "@common/multi-select/multi-select/multi-select.types";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { getInputState } from "@components/form/utils/getFieldState";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import { UISizes } from "@global-types/mods.types";
import { clearReklamaAndObjectText } from "@utils/clear-text";
import { deleteObjectIfInMap, getFlatArray, isObjectInMap } from "@utils/find-item";
import { useCombobox, useMultipleSelection } from "downshift";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import styles from "./multi-select.module.scss";

export const MultiSelect = <Item extends { [key: string]: any }>({
	acceptPathAsTitle = false,
	enableSearch = true,
	isDataLoading,
	isDataNested,
	dropdownMenuSelectionVariant,
	data,
	inputId,
	width = "content",
	inputLabelText,
	filterByQuery,
	inputContainerOrientation,
	dropdownMenuItemWithCheckbox = false,
	initialSelectedItems,
	externalInputProps,
	externalInputMetaProps,
	disabled,
	dropdownMenuContainerWidth,
	maxLenSelectedItemTitle,
	onChange,
	withTooltip,
	isGroupSelectable = true,
	enableVirtualization = false,
	debounceSearchTime = 50,
	typeField,
	allowOnlyChildSelection = false,
}: MultiSelectProps<Item>) => {
	const isSingleSelection = dropdownMenuSelectionVariant === "single-select";

	const newItems = useMemo(() => new Map(), [data]);
	const newItemsById = useMemo(() => new Map(), [data]);

	const [newSelectedItems, setNewSelectedItems] = useState<Map<Item, Item>>(new Map());

	const [newOpenedItems, setNewOpenedItems] = useState<Map<Item, Item>>(new Map());

	const [debouncedInputValue, setDebouncedInputValue] = useState<string>("");

	const [withResetButtonState, setWithResetButtonState] = useState(false);

	const [filterReklamaByQuery, { isLoading: isFilterReklamaByQueryLoading, isFetching: isFilterReklamaByQueryFetching }] =
		useLazyReklamasFilterByNameQuery();

	useEffect(() => {
		const flattenData = data && getFlatArray<Item>(data);
		if (flattenData) {
			flattenData?.map((item) => {
				newItems.set(item, item);
				newItemsById.set(item?.id, item);
			});
		}
	}, [data]);

	const debouncedFetch = useMemo(
		() =>
			debounce(
				(query: string) =>
					filterReklamaByQuery({
						name: query,
					})
						.unwrap()
						.then((response) => {
							const newOpenedItemsMap = new Map(newOpenedItems);
							setItems(response.data as Item[]);

							const allItemsToOpen = getFlatArray<Item>(response.data as Item[]);

							Array.isArray(allItemsToOpen) &&
								allItemsToOpen?.map((item: Item) => {
									newOpenedItemsMap.set(item, item);
								});

							setNewOpenedItems(newOpenedItemsMap);
						})
						.catch(() => {
							data && setItems(data);
						}),
				debounceSearchTime,
			),
		[filterReklamaByQuery],
	);

	const [inputState, setInputState] = useState<InputStateType>("default");

	const [items, setItems] = useState<Item[]>(data ?? []);

	useEffect(() => {
		data && setItems(data);
	}, [data]);

	const handleCollapseNestedItems = (item: Item) => {
		const newOpenedItemsMap = new Map(newOpenedItems);

		if (isObjectInMap(newOpenedItemsMap, item?.id, item?.title)) {
			deleteObjectIfInMap(newOpenedItemsMap, item?.id, item?.title);
			setNewOpenedItems(newOpenedItemsMap);
		} else {
			newOpenedItemsMap.set(item, item);
			setNewOpenedItems(newOpenedItemsMap);
		}
	};

	const handleSelectedItemTitle = (title: string, maxLen: number) => {
		const words = title?.split(" ");

		return words?.length > 1
			? `${words[0] && words[0]?.length > 5 ? `${words[0]?.substring(0, 5)}.` : words[0]} ${
					words?.slice(1)?.join("").length >= maxLen
						? `${words.slice(1)?.join(" ").substring(0, maxLen)}...`
						: words?.slice(1)?.join(" ")
				}`
			: title?.length >= maxLen
				? `${title?.substring(0, maxLen)}...`
				: title;
	};

	const handleSelectedItemsTitleInLine = (selectedItems: Map<Item, Item>, maxLen: number): string => {
		const titles = Array.from(selectedItems.values()).map((item) => item.title);

		if (titles.length === 1) {
			return handleSelectedItemTitle(titles[0], maxLen);
		}

		const truncatedTitles = titles.map((title) => {
			return handleSelectedItemTitle(title, maxLen);
		});

		return truncatedTitles.join(", ");
	};

	const getRecursiveItems = (item: Item, arr: Item[]) => {
		const hasItems = item && Object.hasOwn(item, "items");
		if (hasItems) {
			arr.push(item.items);
			return item.items?.map((item: Item) => getRecursiveItems(item, arr));
		}
	};

	const handleSelectedItem = (item: Item) => {
		const newSelectedItemsMap = new Map(newSelectedItems);
		if (isObjectInMap(newSelectedItemsMap, item?.id, item?.title)) {
			deleteObjectIfInMap(newSelectedItemsMap, item?.id, item?.title);
			setNewSelectedItems(newSelectedItemsMap);
			setInputState("default");
		} else {
			dropdownMenuSelectionVariant === "single-select" && newSelectedItemsMap.clear();
			newSelectedItemsMap.set(item, item);
			setNewSelectedItems(newSelectedItemsMap);
		}

		onChange?.(Array.from(newSelectedItemsMap.values()));
	};

	const handleSelectedItemGroupNotSelectable = (item: Item) => {
		const newSelectedItemsMap = new Map(newSelectedItems);
		const hasItems = Object.hasOwn(item, "items");
		const recursiveItems: Item[] = [];

		recursiveItems.push(item);
		if (hasItems && item.items.length > 0) {
			const { items } = item;
			getRecursiveItems(item, recursiveItems);
			const isIncludes = !!Array.from(newSelectedItemsMap.values()).filter((sItem) => items.includes(sItem)).length;
			const allChilds = recursiveItems.flat() as Item[];

			if (isIncludes) {
				allChilds?.map((child) => {
					newSelectedItemsMap.delete(child);
				});
				setNewSelectedItems(newSelectedItemsMap);
				onChange?.(Array.from(newSelectedItemsMap.values()));
				return;
			}

			if (!isIncludes) {
				allChilds?.map((child) => {
					newItems.get(child) ? newSelectedItemsMap.set(child, newItems.get(child)) : newSelectedItemsMap.set(child, child);
				});
				setNewSelectedItems(newSelectedItemsMap);
				onChange?.(Array.from(newSelectedItemsMap.values()));
				return;
			}
		}

		if (!hasItems || item.items.length < 1) {
			handleSelectedItem(item);
		}
	};

	const filterData = (data: Item[], inputValue: string) => {
		if (!enableSearch) {
			return data;
		}
		return data.reduce((a: any, b: any) => {
			if (b?.title?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase())) {
				return [b].concat(a);
			}

			if (b && Object.hasOwn(b, "items")) {
				const filtered = filterData(b.items, inputValue);

				if (filtered?.length) {
					const newArr: Item = { ...b, items: filtered };
					const temp = [newArr].concat(a);

					return temp;
				}
				return a;
			}
			return b?.title?.toLowerCase()?.includes(inputValue?.toLocaleLowerCase()) ? [b].concat(a) : a;
		}, []);
	};

	const { getDropdownProps } = useMultipleSelection({
		selectedItems: Array.from(newSelectedItems.values()),
		onStateChange({ type }) {
			switch (type) {
				case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
				case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
				case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
				case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
					break;
				default:
					break;
			}
		},
	});

	const {
		isOpen,
		getToggleButtonProps,
		getLabelProps,
		getMenuProps,
		getInputProps,
		highlightedIndex,
		inputValue: currInputValue,
		getItemProps,
		setInputValue,
		selectItem,
	} = useCombobox({
		//фильтрация по вводу
		items,
		itemToString(item) {
			const shortenedTitle = acceptPathAsTitle
				? String(clearReklamaAndObjectText(item?.path?.split("-").join("->")))
				: handleSelectedItemTitle(item?.title, 40);

			return shortenedTitle;
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (!selectedItem) {
				return;
			}

			if (isGroupSelectable) {
				handleSelectedItem(selectedItem);
			}

			if (!isGroupSelectable) {
				handleSelectedItemGroupNotSelectable(selectedItem);
			}
		},
		selectedItem: acceptPathAsTitle ? undefined : null,

		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;
			switch (type) {
				case useCombobox.stateChangeTypes.InputKeyDownEnter:
					return {
						...changes,
						inputValue: state.inputValue,
						isOpen: !isSingleSelection,
					};
				case useCombobox.stateChangeTypes.FunctionSelectItem:
					setInputState("filled");
					return {
						...changes,
						inputValue: isSingleSelection ? state.inputValue : "",
						isOpen: !isSingleSelection,
						// меню открыто если выбираем несколько элементов
					};
				case useCombobox.stateChangeTypes.ItemClick:
					setInputState("filled");
					return {
						...changes,
						isOpen: !isSingleSelection,
						// меню открыто если выбираем несколько элементов
						highlightedIndex: state.highlightedIndex,
						inputValue: isSingleSelection ? state.inputValue : "",
					};
				case useCombobox.stateChangeTypes.InputChange:
					if (isSingleSelection && state.inputValue) setWithResetButtonState(true);
					return {
						...changes,
					};
				case useCombobox.stateChangeTypes.InputFocus:
					setInputState("active");
					return {
						...changes,
					};

				case useCombobox.stateChangeTypes.InputBlur:
					newSelectedItems.size > 0 ? setInputState("filled") : setInputState("default");
					newOpenedItems.clear();

					if (isSingleSelection && !acceptPathAsTitle && state.inputValue) {
						setWithResetButtonState(false);
						return {
							...changes,
							isOpen: false,
							inputValue: Array.from(newSelectedItems.values())[0]?.title || "",
						};
					}

					return {
						...changes,
						isOpen: false,
						inputValue:
							isSingleSelection &&
							acceptPathAsTitle &&
							clearReklamaAndObjectText(Array.from(newSelectedItems.values())[0]?.path)
								? String(clearReklamaAndObjectText(Array.from(newSelectedItems.values())[0]?.path))
								: isSingleSelection && !acceptPathAsTitle
									? Array.from(newSelectedItems.values())[0]?.title || state.inputValue
									: "",
					};
				default:
					return changes;
			}
		},
	});

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedInputValue(currInputValue);
		}, debounceSearchTime);

		handler();

		return () => handler.cancel();
	}, [currInputValue, debounceSearchTime]);

	useEffect(() => {
		if (filterByQuery) {
			if (inputId === FilterNames.ads && debouncedInputValue?.length) {
				debouncedFetch(debouncedInputValue);
			} else {
				data && setItems(data);
			}
		} else {
			if (!debouncedInputValue) {
				data && setItems(data);
			}

			if (debouncedInputValue) {
				const searchResult: Item[] =
					data &&
					filterData(data, debouncedInputValue)
						.slice(0)
						.sort((a: Item) => (a.title?.toLowerCase().startsWith(debouncedInputValue?.toLowerCase()) ? -1 : 1));

				if (data && isDataNested) {
					const newOpenedItemsMap = new Map(newOpenedItems);
					const newSelectedItemsMap = new Map(newSelectedItems);
					const parentItem = newSelectedItemsMap.size > 0 && [
						newItemsById.get(Array.from(newSelectedItemsMap.values())[0]?.parentId),
					];

					const filteredParentItemOrSearchResult = parentItem ? filterData(parentItem, debouncedInputValue) : searchResult;

					const getAllItemsToOpen = filteredParentItemOrSearchResult.length
						? getFlatArray<Item>(filteredParentItemOrSearchResult)
						: getFlatArray<Item>(searchResult);

					filteredParentItemOrSearchResult.length ? setItems(filteredParentItemOrSearchResult) : setItems(searchResult);
					getAllItemsToOpen?.map((item: Item) => {
						newOpenedItemsMap.set(item, item);
					});
					setNewOpenedItems(newOpenedItemsMap);
				} else {
					setItems(searchResult);
				}
			}
		}
	}, [debouncedInputValue, filterByQuery]);

	const handleResetInput = () => {
		setInputValue("");
		setNewSelectedItems(new Map());
		onChange?.(undefined);
		setInputState("default");
		setWithResetButtonState(false);
	};

	useEffect(() => {
		const newSelectedItemsMap = new Map(newSelectedItems);

		if (!isSingleSelection && Array.isArray(initialSelectedItems) && initialSelectedItems && initialSelectedItems.length) {
			initialSelectedItems?.map((item) => {
				!isObjectInMap(newSelectedItemsMap, item?.id, item?.title) && newSelectedItemsMap.set(item, item);
			});
			setNewSelectedItems(newSelectedItemsMap);
		}

		if (isSingleSelection && initialSelectedItems && Array.isArray(initialSelectedItems)) {
			newSelectedItemsMap.clear();
			initialSelectedItems?.map((item) => {
				!isObjectInMap(newSelectedItemsMap, item?.id, item?.title) && newSelectedItemsMap.set(item, item);
			});

			setNewSelectedItems(newSelectedItemsMap);
			setInputState("filled");
		}
	}, [initialSelectedItems]);

	const resetSelectedItems = () => {
		newSelectedItems.clear();
	};

	useEffect(() => {
		if (externalInputProps?.value === "" || externalInputProps?.value === null || externalInputProps?.value === undefined) {
			resetSelectedItems();
		}
	}, [externalInputProps?.value]);

	useEffect(() => {
		if (isSingleSelection) {
			newSelectedItems.size && acceptPathAsTitle
				? setInputValue(String(clearReklamaAndObjectText(Array.from(newSelectedItems.values())[0]?.path)))
				: setInputValue(Array.from(newSelectedItems.values())[0]?.title);
		}

		if (isSingleSelection && !newSelectedItems.size) {
			setInputValue("");
		}
	}, [newSelectedItems.size, Array.from(newSelectedItems.values())[0]?.id]);

	const inputDownShiftProps = {
		...getInputProps(getDropdownProps({ preventKeyAction: isOpen })),
	};
	const labelProps = { ...getLabelProps() };
	const menuProps = { ...getMenuProps() };

	const mergedInputProps = externalInputProps ? inputDownShiftProps : { ...inputDownShiftProps, ...externalInputProps };

	return (
		<InputDefault
			inputElementClasses={styles["selected-item-title"]}
			readonly={!enableSearch}
			disabled={disabled}
			state={
				inputState === "active" || isOpen
					? "active"
					: externalInputMetaProps
						? getInputState(externalInputMetaProps, externalInputProps)
						: inputState
			}
			width={width}
			labelProps={labelProps}
			inputProps={mergedInputProps}
			id={inputId}
			type={"text"}
			labelText={inputLabelText}
			inputContainerOrientation={inputContainerOrientation}
			withRightButton={true}
			renderSelectedItems={
				newSelectedItems.size > 0 && !isSingleSelection && inputState !== "active" && typeField === "violation-point"
					? () => (
							<>
								<span id={"selected-item-title"} className={styles["selected-item-title"]}>
									{handleSelectedItemsTitleInLine(newSelectedItems, maxLenSelectedItemTitle || 16)}
								</span>
							</>
						)
					: newSelectedItems.size > 0 &&
							!isSingleSelection &&
							inputState !== "active" &&
							typeField === undefined &&
							!debouncedInputValue
						? () => (
								<>
									<span id={"selected-item-title"} className={styles["selected-item-title"]}>
										{handleSelectedItemTitle(
											Array.from(newSelectedItems.values())[0]?.title,
											maxLenSelectedItemTitle || 16,
										)}
									</span>
									{newSelectedItems.size > 1 && (
										<span id={"selected-items-count"} className={styles["selected-item-title"]}>
											и еще {newSelectedItems.size - 1}
										</span>
									)}
								</>
							)
						: null
			}
			renderDropdownMenu={() =>
				enableVirtualization ? (
					<MultiSelectDropdownMenuVirtualized<Item>
						// disableGroupsWithoutChilds={disableGroupsWithoutChilds}
						isGroupSelectable={isGroupSelectable}
						selectedItems={newSelectedItems}
						withTooltip={withTooltip}
						dropdownMenuItemVariant={dropdownMenuSelectionVariant}
						dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
						menuProps={menuProps}
						getItemProps={getItemProps}
						items={items || []}
						highlightedIndex={highlightedIndex}
						isOpen={isOpen}
						isDataNested={isDataNested}
						selectItem={selectItem}
						handleCollapseNestedItems={handleCollapseNestedItems}
						openedNestedItems={newOpenedItems}
						dropdownMenuContainerWidth={dropdownMenuContainerWidth}
					/>
				) : (
					<MultiSelectDropdownMenu
						// disableGroupsWithoutChilds={disableGroupsWithoutChilds}
						isGroupSelectable={isGroupSelectable}
						selectedItems={newSelectedItems}
						withTooltip={withTooltip}
						dropdownMenuItemVariant={dropdownMenuSelectionVariant}
						dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
						menuProps={menuProps}
						getItemProps={getItemProps}
						items={items || []}
						isLoading={isFilterReklamaByQueryLoading || isFilterReklamaByQueryFetching}
						highlightedIndex={highlightedIndex}
						isOpen={isOpen}
						isDataNested={isDataNested}
						selectItem={selectItem}
						handleCollapseNestedItems={handleCollapseNestedItems}
						openedNestedItems={newOpenedItems}
						dropdownMenuContainerWidth={dropdownMenuContainerWidth}
						allowOnlyChildSelection={allowOnlyChildSelection}
					/>
				)
			}
			renderRightButton={() => {
				return newSelectedItems.size === 0 && !withResetButtonState ? (
					<Button
						btnType={"button"}
						disabled={disabled}
						isLoading={isDataLoading}
						type={ButtonTypes.secondaryUncolored}
						size={UISizes.medium}
						iconLeft={<i className={`icon icon-cheveron-${isOpen ? "up" : "down"}`} />}
						{...getToggleButtonProps()}
					/>
				) : (newSelectedItems.size > 0 && !!Array.from(newSelectedItems.values())[0]) || withResetButtonState ? (
					<Button
						className={styles["right-btn"]}
						btnType={"button"}
						disabled={disabled}
						isLoading={isDataLoading}
						type={ButtonTypes.secondaryUncolored}
						size={UISizes.medium}
						iconLeft={<i className={"icon icon-close"} />}
						onClick={handleResetInput}
					/>
				) : null;
			}}
			{...externalInputMetaProps}
		/>
	);
};
