import { InstallmentScheduleServerData } from '@/constants/installment'

export const getColorByPaymentStatus = (row: any) => {
  if (row) {
    if (row?.[InstallmentScheduleServerData?.is_passed]) return '#0D6B23' // --validation-success-content
    if (row?.[InstallmentScheduleServerData?.period_status] === 'past' && !row?.[InstallmentScheduleServerData?.is_passed]) return '#951A20'
    if (row?.[InstallmentScheduleServerData?.period_status] === 'current') return '#0F0F0F'
    return '#EBEBEB'
  }
}
