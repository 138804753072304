import { type ReactNode, type Dispatch, type SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useMasterTab } from "../master-tab/master-tab";

export enum SessionStatus {
	RINGING = "RINGING",
	HOLD = "HOLD",
	CONNECTING = "CONNECTING",
	STARTED_CONVERSATION = "STARTED_CONVERSATION",
	ACCEPTED = "ACCEPTED",
}

export enum CallDirection {
	INCOMING = "INCOMING",
	OUTCOMING = "OUTCOMING",
}

export interface SessionDTO {
	sessionId?: string;
	direction?: CallDirection;
	phoneNumber?: string;
	status?: SessionStatus;
	isMuted?: boolean;
	startedAt?: number;
	isConnected?: boolean;
}
export type SessionContext = SessionDTO[];

export interface SessionStateContext {
	sessionsDTO: SessionContext;
	setSessionsDTO: Dispatch<SetStateAction<SessionContext>>;
}

export const SessionStateContext = createContext<SessionStateContext>({
	sessionsDTO: [],
	setSessionsDTO: () => null,
});

export const useSessionContext = () => useContext(SessionStateContext);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
	const [sessionsDTO, setSessionsDTO] = useState<SessionContext>(JSON.parse(localStorage.getItem("sessionsState") || "[]"));
	const { isCurrentTabMasterTab } = useMasterTab();

	useEffect(() => {
		isCurrentTabMasterTab && localStorage.setItem("sessionsState", JSON.stringify(sessionsDTO));

		return () => {
			isCurrentTabMasterTab && localStorage.removeItem("sessionsState");
		};
	}, [sessionsDTO, isCurrentTabMasterTab]);

	return <SessionStateContext.Provider value={{ sessionsDTO, setSessionsDTO }}>{children}</SessionStateContext.Provider>;
};
