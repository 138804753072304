import { CallIconButton } from "../../../shared/components";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { nanoid } from "nanoid";
import { usePhoner } from "../../../shared/contexts/phoner/phoner";
import { formatPhoneNumber } from "../../fast-search/call/call";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { useSharedWorker } from "../../../shared/contexts/shared-worker";
import { START_SECOND_LINE } from "../../../shared/event-bus/types/event-action.types";
import { useFormMachine } from "../../../app/machines/form-factory-machine/useFormMachine";
import { SessionStatus, useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";

export const CurrentCallButton = () => {
	const { fastSearchService, phonerIncomingCallService, phonerSecondLineService, phonerConnectionService } = useGlobalPhonerState();
	const [state, send] = useActor(fastSearchService);
	const { resetField } = useFormMachine({ state, send });
	const [stateFastSearch] = useActor(fastSearchService);
	const [statePhonerIncomingCall, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);
	const [, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);
	const { isCurrentTabMasterTab } = useMasterTab();
	const { makeCall, setSessionHold, userInternalNumber } = usePhoner();
	const { sendMessage } = useSharedWorker();
	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const [fastSearchState] = useActor(fastSearchService);
	const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);
	const [phonerConnectionState] = useActor(phonerConnectionService);
	const isConnectionEstablished = phonerConnectionState.context.isConnectionEstablished;
	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const hasIncomingCallAtCurrent = statePhonerSecondLine.matches("second call received");

	const handleCallToPhone = () => {
		if (!isConnectionEstablished) return;

		if (isCurrentTabMasterTab) {
			setSessionHold();
			setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
			sendMessage({ eventName: START_SECOND_LINE, eventPayload: { phoneNumber: stateFastSearch.context.data["fast-search"] } });
		} else {
			sendMessage({ eventName: START_SECOND_LINE, eventPayload: { phoneNumber: stateFastSearch.context.data["fast-search"] } });
			setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
		}

		sendPhonerSecondLineEvents({
			type: "start second line",
			callInfo: {
				id: nanoid(),
				phoneNumber: stateFastSearch.context.data["fast-search"],
				currentPhone: statePhonerIncomingCall?.context?.currentCall?.phoneNumber,
			},
			makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
		});

		sendPhonerIncomingCallEvents({ type: "back to current" });
		resetField("", "fast-search");
	};

	const isCallButtonDisabled =
		sessionsDTO.some(
			(session) => formatPhoneNumber(session.phoneNumber || "") === formatPhoneNumber(stateFastSearch.context.data["fast-search"]),
		) ||
		stateFastSearch.context.data["fast-search"] === userInternalNumber ||
		!isReadyToSecondCall ||
		!fastSearchState.context.canSubmit ||
		hasIncomingCallAtCurrent;

	return <CallIconButton handleCallToPhone={handleCallToPhone} isCallButtonDisabled={isCallButtonDisabled} />;
};
