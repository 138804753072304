import { useActor } from "@xstate/react";
import cn from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { type DetailedHTMLProps, type HTMLAttributes, type ReactNode, useRef } from "react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { CallLog, FastSearchPanelMenu, PhonerSettings } from "../../../entities";
import { HotKeys } from "../../../entities/hot-keys/ui/hot-keys";
import { Collapse } from "../../../features/fast-search/collapse";
import styles from "../ui/phoner-widget.module.scss";
interface GetTopBottomProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	children?: ReactNode;
	isFastSearchWidget?: boolean;
}

export const GetBottomSlot = ({ isFastSearchWidget }: GetTopBottomProps) => {
	const { phonerMenuService, phonerTreyService, phonerIncomingCallService, phonerDraggingService } = useGlobalPhonerState();
	const [statePhonerDragging] = useActor(phonerDraggingService);
	const [menuState] = useActor(phonerMenuService);
	const [statePhonerTrey] = useActor(phonerTreyService);
	const showPanelMenu = menuState.matches("menu opened.menu opened");
	const showCallLogs = menuState.matches("menu opened.call logs");
	const showHotKeys = menuState.matches("menu opened.hot keys");
	const showSettings = menuState.matches("menu opened.settings");
	const showCollpase = statePhonerTrey.matches("activated") && !showPanelMenu && !showCallLogs && !showHotKeys && !showSettings;
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const showCurrentCall = statePhonerIncomingCall.matches("current call");
	const ref = useRef<HTMLDivElement>(null);
	const { animationDirection, translateDirection } = statePhonerDragging.context.animationTransitionConfig;
	const isDragging = statePhonerDragging.context.isDragging;
	const isLeftShift = translateDirection === "left" && (showHotKeys || showSettings);
	const isRightShift = translateDirection === "right" && (showHotKeys || showSettings);
	const showIncomingCall = statePhonerIncomingCall.matches("call received");

	const bottomSlotTopOffset = cn({
		[styles["bottom-slot-increased-offset"] as string]: showIncomingCall && animationDirection === "bottom",
	});

	const getBottomSlotWrapperSizes = (): { width: string; height: string } => {
		const sizes = { width: "", height: "" };

		if (showCollpase && !showCurrentCall) {
			sizes.width = "48px";
			sizes.height = "0px";
			return sizes;
		}

		if (showSettings || showHotKeys) {
			sizes.width = "530px";
			sizes.height = `${showHotKeys ? 258 : 122}px`;
			return sizes;
		}
		if (showPanelMenu) {
			sizes.width = isFastSearchWidget ? "326px" : "466px";
			sizes.height = "122px";
			return sizes;
		}
		if (showCallLogs) {
			(sizes.width = isFastSearchWidget ? "326px" : "466px"), (sizes.height = "276px");
			return sizes;
		}

		return sizes;
	};

	return (
		<>
			{!isDragging ? (
				<div
					className={`${styles["top-slot-wrapper"]} ${bottomSlotTopOffset}`}
					ref={ref}
					style={{
						width: getBottomSlotWrapperSizes().width,
						height: getBottomSlotWrapperSizes().height,
						right: isRightShift ? "0" : "",
						left: isLeftShift ? "0" : "",
					}}
				>
					<AnimatePresence>
						<motion.div
							className={`${styles["bottom-motion-wrapper"]}`}
							key="topSlotWrapper"
							layout
							initial={{ opacity: "0" }}
							transition={{ layout: { duration: 0.16 } }}
							animate={{ opacity: 1 }}
							style={{ width: getBottomSlotWrapperSizes().width, height: getBottomSlotWrapperSizes().height }}
						>
							{showPanelMenu && (
								<motion.div
									key="menuPanel"
									style={{
										opacity: "0",
										width: isFastSearchWidget ? "326px" : "466px",
										originX: 0,
										originY: 1,
									}}
									initial={{ opacity: 0 }}
									animate={{
										opacity: showPanelMenu ? 1 : 0,
									}}
									transition={{ duration: 0.8 }}
								>
									<FastSearchPanelMenu />
								</motion.div>
							)}

							{showCallLogs && (
								<motion.div
									key="callog"
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
									}}
									animate={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "276px",
									}}
									transition={{ duration: 0.16 }}
									exit={{ height: "122px" }}
								>
									<CallLog />
								</motion.div>
							)}
							{showHotKeys && (
								<motion.div
									key="hotKeys"
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
										overflow: "hidden",
									}}
									animate={{
										width: "530px",
										height: showHotKeys ? "258px" : "122px",
										opacity: 1,
									}}
									transition={{ duration: 0.16 }}
								>
									<HotKeys />
								</motion.div>
							)}
							{showSettings && (
								<motion.div
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
										overflow: "hidden",
									}}
									animate={{ width: isFastSearchWidget ? "530px" : "530px" }}
									transition={{ duration: 0.16 }}
								>
									<PhonerSettings />
								</motion.div>
							)}
						</motion.div>
						{showCollpase && !showCurrentCall && !showIncomingCall && (
							<motion.div
								key="collapse"
								exit={{ transition: { duration: 0.16 } }}
								className={`=${styles["bottom-collapse-wrapper"]}`}
							>
								<Collapse iconType="icon-cheveron-up" />
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
