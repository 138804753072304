import { type DetailedHTMLProps, type HTMLAttributes, type ReactNode, useRef } from "react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { CallLog, FastSearchPanelMenu, PhonerSettings } from "../../../entities";
import { Collapse } from "../../../features/fast-search/collapse";
import { HotKeys } from "../../../entities/hot-keys/ui/hot-keys";
import { motion, AnimatePresence } from "framer-motion";
import styles from "../ui/phoner-widget.module.scss";

interface GetTopWidgetProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	children?: ReactNode;
	isFastSearchWidget?: boolean;
}
const TOP_SLOT_FAST_SEARCH_OFFSET_X = 102.5;
const TOP_SLOT_CURRENT_CALL_OFFSET_X = 32;

export const GetTopSlot = ({ isFastSearchWidget }: GetTopWidgetProps) => {
	const { phonerMenuService, phonerTreyService, phonerIncomingCallService, phonerDraggingService } = useGlobalPhonerState();
	const [statePhonerDragging] = useActor(phonerDraggingService);

	const [menuState] = useActor(phonerMenuService);
	const [statePhonerTrey] = useActor(phonerTreyService);
	const showPanelMenu = menuState.matches("menu opened.menu opened");
	const showCallLogs = menuState.matches("menu opened.call logs");
	const showHotKeys = menuState.matches("menu opened.hot keys");
	const showSettings = menuState.matches("menu opened.settings");
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const showIncomingCall = statePhonerIncomingCall.matches("call received");

	const showCurrentCall = statePhonerIncomingCall.matches("current call");

	const showCollapse =
		statePhonerTrey.matches("activated") &&
		!showPanelMenu &&
		!showCallLogs &&
		!showHotKeys &&
		!showSettings &&
		!showCurrentCall &&
		!showIncomingCall;

	const ref = useRef<HTMLDivElement>(null);
	const { translateDirection } = statePhonerDragging.context.animationTransitionConfig;
	const isDragging = statePhonerDragging.context.isDragging;
	const isLeftShift = translateDirection === "left" && (showHotKeys || showSettings);
	const isRightShift = translateDirection === "right" && (showHotKeys || showSettings);

	return (
		<>
			{!isDragging ? (
				<div
					ref={ref}
					style={{
						left: isLeftShift ? `${showCurrentCall ? TOP_SLOT_CURRENT_CALL_OFFSET_X : TOP_SLOT_FAST_SEARCH_OFFSET_X}px` : "",
						right: isRightShift ? `${showCurrentCall ? TOP_SLOT_CURRENT_CALL_OFFSET_X : TOP_SLOT_FAST_SEARCH_OFFSET_X}px` : "",
						position: "relative",
						top: "-25px",
					}}
				>
					<AnimatePresence>
						<motion.div
							className={`${styles.motionWrapper}`}
							key="topSlotWrapper"
							layout
							initial={{ opacity: "0" }}
							transition={{ layout: { duration: 0.16 } }}
							animate={{ opacity: 1 }}
						>
							{showPanelMenu && (
								<motion.div
									key="menuPanel"
									style={{
										opacity: "0",
										width: isFastSearchWidget ? "326px" : "466px",
									}}
									initial={{ opacity: 0 }}
									animate={{
										opacity: showPanelMenu ? 1 : 0,
									}}
									transition={{ duration: 0.8 }}
								>
									<FastSearchPanelMenu />
								</motion.div>
							)}

							{showCallLogs && (
								<motion.div
									key="callLog"
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
									}}
									animate={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "276px",
									}}
									transition={{ duration: 0.16 }}
									exit={{ height: "122px" }}
								>
									<CallLog />
								</motion.div>
							)}
							{showHotKeys && (
								<motion.div
									key="hotKeys"
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
										overflow: "hidden",
									}}
									animate={{
										width: "530px",
										height: showHotKeys ? "258px" : "122px",
										opacity: 1,
									}}
									transition={{ duration: 0.16 }}
								>
									<HotKeys />
								</motion.div>
							)}
							{showSettings && (
								<motion.div
									initial={{
										width: isFastSearchWidget ? "326px" : "466px",
										height: "122px",
										overflow: "hidden",
									}}
									animate={{ width: isFastSearchWidget ? "530px" : "530px" }}
									transition={{ duration: 0.16 }}
								>
									<PhonerSettings />
								</motion.div>
							)}
						</motion.div>
						{showCollapse && (
							<motion.div
								key="collapse"
								initial={{ left: "calc(50% - 24px)" }}
								exit={{ left: "calc(50% - 24px)", transition: { duration: 0 } }}
								className={` ${styles["collapse-wrapper"]}`}
							>
								<Collapse iconType="icon-cheveron-down" />
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
