import { useFiltersState } from "@/contexts/filters-state";
import classNames from "classnames";
import type React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../button";
import { ButtonTypes } from "../button/button.types";
import styles from "./pagination.module.scss";
import type { IPagination } from "./pagination.types";

const Pagination = ({ total, totalOnPage, current, perPage, setPage, lastPage, setPerPage, isFetching }: IPagination) => {
	const [manualPage, setManualPage] = useState(current ?? 1);
	const [showPerPage, setShowPerPage] = useState(false);
	const ref = useRef<HTMLDivElement | null>(null);
	const { filters } = useFiltersState();

	useEffect(() => {
		if (filters.page === 1) {
			setManualPage(1);
		}
	}, [filters.page]);

	const handleClickOutside = (event: MouseEvent) => {
		event.stopPropagation();

		if (ref.current && !ref.current.contains(event.target as Node)) {
			setShowPerPage(false);
		}
	};

	const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setShowPerPage(!showPerPage);
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, {
			capture: false,
		});

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const goFivePagesFwd = () => {
		if (lastPage - current < 5) {
			setPage(lastPage);
		} else {
			setPage(current + 5);
		}
	};

	const goFivePagesBack = () => {
		if (current <= 5) {
			setPage(1);
		} else {
			setPage(current - 5);
		}
	};

	const buttons = useMemo(() => {
		if (lastPage <= 6) {
			const btns = [];
			for (let i = 1; i <= lastPage; i++) {
				btns.push(i);
			}
			return btns.map((e) => (
				<Button
					type={ButtonTypes.secondaryUncoloredPagination}
					key={e}
					onClick={() => {
						setPage(e);
					}}
					isActive={current === e}
				>
					{e}
				</Button>
			));
		}
		if (lastPage - current >= 3 && current >= 5) {
			const btns = [];
			for (let i = current - 2; i < current + 3; i++) {
				btns.push(i);
			}
			return btns.map((e) => (
				<Button
					type={ButtonTypes.secondaryUncoloredPagination}
					key={e}
					onClick={() => {
						setPage(e);
					}}
					isActive={current === e}
				>
					{e}
				</Button>
			));
		}
		if (lastPage - current < 3 && lastPage !== current) {
			const btns = [];
			for (let i = current - 2; i < lastPage; i++) {
				btns.push(i);
			}
			return btns.map((e) => (
				<Button
					type={ButtonTypes.secondaryUncoloredPagination}
					key={e}
					onClick={() => {
						setPage(e);
					}}
					isActive={current === e}
				>
					{e}
				</Button>
			));
		}
		if (lastPage === current) {
			const btns = [];
			for (let i = lastPage - 4; i < lastPage; i++) {
				btns.push(i);
			}
			return btns.map((e) => (
				<Button
					type={ButtonTypes.secondaryUncoloredPagination}
					key={e}
					onClick={() => {
						setPage(e);
					}}
					isActive={current === e}
				>
					{e}
				</Button>
			));
		}
		const btns = [];
		const limit = current <= 2 ? 5 : current + 3;
		for (let i = 1; i < limit; i++) {
			btns.push(i);
		}
		return btns.map((e) => (
			<Button
				type={ButtonTypes.secondaryUncoloredPagination}
				key={e}
				onClick={() => {
					setPage(e);
				}}
				isActive={current === e}
			>
				{e}
			</Button>
		));
	}, [current, total, lastPage]);

	const handleManualChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (/^\d+$/.test(e.currentTarget.value) || e.currentTarget.value === "") {
			setManualPage(Number(e.currentTarget.value));
		}
	};

	const changePageAmount = (amount: number) => {
		setPerPage(amount);
		setPage(1);
		setShowPerPage(false);
	};

	const handleInputChange = (event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const isKeyboardEvent = (
			event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
		): event is React.KeyboardEvent<HTMLInputElement> => {
			return "key" in (event as React.KeyboardEvent<HTMLInputElement>);
		};
		const isClickEvent = (
			event: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
		): event is React.KeyboardEvent<HTMLInputElement> => {
			if ("type" in (event as React.MouseEvent<HTMLButtonElement, MouseEvent>)) return event.type === "click";
			return false;
		};

		const setPageNumberByUserInput = () => {
			const manualPageNum = Number(manualPage);
			if (manualPageNum > lastPage) {
				setPage(lastPage);
				setManualPage(lastPage);
			} else if (manualPageNum < 1) {
				setPage(1);
				setManualPage(1);
			} else if (manualPageNum <= lastPage && manualPageNum >= 1) {
				setPage(manualPageNum);
			}
		};

		if (isClickEvent(event)) {
			setPageNumberByUserInput();
		}

		if (isKeyboardEvent(event)) {
			const code = event.code.toLowerCase();
			const isEnter = code.includes("enter");
			const isArrowUp = code.includes("arrowup");
			const isArrowDown = code.includes("arrowdown");
			const isArrowRight = code.includes("arrowright");
			const isArrowLeft = code.includes("arrowleft");

			if (isEnter) {
				setPageNumberByUserInput();
			}
			if (isArrowUp) {
				setManualPage((prev) => Math.min(prev + 1, lastPage));
			}

			if (isArrowDown) {
				setManualPage((prev) => Math.max(prev - 1, 1));
			}

			if (isArrowRight) {
				setManualPage((prev) => Math.min(prev + 10, lastPage));
			}

			if (isArrowLeft) {
				setManualPage((prev) => Math.max(prev - 10, 1));
			}
		}
	};

	return (
		<div className={styles["pagination-container"]}>
			<div className={styles["pagination-left"]}>
				<div className={styles["pagination-quantity"]}>
					Количество заявок: {totalOnPage}/{total}
				</div>
				<div className={styles["pagination-perpage-change"]}>
					Отображать по:
					<div className={classNames(styles["pagination-perpage-container"], isFetching ? styles["pagination-disabled"] : "")}>
						<div
							onClick={handleButtonClick}
							className={classNames(
								styles["pagination-perpage-amount"],
								{
									[styles["pagination-perpage-amount-active"]]: showPerPage,
								},
								isFetching ? styles["pagination-disabled"] : "",
							)}
						>
							{perPage} <i className={showPerPage ? "icon icon-cheveron-up" : "icon icon-cheveron-down"} />
						</div>
						{showPerPage && (
							<div className={styles["pagination-perpage-control"]}>
								<div ref={ref}>
									<ul className={styles["pagination-perpage-list"]}>
										<li
											className={classNames(styles["pagination-perpage-item"], {
												[styles["pagination-perpage-item-active"]]: perPage === 10,
											})}
											onClick={() => changePageAmount(10)}
										>
											10
										</li>
										<li
											className={classNames(styles["pagination-perpage-item"], {
												[styles["pagination-perpage-item-active"]]: perPage === 25,
											})}
											onClick={() => changePageAmount(25)}
										>
											25
										</li>
										<li
											className={classNames(styles["pagination-perpage-item"], {
												[styles["pagination-perpage-item-active"]]: perPage === 50,
											})}
											onClick={() => changePageAmount(50)}
										>
											50
										</li>
										<li
											className={classNames(styles["pagination-perpage-item"], {
												[styles["pagination-perpage-item-active"]]: perPage === 100,
											})}
											onClick={() => changePageAmount(100)}
										>
											100
										</li>
									</ul>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={classNames(styles["pagination-right"], isFetching ? styles["pagination-disabled"] : "")}>
				<div className={styles["pagination-controls"]}>
					<Button
						type={ButtonTypes.secondaryUncolored}
						disabled={current === 1}
						onClick={() => setPage(current - 1)}
						iconLeft={<i className={"icon icon-cheveron-left"} />}
					/>
					{lastPage > 6 && current >= 5 && (
						<Button type={ButtonTypes.secondaryUncolored} isActive={current === 1} onClick={() => setPage(1)}>
							1
						</Button>
					)}
					{lastPage > 6 && current >= 5 && (
						<Button type={ButtonTypes.secondaryUncolored} onClick={goFivePagesBack}>
							...
						</Button>
					)}
					{buttons}
					{lastPage > 6 && lastPage - current >= 3 && (
						<Button type={ButtonTypes.secondaryUncolored} onClick={goFivePagesFwd}>
							...
						</Button>
					)}

					{lastPage > 6 && (
						<Button type={ButtonTypes.secondaryUncolored} isActive={current === lastPage} onClick={() => setPage(lastPage)}>
							{lastPage}
						</Button>
					)}
					<Button type={ButtonTypes.secondaryUncolored} disabled={current === lastPage} onClick={() => setPage(current + 1)}>
						<i className={"icon icon-cheveron-right"} />
					</Button>
				</div>
				<p>Перейти к:</p>
				<div className={styles["pagination-input-container"]}>
					<input
						className={classNames(styles["pagination-input"], isFetching ? styles["pagination-disabled"] : "")}
						value={manualPage}
						onChange={handleManualChange}
						onKeyDown={handleInputChange}
					/>
					<button onClick={handleInputChange} className={classNames(styles["pagination-input-btn"], styles["pagination-btn"])}>
						<i className="icon icon-arrow-right" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
