import { useActor } from "@xstate/react";
import cn from "classnames";
import { type ReactNode, useEffect, useRef, useState } from "react";
import { useGlobalPhonerState } from "../../../../app/providers/xstate-provider";
import { Icon } from "../../../../shared";
import { usePhoner } from "../../../contexts/phoner/phoner";
import styles from "./draggable-area.module.scss";

type IDraggableArea = {
	children: ReactNode;
};

const DRAGGABLE_AREA_WIDTH = 40;
const DRAGGABLE_AREA_HEIGHT = 46;
const DRAGGABLE_TRACK_WIDTH = 20;
const DRAGGABLE_TRACK_HEIGHT = 30;

const DRAGGABLE_AREA_OFFSET_Y_FOR_BOTTOM_DIRECTION = -26;
const DRAGGABLE_AREA_OFFSET_Y_FOR_TOP_DIRECTION = -20;

const DRAGGABLE_TRACK_OFFSET_Y_FOR_BOTTOM_DIRECTION = 6;
const DRAGGABLE_TRACK_OFFSET_Y_FOR_TOP_DIRECTION = 10;

let timer: ReturnType<typeof setTimeout>;

export const DraggableArea = ({ children }: IDraggableArea) => {
	const { phonerDraggingService, phonerIncomingCallService, phonerSecondLineService } = useGlobalPhonerState();

	const { setCurrentActiveWidget } = usePhoner();
	const [statePhonerDragging] = useActor(phonerDraggingService);
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const showCurrentCall = statePhonerIncomingCall.matches("current call");
	const showSecondLine = !statePhonerSecondLine.matches("idle") && !statePhonerSecondLine.matches("second call received");
	const isShowCurrentCall = showCurrentCall && !showSecondLine;
	const isDragging = statePhonerDragging.context.isDragging;
	const ref = useRef(null);
	const mountedContentWidth = useRef<HTMLDivElement>(null);
	const [isTrackActive, setIsTrackActive] = useState(false);
	const [draggableAreaHover, setDraggableAreaHover] = useState(false);
	const [contentAreaHover, setContentAreaHover] = useState(false);
	const [contentContainerData, setContentContainerData] = useState<HTMLDivElement>();
	const [isDragTrackWasActive, setIsDragTrackWasActive] = useState(false);
	const [iconAlign, setIconAlign] = useState<"end" | "start">("end");
	const topSlotAnimationBottomDirection = statePhonerDragging.context.animationTransitionConfig.animationDirection === "bottom";

	const [draggableWrapperOffsetY, setDraggableWrapperOffsetY] = useState(
		topSlotAnimationBottomDirection ? DRAGGABLE_AREA_OFFSET_Y_FOR_BOTTOM_DIRECTION : DRAGGABLE_AREA_OFFSET_Y_FOR_TOP_DIRECTION,
	);

	const [draggableTrackOffsetY, setDraggableTrackOffsetY] = useState(
		topSlotAnimationBottomDirection ? DRAGGABLE_TRACK_OFFSET_Y_FOR_BOTTOM_DIRECTION : DRAGGABLE_TRACK_OFFSET_Y_FOR_TOP_DIRECTION,
	);

	useEffect(() => {
		if (!isDragging) {
			setDraggableWrapperOffsetY(() =>
				topSlotAnimationBottomDirection ? DRAGGABLE_AREA_OFFSET_Y_FOR_BOTTOM_DIRECTION : DRAGGABLE_AREA_OFFSET_Y_FOR_TOP_DIRECTION,
			);
			setDraggableTrackOffsetY(() =>
				topSlotAnimationBottomDirection
					? DRAGGABLE_TRACK_OFFSET_Y_FOR_BOTTOM_DIRECTION
					: DRAGGABLE_TRACK_OFFSET_Y_FOR_TOP_DIRECTION,
			);
			topSlotAnimationBottomDirection ? setIconAlign("start") : setIconAlign("end");
			return;
		}
		setDraggableWrapperOffsetY(draggableWrapperOffsetY);
		setDraggableTrackOffsetY(draggableTrackOffsetY);
	}, [topSlotAnimationBottomDirection, isDragging]);

	useEffect(() => {
		if (ref.current) {
			setCurrentActiveWidget(ref.current);
		}
	}, [ref.current]);

	const draggableTrackStyles = cn(styles["draggable-track"], {
		[styles.hover as string]: (draggableAreaHover && !contentAreaHover) || isDragging,
		[styles.active as string]: isTrackActive,
		[styles.delay as string]: !contentAreaHover && !isDragTrackWasActive && !isTrackActive && draggableAreaHover,
	});

	const draggableTrackIconAlign = cn({
		[styles["align-start"] as string]: iconAlign === "start",
		[styles["align-end"] as string]: iconAlign === "end",
	});

	const containerStyles = cn(styles.container, {
		[styles["full-width"] as string]: isShowCurrentCall,
	});

	useEffect(() => {
		if (mountedContentWidth.current) {
			setContentContainerData(mountedContentWidth.current);
		}
	}, [mountedContentWidth.current]);

	useEffect(() => {
		if (!isTrackActive) {
			clearTimeout(timer);
			timer = setTimeout(() => setIsDragTrackWasActive(false), 300);
		}
	}, [isTrackActive]);

	const draggableAreaWrapperWidth = (contentContainerData?.offsetWidth ?? 0) + DRAGGABLE_AREA_WIDTH;
	const draggableAreaWrapperHeight = (contentContainerData?.offsetHeight ?? 0) + DRAGGABLE_AREA_HEIGHT;
	const draggableTrackWrapperWidth = (contentContainerData?.offsetWidth ?? 0) + DRAGGABLE_TRACK_WIDTH;
	const draggableTrackWrapperHeight = (contentContainerData?.offsetHeight ?? 0) + DRAGGABLE_TRACK_HEIGHT;

	const draggableTrackOnMouseDown = () => {
		setIsTrackActive(true);
	};

	const draggableTrackOnMouseUp = () => {
		!isDragging && setIsTrackActive(false);
		setIsDragTrackWasActive(true);
	};

	const draggableAreaWrapperOnMouseLeave = () => {
		setDraggableAreaHover(false);
		!isDragging && setIsTrackActive(false);
	};

	const draggableAreaWrapperOnMouseEnter = () => {
		setDraggableAreaHover(true);
	};

	const draggableAreaWrapperOnMouseDown = () => {
		setIsTrackActive(true);
	};
	const draggableAreaWrapperOnMouseUp = () => {
		setIsTrackActive(false);
	};

	const contentWrapperOnMouseEnter = () => {
		!isDragging && setContentAreaHover(true);
	};
	const contentWrapperOnMouseLeave = () => {
		setContentAreaHover(false);
	};

	return (
		<div className={containerStyles}>
			<div
				onMouseEnter={draggableAreaWrapperOnMouseEnter}
				onMouseLeave={draggableAreaWrapperOnMouseLeave}
				onMouseDown={draggableAreaWrapperOnMouseDown}
				onMouseUp={draggableAreaWrapperOnMouseUp}
				style={{
					height: `${draggableAreaWrapperHeight}px`,
					width: `${draggableAreaWrapperWidth}px`,
					transform: `translate(${-20}px, ${draggableWrapperOffsetY}px)`,
					cursor: isDragging ? "grabbing" : "grab",
				}}
				ref={ref}
				className={`${styles["draggable-area-wrapper"]} canDraggable `}
			>
				<div
					onMouseDown={draggableTrackOnMouseDown}
					onMouseUp={draggableTrackOnMouseUp}
					style={{
						height: `${draggableTrackWrapperHeight}px`,
						width: `${draggableTrackWrapperWidth}px`,
						top: `${draggableTrackOffsetY}px`,
					}}
					className={`${draggableTrackStyles} ${draggableTrackIconAlign}`}
				>
					<Icon icon="icon-bx-grid-horizontal" />
				</div>
			</div>
			<div
				style={{ position: "relative", zIndex: 1 }}
				ref={mountedContentWidth}
				onMouseEnter={contentWrapperOnMouseEnter}
				onMouseLeave={contentWrapperOnMouseLeave}
			>
				{children}
			</div>
		</div>
	);
};
