import { BadgeProps } from '@common/badge/badge.types'
import styles from './badge.module.scss'
import { Typography } from '@components/common'
import cn from 'classnames'

export const Badge = ({ children, className = '', isQuiz = false }: BadgeProps) => {
  const badgeClasses = cn(styles.badge, className)
  
  let tag: 3 | 4 | 5 | 6 = 6;
  let weight = 3;

  if (isQuiz) {
    tag = 4;
    weight = 2;
  }

  return (
    <div className={badgeClasses}>
      <Typography
        className={styles['badge-text']}
        as={'caption'}
        decoration={'none'}
        tag={tag}
        weight={weight}
      >
        {children}
      </Typography>
    </div>
  )
}
