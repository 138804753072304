import { Typography } from '@/components/common'
import { MasterCardHeaderRightContentTypeProps } from './master-card-modal-right-content.types'

export const MasterCardHeaderRightContent = ({
  workedAt,
}: MasterCardHeaderRightContentTypeProps) => {
  return (
    <>
      {workedAt && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Typography as={'paragraph'} decoration={'none'} tag={4}>
            Работает с: {workedAt}
          </Typography>
        </div>
      )}
    </>
  )
}
