import { ArrowLeftIcon } from "../../../../shared/components/arrow-left-icon"
import { useGlobalPhonerState } from '../../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'

export const BackToCurrentCallButton= () => {


  const { phonerIncomingCallService } = useGlobalPhonerState()
  const [, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService)

  const handleBackToCurrentCall = () => {
    sendPhonerIncomingCallEvents('back to current')
  }

  return (
    <ArrowLeftIcon
        handleArrowLeftIcon={handleBackToCurrentCall}
        isArrowLeftIconDisabled={false}
    />
  )
}
