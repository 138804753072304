import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { deviceInitialState } from "./initial-state";

const deviceSlice = createSlice({
  initialState: { ...deviceInitialState },
  name: "device",
  reducers: {
    identifyTheDevice: (state, { payload }: PayloadAction<number>) => {
      state.isTablet = payload < 900 && payload > 550;
      state.isMobileOnly = payload < 550;
      state.isMobile = payload < 768;
      state.isDesktop = payload > 768;
      state.isLargeDesktop = payload > 1300;
      if (payload <= 320) {
        state.screenSize = "XS";
      } else if (payload <= 568) {
        state.screenSize = "S";
      } else if (payload <= 1024) {
        state.screenSize = "M";
      } else if (payload <= 1280) {
        state.screenSize = "L";
      } else if (payload <= 1366) {
        state.screenSize = "XL";
      } else if (payload > 1366) {
        state.screenSize = "XXL";
      }
    },
  },
});

export const { reducer: deviceReducer } = deviceSlice;
export const { identifyTheDevice } = deviceSlice.actions;
