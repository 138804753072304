import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import React from 'react'
import { useField } from 'react-final-form'
import { FilterNames } from '@components/headers/requests-header/request-header-filters/utils/filter-names'
import { useNavigate } from 'react-router-dom'
import { FILTER_TEMPLATES_DESTROY } from '@constants/routes'

export const FilterDeleteTemplate = ({
  resetInitialState,
}: {
  resetInitialState: () => void
}) => {
  const templateValue = useField(FilterNames.template)
  const navigate = useNavigate()
  const canDelete =
    templateValue.input.value &&
    typeof templateValue.input.value === 'object' &&
    templateValue.input.value.id

  return (
    <Button
      disabled={!canDelete}
      btnType={'button'}
      type={ButtonTypes.secondary}
      size={UISizes.medium}
      onClick={() => {
        navigate(
          `/requests/${FILTER_TEMPLATES_DESTROY(
            templateValue.input.value.id || String(0),
          )}`,
        )
        templateValue.input.onChange(undefined)
        resetInitialState()
      }}
    >
      Удалить шаблон
    </Button>
  )
}
