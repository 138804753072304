import {
  TableBodyProps,
  TableColumnItemProps,
  TableColumnProps,
  TableHeaderProps,
  TableProps,
  TableRowItemProps,
  TableRowProps,
} from './table.types'
import { flexRender, getCoreRowModel, getSortedRowModel, Row, SortingState, useReactTable } from '@tanstack/react-table'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import cn from 'clsx'

import styles from './table.module.scss'
import { Typography } from '../../../ui-kit/typography'
export const Table = <TableItem,>({ columns, tableRows = [], className, children, handleScrollPage }: TableProps) => {
  const parentRef = useRef<HTMLDivElement>(null)

  const [data, setData] = useState(() => tableRows)
  //const rerender = useReducer(() => ({}), {})[1]

  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    overscan: 100,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 44, []),
  })
  const { getVirtualItems } = rowVirtualizer

  useEffect(() => {
    if (rowVirtualizer.range.endIndex >= tableRows.length - 1) {
      if (Array.isArray(tableRows)) {
        handleScrollPage && handleScrollPage()
      }
    }
  }, [rowVirtualizer.range])

  const virtualRows = getVirtualItems()

  const tableContainerClasses = cn(styles.container, className)

  const refreshData = () => setData(() => tableRows)

  useEffect(() => {
    refreshData()
  }, [tableRows.length])


  return (
    <div className={styles['table-wrapper']}>
       <Table.Header>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Column key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Table.ColumnItem
                      key={header.id}
                      style={{ minWidth: header.column.getSize() }}
                      {...{
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      <Typography as={'caption'} tag={5} decoration={'none'}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>

                      {/* <Icon icon={'icon-bx-sort-alt-2-1'} /> */}
                    </Table.ColumnItem>
                  )
                })}
              </Table.Column>
            ))}
          </Table.Header>
      <div ref={parentRef} className={tableContainerClasses}>
        <Table.Body
          mRef={tableContainerRef}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}>
         
          {!!(tableRows && tableRows?.length) && (
            virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<TableItem>

              return (
                <Table.Row
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  mRef={rowVirtualizer.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualRow.start}px)`,
                  }}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Table.RowItem key={cell.id} style={{ minWidth: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.RowItem>
                    )
                  })}
                </Table.Row>
              )
            })
          )} 
        </Table.Body>
      </div>
    </div>
  )
}

Table.Header = ({ children, className, ...props }: TableHeaderProps) => {
  const headerTableClasses = cn(styles.header, className)

  return (
    <div className={headerTableClasses} {...props}>
      {children}
    </div>
  )
}
Table.Body = ({ children, className, mRef, ...props }: TableBodyProps) => {
  const bodyTableClasses = cn(styles.body, className)

  return (
    <div ref={mRef} className={bodyTableClasses} {...props}>
      {children}
    </div>
  )
}
Table.Column = ({ children, className, onClick, ...props }: TableColumnProps) => {
  const columnTableClasses = cn(styles.column, className)

  return (
    <div className={columnTableClasses} {...props}>
      {children}
    </div>
  )
}
Table.ColumnItem = ({ children, className, ...props }: TableColumnItemProps) => {
  const columnItemTableClasses = cn(styles['column-item'], className)

  return (
    <div className={columnItemTableClasses} {...props}>
      {children}
    </div>
  )
}

Table.Row = ({ children, className, onClick, mRef, ...props }: TableRowProps) => {
  const rowTableClasses = cn(styles.row, className)

  return (
    <div ref={mRef} className={rowTableClasses} {...props}>
      {children}
    </div>
  )
}
Table.RowItem = ({ children, className, ...props }: TableRowItemProps) => {
  const rowItemTableClasses = cn(styles['row-item'], className)

  return (
    <div className={rowItemTableClasses} {...props}>
      {children}
    </div>
  )
}
