import { SidebarMenuContentProps } from '@common/sidebar-menu/sidebar-menu.types'
import styles from '@common/sidebar-menu/sidebar-menu.module.scss'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

export const SidebarMenuContent = ({
  children,
  isOpened,
  handleOpenSidebar,
}: Partial<SidebarMenuContentProps>) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  let selectedCard = null
  const top = useRef<number | undefined>(null)

  const [scrollPosition, setScrollPosition] = useState<
    'top' | 'bottom' | 'middle'
  >()

  const handleShowScrollTopBtn = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop === 0 && setScrollPosition('top')
      scrollContainerRef.current.scrollTop >= 20 && setScrollPosition('middle')
      scrollContainerRef.current.scrollTop >=
        scrollContainerRef.current.scrollHeight -
          scrollContainerRef.current.getBoundingClientRect().bottom &&
        setScrollPosition('bottom')
    }
  }
  const scrollToCard = () => {
    if (scrollContainerRef.current && top.current) {
      scrollContainerRef.current.scrollBy({
        top: top.current - 300,
        behavior: 'auto',
      })
    }
  }

  useEffect(() => {
    selectedCard = document.querySelector('[aria-selected="true"]')
    // @ts-ignore
    top.current = selectedCard?.getClientRects()[0]?.top

    scrollToCard()
  }, [scrollContainerRef, isOpened])

  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener(
        'scroll',
        handleShowScrollTopBtn,
      )
    }
    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef?.current?.removeEventListener(
          'scroll',
          handleShowScrollTopBtn,
        )
      }
    }
  }, [])


  const sidebarMenuContentClasses = cn([styles['sidebar-menu_content']], {
    [styles['sidebar-menu_content_mask']]: scrollPosition === 'middle',
    [styles['sidebar-menu_content__opened']]: isOpened,
  })


  return (
    <div className={styles['sidebar-menu_content-container']}>
      <div ref={scrollContainerRef} className={sidebarMenuContentClasses}>
        {children}
      </div>
    </div>
  )
}
