import { ContentLabel, LabelContainer, ModalContainer, Typography } from '@components/common'
import { ModalBody, ModalHeader } from '@common/modal-container'
import { SelectionOfMastersModalProps } from './selection-of-masters.types'
import { SelectionOfMastersModalLeftContent } from './left-content'
import { useEffect, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { TableSelectionOfMasters } from '@components/modals/selection-of-masters/body-content/table'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import { ContentLoader, Input, TabsSwitcher } from '@/components'
import { Checkbox } from '@components/form/checkbox'
import { useNavigate } from 'react-router-dom'
import { nanoid } from '@reduxjs/toolkit'
import { ContentLabelsBlock } from '@common/content-label'
import { MasterSelectionResource, useMasterSelectionsIndexQuery, useRequestsMastersStoreMutation } from '@/state/api'
import { BasicInputValues } from '@global-types/utils-type'
import { Field, useField } from 'react-final-form'
import { CreateRequestNames } from '@constants/create-request'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'
import { Outlet, useLocation, useParams } from 'react-router'
import styles from './selection-of-masters.module.scss'
import { useUserProfileInfo } from '@/state/selector'

export const SelectionOfMastersModal = ({
  modalView,
  onClose,
  onClosePath,
  title,
  subtitle,
  showBackButton,
  onHandleBackButton,
  enableMultiRowSelection = true,
  closeOnBlackoutClick = true,
  onSelectMasters,
}: Partial<SelectionOfMastersModalProps>) => {
  // делаем запрос
  const profile = useUserProfileInfo()

  const whiteListTags = ['выходной']

  const [lastSubmittedState, setLastSubmittedState] = useState<number[]>([])

  const { requestId, fullcodeId } = useParams()
  const location = useLocation()
  const onSelectMastersChange = useField(CreateRequestNames.selectedMasters).input.onChange
  const selectedMasters = useField(CreateRequestNames.selectedMasters).input.value || []
  const masterSelectionFilter = useField(CreateRequestNames.masterSelectionFilter).input.value
  const typeOfWorkId = useField(CreateRequestNames.typeOfWork).input.value[0]
  const districtId = useField(CreateRequestNames.districtId).input.value[0]

  const [search, setSearch] = useState<BasicInputValues>('')
  const [rowSelection, setRowSelection] = useState<MasterSelectionResource[] | null>(
    selectedMasters.length > 0 ? selectedMasters : lastSubmittedState,
  )

  const navigate = useNavigate()

  const [
    sendSelectedMasters,
    { isLoading: isSendSelectionMastersLoading, isSuccess: isSendSelectionMastersSuccess, isError: isSendSelectionMastersError },
  ] = useRequestsMastersStoreMutation()

  // делаем запрос
  const {
    data,
    isFetching: isMastersSelectionsFetching,
    isError,
    refetch,
    isSuccess: isMastersSelectionsSuccess,
    error,
  } = useMasterSelectionsIndexQuery({
    type: masterSelectionFilter,
    workId: location?.state?.work || undefined,
    districtId: location?.state?.district || undefined,
    requestId: modalView === 'create' ? undefined : Number(requestId),
  })

  const commonSelectedMasters = useField('commonSelectedMasters').input.value
  const commonSelectedMastersField = useField('commonSelectedMasters')
  const handleRefetchMasterSelections = () => {
    refetch()
  }

  useEffect(() => {
    if (isSendSelectionMastersSuccess) onClose && onClose()
  }, [isSendSelectionMastersSuccess])

  const handleSendLastSubmittedState = () => {
    Array.isArray(lastSubmittedState) &&
      sendSelectedMasters({
        requestId: Number(requestId),
        requestsRequestMasterStore: {
          id: [...lastSubmittedState.map((item: any) => item.id)],
        },
      })
  }

  const handleSendSelectedMasters = () => {
    clearSnacks()
    setLastSubmittedState([...commonSelectedMasters])
    selectedMasters &&
      sendSelectedMasters({
        requestId: Number(requestId),
        requestsRequestMasterStore: {
          id: [...new Set([...commonSelectedMasters])].map((item: any) => item.id),
        },
      })
  }
  useEffect(() => {
    isSendSelectionMastersSuccess && navigate('../')
  }, [isSendSelectionMastersSuccess])

  useEffect(() => {
    if (isSendSelectionMastersError) {
      addSnack({
        type: 'error',
        eventName: 'add-master-selections-error',
        text: 'Не удалось добавить выбранных мастеров. Попробуйте еще раз.',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => {
          handleSendLastSubmittedState()
        },
      })
    }

    if (isError) {
      console.error('Ошибка загрузки данных', error)
      addSnack({
        type: 'error',
        eventName: 'fetch-master-selections-error',
        text: 'Не удалось загрузить список мастеров. Попробуйте еще' + ' раз.',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchMasterSelections(),
      })
    }
  }, [isError, isSendSelectionMastersError])

  // кастомная логика состояния чекбоксов

  const [allChecked, setAllChecked] = useState(false)

  function contains(): any {
    const commonSelectedMastersIdArray = commonSelectedMasters?.map((master: any) => master.id)
    if (data?.data?.length) {
      for (const master of data?.data) {
        if (commonSelectedMastersIdArray.indexOf(master.id) == -1) {
          setAllChecked(false)
          return
        } else {
          setAllChecked(true)
        }
      }
    }
  }

  useEffect(() => {
    contains()
  }, [allChecked, commonSelectedMasters])

  function checkIsAllMAsterSelected(): any {
    const commonSelectedMastersIdArray = commonSelectedMasters?.map((master: any) => master.id)
    let mastersWithFiltration = data?.data

    if (!profile?.permissions?.CAN_REQUEST_SELECT_ANY_MASTER) {
      mastersWithFiltration = mastersWithFiltration?.filter((master) => {
        const tags = master.tags?.map((tag) => tag.title?.toLocaleLowerCase())

        const isAvailable = !tags?.filter((tag) => !whiteListTags.includes(tag ?? ''))?.length

        return isAvailable
      })
    }

    if (data?.data?.length) {
      for (const master of mastersWithFiltration ?? []) {
        if (commonSelectedMastersIdArray.indexOf(master.id) === -1) {
          setAllChecked(false)
          return
        } else {
          setAllChecked(true)
        }
      }
      if (!mastersWithFiltration || !mastersWithFiltration.length) {
        setAllChecked(false)
      }
    }
  }

  useEffect(() => {
    checkIsAllMAsterSelected()
  }, [commonSelectedMasters, data?.data])

  const columnHelper = createColumnHelper<any>()
  const columns = [
    //указываем ключи объекта первым аргументом
    columnHelper.display({
      id: 'checkbox',
      size: 40,
      header: ({ table }) => {
        return (
          <Checkbox
            className={styles['selection-masters-modal-checkbox']}
            {...{
              checked: allChecked,
              value: allChecked,
              indeterminate: true,
              onChange: (e) => {
                let newUpdater = {}
                let selectedMasters: Array<any> = []

                table.getRowModel().rows.map((row: any) => {
                  const isCheckboxDisabled =
                    !!row?.original?.tags
                      ?.map((tag: { title?: string; color?: string }) => tag?.title?.trim()?.toLowerCase())
                      ?.filter((tag: string) => !whiteListTags?.includes(tag))?.length &&
                    !profile?.permissions?.CAN_REQUEST_SELECT_ANY_MASTER

                  let isSelected = e.target.checked

                  if (isCheckboxDisabled) {
                    isSelected = row.getIsSelected()
                  }

                  newUpdater = { ...newUpdater, [row.id]: isSelected }

                  if (!isCheckboxDisabled) {
                    selectedMasters = [...selectedMasters, row?.original]
                  }
                })

                if (!e.target.checked) {
                  commonSelectedMastersField.input.onChange(
                    commonSelectedMasters.filter((master: any) => !data?.data?.some((dataMaster) => master.id === dataMaster.id)),
                  )
                } else {
                  const filteredMasters = commonSelectedMasters.filter(
                    (master: any) => !data?.data?.some((dataMaster) => master.id === dataMaster.id),
                  )
                  commonSelectedMastersField.input.onChange(filteredMasters ? [...filteredMasters, ...selectedMasters] : selectedMasters)
                }

                table?.setRowSelection((updater) => {
                  return newUpdater
                })
              },
            }}
          />
        )
      },
      cell: ({ row }) => {
        const whiteListTags = ['выходной']

        const isWeekend = !!row?.original?.tags
          ?.map((tag: { title?: string; color?: string }) => tag?.title?.trim()?.toLowerCase())
          ?.filter((tag: string) => !whiteListTags?.includes(tag))?.length

        const isCheckboxDisabled = profile?.permissions?.CAN_REQUEST_SELECT_ANY_MASTER ? false : isWeekend

        return (
          <Checkbox
            className={styles['selection-masters-modal-checkbox']}
            disabled={isCheckboxDisabled}
            checked={row.getIsSelected()}
            onChange={(e) => {
              if (masterSelectionFilter === 'optimal') {
                Array.isArray(commonSelectedMastersField?.input?.value) &&
                  commonSelectedMastersField.input.onChange(
                    commonSelectedMastersField?.input?.value?.filter((el: any) => el.id !== row.original.id),
                  )
              } else {
                Array.isArray(commonSelectedMastersField?.input?.value) &&
                  commonSelectedMastersField.input.onChange(
                    commonSelectedMastersField?.input?.value?.filter((el: any) => el.id !== row.original.id),
                  )
              }

              row.getToggleSelectedHandler()(e)
            }}
          />
        )
      },
    }),
    columnHelper.display({
      id: 'page-link',
      size: 40,
      header: ({ table }) => (
        <div className={styles['selection-masters-modal_page-link']}>
          <i className={'icon icon-page'} />
        </div>
      ),
      cell: ({ row }) => (
        <div className={styles['selection-masters-modal_page-link']}>
          <i className={'icon icon-page'} />
        </div>
      ),
    }),
    columnHelper.accessor('fio', {
      size: 360,
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Мастер
        </Typography>
      ),
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row?.day_limit, {
      size: 188,
      id: 'dayLimit',
      cell: (info) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography as={'caption'} decoration={'none'} tag={4} weight={2}>
              {info?.getValue().split(':')[0]}:
            </Typography>
            <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
              {info?.getValue().split(':')[1]}
            </Typography>
          </div>
        )
      },
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Лимиты
        </Typography>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('tags', {
      size: 468,
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Теги{' '}
        </Typography>
      ),
      cell: (info) => {
        const tags = info.row.original?.tags

        return (
          <ContentLabelsBlock>
            {tags.length ? (
              tags?.map((tag: { title: string; color: string }) => {
                return (
                  <ContentLabel
                    key={nanoid()}
                    customBgColor={tag?.color} //: 'danger'
                  >
                    <Typography as={'caption'} decoration={'none'} tag={4} weight={2}>
                      {tag?.title}
                    </Typography>
                  </ContentLabel>
                )
              })
            ) : (
              <></>
            )}
          </ContentLabelsBlock>
        )
      },
      footer: (info) => info.column.id,
    }),
  ]

  return (
    <>
      <div className={styles['selection-masters-modal']}>
        <ModalContainer
          zIndex={2}
          className={styles['selection-masters-modal']}
          onClose={
            onClose
              ? onClose
              : onClosePath
              ? () => {
                  navigate(onClosePath)
                  clearSnacks()
                }
              : () => navigate('/')
          }
          showBlackout
          closeOnBlackoutClick={closeOnBlackoutClick}>
          <ModalHeader
            showLeftContent
            showRightContent
            contentLeft={
              <SelectionOfMastersModalLeftContent
                showBackButton
                title={title}
                subtitle={fullcodeId ? `Заявка #${fullcodeId}` : ''}
                onHandleBackButton={() => {
                  navigate('../')
                  clearSnacks()
                }}
              />
            }
            contentRight={
              modalView === 'manual-selection' ? (
                <Button
                  disabled={!!((rowSelection && rowSelection.length === 0) || isMastersSelectionsFetching)}
                  btnType={'button'}
                  isLoading={isSendSelectionMastersLoading}
                  type={ButtonTypes.primary}
                  size={UISizes.medium}
                  onClick={() => handleSendSelectedMasters()}>
                  Добавить
                </Button>
              ) : null
            }
            showCloseButton
            onClose={
              onClose
                ? onClose
                : onClosePath
                ? () => {
                    navigate(onClosePath)
                    clearSnacks()
                  }
                : () => navigate('/')
            }
          />
          <ModalBody>
            <div className={styles['body-content_header']}>
              <div className={styles['body-content_header__input-container']}>
                <LabelContainer showLabel={true} label={'Имя мастера'} labelSide={'left'}>
                  <Input
                    onChange={(e) => {
                      e && typeof e !== 'string' && typeof e !== 'number' && setSearch(e.target?.value)
                    }}
                    value={search}
                    placeholder={'Поиск'}
                  />
                </LabelContainer>
              </div>
              <Field<number> name={'masterSelectionFilter'} type={'text'}>
                {({ input, meta }) => {
                  return (
                    <TabsSwitcher
                      onChange={(id) => input.onChange(id)}
                      tabs={[
                        { id: 'optimal', text: 'Оптимальный подбор' },
                        { id: 'approach', text: 'Подходящие по типу работы' },
                        // { id: 'all', text: 'Все мастера' }, //TODO: Тимур
                        //  сказал .что пока не определено кнкретно что именно
                        //  и как
                        //  убдет возвращаться по запросу Всех мастеров
                      ]}
                      activeTab={input.value}
                    />
                  )
                }}
              </Field>
            </div>
            <div className={styles['selection-masters-modal-content']}>
              {(isMastersSelectionsFetching || isSendSelectionMastersLoading) && <ContentLoader />}
              {!isError && !isMastersSelectionsFetching && !isSendSelectionMastersLoading && (
                <TableSelectionOfMasters
                  modalView={modalView}
                  masterSelectionFilter={masterSelectionFilter}
                  data={data?.data || []}
                  columns={columns}
                  pageSize={data?.data ? data?.data.length : 100}
                  enableRowSelection={true}
                  enableMultiRowSelection={enableMultiRowSelection}
                  globalFilter={search}
                  setGlobalFilter={setSearch}
                  onSelectMasters={onSelectMastersChange}
                  onSelectedRowsOriginalChange={setRowSelection}
                  rowSelectionOriginal={rowSelection}
                />
              )}
              {isError && (
                <Typography as={'paragraph'} decoration={'none'} tag={3}>
                  Не удалось подобрать мастера: сервер не отвечает или временно не доступен, попробуйте еще раз.
                </Typography>
              )}
            </div>
          </ModalBody>
        </ModalContainer>
      </div>
      <Outlet />
    </>
  )
}
