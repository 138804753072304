import { ContentLoader } from "@/components";
import { OLD_CMS_URL } from "@/constants/env";
import { useProfileQuery, useSsoLoginMutation } from "@/state/api";
import ErrorIndicator from "@components/error-indicator";
import { type ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const LoginMid = ({
	uid,
	token,
	phone,
	point,
}: {
	uid: string;
	token: string;
	phone: string;
	point: string;
}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [login] = useSsoLoginMutation();

	useEffect(() => {
		if (uid && token && phone && point) {
			login({
				requestsAuthLogin: {
					uid: Number(uid),
					token,
					phone,
					point: Number(point),
				},
			})
				.unwrap()
				.then(() => {
					searchParams.delete("uid");
					searchParams.delete("token");
					searchParams.delete("phone");
					searchParams.delete("point");
					setSearchParams(searchParams);
				})
				.catch(() => {
					window.location.replace(window.location.origin);
				});
		}
	}, []);
	return <ContentLoader />;
};

const RedirectMid = ({ link }: { link: string }) => {
	useEffect(() => {
		if (link) {
			window.location.replace(link);
		}
	}, [link]);

	return <ContentLoader />;
};

const GetProfileMid = ({ children }: { children: ReactNode }) => {
	const { data: user, isError, isLoading, error } = useProfileQuery();

	if (isLoading) {
		return <ContentLoader />;
	}
	if (isError) {
		//@ts-ignore
		const redirectURL = error?.data?.data?.redirect_url as string;

		return <RedirectMid link={redirectURL ? redirectURL : OLD_CMS_URL} />;
		return null;
	}

	if (user?.data) {
		return <>{children}</>;
	}
	return <ErrorIndicator reloadAfterError={true} />;
};
export const AuthMiddleware = ({ children }: { children: ReactNode }) => {
	const [searchParams] = useSearchParams();

	const uid = searchParams.get("uid");
	const token = searchParams.get("token");
	const phone = searchParams.get("phone");
	const point = searchParams.get("point");

	if (uid && uid !== "" && token && token !== "" && phone && phone !== "" && point && point !== "") {
		return <LoginMid phone={phone} uid={uid} token={token} point={point} />;
	}

	return <GetProfileMid>{children}</GetProfileMid>;
};
