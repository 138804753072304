import { shortFio } from "@/utils/short-fio";
import { ContentDivider } from "@common/content-divider";
import type { RequestCardContentProps } from "@common/request-card/request-card.types";
import cn from "classnames";
import { useMemo } from "react";
import { Title } from "../../title";
import { Typography } from "../../typography";
import styles from "../request-card.module.scss";
import { RequestCardComment } from "./request-card-comment/request-card-comment";
import { RequestCardContentItem } from "./request-card-content-item";

export const formatCommentTime = (val?: string) => {
	if (val) {
		const splittedVal = val.split(" ");
		const time = splittedVal?.[1]?.substring(0, 5);

		return `${splittedVal[0]} ${time}`;
	}
	return "";
};

export const RequestCardContent = ({
	rightContent,
	leftContent,
	requestComments,
	qualityControlComments,
}: Partial<RequestCardContentProps>) => {
	const comments = useMemo(() => {
		const okk = qualityControlComments?.filter((item) => item.type === "OKK");
		const tele = qualityControlComments?.filter((item) => item.type === "Телемаркетинг");
		const ozok = requestComments?.filter((comment) => comment.author?.group?.name === "ОЗОК");
		const teleQuantity = okk?.length ? 1 : 2;
		const okkQuantity = tele?.length ? 1 : 2;
		const ozokQuantity = ozok?.length ? 1 : 2;

		const lastThreeSimpleComments = requestComments?.length
			? [...requestComments].slice(-2).filter((comment) => comment.author?.group?.name !== "ОЗОК")
			: [];

		return {
			okk: okk?.slice(-okkQuantity),
			tele: tele?.slice(-teleQuantity),
			ozok: ozok?.slice(-ozokQuantity),
			lastThreeSimpleComments,
		};
	}, [qualityControlComments, requestComments]);

	const ozokBackgroundColor = requestComments?.find((comment) => comment.author?.group?.background_color !== null)?.author?.group
		?.background_color;

	const lastThreeComments = (
		<div className={styles["okk-container"]}>
			<Title as={"h5"}>Комментарии</Title>
			<div className={styles["request-card__comments-wrapper"]}>
				{comments.lastThreeSimpleComments &&
					[...comments.lastThreeSimpleComments].map((comment) => {
						const commentClasses = cn(styles["request-card__comment-container"], {
							[styles["request-card__comment-container--hide-from-master"]]: !comment?.show_to_master,
						});

						return (
							<div key={comment.id} className={commentClasses} style={{ width: "100%" }}>
								<RequestCardContentItem className={styles["request-card__comment"]} style={{ width: "100%" }}>
									<Typography
										className={`${styles["request-card-comment-date"]} ${styles["request-card-comment-date--no-wrap"]}`}
										as={"caption"}
										decoration={"none"}
										tag={5}
									>
										{comment.date ? formatCommentTime(comment.date) : ""}
									</Typography>
									<Typography
										className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
										as={"paragraph"}
										decoration={"none"}
										tag={4}
									>
										{`${shortFio(comment.user_fio)} [${comment?.state_name}] ${
											!comment?.show_to_master ? " [Не виден мастеру] " : ""
										}`}
										:
									</Typography>
									<div style={{ display: "contents", width: "100%" }}>
										<Typography
											className={styles["request-card-text-value"]}
											as={"paragraph"}
											decoration={"none"}
											style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
											tag={4}
										>
											{comment.text}
										</Typography>
									</div>
								</RequestCardContentItem>
							</div>
						);
					})}
			</div>
		</div>
	);

	const okkCommentsNode = (
		<div className={styles["okk-container"]}>
			<Title as={"h5"}>Комментарии ОКК</Title>
			<>
				{comments.okk?.map((item) => {
					return <RequestCardComment key={`${item.id}okk`} comment={item} />;
				})}
			</>
		</div>
	);

	const teleCommentsNode = (
		<div className={styles["okk-container"]}>
			<Title as={"h5"}>Комментарии Телемаркетинга</Title>
			{comments.tele?.map((item) => {
				return <RequestCardComment key={`${item.id}tele`} comment={item} />;
			})}
		</div>
	);
	const ozokCommentsNode = (
		<div className={styles["ozok-container"]} style={{ background: `${ozokBackgroundColor}` }}>
			<Title as={"h5"}>Комментарии ОЗОК</Title>
			{comments.ozok?.map((item) => {
				return <RequestCardComment key={`${item.id}tele`} comment={item} />;
			})}
		</div>
	);

	return (
		<div className={styles["request-card_content-container"]}>
			<div className={styles["request-card_content"]}>
				<div className={styles["request-card_content__left"]}>{leftContent}</div>
				<ContentDivider type={"vertical"} height={"208px"} />
				<div className={styles["request-card_content__right"]}>{rightContent}</div>
			</div>
			<ContentDivider type={"vertical"} height={"208px"} />
			{requestComments?.length ? <ContentDivider type={"horizontal"} /> : null}
			{comments.lastThreeSimpleComments.length > 0 &&
				qualityControlComments?.length === 0 &&
				(comments.lastThreeSimpleComments.length > 0 ? lastThreeComments : null)}
			{comments.ozok?.length ? ozokCommentsNode : null}
			{qualityControlComments && qualityControlComments.length > 0 && (
				<div>
					{!!comments.okk?.length && okkCommentsNode}
					{!!comments.tele?.length && teleCommentsNode}
				</div>
			)}
		</div>
	);
};
