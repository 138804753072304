import { Input, InputContainer } from '../common'
import type { InputDefaultProps } from '../input-default/input-default.types'
import { Label } from '../common/label'
import styles from './input-default.module.scss'
import type { ElementType } from 'react'
import type { defaultInputElement } from '../common/input/input.types'
import cn from 'classnames'
import { Typography } from '../../typography'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import { UISizes } from '@/global-types/mods.types'

export const InputDefault = <T extends ElementType = typeof defaultInputElement>({
  readonly,
  inputContainerClasses,
  inputElementClasses,
  withRightButton = false,
  type = 'text',
  labelText = '',
  renderRightButton,
  renderDropdownMenu,
  renderSelectedItems,
  inputContainerOrientation = 'row',
  id,
  width = 'content',
  placeholder = '',
  inputProps,
  labelProps,
  state,
  disabled,
  maxLength,
  withResetButton,
  onReset,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  handleShowError = () => {},
  name,
  ...props
}: InputDefaultProps<T>) => {
  const withRightBtnContainerClasses = cn(styles['with-right-btn-container'], styles[`width-${width}`], {
    [styles['input-default']]: state === 'default',
    [styles['input-active']]: state === 'active',
    [styles['input-error']]: state === 'error',
    [styles['input-disabled']]: disabled,
    [styles['input-filled']]: state === 'filled',
  })

  return (
    <InputContainer width={width} orientation={inputContainerOrientation} className={inputContainerClasses}>
      {labelText && <Label {...labelProps} htmlFor={id} labelText={labelText} />}
      <div
        onMouseLeave={() => handleShowError('')}
        onMouseEnter={() => handleShowError(String(id))}
        className={withRightBtnContainerClasses}>
        {state !== 'active' && renderSelectedItems && <div className={styles['selected-items__container']}>{renderSelectedItems()}</div>}
        <Input
          ref={inputProps?.ref}
          readonly={readonly}
          id={id}
          name={id}
          withRightButton={withRightButton}
          type={type}
          placeholder={placeholder}
          className={inputElementClasses}
          disabled={disabled}
          maxLength={maxLength}
          {...inputProps}
          {...props}
        />
        {withResetButton && inputProps.value && (
          <div className={styles['input-button']}>
            <Button
              btnType={'button'}
              disabled={state === 'disabled'}
              type={ButtonTypes.secondary}
              size={UISizes.medium}
              iconLeft={<i className={'icon icon-close'} />}
              onClick={() => onReset?.()}
            />
          </div>
        )}
        {!!maxLength && inputProps?.value?.length && (
          <div className={styles['max-length-container']}>
            <Typography as={'caption'} decoration={'none'} tag={6}>
              {inputProps?.value?.length}
            </Typography>
            /
            <Typography as={'caption'} decoration={'none'} tag={6}>
              {maxLength}
            </Typography>
          </div>
        )}
        {withRightButton && renderRightButton && renderRightButton()}
        {renderDropdownMenu?.()}
      </div>
    </InputContainer>
  )
}
