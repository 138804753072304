import { required } from "@/components/form/utils/validators";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { useRequestsSendSMSMessageToClientStoreMutation } from "@/state/api";
import { ModalBody, ModalHeader } from "@common/modal-container";
import { ModalContainer } from "@components/common";
import { PushMessageInput } from "@components/modals/push-message/input-message";
import { Field, Form } from "react-final-form";
import { PushMessageModalLeftContent } from "./left-content";
import styles from "./sms-to-client-modal.module.scss";
import type { SmsToClientModalFormTypes, SmsToClientModalProps } from "./sms-to-client-modal.types";

import { addSnack } from "@/eventbus/events/snackbar";
import { SmsToClientNames } from "@constants/sms-to-client";
import { clearPhoneForRequest, convertPhone } from "@utils/convert-phone";
import { useEffect, useState } from "react";

export const SmsToClientModal = ({
	onClose = () => {
		return;
	},
	subtitle = "SMS по номеру",
	onHandleBackButton,
	closeOnBlackoutClick,
	phone,
	fullcodeId,
	requestId,
}: Partial<SmsToClientModalProps>) => {
	const [sendSMSMessageToClient, { isError, isSuccess }] = useRequestsSendSMSMessageToClientStoreMutation();
	const { handleSetRequestFullcodeId } = useFullCodeRequest();

	const [lastSubmittedState, setLastSubmittedState] = useState<{
		message: string;
		phone: string;
	}>({
		message: "",
		phone: String(phone),
	});

	const handleSendSMSMessageToClient = ({ phone, message, request_id }: { phone: string; message: string; request_id: number }) => {
		setLastSubmittedState({ phone, message });
		sendSMSMessageToClient({
			phone: String(phone),
			smsMessageToClientRequestStore: {
				phone: phone,
				text: message,
				request_id,
			},
		});
	};

	const submitForm = (values: SmsToClientModalFormTypes) => {
		if (values.message) {
			handleSendSMSMessageToClient({
				phone: clearPhoneForRequest(String(phone)),
				message: values.message,

				request_id: Number(requestId),
			});
			handleSetRequestFullcodeId(String(fullcodeId));
		}
	};

	useEffect(() => {
		if (isError && lastSubmittedState) {
			addSnack({
				type: "error",
				eventName: "send-sms-error",
				text: `Не удалось отправить SMS по номеру ${convertPhone(String(phone))}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () =>
					handleSendSMSMessageToClient({
						phone: clearPhoneForRequest(lastSubmittedState.phone),
						message: lastSubmittedState.message,
						request_id: Number(requestId),
					}),
			});
		}

		if (isSuccess) {
			onClose?.();
			addSnack({
				type: "default",
				eventName: "send-sms-success",
				text: `SMS клиенту по номеру ${convertPhone(String(phone))} отправлено`,
			});
		}
	}, [isError, isSuccess]);

	return (
		<>
			<Form onSubmit={submitForm}>
				{({ handleSubmit }) => (
					<ModalContainer
						onClose={() => onClose()}
						showBlackout
						closeOnBlackoutClick={closeOnBlackoutClick}
						className={styles["sms-to-client-message-modal"]}
					>
						<ModalHeader
							showLeftContent
							contentLeft={
								<PushMessageModalLeftContent
									showBackButton={false}
									title={`${convertPhone(String(phone))}`}
									subtitle={subtitle}
									onHandleBackButton={onHandleBackButton}
								/>
							}
							showCloseButton
							onClose={() => onClose()}
						/>
						<form onSubmit={handleSubmit}>
							<ModalBody>
								<Field<SmsToClientModalFormTypes["message"]>
									validate={required}
									name={SmsToClientNames.message}
									type={"text"}
									parse={(value) => value.slice(0, 140)}
									showMaxlengthValue
									component={PushMessageInput}
								/>
							</ModalBody>
						</form>
					</ModalContainer>
				)}
			</Form>
		</>
	);
};
