import { AnyObject, BasicInputValues } from '@global-types/utils-type'

export const defaultItemToString =
  <T extends BasicInputValues | AnyObject>(key: string) =>
  (item: T) => {
    return item !== undefined && item !== null
      ? typeof item === 'object' && item[key] !== undefined
        ? item[key]
        : item
      : ''
  }
