import { createMachine } from "xstate";

export const phonerTreyMachine = createMachine(
  {
    id: "phoner trey",
    initial: "idle",
    context: { isHovered: false, isActiveInput: false },
    states: {
      idle: {
        description: "Фонер находится в свернутом состоянии Трей",

        on: {
          click: {
            target: "activated",
            actions: {
              type: "setIsHovered",
              params: {},
            },
            description:
              "При клике Фонер разворачивается и становится активным.",
          },
        },
      },

      activated: {
        description:
          "`Фонер находится в развернутом состоянии.\n\nМожем: \n- Свернуть фонер в Трей при нажатии на элемент Collapse над input.\n- Открыть/Закрыть меню Фонера по кнопке слева от input;\n- Начать быстрый поиск по контактам;\n- Выбрать контакт для звонка из списка.\n- Начать звонок с выбранным контактом при нажатии по кнопке звонка справа от input`",
        after: {
          "3000": [
            {
              target: "#phoner trey.idle",
              cond: "notHovered",
              actions: [],
              description:
                "Если курсор находится вне зоны Фонера больше 3 секунд - фонер сворачивается в Трей",
            },
            {
              internal: false,
            },
          ],
        },
        on: {
          collapse: {
            target: "idle",
            description:
              "При нажатии на элемент collapse над input - фонер сворачивается в Трей не дожидаясь 3 секунд.",
          },
          collapseClick: {
            target: "idle",
            description:
              "При нажатии за пределами компонента фонера  - фонер сворачивается в Трей не дожидаясь 3 секунд.",
          },

          hover: [
            {
              target: "activated",

              actions: {
                type: "setIsHovered",
                params: {},
              },

              description:
                "Пока курсор находится в зоне Фонера - фонер остается в развернутом состоянии.",
              internal: false,
              cond: "notActiveInput",
            },
            {
              target: "activated",
              actions: "setIsHovered",
            },
          ],

          "open menu": {
            target: "menu opened",
          },

          "active input": {
            target: "activated",

            actions: {
              type: "setIsActiveInput",
              params: {},
            },

            description:
              "Пока поле ввода активно фонер остается в развернутом состоянии.",
          },
        },
      },

      "menu opened": {
        on: {
          "close menu": {
            target: "activated",
          },
        },
      },
    },
    schema: {
      events: {} as
        | { type: "hover"; isHovered: boolean }
        | { type: "click"; isHovered: boolean }
        | { type: "collapse" }
        | { type: "collapseClick" }
        | { type: "open menu" }
        | { type: "close menu" }
        | { type: "active input"; isActiveInput: boolean },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    //tsTypes: {},
  },
  {
    actions: {
      setIsHovered: (context, event) => {
        context.isHovered = event.type === "hover" && event.isHovered;
      },
      setIsActiveInput: (context, event) => {
        context.isActiveInput =
          event.type === "active input" && event.isActiveInput;
      },
    },
    services: {},
    guards: {
      notHovered: (context) => {
        return !context.isHovered && !context.isActiveInput;
      },
      notActiveInput: (context) => {
        return !context.isActiveInput;
      },
    },
    delays: {},
  }
);
