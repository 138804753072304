import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import {
  useRequestsPresetsContractTypesQuery,
  WorkFeedResource,
} from '@/state/api'
import { CreateRequestNames } from '@constants/create-request'
import { MultiSelect } from '@common/multi-select'
import { prepareSelectPresets } from '@utils/prepeare-select-presets'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'

export const RequestModalFinanceContract = <T,>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  enableSearch,
  ...props
}: RequestModalTextInputProps<T>) => {
  const [initialValue, setInitialValue] = useState<
    WorkFeedResource[] | undefined
  >(undefined)

  const {
    data: serverData,
    isLoading,
    isError,
  } = useRequestsPresetsContractTypesQuery()

  const field = useField(input?.name)?.input?.value

  useEffect(() => {
    if (!isLoading && serverData?.data) {
      setInitialValue(
        findItem<WorkFeedResource>({
          id: field,
          arr: serverData?.data as WorkFeedResource[],
        }),
      )
    }
  }, [field, isLoading])

  return (
    <>
      <MultiSelect<WorkFeedResource>
        enableSearch={enableSearch}
        isDataLoading={isLoading}
        width={'full-w'}
        isDataNested={false}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={(serverData?.data as WorkFeedResource[]) || []}
        inputId={CreateRequestNames.typeId}
        onChange={(items) => {
          items?.length
            ? input.onChange(items[0].id)
            : input.onChange(undefined)
        }}
        initialSelectedItems={initialValue}
        externalInputProps={input}
        externalInputMetaProps={meta}
      />
    </>
  )
}
