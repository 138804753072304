import { ReactNode } from 'react'
import styles from './action-buttons.module.scss'

interface ActionButtonsProps {
    leftSlot: ReactNode
    rightSlot: ReactNode
}
export const ActionButtons = ({
    leftSlot = <></>,
    rightSlot = <></>,
}: ActionButtonsProps) => {
  return (
      <div className={styles['action-buttons']}>
           { leftSlot }
           { rightSlot }   
      </div>
  )
}

