import type { SnackbarProps } from './snackbar.types'

import React from 'react'

import styles from './snackbar.module.scss'
import cn from 'classnames'

export const Snackbar = ({
  type,
  isMobile,
  withAction,
  children,
}: SnackbarProps) => {
  return (
    <div
      className={cn(
        styles['snackbar-container'],
        {
          [styles['snackbar-default']]: type === 'default',
          [styles['snackbar-loading']]: type === 'loading',
          [styles['snackbar-error']]: type === 'error',
          [styles['snackbar-mobile']]: isMobile,
        },
        withAction && styles['snackbar_with-action'],
      )}
    >
      <div className={styles['snackbar-content-container']}>{children}</div>
    </div>
  )
}
