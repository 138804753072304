import { containsNumber } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/filter-period";
import { MONTH_MAX_INDEX } from "@constants/date";
import { format, isValid, parse } from "date-fns";
import { getDate } from "@/utils/get-date";
import { compareDateWithCurrent } from "@components/modals/okk-comment-modal/okk-steps/okk-common";

export const validateDateForCreateEditRequest = (value: string) => {
	let isInvalid = false;
	let date = new Date();
	let inputDateValue = value;

	if (!inputDateValue || !containsNumber(inputDateValue)) {
		return { inputDateValue, isInvalid, date };
	}

	const parsedDateParts = inputDateValue.split(".").flatMap((chunk, index, arr) => {
		return index === arr.length - 1 ? chunk.split(" ") : chunk;
	});

	const [day, month, year, time] = parsedDateParts;
	const daysInSelectedMonth = new Date(Number(year), Number(month), 0).getDate();

	if (Number(day) > daysInSelectedMonth || Number(month) > MONTH_MAX_INDEX) {
		const validatedMonth = Number(month) > MONTH_MAX_INDEX ? MONTH_MAX_INDEX : month;
		const validatedDay =
			Number(month) > MONTH_MAX_INDEX ? new Date(Number(year), Number(MONTH_MAX_INDEX), 0).getDate() : daysInSelectedMonth;
		const ISOFormattedDateString = `${year}-${validatedMonth}-${validatedDay}T${time?.includes("_") ? "12:00" : time}:00`;
		const isoFormattedDate = new Date(ISOFormattedDateString);
		inputDateValue = format(isoFormattedDate, "dd.MM.yyyy HH:mm");
		date = isoFormattedDate;
	}

	const parsedDate = parse(inputDateValue, "dd.MM.yyyy HH:mm", new Date());
	const currentDate = new Date(parse(getDate(), "dd.MM.yyyy HH:mm", new Date()));

	if (!isValid(parsedDate)) {
		isInvalid = true;
		return { inputDateValue, isInvalid, date };
	}

	isInvalid = false;

	if (compareDateWithCurrent(parsedDate, currentDate) > -1) {
		date = parsedDate;
	} else {
		inputDateValue = format(currentDate, "dd.MM.yyyy HH:mm");
	}

	return { inputDateValue, isInvalid, date };
};
