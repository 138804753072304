import { MicrophoneIconButton } from '../../../../shared/components'

type ToggleMicrophoneButtonProps = {
  isOnHold: boolean
  isMicMuted: boolean
  handleToggleMicrophone: () => void
}

export const ToggleMicrophoneButton = ({
  isMicMuted,
  isOnHold,
  handleToggleMicrophone,
}: ToggleMicrophoneButtonProps) => {
  return (
    <MicrophoneIconButton
      isDisabled={isOnHold}
      isMuted={isMicMuted}
      handleMicrophone={handleToggleMicrophone}
    />
  )
}
