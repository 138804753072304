import { useQuery } from "react-query";
export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export type CallDirection = "OUT" | "IN";

export interface TCallLog {
  id: number;
  call_date: string;
  call_time: string;
  duration: number | null;
  direction: CallDirection;
  filename: string;
  caller: CallPerson;
  called: CallPerson;
}

export interface CallPerson {
  type: UserCallType;
  phone: string;
}

export type UserCallType = "client" | "user";

export interface Links {
  first: string;
  last: string;
  prev: null;
  next: string;
}

export interface Meta {
  current_page: number;
  from: number;
  last_page: number;
  links: Link[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface RecordsHistoryResponse {
  data?: TCallLog[];
  links?: Links;
  meta?: Meta;
}

type TUseRecordsHistoryQuery = {
  currentPage: number;
  searchNumber?: string;
};

export const useRecordsHistory = (query: TUseRecordsHistoryQuery) => {
  const results = document?.cookie?.match(/session_id=(.+?)(;|$)/);
  const cookie = results?.[1];

  const queryUrl = `&page=${query.currentPage}&per_page=15${
    query.searchNumber ? `&search=${query.searchNumber}` : ""
  }`;

  const getRecordsHistoryFirst = async (signal?: AbortSignal) => {
    const response = await fetch(
      `${
        import.meta.env.REACT_APP_PHONER_BACK
      }/api/v1/profile/records/history?sort_order=desc&sort_by=call_date${queryUrl}`,
      {
        headers: { Authorization: `Bearer ${cookie}` },
        signal: signal,
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result = await response.json();
    return result;
  };
  const queryRes = useQuery<RecordsHistoryResponse, Error>({
    queryFn: ({ signal }) => getRecordsHistoryFirst(signal),
    queryKey: ["records", query],
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  return { ...queryRes };
};
