export const RequestInfoBlock = {
  status: 'Статус: ',
  requestCreated: 'Заявку завёл:',
  creationDate: 'Дата создания:',
  typeOfWork: 'Вид работ:',
  object: 'Объект:',
  requestStatus: 'Статус заявки:',
  price: 'Стоимость:',
  organization: 'Организация:',
  contactPerson: 'Контактное лицо:',
  phones: 'Телефоны:',
  address: 'Адрес:',
  metroStation: 'Метро:',
  advertising: 'Реклама:',
  hasGuarantee: 'Гарантия:',
  dublicateFrom: 'Дубли от:',
  dublicateBefore: 'Дубли до:',
  master: 'Мастер:',
  callScheduled: 'Звонок запланирован:',
  visitScheduled: 'Визит запланирован:',
  client: 'Клиент:',
  responsible: 'Ответственный:',
  pointDirection: 'Hаправление:',
  inn: 'ИНН:',
} as const
