import { type WorkFeedResource, useOfficesFeedQuery } from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import { MultiSelect } from "@common/multi-select";
import type { RequestModalTextInputProps } from "@components/modals/common-forms/request-modal-input/request-modal-text-input.types";
import { CreateRequestNames } from "@constants/create-request";
import { findItem } from "@utils/find-item";
import { useEffect, useState } from "react";
import { useField } from "react-final-form";

export const RequestModalLocationInput = <T,>({ input, meta, label, enableSearch }: RequestModalTextInputProps<T>) => {
	const field = useField(input?.name)?.input?.value;
	const [initialValue, setInitialValue] = useState<WorkFeedResource[] | undefined>(field);
	const { data: serverData, isLoading } = useOfficesFeedQuery();
	const profile = useUserProfileInfo();

	useEffect(() => {
		if (!isLoading && serverData?.data && (field === 0 || field)) {
			const items = findItem<WorkFeedResource>({
				id: field && field !== 0 ? field : profile?.location_id,
				arr: serverData?.data as WorkFeedResource[],
			});

			setInitialValue(items);
			input.onChange(items[0]?.id ?? undefined);
		}
	}, [field, isLoading]);

	return (
		<>
			<MultiSelect<WorkFeedResource>
				enableSearch={enableSearch}
				width={"full-w"}
				isDataNested={false}
				inputLabelText={`${label}: `}
				dropdownMenuSelectionVariant={"single-select"}
				data={(serverData?.data as WorkFeedResource[]) || []}
				inputId={CreateRequestNames.location_id}
				onChange={(items) => (items?.length ? input.onChange(items[0].id) : input.onChange(undefined))}
				initialSelectedItems={initialValue}
				externalInputProps={input}
				externalInputMetaProps={meta}
				isDataLoading={isLoading}
			/>
		</>
	);
};
