import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table'
import { useEffect, useReducer, useState } from 'react'

import { TableProps } from './masters-table.types'
import { TableBody } from '@common/table-container/table-body'
import { TableHeader } from '@common/table-container/table-header'
import { TableRow } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableCol, TableColItem } from '@components/common'
import { TableRowItem } from '@common/table-container/table-row/table-row-item'
import { ContentLoader } from '@/components'
import { useField } from 'react-final-form'

export const TableMastersSelection = ({
  data = [],
  columns,
  isLoading,
  modalView,
}: TableProps) => {
  const rerender = useReducer(() => ({}), {})[1]
  const commonSelectedMasters = useField('commonSelectedMasters').input.value
  const selectedMasters = useField('selectedMasters').input.value

  const [tableData, setTableData] = useState(() => [...selectedMasters])
  const refreshData = () => setTableData(() => [...data])

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: commonSelectedMasters.length || 999,
  })

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      pagination,
    },

    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  })

  useEffect(() => {
    setTableData(() => [
      ...new Map([...commonSelectedMasters].map((v) => [v.id, v])).values(),
    ])
  }, [commonSelectedMasters, modalView])
  return (
    <>
      {isLoading ? (
        <ContentLoader />
      ) : (
        <TableContainer>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCol key={header.id} style={{ maxWidth: '100%' }}>
                      {header.isPlaceholder ? null : (
                        <TableColItem>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </TableColItem>
                      )}
                    </TableCol>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {!tableData.length ? (
              <TableRow>
                <TableRowItem>Мастер еще не назначен</TableRowItem>
              </TableRow>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableRowItem
                            style={{ maxWidth: '100%' }}
                            key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableRowItem>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </>
            )}
          </TableBody>
        </TableContainer>
      )}
    </>
  )
}
