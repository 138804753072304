import { Button, Icon } from "../../../index";
import type React from "react";

type CallButtonProps = {
	handleCallToPhone: () => void;
	isCallButtonDisabled: boolean;
};
export const CallIconButton = ({ handleCallToPhone, isCallButtonDisabled }: CallButtonProps) => {
	return (
		<Button
			disabled={isCallButtonDisabled}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				handleCallToPhone();
			}}
			size="m"
			variant="primary"
			width="icon"
			renderIcon={<Icon icon={"icon-phone"} />}
		/>
	);
};
