import { ITag } from "./tag.types"
import styles from './tag.module.scss';
import classNames from "classnames";

const Tag = ({children, type, className}: ITag) => {
  return (
    <div className={classNames(styles.tag, styles[`tag-${type}`], className)}>
      {children}
    </div>
  )
}

export default Tag
