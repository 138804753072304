import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import {
  MetroStationFeedResource,
  useMetroStationsFeedQuery,
} from '@/state/api'
import { addSnack } from '@/eventbus/events/snackbar'
import { CreateRequestNames } from '@constants/create-request'
import { MultiSelect } from '@common/multi-select'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'

export const RequestModalMetroStationInput = <
  T extends MetroStationFeedResource[],
>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  ...props
}: RequestModalTextInputProps<T>) => {
  const [initialValue, setInitialValue] = useState<
    MetroStationFeedResource[] | undefined
  >(undefined)

  const {
    data: serverData,
    isLoading,
    isError,
    error,
    refetch,
  } = useMetroStationsFeedQuery()

  const handleRefetchMetroStations = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-metro-error',
        text: `Не удалось загрузить станции метро.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchMetroStations(),
      })
    }
  }, [isError])

  const metroId = useField(input?.name)?.input?.value

  useEffect(() => {
    if (metroId && !isLoading && serverData?.data) {
      setInitialValue(
        findItem<MetroStationFeedResource>({
          id: metroId,
          arr: serverData?.data,
        }),
      )
    }
  }, [metroId, isLoading])

  return (
    <>
      <MultiSelect<MetroStationFeedResource>
        isDataLoading={isLoading}
        width={'full-w'}
        isDataNested={true}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={serverData?.data || []}
        inputId={CreateRequestNames.metroStation}
        onChange={(items) =>
          items?.length
            ? input.onChange(items[0].id)
            : input.onChange(undefined)
        }
        externalInputProps={input}
        externalInputMetaProps={meta}
        initialSelectedItems={initialValue}
      />
    </>
  )
}
