import { Events } from './form-factory-machine'

type FormMachine = {
  state: any
  send: any
}
export const useFormMachine = ({ state, send }: FormMachine) => {
  function getError(field: string) {
    const error = state.context.dataEntryErrors[field]

    return error && (error.message || ``)
  }

  function sendEvent(config: { field: string; type: Events; event: any }) {
    const { type, event, field } = config
    send({
      type,
      data: {
        value: event,
        field,
      },
    })
  }

  function onBlur(event: any, field: string) {
    sendEvent({
      type: Events.BLUR_DATA,
      event,
      field,
    })
  }

  function onChange(event: any, field: string) {
    sendEvent({
      type: Events.ENTER_DATA,
      event,
      field,
    })
  }

  function resetField(event: any, field: string) {
    sendEvent({
      type: Events.RESET,
      event,
      field,
    })
  }

  return {
    sendEvent,
    resetField,
    onBlur,
    onChange,
    getError,
  }
}
