import {
  useRequestsCommentsDestroyMutation,
  useRequestsCommentsIndexQuery,
} from '@/state/api'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AlertModal } from '../alert-modal'

export const DeleteComment = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = location.pathname.split('/').filter(Number)
  const {
    data: comments,
    isLoading: commentsLoading,
    isError,
  } = useRequestsCommentsIndexQuery({
    requestId: Number(params[0]),
  })

  const [deleteComment, { isLoading }] = useRequestsCommentsDestroyMutation()
  const onClick = async () =>
    params &&
    params[0] &&
    params[1] &&
    (await deleteComment({
      requestId: Number(params[0]),
      commentId: Number(params[1]),
    })
      .unwrap()
      .then(() => navigate(-1))
      .catch((e) => navigate(-1)))

  useEffect(() => {
    if (isError) navigate('../../')
    if (comments) {
      const foundedComment =
        Array.isArray(comments?.data) &&
        comments?.data?.length &&
        comments?.data
          ?.map(
            (comment) =>
              Array.isArray(comment.comments) &&
              comment.comments.length &&
              comment.comments.filter(
                (comment) => comment.id === Number(params[1]),
              ),
          )
          .flat()[0]
      //Найденный комент который совпадает с айди из парамсов
      //Если не нашли коммента (пользователь ввел в ручную рандомный редиректим)
      if (!foundedComment || !foundedComment?.abilities?.delete)
        navigate('../../')
    }
  }, [comments, isError])

  return (
    <AlertModal
      conentLoading={commentsLoading}
      onClick={onClick}
      isLoading={isLoading}
      title={'Вы хотите удалить выбранный комментарий?'}
      showBackButton={false}
    />
  )
}
