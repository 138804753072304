import type { RTCSession } from "jssip/lib/RTCSession";
import { type SessionDTO, SessionStatus, CallDirection } from "../../../contexts/tab-session-context/tab-session-context";

export const getTimerPlaceholder = (session: RTCSession | null): string => {
	if (!session) return "";

	const isIncoming = session.direction === "incoming";
	const isConfirmed = session.status === 9;

	const isInProgress = session.isInProgress();
	const isOnHold = session.isOnHold().local;

	if (isOnHold) return "Удержание...";

	if (isIncoming) {
		return isConfirmed ? "" : "Соединение...";
	}

	return isInProgress ? "Вызов..." : "";
};

export const getTimerPlaceholderByStatus = (session?: SessionDTO) => {
	const sessionStatus = session?.status;
	let timerPlaceholder = "";

	switch (sessionStatus) {
		case SessionStatus.HOLD:
			timerPlaceholder = "Удержание...";
			break;
		case SessionStatus.ACCEPTED:
			timerPlaceholder = "Соединение...";
			break;
		case SessionStatus.CONNECTING:
			timerPlaceholder = session?.direction === CallDirection.INCOMING ? "Соединение..." : "Вызов...";
			break;
		case SessionStatus.STARTED_CONVERSATION:
			timerPlaceholder = "";
			break;
		default:
			timerPlaceholder = "";
	}

	return timerPlaceholder;
};
