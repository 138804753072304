import { useActor } from "@xstate/react";
import { motion } from "framer-motion";
import { Fragment, useEffect } from "react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { Phoner } from "../../../entities";
import { SecondLine } from "../../../entities/second-line";
import { ConnectTwoLines } from "../../../features/second-line/connect-two-lines";
import { DeclineSecondLineCall } from "../../../features/second-line/decline";
import { ToggleCallOnHold } from "../../../features/second-line/toggle-call-on-hold";
import { ToggleMicrophoneButton } from "../../../features/second-line/toggle-mic";
import { CurrentCallPhone } from "../../../shared/components/current-call-phone";
import { Timer } from "../../../shared/components/timer/ui/timer";
import { CauseReason, usePhoner } from "../../../shared/contexts/phoner/phoner";
import { Icon } from "../../../shared/index";
import { convertPhone } from "../../../shared/utils/convert-phone";
import styles from "./second-line.module.scss";
import { getTimerPlaceholderByStatus } from "../../../shared/components/timer/utils/getTimerPlaceholder";
import { SessionStatus, useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { useSharedWorker } from "../../../shared/contexts/shared-worker";
import {
	DECLINE_SECOND_LINE_CALL,
	SET_MUTE_MICROPHONE,
	SET_SESSION_HOLD,
	SET_SESSION_UNHOLD,
	SET_UNMUTE_MICROPHONE,
	START_CONNECTING_TWO_LINES,
	TRANSFER_TO_AUTOINFORMER,
	SUCCESS_TWO_LINES_CONNECTION,
	TOGGLE_SESSION_HOLD_STATE,
} from "../../../shared/event-bus/types/event-action.types";
import { useEventBus } from "../../../shared/contexts/event-bus/event-bus";
import { ReferSecondLineToAutoInformer } from "../../../features/second-line/refer-second-line-to-autoinformer";

export const SecondLineWidget = () => {
	const { endCall, sessions } = usePhoner();
	const { phonerSecondLineService } = useGlobalPhonerState();

	const [statePhonerSecondLine, sendPhonerSecondLine] = useActor(phonerSecondLineService);
	const { setSessionsDTO, sessionsDTO } = useSessionContext();
	const { toggleActiveSession, setSessions, muteMicro, unmuteMicro, referToAutoInformer } = usePhoner();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { sendMessage } = useSharedWorker();
	const startConnectingTwoLines = statePhonerSecondLine.matches("connecting two lines");
	const { on } = useEventBus();
	const twoLineConnected = statePhonerSecondLine.matches("two lines connected");

	const topSlotCurrentLineVariants = {
		initial: {
			opacity: 1,
		},
		animate: {
			opacity: 0.5,
		},
	};

	useEffect(() => {
		const unsubscribeMuteMicrophone = on(SET_MUTE_MICROPHONE, ({ sessionId }) => {
			if (isCurrentTabMasterTab) {
				muteMicro(sessionId);
				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: true } : session)),
				);

				return;
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: true } : session)),
			);
		});

		const unsubscribeUnMuteMicrophone = on(SET_UNMUTE_MICROPHONE, ({ sessionId }) => {
			if (isCurrentTabMasterTab) {
				unmuteMicro(sessionId);

				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: false } : session)),
				);
				return;
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) => (session.sessionId === sessionId ? { ...session, isMuted: false } : session)),
			);
		});

		return () => {
			unsubscribeMuteMicrophone();
			unsubscribeUnMuteMicrophone();
		};
	}, [isCurrentTabMasterTab, sessionsDTO]);

	const handleToggleMicrophone = (activeSessionId: string) => {
		const isCurrentSessionMicrophoneMuted = sessionsDTO.find((session) => session.sessionId === activeSessionId)?.isMuted || false;

		if (isCurrentTabMasterTab) {
			if (isCurrentSessionMicrophoneMuted) {
				unmuteMicro(activeSessionId);
				sendMessage({ eventName: SET_UNMUTE_MICROPHONE, eventPayload: { sessionId: activeSessionId } });

				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === activeSessionId ? { ...session, isMuted: false } : session)),
				);
			} else {
				muteMicro(activeSessionId);
				sendMessage({ eventName: SET_MUTE_MICROPHONE, eventPayload: { sessionId: activeSessionId } });
				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === activeSessionId ? { ...session, isMuted: true } : session)),
				);
			}
		} else {
			if (isCurrentSessionMicrophoneMuted) {
				sendMessage({ eventName: SET_UNMUTE_MICROPHONE, eventPayload: { sessionId: activeSessionId } });
				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === activeSessionId ? { ...session, isMuted: false } : session)),
				);
			} else {
				sendMessage({ eventName: SET_MUTE_MICROPHONE, eventPayload: { sessionId: activeSessionId } });
				setSessionsDTO((sessions) =>
					sessions.map((session) => (session.sessionId === activeSessionId ? { ...session, isMuted: true } : session)),
				);
			}
		}
	};

	useEffect(() => {
		const unsubscribeSetSessiOnOnHold = on(SET_SESSION_HOLD, ({ sessionId: clickedOnSessionId }) => {
			if (isCurrentTabMasterTab) {
				toggleActiveSession(clickedOnSessionId);
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId === clickedOnSessionId ? { ...session, status: SessionStatus.HOLD, isMuted: true } : session,
				),
			);
		});

		const unsubscribeSetSessiOnOnUnHold = on(SET_SESSION_UNHOLD, ({ sessionId: clickedOnSessionId }) => {
			if (isCurrentTabMasterTab) {
				toggleActiveSession(clickedOnSessionId);
			}

			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId === clickedOnSessionId
						? { ...session, status: SessionStatus.STARTED_CONVERSATION, isMuted: false }
						: session,
				),
			);
		});

		const unsubscribeToggleSessionHoldState = on(TOGGLE_SESSION_HOLD_STATE, ({ sessionId: clickedOnSessionId }) => {
			if (isCurrentTabMasterTab) {
				toggleActiveSession(clickedOnSessionId);
			}

			const sessionClickedOn = sessionsDTO.find((session) => session.sessionId === clickedOnSessionId);
			const session = sessionsDTO.find((session) => session.sessionId !== clickedOnSessionId);

			if (sessionClickedOn && session) {
				if (sessionClickedOn.status === SessionStatus.HOLD) {
					setSessionsDTO((sessions) =>
						sessions.map((session) =>
							session.sessionId === clickedOnSessionId
								? { ...session, status: SessionStatus.STARTED_CONVERSATION, isMuted: false }
								: { ...session, status: SessionStatus.HOLD, isMuted: true },
						),
					);
					isCurrentTabMasterTab && unmuteMicro(sessionClickedOn.sessionId || "");
				} else {
					setSessionsDTO((sessions) =>
						sessions.map((session) =>
							session.sessionId === clickedOnSessionId
								? { ...session, status: SessionStatus.HOLD, isMuted: true }
								: { ...session, status: SessionStatus.STARTED_CONVERSATION, isMuted: false },
						),
					);
				}
			}
		});

		return () => {
			unsubscribeSetSessiOnOnHold();
			unsubscribeSetSessiOnOnUnHold();
			unsubscribeToggleSessionHoldState();
		};
	}, [isCurrentTabMasterTab, sessionsDTO]);

	const handleToggleCall = (idSession: string) => {
		if (isCurrentTabMasterTab) {
			toggleActiveSession(idSession);
		}

		const sessionClickedOn = sessionsDTO.find((session) => session.sessionId === idSession);
		const session = sessionsDTO.find((session) => session.sessionId !== idSession);

		if (sessionClickedOn && session) {
			if (sessionClickedOn.status === SessionStatus.HOLD) {
				setSessionsDTO((sessions) =>
					sessions.map((session) =>
						session.sessionId === sessionClickedOn.sessionId
							? { ...session, status: SessionStatus.STARTED_CONVERSATION, isMuted: false }
							: { ...session, status: SessionStatus.HOLD, isMuted: true },
					),
				);
				isCurrentTabMasterTab && unmuteMicro(sessionClickedOn.sessionId || "");
				sendMessage({ eventName: TOGGLE_SESSION_HOLD_STATE, eventPayload: { sessionId: idSession } });
			} else {
				setSessionsDTO((sessions) =>
					sessions.map((session) =>
						session.sessionId === sessionClickedOn.sessionId
							? { ...session, status: SessionStatus.HOLD, isMuted: true }
							: { ...session, status: SessionStatus.STARTED_CONVERSATION, isMuted: false },
					),
				);
				sendMessage({ eventName: TOGGLE_SESSION_HOLD_STATE, eventPayload: { sessionId: idSession } });
			}
		}
	};

	useEffect(() => {
		const unsubscribeDeclineCurrentCall = on(DECLINE_SECOND_LINE_CALL, ({ sessionId: clickedOnSessionId }) => {
			endCall(clickedOnSessionId, false);
		});

		return () => unsubscribeDeclineCurrentCall();
	}, [isCurrentTabMasterTab, sessions, sessionsDTO]);

	useEffect(() => {
		const unsubscribeStartConnectingTwoLines = on(START_CONNECTING_TWO_LINES, () => {
			if (isCurrentTabMasterTab) {
				const [firstSession, secondSession] = sessions;

				try {
					firstSession &&
						secondSession &&
						firstSession.refer(secondSession.remote_identity.uri, {
							replaces: secondSession,
							eventHandlers: {
								accepted: () => {
									firstSession.data = {
										...firstSession.data,
										successTwoLinesConnection: true,
										customCause: CauseReason.LOCAL_TERMINATE,
									};

									secondSession.data = {
										...secondSession.data,
										successTwoLinesConnection: true,
										customCause: CauseReason.LOCAL_TERMINATE,
									};

									firstSession.terminate();
									secondSession.terminate();
									sendPhonerSecondLine("success");
									setSessions([]);
									setSessionsDTO([]);

									sendMessage({
										eventName: SUCCESS_TWO_LINES_CONNECTION,
										eventPayload: { sessionIds: sessionsDTO.map((session) => session.sessionId || "") },
									});
								},
								failed: (e: any) => {
									console.info("two lines failed", "error", e);
								},
								process: () => {
									console.info("two lines process");
								},
								requestFailed: () => {
									console.info("requestFailed");
								},
								requestSucceeded: () => {
									console.info("requestSucceeded");
								},
							},
						});
					sendPhonerSecondLine("start connecting two lines");
				} catch (e) {
					console.info("e", e);
				}
			} else {
				sendPhonerSecondLine("start connecting two lines");
			}
		});

		const unsubscribeSuccessTwoLinesConnection = on(SUCCESS_TWO_LINES_CONNECTION, () => {
			sendPhonerSecondLine("success");
			setSessionsDTO([]);
		});

		return () => {
			unsubscribeStartConnectingTwoLines();
			unsubscribeSuccessTwoLinesConnection();
		};
	}, [isCurrentTabMasterTab, sessions]);

	const handleDeclineCall = (sessionId: string) => {
		endCall(sessionId);
	};

	const referCallToAutoInformer = (sessionId: string) => {
		referToAutoInformer(sessionId);
	};

	useEffect(() => {
		const unsubscribeStartTransferToAutoInformer = on(TRANSFER_TO_AUTOINFORMER, ({ sessionId: clickedOnSessionId }) => {
			referToAutoInformer(clickedOnSessionId, false);
		});

		return () => unsubscribeStartTransferToAutoInformer();
	}, [sessionsDTO]);

	return (
		<SecondLine
			topSlot={
				<>
					{sessionsDTO.map((session) => {
						const callDurationInSeconds = session.startedAt ? Math.round((Date.now() - session.startedAt) / 1000) : 0;

						return (
							<Fragment key={session.sessionId}>
								{!twoLineConnected && (
									<motion.div
										key="top-slot-motion-wrapper"
										variants={topSlotCurrentLineVariants}
										animate={startConnectingTwoLines ? "animate" : "initial"}
										style={{ pointerEvents: startConnectingTwoLines ? "none" : "auto" }}
									>
										<Phoner
											className={
												session.status === SessionStatus.HOLD
													? styles["phoner__container--on-hold"]
													: styles["phoner__container--current"]
											}
											leftSlot={
												<ToggleMicrophoneButton
													isOnHold={!!(session.status === SessionStatus.HOLD || !session.isConnected)}
													isMicMuted={session.isMuted || session.status === SessionStatus.HOLD}
													handleToggleMicrophone={() => handleToggleMicrophone(session.sessionId || "")}
												/>
											}
											middleSlot={
												<CurrentCallPhone
													number={session.phoneNumber ? convertPhone(session.phoneNumber) : "Неизвестный номер"}
													timer={
														<Timer
															isStarted={session.status === SessionStatus.STARTED_CONVERSATION}
															isPaused={session.status === SessionStatus.HOLD}
															startFrom={callDurationInSeconds}
															placeholder={getTimerPlaceholderByStatus(session)}
														/>
													}
												/>
											}
											rightSlot={
												<div className={styles["right-slot-btn-wrapper"]}>
													<ToggleCallOnHold
														isCallOnHold={session.status === SessionStatus.HOLD}
														handleToggleCall={() => handleToggleCall(session.sessionId || "")}
														currentSessionId={session.sessionId || ""}
													/>
													<div className={styles["call-control-wrapper"]}>
														<ReferSecondLineToAutoInformer
															isDisabled={session.status === SessionStatus.HOLD || !session.isConnected}
															referCallToAutoInformer={() => referCallToAutoInformer(session.sessionId || "")}
														/>
														<DeclineSecondLineCall
															handleDeclineCall={() => handleDeclineCall(session.sessionId || "")}
															isDisabled={session.status === SessionStatus.HOLD}
														/>
													</div>
												</div>
											}
										/>
									</motion.div>
								)}
							</Fragment>
						);
					})}
				</>
			}
			bottomSlot={
				<>
					{sessionsDTO.every((session) => session.isConnected) && (
						<motion.div key="connect-two-lines-btn">
							<div className={styles["connected-button-wrapper"]}>
								{!startConnectingTwoLines ? (
									<ConnectTwoLines />
								) : (
									<div className={styles["text-connection-wrapper"]}>
										<div className={styles["loading-icon-container"]}>
											<Icon icon={"icon-loading-spinner"} className={styles["loading-icon"]} />
										</div>
										<p className={styles.text}>Соединение...</p>
									</div>
								)}
							</div>
						</motion.div>
					)}
				</>
			}
		/>
	);
};
