import { CloseButtonProps } from './close-button.types'

import { Button } from '../button'

import classname from 'classnames'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import React from 'react'

export const CloseButton = ({
  btnType,
  onClick = () => {},
  className = '',
  iconClassName = '',
  size,
}: CloseButtonProps) => {
  return (
    <div onClick={onClick} className={className}>
      <Button
        btnType={'button'}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        iconLeft={<i className={'icon icon-close'} />}
        //onClick={onClose}
      />
    </div>
  )
}
