import { useEffect, useState, useMemo } from "react";
import Downshift from "downshift";
import classNames from "classnames";
import { coreApi } from "@/state/api";
import { useForm } from "react-final-form";
import { debounce } from "lodash";
import { Label } from "../multi-select/common/label";
import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { UISizes } from "@/global-types/mods.types";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import styles from "./organisations-input.module.scss";
import { InputErrorContainer } from "../input-error-container";

export const OrganisationsInput = ({
	className,
	name,
	label,
	error,
	disabled,
	placeholder = "",
	keyForInput,
	startLength = 3,
	dropdownClassname,
	input,
	meta,
}: any) => {
	const [inputValue, setInputValue] = useState("");
	const [isTouched, setIsTouched] = useState(false);
	const [fetchOrganisationsFeed, { data, isLoading }] = coreApi.endpoints.companiesFeed.useLazyQuery();
	const [hoveredTooltip, setHoveredTooltip] = useState<string>("");

	const handleSetHoveredTooltip = (val: string) => {
		setHoveredTooltip(val);
	};

	useEffect(() => {
		if (input?.value) {
			setInputValue(input.value);
		} else {
			setInputValue("");
		}
	}, [input?.value]);

	const debouncedFetch = useMemo(
		() =>
			debounce(
				(query: string) =>
					fetchOrganisationsFeed({
						title: query,
					}),
				700,
			),
		[fetchOrganisationsFeed],
	);

	useEffect(() => {
		if (inputValue && inputValue.length >= startLength && isTouched) {
			debouncedFetch(inputValue);
		}
	}, [inputValue]);

	const form = useForm();

	const handleSelection = (selection: any) => {
		input?.onChange?.(selection?.title);
		form.change("urId", selection?.id);
		form.change("ur_inn", selection.inn);
	};

	return (
		<InputErrorContainer anchorId={hoveredTooltip} errorMessage={meta.error}>
			<Downshift
				onInputValueChange={(e) => {
					setInputValue(e);
					input?.onChange?.(e);

					if (!e) {
						input?.onChange?.("");
						form.change("urId", undefined);
					}
				}}
				inputValue={inputValue}
				onSelect={(selection) => handleSelection(selection)}
				itemToString={(item) => (item?.data && keyForInput ? item.data[keyForInput] : item)}
				stateReducer={(_, changes) => {
					if (changes.type === Downshift.stateChangeTypes.blurInput) {
						return {
							...changes,
							inputValue: inputValue,
							isOpen: false,
						};
					}
					if (changes.type === Downshift.stateChangeTypes.mouseUp) {
						return {
							...changes,
							inputValue: inputValue,
							isOpen: false,
						};
					}
					return changes;
				}}
			>
				{({ getInputProps, getItemProps, getMenuProps, highlightedIndex, getRootProps, isOpen, selectHighlightedItem }) => (
					<div className={classNames(styles["address-container"], className)}>
						<div {...getRootProps({}, { suppressRefError: true })}>
							<div className={styles["address-input-container"]}>
								{label && <Label htmlFor={name || input?.name} labelText={label} />}
								<div className={styles["address-input-with-button"]}>
									<input
										onMouseEnter={() => handleSetHoveredTooltip("1")}
										onMouseLeave={() => handleSetHoveredTooltip("")}
										{...getInputProps({
											autoComplete: "nope",
											placeholder: placeholder,
											onKeyDown: (event) => {
												setIsTouched(true);
												switch (event.key) {
													case "Tab": {
														if (isOpen) {
															event.preventDefault();
															data?.data?.length && highlightedIndex !== null && selectHighlightedItem();
														}
														break;
													}
													default:
														break;
												}
											},
										})}
										name={name || input?.name}
										className={classNames(styles["address-input"], {
											[styles["address-input-filled"]]: !!inputValue,
											[styles["address-input-error"]]:
												(!!meta.submitFailed && !!error) ||
												(!!meta.submitFailed && !!meta.error) ||
												(!!meta.submitFailed && !inputValue),
											// [styles['address-input-error']]: !!error || (!!meta.submitError && !meta.dirty) || meta.error,
										})}
										disabled={disabled}
									/>
									{!!inputValue && (
										<Button
											btnType={"button"}
											className={styles["address-input-remove-button"]}
											type={ButtonTypes.secondaryUncolored}
											size={UISizes.medium}
											iconLeft={<i className={"icon icon-close"} />}
											onClick={() => {
												input?.onChange?.("");
												form.change("urId", undefined);
											}}
										/>
									)}
								</div>
							</div>
						</div>
						{isLoading && isOpen && (
							<div {...getMenuProps()} className={classNames(styles["address-dropdown"], dropdownClassname)}>
								<LoadingSpinner />
							</div>
						)}
						{!isLoading && isOpen && data && data.data && data.data.length > 0 && (
							<div className={styles.wrapper}>
								<div {...getMenuProps()} className={classNames(styles["dropdown-container"], dropdownClassname)}>
									{data.data.map((item, index: number) => (
										<div
											key={item.id}
											className={styles["list-item"]}
											{...getItemProps({
												key: item.id,
												index,
												item: item,
												style: {
													backgroundColor: highlightedIndex === index ? "lightgray" : "white",
													fontWeight: "normal",
												},
											})}
										>
											{/*{itemsFormatter*/}
											{/*  ? itemsFormatter(item.data)*/}
											{/*  : item.unrestricted_value}*/}
											{item.title}
										</div>
									))}
								</div>
							</div>
						)}
						{!isLoading && isOpen && data && data.data && data.data.length === 0 && inputValue && (
							<div className={styles["address__empty-plug"]}>Не найдено совпадений</div>
						)}
					</div>
				)}
			</Downshift>
		</InputErrorContainer>
	);
};
