import { InputMessageEditProps } from '@components/input-message-edit/input-message-edit.types'
import cn from 'classnames'
import styles from './input-message-edit.module.scss'
import { Input } from '@common/multi-select/common'
import { Typography } from '@components/common'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'

export const InputMessageEdit = ({
  state,
  disabled,
  width,
  input,
  meta,
  handleSubmit,
  onClose,
}: InputMessageEditProps) => {
  const inputMessageEditClasses = cn(
    styles['input-msg-edit-btn-container'],
    styles[`width-${width}`],
    {
      [styles['input-default']]: state === 'default',
      [styles['input-active']]: state === 'active',
      [styles['input-error']]: state === 'error',
      [styles['input-disabled']]: disabled,
      [styles['input-filled']]: state === 'filled',
    },
  )

  return (
    <div className={inputMessageEditClasses}>
      <div className={styles.header}>
        <div className={styles['header-title']}>
          <i className={'icon icon-edit-2'} />
          <Typography as={'caption'} decoration={'none'} tag={4} weight={2}>
            Редактирование сообщения
          </Typography>
        </div>
        <Button
          btnType={'button'}
          type={ButtonTypes.secondaryUncolored}
          size={UISizes.medium}
          iconLeft={<i className={'icon icon-close'} />}
          onClick={onClose}
        />
      </div>
      <div className={styles.body}>
        <Input
          type={'text'}
          disabled={disabled}
          {...input}
          {...meta}
        />
        <Button
          btnType={'submit'}
          type={ButtonTypes.secondaryUncolored}
          size={UISizes.medium}
          iconLeft={<i className={'icon icon-check'} />}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
