import { Header } from '@common/header/header'
import { RequestHeaderTop } from '@components/headers/requests-header/request-header-top/request-header-top'
import { RequestHeaderBottom } from '@components/headers/requests-header/request-header-bottom/request-header-bottom'
import { ResetPageOnFilterChange } from '@global-types/reset-page.types'

export const RequestsHeader = ({
  isFetching,
  resetPageOnFilterChange,
}: {
  resetPageOnFilterChange?: (
    resetLocalStoragePage: ResetPageOnFilterChange,
  ) => void
  isFetching?: boolean
}) => {
  return (
    <Header
      topSection={<RequestHeaderTop />}
      bottomSection={
        <RequestHeaderBottom
          resetPageOnFilterChange={resetPageOnFilterChange}
          isFetching={isFetching}
        />
      }
    />
  )
}
