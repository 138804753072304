import { useButtonSwitch } from "@/hooks";
import { ButtonSwitchContainer } from "@common/index";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import type { FilterCheckboxTypes } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-checkbox/filter-checkbox.types";
import { CreateRequestNames } from "@constants/create-request";
import { UISizes } from "@global-types/mods.types";
import { useField } from "react-final-form";

export const AutoSelectionSwitcher = <T extends boolean>({
	showLabel = false,
	disabled,
	label = "",
	activeButton = 0,
	...props
}: FilterCheckboxTypes<T>) => {
	const autoSelectionField = useField(CreateRequestNames.autoSelection).input.value;
	const { toggleActiveButton } = useButtonSwitch(autoSelectionField ? 0 : 1);

	return (
		<ButtonSwitchContainer showLabel={showLabel} label={label}>
			<Button
				disabled={!!disabled}
				isActive={!autoSelectionField}
				btnType={"button"}
				type={ButtonTypes.secondary}
				size={UISizes.medium}
				onClick={() => {
					toggleActiveButton();
					props.input.onChange(false);
				}}
			>
				Выключен
			</Button>
			<Button
				disabled={!!disabled}
				btnType={"button"}
				isActive={autoSelectionField}
				type={ButtonTypes.secondary}
				size={UISizes.medium}
				onClick={() => {
					toggleActiveButton();
					props.input.onChange(true);
				}}
			>
				Включен
			</Button>
		</ButtonSwitchContainer>
	);
};
