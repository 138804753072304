import type { IOkkCommonFormType, IOkkTeleCommonFormType } from "@components/modals/okk-comment-modal/okk-comment.types";

export const okkCommentForm: IOkkCommonFormType = {
	inputDateValue: "",
	comment: "",
	control: false,
	refundRefusal: false,
	adminComment: "",
	answers: [],
	dispatcher: undefined,
	denyReason: {},
	dispatcherViolation: [],
	inputState: "default",
	masterViolation: undefined,
	master: undefined,
	masterGrade: { id: 0, title: "" },
	questionHandled: false,
	date: new Date(),
	isInvalid: false,
	showAll: false,
};

export const okkTeleCommentForm: IOkkTeleCommonFormType = {
	inputDateValue: "",
	comment: "",
	control: false,
	answers: [],
	inputState: "default",
	masterViolation: undefined,
	master: undefined,
	masterGrade: { id: 0, title: "" },
	date: new Date(),
	isInvalid: false,
	showAll: false,
};
