import { Button } from "@/components/button";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { InputErrorContainer } from "@/components/common/input-error-container";
import { InputDefault, MultiSelect } from "@/components/common/multi-select";
import Textfield from "@/components/form/textfield/textfield";
import { addSnack } from "@/eventbus/events/snackbar";
import { UISizes } from "@/global-types/mods.types";
import {
	type ResourcesRequestMasterShow,
	useMastersFeedQuery,
	useMastersMasterComplaintsFeedQuery,
	useRequestsComplaintsIndexQuery,
	useRequestsMastersComplaintsStoreMutation,
	useUpdateMastersComplaintsStoreMutation,
} from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import type { WorkData } from "@components/modals/okk-comment-modal/okk-comment.types";
import { ComplaintsTypes } from "@global-types/complaints.types";
import { getOnlyFillData } from "@utils/getOnlyFillData";
import type React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "../okk-comment-modal.module.scss";

const OkkMaster = ({
	onAdd,
	id,
	masterOnRequest,
	isRequestShowQueryLoading,
}: {
	onAdd: () => void;
	id?: number;
	masterOnRequest?: ResourcesRequestMasterShow;
	isRequestShowQueryLoading: boolean;
}) => {
	const params = useParams();
	const { data: mastersData, isLoading: mastersLoading } = useMastersFeedQuery();
	const profile = useUserProfileInfo();

	const { data: complaintsData, isLoading: complainsDataLoading } = useRequestsComplaintsIndexQuery({
		requestId: Number(params.cardId ?? params.requestId),
	});

	const { data: masterComplaints } = useMastersMasterComplaintsFeedQuery();

	const [estimateMaster, { isSuccess: isMasterSuccess, error: estimateError }] = useRequestsMastersComplaintsStoreMutation();

	const [updateMaster, { isSuccess: isMasterUpdateSuccess, isLoading: loading, error: updateError }] =
		useUpdateMastersComplaintsStoreMutation();

	const [sumError, setSumError] = useState("");

	const [workData, setWorkData] = useState<WorkData>({
		person: null,
		date: null,
		category: null,
		complaintId: null,
		comment: null,
		payed: false,
		summ: null,
	});

	const handleAddWork = () => {
		if (
			profile?.permissions?.CAN_ADD_BONUS &&
			workData.category?.id === ComplaintsTypes.Bonus &&
			(workData?.summ?.startsWith("0") || Number(workData.summ) < 100)
		) {
			setSumError("Сумма не может быть меньше 100");
			return;
		}
		if ((params.cardId || params.requestId) && workData.category && workData.person?.id) {
			const payload = getOnlyFillData({
				masterId: workData.person.id,
				requestId: Number(params.cardId ?? params.requestId),
				complaintId: workData.complaintId,
				requestsRequestMastersComplaintsStore: getOnlyFillData({
					text: workData.comment,
					type: workData.category.id,
					payed: false,
					summ: workData.category.id === ComplaintsTypes.Bonus && workData.summ ? Number(workData.summ) : null,
				}),
			});
			if (workData.complaintId) {
				updateMaster(payload);
			} else {
				estimateMaster(payload);
			}
		}
	};

	useEffect(() => {
		if (complaintsData?.data && mastersData?.data && id && masterComplaints?.data) {
			const itemToEdit = complaintsData?.data.master_complaints?.find((item) => item.id === id);
			const master = mastersData?.data.find((item) => item.id === itemToEdit?.master_id);
			const type = masterComplaints.data.find((item) => item.id === itemToEdit?.type);
			setWorkData({
				date: itemToEdit?.date ?? null,
				complaintId: itemToEdit?.id ?? null,
				person: master ?? null,
				summ: itemToEdit?.summ ? String(itemToEdit.summ) : null,
				comment: itemToEdit?.text ?? null,
				category: type ?? null,
			});
		}
	}, [id, mastersData, masterComplaints, complaintsData]);

	useEffect(() => {
		if (isMasterSuccess || isMasterUpdateSuccess) {
			onAdd?.();
			workData.comment = "";
		}
	}, [isMasterSuccess, isMasterUpdateSuccess]);

	useEffect(() => {
		if (!isRequestShowQueryLoading && masterOnRequest && !id) {
			setWorkData((prev) => ({
				...prev,
				person: {
					id: masterOnRequest?.id,
					title: masterOnRequest?.fio,
				},
			}));
		}
	}, [isRequestShowQueryLoading, masterOnRequest, id]);

	useEffect(() => {
		if (updateError || estimateError) {
			const error = updateError || estimateError;
			if (error && "data" in error) {
				const message = (error.data as any).message;
				addSnack({
					type: "error",
					eventName: "update/estimate master",
					text: `${message}`,
					withAction: false,
				});
			}
		}
	}, [updateError, estimateError]);

	return !mastersLoading && !complainsDataLoading ? (
		<div className={styles["okk-column"]}>
			<div className={styles["okk-row"]}>
				{mastersData && (
					<MultiSelect
						inputContainerOrientation="column"
						width={"full-w"}
						disabled={id !== undefined && id !== null}
						initialSelectedItems={workData?.person ? [workData.person] : undefined}
						onChange={(data) => {
							if (data?.[0]) {
								setWorkData((prev) => ({ ...prev, person: data[0] }));
							} else {
								setWorkData((prev) => ({ ...prev, person: null }));
							}
						}}
						className={styles["okk-select"]}
						isDataNested={false}
						enableVirtualization
						inputLabelText={"Мастер:"}
						dropdownMenuSelectionVariant={"single-select"}
						data={mastersData?.data}
						inputId={"master"}
					/>
				)}
			</div>
			<div className={styles["okk-row"]}>
				{masterComplaints && (
					<MultiSelect
						enableSearch={false}
						inputContainerOrientation="column"
						className={styles["okk-select"]}
						isDataNested={false}
						width={"full-w"}
						inputLabelText={"Категория:"}
						dropdownMenuSelectionVariant={"single-select"}
						data={masterComplaints.data}
						inputId={"category"}
						initialSelectedItems={workData.category?.id || workData?.category?.id === 0 ? [workData.category] : undefined}
						onChange={(data) => {
							if (data?.[0]) {
								setWorkData((prev) => ({ ...prev, category: data[0] }));
								return;
							}
							setWorkData((prev) => ({ ...prev, category: null }));
						}}
					/>
				)}
				{workData.category && workData.category.id === ComplaintsTypes.Bonus && (
					<InputErrorContainer anchorId={"sum"} errorMessage={sumError} className={styles["input-error-container"]}>
						<InputDefault
							disabled={!profile?.permissions?.CAN_ADD_BONUS}
							state={sumError ? "error" : "default"}
							withRightButton={false}
							id={"sum"}
							type={"number"}
							inputContainerOrientation="column"
							labelText={"Сумма:"}
							inputProps={{
								value: workData.summ,
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									setSumError("");
									setWorkData((prev) => ({ ...prev, summ: e.target.value ? e.target.value : null }));
								},
								onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
									if (e.target.value.startsWith("0") || Number(e.target.value) < 100) {
										setSumError("Сумма не может быть меньше 100");
									}
								},
							}}
						/>
					</InputErrorContainer>
				)}
			</div>
			<Textfield
				label="Комментарий"
				value={workData.comment ?? ""}
				onChange={(e) => setWorkData((prev) => ({ ...prev, comment: e.target.value }))}
			/>
			<Button
				onClick={() => {
					handleAddWork();
				}}
				size={UISizes.medium}
				className={styles["okk-submit-button"]}
				disabled={!workData.person || !workData.category || !workData.comment}
				isLoading={loading}
			>
				{`${!id ? "Добавить" : "Редактировать"}`}
			</Button>
		</div>
	) : (
		<LoadingSpinner />
	);
};

export default OkkMaster;
