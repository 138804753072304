import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import { WorkFeedResource } from '@/state/api'
import { MultiSelect } from '@common/multi-select'
import { CreateRequestNames } from '@constants/create-request'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'

export const RequestModalSalePercentInput = <T extends number | undefined>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  disabled,
  options = [],
  enableSearch,
  enableMultiRowSelection = false,
  ...props
}: RequestModalTextInputProps<T>) => {
  const percent = useField(input?.name).input.value
  const saleTypeField = useField('saleType').input.value

  const [initialValue, setInitialValue] = useState<
    WorkFeedResource[] | undefined
  >(undefined)

  useEffect(() => {
    const arr = findItem<WorkFeedResource>({
      id: Number(percent),
      arr: options,
    })

    if (Array.isArray(arr) && arr.length > 0) {
      saleTypeField === 1 &&
        setInitialValue(
          findItem<WorkFeedResource>({
            id: Number(percent),
            arr: options,
          }),
        )
    } else {
      input.onChange(undefined)
      setInitialValue(undefined)
    }
  }, [percent, options, saleTypeField])

  return (
    <>
      <MultiSelect<WorkFeedResource>
        disabled={disabled}
        width={'full-w'}
        enableSearch={enableSearch}
        isDataNested={false}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={options || []}
        inputId={CreateRequestNames.typeOfWork}
        initialSelectedItems={initialValue}
        externalInputProps={input}
        onChange={(items) =>
          items?.length
            ? input.onChange(items[0].id)
            : input.onChange(undefined)
        }
        externalInputMetaProps={meta}
      />
    </>
  )
}
