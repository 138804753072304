import { SidebarMenuLogoProps } from '@common/sidebar-menu/sidebar-menu.types'
import styles from '../sidebar-menu.module.scss'
import LogoTrademark from './logo-trademark.svg'
import LogoFull from './logo-full.svg'
import cn from 'classnames'

export const SidebarMenuLogo = ({
  isOpened,
}: Partial<SidebarMenuLogoProps>) => {
  const sidebarMenuLogoClasses = cn(styles['sidebar-menu_logo'], {
    [styles['sidebar-menu_logo__opened']]: isOpened,
    [styles['sidebar-menu_logo__closed']]: !isOpened,
  })

  return (
    <div className={sidebarMenuLogoClasses}>
      <img src={isOpened ? LogoFull : LogoTrademark} alt="Ленремонт логотип" />
    </div>
  )
}
