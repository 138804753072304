import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import type { AnyObject } from "@global-types/utils-type";

export const MetricsSidebarFields = {
	titleCommon: "Общая статистика:",
	titleDispatchers: "Статистика диспетчеров:",
	titleOneDispatcher: "Статистика диспетчера:",
	callBack: "Обратный звонок:",
	applicationsToday: "Заявок сегодня:",
	applicationsYesterday: "Заявок вчера:",
	applicationsDuplicate: "Дублей заявок:",
	currentRecord: "Текущий рекорд:",
	clientsWaiting: "Клиент ждет:",
	masterNotSelected: "Мастер не подобран:",
	notPassedToMaster: "Не передан мастеру:",
	missedCalls: "Пропущенные вызовы:",
	autoanswer: "Автоответчик:",
	totalDispatchers: "Всего диспетчеров:",
	freeDispatchers: "Свободно диспетчеров:",
	clientsInLine: "Клиентов в очереди:",
	notReceivingCalls: "Не принимают звонки:",
} as const;

export const startedDateForClientWaiting = "01.01.2020 00:00";

export const checkEmptуСommonFilters = (filters: FilterTypes | AnyObject) => {
	if (!filters) return false;
	return (
		!filters?.[FilterNames.ads]?.length &&
		!filters?.[FilterNames.template] &&
		!filters?.[FilterNames.workerSetter]?.length &&
		!filters?.[FilterNames.street]?.length &&
		!filters?.[FilterNames.house]?.length &&
		!filters?.[FilterNames.housing]?.length &&
		!filters?.[FilterNames.apartment]?.length &&
		!filters?.[FilterNames.codeNumber] &&
		!filters?.[FilterNames.phone]?.length &&
		!filters?.[FilterNames.entrance] &&
		!filters?.[FilterNames.announced]?.length &&
		!filters?.[FilterNames.dispatcher]?.length &&
		!filters?.[FilterNames.district]?.length &&
		!filters?.[FilterNames.worker]?.length &&
		!filters?.[FilterNames.partner]?.length &&
		!filters?.[FilterNames.request]?.length &&
		!filters?.[FilterNames.responsible]?.length &&
		!filters?.[FilterNames.type]?.length &&
		!filters?.[FilterNames.typeOfWork]?.length &&
		!filters?.[FilterNames.notSaved] &&
		!filters?.[FilterNames.isCredit] &&
		!filters?.[FilterNames.isDublicate] &&
		!filters?.[FilterNames.onControl] &&
		!filters?.[FilterNames.withWorkRecords] &&
		!filters?.[FilterNames.isEntity] &&
		!filters?.[FilterNames.beznal] &&
		!filters?.[FilterNames.beznalOnline] &&
		!filters?.[FilterNames.beznalByAccount]
	);
};
