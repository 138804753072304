import { TypographyProps } from './typography.types'
import styles from './typography.module.scss'
import { createElement } from 'react'
import cn from 'clsx'

type GetClassNameType = {
  as: string
  tag: number
  weight?: number
  decoration: string
}

function getClassName({ as, tag, weight, decoration }: GetClassNameType) {
  if (as === 'caption') {
    return `caption-${tag}-${decoration}${
      tag === 3 || tag === 5 || tag === 6 ? '' : `-weight-${weight}`
    }`
  }
  if (as === 'paragraph') {
    return `paragraph-${tag}-${decoration}`
  }

  return ''
}

//@todo
// плохой компонет со репы реквеста, но с цсс переменными, переделать чтобы пропсы соответствовали

export const Typography = ({
  as,
  tag,
  weight,
  decoration = 'none',
  children,
  className,
  mRef,
  ...props
}: TypographyProps) => {
  const elementClasses = getClassName({ as, tag, weight, decoration })

  const typographyClasses = cn(styles[elementClasses], className)

  return createElement(
    'p',
    {
      ref: mRef,
      className: typographyClasses,
      ...props,
    },
    children,
  )
}
