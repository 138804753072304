import JSSIP from "jssip";
import type {
	ConnectingEvent,
	IncomingAckEvent,
	IncomingEvent,
	OutgoingAckEvent,
	OutgoingEvent,
	RTCSession,
	ReInviteEvent,
} from "jssip/lib/RTCSession";
import type React from "react";
import type { JSX } from "react";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import { useActor } from "@xstate/react";
import type { IncomingRTCSessionEvent } from "jssip/lib/UA";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import callAnsweredMelody from "../../sounds/answered-call.mp3";
import RejectedMedia from "../../sounds/decline-call.mp3";
import RingingMedia from "../../sounds/ringing.wav";
import OutcomingCallMedia from "../../sounds/outcoming-call.mp3";
import { PhonerConfig } from "./config";

import { formatPhoneNumber } from "../../../features/fast-search/call/call";
import { useCreateC2CLog } from "../../api/useCreateC2CLog";
import { useProfile } from "../../api/useProfile";
import { AUTO_INFORMERS_NUMBERS } from "../../constants/autoInformer/autoinformer-numbers";
import {
	ACCEPT_INCOMING_CALL,
	DECLINE_CURRENT_CALL,
	DECLINE_INCOMING_CALL,
	DECLINE_SECOND_INCOMING_CALL,
	DECLINE_SECOND_LINE_CALL,
	SECOND_INCOMING_CALL,
	SET_INCOMING_CALL_INFO,
	SET_OUTGOING_CALL_INFO,
	START_SECOND_LINE,
	TRANSFER_TO_AUTOINFORMER,
} from "../../event-bus/types/event-action.types";
import type { MakeCallEventT } from "../../phoner-events";
import { parsePhoneFromExternalSource } from "../../utils/convert-phone";
import { useMasterTab } from "../master-tab/master-tab";
import { useSharedWorker } from "../shared-worker";
import { CallDirection, SessionStatus as SessionDTOStatus, useSessionContext } from "../tab-session-context/tab-session-context";
import { callAcceptEventHandler, callConfirmEventHandler, callConnectingEventHandler, iceCandidateCallHandler } from "./utils/handlers";

enum SessionStatus {
	STATUS_NULL = 0,
	STATUS_INVITE_SENT = 1,
	STATUS_1XX_RECEIVED = 2,
	STATUS_INVITE_RECEIVED = 3,
	STATUS_WAITING_FOR_ANSWER = 4,
	STATUS_ANSWERED = 5,
	STATUS_WAITING_FOR_ACK = 6,
	STATUS_CANCELED = 7,
	STATUS_TERMINATED = 8,
	STATUS_CONFIRMED = 9,
}

export enum CauseReason {
	NO_ANSWER = "No Answer",
	LOCAL_TERMINATE = "Local Terminate",
	MASTER_TAB_EXITED = "Exited Master Tab",
}

interface PhoneContextProps {
	isConnected: boolean;
	volume: number;
	sessions: RTCSession[];
	activeSession: RTCSession | null;
	userInternalNumber: string;
	setCurrentActiveWidget: React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>;
	currentActiveWidget: HTMLDivElement | undefined;
	makeCall: (phoneNumber: string, additionalInfo?: MakeCallEventT) => void;
	endCall: (sessionId: string, withSendingMessage?: boolean) => void;
	toggleHold: () => void;
	handleVolumeChange: (value: number) => void;
	acceptCall: (sessionId: string, withSendingMessage?: boolean) => void;
	stopSound: () => void;
	setRemoteAudioControl: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
	setSoundControl: React.Dispatch<React.SetStateAction<HTMLAudioElement | null>>;
	toggleActiveSession: (sessionId: string) => void;
	setSessions: React.Dispatch<React.SetStateAction<RTCSession[]>>;
	muteMicro: (sessionId: string) => void;
	unmuteMicro: (sessionId: string) => void;
	setSessionHold: () => void;
	setSessionUnHold: () => void;
	referToAutoInformer: (sessionId: string, withSendingMessage?: boolean) => void;
}

const PhoneContext = createContext<PhoneContextProps>({
	isConnected: false,
	volume: 100,
	activeSession: null,
	sessions: [],
	userInternalNumber: "",
	setCurrentActiveWidget: () => {
		return;
	},
	currentActiveWidget: undefined,
	makeCall: () => {
		return;
	},
	endCall: () => {
		return;
	},
	toggleHold: () => {
		return;
	},
	handleVolumeChange: () => {
		return;
	},
	acceptCall: () => {
		return;
	},
	stopSound: () => {
		return;
	},
	setRemoteAudioControl: () => {
		return;
	},
	setSoundControl: () => {
		return;
	},
	toggleActiveSession: () => {
		return;
	},
	setSessions: () => {
		return;
	},
	muteMicro: () => {
		return;
	},
	unmuteMicro: () => {
		return;
	},
	setSessionHold: () => {
		return;
	},
	setSessionUnHold: () => {
		return;
	},
	referToAutoInformer: () => {
		return;
	},
});

export const usePhoner = () => useContext(PhoneContext);

export const PhonerProvider = ({ children }: { children: JSX.Element }) => {
	const [volume, setVolumeState] = useState(50);
	const [activeSession, setActiveSession] = useState<RTCSession | null>(null);
	const [, setCurrentStream] = useState<MediaStream | null>(null);
	const { sendMessage } = useSharedWorker();
	const { setSessionsDTO, sessionsDTO } = useSessionContext();
	const userAgent = useRef<JSSIP.UA | null>(null);
	const [isConnected, setIsConnected] = useState(false);
	const { createJSSipConfiguration, url, callOptions } = PhonerConfig;
	const { mutate: createLog } = useCreateC2CLog();
	const [sessions, setSessions] = useState<RTCSession[]>([]);
	const { phonerIncomingCallService, phonerTreyService, phonerSecondLineService, phonerConnectionService } = useGlobalPhonerState();

	const [userInternalNumber, setUserInternalNumber] = useState("");
	const [incomingCallState, sendPhonerIncomingCallEvent] = useActor(phonerIncomingCallService);
	const [statePhonerSecondLine, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);
	const [, setPhonerConnectionService] = useActor(phonerConnectionService);
	const [, setPhonerTrey] = useActor(phonerTreyService);

	const firstLineConnected =
		incomingCallState.matches("current call.connected") ||
		incomingCallState.matches("current call.call on hold") ||
		incomingCallState.matches("current call.filter enabled");

	const isSecondLineOutcomingCallConnecting = statePhonerSecondLine.matches("connecting");
	const isSecondLineOutcomingCallConnected = statePhonerSecondLine.matches("connected");
	const secondLineCallInfo = statePhonerSecondLine?.context?.secondLineCallInfo;
	const sessionContext = sessionsDTO;

	const [currentActiveWidget, setCurrentActiveWidget] = useState<HTMLDivElement>();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { data } = useProfile();

	const [remoteAudioControl, setRemoteAudioControl] = useState<HTMLAudioElement | null>(null);
	const [soundsControl, setSoundControl] = useState<HTMLAudioElement | null>(null);

	const toggleMicrophone = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

			for (const track of stream.getTracks()) {
				track.stop();
			}
		} catch (error) {
			console.error("Ошибка при получении доступа к микрофону:", error);
		}
	};

	useEffect(() => {
		if (!isCurrentTabMasterTab) return;
		const phone = data?.data?.phone;
		const password = data?.data?.password;

		if (phone && password) {
			connect();

			return;
		}

		setPhonerConnectionService({
			type: "isConnectionEstablished",
			value: false,
		});

		return () => {
			phone && password && disconnect();
		};
	}, [isCurrentTabMasterTab, data?.data?.password, data?.data?.phone]);

	const toggleActiveSession = (sessionId: string) => {
		setSessions((prev) => {
			const sessionClickedOn = prev.find((session) => session.id === sessionId);
			const session = prev.find((session) => session.id !== sessionId);

			if (!session) return prev;
			if (!sessionClickedOn) return prev;

			if (sessionClickedOn.isOnHold().local) {
				sessionClickedOn.unhold();
				sessionClickedOn.unmute();
				session?.hold();
				session?.mute();

				const remoteStreamTrack = sessionClickedOn.connection.getReceivers()?.[0]?.track;
				if (!remoteStreamTrack) return prev;

				const remoteStream = new MediaStream([remoteStreamTrack]);
				setCurrentStream(remoteStream);

				if (remoteAudioControl) {
					remoteAudioControl.srcObject = remoteStream;
					remoteAudioControl.muted = false;
					remoteAudioControl.onloadeddata = () => {
						remoteAudioControl.play();
					};
				}
			} else {
				sessionClickedOn.hold();
				sessionClickedOn.mute();
				session?.unhold();
				session?.unmute();

				const remoteStreamTrack = session?.connection?.getReceivers()?.[0]?.track;
				if (!remoteStreamTrack) return prev;

				const remoteStream = new MediaStream([remoteStreamTrack]);
				setCurrentStream(remoteStream);

				if (!remoteAudioControl) return prev;

				remoteAudioControl.srcObject = remoteStream;
				remoteAudioControl.muted = false;

				remoteAudioControl.onloadeddata = () => {
					remoteAudioControl.play();
				};
			}

			return [...sessions];
		});
	};

	useEffect(() => {
		if (!data?.data?.phone) return;

		setUserInternalNumber(data?.data?.phone.toString());
	}, [data?.data?.phone]);

	useEffect(() => {
		userAgent.current?.on("newRTCSession", (data: IncomingRTCSessionEvent) => {
			if (data.session.direction !== "incoming") return;

			if (data.session.direction === "incoming" && sessions.length > 1) {
				data.session.terminate();

				return;
			}

			const preparedPhone = parsePhoneFromExternalSource(data.session.remote_identity.uri.user);

			sessions.length === 0 && setActiveSession(data.session);
			setSessions((prev) => [...prev, data.session]);
			stopSound();
			playSound(RingingMedia, true);

			setSessionsDTO((prev) => [
				...prev,
				{
					sessionId: data.session.id,
					direction: CallDirection.INCOMING,
					phoneNumber: preparedPhone,
					status: SessionDTOStatus.RINGING,
				},
			]);

			if (sessions.length) {
				sendPhonerSecondLineEvents({
					type: "incoming second call",
					callInfo: {
						id: data.session.id,
						phoneNumber: preparedPhone,
					},
				});

				sendMessage({
					eventName: SECOND_INCOMING_CALL,
					eventPayload: {
						sessionId: data.session.id,
						direction: CallDirection.INCOMING,
						phoneNumber: preparedPhone,
						status: SessionDTOStatus.RINGING,
					},
				});

				return;
			}

			sendPhonerIncomingCallEvent({
				type: "incoming call",
				callInfo: {
					id: data.session.id,
					phoneNumber: preparedPhone,
				},
			});

			sendMessage({
				eventName: SET_INCOMING_CALL_INFO,
				eventPayload: {
					sessionId: data.session.id,
					direction: CallDirection.INCOMING,
					phoneNumber: preparedPhone,
					status: SessionDTOStatus.RINGING,
				},
			});
		});

		return () => {
			userAgent.current?.removeAllListeners("newRTCSession");
		};
	}, [userAgent.current, soundsControl, sessions.length]);

	useEffect(() => {
		for (const session of sessions) {
			session.on("connecting", (e: ConnectingEvent) => {
				playSound(OutcomingCallMedia, true);

				callConnectingEventHandler(e, session, sendMessage, sessionContext, setSessionsDTO);
			});

			session.on("accepted", (e: OutgoingEvent | IncomingEvent) => {
				callAcceptEventHandler(e, stopSound, session, sendMessage, sessionContext, setSessionsDTO);
			});

			session.on("confirmed", (e: IncomingAckEvent | OutgoingAckEvent) => {
				callConfirmEventHandler(
					e,
					playSound,
					callAnsweredMelody,
					remoteAudioControl,
					session,
					isSecondLineOutcomingCallConnecting,
					setCurrentStream,
					sendPhonerIncomingCallEvent,
					sendPhonerSecondLineEvents,
					setSessions,
					sendMessage,
					sessionContext,
					setSessionsDTO,
				);
			});

			session.on("icecandidate", iceCandidateCallHandler);

			session.on("failed", (e) => {
				console.info("failedEvent", e);
				session.data.additionalInfo && createLog(session.data.additionalInfo);

				if (e.cause === CauseReason.MASTER_TAB_EXITED) {
					sendMessage({
						eventName: DECLINE_SECOND_LINE_CALL,
						eventPayload: { sessionId: session.id },
					});

					sendMessage({
						eventName: DECLINE_CURRENT_CALL,
						eventPayload: { sessionId: session.id },
					});

					return;
				}

				if (session.data.customCause !== CauseReason.LOCAL_TERMINATE) {
					endCall(session.id);
				}
			});

			session.on("ended", (e) => {
				console.info("endEvent", e);
				session.data.additionalInfo && createLog(session.data.additionalInfo);

				if (e.cause === CauseReason.MASTER_TAB_EXITED) {
					sendMessage({
						eventName: DECLINE_SECOND_LINE_CALL,
						eventPayload: { sessionId: session.id },
					});

					sendMessage({
						eventName: DECLINE_CURRENT_CALL,
						eventPayload: { sessionId: session.id },
					});

					return;
				}

				if (session.data.customCause !== CauseReason.LOCAL_TERMINATE) {
					endCall(session.id);
				}
			});

			session.on("update", (e: ReInviteEvent) => {
				console.info("updateEvent", e);
				sendPhonerIncomingCallEvent("clear current phone after update session");
			});
		}

		return () => {
			for (const session of sessions) {
				session.removeAllListeners();
			}
		};
	}, [sessions.length, isSecondLineOutcomingCallConnecting, isSecondLineOutcomingCallConnected, secondLineCallInfo, firstLineConnected]);

	useEffect(() => {
		if (!isCurrentTabMasterTab) return;

		toggleMicrophone()
			.then(() => console.info("Микрофон разрешен"))
			.catch(() => console.info("Ошибка"));
	}, [isCurrentTabMasterTab]);

	useEffect(() => {
		if (isCurrentTabMasterTab) {
			window.onbeforeunload = () => {
				userAgent.current?.terminateSessions({
					cause: CauseReason.MASTER_TAB_EXITED,
				});
				userAgent.current?.stop();
			};
		}

		return () => {
			isCurrentTabMasterTab && userAgent.current?.stop();
			window.onbeforeunload = null;
		};
	}, [isCurrentTabMasterTab]);

	const setVolume = (value: number) => {
		const newVolume = Math.max(0, Math.min(value, 100));
		setVolumeState(newVolume);

		if (remoteAudioControl) {
			remoteAudioControl.volume = newVolume / 100;
		}
		if (soundsControl) {
			soundsControl.volume = newVolume / 100;
		}
	};

	const muteMicro = (sessionId: string) => {
		const targetSession = sessions.find((session) => session.id === sessionId);
		targetSession?.mute();
	};

	const unmuteMicro = (sessionId: string) => {
		const targetSession = sessions.find((session) => session.id === sessionId);
		targetSession?.unmute();
	};

	const acceptCall = (sessionId: string, withSendingMessage = true) => {
		const session = sessions.find((session) => session.id === sessionId);
		const targetDTOSession = sessionsDTO.find((session) => session.sessionId === sessionId);
		session?.answer(callOptions);

		if (isCurrentTabMasterTab) {
			stopSound();
			sessionsDTO.length > 1 && setSessionHold();
		}

		if (sessionsDTO.length > 1) {
			setSessionsDTO((sessions) =>
				sessions.map((session) =>
					session.sessionId !== sessionId ? { ...session, status: SessionDTOStatus.HOLD, isMuted: true } : session,
				),
			);

			sendPhonerSecondLineEvents("start second line");
		} else {
			sendPhonerIncomingCallEvent("accept");
		}

		withSendingMessage &&
			sendMessage({
				eventName: ACCEPT_INCOMING_CALL,
				eventPayload: {
					...targetDTOSession,
					status: SessionDTOStatus.ACCEPTED,
				},
			});
	};

	const connect = () => {
		if (!data?.data?.phone || !data?.data?.password) return;

		userAgent.current = new JSSIP.UA(createJSSipConfiguration(data?.data?.password, data?.data?.phone.toString()));

		userAgent.current.on("connecting", () => {
			console.info("<<<<< CONNECTING... >>>>>>");
		});

		userAgent.current.on("connected", () => {
			console.info("<<<<< SIP CONNECTED!!!! >>>>>");
		});

		userAgent.current.on("registered", () => {
			console.info("<<<<< SIP REGISTERED!!!! >>>>>");
			setIsConnected(true);

			setPhonerConnectionService({
				type: "isConnectionEstablished",
				value: true,
			});
		});

		userAgent.current.on("registrationFailed", () => {
			console.info("<<<<< SIP REGISTRATION FAILED!!!! >>>>>");
		});

		userAgent.current.start();
	};

	const disconnect = () => {
		console.info("disconnect");
		stopSound();
		playSound(RejectedMedia, false);
		userAgent?.current?.stop();

		setPhonerConnectionService({
			type: "isConnectionEstablished",
			value: false,
		});
	};

	const referToAutoInformer = async (sessionId: string, withSendingMessage = true) => {
		const currentSession = sessions.find((session) => session.id === sessionId);

		withSendingMessage &&
			sendMessage({
				eventName: TRANSFER_TO_AUTOINFORMER,
				eventPayload: { sessionId: sessionId },
			});

		if (isCurrentTabMasterTab) {
			const audioContext = new window.AudioContext();
			const audio = new Audio(RingingMedia);
			const source = audioContext.createMediaElementSource(audio);
			const destination = audioContext.createMediaStreamDestination();

			source.connect(destination);
			source.mediaElement.loop = true;
			const track = destination.stream.getAudioTracks()[0];
			const sender = currentSession?.connection.getSenders()[0];

			track && sender?.replaceTrack(track);

			audio.play().catch((error) => console.log(error));
		}

		setTimeout(() => {
			isCurrentTabMasterTab &&
				currentSession?.refer(`sip:${AUTO_INFORMERS_NUMBERS.QUALITY_CONTROL}@${url.hostname}`, {
					eventHandlers: {
						accepted: (e: any) => {
							console.info(e);
						},
						failed: (e: any) => {
							console.info(e);
						},
					},
				});

			endCall(sessionId, withSendingMessage);
		}, 1000);
	};

	const makeCall = (phoneNumber: string, additionalInfo?: MakeCallEventT) => {
		if (!isCurrentTabMasterTab || !userAgent.current) return;

		let formattedPhone =
			formatPhoneNumber(phoneNumber).length > 4 ? `9${formatPhoneNumber(phoneNumber)}` : formatPhoneNumber(phoneNumber);

		if (formattedPhone.length > 4 && (formattedPhone.startsWith("97812") || formattedPhone.startsWith("97800"))) {
			formattedPhone = `98${formattedPhone.slice(2)}`;
		}

		const session: RTCSession = userAgent.current.call(`sip:${formattedPhone}@${url.hostname}`, callOptions);

		if (additionalInfo) {
			session.data.additionalInfo = additionalInfo;
		}

		setActiveSession(session);

		setSessionsDTO((prev) => [
			...prev,
			{
				sessionId: session.id,
				direction: CallDirection.OUTCOMING,
				phoneNumber,
				status: SessionDTOStatus.CONNECTING,
			},
		]);

		setSessions((prev) => [...prev, session]);

		if (sessions.length === 0) {
			sendMessage({
				eventName: SET_OUTGOING_CALL_INFO,
				eventPayload: {
					sessionId: session.id,
					direction: CallDirection.OUTCOMING,
					phoneNumber,
					status: SessionDTOStatus.CONNECTING,
				},
			});

			return;
		}

		sendMessage({
			eventName: START_SECOND_LINE,
			eventPayload: {
				sessionId: session.id,
				direction: CallDirection.OUTCOMING,
				phoneNumber,
				status: SessionDTOStatus.CONNECTING,
			},
		});
	};

	const endCall = (sessionId: string, withSendingMessage = true) => {
		const targetSession = sessions.find((session) => session.id === sessionId);
		const activeSession = sessions.find((session) => session.id !== sessionId);

		if (isCurrentTabMasterTab) {
			if (targetSession)
				targetSession.data = {
					...targetSession?.data,
					customCause: CauseReason.LOCAL_TERMINATE,
				};

			targetSession?.status !== SessionStatus.STATUS_TERMINATED && targetSession?.terminate();
			stopSound();
			playSound(RejectedMedia, false);
			setActiveSession(activeSession || null);

			activeSession?.isOnHold().local && activeSession?.unhold();
			activeSession?.isMuted().audio && activeSession?.unmute();

			const remoteStreamTrack = activeSession?.connection?.getReceivers()?.[0]?.track;

			const remoteStream = remoteStreamTrack ? new MediaStream([remoteStreamTrack]) : null;
			remoteStream && setCurrentStream(remoteStream);

			if (remoteAudioControl && remoteStream) {
				remoteAudioControl.srcObject = remoteStream;
				remoteAudioControl.muted = false;

				remoteAudioControl.onloadeddata = () => {
					remoteAudioControl.play();
				};
			}

			setSessions((prev) => prev.filter((session) => session.id !== sessionId));
		}

		if (sessionsDTO.length > 1) {
			handleDeclineOnMultipleCalls(sessionId, withSendingMessage);
		} else {
			handleDeclineOnSingleCall(sessionId, withSendingMessage);
		}

		setSessionsDTO((prevSessions) =>
			prevSessions
				.filter((session) => session.sessionId !== sessionId)
				.map((session) => ({
					...session,
					isMuted: false,
					status: session.status === SessionDTOStatus.HOLD ? SessionDTOStatus.STARTED_CONVERSATION : session.status,
				})),
		);
	};

	const handleDeclineOnSingleCall = (sessionId: string, withSendingMessage = true) => {
		const targetDTOSession = sessionsDTO.find((session) => session.sessionId === sessionId);

		sendPhonerIncomingCallEvent("decline");
		setPhonerTrey({ type: "hover", isHovered: false });

		if (targetDTOSession?.status === SessionDTOStatus.RINGING) {
			withSendingMessage &&
				sendMessage({
					eventName: DECLINE_INCOMING_CALL,
					eventPayload: { sessionId },
				});

			return;
		}

		withSendingMessage &&
			sendMessage({
				eventName: DECLINE_CURRENT_CALL,
				eventPayload: { sessionId },
			});
	};

	const handleDeclineOnMultipleCalls = (sessionId: string, withSendingMessage = true) => {
		const targetDTOSession = sessionsDTO.find((session) => session.sessionId === sessionId);
		const activeDTOSession = sessionsDTO.find((session) => session.sessionId !== sessionId);
		const isSomeCallRinging = sessionsDTO.some((session) => session.status === SessionDTOStatus.RINGING);

		if (!isSomeCallRinging) {
			withSendingMessage &&
				sendMessage({
					eventName: DECLINE_SECOND_LINE_CALL,
					eventPayload: { sessionId },
				});
			sendPhonerSecondLineEvents("decline");

			sendPhonerIncomingCallEvent({
				type: "setNewIncomingCallInfo",
				callInfo: {
					id: activeDTOSession?.sessionId || "",
					phoneNumber: activeDTOSession?.phoneNumber || "",
				},
			});

			return;
		}

		if (targetDTOSession?.status === SessionDTOStatus.RINGING) {
			sendPhonerSecondLineEvents("decline");
			withSendingMessage &&
				sendMessage({
					eventName: DECLINE_SECOND_INCOMING_CALL,
					eventPayload: { sessionId },
				});

			return;
		}

		sendPhonerSecondLineEvents("decline");

		sendPhonerIncomingCallEvent({
			type: "backToIncomingCall",
			callInfo: {
				id: activeDTOSession?.sessionId || "",
				phoneNumber: activeDTOSession?.phoneNumber,
			},
		});

		isCurrentTabMasterTab && playSound(RingingMedia, true);

		withSendingMessage &&
			sendMessage({
				eventName: DECLINE_CURRENT_CALL,
				eventPayload: { sessionId },
			});
	};

	const setSessionHold = () => {
		if (activeSession) {
			activeSession.hold();

			if (remoteAudioControl) {
				remoteAudioControl.muted = true;
			}
		}
	};

	const setSessionUnHold = () => {
		if (activeSession) {
			activeSession.unhold();

			if (remoteAudioControl) {
				remoteAudioControl.muted = false;
			}
		}
	};

	const toggleHold = () => {
		if (activeSession) {
			if (!activeSession.isOnHold().local) {
				activeSession.hold();
			} else {
				activeSession.unhold();
			}
		}
	};

	const handleVolumeChange = (value: number) => {
		setVolume(value);
	};

	function stopSound() {
		if (soundsControl) {
			soundsControl.src = "";
			soundsControl.pause();
			soundsControl.currentTime = 0.0;
		}
	}

	function playSound(soundName: string, loop: boolean) {
		if (soundsControl) {
			soundsControl.pause();
			soundsControl.currentTime = 0.0;
			soundsControl.src = soundName;
			soundsControl.loop = loop;
			soundsControl.onloadeddata = () => {
				soundsControl.play().catch((e) => {
					console.info("e", e);
				});
			};
		}
	}

	return (
		<PhoneContext.Provider
			value={{
				isConnected,
				volume,
				activeSession,
				sessions,
				userInternalNumber,
				currentActiveWidget,
				setCurrentActiveWidget,
				makeCall,
				endCall,
				toggleHold,
				handleVolumeChange,
				acceptCall,
				stopSound,
				setRemoteAudioControl,
				setSoundControl,
				toggleActiveSession,
				setSessions,
				muteMicro,
				unmuteMicro,
				setSessionHold,
				setSessionUnHold,
				referToAutoInformer,
			}}
		>
			{children}
		</PhoneContext.Provider>
	);
};
