export const parseJsonBoolean = (obj: any): any => {
  const filteredData = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (value === 'true') {
        return [key, true]
      } else if (value === 'false') {
        return [key, false]
      } else {
        return [key, value]
      }
    }),
  )

  return filteredData
}
