import { createContext, useContext, useState, useEffect, type ReactElement } from "react";
import type { EventBusMappedEvents } from "../../event-bus/types/event-bus.types";
import { useEventBus } from "../event-bus/event-bus";

export type SharedWorkerMessage = {
	eventName: keyof EventBusMappedEvents;
	eventPayload?: Parameters<EventBusMappedEvents[keyof EventBusMappedEvents]>[0];
	connectionId?: number;
};

type SharedWorkerEventType = {
	type: string;
	connectionId: number;
};

type SharedWorkerContextType = {
	worker: null | SharedWorker;
	sendMessage: (data: SharedWorkerMessage) => void;
	connectionId: number;
};

export const SharedWorkerContext = createContext<SharedWorkerContextType>({
	worker: null,
	sendMessage: () => {
		return;
	},
	connectionId: 0,
});

export const useSharedWorker = () => useContext(SharedWorkerContext);

function isPayloadToEventBus(payload: unknown): payload is SharedWorkerMessage {
	const payloadWithMessage = payload as SharedWorkerMessage;

	return !!(payloadWithMessage.eventName && payloadWithMessage.eventPayload);
}

export const SharedWorkerProvider = ({ children }: { children: ReactElement }) => {
	const [worker, setWorker] = useState<SharedWorker | null>(null);
	const [connectionId, setConnectionId] = useState(0);
	const { emit } = useEventBus();

	const sendMessage = (data: SharedWorkerMessage) => {
		worker?.port.postMessage({ ...data, connectionId });
	};

	useEffect(() => {
		if (worker) {
			worker.port.onmessage = (e: MessageEvent<SharedWorkerMessage | SharedWorkerEventType>) => {
				const payload = e.data;

				if (isPayloadToEventBus(payload)) {
					emit(payload.eventName, payload.eventPayload);

					return;
				}

				setConnectionId(e.data.connectionId || 0);
			};
		}
	}, [worker]);

	useEffect(() => {
		if (!("SharedWorker" in window)) return;

		const sharedWorker = new SharedWorker(new URL("../../../../worker-scripts/shared-worker.js", import.meta.url), {
			name: "phoner-shared-worker",
		});

		sharedWorker.port.start();
		setWorker(sharedWorker);
	}, []);

	return <SharedWorkerContext.Provider value={{ worker, sendMessage, connectionId }}>{children}</SharedWorkerContext.Provider>;
};
