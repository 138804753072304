import styles from './files.module.scss'
import { FilesHeader } from '@components/files/files-header'
import { FilesItem } from '@components/files/files-item'
import { FilesContainer } from '@components/files/files-container'
import { useRequestsAttachmentsIndexQuery, useRequestsAttachmentsStoreMutation } from '@/state/api'
import { ContentLoader } from '../content-loader'
import { useParams } from 'react-router'
import { HandleUpload } from './files-header/files-header.types'
import { NoData, Typography } from '../common'
import NoFilesImage from '@/assets/svg/files-nodata.svg'
import { Button } from '../button'
import { ButtonTypes } from '../button/button.types'
import { UISizes } from '@/global-types/mods.types'
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'

type FilesProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
export const Files = ({ className }: FilesProps) => {
  const { requestId } = useParams()
  const [lastSubmittedState, setLastSubmittedState] = useState<FormData>()
  const [addFile, { isLoading, isError: isAddFileError }] = useRequestsAttachmentsStoreMutation()
  const {
    data,
    isLoading: loadFiles,
    isFetching: fetchFiles,
    isSuccess,
    isError,
    refetch,
  } = useRequestsAttachmentsIndexQuery({
    requestId: Number(requestId),
  })
  const filesData = data?.data || []

  const handleUploadFile: HandleUpload = (attachments, request_id) => {
    clearSnacks()
    setLastSubmittedState(attachments)
    addFile({
      requestsAttachmentStore: attachments,
      requestId: Number(request_id),
    })
  }

  const refetchFiles = () => {
    refetch()
  }

  useEffect(() => {
    if (isAddFileError && lastSubmittedState) {
      addSnack({
        type: 'error',
        eventName: 'add-files-error',
        text: 'Не удалось отправить файлы.',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleUploadFile(lastSubmittedState, Number(requestId)),
      })
    }
  }, [isAddFileError])

  return (
    <div className={className}>
      <div className={styles.files_container}>
        <FilesHeader addFile={handleUploadFile} />
        <>
          {!(isLoading || loadFiles || fetchFiles) && isSuccess && filesData.length !== 0 && (
            <FilesContainer>
              <>
                {filesData &&
                  filesData.map((item, idx) => {
                    return <FilesItem data={item} key={idx} />
                  })}
              </>
            </FilesContainer>
          )}
        </>

        {isLoading || loadFiles || fetchFiles ? <ContentLoader /> : null}
        {filesData.length === 0 && isSuccess && !(isLoading || loadFiles || fetchFiles) && (
          <NoData src={NoFilesImage} alt="Файлы отсутствуют" title="Файлы отсутствуют" />
        )}
        {isError && !(isLoading || loadFiles) && !fetchFiles && (
          <>
            <Typography as={'paragraph'} decoration={'none'} tag={3} className={styles['error-text']}>
              Не удалось загрузить файлы: сервер не отвечает или временно не доступен, попробуйте еще раз
            </Typography>
            <Button btnType={'button'} type={ButtonTypes.secondary} size={UISizes.medium} onClick={refetchFiles}>
              Обновить
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
