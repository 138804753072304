import { RequestCard } from "@/components";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import type { AnyObject } from "@global-types/utils-type";
import isEqual from "lodash/isEqual";

export const RequestsCardsList = ({
	items,
	filters,
	localFilters,
}: {
	items: Record<string, any>[] | undefined;
	filters: FilterTypes | AnyObject;
	localFilters: FilterTypes | AnyObject;
}) => {
	return (
		<div>
			{isEqual(filters, localFilters) && items?.length && items?.length > 0
				? items?.map((item) => {
						return (
							<div data-rfc={item.fullcode} key={item.id}>
								<RequestCard key={item?.id} requestId={item.fullcode} isLegalEntity={!!item.ur_id} card={item} />
							</div>
						);
					})
				: null}
		</div>
	);
};
