import { InstallmentFields, InstallmentServerData } from '@/constants/installment'

export const createInstallmentValidation = (values: Record<string, any>) => {
  const errors: Record<string, any> = {}
  if (!values?.[InstallmentServerData.agreement]) {
    errors[InstallmentServerData.agreement] = `Поле "${InstallmentFields.contractNumber.toLowerCase()}" не заполнено`
  }
  if (!values?.[InstallmentServerData.date_begin]) {
    errors[InstallmentServerData.date_begin] = `Поле "${InstallmentFields.dateOfCommencementOfInstallment.toLowerCase()}" не заполнено`
  }
  if (!values?.[InstallmentServerData.contract_date]) {
    errors[InstallmentServerData.contract_date] = `Поле "${InstallmentFields.dateOfSigningTheContract.toLowerCase()}" не заполнено`
  }
  if (!values?.[InstallmentServerData.period]) {
    errors[InstallmentServerData.period] = `Поле "${InstallmentFields.installmentPeriod.toLowerCase()}" не заполнено`
  }

  return errors
}
