import { validateDate } from "@/components/headers/requests-header/request-header-filters/filter-inputs/filter-period/filter-period";
import { RequiredFormTextFieldsParsers } from "@constants/form-parsers";
import type { BasicInputValues } from "@global-types/utils-type";
import type { GetSliceFromValue, ParseValue, ParsersType, SpecialSymbolsValidator } from "@global-types/validation.types";
import { valueToString } from "@utils/value-to-string";
import type React from "react";

export const handlePhoneOnPaste = (e: React.ClipboardEvent<HTMLInputElement>, form: any, field: string) => {
	const clipBoardText = e?.clipboardData?.getData("text").trim().toLowerCase().replace(/\D/gi, "").replace(/\s/gi, "");

	if (clipBoardText) {
		const parsedText = clipBoardText.slice(-10);
		const element = e.target as HTMLInputElement;
		element.value = `+7${parsedText}`;
		form.change(field, `+7${parsedText}`);
		e.preventDefault();
	}
};

export const normalizePhone = (value?: string) => {
	if (!value) return value;

	const numPart = value.replace(/[\D]/gi, "").replace(/\s/gi, "");
	let newValue = `+${numPart?.slice(0, 11)}`;
	const pattern = /^\+7[0-9]{10}$/;

	if (pattern?.test(String(newValue))) return newValue;
	if (newValue.substring(0, 1) === "8" && newValue.length === 12) {
		newValue = `+7${newValue.substring(1)}`;
	} else if (newValue.substring(0, 2) === "+8" && newValue.length === 12) {
		newValue = `+7${newValue.substring(2)}`;
	}
	return newValue;
};

export const composeValidators =
	(...validators: any) =>
	(value: any, values?: any, meta?: any) =>
		validators.reduce((error?: string | Promise<any>, validator?: any) => error || validator(value, values, meta), undefined);

export const composeParse =
	(...parses: ParsersType[]) =>
	(arg: ParseValue) =>
		parses.reduce((previousValue, parse) => parse(previousValue), arg);

export const required = <T = BasicInputValues | boolean>(value: T) => {
	return value !== undefined && value !== "" ? undefined : "Обязательно для заполнения";
};
export const validateLocationId = <T = BasicInputValues | boolean>(value: T) => {
	return value !== undefined && value !== "" && value !== 0 ? undefined : "Обязательно для заполнения";
};
export const isServerError = <T = boolean>(value: T, errorMsg: string) => {
	return () => (value ? errorMsg : undefined);
};
export const doNotTouch = <T = BasicInputValues | boolean>(value: T) => {
	return !value ? undefined : "Не трогай";
};

export const installmentDateValidator = <T = string | null>(dateString: T) => {
	if (typeof dateString === "string") {
		if (dateString === "__.__.____") {
			return undefined;
		}
		const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19\d{2}|20\d{2})$/;

		// Проверка формата даты
		if (!dateRegex.test(dateString)) {
			return "Неверная дата";
		}

		// Разбиваем строку даты на компоненты
		const [day, month, year] = dateString.split(".").map(Number);

		// Создаем объект Date для валидации
		const dateObject = new Date(year as number, (month as number) - 1, day);

		// Проверка диапазона дат
		const startDate = new Date(2000, 0, 1);
		const endDate = new Date(2099, 11, 31);
		const result = startDate <= dateObject && dateObject <= endDate;

		return result ? undefined : "Неверная дата";
	}
	return undefined;
};

export const phoneValidator = <T = string | null>(str: T) => {
	if (!str) {
		return undefined;
	}

	const value = typeof str === "string" && str?.replace(/_/g, "");

	const pattern2 = /^\+(?:\d{4}\*{5}\d{2})$/;
	const pattern3 = /^\+7[0-9]{10}$/;

	if (typeof value === "string") {
		if (value.length < 12) {
			return "Неверный номер телефона";
		}
		if (value.includes("*")) {
			return pattern2?.test(String(value)) ? undefined : "Неверный номер телефона";
		}

		return pattern3?.test(String(value)) ? undefined : "Неверный номер телефона";
	}
};

export const parserInputNumber = (value: string | undefined) => {
	if (!value) {
		return value;
	}

	const pattern = /([0-9])+/g;
	const arrWithNum = value.match(pattern);

	let resultString = "";

	if (arrWithNum !== null) {
		const str = arrWithNum.join("");

		if (value?.substring(0, 1) === "+") {
			resultString = `+${str}`;
		} else if (str.substring(0, 1) === "8") {
			resultString = `+7${str.substring(1)}`;
		} else if (str.substring(0, 1) === "+8") {
			resultString = `+7${str.substring(2)}`;
		} else {
			resultString = str;
		}
	}

	return resultString;
};
export const validateDateRange = (inputValue: string) => {
	if (!inputValue || inputValue?.includes("_")) return;

	const delimiter = /\s+-\s+|–|—/;
	const [startDateStr, endDateStr] = inputValue.split(delimiter);

	const formattedStartDate = validateDate(startDateStr);
	const formattedEndDate = validateDate(endDateStr);

	if (!formattedStartDate || !formattedEndDate) return "Неверный период";

	if (formattedStartDate >= formattedEndDate) return "Неверный период";
};

export const datesRangeValidator = <T = string | null>(value: T) => {
	if (!value) {
		return undefined;
	}

	const pattern =
		/^([0-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\s([0-1][0-9]|2[0-3]):[0-5][0-9]\s[-–—]\s([0-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}\s([0-1][0-9]|2[0-3]):[0-5][0-9]$/;

	return pattern.test(String(value)) ? undefined : "Неверный период";
};

export const mustBeNumber = <T>(value: T) => {
	if (!value || value === "") return undefined;

	const pattern = /^[1-9][0-9]*$/;

	return pattern.test(String(value)) ? undefined : "Значение должно быть числом";
};

export const mustBeNumberWithSpecialSymbol = <T>(value: T) => {
	if (!value || value === "") return undefined;

	const pattern = /^[1-9][0-9/\\|]*$/g;

	return pattern.test(String(value)) ? undefined : "Значение должно быть числом";
};

export const getSliceFromValue = ({ value, length }: GetSliceFromValue) => {
	// if (!value || !length) return undefined
	if (!value || !length) return "";
	return valueToString(value).slice(0, length);
};

export const specialSymbolsValidator = ({ value, pattern, errorMessage }: SpecialSymbolsValidator) => {
	if (!value) {
		return undefined;
	}
	const invalidChars = value
		.toString()
		.split("")
		.filter((char) => !char.match(pattern))
		.join("");

	return pattern.test(String(value)) ? undefined : `${errorMessage} : ${invalidChars}`;
};

export const mustBeLettersWithSpecialSymbols = (value: string) => {
	if (!value || value === "") return undefined;

	const pattern = /^[0-9а-яёА-ЯЁ.,/\[|\]()/\s''""/-]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const firstSymbolCantBeSpaceValidator = (value: string) => {
	if (!value) {
		return undefined;
	}
	if (/^[^\s].*/.test(value)) {
		return undefined;
	}
	return "Значение не может начинаться с пробела";
};

export const streetSpecialSymbolsValidator = (value: string) => {
	const pattern = /^[0-9а-яёА-ЯЁ.,()\[\]/\s/-]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const clientFioSpecialSymbolsValidator = (value: string) => {
	const pattern = /^[а-яёА-ЯЁ/\s./-]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const housingSpecialSymbolsValidator = (value: string) => {
	// const pattern = /^[0-9a-zA-Zа-яёА-ЯЁ.,|/s/]*$/
	const pattern = /^[0-9а-яёА-ЯЁ.,|\s\\/]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const objectSpecialSymbolsValidator = (value: string) => {
	const pattern = /^[0-9a-zA-Zа-яёА-ЯЁ.,''""/\s/():+№»«!*-]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};
export const objectOnlySpecialSymbolsValidator = (value: string) => {
	const pattern = /^[0-9a-zA-Zа-яёА-ЯЁ.,''""/\\s/():+№»«|!\s**-]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const commentOnlySpecialSymbolsValidator = (value: string) => {
	const pattern = /^[a-zA-Z0-9а-яА-ЯёЁ!"#$№%&'()*+,-./:;<=>?@[\\\]^_`{|}~\s]*$/;
	const errorMessage = "Поле содержит недопустимые символы";

	return specialSymbolsValidator({ value, pattern, errorMessage });
};

export const checkStartsWithZeroParser = (val?: ParseValue) => {
	if (!val) return undefined;

	return valueToString(val).replace(/^0+/, "");
};

export const checkStartsWithSpacesParser = (val?: ParseValue) => {
	if (!val) return undefined;

	return valueToString(val).replace(/^\s+/, "");
};

export const entranceLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.entrance.length,
	});
};

export const houseLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.house.length,
	});
};

export const pushMessageLengthParser = (val: string) => {
	return typeof val === "string"
		? getSliceFromValue({
				value: val,
				length: RequiredFormTextFieldsParsers.smsMessage.length,
			})
		: val;
};

export const discountParser = (val?: ParseValue) => {
	if (!val) return;
	return val.toString().slice(0, 6);
};

export const percentValidator =
	(saleType: any) =>
	<T>(value: T) => {
		if (!value || value === "") return undefined;

		if (saleType === 1 && (Number(value) < 0 || Number(value) > 100)) {
			return "Введите корректное значения процента скидки";
		}

		return undefined;
	};

export const apartmentLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.apartment.length,
	});
};

export const objectLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.object.length,
	});
};

export const fioLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.clientFio.length,
	});
};

export const streetLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.street.length,
	});
};
export const organizationLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.organization.length,
	});
};

export const innValidator = <T = string | null>(inn: T) => {
	if (!inn) {
		return undefined;
	}

	const value = typeof inn === "string" && inn?.replace(/D/g, "");

	const pattern = /^\d{10}(?:12)?$/;

	if (typeof value === "string") {
		if (pattern.test(value)) {
			return undefined;
		}
		if (value.length < 10) {
			return "Минимальная длина 10 цифр";
		}
		if (value.length > 12) {
			return "Максимальная длина 12 цифр";
		}
		if (value.length === 11) {
			return "ИНН должен быть 10 или 12 цифр";
		}
		return;
	}
};

export const normalizeInn = (value?: string) => {
	if (!value) return value;
	const pattern = /\D/g;
	const numPart = value.replace(pattern, "");

	if (!numPart.length) return;
	return numPart;
};

export const handleInnOnPaste = (e: React.ClipboardEvent<HTMLInputElement>, form: any, field: string) => {
	const clipBoardText = e?.clipboardData?.getData("text").trim().toLowerCase().replace(/\s/g, "").replace(/\D/g, "");

	if (clipBoardText) {
		const parsedText = clipBoardText.slice(-12);
		const element = e.target as HTMLInputElement;
		element.value = parsedText;
		form.change(field, parsedText);
		e.preventDefault();
	}
};

export const formatSecondToTimeString = (seconds: number) => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = Math.floor(seconds % 60);
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

	return `${formattedMinutes}:${formattedSeconds}`;
};

export const installmentPeriodLengthParser = (val?: ParseValue) => {
	return getSliceFromValue({
		value: val,
		length: RequiredFormTextFieldsParsers.installmentPeriod.length,
	});
};

export const firstSymbolCantBeZeroValidator = (value: string) => {
	if (!value) {
		return undefined;
	}
	if (/^[^0].*/.test(value)) {
		return undefined;
	}
	return "Значение не может начинаться с нуля";
};
