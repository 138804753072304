import { Title } from '@common/modal-container/modal-title'
import { ModalHeaderContentProps } from '@global-types/modal-header-content.types'

export const MetricsSidebarModalLeftContent = ({
  title,
}: Partial<ModalHeaderContentProps>) => {
  return (
    <>
      <Title>{title}</Title>
    </>
  )
}
