/* eslint-disable no-var */
import styles from './files-item-content.module.scss'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ATTACHMENTS_ATTACHMENT_ID } from '@constants/routes'
import { AttachmentIndexResource } from '@/state/api'

export const FilesItemContent: FC<{ data: AttachmentIndexResource }> = ({ data }): JSX.Element => {
  const navigate = useNavigate()

  return (
    <div className={styles.files_item_content}>
      <div className={styles.files_item_name}>{data.name}</div>
      <div className={styles.files_item_buttons}>
        <div className={styles.files_item_size}>{data.size}</div>
        <a
          download={data.name}
          href={`/api/attachments/${Number(data?.id)}/download`}
          target={'_blank'}
          className={styles.files_item_btn}
          rel="noreferrer">
          Скачать
        </a>
        <button
          disabled={!data.can_del}
          className={`${styles.files_item_btn} ${!data.can_del ? styles.files_item_btn_disabled : ''}`}
          onClick={() => navigate(ATTACHMENTS_ATTACHMENT_ID(String(data.id)))}>
          Удалить
        </button>
      </div>
    </div>
  )
}
