import { Button, Icon } from '../../../index'
import React from 'react'

type DeclineCallButtonProps = {
  handleDeclineCall: () => void
  isDeclineCallButtonDisabled: boolean
}
export const DeclineCallButton = ({
  handleDeclineCall,
  isDeclineCallButtonDisabled,
}: DeclineCallButtonProps) => {
  return (
    <Button
      disabled={isDeclineCallButtonDisabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        handleDeclineCall()
      }}
      size="m"
      variant="danger"
      width="icon"
      renderIcon={<Icon icon={'icon-terminate'} />}>
      {/* Отклонить */}
    </Button>
  )
}
