import type React from "react";
import { createContext, type Dispatch, type SetStateAction, useContext, useEffect, useState } from "react";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import type { AnyObject } from "@global-types/utils-type";
import { useSearchParams } from "react-router-dom";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import { format, subDays } from "date-fns";
import {
	formatDateRangeString,
	startTimeDefault,
	endTimeDefault,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import { isObject } from "lodash";

const today = new Date();
const prevMonth = new Date();
prevMonth.setMonth(prevMonth.getMonth() - 3);

const initialState = {
	[FilterNames.dispatcher]: [],
	[FilterNames.announced]: [],
	[FilterNames.responsible]: [],
	[FilterNames.period]: formatDateRangeString({
		startDate: subDays(today, 90),
		startTime: startTimeDefault,
		endDate: today,
		endTime: endTimeDefault,
	}),
	[FilterNames.dateRangeType]: "by_receipt",
	[FilterNames.fastFilters]: "not_selected",
	[FilterNames.dateStart]: `${format(subDays(today, 90), "dd.MM.yyyy")} 00:00`,
	[FilterNames.dateEnd]: `${format(today, "dd.MM.yyyy")} ${endTimeDefault}`,
	[FilterNames.page]: 1,
	[FilterNames.perPage]: 25,
	[FilterNames.hasNoMaster]: false,
	[FilterNames.notGivenWorker]: false,
	[FilterNames.is_installment]: false,
	[FilterNames.isCredit]: false,
	[FilterNames.beznalOnline]: false,
	[FilterNames.isDublicate]: false,
	[FilterNames.hasOrderExecutions]: false,
	[FilterNames.isControlledDispatcher]: false,
	[FilterNames.isTemp]: false,
	[FilterNames.creditStake]: false,
	[FilterNames.beznalByAccount]: false,
	[FilterNames.isEntity]: false,
	[FilterNames.street]: "",
	templateId: undefined,
};

type FiltersStateContextType = {
	filters: FilterTypes | AnyObject;
	templateFilters: FilterTypes | AnyObject;
	initialState: FilterTypes | AnyObject;
	setFilters: Dispatch<SetStateAction<FilterTypes | AnyObject>>;
	setTemplateFilters: Dispatch<SetStateAction<FilterTypes | AnyObject>>;
	skipGetRequests?: boolean;
	setSkipGetRequests?: Dispatch<SetStateAction<boolean>>;
};

export const FiltersStateContext = createContext<FiltersStateContextType>({
	filters: initialState,
	templateFilters: initialState,
	initialState: initialState,
	setFilters: () => {
		return;
	},
	setTemplateFilters: () => {
		return;
	},
});

export const useFiltersState = () => useContext(FiltersStateContext);

const getSearchParams = (searchParams: URLSearchParams) => {
	const values = Object.entries(Object.fromEntries(searchParams.entries()));
	const prepare = values
		.map(([key, value]) => {
			if (value !== undefined && value !== "" && !isObject(value)) {
				if (value.includes(",")) {
					const valArr = value.split(",").map((item) => {
						if (!Number.isNaN(Number(item))) {
							return Number(item);
						}
						return item;
					});

					return [key, valArr];
				}
				if (key === "state") {
					return [key, [Number(value)]];
				}
				if (!Number.isNaN(Number(value)) && !isObject(value)) {
					return key === "phone" && value ? [key, value.replace(/_/gi, "")] : [key, Number(value)];
				}

				if (value === "true") {
					return [key, true];
				}
				if (value === "false") {
					return [key, false];
				}
				return [key, value];
			}
			return [null, null];
		})
		.filter(([, value]) => value !== null);

	return prepare.length > 0 ? Object.fromEntries(prepare) : null;
};

export const FiltersStateProvider = ({ children }: { children: React.ReactElement }) => {
	const filtersLS = localStorage.getItem("filters") ? JSON.parse(String(localStorage.getItem("filters"))) : null;

	const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams(filtersLS) as AnyObject);
	const [skipGetRequests, setSkipGetRequests] = useState<boolean>(false);
	const [filters, setFilters] = useState<FilterTypes | AnyObject>(
		getSearchParams(searchParams) ? getSearchParams(searchParams) : initialState,
	);

	const [templateFilters, setTemplateFilters] = useState<FilterTypes | AnyObject>(
		getSearchParams(searchParams) ? getSearchParams(searchParams) : initialState,
	);

	useEffect(() => {
		const preparedSearchParams = getSearchParams(searchParams);
		setFilters((prev) => ({ ...prev, ...preparedSearchParams }));
	}, []);

	useEffect(() => {
		const filterData = Object.fromEntries(
			Object.entries(filters).filter(([_, value]) => {
				return value !== undefined && value !== "";
			}),
		);
		const { template, ...rest } = filterData;

		setSearchParams(new URLSearchParams(rest as AnyObject));
	}, [filters]);

	return (
		<FiltersStateContext.Provider
			value={{
				templateFilters,
				setTemplateFilters,
				filters: filters,
				setFilters,
				initialState,
				skipGetRequests,
				setSkipGetRequests,
			}}
		>
			{children}
		</FiltersStateContext.Provider>
	);
};
