import { ChatContainerProps } from '@common/chat/chat-container/chat-container.types'
import styles from './chat-container.module.scss'

export const ChatContainer = ({
  children,
  className = '',
}: ChatContainerProps) => {
  return (
    <div className={`${styles['chat-container']} ${className}`}>
      <div className={`${styles['chat-container_content']}`}>{children}</div>
    </div>
  )
}
