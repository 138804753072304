import { ModalBodyProps } from './modal-body.types'
import styles from './modal-body.module.scss'

export const ModalBody = ({ children, className }: ModalBodyProps) => {
  return (
    <div className={`${styles['modal-body-container']}  ${className || ''}`}>
      {children}
    </div>
  )
}
