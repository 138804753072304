import { TableContainerProps } from '@common/table-container/table-container.types'
import { forwardRef } from 'react'
import styles from '@common/table-container/table.module.scss'

export const TableContainer = forwardRef<HTMLDivElement, TableContainerProps>(
  (props, ref) => (
    <div
      style={props.style}
      className={`${styles['table-container']} ${props.className}`}>
      <div ref={ref} style={props.tableStyle} className={styles.table}>
        {props.children}
      </div>
    </div>
  ),
)
