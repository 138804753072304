import { Button } from '@/components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Tooltip } from '@/components/common'

import style from '../../installment.module.scss'

const AddInstallmentButton = ({ onClickAdd, isLoading, amountAvailability }: any) => {
  return (
    <Tooltip anchorId={'InstallmentAddButton'} text={'Сумма заявки должна быть больше нуля'} show={!amountAvailability && !isLoading}>
      <Button
        type={ButtonTypes.primary}
        onClick={onClickAdd}
        disabled={!amountAvailability && !isLoading}
        iconLeft={<i className={'icon-plus'} />}>
        Добавить договор
      </Button>
    </Tooltip>
  )
}

const EditInstallmentButton = ({onClickEdit }: any) => {
  return (
    <Button iconLeft={<i className={'icon-edit-1'} />} onClick={onClickEdit} type={ButtonTypes.primaryUncolored}>
      Редактировать
    </Button>
  )
}

const CancelSaveInstallmentButton = ({ onClickCancel, onClickSave, form }: any) => {
  return (
    <div className={style['control-buttons']}>
      <Button btnType={'button'} type={ButtonTypes.primaryUncolored} size={UISizes.medium} onClick={() => onClickCancel(form)}>
        Отменить
      </Button>
      <Button btnType={'button'} type={ButtonTypes.primary} size={UISizes.medium} onClick={() => onClickSave(form)}>
        Сохранить
      </Button>
    </div>
  )
}

export const InstallmentInfoStatusButtons = ({
  amountAvailability,
  isLoading,
  showAddButton,
  showEditButton,
  showCancelSaveButton,
  form,
  onClickCancel,
  onClickSave,
  onClickEdit,
  onClickAdd,
  canEdit,
  canCreate,
  canView,
}: any) => {
  const breakPoint =
    [Boolean(showAddButton), Boolean(showEditButton), Boolean(showCancelSaveButton)]?.filter((item) => item)?.length > 1
      ? 'stopRender'
      : null
  if (breakPoint) {
    return <></>
  }

  if (showAddButton && canCreate) {
    return <AddInstallmentButton onClickAdd={onClickAdd} isLoading={isLoading} amountAvailability={amountAvailability} />
  }
  if (showEditButton && canEdit) {
    return <EditInstallmentButton onClickEdit={onClickEdit}/>
  }
  if (showCancelSaveButton) {
    return <CancelSaveInstallmentButton onClickCancel={onClickCancel} onClickSave={onClickSave} form={form} />
  }
  return <></>
}
