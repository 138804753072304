import { InputDefault, MultiSelect } from "@/components/common/multi-select";

import { Button } from "@/components/button";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { InputErrorContainer } from "@/components/common/input-error-container";
import Textfield from "@/components/form/textfield/textfield";
import { addSnack } from "@/eventbus/events/snackbar";
import { UISizes } from "@/global-types/mods.types";
import {
	type ResourcesRequestIndexDispatcher,
	useDispatchersDispatcherComplaintsFeedQuery,
	useRequestsComplaintsIndexQuery,
	useRequestsDispComplaintsStoreMutation,
	useUpdateDispComplaintsStoreMutation,
	useUsersFeedQuery,
} from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import type { WorkData } from "@components/modals/okk-comment-modal/okk-comment.types";
import { ComplaintsTypes } from "@global-types/complaints.types";
import { getOnlyFillData } from "@utils/getOnlyFillData";
import cn from "classnames";
import type React from "react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import styles from "../okk-comment-modal.module.scss";

const OkkDispatcher = ({
	onAdd,
	id,
	className,
	dispatcherOnRequest,
	isRequestShowQueryLoading,
}: {
	onAdd: () => void;
	className?: string;
	id?: number;
	dispatcherOnRequest?: ResourcesRequestIndexDispatcher;
	isRequestShowQueryLoading: boolean;
}) => {
	const params = useParams();
	const profile = useUserProfileInfo();

	const { data: complaintsData, isLoading: complaintsDataLoading } = useRequestsComplaintsIndexQuery({
		requestId: Number(params.cardId ?? params.requestId),
	});

	const { data: dispatchersData, isLoading: dispatchersLoading } = useUsersFeedQuery();
	const { data: dispatcherComplaints, isLoading: complantsLoading } = useDispatchersDispatcherComplaintsFeedQuery();

	const [estimateDispatcher, { isSuccess: isDispatcherSuccess, error: estimateError }] = useRequestsDispComplaintsStoreMutation();

	const [updateDispatcher, { isSuccess: isDispatcherUpdateSuccess, isLoading: loading, error: updateError }] =
		useUpdateDispComplaintsStoreMutation();

	const okkDispatcherClasses = cn(styles["okk-column"], className);

	const [sumError, setSumError] = useState("");

	const dispatchersDataWithoutCurretnUser = useMemo(() => {
		const currentUserId = profile?.id;

		return dispatchersData?.data?.map((item) => ({
			...item,
			items: item.items?.filter((user) => user?.id !== currentUserId),
		}));
	}, [profile, dispatchersData]);

	const [workData, setWorkData] = useState<WorkData>({
		person: null,
		date: null,
		category: null,
		complaintId: null,
		comment: null,
		payed: false,
		summ: null,
	});

	const handleAddWork = () => {
		if (
			profile?.permissions?.CAN_ADD_BONUS &&
			workData.category?.id === ComplaintsTypes.Bonus &&
			(workData?.summ?.startsWith("0") || Number(workData.summ) < 100)
		) {
			setSumError("Сумма не может быть меньше 100");
			return;
		}
		if ((params.cardId || params.requestId) && workData.category && workData.person?.id) {
			const payload = getOnlyFillData({
				dispatcherId: Number(workData.person?.id),
				requestId: Number(params.cardId ?? params.requestId),
				complaintId: workData.complaintId,
				requestsRequestDispatchersComplaintsStore: getOnlyFillData({
					text: workData.comment,
					type: workData.category.id,
					payed: false,
					summ: workData.category.id === ComplaintsTypes.Bonus && workData.summ ? Number(workData.summ) : null,
				}),
			});
			if (workData.complaintId) {
				updateDispatcher(payload);
			} else {
				estimateDispatcher(payload);
			}
		}
	};

	useEffect(() => {
		if (complaintsData?.data && dispatchersDataWithoutCurretnUser && id && dispatcherComplaints?.data) {
			const itemToEdit = complaintsData.data.dispatcher_complaints?.find((item) => item.id === id);
			const dispatcher = dispatchersDataWithoutCurretnUser
				?.flatMap((item) => item.items)
				.find((item) => item?.id === itemToEdit?.disp_id);
			const type = dispatcherComplaints.data.find((item) => item.id === itemToEdit?.type);
			setWorkData({
				date: itemToEdit?.date ?? null,
				complaintId: itemToEdit?.id ?? null,
				person: dispatcher ?? null,
				summ: itemToEdit?.summ ? String(itemToEdit.summ) : null,
				comment: itemToEdit?.text ?? null,
				category: type ?? null,
			});
		}
	}, [id, dispatchersDataWithoutCurretnUser, dispatcherComplaints, complaintsData]);

	useEffect(() => {
		if (isDispatcherSuccess || isDispatcherUpdateSuccess) {
			onAdd?.();
			workData.comment = "";
		}
	}, [isDispatcherSuccess, isDispatcherUpdateSuccess]);

	useEffect(() => {
		if (!isRequestShowQueryLoading && dispatcherOnRequest && !id) {
			setWorkData((prev) => ({
				...prev,
				person: {
					id: dispatcherOnRequest?.id,
					title: dispatcherOnRequest?.fio,
				},
			}));
		}
	}, [isRequestShowQueryLoading, dispatcherOnRequest]);

	useEffect(() => {
		if (updateError || estimateError) {
			const error = updateError || estimateError;
			if (error && "data" in error) {
				const message = (error.data as any).message;
				addSnack({
					type: "error",
					eventName: "update/estimate dispatcher",
					text: `${message}`,
					withAction: false,
				});
			}
		}
	}, [updateError, estimateError]);

	return !dispatchersLoading && !complantsLoading && !complaintsDataLoading ? (
		<div className={okkDispatcherClasses}>
			<div className={styles["okk-row"]}>
				<InputErrorContainer anchorId={"sum"} className={styles["input-error-container-disp"]}>
					<MultiSelect
						inputContainerOrientation="column"
						disabled={id !== undefined && id !== null}
						initialSelectedItems={workData?.person ? [workData.person] : undefined}
						onChange={(data) => {
							if (data?.[0]) {
								setWorkData((prev) => ({ ...prev, person: data[0] }));
							} else {
								setWorkData((prev) => ({ ...prev, person: null }));
							}
						}}
						className={styles["okk-select"]}
						width={"full-w"}
						inputLabelText={"Диспетчер:"}
						dropdownMenuSelectionVariant={"single-select"}
						data={dispatchersDataWithoutCurretnUser}
						inputId={"dispatcher"}
						isDataNested
						allowOnlyChildSelection
					/>
				</InputErrorContainer>
			</div>
			<div className={styles["okk-row"]}>
				{dispatcherComplaints && (
					<MultiSelect
						enableSearch={false}
						inputContainerOrientation="column"
						className={styles["okk-select"]}
						width={"full-w"}
						isDataNested={false}
						inputLabelText={"Категория:"}
						dropdownMenuSelectionVariant={"single-select"}
						data={dispatcherComplaints?.data}
						initialSelectedItems={workData.category?.id || workData?.category?.id === 0 ? [workData.category] : undefined}
						onChange={(data) => {
							if (data?.[0]) {
								setWorkData((prev: any) => ({ ...prev, category: data[0] }));
								return;
							}
							setWorkData((prev) => ({ ...prev, category: null }));
						}}
						inputId={"category"}
					/>
				)}
				{workData.category && workData.category.id === ComplaintsTypes.Bonus && (
					<InputErrorContainer anchorId={"sum"} errorMessage={sumError} className={styles["input-error-container"]}>
						<InputDefault
							disabled={!profile?.permissions?.CAN_ADD_BONUS}
							state={sumError ? "error" : "default"}
							withRightButton={false}
							id={"sum"}
							inputContainerOrientation="column"
							type={"number"}
							labelText={"Сумма:"}
							inputProps={{
								value: workData.summ,
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									setSumError("");
									setWorkData((prev) => ({ ...prev, summ: e.target.value ? e.target.value : null }));
								},
								onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
									if (e.target.value.startsWith("0") || Number(e.target.value) < 100) {
										setSumError("Сумма не может быть меньше 100");
									}
								},
							}}
						/>
					</InputErrorContainer>
				)}
			</div>
			<Textfield
				label="Комментарий"
				value={workData.comment ?? ""}
				onChange={(e) => setWorkData((prev) => ({ ...prev, comment: e.target.value }))}
			/>
			<Button
				onClick={() => {
					handleAddWork();
				}}
				size={UISizes.medium}
				className={styles["okk-submit-button"]}
				disabled={!workData.person || !workData.category || !workData.comment}
				isLoading={loading}
			>
				{`${!id ? "Добавить" : "Редактировать"}`}
			</Button>
		</div>
	) : (
		<LoadingSpinner />
	);
};

export default OkkDispatcher;
