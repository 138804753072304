import type {
  PushMessageModalFormTypes,
  PushMessageModalProps,
} from "./push-message-modal.types";
import { ModalContainer, Typography } from "@components/common";
import { ModalBody, ModalHeader } from "@common/modal-container";
import { PushMessageModalLeftContent } from "./left-content";
import styles from "./push-message-modal.module.scss";
import { type AnyObject, Field, Form } from "react-final-form";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { RequestModalSelectInput } from "@components/modals/common-forms/request-modal-select-input";
import { PushMessageInput } from "@components/modals/push-message/input-message";
import { PushMessageLabels, PushMessageNames } from "@constants/push-message";
import { required } from "@/components/form/utils/validators";
import type { FormApi } from "final-form";
import {
  useMastersMessagesCategoriesFeedQuery,
  useRequestsShowQuery,
  useSendMasterMessageMutation,
} from "@/state/api";
import { prepareSelectPresets } from "@/utils/prepeare-select-presets";
import { ContentLoader } from "@/components/content-loader";
import { useEffect } from "react";
import { REQUESTS } from "@constants/routes";

export const PushMessageModal = ({
  onClose,
  onClosePath,
  subtitle,
  onHandleBackButton,
  closeOnBlackoutClick,
  master,
  request,
}: Partial<PushMessageModalProps>) => {
  const [sendMessage, { isError, isLoading }] = useSendMasterMessageMutation();
  const navigate = useNavigate();
  const { requestId, masterId, fullcodeId } = useParams();
  const {
    data: requestData,
    isLoading: requestDataLoading,
    isError: requestDataError,
  } = useRequestsShowQuery({
    requestId: Number(requestId) || Number(request),
  });
  const match = useMatch(
    "requests/feed/:requestId/message/:messageId/:fullcodeId"
  );

  const filters = localStorage.getItem("filters")
    ? JSON.parse(String(localStorage.getItem("filters")))
    : null;

  const { data: messageOptions, isLoading: loadOptions } =
    useMastersMessagesCategoriesFeedQuery();
  const submitForm = async (
    values: PushMessageModalFormTypes,
    form: FormApi<PushMessageModalFormTypes, PushMessageModalFormTypes>
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  ): Promise<boolean | AnyObject | void> => {
    if (values.message) {
      const result = await sendMessage({
        masterId: master ? Number(master) : Number(masterId),
        requestId: request ? Number(request) : Number(requestId),
        masterMessageRequestStore: {
          message: values.message,
          //Потому что бек требует число, а в category лежит объект
          category: values.category as unknown as number,
        },
      })
        .unwrap()
        .then(() => {
          if (onClose) onClose();
          else
            match
              ? navigate({
                  pathname: REQUESTS,
                  search: new URLSearchParams(filters).toString(),
                })
              : navigate("../");
        })
        .catch((e) => {
          form.reset();
          form.restart();
          console.warn(e);
        });

      return result;
    }
  };

  useEffect(() => {
    if (requestDataError) navigate("../../");
    if (requestData && !requestData?.data?.abilities?.master_message) {
      navigate("../../");
    }
  }, [requestData, requestDataError]);

  return (
    <>
      <Form onSubmit={submitForm}>
        {({ handleSubmit }) => (
          <ModalContainer
            onClose={() => {
              if (onClose) onClose();
              else if (match?.pathname) navigate(`${onClosePath}`);
              else navigate(`${onClosePath}`);
            }}
            showBlackout
            closeOnBlackoutClick={closeOnBlackoutClick}
            className={styles["push-message-modal"]}
          >
            <ModalHeader
              showLeftContent
              contentLeft={
                <PushMessageModalLeftContent
                  showBackButton={false}
                  title={`#${fullcodeId || request}`}
                  subtitle={subtitle}
                  onHandleBackButton={onHandleBackButton}
                />
              }
              showCloseButton
              onClose={() => {
                if (onClose) onClose();
                else if (match?.pathname) navigate(`${onClosePath}`);
                else navigate(`${onClosePath}`);
              }}
            />
            <form onSubmit={handleSubmit}>
              <ModalBody>
                {loadOptions || isLoading || requestDataLoading ? (
                  <ContentLoader />
                ) : (
                  <>
                    <div className={styles["push-message-select"]}>
                      <Field<PushMessageModalFormTypes["category"]>
                        name={PushMessageNames.category}
                        type={"select"}
                        label={PushMessageLabels.category}
                        options={prepareSelectPresets(messageOptions) || []}
                        validate={required}
                        component={RequestModalSelectInput}
                      />
                    </div>
                    <Field<PushMessageModalFormTypes["message"]>
                      validate={required}
                      name={PushMessageNames.message}
                      type={"text"}
                      component={PushMessageInput}
                    />
                    {isError && (
                      <Typography
                        as={"paragraph"}
                        decoration={"none"}
                        tag={3}
                        className={styles["error-text"]}
                      >
                        Не удалось отправить сообщение мастеру: сервер не
                        отвечает или временно не доступен, попробуйте еще раз
                      </Typography>
                    )}
                  </>
                )}
              </ModalBody>
            </form>
          </ModalContainer>
        )}
      </Form>
    </>
  );
};
