import { useState, useEffect } from 'react'
import { ModalContainer } from '@/components/common'
import { ModalBody, ModalHeader } from '@/components/common/modal-container'
import { MasterCardHeaderLeftContent } from '../master-card-modal/master-card-header/left-content'
import { ContentLoader } from '@/components/content-loader'

import styles from './dispetcher-card-modal.module.scss'
import { DispetcherCardModalProps } from './dispetcher-card-modal.types'
import { useNavigate, useParams } from 'react-router'
import { useDispetcherCardQuery } from '@/state/api'
import { addSnack } from '@/eventbus/events/snackbar'
import { DispetcherCardContent } from './content'
import { DispetcherCardHeader } from './header'

export const DispetcherCardModal = ({ onClosePath, closeOnBlackoutClick, subtitle = '' }: DispetcherCardModalProps) => {
  const navigate = useNavigate()
  const { dispatcherId } = useParams()

  const {
    data: requestDataDispetcher,
    isLoading,
    isError,
    error,
    refetch,
  } = useDispetcherCardQuery({ dispatcherId: dispatcherId ? dispatcherId : null })

  const handleRefetchRequests = () => {
    refetch()
    return
  }

  useEffect(() => {
    if (isError) {
      console.error('Ошибка загрузки данных', error)
      addSnack({
        type: 'error',
        eventName: 'fetch-dispetcher-show-error',
        text: 'Не удалось загрузить статистику.',
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchRequests(),
      })
    }
  }, [isError])

  return (
    <>
      <ModalContainer
        className={styles['dispetcher-card-modal']}
        onClose={
          onClosePath
            ? () => {
                navigate(onClosePath)
              }
            : () => {
                navigate('/')
              }
        }
        showBlackout
        closeOnBlackoutClick={closeOnBlackoutClick}>
        <ModalHeader
          showLeftContent
          contentLeft={<DispetcherCardHeader showBackButton={false} title={requestDataDispetcher?.data?.fio} subtitle={subtitle} />}
          showCloseButton
          onClose={
            onClosePath
              ? () => {
                  navigate(onClosePath)
                }
              : () => {
                  navigate('../')
                }
          }
        />
        <ModalBody>
          {isLoading ? (
            <ContentLoader />
          ) : (
            <div className={styles['dispetcher-card-modal-content']}>
              <DispetcherCardContent work={requestDataDispetcher?.data.complaint} statistic={requestDataDispetcher?.data.state} />
            </div>
          )}
        </ModalBody>
      </ModalContainer>
    </>
  )
}
