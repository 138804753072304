import { AvatarProps } from '@common/chat/avatar/avatar.types'
import styles from './avatar.module.scss'
import { SyntheticEvent } from 'react'
import defaultAvatar from './default-avatar.png'
export const Avatar = ({ src }: Partial<AvatarProps>) => {
  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = defaultAvatar
  }

  return (
    <div className={styles['avatar-container']}>
      {src && <img src={src} alt="" onError={addImageFallback} />}
    </div>
  )
}
