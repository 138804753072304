import { useActor } from "@xstate/react";
import { useEffect } from "react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { Phoner } from "../../../entities";
import { CurrentCall } from "../../../entities/current-call";
import { ActionButtons } from "../../../entities/current-call/action-buttons";
import { MainPanel } from "../../../entities/current-call/main-panel";
import { BackToCurrentCallButton } from "../../../features/current-call/back-to-current-call";
import { CurrentCallButton } from "../../../features/current-call/call";
import { SearchCurrentCallButton } from "../../../features/current-call/search";
import { Search } from "../../../features/current-call/search";
import { ToggleCallOnHold } from "../../../features/current-call/toggle-call-on-hold";
import { ToggleMicrophoneButton } from "../../../features/current-call/toggle-mic";
import { ToggleMenuButton } from "../../../features/toggle-menu";
import { Panel } from "../../../shared";
import { CurrentCallPhone } from "../../../shared/components/current-call-phone";
import { Timer } from "../../../shared/components/timer/ui/timer";
import { getTimerPlaceholderByStatus } from "../../../shared/components/timer/utils/getTimerPlaceholder";
import { ANIMATION_DIRECTIONS } from "../../../shared/constants/animation-direction/animation-direction";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { usePhoner } from "../../../shared/contexts/phoner/phoner";
import { SessionStatus, useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";
import { DECLINE_SECOND_INCOMING_CALL, SECOND_INCOMING_CALL } from "../../../shared/event-bus/types/event-action.types";
import { convertPhone } from "../../../shared/utils/convert-phone";
import { CallControlWidget } from "../../call-control";
import { GetBottomSlot } from "../../phoner/utils/getBottomSlot";
import { GetTopSlot } from "../../phoner/utils/getTopSlot";
import styles from "./current-call.module.scss";
import { IncomingCallWidget } from "../../incoming-call";
import { useEventBus } from "../../../shared/contexts/event-bus/event-bus";

export const CurrentCallWidget = () => {
	const { phonerIncomingCallService, fastSearchService, phonerDraggingService, phonerSecondLineService } = useGlobalPhonerState();

	const [, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);

	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const hasIncomingCallAtCurrent = statePhonerSecondLine.matches("second call received");

	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const [stateFastSearch] = useActor(fastSearchService);
	const showCurrentCallFilterEnabled = statePhonerIncomingCall.matches("current call.filter enabled");
	const { sessions, endCall, currentActiveWidget } = usePhoner();
	const { sessionsDTO, setSessionsDTO } = useSessionContext();

	const { isCurrentTabMasterTab } = useMasterTab();
	const showCallButton = !!stateFastSearch.context.data["fast-search"];
	const callDurationInSeconds = sessionsDTO?.[0]?.startedAt ? Math.round((Date.now() - sessionsDTO?.[0]?.startedAt) / 1000) : 0;
	const currentSessionState = sessionsDTO.find((session) => session?.sessionId === sessions[0]?.id);
	const [phonerDraggingState, setPhonerDraggingEvents] = useActor(phonerDraggingService);
	const { animationDirection } = phonerDraggingState.context.animationTransitionConfig;
	const { on } = useEventBus();

	useEffect(() => {
		if (!currentActiveWidget) return;

		const isLeftViewportBoundaryReached = phonerDraggingState.context.position.x < currentActiveWidget.clientWidth / 2;

		const isRightViewportBoundaryReached =
			window.innerWidth - currentActiveWidget.clientWidth / 2 < phonerDraggingState.context.position.x;

		const isTopViewportBoundaryReached =
			window.innerHeight - currentActiveWidget.clientHeight < Math.abs(phonerDraggingState.context.position.y);

		if (isLeftViewportBoundaryReached) {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: { x: currentActiveWidget.clientWidth / 2, y: phonerDraggingState.context.position.y },
			});
		}

		if (isRightViewportBoundaryReached) {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: { x: window.innerWidth - currentActiveWidget.clientWidth / 2, y: phonerDraggingState.context.position.y },
			});
		}

		if (isTopViewportBoundaryReached) {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: { y: -(window.innerHeight - currentActiveWidget.clientHeight), x: phonerDraggingState.context.position.x },
			});
		}
	}, [currentActiveWidget]);

	useEffect(() => {
		const unsubscribeAnswerIncomingCall = on(SECOND_INCOMING_CALL, (payload) => {
			sendPhonerSecondLineEvents({
				type: "incoming second call",
				callInfo: {
					id: payload.sessionId || "",
					phoneNumber: payload.phoneNumber,
				},
			});

			const session = sessionsDTO.find((session) => session.sessionId === payload.sessionId);
			!session && setSessionsDTO((prev) => [...prev, { ...payload }]);
			session && setSessionsDTO((prev) => prev.map((session) => (session.sessionId === payload.sessionId ? payload : session)));
		});

		return () => unsubscribeAnswerIncomingCall();
	}, [isCurrentTabMasterTab, sessionsDTO]);

	useEffect(() => {
		const unsubscribeDeclineCurrentCall = on(DECLINE_SECOND_INCOMING_CALL, (payload) => {
			endCall(payload.sessionId, false);
		});

		return () => unsubscribeDeclineCurrentCall();
	}, [isCurrentTabMasterTab, sessions, sessionsDTO]);

	return (
		<CurrentCall
			topSlot={
				animationDirection === ANIMATION_DIRECTIONS.TOP ? (
					<>
						{hasIncomingCallAtCurrent && (
							<div className={styles["incoming-call-wrapper"]} style={{ marginBottom: "8px" }}>
								<IncomingCallWidget withDivider={false} />
							</div>
						)}
						{!hasIncomingCallAtCurrent && <GetTopSlot />}
					</>
				) : (
					<></>
				)
			}
			wrapTopSlot={ANIMATION_DIRECTIONS.TOP && hasIncomingCallAtCurrent}
			wrapBottomSlot={ANIMATION_DIRECTIONS.BOTTOM && hasIncomingCallAtCurrent}
			bottomLeftSlot={
				!showCurrentCallFilterEnabled ? (
					<div className={styles["bottom-left-slot-wrapper"]}>
						<MainPanel leftSlot={<ToggleMenuButton />} rightSlot={<SearchCurrentCallButton />} />
					</div>
				) : null
			}
			bottomMiddleSlot={
				<div className={styles["bottom-middle-slot-wrapper"]}>
					<Phoner
						className={styles["middle-slot"]}
						leftSlot={showCurrentCallFilterEnabled ? <ToggleMenuButton /> : <ToggleMicrophoneButton />}
						middleSlot={
							showCurrentCallFilterEnabled ? (
								<Search />
							) : (
								<CurrentCallPhone
									number={
										currentSessionState?.phoneNumber || statePhonerIncomingCall?.context?.currentCall?.phoneNumber
											? convertPhone(
													currentSessionState?.phoneNumber ||
														statePhonerIncomingCall?.context?.currentCall?.phoneNumber,
												)
											: "Неизвестный номер"
									}
									timer={
										<Timer
											isStarted={sessionsDTO?.[0]?.status === SessionStatus.STARTED_CONVERSATION}
											isPaused={sessionsDTO?.[0]?.status === SessionStatus.HOLD}
											placeholder={getTimerPlaceholderByStatus(sessionsDTO[0])}
											startFrom={callDurationInSeconds}
										/>
									}
								/>
							)
						}
						rightSlot={
							showCurrentCallFilterEnabled ? (
								<>
									{showCallButton && <CurrentCallButton />}
									<BackToCurrentCallButton />
								</>
							) : (
								<ActionButtons leftSlot={<ToggleCallOnHold />} rightSlot={<CallControlWidget />} />
							)
						}
					/>
				</div>
			}
			bottomRightSlot={
				showCurrentCallFilterEnabled ? (
					<div className={styles["bottom-middle-left-slot-wrapper"]}>
						<Panel>
							<CallControlWidget />
						</Panel>
					</div>
				) : null
			}
			bottomSlot={
				animationDirection === ANIMATION_DIRECTIONS.BOTTOM ? (
					<>
						{hasIncomingCallAtCurrent && (
							<div className={styles["incoming-call-wrapper"]} style={{ marginTop: "8px" }}>
								<IncomingCallWidget withDivider={false} />
							</div>
						)}
						{!hasIncomingCallAtCurrent && <GetBottomSlot />}
					</>
				) : (
					<></>
				)
			}
		/>
	);
};
