import styles from './content-frame.module.scss'
import { ContentFrameProps } from '@common/content-frame/content-frame.types'
import cn from 'classnames'

export const ContentFrame = ({
  children,
  className,
  bgColor = 'transparent',
}: Partial<ContentFrameProps>) => {
  const contentFrameClasses = cn(styles['content-frame'], className)

  return (
    <div style={{ backgroundColor: bgColor }} className={contentFrameClasses}>
      {children}
    </div>
  )
}
