import { AnyObject, BasicInputValues } from '@global-types/utils-type'
import { defaultItemToString } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-select-input/default-item-to-string'

export const defaultObjectValueFormatter =
  (key = 'title') =>
  <T extends BasicInputValues | AnyObject>(item: T, items: T[]): string =>
    defaultItemToString(key)(item)

export const defaultStringValueFormatter =
  (keyValue = 'title', keyName = 'title') =>
  <T extends BasicInputValues | AnyObject>(item: T, items: T[]): string => {
    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && item[keyValue]) {
        return item[keyValue]
      }
      const it = items.find(
        (i) =>
          i !== undefined &&
          i !== null &&
          (typeof i === 'object' ? i[keyValue] === item : i === item),
      )

      return it !== undefined &&
        it !== null &&
        typeof it === 'object' &&
        it[keyName]
        ? it[keyName]
        : item
    }
    return ''
  }
