import { DropdownMenuItemSingleSelectProps } from '@common/multi-select/multi-select/dropdown-menu/dropdown-menu-item/dropdown-menu-item.types'
import React from 'react'
import styles from './dropdown-menu-item-single-select.module.scss'
import cn from 'classnames'
import { isObjectInMap } from '@utils/find-item'

export const DropdownMenuItemSingleSelect = <Item extends { [key: string]: any }>({
  item,
  isDataNested,
  isActive,
  itemProps,
  children,
  selectItem,
  selectedItems,
  handleCollapseNestedItems,
  openedNestedItems,
  id,
  disableGroupsWithoutChilds,
  allowOnlyChildSelection = false,
}: DropdownMenuItemSingleSelectProps<Item>) => {
  //@TODO : разобраться с подсветкой item при выборе с клавиатуры
  const isCollapsed = !isObjectInMap(openedNestedItems, item.id, item.title)
  const hasItems = !!item.items && !!item.items.length
  const canBeSelected = allowOnlyChildSelection ? (hasItems ? false : true) : true
  const singleSelectContainerClasses = cn(styles['single-select-container'], {
    [styles['single-select-container--active']]: isActive,
  })

  const dropdownItemTitleClasses = cn(styles['dropdown-item__title'], {
    [styles['dropdown-item__title--active']]: isActive,
  })

  const dropdownItemIconClasses = cn('icon', {
    'icon-cheveron-down': isCollapsed,
    'icon-cheveron-up': !isCollapsed,
    [styles['icon--active']]: isActive,
  })
  const contentWrapperClasses = cn(styles['content-wrapper'], {
    [styles['disable-pointer-events']]: disableGroupsWithoutChilds,
  })

  return (
    <div className={contentWrapperClasses}>
      <div
        className={singleSelectContainerClasses}
        onClick={(e) => {
          e.stopPropagation()
          canBeSelected && selectItem(item as Item)
        }}>
        <div className={styles['dropdown-item__container']} id={id}>
          {hasItems && item.items.length > 0 && isDataNested && (
            <i
              onClick={(e) => {
                e.stopPropagation()
                handleCollapseNestedItems(item as Item)
              }}
              className={dropdownItemIconClasses}
            />
          )}
          <div
            {...itemProps}
            ref={itemProps.ref}
            onClick={(e) => {
              e.stopPropagation()
              canBeSelected && selectItem(item as Item)
            }}
            onMouseMove={() => {}}
            onMouseDown={() => {}}
            className={styles['dropdown-item']}>
            <span className={dropdownItemTitleClasses}>{item.title}</span>
          </div>
        </div>
      </div>

      {hasItems && isDataNested && !isCollapsed && (
        <div
          style={{
            height: `${isCollapsed ? 0 : 'auto'}`,
            overflow: 'hidden',
          }}>
          {children}
        </div>
      )}
    </div>
  )
}
