import type { RequestCardProps } from "./request-card.types";
import { RequestCardActions, RequestCardContainer, RequestCardContent } from "@components/common";
import { RequestCardHeader } from "@components/request-card/header";
import { RequestCardLeftContent, RequestCardRightContent } from "@components/request-card/content";
import { ContentDivider } from "@common/content-divider";
import { useNavigate } from "react-router-dom";

import { FEED_CALL_RECORDS, FEED_COMMENTS_BY_ID, FEED_HISTORY_OF_REQUEST, FEED_INFO_INSTALLMENT } from "@constants/routes";

import styles from "@common/request-card/request-card.module.scss";
import { RequestCardActionButton } from "@common/request-card/request-card-actions";
import QualityPopup from "./quality-popup/quality-popup";
import { useComponentVisible } from "@/hooks";
import { useFullCodeRequest } from "@/contexts/scroll-pos";

export const RequestCard = ({ isLegalEntity = false, requestId, card, onHandleSetPage }: Partial<RequestCardProps>) => {
	const navigate = useNavigate();
	const [ref, qualityPopupOpen, setQualityPopupOpen] = useComponentVisible<HTMLDivElement>(false);

	const { handleSetRequestFullcodeId } = useFullCodeRequest();

	const isRequestInBlackList = card?.type_id === 2;
	const isRequestUr = !!card?.ur_org || !!card?.ur_fio || !!card?.ur_id;

	const requestStatus = () => {
		if (isRequestInBlackList) return "header-blacklist";
		if (isRequestUr) return "entity";
		return "header";
	};

	return (
		<RequestCardContainer>
			<div>
				<RequestCardHeader
					onHandleSetPage={onHandleSetPage}
					requestStatus={requestStatus()}
					title={`#${requestId}`}
					requestId={requestId}
					card={card}
				/>

				<ContentDivider type={requestStatus()} />
			</div>
			<div className={styles["main-card"]}>
				<RequestCardContent
					leftContent={<RequestCardLeftContent card={card} onHandleSetPage={onHandleSetPage} />}
					rightContent={<RequestCardRightContent card={card} isLegalEntity={isLegalEntity} />}
					qualityControlComments={card?.quality_controls}
					requestComments={card?.last_three_comments}
				/>

				<ContentDivider type={"vertical"} height={"273px"} />

				<RequestCardActions>
					<RequestCardActionButton
						icon={"icon-chat"}
						disabled={false}
						handleClick={() => {
							handleSetRequestFullcodeId(String(card?.fullcode));
							navigate(FEED_COMMENTS_BY_ID(String(card?.id)));
						}}
						tooltipAnchorId={"request-card-comments-btn"}
						tooltipText={"Комментарии"}
						badgeText={Number(card?.comments_count) > 0 ? String(card?.comments_count) : ""}
					/>
					<div className={styles["quality-block"]}>
						<RequestCardActionButton
							icon={"icon-chat-quality"}
							showTooltip={!qualityPopupOpen}
							disabled={false}
							handleClick={() => {
								setQualityPopupOpen((prev) => !prev);
							}}
							tooltipAnchorId={"request-card-history-btn"}
							tooltipText={"Комментарии ОКК"}
							badgeText={card?.quality_controls?.length ? `${card.quality_controls.length}` : ""}
						/>
						{qualityPopupOpen && card && <QualityPopup card={card} ref={ref} />}
					</div>
					<RequestCardActionButton
						icon={"icon-history"}
						disabled={false}
						handleClick={() => navigate(FEED_HISTORY_OF_REQUEST(card?.id as unknown as string, requestId as string))}
						tooltipAnchorId={"request-card-history-btn"}
						tooltipText={"История заявки"}
					/>
					<RequestCardActionButton
						icon={"icon-phone-incoming"}
						disabled={false}
						handleClick={() => {
							handleSetRequestFullcodeId(String(card?.fullcode));
							navigate(FEED_CALL_RECORDS(String(card?.id)));
						}}
						tooltipAnchorId={"request-card-call-records-btn"}
						tooltipText={"Записи звонков"}
					/>
					{card?.abilities?.installment?.view ? (
						<RequestCardActionButton
							icon={"icon-bx-wallet"}
							disabled={false}
							handleClick={() => {
								handleSetRequestFullcodeId(String(card?.fullcode));
								navigate(FEED_INFO_INSTALLMENT(card?.id as unknown as string, requestId as string));
							}}
							tooltipAnchorId={"request-card-bx-wallet-btn"}
							tooltipText={"Рассрочка"}
						/>
					) : null}
				</RequestCardActions>
			</div>
		</RequestCardContainer>
	);
};
