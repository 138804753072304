import classnames from 'classnames'
import styles from '../button.module.scss'

export const buttonClassNames = (
  type: string,
  size: string,
  active: boolean,
  fullWidth: boolean,
  isEmpty: boolean,
) => {
  return classnames(
    styles.button,
    styles[`button_${size}`],
    styles[`button_${type}`],
    {
      [styles[`button_${type}_active`]]: active,
      [styles.button_fullwidth]: fullWidth,
      [styles.button_squared]: isEmpty,
    },
  )
}

export const buttonContentContainerClassNames = (
  position: string | undefined,
  isLoading: boolean,
) => {
  return `
    ${styles[`button-content-container${position ? `_${position}` : ''}`]}
    ${isLoading ? styles['button-content-container_hidden'] : ''}
    `
}
export const buttonChildrenClassNames = (
  wrapText: boolean | undefined,
  overflowText: 'ellipsis' | 'inherit' | undefined,
) => {
  return `
    ${styles['button-children']}
    ${wrapText ? styles[`button-children-text_wrap`] : ''}
    ${overflowText ? styles[`button-children-text_${overflowText}`] : ''}
    `
}
