import { RequestCardContentItem } from "@common/request-card";
import { Title } from "@common/title";
import { Typography } from "@components/common";
import styles from "@components/request-card/request-card.module.scss";
import type { RequestCardLeftContentProps } from "@components/request-card/request-card.types";

import { RequestCardFinanceDropdown } from "@/components/common/request-card/request-card-content";
import { REQUEST_PAGE_BY_REQUEST_ID } from "@/constants/routes";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { useComponentVisible } from "@/hooks";
import { useUserProfileInfo } from "@/state/selector";
import { clearReklamaAndObjectText } from "@/utils/clear-text";
import { countMasters } from "@/utils/count-masters";
import { RequestInfoBlock } from "@constants/request";
import classNames from "classnames";
import cn from "classnames";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import MastersPopup from "./masters-popup/masters-popup";
import MastersPopupItem from "./masters-popup/masters-popup-item";

export const RequestCardLeftContent = ({ card }: Partial<RequestCardLeftContentProps>) => {
	const profile = useUserProfileInfo();
	const navigate = useNavigate();
	const { handleSetRequestFullcodeId } = useFullCodeRequest();
	const [showOnTop, setShowOnTop] = useState(false);

	const [ref, open, setOpen] = useComponentVisible(false, undefined, undefined, [
		styles["text-value-interactive"],
		"button-children",
		"icon",
	]);

	const formatDateCallVisit = (val: string) => {
		const splittedVal = val.split(" ");

		return `${splittedVal[0]} в ${splittedVal[1]}`;
	};
	const pointRequestCode = card?.point_request_link && /request_code=R(\d+)/.exec(card?.point_request_link);

	useLayoutEffect(() => {
		if (open && ref?.current) {
			const childRect = ref.current.getBoundingClientRect();
			setShowOnTop(childRect.bottom + 52 > window.innerHeight);

			return;
		}

		setShowOnTop(false);
	}, [open]);

	return (
		<>
			<Title as={"h5"}>Основная информация</Title>
			{card?.work !== null && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.typeOfWork}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={1} decoration={"none"} tag={4}>
						{clearReklamaAndObjectText(card?.work?.path)}
					</Typography>
				</RequestCardContentItem>
			)}
			{card?.obj !== null && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.object}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{card?.obj}
					</Typography>
				</RequestCardContentItem>
			)}
			{card?.is_guarantee && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.hasGuarantee}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						По гарантии
					</Typography>
				</RequestCardContentItem>
			)}
			{card?.duplicates && !!card?.duplicates?.length && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.dublicateFrom}
					</Typography>
					<div className={styles.dublicates}>
						{card?.duplicates?.map(({ id, fullcode }) => (
							<Typography
								key={id}
								className={styles["text-value-interactive"]}
								as={"caption"}
								weight={2}
								decoration={"none"}
								tag={4}
								onClick={() => {
									handleSetRequestFullcodeId(String(card?.fullcode));
									card?.duplicates ? navigate(REQUEST_PAGE_BY_REQUEST_ID(String(id))) : {};
								}}
							>
								{fullcode}
							</Typography>
						))}
					</div>
				</RequestCardContentItem>
			)}
			{card?.duplicated_of && Array.isArray(card?.duplicated_of) && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.dublicateBefore}
					</Typography>
					<div className={styles.dublicates}>
						{card?.duplicated_of?.map(({ id, fullcode }) => (
							<Typography
								key={id}
								className={styles["text-value-interactive"]}
								weight={2}
								as={"caption"}
								decoration={"none"}
								tag={4}
								onClick={() => {
									handleSetRequestFullcodeId(String(card?.fullcode));
									card?.duplicates ? navigate(REQUEST_PAGE_BY_REQUEST_ID(String(id))) : {};
								}}
							>
								{fullcode}
							</Typography>
						))}
					</div>
				</RequestCardContentItem>
			)}

			{(card?.tofirm !== null || card?.tomaster_summ !== null || card?.detal !== null) && !!profile?.available_menu?.fin && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.price}
					</Typography>
					<RequestCardFinanceDropdown isDefaultOpen={false} card={card} />
				</RequestCardContentItem>
			)}

			{card?.point_request_link && (
				<RequestCardContentItem className={styles["request-card_content__item-left"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.pointDirection}
					</Typography>
					{pointRequestCode && (
						<div className={styles["request-card-point"]}>
							<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
								В мастерскую
							</Typography>
							<a
								target={"_blank"}
								className={styles["text-value-interactive"]}
								href={`${card?.point_request_link}`}
								rel="noreferrer"
							>
								{pointRequestCode[1]}
							</a>
						</div>
					)}
				</RequestCardContentItem>
			)}
			<RequestCardContentItem className={styles["request-card_content__item-left"]}>
				<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
					{RequestInfoBlock.master}
				</Typography>
				{!card?.auto_selection && card?.masters?.length === 0 && (
					<Typography
						style={{ cursor: "unset" }}
						className={`${styles["text-value-manual"]} ${card.state_id === 1 ? styles["text-value-manual-disabled"] : ""}`}
						as={"caption"}
						weight={2}
						decoration={"none"}
						tag={4}
					>
						Необходим ручной подбор мастера
					</Typography>
				)}
				{card?.auto_selection && card?.masters?.length === 0 && (
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						Идёт определение мастера...
					</Typography>
				)}
				{!!card?.masters?.length && (
					<div style={{ position: "relative" }}>
						{card?.approved_master?.fio ? (
							<MastersPopupItem
								showDelivery={false}
								requestId={card.id}
								fullcodeId={card.fullcode}
								requestStatus={card.state_id}
								master={card.approved_master}
							/>
						) : (
							<div className={styles["masters-popup-wrapper"]}>
								<Typography
									className={`${styles["text-value-interactive"]}  ${
										!card?.abilities?.update ? styles["text-value-interactive-disabled"] : ""
									}`}
									as={"caption"}
									weight={2}
									decoration={"none"}
									tag={4}
									onClick={() => {
										setOpen(!open);
									}}
								>
									Доставка мастерам {countMasters(card?.masters)}
									{open ? (
										<i
											onClick={() => {
												setOpen(!open);
											}}
											className={classNames(styles["cheveron-icon"], "icon icon-cheveron-up")}
										/>
									) : (
										<i
											onClick={() => {
												setOpen(!open);
											}}
											className={classNames(styles["cheveron-icon"], "icon icon-cheveron-down")}
										/>
									)}
								</Typography>
								{open && card?.masters && (
									<div className={styles["masters-popup-wrapper"]}>
										<MastersPopup
											showApproveMaster={!card?.approved_master?.fio}
											className={cn(styles["masters-popup-wrapper__popup-hidden"], {
												[styles["masters-popup-wrapper__popup-openUp"]]: open && showOnTop,
												[styles["masters-popup-wrapper__popup"]]: open && !showOnTop,
											})}
											requestId={card.id}
											fullcodeId={card.fullcode}
											ref={ref}
											requestStatus={card.state_id}
											masters={card.masters}
										/>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</RequestCardContentItem>
			{card?.date_call && (
				<RequestCardContentItem className={styles["request-card-date"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.callScheduled}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{`${formatDateCallVisit(card?.date_call)} `}
						<span className={styles["request-card-comment-date"]}>(МСК)</span>
					</Typography>
				</RequestCardContentItem>
			)}
			{card?.date_visit && (
				<RequestCardContentItem className={styles["request-card-date"]}>
					<Typography className={styles["request-card-text"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{RequestInfoBlock.visitScheduled}
					</Typography>
					<Typography className={styles["request-card-text-value"]} as={"caption"} weight={2} decoration={"none"} tag={4}>
						{formatDateCallVisit(card?.date_visit)}
						<span className={styles["request-card-comment-date"]}>(МСК)</span>
					</Typography>
				</RequestCardContentItem>
			)}
		</>
	);
};
