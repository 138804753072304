export const BASE_PATH = '/'
export const REQUESTS = '/requests'

export const CREATE_REQUEST = 'create'
export const FEED = 'feed'
export const TEMPLATES = 'templates'
export const CALLS = 'calls'
export const CALL = 'call'
export const MESSAGE = 'message'
export const VIOLATION = 'violation'
export const VIOLATION_DISPATCHER = 'violation-dispatcher'
export const CALL_RECORDS = 'call-records'
export const EDIT = 'edit'
export const WORKS = 'works'
export const ADS = 'ads'
export const DISTRICTS = 'districts'
export const COMMENTS = 'comments'
export const ATTACHMENTS = 'attachments'
export const DUPLICATE = 'duplicate'
export const HISTORY = 'history'
export const MASTER_SELECTION = 'master-selection'
export const ADD_CALL = "add-call"
export const OKK_COMMENT = "okk-comment"
export const OKK_EMPLOYEE = "okk-employee"
export const OKK_TELE = "okk-tele"
export const AUTOANSWER = "autoanswer"
export const MISSED_CALLS = "missed-calls"
export const INFO_INSTALLMENT = "installment"
export const MASTER_SELECTION_ROUTES = 'master-selection/*'

export const CARD_MASTER = "card-master"
export const CARD_DISPETCHER = 'card-dispetcher'

export const OKK_COMMENT_BY_ID = "feed/okk-comment/:cardId"
export const OKK_EMPLOYEE_BY_ID = "feed/okk-employee/:cardId"
export const OKK_TELE_BY_ID = "feed/okk-tele/:cardId"

// export const CARD_MASTERS_BY_ID = `card-master/:masterId`

export const LINK_OKK_COMMENT = (id: number) => `feed/okk-comment/${id}`
export const LINK_OKK_EMPLOYEE = (id: number) => `feed/okk-employee/${id}`
export const LINK_OKK_TELE = (id: number) => `feed/okk-tele/${id}`

// Страница мастера по masterId
export const REQEST_MASTER_CARD_BY_ID = (id: string) => `${CARD_MASTER}/${id}`

// Открытие личной статистики диспетчера
export const REQEST_DISPETCHER_CARD_BY_ID = (id: string) => `${CARD_DISPETCHER}/${id}`

//ids
export const REQUEST_ID = ':requestId'
export const FULLCODE_ID = ':fullcodeId'
export const TEMPLATE_ID = ':templateId'
export const ATTACHMENT_ID = ':attachmentId'
export const COMMENT_ID = ':commentId'
export const MASTER_ID = ':masterId'
export const DISPATCHER_ID = ':dispatcherId'

// Страница заявки по requestId
export const REQUEST_PAGE_BY_REQUEST_ID = (requestId: string) => `${REQUESTS}/${requestId}`

// Дублирование заявки со страницы Заявки
export const REQUEST_PAGE_DUPLICATION = (requestId: string) => `${REQUEST_PAGE_BY_REQUEST_ID(requestId)}/duplicate`

export const FEED_REQUEST_ID = (requestId: string) => `${FEED}/${requestId}`

// Подтерждение удаления файла
export const ATTACHMENTS_ATTACHMENT_ID = (attachmentId: string) => `${ATTACHMENTS}/${attachmentId}`

// переходы через ленту карточек с главной страницы

// История заявки
export const FEED_HISTORY_OF_REQUEST = (requestId: string, fullcodeId: string) =>
  `${FEED_REQUEST_ID(requestId)}/${HISTORY}${fullcodeId ? `/${fullcodeId}` : ''}`

// Записи звонков
export const FEED_CALL_RECORDS = (requestId: string) => `${FEED_REQUEST_ID(requestId)}/${CALL_RECORDS}`

// Редактирование заявки
export const FEED_EDIT_REQUEST = (requestId: string, fullcodeId?: string) =>
  `${FEED_REQUEST_ID(requestId)}/${EDIT}${fullcodeId ? `/${fullcodeId}` : ''}`

//Редактирование заявки - Выбор мастера внутри карточки
export const EDIT_REQUEST_SELECTION_OF_MASTERS = (fullcodeId: string) => `${EDIT}/${MASTER_SELECTION}/${fullcodeId}`

// Редактирование заявки Выбор мастера
export const FEED_EDIT_REQUEST_SELECTION_OF_MASTERS = (requestId: string, fullcodeId: string) =>
  `${FEED_EDIT_REQUEST(requestId)}/${MASTER_SELECTION}/${fullcodeId}`

// Сообщение мастеру
export const FEED_MESSAGE_BY_REQUEST = (masterId: string, fullcodeId: string) =>
  `${FEED_REQUEST_ID(REQUEST_ID)}/${MESSAGE}/${masterId}/${fullcodeId}`

// Звонок мастеру
export const FEED_CALL_BY_REQUEST = (requestId: string) => `${FEED_REQUEST_ID(requestId)}/${CALL}`

//Оценка мастера
export const FEED_VIOLATION_BY_REQUEST = (masterId: string) => `${FEED_REQUEST_ID(REQUEST_ID)}/${VIOLATION}/${masterId}`

//Оценка диспетчера
export const FEED_VIOLATION_DISPATCHER_BY_REQUEST = (dispatcherId: string) =>
  `${FEED_REQUEST_ID(REQUEST_ID)}/${VIOLATION_DISPATCHER}/${dispatcherId}`

// Редактирование заявки Выбор типа работ
export const FEED_EDIT_REQUEST_SELECTION_OF_WORK_TYPE = (requestId: string) => `${FEED_EDIT_REQUEST(requestId)}/${WORKS}`

// Редактирование заявки Выбор рекламы
export const FEED_EDIT_REQUEST_SELECTION_OF_ADS = (requestId: string) => `${FEED_EDIT_REQUEST(requestId)}/${ADS}`

// Комментарии по заявке
export const FEED_COMMENTS_BY_ID = (requestId: string) => `${FEED_REQUEST_ID(requestId)}/${COMMENTS}/`

// Дублирование заявки
export const FEED_DUPLICATION_OF_REQUEST = (requestId: string, fullcodeId: string) =>
  `${FEED_REQUEST_ID(requestId)}/${DUPLICATE}${fullcodeId ? `/${fullcodeId}` : ''}`

// Информация о рассрочке
export const FEED_INFO_INSTALLMENT = (requestId: string, fullcodeId: string) =>
  `${FEED_REQUEST_ID(requestId)}/${INFO_INSTALLMENT}${fullcodeId ? `/${fullcodeId}` : ''}`

export const SELECTION_OF_WORK_TYPE = `${CREATE_REQUEST}/${WORKS}`
export const SELECTION_OF_ADS = `${CREATE_REQUEST}/${ADS}`
export const SELECTION_OF_DISTRICTS = `${CREATE_REQUEST}/${DISTRICTS}`
export const SELECTION_OF_MASTERS = `${CREATE_REQUEST}/${MASTER_SELECTION}`

export const FILTER_TEMPLATES_SAVE = (templateId: string) => `${TEMPLATES}/${templateId}/save-filter-template`

export const FILTER_TEMPLATES_DESTROY = (templateId: string) => `${TEMPLATES}/${templateId}/filter-destroy`
export const ADD_CALL_SELECTION_OF_WORK_TYPE = `${ADD_CALL}/${WORKS}`
