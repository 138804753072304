import { PhoneActionsDropdown } from "@/components/phone-actions-dropdown/phone-actions-dropdown";
import {
	type RequestsBlackListApiResponse,
	coreApi,
	useClientCallMutation,
	useProfileQuery,
	useRequestsBlackListStoreMutation,
	useRequestsRemovePhoneFromBlackListStoreMutation,
} from "@/state/api";
import { PhoneActionsDropdownList } from "@components/phone-actions-dropdown/phone-actions-dropdown-list";

import { INCLUDE_PHONER } from "@/constants/env";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { addSnack } from "@/eventbus/events/snackbar";
import { PhonerEvents, type PhonerEventsT } from "@/phoner";
import { SmsToClientModal } from "@components/modals";
import type { PhoneActionsDropdownWrapperProps, PhoneItem } from "@components/phone-actions-dropdown/phone-ations-dropdown.types";
import { clearPhoneForRequest } from "@utils/convert-phone";
import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from "react";

export const PhoneActionsDropdownWrapper = ({ requestId, phones, fullcodeId, clientId }: PhoneActionsDropdownWrapperProps) => {
	const [phoneItems, setPhoneItems] = useState<PhoneItem[]>([]);
	const { handleSetRequestFullcodeId } = useFullCodeRequest();
	const memoizedPhones = useMemo(() => phones, [phones]);
	const [showSmsToClientModal, setShowSmsToClientModal] = useState<boolean>(false);
	const { data: user } = useProfileQuery();

	const [call, { isError: isCallError, originalArgs, isSuccess: isCallSuccess }] = useClientCallMutation();

	const [clientPhone, setClientPhone] = useState<string>("");

	const [addToBlackList, { isLoading: isAddToBlackListLoading }] = useRequestsBlackListStoreMutation();

	const [removeFromBlackList, { isLoading: isRemoveFromBlackListLoading }] = useRequestsRemovePhoneFromBlackListStoreMutation();

	const isPhonerAvailable = user?.data?.inner_phone !== "999" && user?.data?.permissions?.CAN_USE_PHONER && INCLUDE_PHONER;

	const handleCallToClient = (phone: string) => {
		if (!isPhonerAvailable) {
			call({ requestId: Number(requestId), requestMasterCallCall: { phone } });
			return;
		}

		const event = new CustomEvent<PhonerEventsT.MakeCallEventT>(PhonerEvents.MAKE_CALL_EVENT_NAME, {
			detail: {
				entityType: "request",
				app: "basa",
				entityId: requestId,
				calledPhone: phone,
				callerId: user?.data?.id || 0,
				calledId: clientId,
				calledType: "client",
				callerPhone: user?.data?.inner_phone || "",
				callerType: "user",
			},
		});
		window.dispatchEvent(event);
	};

	useEffect(() => {
		if (isCallError) {
			addSnack({
				type: "error",
				eventName: "fetch-requests-error",
				text: "Не удалось позвонить.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleCallToClient(originalArgs?.requestMasterCallCall?.phone ?? ""),
			});
		}
		if (isCallSuccess) {
			addSnack({
				type: "default",
				eventName: "fetch-requests-error",
				text: "Ожидайте, звонок скоро начнется.",
			});
		}
	}, [isCallError, isCallSuccess]);

	const [getBlackListInfoByPhone, { isLoading: isBlackListInfoByPhoneLoading, isFetching: isBlackListInfoByPhoneFetching }] =
		coreApi.endpoints.requestsBlackListInfo.useLazyQuery();

	const checkPhone = (phone: string, data: RequestsBlackListApiResponse) => {
		const { data: blackListInfoData } = data;
		if (Array.isArray(blackListInfoData) && blackListInfoData.length) {
			const phoneItem = {
				phone: blackListInfoData[0].phone,
				isPhoneInBlackList: blackListInfoData[0].enabled === "1",
				blacklistId: blackListInfoData[0].id,
				description: blackListInfoData[0].description,
			};

			setPhoneItems((prev) => [...prev.map((prevPhone) => (prevPhone.phone === phone ? phoneItem : prevPhone))]);
			return;
		}
	};

	const handleInitialCheckPhone = (phone: string) => {
		const phoneItem = {
			phone: phone,
			isPhoneInBlackList: false,
			blacklistId: undefined,
			description: "",
		};

		setPhoneItems((prev) => [...prev, phoneItem]);
		return;
	};

	const handleGetBlackListInfoByPhone = (phone: string) => {
		getBlackListInfoByPhone({
			phone: clearPhoneForRequest(phone),
		})
			.unwrap()
			.then((fulfilled) => {
				checkPhone(phone, fulfilled);
			})
			.catch((rejected) => console.warn(rejected));
	};

	const handleAddPhoneToBlackList = ({ phone, requestId }: { phone: string; requestId: number }) => {
		addToBlackList({
			request_id: requestId,
			requestsBlackListsStore: {
				phone: clearPhoneForRequest(phone),
			},
		});
	};

	const handleRemoveFromBlackList = ({ blackListId }: { blackListId: number }) => {
		removeFromBlackList({
			blacklist_id: blackListId,
		});
	};

	useEffect(() => {
		if (memoizedPhones.length) {
			setPhoneItems([]);
			memoizedPhones.filter((phone) => typeof phone === "string").map((phone) => handleInitialCheckPhone(String(phone)));
		}
	}, [memoizedPhones]);

	if (!phones.length) {
		return <></>;
	}

	return (
		<>
			{Array.isArray(phoneItems) &&
				!!phoneItems.length &&
				phoneItems.map((item) => {
					return (
						<PhoneActionsDropdown
							key={`${item.phone}${item.blacklistId}`}
							handleGetBlackListInfoByPhone={handleGetBlackListInfoByPhone}
							phone={item}
							renderActionsList={(setOpenPhoneActions: Dispatch<SetStateAction<boolean>>) => {
								return (
									<PhoneActionsDropdownList
										isBlackListInfoByPhoneLoading={isBlackListInfoByPhoneLoading || isBlackListInfoByPhoneFetching}
										isRemoveFromBlackListLoading={isRemoveFromBlackListLoading}
										isAddToBlackListLoading={isAddToBlackListLoading}
										isPhoneInBlackList={item.isPhoneInBlackList}
										handleAddToBlackList={() => {
											handleSetRequestFullcodeId(String(fullcodeId));
											handleAddPhoneToBlackList({
												phone: String(item.phone),
												requestId: Number(requestId),
											});
											setOpenPhoneActions((isComponentVisible) => !isComponentVisible);
										}}
										handleRemoveFromBlackList={() => {
											if (item.blacklistId !== null && item.blacklistId !== undefined) {
												handleSetRequestFullcodeId(String(fullcodeId));
												handleRemoveFromBlackList({
													blackListId: item.blacklistId,
												});
												setOpenPhoneActions((isComponentVisible) => !isComponentVisible);
											}
										}}
										handleCall={() => {
											handleSetRequestFullcodeId(String(fullcodeId));
											handleCallToClient(String(item.phone));
										}}
										handleSMS={() => {
											// handleSetRequestFullcodeId(String(fullcodeId))
											setClientPhone(item.phone);
											setShowSmsToClientModal(true);
										}}
									/>
								);
							}}
						/>
					);
				})}

			{showSmsToClientModal && clientPhone && (
				<SmsToClientModal
					closeOnBlackoutClick
					phone={clientPhone}
					fullcodeId={fullcodeId}
					requestId={requestId}
					onClose={() => {
						setShowSmsToClientModal(false);
						setClientPhone("");
					}}
				/>
			)}
		</>
	);
};
