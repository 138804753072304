import { useActor } from "@xstate/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useGlobalPhonerState } from "../../app/providers/xstate-provider";
import { Typography } from "../../shared";
import { PhonerContainer, SnackControlContainer } from "../../shared/components";
import { AudioPlayer } from "../../shared/components/audio-player/ui/audio-payer";
import { SuccessConnectionIcon } from "../../shared/components/success-connection-icon/success-connection-icon";
import { usePhoner } from "../../shared/contexts/phoner/phoner";
import { CurrentCallWidget, SecondLineWidget, SnackControlWidget } from "../../widgets";
import { PhonerWidget } from "../../widgets/phoner/ui/phoner";
import styles from "./phoner.module.scss";
import { useMasterTab } from "../../shared/contexts/master-tab/master-tab";
import { SessionStatus, useSessionContext } from "../../shared/contexts/tab-session-context/tab-session-context";
import {
	CHANGE_AUDIO_VOLUME,
	SECOND_LINE_CALL_ANSWERED,
	SET_OUTGOING_CALL_INFO,
	START_SECOND_LINE,
	UPDATE_SESSION_STATUS,
} from "../../shared/event-bus/types/event-action.types";
import { useEventBus } from "../../shared/contexts/event-bus/event-bus";
import { PhonerEvents } from "../../shared/phoner-events";
import type * as PhonerEventsT from "../../shared/phoner-events";
import { useSharedWorker } from "../../shared/contexts/shared-worker";
import { formatPhoneNumber } from "../../features/fast-search/call/call";
import { nanoid } from "nanoid";
import { useFormMachine } from "../../app/machines/form-factory-machine/useFormMachine";
import { DraggableWrapper } from "../../shared/components/draggable-wrapper";
import { type IHotKey, useHotKey } from "../../shared/hooks/useHotKey";

const START_DRAGGABLE_WRAPPER_POSITION = 8;
const BOTTOM_OFFSET = 0;

export const Phoner = () => {
	const {
		phonerMenuService,
		fastSearchService,
		phonerTreyService,
		phonerIncomingCallService,
		phonerSecondLineService,
		phonerSnackControlService,
		phonerConnectionService,
		phonerDraggingService,
		phonerSettingsService,
	} = useGlobalPhonerState();

	const [statePhonerSnackControl] = useActor(phonerSnackControlService);
	const [statePhonerIncomingCall, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);
	const [stateMenu] = useActor(phonerMenuService);
	const [statePhonerSettings, sendPhonerSettingsEvents] = useActor(phonerSettingsService);
	const [statePhonerSecondLine, sendPhonerSecondLine] = useActor(phonerSecondLineService);
	const audioVolume = statePhonerSettings?.context?.audioVolume;
	const showIncomingCall = statePhonerIncomingCall.matches("call received");
	const showCurrentCall = statePhonerIncomingCall.matches("current call");
	const showSecondLine = !statePhonerSecondLine.matches("idle") && !statePhonerSecondLine.matches("second call received");
	const twoLineConnected = statePhonerSecondLine.matches("two lines connected");
	const showSuccessConnectionModal = statePhonerSecondLine.matches("show success connection modal");
	const showSettings = stateMenu.matches("menu opened.settings");
	const [phonerConnectionState] = useActor(phonerConnectionService);
	const isConnectionEstablished = phonerConnectionState.context.isConnectionEstablished;
	const [, send] = useActor(phonerMenuService);
	const [, sendPhonerTreyEvent] = useActor(phonerTreyService);
	const [state, sendFastSearchEvents] = useActor(fastSearchService);
	const [, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);
	const { onChange, resetField } = useFormMachine({ state, send: sendFastSearchEvents });
	const [, sendSnackControlEvents] = useActor(phonerSnackControlService);

	const showSnackControl = statePhonerSnackControl.matches("show snackbar");
	const { acceptCall, makeCall, setSessionHold, currentActiveWidget, endCall } = usePhoner();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { sendMessage } = useSharedWorker();

	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const { on } = useEventBus();

	const [phonerDraggingState, setPhonerDraggingState] = useActor(phonerDraggingService);

	useEffect(() => {
		if (showSuccessConnectionModal) {
			sendPhonerIncomingCallEvents("reset current call after connect lines");
		}
	}, [showSuccessConnectionModal]);

	useEffect(() => {
		const unsubscribeSecondLineCallAnswered = on(SECOND_LINE_CALL_ANSWERED, (payload) => {
			if (!isCurrentTabMasterTab) {
				sendPhonerSecondLine("success");
				setSessionsDTO((prevSession) =>
					prevSession.map((session) => (session.sessionId === payload.sessionId ? { ...session, ...payload } : session)),
				);
			}
		});

		return () => unsubscribeSecondLineCallAnswered();
	}, [isCurrentTabMasterTab]);

	useEffect(() => {
		window.addEventListener(PhonerEvents.MAKE_CALL_EVENT_NAME, handleCallFromExternalSource as EventListener);

		return () => {
			window.removeEventListener(PhonerEvents.MAKE_CALL_EVENT_NAME, handleCallFromExternalSource as EventListener);
		};
	}, [sessionsDTO, isCurrentTabMasterTab, isConnectionEstablished, sendMessage]);

	const handleCallFromExternalSource = (e: CustomEvent<PhonerEventsT.MakeCallEventT>) => {
		const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);

		if (!isConnectionEstablished || !isReadyToSecondCall) return;

		const formattedCalledPhone = formatPhoneNumber(e.detail.calledPhone);
		const isAlreadyAtCall = sessionsDTO.some((session) => formatPhoneNumber(session.phoneNumber || "") === formattedCalledPhone);

		if (sessionsDTO.length > 1 || isAlreadyAtCall) {
			return;
		}

		if (!sessionsDTO.length) {
			if (!isCurrentTabMasterTab) {
				sendMessage({
					eventName: SET_OUTGOING_CALL_INFO,
					eventPayload: { phoneNumber: formattedCalledPhone },
				});
			}

			onChange(formattedCalledPhone, "fast-search");

			sendPhonerIncomingCallEvents({
				type: "outcoming call",
				callInfo: {
					id: nanoid(),
					phoneNumber: formattedCalledPhone,
				},
				makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber), e.detail),
			});

			resetField("", "fast-search");
		} else {
			if (isCurrentTabMasterTab) {
				setSessionHold();
				setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));

				sendMessage({
					eventName: START_SECOND_LINE,
					eventPayload: { phoneNumber: formattedCalledPhone },
				});
			} else {
				sendMessage({
					eventName: START_SECOND_LINE,
					eventPayload: { phoneNumber: formattedCalledPhone },
				});

				setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
			}

			onChange(formattedCalledPhone, "fast-search");

			sendPhonerSecondLineEvents({
				type: "start second line",
				callInfo: {
					id: nanoid(),
					phoneNumber: formattedCalledPhone,
					currentPhone: statePhonerIncomingCall.context.currentCall?.phoneNumber,
				},
				makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber), e.detail),
			});

			resetField("", "fast-search");
		}
		sendPhonerTreyEvent("close menu");
		send("close menu");
	};

	useEffect(() => {
		if (currentActiveWidget) {
			const currentShiftX = phonerDraggingState.context.position.x;
			const currentShiftY = phonerDraggingState.context.position.y;

			setPhonerDraggingState({ type: "positionChanged", value: { x: currentShiftX, y: currentShiftY } });
		}
	}, [currentActiveWidget]);

	useEffect(() => {
		const unsubscribeSessionUpdate = on(UPDATE_SESSION_STATUS, (payload) => {
			setSessionsDTO((sessions) =>
				sessions.map((session) => (session.sessionId === payload.sessionId ? { ...session, ...payload } : session)),
			);
		});

		return () => unsubscribeSessionUpdate();
	}, [sessionsDTO]);

	useEffect(() => {
		sendMessage({
			eventName: CHANGE_AUDIO_VOLUME,
			eventPayload: { volumeValue: audioVolume },
		});
	}, [audioVolume]);

	useEffect(() => {
		const unsubscribeOnChangeAudioVolume = on(CHANGE_AUDIO_VOLUME, () => {
			const { audioVolume } = JSON.parse(localStorage.getItem("settings") || "");

			sendPhonerSettingsEvents({ type: "change audio", value: audioVolume });
			sendPhonerSettingsEvents("finish changing");
		});
		return () => unsubscribeOnChangeAudioVolume();
	}, []);

	const acceptCallHotKey: IHotKey = {
		keys: ["Q"],
		alt: true,
	};

	const declineCallHotKey: IHotKey = {
		keys: ["W"],
		alt: true,
	};

	const increaseVolumeHotKey: IHotKey = {
		ctrl: true,
		keys: ["ArrowUp"],
	};

	const decreaseVolumeHotKey: IHotKey = {
		ctrl: true,
		keys: ["ArrowDown"],
	};

	const handleHotKeyIncreaseVolume = () => {
		sendSnackControlEvents("show");
		sendSnackControlEvents("audio down");

		sendPhonerSettingsEvents({
			type: "change audio",
			value: audioVolume >= 100 ? 100 : audioVolume + 1,
		});

		sendPhonerSettingsEvents("finish changing");
		sendSnackControlEvents("hide snackbar");
	};

	const handleHotKeyDecreaseVolume = () => {
		sendSnackControlEvents("show");
		sendSnackControlEvents("audio down");

		sendPhonerSettingsEvents({
			type: "change audio",
			value: audioVolume > 0 ? audioVolume - 1 : 0,
		});

		sendPhonerSettingsEvents("finish changing");
		sendSnackControlEvents("hide snackbar");
	};

	const handleHotKeyAcceptCall = () => {
		const ringingSession = sessionsDTO.find((session) => session.status === SessionStatus.RINGING);

		if (!ringingSession) return;

		acceptCall(ringingSession?.sessionId || "");
	};

	const handleHotKeyDeclineCall = () => {
		const ringingSession = sessionsDTO.find((session) => session.status === SessionStatus.RINGING);
		const activeSession = sessionsDTO.find((session) => session.status !== SessionStatus.HOLD);
		const sessionId = ringingSession?.sessionId || activeSession?.sessionId || "";

		if (!sessionId) return;

		endCall(sessionId);
	};

	const handleHotkeyAcceptCallFromExtension = () => {
		if (!isCurrentTabMasterTab) return;

		handleHotKeyAcceptCall();
	};

	const handleHotkeyDeclineCallFromExtension = () => {
		if (!isCurrentTabMasterTab) return;

		handleHotKeyDeclineCall();
	};

	useHotKey(handleHotKeyAcceptCall, acceptCallHotKey);
	useHotKey(handleHotKeyDeclineCall, declineCallHotKey);
	useHotKey(handleHotKeyIncreaseVolume, increaseVolumeHotKey);
	useHotKey(handleHotKeyDecreaseVolume, decreaseVolumeHotKey);

	useEffect(() => {
		window.addEventListener("answer", handleHotkeyAcceptCallFromExtension);
		window.addEventListener("decline", handleHotkeyDeclineCallFromExtension);

		return () => {
			window.removeEventListener("answer", handleHotkeyAcceptCallFromExtension);
			window.removeEventListener("decline", handleHotkeyDeclineCallFromExtension);
		};
	}, [isCurrentTabMasterTab, sessionsDTO]);

	const leftRestriction = (currentActiveWidget?.clientWidth ?? 0) / 2;
	const topRestriction = -(window.innerHeight - (currentActiveWidget?.clientHeight ?? 0) - START_DRAGGABLE_WRAPPER_POSITION);
	const rightRestriction = window.innerWidth - (currentActiveWidget?.offsetWidth ?? 0) / 2;
	const bottomRestriction = BOTTOM_OFFSET;
	const handle = ".canDraggable";

	return (
		<>
			<DraggableWrapper
				bounds={{ left: leftRestriction, top: topRestriction, right: rightRestriction, bottom: bottomRestriction }}
				handle={handle}
				position={phonerDraggingState.context.position}
				axis="both"
			>
				<div style={{ position: "absolute", bottom: 0, zIndex: 1000 }}>
					<AnimatePresence>
						{(twoLineConnected || showSuccessConnectionModal) && (
							<motion.div
								key="modal"
								className={styles["success-modal"]}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.25 }}
							>
								<SuccessConnectionIcon />
								<Typography as={"paragraph"} decoration={"none"} tag={3} weight={500} className={styles["modal-title"]}>
									Успешное соединение
								</Typography>
							</motion.div>
						)}
						{showSecondLine && !showSuccessConnectionModal ? (
							<motion.div key={"uniqAnimate"}>
								<PhonerContainer>{showSecondLine && <SecondLineWidget />}</PhonerContainer>
							</motion.div>
						) : null}

						{(showIncomingCall || showCurrentCall) && (
							<motion.div
								className={styles["call-wrapper"]}
								key="callsInfo"
								animate={{ opacity: 1, y: 0 }}
								style={{
									y: 10,
								}}
								exit={{ opacity: 0, y: 10, transition: { duration: 0.2 } }}
							>
								{!showSettings &&
									showSnackControl &&
									createPortal(
										<SnackControlContainer>
											<SnackControlWidget />
										</SnackControlContainer>,
										document.body,
									)}
								<AnimatePresence>
									{showCurrentCall && !showSecondLine && (
										<motion.div
											key="currentCall"
											initial={{ scaleX: 0.7 }}
											animate={{ opacity: 1, scaleX: 1 }}
											className={styles["current-call"]}
											transition={{ duration: 0.2 }}
											exit={{ opacity: 0, scaleX: 0.7 }}
										>
											<PhonerContainer>
												<CurrentCallWidget />
											</PhonerContainer>
										</motion.div>
									)}
								</AnimatePresence>
							</motion.div>
						)}
						<>
							{!showSettings &&
								showSnackControl &&
								createPortal(
									<SnackControlContainer>
										<SnackControlWidget />
									</SnackControlContainer>,
									document.body,
								)}
							{!showSuccessConnectionModal && (
								<PhonerContainer>
									<PhonerWidget />
								</PhonerContainer>
							)}
							<AudioPlayer />
						</>
					</AnimatePresence>
				</div>
			</DraggableWrapper>
		</>
	);
};
