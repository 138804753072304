import { AnyObject } from '@global-types/utils-type'

export const playlistInitialState: PlaylistInitialStateType = {
  isAudioPlaying: false,
  currentCdrId: null,
  currentIndex: null,
  currentPlaylist: null,
  errorList: [],
  playlists: {},
  preventPlaying: false,
}

export interface PlaylistInitialStateType {
  isAudioPlaying: boolean
  currentIndex: number | null
  currentCdrId: string | null
  currentPlaylist: string | null
  errorList: string[]
  playlists: {
    [key: string]: Iplaylist[]
  } | null
  preventPlaying: boolean
}

export interface Iplaylist {
  id: string
  src: AnyObject[]
  playingState: PlayingState
  idx: any
  call_time: any
  called: any
  caller: any
  cdr_id: any
}

export interface IaudioTracks {
  id: string
  call_time: any
  called: any
  caller: any
  cdr_id: any
}

export interface InewPlaylist {
  requestId: Iplaylist[] | null
  // playlist: Iplaylist[] | null
}

type PlayingState = 'play' | 'pause' | 'stop'

export interface IAudioTrack {
  id?: string
  src?: string
  playingState: PlayingState
}
