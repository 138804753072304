import { FC } from 'react'
import styles from './info-card-item-link.module.scss'
import { IInfoCardItemLinkProps } from './info-card-item-link.types'

export const InfoCardItemLink: FC<IInfoCardItemLinkProps> = ({
  children,
  href,
}): JSX.Element => {
  return (
    <a href={href} className={styles.info_block_link}>
      {children}
    </a>
  )
}
