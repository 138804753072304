import styles from './content-label.module.scss'
import { ContentLabelProps } from './content-label.types'
import cn from 'classnames'

export const ContentLabel = ({
  children,
  type = 'warn',
  className,
  customBgColor,
}: Partial<ContentLabelProps>) => {
  const labelClasses = cn(
    styles['content-label'],
    {
      [styles['content-label_success']]: type === 'success',
      [styles['content-label_warn']]: type === 'warn',
      [styles['content-label_danger']]: type === 'danger',
    },
    className,
  )

  return (
    <div style={{ backgroundColor: customBgColor }} className={labelClasses}>
      {children}
    </div>
  )
}
