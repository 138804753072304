import { Button } from "@/components/button";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { Calendar } from "@/components/calendar";
import { Title } from "@/components/common";
import Accordion from "@/components/common/accordion/accordion";
import { InputErrorContainer } from "@/components/common/input-error-container";
import { InputDefault, MultiSelect } from "@/components/common/multi-select";
import { Checkbox } from "@/components/form/checkbox";
import Textfield from "@/components/form/textfield/textfield";
import { containsNumber } from "@/components/headers/requests-header/request-header-filters/filter-inputs/filter-period/filter-period";
import { generateDateString } from "@/components/headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import { MONTH_MAX_INDEX } from "@/constants/date";
import { addSnack } from "@/eventbus/events/snackbar";
import { UISizes } from "@/global-types/mods.types";
import {
	type QualityControlDenyReason,
	type QualityControlIndexResource,
	type QualityControlMasterGrade,
	type RequestsIndexApiResponse,
	type ResourcesRequestIndex,
	type ResourcesRequestIndexDispatcher,
	type ResourcesRequestMasterShow,
	type TypeOfQualityAssessment,
	type ViolationFeedResource,
	coreApi,
	useMastersFeedQuery,
	useQualityControlsPresetsMasterGradesQuery,
	useQualityControlsQuestionGroupsIndexQuery,
	useQualityControlsViolationsFeedQuery,
	useRequestsQualityControlsStoreMutation,
} from "@/state/api";
import { useAppDispatch, useAppSelector } from "@/state/selector";
import cn from "classnames";
import { format, isValid, parse } from "date-fns";
import { isEmpty } from "lodash";
import type React from "react";
import { type ChangeEvent, Fragment, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import type { IOkkTeleCommonFormType } from "../../okk-comment-modal/okk-comment.types";
import Question from "../../okk-comment-modal/okk-components/question";
import { compareDateWithCurrent } from "../../okk-comment-modal/okk-steps/okk-common";
import styles from "../okk-tele.module.scss";

const OkkTeleCommon = ({
	okkTeleCommentFormState,
	setOkkTeleCommentFormState,
	className,
	masterOnRequest,
	isRequestShowQueryLoading,
}: {
	okkTeleCommentFormState: IOkkTeleCommonFormType;
	setOkkTeleCommentFormState: React.Dispatch<React.SetStateAction<IOkkTeleCommonFormType>>;
	masterOnRequest?: ResourcesRequestMasterShow;
	dispatcherOnRequest?: ResourcesRequestIndexDispatcher;
	isRequestShowQueryLoading: boolean;
	className?: string;
}) => {
	const params = useParams();
	const navigate = useNavigate();
	const [createOKK, { isSuccess, isError, error }] = useRequestsQualityControlsStoreMutation();

	const { data: questionsData } = useQualityControlsQuestionGroupsIndexQuery({
		type: "telemarketing",
	});

	const { data: mastersData, isLoading: mastersLoading } = useMastersFeedQuery();
	const { data: gradesData, isLoading: gradesLoading } = useQualityControlsPresetsMasterGradesQuery();
	const { data: violationsData, isLoading: violationsLoading } = useQualityControlsViolationsFeedQuery({
		type: 1,
	});
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!okkTeleCommentFormState.control) {
			setOkkTeleCommentFormState((prev) => ({ ...prev, inputDateValue: "" }));
			return;
		}
		if (!okkTeleCommentFormState.inputDateValue) {
			const currentDate = new Date();
			setOkkTeleCommentFormState((prev) => ({
				...prev,
				inputDateValue: format(currentDate, "dd.MM.yyyy HH:mm"),
			}));
		}
	}, [okkTeleCommentFormState.control]);

	useEffect(() => {
		if (okkTeleCommentFormState.inputDateValue) {
			if (!okkTeleCommentFormState.inputDateValue || !containsNumber(okkTeleCommentFormState.inputDateValue)) return;

			const parsedDateParts = okkTeleCommentFormState.inputDateValue.split(".").flatMap((chunk, index, arr) => {
				return index === arr.length - 1 ? chunk.split(" ") : chunk;
			});

			const [day, month, year, time] = parsedDateParts;
			const daysInSelectedMonth = new Date(Number(year), Number(month), 0).getDate();

			if (Number(day) > daysInSelectedMonth || Number(month) > MONTH_MAX_INDEX) {
				const validatedMonth = Number(month) > MONTH_MAX_INDEX ? MONTH_MAX_INDEX : month;
				const validatedDay =
					Number(month) > MONTH_MAX_INDEX ? new Date(Number(year), Number(MONTH_MAX_INDEX), 0).getDate() : daysInSelectedMonth;
				const ISOFormattedDateString = `${year}-${validatedMonth}-${validatedDay}T${time?.includes("_") ? "12:00" : time}:00`;
				const isoFormattedDate = new Date(ISOFormattedDateString);
				setOkkTeleCommentFormState((prev) => ({
					...prev,
					inputDateValue: format(isoFormattedDate, "dd.MM.yyyy HH:mm"),
					date: isoFormattedDate,
				}));
				return;
			}

			const parsedDate = parse(okkTeleCommentFormState.inputDateValue, "dd.MM.yyyy HH:mm", new Date());
			const currentDate = new Date();

			if (!isValid(parsedDate)) {
				setOkkTeleCommentFormState((prev) => ({
					...prev,
					inputState: "error",
					isInvalid: true,
				}));
				return;
			}
			setOkkTeleCommentFormState((prev) => ({
				...prev,
				isInvalid: false,
				inputState: "default",
			}));
			if (compareDateWithCurrent(parsedDate, currentDate) > -1) {
				setOkkTeleCommentFormState((prev) => ({ ...prev, date: parsedDate }));
			} else {
				setOkkTeleCommentFormState((prev) => ({
					...prev,
					inputDateValue: format(currentDate, "dd.MM.yyyy HH:mm"),
				}));
			}
		}
	}, [okkTeleCommentFormState.inputDateValue]);

	useEffect(() => {
		if (isSuccess) {
			navigate("../");
		}
	}, [isSuccess]);

	const requestsStore = useAppSelector<Partial<RequestsIndexApiResponse>>((state) => {
		const data = state.api.queries.requestsIndex?.data as RequestsIndexApiResponse;
		if (data) return data;
		return {};
	});

	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const handleChangeCache = (newComment: QualityControlIndexResource) => {
		dispatch(
			coreApi.util.updateQueryData("requestsIndex", {}, () => {
				const changeStore = [...(requestsStore.data || [])];
				const newStore = changeStore.map((item: ResourcesRequestIndex) => {
					if (item?.id !== Number(params.cardId)) return item;

					const newComments = {
						...item,
						quality_controls: item.quality_controls?.length ? [...item.quality_controls, newComment] : [newComment],
					};

					return newComments;
				}) as QualityControlIndexResource[];

				return { ...requestsStore, data: newStore };
			}),
		);
	};

	const changeAnswer = (id: number, answer_type: number | undefined, is_special: boolean, special_value: string | number) => {
		const answer = {
			answer_type: is_special ? undefined : answer_type,
			is_special: is_special,
			question_id: id,
			special_value: is_special ? special_value : "",
		};
		let newAnswers = okkTeleCommentFormState.answers.map((item) => {
			return item.question_id === id ? answer : item;
		});

		if (!newAnswers.find((item) => item.question_id === id)) {
			newAnswers.push(answer);
		}

		newAnswers = newAnswers.filter((item) => (item.is_special ? item.special_value : item.answer_type && !item.is_special));
		setOkkTeleCommentFormState((prev) => ({ ...prev, answers: newAnswers }));
	};

	const onSubmit = () => {
		if (params.cardId || params.requestId) {
			const data = {
				requestsRequestQualityControlStore: {
					type: "telemarketing" as TypeOfQualityAssessment,
					deny_reason: "deny_01" as QualityControlDenyReason,
					master_id: okkTeleCommentFormState.master?.id,
					request_master_rating: okkTeleCommentFormState.masterViolation?.map((item) => item.id) as string[],
					request_master_grade: okkTeleCommentFormState.masterGrade?.id as QualityControlMasterGrade,
					control: okkTeleCommentFormState.control,
					comment: okkTeleCommentFormState.comment,
					answers: okkTeleCommentFormState.answers.filter((item) => !!item.answer_type || !!item.special_value),
					control_date: okkTeleCommentFormState.control ? okkTeleCommentFormState.inputDateValue : undefined,
				},
				requestId: Number(params.cardId ?? params.requestId),
			};
			createOKK(data)
				.unwrap()
				.then((payload) => {
					if (payload?.data) {
						// const newComment = payload.data;
						// handleChangeCache(newComment);
					}
				});
		}
	};

	useEffect(() => {
		if (!isError) return;

		if (error && "status" in error && error?.status === 403) {
			addSnack({
				type: "error",
				eventName: "comment-okk-common-error",
				text: "Недостаточно прав пользователя",
				withAction: false,
			});
		} else {
			addSnack({
				type: "error",
				eventName: "comment-okk-common-error",
				text: "Произошла непредвиденная ошибка",
				withAction: false,
			});
		}
	}, [isError]);

	const okkTeleCommon = cn(styles["okk-column"], className);
	const calendarRef = useRef<any>(null);

	useEffect(() => {
		if (!isRequestShowQueryLoading && masterOnRequest) {
			setOkkTeleCommentFormState((prev) => ({
				...prev,
				master: { id: masterOnRequest?.id, title: masterOnRequest?.fio },
			}));
		}
	}, [isRequestShowQueryLoading, masterOnRequest]);

	return !mastersLoading && !gradesLoading && !violationsLoading ? (
		<div className={okkTeleCommon}>
			<div className={styles["okk-row"]}>
				<div className={styles["okk-input-container"]}>
					<MultiSelect
						initialSelectedItems={!isEmpty(okkTeleCommentFormState.master) ? [okkTeleCommentFormState.master] : undefined}
						inputContainerOrientation="column"
						width={"full-w"}
						onChange={(data) => {
							if (Array.isArray(data) && data?.length) {
								setOkkTeleCommentFormState((prev) => ({
									...prev,
									master: data[0],
								}));
								return;
							}

							setOkkTeleCommentFormState((prev) => ({
								...prev,
								master: undefined,
							}));
						}}
						className={styles["okk-select"]}
						isDataNested={false}
						inputLabelText={"Мастер:"}
						enableVirtualization
						dropdownMenuSelectionVariant={"single-select"}
						data={mastersData?.data}
						inputId={"master"}
					/>
				</div>
				<MultiSelect
					inputContainerOrientation="column"
					className={styles["okk-select"]}
					width={"full-w"}
					isDataNested={false}
					inputLabelText={"Пункт нарушения:"}
					withTooltip
					dropdownMenuSelectionVariant={"multiple-select"}
					data={violationsData?.data}
					inputId={"violation"}
					initialSelectedItems={okkTeleCommentFormState.masterViolation}
					onChange={(data) => {
						if (Array.isArray(data) && data?.length) {
							setOkkTeleCommentFormState((prev) => ({
								...prev,
								masterViolation: data as ViolationFeedResource[],
							}));
							return;
						}
						setOkkTeleCommentFormState((prev) => ({
							...prev,
							masterViolation: [],
						}));
					}}
				/>
				<MultiSelect
					className={styles["okk-select"]}
					isDataNested={false}
					width={"full-w"}
					inputContainerOrientation="column"
					inputLabelText={"Оценка:"}
					dropdownMenuSelectionVariant={"single-select"}
					data={gradesData?.data}
					inputId={"grade"}
					initialSelectedItems={okkTeleCommentFormState.masterGrade?.id ? [okkTeleCommentFormState.masterGrade] : undefined}
					onChange={(data) => {
						if (Array.isArray(data) && data?.length) {
							setOkkTeleCommentFormState((prev) => ({
								...prev,
								masterGrade: data[0],
							}));
							return;
						}

						setOkkTeleCommentFormState((prev) => ({
							...prev,
							masterGrade: undefined,
						}));
					}}
				/>
			</div>
			<div>
				<Title className={styles["okk-title"]} as={"h5"}>
					Контроль
				</Title>
				<div className={styles["okk-row"]}>
					<Checkbox
						label={"На контроле Телемаркетинга"}
						checked={okkTeleCommentFormState.control}
						onChange={(e) => {
							if (e.target.checked) {
								setOkkTeleCommentFormState((prev) => ({
									...prev,
									control: e.target.checked,
								}));
							} else {
								setOkkTeleCommentFormState((prev) => ({
									...prev,
									control: e.target.checked,
									inputDateValue: "",
								}));
							}
						}}
						showLabel
					/>
					<InputErrorContainer className={"react-datepicker-ignore-onclickoutside"} errorMessage={"meta.error"}>
						<InputDefault
							disabled={!okkTeleCommentFormState.control}
							width={"full-w"}
							id={"calendar"}
							type={"text"}
							withRightButton={true}
							labelText={"Дата и время контроля:"}
							state={okkTeleCommentFormState.inputState}
							inputProps={{
								value: okkTeleCommentFormState.inputDateValue,
								onChange: (e: ChangeEvent<HTMLInputElement>) => {
									setOkkTeleCommentFormState((prev) => ({
										...prev,
										inputDateValue: e.target.value,
										inputState: "filled",
									}));
								},
								onClick: () => {
									calendarRef?.current?.setOpen(true);
									!okkTeleCommentFormState.isInvalid &&
										setOkkTeleCommentFormState((prev) => ({
											...prev,
											inputState: "active",
										}));
								},
								onFocus: () => {
									calendarRef?.current?.setOpen(true);
									!okkTeleCommentFormState.isInvalid &&
										setOkkTeleCommentFormState((prev) => ({
											...prev,
											inputState: "active",
										}));
								},
								onBlur: () => {
									!okkTeleCommentFormState.isInvalid &&
										setOkkTeleCommentFormState((prev) => ({
											...prev,
											inputState: "default",
										}));
								},
							}}
							mask={generateDateString()}
							renderDropdownMenu={() => (
								<Calendar
									ref={calendarRef}
									selectedTime={format(okkTeleCommentFormState.date, "HH:mm")}
									popperPlacement={"bottom"}
									popperModifiers={[
										{
											name: "offset",
											options: {
												offset: [-150, 20],
											},
										},
									]}
									minDate={new Date()}
									className={styles["without-bs"]}
									selected={okkTeleCommentFormState.date}
									withoutTriggerButton
									onChange={(date) => {
										date &&
											setOkkTeleCommentFormState((prev) => ({
												...prev,
												inputDateValue: format(date, "dd.MM.yyyy HH:mm"),
											}));
									}}
								/>
							)}
						/>
					</InputErrorContainer>
				</div>
			</div>
			<Textfield
				label="Комментарий"
				value={okkTeleCommentFormState.comment}
				onChange={(e) => {
					setOkkTeleCommentFormState((prev) => ({
						...prev,
						comment: e.target.value,
					}));
				}}
			/>
			<div>
				<Title className={styles["okk-title"]} as={"h5"}>
					Вопросы
				</Title>
				{questionsData?.data && (
					<div className={styles["accordion-container"]}>
						{questionsData.data.map((question) => {
							return (
								<Accordion
									open={okkTeleCommentFormState.answers.some((answer) =>
										(question?.questions || []).some(
											(item: { id?: number; text?: string; title?: string; is_special?: boolean }) =>
												item?.id === answer.question_id,
										),
									)}
									header={question.name}
									key={question.id}
									content={
										<ol className={styles.questions}>
											{question.questions?.map((item) => (
												<Fragment key={item.id}>
													{item.text ? (
														<Question
															key={item?.id}
															changeAnswer={changeAnswer}
															themeId={question.id}
															answer={okkTeleCommentFormState.answers.find(
																(answer) => answer.question_id === item.id,
															)}
															question={item}
														/>
													) : null}
												</Fragment>
											))}
										</ol>
									}
								/>
							);
						})}
					</div>
				)}
				<Checkbox
					checked={okkTeleCommentFormState.showAll}
					onChange={(e) => {
						setOkkTeleCommentFormState((prev) => ({
							...prev,
							showAll: e.target.checked,
						}));
					}}
					label={"Показывать всем"}
					showLabel
				/>{" "}
				<div className={styles["button-container"]}>
					<Button
						disabled={!okkTeleCommentFormState.comment || okkTeleCommentFormState.inputState === "error"}
						onClick={() => {
							onSubmit();
						}}
						size={UISizes.medium}
						className={styles["okk-submit-button"]}
					>
						Добавить
					</Button>
				</div>
			</div>
		</div>
	) : (
		<LoadingSpinner />
	);
};

export default OkkTeleCommon;
