import { RequestCardHeaderContainerProps } from '@common/request-card/request-card.types'
import styles from '../request-card.module.scss'
import cn from 'classnames'
import { ContentDivider } from '../../content-divider'
import { useNavigate } from 'react-router-dom'
import { REQUEST_PAGE_BY_REQUEST_ID } from '@constants/routes'
import { useFullCodeRequest } from '@/contexts/scroll-pos'

export const RequestCardHeaderContainer = ({
  headerTitleContent,
  headerInfoContent,
  headerActionButtons,
  requestStatus,
  card,
}: Partial<RequestCardHeaderContainerProps>) => {
  const requestCardHeaderClasses = cn(styles['request-card_header'], {
    [styles['request-card_header--black-list']]: requestStatus === 'header-blacklist',
    [styles['request-card_header--entity']]: requestStatus === 'entity',
    [styles['request-card_header--simple']]: requestStatus === 'header',
  })
  const requestCardHeaderInfoClasses = cn(styles['request-card_header__info'], {
    [styles['request-card_header__info--black-list']]: requestStatus === 'header-blacklist',
  })
  const navigate = useNavigate()
  const { requestFullcodeId, handleSetRequestFullcodeId } = useFullCodeRequest()

  return (
    <div className={requestCardHeaderClasses}>
      <div 
        className={styles['header-hover-wrapper']}
        onClick={() => {
          navigate(REQUEST_PAGE_BY_REQUEST_ID(String(card?.id)))
          handleSetRequestFullcodeId(String(card?.fullcode))
        }}
        >
        <div className={styles['request-card_header__title']}>
          {headerTitleContent}
        </div>
        <div className={requestCardHeaderInfoClasses}>{headerInfoContent}</div>
      </div>
      <div style={{margin: 'auto 12px'}}>
        <ContentDivider type={'vertical'}  style={{height: '20px'}} subType={'header-vertical'}/>
      </div>
      <div className={styles['header-action-buttons']}>{headerActionButtons}</div>
    </div>
  )
}
