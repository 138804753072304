import { PanelMenu } from '../../../shared/ui-kit/panel-menu'
import { useGlobalPhonerState } from '../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import { useFormMachine } from '../../../app/machines/form-factory-machine/useFormMachine'

type MenuEventType = 'back' | 'call logs' | 'settings' | 'hot keys' | 'contacts' | 'final call recordings' | 'open menu' | 'close menu'

export const FastSearchPanelMenu = () => {
  const { phonerMenuService, fastSearchService } = useGlobalPhonerState()
  const [fastSearchState, fastSearchSendEvents] = useActor(fastSearchService)
  const [, send] = useActor(phonerMenuService)
  const { resetField } = useFormMachine({
    state: fastSearchState,
    send: fastSearchSendEvents,
  })
  const handleClick = (valueSend: MenuEventType, isClear: boolean = false) => {
    send(valueSend)
    isClear && resetField('', 'fast-search')
  }

  return (
    <PanelMenu
      menuItemsSlot={
        <>
          <PanelMenu.Item
            title={'Журнал звонков'}
            // onClick={() => send("call logs")}
            onClick={() => handleClick('call logs', true)}
            icon={'icon-history'}
          />
          <PanelMenu.Item
            title={'Горячие клавиши'}
            // onClick={() => send("hot keys")}
            onClick={() => handleClick('hot keys')}
            icon={'icon-constructor'}
          />
          <PanelMenu.Item
            title={'Настройки аудио'}
            // onClick={() => send("settings")}
            onClick={() => handleClick('settings')}
            icon={'icon-settings'}
          />
        </>
      }
    />
  )
}
