import { Container, Col, Row } from 'react-grid-system'
import { InstallmentFields, InstallmentServerData } from '@/constants/installment'
import { Typography } from '@/components/common'
import style from '../../installment.module.scss'
import { currencyFormatter } from '@/utils/currency-formatter'

export const InstallmentInfoRightColumn = ({ data, installmentSubmitForm, canViewFinInfo }: any): JSX.Element => {
  const isCurrencyNotAvailable = installmentSubmitForm || !data?.[InstallmentServerData.summ] || !canViewFinInfo
  const styles = isCurrencyNotAvailable ? style['typography-title'] : style['typography-data']

  return (
    <>
      <Row gutterWidth={0} justify={'between'} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.installmentAmount}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={styles}>
          {isCurrencyNotAvailable ? '\u2014' : currencyFormatter.format(data?.[InstallmentServerData.summ])}
        </Typography>
      </Row>

      <Row gutterWidth={0} justify={'between'} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.minimumInstallment}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={styles}>
          {isCurrencyNotAvailable ? '\u2014' : currencyFormatter.format(data?.[InstallmentServerData.min_payment])}
        </Typography>
      </Row>

      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.paid}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={styles}>
          {isCurrencyNotAvailable ? '\u2014' : currencyFormatter.format(data?.[InstallmentServerData.fee])}
        </Typography>
      </Row>

      <Row justify={'between'} gutterWidth={0} className={style.rowStyle} align={'center'}>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={style['typography-title']}>
          {InstallmentFields.balance}
        </Typography>
        <Typography as={'paragraph'} decoration={'none'} weight={2} tag={4} className={styles}>
          {isCurrencyNotAvailable ? '\u2014' : currencyFormatter.format(data?.[InstallmentServerData.dept])}
        </Typography>
      </Row>
    </>
  )
}
