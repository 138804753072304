import { useMemo } from 'react'

import { useWindowSize } from '@/hooks'
//import { useScreenSizeSelector } from '@store/app'
import { ScreenSizes } from '@/global-types/options.types'

type WidthMatcher = ScreenSizes[] | number | number[] | string

const sortedSizes = [
  ScreenSizes.XS,
  ScreenSizes.S,
  ScreenSizes.M,
  ScreenSizes.L,
  ScreenSizes.XL,
  ScreenSizes.XXL,
]
const widthMatch = (currentWidth: string, minWidth: string) => {
  const sizes = []

  for (const size of sortedSizes) {
    sizes.push(size)

    if (size === currentWidth) return true
    if (size === minWidth) break
  }

  return false
}

function useWidthMatcher<T>(size: T): boolean {
  const { width } = useWindowSize()
  //const screenSize = useScreenSizeSelector() as ScreenSizes

  // const matchString = useMemo(() => {
  //   if (Array.isArray(size) && size.every((el) => typeof el === 'string')) {
  //     return size.includes(screenSize)
  //   } else if (typeof size === 'string') {
  //     return widthMatch(screenSize, size)
  //   }
  //
  //   return false
  // }, [screenSize, size])

  const matchNumber = useMemo(() => {
    if (Array.isArray(size) && size.every((el) => typeof el === 'number')) {
      if (size.length === 2) {
        const sorted = size.sort((a, b) => (a as number) - (b as number))

        return width > sorted[0] && width < sorted[1]
      }
    } else if (typeof size === 'number') {
      return width < size
    }

    return false
  }, [width, size])

  //return matchString || matchNumber

  return matchNumber
}

export type { WidthMatcher }
export { widthMatch, useWidthMatcher }
