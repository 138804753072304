import {
  SidebarContainerProps
} from '@common/sidebar-container/sidebar-container.types'
import styles from './sidebar-container.module.scss'

export const SidebarContainer = ({children}: SidebarContainerProps) => {
  return <div className={styles['sidebar-container']}>
    <div className={styles['sidebar-content-container']}>
      <div className={styles['sidebar-content']}>
        {children}
      </div>
    </div>
  </div>
}
