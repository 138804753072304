import { type DetailedHTMLProps, type HTMLAttributes, type ReactNode, useLayoutEffect, useRef, useState } from "react";
import { Panel } from "../../../shared";
import styles from "./phoner.module.scss";
import { useActor } from "@xstate/react";
import cn from "clsx";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { IncomingCallWidget } from "../../../widgets";
import { DraggableArea } from "../../../shared/components/draggable-area";

interface PhonerProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	leftSlot?: ReactNode;
	middleSlot?: ReactNode;
	rightSlot?: ReactNode;
	topSlot?: ReactNode;
	bottomSlot?: ReactNode;
	isShowDraggableTrack?: boolean;
}

export const Phoner = ({
	leftSlot = <></>,
	rightSlot = <></>,
	middleSlot = <></>,
	topSlot = <></>,
	bottomSlot = <></>,
	className = "",
	isShowDraggableTrack = false,
	...props
}: PhonerProps) => {
	const { phonerIncomingCallService, phonerDraggingService } = useGlobalPhonerState();

	const phonerClasses = cn(styles.container, className);
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const showIncomingCall = statePhonerIncomingCall.matches("call received");
	const [statePhonerDragging] = useActor(phonerDraggingService);
	const topAnimationDirection = statePhonerDragging.context.animationTransitionConfig.animationDirection === "top";
	const componentRef = useRef<HTMLDivElement>(null);
	const [prevHeight, setPrevHeight] = useState(0);
	const [phonerDraggingState, setPhonerDraggingState] = useActor(phonerDraggingService);

	useLayoutEffect(() => {
		if (componentRef.current && !topAnimationDirection) {
			const currentHeight = componentRef.current.offsetHeight;
			if (prevHeight !== 0) {
				const deltaHeight = currentHeight - prevHeight;
				const translateY = phonerDraggingState.context.position.y + deltaHeight;
				setPhonerDraggingState({ type: "positionChanged", value: { x: phonerDraggingState.context.position.x, y: translateY } });
			}
			setPrevHeight(currentHeight);
		}
	}, [showIncomingCall, topAnimationDirection]);

	return (
		<div className={styles["with-collapse-container"]} {...props} ref={componentRef}>
			{topSlot}
			{isShowDraggableTrack ? (
				<DraggableArea>
					<Panel className={phonerClasses}>
						{showIncomingCall && topAnimationDirection ? <IncomingCallWidget /> : <></>}
						<Panel.Body className={styles["fast-search-input"]}>
							{leftSlot}
							{middleSlot}
							{rightSlot}
						</Panel.Body>
						{showIncomingCall && !topAnimationDirection ? <IncomingCallWidget /> : <></>}
					</Panel>
				</DraggableArea>
			) : (
				<Panel className={phonerClasses}>
					<Panel.Body className={styles["fast-search-input"]}>
						{leftSlot}
						{middleSlot}
						{rightSlot}
					</Panel.Body>
				</Panel>
			)}
			{bottomSlot}
		</div>
	);
};
