import { useEffect, useState } from 'react'
import { Button, Icon } from '../../../shared'
import { useActor } from '@xstate/react'
import { useGlobalPhonerState } from '../../../app/providers/xstate-provider'

export const ControlVolumeButton = () => {
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false)
  const [previousVolume, setPreviousVolume] = useState<number>()

  const { phonerSettingsService } = useGlobalPhonerState()

  const [statePhonerSettings, sendPhonerSettingsEvents] = useActor(
    phonerSettingsService,
  )

  const volume = statePhonerSettings?.context?.audioVolume

  const handleControlVolumeButton = () => {
    if (isAudioMuted) {
      setIsAudioMuted(false)
      sendPhonerSettingsEvents({ type: 'change audio', value: previousVolume || 50 })
      sendPhonerSettingsEvents('finish changing')
    } else {
      setIsAudioMuted(true)
      setPreviousVolume(volume)
      sendPhonerSettingsEvents({ type: 'change audio', value: 0})
      sendPhonerSettingsEvents('finish changing')
    }
  }

  useEffect(() => {
    if(volume === 0){
      setIsAudioMuted(true)
    } else {
      setIsAudioMuted(false)
    }
  }, [volume])

  return (
    <Button
      onClick={handleControlVolumeButton}
      size="m"
      variant={'secondary-uncolored'}
      width="icon"
      renderIcon={
        <Icon icon={isAudioMuted ? 'icon-volume-mute' : 'icon-volume'} />
      }
    />
  )
}