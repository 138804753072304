import { InstallmentStatusNames, InstallmentStatuses } from '@/constants/installment'
import { InstallmentStatusProps } from '../../installment.types'
import { Typography } from '@/components/common'
import styles from './installment-status.module.scss'
import cn from 'classnames'

export const InstallmentStatus = ({ status, statusName }: InstallmentStatusProps) => {
  const statusBlockStyle = cn(styles['status-block'], {
    [styles[`status-block-${InstallmentStatuses.overdue.toLowerCase()}`]]: status === 10,
    [styles[`status-block-${InstallmentStatuses.paid.toLowerCase()}`]]: status === 20,
    [styles[`status-block-${InstallmentStatuses.inProgress.toLowerCase()}`]]: status === 30,
  })

  return (
    <div className={statusBlockStyle}>
      <Typography as={'caption'} decoration={'none'} tag={4} weight={2}>
        {statusName}
      </Typography>
    </div>
  )
}
