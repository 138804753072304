import { TableRowProps } from './table-row.types'
import styles from '@common/table-container/table.module.scss'

import cn from 'classnames'

export const TableRow = ({
  children,
  style,
  className = '',
}: TableRowProps) => {
  const tableRowClasses = cn(styles['table-row'], className)

  return (
    <div style={style} className={tableRowClasses}>
      {children}
    </div>
  )
}
