import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom'
import { Layout } from '@/layout/layout'
import App from '@/app/app'
import RequestPage from '@pages/request-page'
import {
  AddCallModal,
  CreateRequestModal,
  DeleteComment,
  DeleteFile,
  MetricsSidebarModal,
  PushMessageModal,
  RequestCommentsModal,
  RequestHistoryModal,
  SelectionOfMastersModal,
  ViolationDispatcherModal,
  ViolationModal,
} from '@components/modals'

import type { To } from 'react-router'
import { CallRecordsModal } from '@components/modals/call-records'
import {
  ADD_CALL,
  ATTACHMENT_ID,
  ATTACHMENTS,
  CALL_RECORDS,
  CALLS,
  COMMENT_ID,
  COMMENTS,
  CREATE_REQUEST,
  FEED_DUPLICATION_OF_REQUEST,
  EDIT,
  FEED_CALL_BY_REQUEST,
  FEED_CALL_RECORDS,
  FEED_COMMENTS_BY_ID,
  FEED_EDIT_REQUEST,
  FEED_EDIT_REQUEST_SELECTION_OF_MASTERS,
  FEED_HISTORY_OF_REQUEST,
  FEED_MESSAGE_BY_REQUEST,
  FEED_VIOLATION_BY_REQUEST,
  FILTER_TEMPLATES_DESTROY,
  REQEST_MASTER_CARD_BY_ID,
  REQEST_DISPETCHER_CARD_BY_ID,
  MESSAGE,
  REQUEST_ID,
  REQUESTS,
  VIOLATION,
  DUPLICATE,
  TEMPLATE_ID,
  SELECTION_OF_MASTERS,
  MASTER_ID,
  FULLCODE_ID,
  EDIT_REQUEST_SELECTION_OF_MASTERS,
  FEED_VIOLATION_DISPATCHER_BY_REQUEST,
  DISPATCHER_ID,
  VIOLATION_DISPATCHER,
  MASTER_SELECTION,
  OKK_COMMENT,
  OKK_EMPLOYEE,
  OKK_TELE,
  OKK_COMMENT_BY_ID,
  OKK_EMPLOYEE_BY_ID,
  OKK_TELE_BY_ID,
  AUTOANSWER,
  MISSED_CALLS,
  FEED_INFO_INSTALLMENT,
  MASTER_SELECTION_ROUTES,
} from '@constants/routes'
import { DeleteFilter } from '@components/modals/delete-filter.tsx'
import { AuthMiddleware } from '@/middlewares/auth-middleware'
import OkkCommentModal from '@/components/modals/okk-comment-modal/okk-comment-modal'
import OkkEmployeeModal from '@/components/modals/okk-employee-modal/okk-employee-modal'
import OkkTele from '@/components/modals/okk-tele/okk-tele'
import { MasterCardModal } from '@/components/modals/master-card-modal'
import { DispetcherCardModal } from '@/components/modals/dispetcher-card-modal'
import { Phoner } from '@/phoner'
import { InstallmentModal } from '@/components/modals/installment-modal'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AuthMiddleware>
          <Phoner />
          <Layout>
            <Outlet />
          </Layout>
        </AuthMiddleware>
      }>
      <Route index element={<Navigate to={'requests'} />} />
      <Route path={'requests'} element={<App />}>
        <Route
          path={CREATE_REQUEST}
          element={<CreateRequestModal modalView={'create'} onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Создание заявки'} />}>
          <Route
            path={MASTER_SELECTION}
            element={<SelectionOfMastersModal onClosePath={'../'} modalView={'create'} title={'Подбор мастеров'} closeOnBlackoutClick />}>
            <Route
              path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
              element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
            />
          </Route>
        </Route>
        <Route path={OKK_COMMENT_BY_ID} element={<OkkCommentModal />} />
        <Route path={OKK_EMPLOYEE_BY_ID} element={<OkkEmployeeModal />} />
        <Route path={OKK_TELE_BY_ID} element={<OkkTele />} />
        <Route
          path={MISSED_CALLS}
          element={
            <MetricsSidebarModal
              modalViewSidebar={'missed'}
              onClosePath={'../' as To}
              closeOnBlackoutClick
              subtitle={'Пропущенные вызовы'}
            />
          }
        />
        <Route
          path={AUTOANSWER}
          element={
            <MetricsSidebarModal modalViewSidebar={'autoanswer'} onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Автоответчик'} />
          }
        />
        <Route
          path={SELECTION_OF_MASTERS}
          element={<SelectionOfMastersModal onClosePath={'../'} title={'Подбор мастеров'} closeOnBlackoutClick />}
        />
        <Route
          path={`${FEED_COMMENTS_BY_ID(REQUEST_ID)}/*`}
          element={<RequestCommentsModal title={'Комментарии'} closeOnBlackoutClick onClosePath={'../'} />}
        />
        <Route
          path={FEED_DUPLICATION_OF_REQUEST(REQUEST_ID, FULLCODE_ID)}
          element={
            <CreateRequestModal modalView={'duplicate'} onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Создание заявки'} />
          }>
          <Route
            path={MASTER_SELECTION}
            element={
              <SelectionOfMastersModal onClosePath={'../'} modalView={'duplicate'} title={'Подбор мастеров'} closeOnBlackoutClick />
            }>
            <Route
              path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
              element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
            />
          </Route>
        </Route>

        <Route
          path={FEED_HISTORY_OF_REQUEST(REQUEST_ID, FULLCODE_ID)}
          element={<RequestHistoryModal title={'История заявки'} closeOnBlackoutClick onClosePath={'../' as To} />}
        />

        <Route
          path={FEED_CALL_RECORDS(REQUEST_ID)}
          element={<CallRecordsModal title={'Записи звонков'} closeOnBlackoutClick onClosePath={'../' as To} />}
        />

        <Route
          path={FEED_INFO_INSTALLMENT(REQUEST_ID, FULLCODE_ID)}
          element={<InstallmentModal title={'Рассрочка'} closeOnBlackoutClick onClosePath={'../' as To} />}
        />

        <Route
          path={FEED_EDIT_REQUEST(REQUEST_ID, FULLCODE_ID)}
          element={<CreateRequestModal onClosePath={'../'} subtitle={'Редактирование заявки'} closeOnBlackoutClick modalView={'edit'} />}>
          <Route
            path={MASTER_SELECTION_ROUTES}
            element={<SelectionOfMastersModal onClosePath={'../'} title={'Подбор мастеров'} closeOnBlackoutClick />}>
            <Route
              path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
              element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
            />
          </Route>
        </Route>
        <Route
          path={FEED_EDIT_REQUEST_SELECTION_OF_MASTERS(REQUEST_ID, FULLCODE_ID)}
          element={
            <SelectionOfMastersModal modalView={'manual-selection'} onClosePath={'../'} title={'Подбор мастеров'} closeOnBlackoutClick />
          }
        />
        <Route
          path={FEED_MESSAGE_BY_REQUEST(MASTER_ID, FULLCODE_ID)}
          element={<PushMessageModal subtitle={'Сообщение по заявке'} onClosePath={'../'} closeOnBlackoutClick />}
        />
        {/* biome-ignore lint/complexity/noUselessFragments: <explanation> */}
        <Route path={FEED_CALL_BY_REQUEST(REQUEST_ID)} element={<></>} />
        <Route
          path={FEED_VIOLATION_BY_REQUEST(MASTER_ID)}
          element={<ViolationModal onClosePath={'../'} title={'Оценка сотрудника'} closeOnBlackoutClick />}
        />
        <Route
          path={FEED_VIOLATION_DISPATCHER_BY_REQUEST(DISPATCHER_ID)}
          element={<ViolationDispatcherModal onClosePath={'../'} title={'Оценка сотрудника'} closeOnBlackoutClick />}
        />
        {/* <Route
          path={FILTER_TEMPLATES_SAVE(TEMPLATE_ID)}
          element={
            <CreateFilterTemplateModal
              onClosePath={'../'}
              title={'Создание шаблона'}
              closeOnBlackoutClick
            />
          }
        />*/}
        <Route path={FILTER_TEMPLATES_DESTROY(TEMPLATE_ID)} element={<DeleteFilter />} />
        <Route
          path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
          element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
        />
        <Route
          path={REQEST_DISPETCHER_CARD_BY_ID(DISPATCHER_ID)}
          element={<DispetcherCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Личная статистика'} />}
        />
      </Route>
      <Route path={CALLS} element={<App />}>
        <Route path={ADD_CALL} element={<AddCallModal closeOnBlackoutClick title={'Добавление звонка'} onClosePath={'/requests'} />} />
      </Route>
      {/*<Route path={'ui-test'} element={<UIExample />} />*/}
      <Route path={REQUESTS}>
        <Route path={REQUEST_ID} element={<RequestPage />}>
          <Route
            path={EDIT_REQUEST_SELECTION_OF_MASTERS(FULLCODE_ID)}
            element={
              <SelectionOfMastersModal onClosePath={'../'} modalView={'manual-selection'} title={'Подбор мастеров'} closeOnBlackoutClick />
            }
          />
          <Route path={OKK_EMPLOYEE} element={<OkkEmployeeModal />} />
          <Route path={OKK_COMMENT} element={<OkkCommentModal />} />
          <Route path={OKK_TELE} element={<OkkTele />} />
          <Route
            path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
            element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
          />
          <Route
            path={REQEST_DISPETCHER_CARD_BY_ID(DISPATCHER_ID)}
            element={<DispetcherCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Личная статистика'} />}
          />
          <Route path={VIOLATION}>
            <Route path={`${MASTER_ID}`} element={<ViolationModal title={'Оценка сотрудника'} closeOnBlackoutClick />} />
          </Route>
          <Route path={VIOLATION_DISPATCHER}>
            <Route
              path={`${DISPATCHER_ID}`}
              element={<ViolationDispatcherModal onClosePath={'../../'} title={'Оценка сотрудника'} closeOnBlackoutClick />}
            />
          </Route>
          <Route path={CALL_RECORDS} element={<CallRecordsModal title={'Записи звонков'} closeOnBlackoutClick />} />
          <Route
            path={EDIT}
            element={
              <CreateRequestModal modalView={'edit'} onClosePath={'../' as To} subtitle={'Редактирование заявки'} closeOnBlackoutClick />
            }>
            <Route
              path={MASTER_SELECTION}
              element={<SelectionOfMastersModal onClosePath={'../'} modalView={'edit'} title={'Подбор мастеров'} closeOnBlackoutClick />}>
              <Route
                path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
                element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
              />
            </Route>
          </Route>
          <Route
            path={DUPLICATE}
            element={<CreateRequestModal onClosePath={'../'} closeOnBlackoutClick subtitle={'Создание заявки'} modalView={'duplicate'} />}>
            <Route
              path={MASTER_SELECTION}
              element={
                <SelectionOfMastersModal onClosePath={'../'} modalView={'duplicate'} title={'Подбор мастеров'} closeOnBlackoutClick />
              }>
              <Route
                path={REQEST_MASTER_CARD_BY_ID(MASTER_ID)}
                element={<MasterCardModal onClosePath={'../' as To} closeOnBlackoutClick subtitle={'Карточка мастера'} />}
              />
            </Route>
          </Route>
          <Route path={COMMENTS}>
            <Route path={`${COMMENT_ID}`} element={<DeleteComment />} />
          </Route>
          <Route path={MESSAGE}>
            <Route path={`${MASTER_ID}`}>
              <Route
                path={`${FULLCODE_ID}`}
                element={<PushMessageModal subtitle={'Сообщение по заявке'} onClosePath={'../'} closeOnBlackoutClick />}
              />
            </Route>
          </Route>
          <Route path={ATTACHMENTS}>
            <Route path={ATTACHMENT_ID} element={<DeleteFile />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<App />} />
    </Route>,
  ),
)
