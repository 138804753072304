import type { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Phoner } from "../pages/phoner";
import { HotKeysProvider } from "../shared/contexts/hot-keys";
import { MasterTabProvider } from "../shared/contexts/master-tab";
import { PhonerProvider } from "../shared/contexts/phoner/phoner";
import { SharedWorkerProvider } from "../shared/contexts/shared-worker";
import { SessionProvider } from "../shared/contexts/tab-session-context/tab-session-context";
import { PhonerEvents } from "../shared/phoner-events";
import type * as PhonerEventsT from "../shared/phoner-events";
import { GlobalStateProvider } from "./providers/xstate-provider";
import { inspect } from "@xstate/inspect";

const queryClient = new QueryClient();
import.meta.env.DEV && inspect();

export const ExternalPhoner: FC = () => {
	return (
		<SharedWorkerProvider>
			<MasterTabProvider>
				<SessionProvider>
					<GlobalStateProvider>
						<QueryClientProvider client={queryClient}>
							<PhonerProvider>
								<HotKeysProvider>
									<Phoner />
								</HotKeysProvider>
							</PhonerProvider>
						</QueryClientProvider>
					</GlobalStateProvider>
				</SessionProvider>
			</MasterTabProvider>
		</SharedWorkerProvider>
	);
};
export { PhonerEvents };
export type { PhonerEventsT };
