import { useCallback, useEffect, useRef } from "react";
export interface HotKeysStatus {
	[key: string]: boolean;
}

export interface IHotKey {
	shift?: boolean;
	alt?: boolean;
	ctrl?: boolean;
	keys: string[];
}
export const useHotKey = (callback: () => void, hotKey: IHotKey) => {
	const pressedKeys = useRef<HotKeysStatus>(
		hotKey.keys.reduce((acc: HotKeysStatus, key) => {
			acc[key] = false;
			return acc;
		}, {}),
	);

	const handleKeyEvents = useCallback(
		(event: KeyboardEvent) => {
			if (event.code === undefined) return;

			const matchResult = event.code.match(/key.*$/i);
			const keyBoardCode = matchResult ? matchResult[0].slice(-1) : event.code;

			if (!pressedKeys.current[keyBoardCode] === undefined || !hotKey.keys.includes(keyBoardCode)) return;

			pressedKeys.current[keyBoardCode] = event.type === "keydown";

			if (hotKey.alt || hotKey.ctrl || hotKey.shift) {
				if (!(!!hotKey?.alt === event.altKey && event.ctrlKey === !!hotKey?.ctrl && event.shiftKey === !!hotKey?.shift)) return;

				hotKey.keys.every((key) => pressedKeys.current[key]) && callback();
			} else {
				hotKey.keys.every((key) => pressedKeys.current[key]) && callback();
			}

			event.preventDefault();
		},
		[callback],
	);

	useEffect(() => {
		document.addEventListener("keydown", handleKeyEvents);

		return () => {
			document.removeEventListener("keydown", handleKeyEvents);
		};
	}, [handleKeyEvents]);
};
