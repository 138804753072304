import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'

import { UserFeedResource, useUsersFeedQuery } from '@/state/api'

import { useField } from 'react-final-form'
import { CreateRequestNames } from '@constants/create-request'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'
import { MultiSelect } from '@common/multi-select'
import { findItem } from '@utils/find-item'

export const RequestModalUserInput = <T,>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  ...props
}: RequestModalTextInputProps<T>) => {
  const { data: serverData, isLoading, isError, refetch } = useUsersFeedQuery()
  const [initialValue, setInitialValue] = useState<
    UserFeedResource[] | undefined
  >(undefined)

  const handleRefetchUsers = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-users-error',
        text: `Не удалось загрузить пользователей.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchUsers(),
      })
    }
  }, [isError])

  const userId = useField('dispatcher')?.input?.value?.id

  useEffect(() => {
    if (userId && !isLoading && serverData?.data) {
      setInitialValue(
        findItem<UserFeedResource>({
          id: userId,
          arr: serverData?.data,
        }),
      )
    }
  }, [userId, isLoading])

  return (
    <>
      <MultiSelect<UserFeedResource>
        isDataLoading={isLoading}
        width={'full-w'}
        disabled={props.disabled}
        isDataNested={true}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={serverData?.data || []}
        inputId={CreateRequestNames.dispatcher}
        onChange={(items) =>
          items?.length
            ? input.onChange(items[0].id)
            : input.onChange(undefined)
        }
        initialSelectedItems={initialValue}
        externalInputProps={input}
        externalInputMetaProps={meta}
      />
    </>
  )
}
