import cn from "clsx";
import { forwardRef, useId } from "react";
import InputMask from "react-input-mask";
import styles from "./input.module.scss";
import type { InputProps } from "./input.types";

export const Input = forwardRef<HTMLInputElement, InputProps<"input">>(
	(
		{
			className,
			type = "text",
			withRightButton,
			mask,
			placeholder,
			disabled,
			value,
			onChange = () => ({}),
			onBlur = () => ({}),
			onFocus = () => ({}),
			onPaste = () => ({}),

			...otherProps
		}: InputProps<"input">,
		ref,
	) => {
		const inputClasses = cn(styles.input, {
			[styles["with-right-button"] as string]: withRightButton,
			className,
		});

		const reactId = useId();
		const localId = otherProps.id && otherProps.id !== "" ? otherProps.id : reactId;

		return (
			<InputMask
				mask={mask || ""}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onPaste={onPaste}
				disabled={disabled}
				id={localId}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.preventDefault(); // Отменить стандартное действие (отправку формы)
					}
				}}
				className={inputClasses}
				autoComplete="off"
				placeholder={placeholder}
			>
				<input
					ref={ref}
					type={type}
					// onChange={handleChange}

					{...otherProps}
				/>
			</InputMask>
		);
	},
);
