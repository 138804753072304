import { FC } from 'react'
import styles from './loader.module.scss'

export type LoaderProps = {
  size?: number
  width?: number
  color?: string
}

export const Loader: FC<LoaderProps> = ({ size = 48, width = 5, color = 'var(--phoner-gray-500)' }) => {
  return (
    <span
      className={styles.loader}
      style={{width: size, height: size}}
    >
      <span className={styles["loader-spinner"]} style={{borderWidth: width, borderColor: color}}></span>
    </span>
  )
}
