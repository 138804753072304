import { InputErrorContainer } from "@common/input-error-container";
import { InputDefault } from "@common/multi-select";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { getInputState } from "@components/form/utils/getFieldState";
import type { FilterTextInputTypes } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-text-input/filter-text-input.types";
import { UISizes } from "@global-types/mods.types";
import type { BasicInputValues } from "@global-types/utils-type";
import { useState } from "react";
import "./filter-text-input.scss";

export const FilterTextInput = <T extends BasicInputValues>({
  input,
  meta,
  label,
  textError = '',
  disabled,
  ...props
}: FilterTextInputTypes<T>) => {
  const [hoveredTooltip, setHoveredTooltip] = useState<string>("");
	const handleSetHoveredTooltip = (val: string) => {
		setHoveredTooltip(val);
	};

	return (
		<InputErrorContainer anchorId={hoveredTooltip} errorMessage={meta.error || textError}>
			<InputDefault
				disabled={disabled}
				width={"full-w"}
				id={input.name}
				type={"text"}
				withRightButton={true}
				labelText={label && `${label}: `}
				state={getInputState(meta, input, textError)}
				handleShowError={handleSetHoveredTooltip}
				{...input}
				{...meta}
				{...props}
				renderRightButton={
					input.value !== ""
						? () => (
								<Button
									btnType={"button"}
									disabled={disabled}
									type={ButtonTypes.secondaryUncolored}
									size={UISizes.medium}
									iconLeft={<i className={"icon icon-close"} />}
									onClick={() => input.onChange(undefined)}
								/>
							)
						: undefined
				}
			/>
		</InputErrorContainer>
	);
};
