import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { Title, Tooltip, Typography } from "@/components/common";
import { UISizes } from "@/global-types/mods.types";
import { useMastersMasterComplaintsFeedQuery } from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import cn from "classnames";
import { useCallback } from "react";
import type { IOkkTable } from "../okk-comment.types";
import styles from "./okk-components.module.scss";

const OkkTable = ({ items, removeItem, setEditItem, title, className }: IOkkTable) => {
	const { data: masterComplaints } = useMastersMasterComplaintsFeedQuery();
	const profile = useUserProfileInfo();
	const getCellColor = useCallback((category: number | undefined) => {
		if (category === 0) {
			return "red";
		}
		if (category === 2) {
			return "green";
		}

		return "";
	}, []);

	const tableClasses = cn(styles.table, className);

	return (
		<div className={tableClasses}>
			<Title className={styles["table-title"]} as="h5">
				{title}
			</Title>
			<table>
				<thead>
					<tr>
						<th>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Категория
							</Typography>
						</th>
						<th className={styles["date-cell"]}>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Дата
							</Typography>
						</th>
						<th>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Кому
							</Typography>
						</th>
						<th>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Назначил
							</Typography>
						</th>
						<th>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Комментарий
							</Typography>
						</th>
						<th>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								Сумма
							</Typography>
						</th>
						<th className={styles["table-icon-header"]}>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								<i className="icon icon-edit-1" />
							</Typography>
						</th>
						<th className={styles["table-icon-header"]}>
							<Typography decoration="none" as={"caption"} tag={4} weight={1}>
								<i className="icon icon-delete" />
							</Typography>
						</th>
					</tr>
				</thead>
				<tbody>
					{items.map((item) => {
						const color = getCellColor(item.type);
						const updateBtnDisabled = item.permissions ? !item.permissions.update : item.user_id !== profile?.id;
						const deleteBtnDisabled = item.permissions ? !item.permissions.delete : item.user_id !== profile?.id;

						return (
							<tr key={`${item.id}`}>
								<td>
									<Typography decoration="none" as={"caption"} tag={4} weight={1}>
										{masterComplaints?.data?.find((complaint) => complaint.id === item.type)?.title || ""}
									</Typography>
								</td>
								<td className={styles[`cell-${color}`]}>
									<Typography decoration="none" as={"caption"} tag={4} weight={2}>
										{item.date}
									</Typography>
								</td>
								<td className={styles[`cell-${color}`]}>
									<Typography decoration="none" as={"caption"} tag={4} weight={2}>
										{item.disp_fio || item.master_fio}
									</Typography>
								</td>
								<td className={styles[`cell-${color}`]}>
									<Typography decoration="none" as={"caption"} tag={4} weight={2}>
										{item.user_fio}
									</Typography>
								</td>
								<td className={styles[`cell-${color}`]}>
									<Typography decoration="none" as={"caption"} tag={4} weight={2}>
										{item.text}
									</Typography>
								</td>
								<td className={styles[`cell-${color}`]}>
									<Typography decoration="none" as={"caption"} tag={4} weight={2}>
										{item.summ || ""}
									</Typography>
								</td>
								<td className={styles["cell-icon"]}>
									<Tooltip anchorId={`${item.id}_edit-button`} key={item.id} text={"Редактировать"} show>
										<Button
											id={`${item.id}_edit-button`}
											onClick={() => setEditItem(item.id)}
											size={UISizes.small}
											disabled={updateBtnDisabled}
											type={ButtonTypes.secondaryUncolored}
										>
											<i className="icon icon-edit-1" />
										</Button>
									</Tooltip>
								</td>
								<td className={styles["cell-icon"]}>
									<Tooltip anchorId={`${item.id}_remove-button`} key={item.id} text={"Удалить"} show>
										<Button
											disabled={deleteBtnDisabled}
											id={`${item.id}_remove-button`}
											onClick={() => removeItem(item)}
											size={UISizes.small}
											type={ButtonTypes.secondaryUncolored}
										>
											<i className="icon icon-delete" />
										</Button>
									</Tooltip>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default OkkTable;
