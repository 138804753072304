import { SidebarMenuContainer, SidebarMenuContent, SidebarMenuItem, SidebarMenuLogo } from "@common/sidebar-menu";
import { useEffect, useState } from "react";

import { useComponentVisible } from "@/hooks";
import { newWindowOpen } from "@/utils/new-window";
import { SidebarMenuItemsContainer } from "@common/sidebar-menu/sidebar-menu-item";
import { Tooltip } from "@common/tooltip";
import { SidebarMenuButtonsContent } from "@constants/sidebar-menu";
import { sliceIntoChunks } from "@utils/slice-into-chunks";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./sidebar-menu.module.scss";

export const SidebarMenu = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [sidebarContainerRef, isOpened, setIsOpened] = useComponentVisible(undefined, undefined, undefined, ["button"]);

	const [showContent, setShowContent] = useState(false);

	const handleOpenSidebar = () => {
		setIsOpened((prev) => !prev);
	};
	const SidebarMenuButtonsContentValues = SidebarMenuButtonsContent();

	const sidebarMenuButtons = sliceIntoChunks(Object.values(SidebarMenuButtonsContentValues), 8);

	const sidebarItemWithTooltip = cn({
		[styles["sidebar-item-with-tooltip"]]: !isOpened,
		[styles["sidebar-item"]]: isOpened,
		[styles["sidebar-item-visible"]]: showContent,
	});

	const isActiveHandler = (path: string) => location.pathname.includes(path);

	useEffect(() => {
		setShowContent(isOpened);
	}, [isOpened]);

	return (
		<SidebarMenuContainer ref={sidebarContainerRef} isOpened={isOpened} handleOpenSidebar={handleOpenSidebar}>
			<SidebarMenuLogo isOpened={isOpened} />
			<SidebarMenuContent isOpened={isOpened} handleOpenSidebar={handleOpenSidebar}>
				{sidebarMenuButtons.map((buttonsChunk, idx) => (
					<SidebarMenuItemsContainer
						key={buttonsChunk.map((chunk) => chunk?.PATHNAME).join("")}
						showDivider={idx === 0 || idx % 3 !== 0}
					>
						{buttonsChunk.map(({ ICON, NAME, PATHNAME, isSimpleLink, show }) => {
							if (!show) return null;

							const isActive = isActiveHandler(PATHNAME);
							const menuItem = <SidebarMenuItem icon={ICON} text={NAME} isOpened={isOpened} isActive={isActive} />;
							const tooltip = (
								<Tooltip anchorId={NAME} show={!isOpened} text={NAME} placement={"right"}>
									{menuItem}
								</Tooltip>
							);

							const handleClick = isSimpleLink
								? () => newWindowOpen(PATHNAME)
								: () => {
										navigate(PATHNAME);
										isOpened && setIsOpened(false);
									};

							return (
								<div key={PATHNAME} className={sidebarItemWithTooltip}>
									<a onClick={handleClick}>{tooltip}</a>
								</div>
							);
						})}
					</SidebarMenuItemsContainer>
				))}
			</SidebarMenuContent>
		</SidebarMenuContainer>
	);
};
