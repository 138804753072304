import React, { CSSProperties } from 'react'
import styles from './content-loader.module.scss'

export const ContentLoader = ({ background }: CSSProperties) => {
  return (
    <div className={styles.loader} style={{ background: background }}>
      <div className={styles.lds_dual_ring}></div>
    </div>
  )
}
