export const setRecursivelyParentId = <
  Item extends { id?: number; title?: string; items?: Item[] },
>(
  item: Item,
  parentId?: number,
): Item => {
  const hasItems = 'items' in item

  if (!hasItems) {
    return item
  }
  if (hasItems) {
    return {
      ...item,
      parentId,
      items:
        Array.isArray(item.items) && !!item.items.length
          ? item.items.map((item: Item) =>
              setRecursivelyParentId(item, parentId),
            )
          : [],
    }
  }
  return item
}

export const filterRecursivelyByTitle = <
  Item extends { id?: number; title?: string; items?: Item[] },
>(
  arr: Item[],
  inputValue: string,
): any => {
  return arr.map((obj) => {
    const { title, items, ...rest } = obj

    const filteredItems = Array.isArray(items)
      ? filterRecursivelyByTitle(items, inputValue).filter(Boolean)
      : items // Рекурсивно
    // фильтруем вложенные элементы

    if (
      typeof title === 'string' &&
      title.toLowerCase().includes(inputValue?.toLocaleLowerCase())
    ) {
      return {
        title,
        items: !!filteredItems?.filter(Boolean).length
          ? filteredItems?.filter(Boolean)
          : items,
        ...rest,
      } // Возвращаем
      // отфильтрованный объект
    }
  })
}
