import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import styles from './text-area.module.scss'
import type { TextAreaProps } from '@/components/common/text-area-container/text-area.types'
import { TextAreaContainer } from '@/components/common/text-area-container'
import { LabelContainer } from '@/components/common'

export const TextAreaDefult = ({
  state = 'default',
  labelSide,
  showLabel = true,
  label = '',
  value,
  showChildren = false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
  onChange = () => {},
  onFocus,
  onBlur,
  placeholder,
  onReset,
  className = '',
  id,
  disabled = false,
  mask,
  ...props
}: Omit<Partial<TextAreaProps>, 'ref'>) => {
  return (
    <LabelContainer showLabel={showLabel} label={label} labelSide={labelSide}>
      <TextAreaContainer
        state={state}
        value={value}
        showChildren={showChildren}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        id={id}
        disabled={disabled}
        mask={mask}
        {...props}>
        {value && (
          <div className={styles['input-button']}>
            <Button
              btnType={'button'}
              disabled={state === 'disabled'}
              type={ButtonTypes.secondaryUncolored}
              size={UISizes.medium}
              iconLeft={<i className={'icon icon-close'} />}
              onClick={onReset}
            />
          </div>
        )}
      </TextAreaContainer>
    </LabelContainer>
  )
}
