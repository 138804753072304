import { flexRender } from '@tanstack/react-table'

import { TableRow, TableRowItem } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableBody } from '@components/common'
import { InstallmentPlanTableHeader } from './installment-plan-table-header'

// @ts-ignore
export const InstallmentPlanTable = ({ table }) => {
  const tableCheck = table && table.getRowModel().rows.length

  return (
    <>
      <InstallmentPlanTableHeader table={table}/>
      <TableContainer style={{ paddingRight: '0' }}>
        <TableBody>
          {!tableCheck ? (
            <TableRow>
              <TableRowItem>Данные отсутствуют</TableRowItem>
            </TableRow>
          ) : (
            <>
              {table.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow key={row.id} style={{ background: 'transparent', height: 'auto' }}>
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <TableRowItem
                          style={{
                            maxWidth: `${cell.column.columnDef.maxSize}px`,
                            boxShadow: 'var(--gray-50-border-bottom)',
                          }}
                          key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableRowItem>
                      )
                    })}
                  </TableRow>
                )
              })}
            </>
          )}
        </TableBody>
      </TableContainer>
    </>
  )
}
