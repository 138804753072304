import { useState } from 'react'
import { InputStateType } from '../../../../shared/ui-kit/input/input.types'

import { SearchInput } from '../../../../entities'
import { useGlobalPhonerState } from '../../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import { useFormMachine } from '../../../../app/machines/form-factory-machine/useFormMachine'

export const Search = () => {
  const { fastSearchService } = useGlobalPhonerState()
  const [state, send] = useActor(fastSearchService)
  const { getError, onBlur, onChange } = useFormMachine({ state, send })

  const [inputState, setInputState] = useState<InputStateType>('default')

  const handleInputState = (value: InputStateType) => {
    setInputState(value)
    onBlur(state.context.data['call-log-list'], 'call-log-list')
  }

  return (
    <>
      <SearchInput
        id={'call-log-list'}
        placeholder={'Поиск по журналу'}
        inputValue={state.context.data['call-log-list'] || ''}
        inputState={inputState}
        handleChange={(value) => onChange(value, 'call-log-list')}
        handleInputState={handleInputState}
        errorMessage={getError('call-log-list')}
      />
    </>
  )
}
