import type { ViolationModalFormTypes, ViolationModalProps } from "./violation-modal.types";
import { ModalContainer } from "@components/common";
import { ModalBody, ModalHeader } from "@common/modal-container";
import { ViolationModalLeftContent } from "./left-content";
import styles from "./violation-modal.module.scss";
import { type AnyObject, Field, Form } from "react-final-form";
import { useMatch, useNavigate } from "react-router-dom";
import { RequestModalSelectInput } from "@components/modals/common-forms/request-modal-select-input";
import { ViolationLabels, ViolationNames } from "@constants/violation";
import { useParams } from "react-router";
import { RequestModalTextInput } from "@components/modals/common-forms/request-modal-input";
import { ButtonTypes } from "@components/button/button.types";
import { Button } from "@components/button";
import { RequestModalCheckbox } from "@components/modals/common-forms/request-modal-checkbox";
import { required } from "@/components/form/utils/validators";
import { useMastersMasterComplaintsFeedQuery, useRequestsMastersComplaintsStoreMutation, useRequestsShowQuery } from "@/state/api";
import { prepareSelectPresets } from "@/utils/prepeare-select-presets";
import { ContentLoader } from "@/components/content-loader";
import { useEffect, useState } from "react";
import { addSnack, clearSnacks } from "@/eventbus/events/snackbar";

export const ViolationModal = ({
	onClose,
	title,
	subtitle,
	onHandleBackButton,
	closeOnBlackoutClick,
	request,
	master,
}: Partial<ViolationModalProps>) => {
	const { requestId, masterId } = useParams();
	const [estimate, { isLoading, isError, isSuccess }] = useRequestsMastersComplaintsStoreMutation();

	const [lastSubmittedState, setLastSubmittedState] = useState<ViolationModalFormTypes | null>(null);
	const { data: complaintsData, isLoading: loadOptions } = useMastersMasterComplaintsFeedQuery();
	const navigate = useNavigate();
	const {
		data: requestData,
		isLoading: requestDataLoading,
		isError: requestDataError,
	} = useRequestsShowQuery({
		requestId: Number(requestId) || Number(request),
	});

	const match = useMatch("requests/feed/:requestId/violation/:masterId");
	const handleSubmit = async (values: ViolationModalFormTypes): Promise<boolean | AnyObject> => {
		setLastSubmittedState(values);
		const result = await estimate({
			masterId: master ? Number(master) : Number(masterId),
			requestId: request ? Number(request) : Number(requestId),
			requestsRequestMastersComplaintsStore: {
				text: values.comment,
				type: values.category as unknown as number,
				payed: values.isRepaid,
				summ: values.summ,
			},
		});
		// .unwrap()
		// .then(() => {
		//   if (onClose) onClose()
		//   else if (match && match?.pathname) navigate('/')
		//   else navigate('../')
		// })
		// .catch((e) => {
		//   form.reset()
		//   form.restart()
		//   console.warn(e)
		// })

		return result;
	};
	useEffect(() => {
		if (requestDataError) navigate("../../");
		if (requestData && !requestData?.data?.abilities?.premium_master) {
			navigate("../../");
		}
	}, [requestData, requestDataError]);

	useEffect(() => {
		if (isError) {
			addSnack({
				type: "error",
				eventName: "violation-request-error",
				text: "Не удалось отправить оценку.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => (lastSubmittedState ? handleSubmit(lastSubmittedState) : {}),
			});
		}

		if (isSuccess) {
			if (onClose) onClose();
			else if (match?.pathname) navigate("/");
			else navigate("../");
		}
	}, [isError, isSuccess]);

	return (
		<>
			<Form onSubmit={handleSubmit}>
				{({ form, values, handleSubmit }) => {
					return (
						<ModalContainer
							onClose={() => {
								if (onClose) {
									onClose();
									clearSnacks();
								} else if (match?.pathname) {
									navigate("/");
									clearSnacks();
								} else {
									navigate("../");
									clearSnacks();
								}
							}}
							showBlackout
							closeOnBlackoutClick={closeOnBlackoutClick}
							className={styles["violation-modal"]}
						>
							<ModalHeader
								showLeftContent
								contentLeft={
									<ViolationModalLeftContent
										showBackButton={false}
										title={title}
										subtitle={subtitle}
										onHandleBackButton={onHandleBackButton}
									/>
								}
								showCloseButton
								onClose={() => {
									if (onClose) {
										onClose();
										clearSnacks();
									} else if (match?.pathname) {
										navigate("/");
										clearSnacks();
									} else {
										navigate("../");
										clearSnacks();
									}
								}}
							/>
							<form onSubmit={handleSubmit}>
								<ModalBody>
									{loadOptions || requestDataLoading ? (
										<ContentLoader />
									) : (
										<div className={styles["violation-content"]}>
											<div className={styles["violation-inputs-container"]}>
												<div className={styles["violation-select"]}>
													<Field<ViolationModalFormTypes["category"]>
														name={ViolationNames.category}
														type={"select"}
														validate={required}
														label={ViolationLabels.category}
														options={prepareSelectPresets(complaintsData) || []}
														component={RequestModalSelectInput}
													/>
												</div>
												{((values.category as unknown as number) === 1 ||
													(values.category as unknown as number) === 3) && (
													<>
														<div className={styles["violation-input"]}>
															<Field<ViolationModalFormTypes["summ"]>
																name={ViolationNames.summ}
																type={"text"}
																validate={required}
																label={ViolationLabels.summ}
																component={RequestModalTextInput}
															/>
														</div>

														<div className={styles["violation-checkbox"]}>
															<Field<ViolationModalFormTypes["isRepaid"]>
																name={ViolationNames.isRepaid}
																type={"checkbox"}
																label={ViolationLabels.isRepaid}
																component={RequestModalCheckbox}
															/>
														</div>
													</>
												)}
											</div>

											<Field<ViolationModalFormTypes["comment"]>
												name={ViolationNames.comment}
												type={"text"}
												label={ViolationLabels.comment}
												component={RequestModalTextInput}
												validate={required}
											>
												{({ input, meta }) => {
													return (
														<RequestModalTextInput
															label={ViolationLabels.comment}
															labelSide="top"
															input={input}
															meta={meta}
														/>
													);
												}}
											</Field>
											<Button
												btnType={"submit"}
												type={ButtonTypes.primary}
												onClick={() => {
													form.submit();
												}}
												isLoading={isLoading}
												disabled={!form.getState().valid}
												className={styles["violation-btn"]}
											>
												Оценить
											</Button>
											{/*{isError && (*/}
											{/*  <Typography*/}
											{/*    as={'paragraph'}*/}
											{/*    decoration={'none'}*/}
											{/*    tag={3}*/}
											{/*    className={styles['error-text']}*/}
											{/*  >*/}
											{/*    Не удалось оценить мастера: сервер не отвечает или*/}
											{/*    временно не доступен, попробуйте еще раз.*/}
											{/*  </Typography>*/}
											{/*)}*/}
										</div>
									)}
								</ModalBody>
							</form>
						</ModalContainer>
					);
				}}
			</Form>
		</>
	);
};
