import { RequestCardActionButtonProps } from '@common/request-card/request-card-actions/request-card-action-button/request-card-action-button.types'
import { nanoid } from '@reduxjs/toolkit'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Tooltip } from '@common/tooltip'
import { Badge } from '@common/badge'
import styles from './request-card-action-button.module.scss'

export const RequestCardActionButton = ({
  tooltipAnchorId,
  tooltipText,
  disabled,
  handleClick,
  icon,
  badgeText,
  showTooltip=true,
  buttonType = ButtonTypes.secondary,
  iconSize = 'tiny'
}: RequestCardActionButtonProps) => {
  return (
    <Tooltip
      anchorId={tooltipAnchorId}
      key={nanoid()}
      text={tooltipText}
      show={showTooltip}
      tooltipOffset={[0, 15]}
      placement={'bottom'}
    >
      <div className={styles['request-card-action-btn__container']}>
        <Button
          type={ButtonTypes[buttonType]}
          size={UISizes.medium}
          disabled={disabled}
          onClick={() => {
            handleClick()
          }}
          iconLeft={<i className={`icon ${icon} icon-${iconSize}`} />}
        />
        {badgeText && (
          <Badge className={styles['request-card-action-btn__badge']}>
            {String(badgeText)}
          </Badge>
        )}
      </div>
    </Tooltip>
  )
}
