import { AnyObject } from '@global-types/utils-type'

export const formatObjectToQueryString = (object: AnyObject): string => {
  // let queryString = ""
  //
  // object && Object.entries(object).forEach(([key, value]) => {
  // 	if (value !== undefined && value !== null && value !== "") {
  // 		queryString += `${key}=${value}&`
  // 	}
  // })
  // return queryString

  const str = []
  for (const p in object)
    if (
      object.hasOwnProperty(p) &&
      object[p] !== null &&
      object[p] !== '' &&
      object[p] !== undefined
    ) {
      if (Array.isArray(object[p])) {
        object[p].forEach((item: any) => {
          str.push(encodeURIComponent(p) + '[]=' + encodeURIComponent(item))
        })
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]))
      }
    }
  return str.join('&')
}
