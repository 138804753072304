import { DeclineCurrentCall } from '../../../features/current-call/decline'
import { ReferCurrentCallToAutoInformer } from '../../../features/current-call/refer-current-call-to-autoinformer/ui/refer-current-call-to-autoinformer'
import styles from './call-control.module.scss'

export const CallControlWidget = () => {
  return (
    <div className={styles['call-control']}>
      <ReferCurrentCallToAutoInformer />
      <DeclineCurrentCall />
    </div>
  )
}
