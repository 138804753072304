import cn from "clsx";
import type { ElementType } from "react";
import styles from "./button.module.scss";
import { type ButtonProps, defaultButtonElement } from "./button.types";

export const Button = <T extends ElementType = typeof defaultButtonElement>({
	mRef,
	children,
	renderIcon,
	iconPlacement,
	className = "",
	as,
	size = "m",
	variant = "primary",
	width = "content",
	gutter = "m",
	justifyContent = "initial",
	isActive = false,
	...otherProps
}: ButtonProps<T>) => {
	const TagName = as || defaultButtonElement;

	const w = width === "icon" ? `icon-${String(size)}` : `width-${String(width)}`;
	const j = `j-${justifyContent?.split("-").length > 1 ? justifyContent?.split("-")[1] : justifyContent?.split("-")[0]}`;

	const buttonClasses = cn(styles.button, className, {
		[styles[`size-${String(size)}`] as string]: true,
		[styles[w] as string]: true,
		[styles[j] as string]: true,
		[styles[`variant-${variant}`] as string]: true,
		[styles[`gutter-${String(gutter)}`] as string]: true,
	});

	if (width === "icon") {
		return (
			<TagName ref={mRef} tabIndex={otherProps.tabIndex || 0} data-btn-active={isActive} className={buttonClasses} {...otherProps}>
				{renderIcon}
			</TagName>
		);
	}

	if (iconPlacement) {
		return (
			<TagName ref={mRef} tabIndex={otherProps.tabIndex || 0} data-btn-active={isActive} className={buttonClasses} {...otherProps}>
				{iconPlacement === "left" ? (
					<>
						{renderIcon} {children}
					</>
				) : (
					<>
						{children}
						{renderIcon}
					</>
				)}
			</TagName>
		);
	}

	return (
		<TagName ref={mRef} tabIndex={otherProps.tabIndex || 0} data-btn-active={isActive} className={buttonClasses} {...otherProps}>
			{children}
		</TagName>
	);
};
