import { StatusLabelProps } from '@components/modals/common-forms/status-label/status-label.types'
import cn from 'classnames'
import styles from './status-label.module.scss'

export const StatusLabel = ({
  children,
  type = 'general',
  className,
}: StatusLabelProps) => {
  const statusLabelClasses = cn(
    styles['status-label'],
    styles[`type-${type}`],
    className,
  )

  return (
    <div className={statusLabelClasses}>
      <span className={styles['status-label__text']}>{children}</span>
    </div>
  )
}
