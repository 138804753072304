import React from 'react'
import { RequestModalSelectInputProps } from './request-modal-select-input.types'
import { getInputState } from '@components/form/utils/getFieldState'
import { Select } from '@/components'
import { AnyObject, BasicInputValues } from '@global-types/utils-type'
import { defaultStringValueFormatter } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-select-input/value-formatter'
import { defaultItemToString } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-select-input/default-item-to-string'
import { LabelContainer } from '@common/index'

export const RequestModalSelectInput = <T extends AnyObject>({
  input,
  meta,
  label,
  options,
  searchKey,
  ...props
}: RequestModalSelectInputProps<T>) => {
  return (
    <LabelContainer showLabel={!!label} label={label} labelSide={'left'}>
      <Select<T>
        state={getInputState(meta, input)}
        itemToString={defaultItemToString<
          RequestModalSelectInputProps<T>['value']
        >('data')}
        valueFormatter={defaultStringValueFormatter('data', 'title')}
        itemsFormatter={defaultItemToString('title')}
        searchKey={'title'}
        {...input}
        {...props}
        onReset={() => input.onChange(undefined)}
        options={options || []}
      />
    </LabelContainer>
  )
}
