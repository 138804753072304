/*eslint-disable*/
import styles from '@pages/request-page/log-card/left-content/comments/request-comments.module.scss'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import React from 'react'
import { RequestCommentsActionButtonsProps } from '@pages/request-page/log-card/left-content/comments/action-buttons/request-comments-action-buttons.types'
import { useUserProfileInfo } from '@/state/selector'

export const RequestCommentsActionButtons = ({
  comment,
  handleClickEditButton,
  handleClickDelButton,
}: RequestCommentsActionButtonsProps) => {
  const { user_id, abilities, text, id } = comment
  const profile = useUserProfileInfo()

  return (
    <div className={styles['action-btns']}>
      {profile?.id === user_id && (
        <Button
          type={ButtonTypes.secondaryUncolored}
          btnType={'button'}
          disabled={!abilities?.update}
          size={UISizes.medium}
          iconLeft={<i className={`icon icon-edit-1`} />}
          onClick={handleClickEditButton}
        />
      )}
      <Button
        type={ButtonTypes.dangerUncolored}
        btnType={'button'}
        disabled={!abilities?.delete}
        size={UISizes.medium}
        iconLeft={<i className={`icon icon-delete`} />}
        onClick={handleClickDelButton}
      />
    </div>
  )
}
