import { AcceptCallButton } from "../../../../shared/components";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { useEffect } from "react";
import { ACCEPT_INCOMING_CALL } from "../../../../shared/event-bus/types/event-action.types";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";

export const AcceptIncomingCall = () => {
	const { acceptCall } = usePhoner();
	const { isCurrentTabMasterTab } = useMasterTab();
	const { on } = useEventBus();
	const { sessionsDTO } = useSessionContext();
	const incomingCallSession = sessionsDTO.filter((session) => session.status === SessionStatus.RINGING)?.[0];

	const handleAcceptCall = () => {
		acceptCall(incomingCallSession?.sessionId ?? "");
	};

	useEffect(() => {
		const unsubscribeAnswerIncomingCall = on(ACCEPT_INCOMING_CALL, (payload) => {
			acceptCall(payload.sessionId ?? "", false);
		});

		return () => unsubscribeAnswerIncomingCall();
	}, [isCurrentTabMasterTab, sessionsDTO]);

	return <AcceptCallButton handleAcceptCall={handleAcceptCall} isAcceptCallButtonDisabled={false} />;
};
