import { Title } from "@common/title";
import style from "../../installment.module.scss";
import { Container, Col, Row } from "react-grid-system";
import { InstallmentFields, InstallmentServerData } from "@/constants/installment";
import { InstallmentInfoLeftColumn } from "./installment-info-left-column";
import { InstallmentInfoRightColumn } from "./installment-info-right-column";
import { Field, FormSpy, Form } from "react-final-form";
import { RequestModalTextInput } from "../../../common-forms/request-modal-input";
import { generateDateRegexp } from "@/components/headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import { useEffect, useState } from "react";
import { InfoInstallmentCreate, RequestsIndexApiResponse, useRequestsChangeInstallmentMutation } from "@/state/api";
import { addSnack, clearSnacks } from "@/eventbus/events/snackbar";
import { createInstallmentValidation } from "@/utils/create-installment-validation";
import { MethodProps } from "../../installment.types";
import { validateOnlyNumbersWithComma } from "@/utils/validateOnlyNumbers";
import {
	commentOnlySpecialSymbolsValidator,
	composeParse,
	composeValidators,
	firstSymbolCantBeSpaceValidator,
	firstSymbolCantBeZeroValidator,
	installmentDateValidator,
	installmentPeriodLengthParser,
} from "@/components/form/utils/validators";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { prepareInstallmentDataCreateRequest } from "@/utils/prepare-installment-data-create-request";
import { InstallmentInfoStatusButtons } from "./installment-info-status-buttons";
import { useAppSelector } from "@/state/selector";
import { InstallmentStatus } from "../installment-status";

// @ts-ignore
export const InstallmentInfoForm = ({ data, fullcodeId, requestId, canEdit, canCreate, canView, canViewFinInfo }) => {
	const isHasInstallment = data?.[InstallmentServerData.agreement];
	const indexStore = useAppSelector<Partial<RequestsIndexApiResponse>>((state) => {
		const data = state.api.queries.requestsIndex?.data as RequestsIndexApiResponse;
		if (data) return data;
		return {};
	});
	const card = indexStore?.data?.find((card) => card.fullcode === fullcodeId);
	const amountAvailability = card?.fin_block?.fromClient;
	const containerStyle = {
		padding: 0,
		margin: 0,
	};
	const rowStyle = {
		gap: "36px",
		padding: 0,
		margin: 0,
	};
	const colStyle = {
		display: "flex",
		"flex-direction": "column",
		gap: "6px",
		padding: 0,
		margin: 0,
	};

	const [installmentData, setInstallmentData] = useState<any>(data);
	const initialValues = {
		[InstallmentServerData.agreement]: installmentData?.[InstallmentServerData.agreement] ?? "",
		[InstallmentServerData.date_begin]: installmentData?.[InstallmentServerData.date_begin] ?? "__.__.____",
		[InstallmentServerData.contract_date]: installmentData?.[InstallmentServerData.contract_date] ?? "__.__.____",
		[InstallmentServerData.period]:
			(installmentData?.[InstallmentServerData.period] &&
				validateOnlyNumbersWithComma(installmentData?.[InstallmentServerData.period])) ??
			6,
	};

	const [installmentSubmitForm, setInstallmentSubmitForm] = useState<boolean>(false);
	const [showForm, setShowForm] = useState<boolean>(false);
	const [unwrapError, setUnwrapError] = useState<boolean>(false);
	const [initialState, setInitialState] = useState<InfoInstallmentCreate | null>(null);
	const [lastSubmittedFormState, setLastSubmittedFormState] = useState<InfoInstallmentCreate | null>(null);

	const [requestsChangeInstallment, { isLoading: isCreateNewRequestLoading, isError: isCreateNewRequestError }] =
		useRequestsChangeInstallmentMutation();

	const handleInstallmentApiService = (values: InfoInstallmentCreate, method: MethodProps | null) => {
		if (method) {
			requestsChangeInstallment({
				values: prepareInstallmentDataCreateRequest(values),
				method,
				requestId,
			})
				.unwrap()
				.then((res: any) => {
					setShowForm(false);
					setInstallmentData(res.data);
				})
				.catch((e) => {
					setUnwrapError(e?.data?.message);
					console.error(e?.data?.message);
				});
		} else {
			return;
		}
	};
	const handleSubmit = (values: any) => {
		clearSnacks();
		setInitialState(values);
		setLastSubmittedFormState(values);

		const errors = createInstallmentValidation(values);
		if (Object.keys(errors).length > 0) {
			return errors;
		}
		if (isHasInstallment) {
			handleInstallmentApiService(values, MethodProps.put);
		} else {
			handleInstallmentApiService(values, MethodProps.post);
		}
	};

	const getModifiedFields = ({
		modifiedFormValues,
		formValues,
	}: {
		modifiedFormValues: { [key: string]: boolean };
		formValues: { [key: string]: unknown };
	}) => {
		let result = {};

		for (const key in modifiedFormValues) {
			if (modifiedFormValues[key] === true) {
				result = {
					...result,
					[key]: formValues[key],
				};
			}
		}

		return {
			length: Object.keys(result).length,
			fields: result,
		};
	};

	const onClickCancel = (form: any) => {
		setInstallmentSubmitForm(false);
		form.reset();
		form.setConfig("initialValues", initialValues);
		setShowForm(false);
	};
	const onClickSave = (form: any) => {
		form.submit();
	};
	const onClickEdit = () => setShowForm(true);
	const onClickAdd = () => {
		setInstallmentSubmitForm(true);
		setShowForm(true);
	};

	useEffect(() => {
		if (isCreateNewRequestError || unwrapError) {
			addSnack({
				type: "error",
				eventName: "create-request-error",
				text: "Не удалось создать заявку",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleSubmit(lastSubmittedFormState as InfoInstallmentCreate),
			});
		}
	}, [isCreateNewRequestError, unwrapError]);

	useEffect(() => {
		setInstallmentData(data);
	}, [data]);

	useEffect(() => {
		setInitialState({
			[InstallmentServerData.agreement]: data?.[InstallmentServerData.agreement] ?? "",
			[InstallmentServerData.date_begin]: data?.[InstallmentServerData.date_begin] ?? "__.__.____",
			[InstallmentServerData.contract_date]: data?.[InstallmentServerData.contract_date] ?? "__.__.____",
			[InstallmentServerData.period]: data?.[InstallmentServerData.period] ?? 6,
		});
	}, [data]);

	return (
		<Form subscription={{ values: true }} onSubmit={handleSubmit} initialValues={initialState}>
			{({ form }) => {
				return (
					<div className={style["installment-info"]}>
						<div className={style["installment-info_header"]}>
							<div style={{ display: "flex", gap: "12px" }}>
								<Title as={"h5"} weight={1}>
									Информация о рассрочке
								</Title>
								{data?.[InstallmentServerData.status] && (
									<InstallmentStatus
										status={data?.[InstallmentServerData.status]}
										statusName={data?.[InstallmentServerData.status_name]}
									/>
								)}
							</div>
							<InstallmentInfoStatusButtons
								amountAvailability={amountAvailability}
								// isLoading={isLoading}
								showAddButton={!isHasInstallment && amountAvailability && !installmentSubmitForm}
								showEditButton={isHasInstallment && !showForm}
								showCancelSaveButton={showForm || installmentSubmitForm}
								showForm={showForm}
								form={form}
								canEdit={canEdit}
								canCreate={canCreate}
								canView={canView}
								onClickCancel={onClickCancel}
								onClickSave={onClickSave}
								onClickEdit={onClickEdit}
								onClickAdd={onClickAdd}
							/>
						</div>
						{isCreateNewRequestLoading ? (
							<div className={style["control-buttons-loading"]}>
								<LoadingSpinner />
							</div>
						) : isHasInstallment || installmentSubmitForm ? (
							<Container style={containerStyle} fluid>
								<Row style={rowStyle} direction="row">
									<Col style={colStyle}>
										{showForm && (canCreate || canEdit) ? (
											<Row gutterWidth={2} justify={"between"}>
												<FormSpy
													subscription={{
														values: true,
														modified: true,
													}}
													onChange={(props) => {
														const modifiedFields = getModifiedFields({
															formValues: props.values,
															modifiedFormValues: props.modified as {
																[key: string]: boolean;
															},
														});
													}}
												/>
												<Col
													xs={12}
													xl={12}
													xxl={12}
													md={12}
													style={{ display: "flex", flexDirection: "column", gap: "6px" }}
												>
													<Field
														width={"installment"}
														name={InstallmentServerData.agreement}
														type={"text"}
														label={InstallmentFields.contractNumber}
														component={RequestModalTextInput}
														validate={composeValidators(
															firstSymbolCantBeSpaceValidator,
															commentOnlySpecialSymbolsValidator,
														)}
													/>
													<Field
														mask={generateDateRegexp({ separator: "." })}
														width={"installment"}
														name={InstallmentServerData.date_begin}
														type={"text"}
														label={InstallmentFields.dateOfCommencementOfInstallment}
														component={RequestModalTextInput}
														validate={composeValidators(installmentDateValidator)}
													/>
													{/* <InputDefaultCalendar mutators={form.mutators} /> */}
													<Field
														mask={generateDateRegexp({ separator: "." })}
														width={"installment"}
														name={InstallmentServerData.contract_date}
														type={"text"}
														label={InstallmentFields.dateOfSigningTheContract}
														component={RequestModalTextInput}
														validate={composeValidators(installmentDateValidator)}
													/>
													<Field
														width={"installment"}
														name={InstallmentServerData.period}
														type={"number"}
														label={InstallmentFields.installmentPeriod}
														defaultValue={6}
														component={RequestModalTextInput}
														validate={composeValidators(
															firstSymbolCantBeSpaceValidator,
															firstSymbolCantBeZeroValidator,
														)}
														parse={composeParse(installmentPeriodLengthParser, (value) =>
															value ? (String(value).replace(/[^0-9]+/g, "") as unknown as number) : value,
														)}
													/>
												</Col>
											</Row>
										) : canView ? (
											<>
												<InstallmentInfoLeftColumn data={installmentData} />
											</>
										) : null}
									</Col>
									{canView && canViewFinInfo ? (
										<Col style={colStyle}>
											<InstallmentInfoRightColumn
												data={installmentData}
												installmentSubmitForm={showForm}
												canViewFinInfo={canViewFinInfo}
											/>
										</Col>
									) : null}
								</Row>
							</Container>
						) : null}
					</div>
				);
			}}
		</Form>
	);
};
