export const checkIsCorrectInputNumber = (value: string) => {
	const normalizePhoneNumber = value.replace(/[^\d+]/g, "").includes("+7")
		? value.replace(/[^\d+]/g, "").slice(2)
		: value.replace(/[^\d+]/g, "");

	if (normalizePhoneNumber.length < 3 || normalizePhoneNumber.length === 5) {
		return false;
	}

	if (normalizePhoneNumber.length < 11 && normalizePhoneNumber.length >= 5) {
		return false;
	}

	return true;
};
