import { nanoid } from '@reduxjs/toolkit'
import { DropdownMenuItem } from '@common/multi-select/multi-select/dropdown-menu/dropdown-menu-item'
import { TreeRecursiveProps } from '@common/multi-select/multi-select/dropdown-menu/dropdown-menu-item/tree-recursive/tree-recursive.types'
import styles from './tree-recursive.module.scss'
import { isObjectInMap } from '@utils/find-item'

export const TreeRecursive = <Item extends { [key: string]: any }>({
  disableGroupsWithoutChilds,
  isGroupSelectable,
  items,
  dropdownMenuItemVariant,
  dropdownMenuItemWithCheckbox = false,
  isDataNested,
  getItemProps,
  highlightedIndex,
  isOpen,
  selectItem,
  selectedItems,
  handleCollapseNestedItems,
  openedNestedItems,
  allowOnlyChildSelection = false,
}: TreeRecursiveProps<Item>) => {
  if (!Array.isArray(items)) return <></>

  return (
    <>
      {items.map((item, index) => {
        if (!item) {
          return <></>
        }
        const hasItems = item && Object.hasOwn(item, 'items')
        if (!hasItems || item.items.length === 0) {
          const itemProps = {
            ...getItemProps({
              item,
              key: item.id,
              index: item.id,
              id: item.id,
            }),
          }

          return (
            <div className={styles['dropdown-menu-item']}>
              <DropdownMenuItem<Item>
                disableGroupsWithoutChilds={disableGroupsWithoutChilds ? item.items?.length === 0 : false}
                key={`${index} + ${item.title} + ${item.id}`}
                selectItem={selectItem}
                selectedItems={selectedItems}
                itemProps={itemProps}
                item={item}
                isDataNested={isDataNested}
                isActive={isObjectInMap(selectedItems, item.id, item.title)}
                variant={dropdownMenuItemVariant}
                withCheckbox={dropdownMenuItemWithCheckbox}
                handleCollapseNestedItems={handleCollapseNestedItems}
                openedNestedItems={openedNestedItems}
              />
            </div>
          )
        }

        if (hasItems && item.items.length > 0) {
          const itemProps = {
            ...getItemProps({
              item,
              key: item?.id,
              index: item?.id,
              id: item?.id,
            }),
          }
          const { items } = item

          const isActive = isObjectInMap(selectedItems, item.id, item.title)

          return (
            <div className={styles['dropdown-multi-menu-item']}>
              <DropdownMenuItem<Item>
                disableGroupsWithoutChilds={disableGroupsWithoutChilds ? item.items?.length === 0 : false}
                key={`${index} + ${item.title} + ${item.id}`}
                selectItem={selectItem}
                selectedItems={selectedItems}
                itemProps={itemProps}
                item={item}
                isDataNested={isDataNested}
                isActive={isActive}
                variant={dropdownMenuItemVariant}
                withCheckbox={dropdownMenuItemWithCheckbox}
                handleCollapseNestedItems={handleCollapseNestedItems}
                openedNestedItems={openedNestedItems}
                allowOnlyChildSelection={allowOnlyChildSelection}>
                <div className={`${styles['tree-recursive-children__container']} ${styles.subItem}`}>
                  <TreeRecursive<Item>
                    disableGroupsWithoutChilds={disableGroupsWithoutChilds}
                    isGroupSelectable={isGroupSelectable}
                    key={nanoid()}
                    selectItem={selectItem}
                    selectedItems={selectedItems}
                    isOpen={isOpen}
                    highlightedIndex={highlightedIndex}
                    items={item.items}
                    dropdownMenuItemVariant={dropdownMenuItemVariant}
                    dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
                    getItemProps={getItemProps}
                    isDataNested={isDataNested}
                    handleCollapseNestedItems={handleCollapseNestedItems}
                    openedNestedItems={openedNestedItems}
                    allowOnlyChildSelection={allowOnlyChildSelection}
                  />
                </div>
              </DropdownMenuItem>
            </div>
          )
        }
      })}
    </>
  )
}
