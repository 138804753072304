import { useActor } from "@xstate/react";
import { Button, Icon, Panel } from "../../../../shared";
import styles from "./connect-two-lines.module.scss";
import { useGlobalPhonerState } from "../../../../app/providers/xstate-provider";
import { CauseReason, usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { useSharedWorker } from "../../../../shared/contexts/shared-worker";
import { START_CONNECTING_TWO_LINES, SUCCESS_TWO_LINES_CONNECTION } from "../../../../shared/event-bus/types/event-action.types";
import { useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";

export const ConnectTwoLines = () => {
	const { phonerSecondLineService } = useGlobalPhonerState();
	const [_, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);

	const { isCurrentTabMasterTab } = useMasterTab();
	const { sessions, setSessions } = usePhoner();
	const { sendMessage } = useSharedWorker();
	const { setSessionsDTO, sessionsDTO } = useSessionContext();

	const handleSuccessConnectionTwoLines = () => {
		const [firstSession, secondSession] = sessions;

		if (!firstSession || !secondSession) return;

		firstSession.data = { ...firstSession.data, successTwoLinesConnection: true, customCause: CauseReason.LOCAL_TERMINATE };
		secondSession.data = { ...secondSession.data, successTwoLinesConnection: true, customCause: CauseReason.LOCAL_TERMINATE };

		firstSession.terminate();
		secondSession.terminate();
		sendPhonerSecondLineEvents("success");
		setSessions([]);
		setSessionsDTO([]);

		sendMessage({
			eventName: SUCCESS_TWO_LINES_CONNECTION,
			eventPayload: { sessionIds: sessionsDTO.map((session) => session.sessionId || "") },
		});
	};

	const handleConnectTwoLines = () => {
		if (isCurrentTabMasterTab) {
			const [firstSession, secondSession] = sessions;

			try {
				firstSession &&
					secondSession &&
					firstSession.refer(secondSession.remote_identity.uri, {
						replaces: secondSession,
						eventHandlers: {
							accepted: () => {
								handleSuccessConnectionTwoLines();
							},
							failed: (e: any) => {
								handleSuccessConnectionTwoLines();
								console.info("two lines failed", "error", e);
							},
							process: () => {
								console.info("two lines process");
							},
							requestFailed: () => {
								handleSuccessConnectionTwoLines();
								console.info("requestFailed");
							},
							requestSucceeded: () => {
								console.info("requestSucceeded");
							},
						},
					});

				sendMessage({
					eventName: START_CONNECTING_TWO_LINES,
					eventPayload: { sessionIds: sessionsDTO.map((session) => session.sessionId || "") },
				});

				sendPhonerSecondLineEvents("start connecting two lines");
			} catch (e) {
				console.info("e", e);
			}
		} else {
			sendMessage({
				eventName: START_CONNECTING_TWO_LINES,
				eventPayload: { sessionIds: sessionsDTO.map((session) => session.sessionId || "") },
			});
			sendPhonerSecondLineEvents("start connecting two lines");
		}
	};

	const isConnectTwoLinesDisabled = false;

	return (
		<Panel className={styles.container}>
			<Button
				disabled={isConnectTwoLinesDisabled}
				onClick={handleConnectTwoLines}
				size="m"
				variant="secondary"
				width="full-w"
				iconPlacement={"left"}
				renderIcon={<Icon icon={"icon-merge-call"} />}
			>
				Соединить
			</Button>
		</Panel>
	);
};
