import {
  SidebarItemTextContentProps,
} from '@common/sidebar-container/sidebar-container.types'
import styles from './sidebar-item.module.scss'


export const SidebarItemTextContent = ({ children }: SidebarItemTextContentProps) => {
  return (<div className={styles['sidebar-item-text-content']}>
    {children}
  </div>)
}
