import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table'
import { InstallmentPlanTable } from './installment-plan-table'
import { createColumnHelper } from '@tanstack/react-table'
import { InstallmentScheduleServerData, InstallmentPlanColWidth } from '@/constants/installment'
import { currencyFormatter } from '@/utils/currency-formatter'
import { TypographyHoc } from '../installment-hoc/installment-typography-hoc'
import { InstallmentPlanProps } from './installment-plan.types'

export const InstallmentPlan = ({ data, canViewFinInfo }: InstallmentPlanProps) => {
  const columnHelper = createColumnHelper<any>()
  const columnsWorkControl: any = [
    columnHelper.accessor(InstallmentScheduleServerData.num, {
      maxSize: InstallmentPlanColWidth[InstallmentScheduleServerData.num],
      id: InstallmentScheduleServerData.num,
      header: '№',
      cell: (info) => {
        const row = info.row.original
        const children = info.renderValue()

        return <TypographyHoc children={children} row={row} />
      },
    }),
    columnHelper.accessor(InstallmentScheduleServerData.date, {
      size: InstallmentPlanColWidth.other,
      id: InstallmentScheduleServerData.date,
      header: 'Срок платежа',
      cell: (info) => {
        const children = info.renderValue() ?? '\u2014'
        const row = info.row.original

        return <TypographyHoc children={children} row={row} />
      },
    }),
    columnHelper.accessor(InstallmentScheduleServerData.dept, {
      size: InstallmentPlanColWidth.other,
      id: InstallmentScheduleServerData.dept,
      header: 'Сумма',
      cell: (info) => {
        const children = currencyFormatter.format(info.renderValue())
        const row = info.row.original

        return <TypographyHoc children={children} row={row} />
      },
    }),
    columnHelper.accessor(InstallmentScheduleServerData.pay_date, {
      size: InstallmentPlanColWidth.other,
      id: InstallmentScheduleServerData.pay_date,
      header: 'Дата платежа',
      cell: (info) => {
        const children = info.renderValue() ?? '\u2014'
        const row = info.row.original

        return <TypographyHoc children={children} row={row} />
      },
    }),
    columnHelper.accessor(InstallmentScheduleServerData.fee, {
      size: InstallmentPlanColWidth.other,
      id: InstallmentScheduleServerData.fee,
      header: 'Внесено',
      cell: (info) => {
        const children = currencyFormatter.format(info.renderValue())
        const row = info.row.original

        return <TypographyHoc children={children} row={row} />
      },
    }),
    columnHelper.accessor(InstallmentScheduleServerData.type, {
      header: 'Тип платежа',
      size: InstallmentPlanColWidth.other,
      id: InstallmentScheduleServerData.type,
      cell: (info) => {
        const row = info.row.original
        const children = info.renderValue() || '\u2014'

        return <TypographyHoc children={children} row={row} />
      },
    }),
  ]

  // Гидрация таблицы
  const pagination = {
    pageIndex: 0,
    pageSize: data?.length || 10,
  }
  const table = useReactTable({
    data: data || [],
    columns: columnsWorkControl,
    state: {
      pagination,
      columnVisibility: {
        [InstallmentScheduleServerData.fee]: canViewFinInfo,
        [InstallmentScheduleServerData.dept]: canViewFinInfo,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    enablePinning: true,
  })

  return <InstallmentPlanTable table={table} />
}
