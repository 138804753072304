export const animationVariants = {
  panelTrey: {
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
  phoner: {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
    },
    exit: {
      scale: 0,
      transition: { duration: 0.16 },
    },
  },
};
