import { useEffect, useRef } from 'react'

export const usePrevious = <T = string | boolean | number>(value?: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
