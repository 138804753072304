import { useCallback, useEffect, useRef, useState } from 'react'
import { ValidRefTarget } from '@hooks/use.types'

export const useComponentVisible = <ElemRefType extends Partial<ValidRefTarget> = ValidRefTarget>(
  initialIsVisible: boolean | undefined = false,
  callBack = (event: Event | undefined) => {},
  additionalIf = true,
  classNameToIgnore?: string | string[],
) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef<ElemRefType | any>(null)

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element

      if (
        target && target.classList && classNameToIgnore && Array.isArray(classNameToIgnore)
          ? classNameToIgnore?.filter(
              (className) => typeof target.className.includes !== 'undefined' && target?.className?.includes(className),
            ).length > 0
          : typeof classNameToIgnore === 'string' &&
            typeof target.className.includes !== 'undefined' &&
            target?.className?.includes(classNameToIgnore)
      ) {
        return
      }
      if (ref.current && ref.current.contains && !ref.current.contains(event.target) && isComponentVisible && additionalIf) {
        setIsComponentVisible(false)
        if (callBack && typeof callBack === 'function') callBack(event)
      }
    },
    [additionalIf, callBack, isComponentVisible],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])

  return [ref, isComponentVisible, setIsComponentVisible] as const
}
