import React from "react";
import { Icon } from "../../../index";
import styles from "./control-button.module.scss";
import type { ReactNode } from "react";

type ControlButtonProps = {
	keyboardKeys?: Array<string | ReactNode>;
};
export const ControlButton = ({ keyboardKeys }: ControlButtonProps) => {
	return (
		<div className={styles.container}>
			{keyboardKeys?.length &&
				keyboardKeys?.map((key, idx) => {
					return (
						<React.Fragment key={idx}>
							<span className={styles["control-button"]}>{key}</span>
							{idx !== keyboardKeys?.length - 1 && (
								<span className={styles["button-plus"]}>
									<Icon icon={"icon-plus"} />
								</span>
							)}
						</React.Fragment>
					);
				})}
		</div>
	);
};
