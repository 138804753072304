/*eslint-disable*/

import { createMachine } from "xstate";
import type { CallInfo } from "../types/call-related-types.types";

export const phonerSecondLineMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5SzAYwPYDsIAIA2AlpmAMQabGoAuOVA7uvkXANoAMAuoqAA7qwEqBLNxAAPRABYATABoQAT0QAOAIwA6AKxsdbacoBsqvdICcmgL4X5KcrkLESsOoNQALJsVjsuSEHwEhET8JBEkAdnklBGUAZk11ZXDtTVU1A1i2SUkrGzQse2Z1Agg8UlgqAEMAJxpbAs8wH1EAwWFMUVDjTXD1SVjkyR7wjINwuUVEA1Ne2LnYw2M51WlVXJB67Ebi0vKq2pxNwuIWVV9efjbg0FDw8NMtXQXVSWnxyMmYrPVpbSy0vTjSRrawbfJbBxgdTkShCTBQJwAV1QqDg3k4LUuQQ6IUQqgGCViK00BkMBiG2mUUSmr0S82Ur3Gyh04XWR22MLQcIRYGq1XQ1Wafla2M6ePCqgSujYpIM5M0lOpCAlCWJbB6Q2USRGbPBxyhnOokBIbnQeAgQougXaYoQsTM6nCal+IzMsVMQ1iSvibHU0uMTLiBhlursHKwsONEDQkMt-ixNtxdodTuJrtM7s9SoiD3mA1McuD3VDDUh0IjXONAFtEVQwDgqwRUHGRYmbuKDH0XjLfkrpPF1GqFgtNO6VjlQeyy4a6xAnPsaNG8JUFC2E9dxIh7Q9Uy6DG6PaO+3ofn82PFXU7WZO9dsKjUaDOiFAcEuVyQxPe6+pKgAzOvVAAFGwACUTi3mW94HE+8KvmAy6rhiwrrji7YIHKDzKPoqhOpI6ojDhx6xI6Z5DGY5ixCWEJFG4lSwDgvL8tUH5flCf4AYBfxgVONF0QxfICmu1obqEqyxJIfQyuJphpKsZhKhKnb7ikDLEqkphUfq6i0fRjECixVTfuxvKcTo3EQbxukCYKZyYsJqGbggaTMok0hZDo-bMgMSpysRapyvo54GJYN5htOlR4HgOBYDgprmiQ1RwIiVZNEhVpXA5oTxBoo4rFqLxvNIcp9po0gkbofxjKYbCqAYmnbAARpUqAANa0IwqCInyYCYFQBmVEZ-4maB4FhUUTWte1OCdd1vVCRltqrJIyh9NIblZNMMxsB80RkuoeamG5GR3HK9VlhNbVUB1XWJb1-WDRxI08cQ6gXVNM23VQpznPG9mLWYK1sBmhjZHhMlUp89wrfocz9BEeHhHoZ1FDBL70IwkKwGQFbUM+tAMI06I-a2Il4vhfQEkMJ2xGMEzRKoHrqKYzPM+J57JEVyjIy96OE9NOOzvdbFDUBmRPRZPME5j-MUJWFppb9C1Jjhkp+rosryoqnwM52kgsx6F4yNeeRjZLGPMPRM7Gp+hnCxxYvmabUK89LVsWrZyF-cry3EZorwZsG2UknTeL9GrOh4QMa0rHc3NQrApp0IcyKorAlsC+0DboBAEVCz+IuAet4tO+oCfoEnsAp2iMuwpnVbZxF82ikmYkSXhGR67JKymEqOt9Pr-QatI16gpg2dwKIz1gHZStoQAtAYSpzwk+ur6vlGhaWRQlGUM-N2hINM6STpqP77xKsomg7n8-T9va8R1Zv1Evaje9to5krSG3qiSpkzqaGoL0kNVbKRlAA88pUnRx3LLLI0EA36kyclfR0-90yZiPJ8OYsxkhZGBNtfoEoN4my3i9KCj4M6wTfNEdK+9HKvDbj2QMaggzHjKjgmqDM3KHWUAWaBOl+JMQQZlPEGYHhfzWvcdUw9xKSAUiA34YCvKQK5k-LSqAIpRRinFeBntZ50PJJJfcTC0g029DTJmADh49ElKkaBb0rrTRuj1KgQjFr2mhuEeICpkgkk-hfbQKDYbkXGHMaBqN8bmy8K4pMcQEgjBJMFaYNV-i93MP3Zmowar9HPNAl2Fsa5y2iWhSU3x4genhvcL+EN6ZpLPO6Hho5kiP2Ic-eOidk4omrk+GK9cc54CKR-QwxFj4APdHfepqSEh1NMA0gkj8rBAA */
    id: "second line",
    initial:
      JSON.parse(localStorage.getItem("phoner-second-line-machine") || "null")
        ?.value || "idle",
    context: JSON.parse(
      localStorage.getItem("phoner-second-line-machine") || "null"
    )?.context || {
      secondLineCallInfo: null as CallInfo,
      isConnected: false,
    },
    states: {
      idle: {
        on: {
          "incoming second call": {
            target: "second call received",
            actions: {
              type: "setSecondLineCallInfo",
              params: {},
            },
          },
          "start second line": [
            {
              target: "connecting",
              cond: "notSamePhoneNumberAsCurrentCall",
              actions: {
                type: "setSecondLineCallInfo",
                params: {},
              },
            },
            {
              target: "idle",
              internal: false,
            },
          ],
        },
      },
      "second call received": {
        on: {
          "start second line": [
            {
              target: "connecting",
            },
          ],
          decline: {
            target: "back to current",
            actions: {
              type: "resetSecondLineCallInfo",
              params: {},
            },
          },
        },
      },
      connecting: {
        on: {
          success: {
            target: "connected",
          },
          error: {
            target: "has error",
          },
          decline: {
            target: "back to current",
            actions: {
              type: "resetSecondLineCallInfo",
              params: {},
            },
          },
        },
      },
      connected: {
        entry: [
          {
            type: "setIsConnected",
            params: {},
          },
        ],
        on: {
          decline: {
            target: "back to current",
            actions: {
              type: "resetSecondLineCallInfo",
              params: {},
            },
          },

          "start delay": {
            target: "start connecting delay",
          },
        },
      },
      "start connecting delay": {
        after: {
          "0": {
            target: "connecting two lines",
          },
        },
      },
      "has error": {
        after: {
          "0": [
            {
              target: "#second line.back to current",
              actions: [],
            },
            {
              internal: false,
            },
          ],
        },
      },
      "back to current": {
        after: {
          "0": [
            {
              target: "#second line.idle",
              actions: [],
            },
            {
              internal: false,
            },
          ],
        },
      },
      "connecting two lines": {
        on: {
          success: {
            target: "two lines connected",
          },
        },
      },
      "two lines connected": {
        after: {
          "300": [
            {
              target: "show success connection modal",
              actions: [],
            },
            {
              internal: false,
            },
          ],
        },
      },
      "show success connection modal": {
        after: {
          2000: {
            target: "#second line.back to current",
            actions: ["resetSecondLineCallInfo"],
          },
        },
      },
    },
    on: {
      "start connecting two lines": {
        target: ".connecting two lines",
        cond: "isConnected",
        internal: true,
      },
      "switch lines": {
        target: ".idle",
        actions: {
          type: "resetSecondLineCallInfo",
          params: {},
        },
        internal: true,
      },
    },
    schema: {
      events: {} as
        | { type: "success" }
        | { type: "incoming second call"; callInfo: CallInfo }
        | {
            type: "start second line";
            callInfo: CallInfo;
            makeCall: (phoneNumber: string) => void;
          }
        | { type: "start connecting two lines" }
        | { type: "connecting two lines" }
        | { type: "start delay" }
        | { type: "decline" }
        | { type: "error" }
        | { type: "switch lines" },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      setIsConnected: (context) => {
        context.isConnected = true;
      },
      setSecondLineCallInfo: (context, event) => {
        if (event.type === "start second line") {
          context.secondLineCallInfo = event?.callInfo;

          if (context.secondLineCallInfo?.phoneNumber) {
            event.makeCall(context.secondLineCallInfo?.phoneNumber);
          }
        }

        if (event.type === "incoming second call") {
          context.secondLineCallInfo = event?.callInfo;
        }
      },
      resetSecondLineCallInfo: (context) => {
        context.secondLineCallInfo = null;
        context.isConnected = false;
      },
    },
    services: {},
    guards: {
      notSamePhoneNumberAsCurrentCall: (_, event) => {
        if (event.type === "start second line") {
          return event?.callInfo?.phoneNumber !== event.callInfo?.currentPhone;
        }
        return true;
      },
      isConnected: (context) => {
        return context.isConnected;
      },
    },
    delays: {},
  }
);
