import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table'

import { InstallmentJounalTable } from './installment-journal-table'
import { createColumnHelper } from '@tanstack/react-table'
import { InstallmentJournalColWidth, InstallmentJournalServerData } from '@/constants/installment'
import { TypographyHoc } from '../installment-hoc/installment-typography-hoc'
import { useRequestsInstallmentLogsJournalQuery } from '@/state/api'
import { ContentLoader } from '@/components/content-loader'

export const InstallmentJournal = ({ requestId = 'norequestid' }: any): JSX.Element => {
  const { refetch, currentData, isError, isFetching, isLoading, isSuccess } = useRequestsInstallmentLogsJournalQuery({ requestId })
  const data = currentData?.data
  const columnHelper = createColumnHelper<any>()
  const columnsWorkControl: any = [
    columnHelper.accessor('created_at', {
      id: 'created_at',
      cell: (info) => {
        const children = info.renderValue()

        return <TypographyHoc children={children} />
      },
    }),
    columnHelper.accessor('text', {
      id: 'text',
      cell: (info) => {
        const children = info.renderValue() ?? '\u2014'

        return <TypographyHoc children={children} />
      },
    }),
  ]

  // Гидрация таблицы
  const pagination = {
    pageIndex: 0,
    pageSize: data?.length || 10,
  }
  const table = useReactTable({
    data: data || [],
    columns: columnsWorkControl,
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    enablePinning: true,
  })

  return <>{isLoading ? <ContentLoader /> : <InstallmentJounalTable table={table} />}</>
}
