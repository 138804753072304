import { Title } from "@common/title";
import type { CreateRequestModalFormTypes } from "@components/modals/create-request-modal/create-request-modal.types";
import { CreateRequestLabels, CreateRequestNames } from "@constants/create-request";
import { Field, useField, useForm } from "react-final-form";
import { Col, Row } from "react-grid-system";
import styles from "./create-edit-request-form.module.scss";

import { Calendar } from "@/components";
import AddressInput from "@/components/common/address-input/address-input";
import RefusalStatusButton from "@/components/refusal-status-button/refusal-status-button";
import StatusButton from "@/components/status-button/status-button";
import { SPB_REGION_FIAS } from "@/constants/address-constants";
import { useComponentVisible } from "@/hooks";
import {
	type Company,
	type RequestsChangeStateApiResponse,
	coreApi,
	useProfileQuery,
	useRequestsChangeStateMutation,
	useRequestsPresetsStatesForCreateQuery,
} from "@/state/api";
import { useAppDispatch, useUserProfileInfo } from "@/state/selector";
import { getStreet } from "@/utils/addressFormatters";
import { filterUniquePhonesNumber } from "@/utils/find-phone-number";
import { getDate } from "@/utils/get-date";
import { InputDefault } from "@common/multi-select";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { LoadingSpinner } from "@components/button/loading-spinner";
import { CreateRequestStatusButton } from "@components/create-status-button";
import {
	apartmentLengthParser,
	checkStartsWithSpacesParser,
	clientFioSpecialSymbolsValidator,
	composeParse,
	composeValidators,
	discountParser,
	entranceLengthParser,
	fioLengthParser,
	firstSymbolCantBeSpaceValidator,
	handleInnOnPaste,
	handlePhoneOnPaste,
	houseLengthParser,
	housingSpecialSymbolsValidator,
	innValidator,
	mustBeNumber,
	normalizeInn,
	normalizePhone,
	objectLengthParser,
	objectOnlySpecialSymbolsValidator,
	objectSpecialSymbolsValidator,
	percentValidator,
	phoneValidator,
	streetLengthParser,
	streetSpecialSymbolsValidator,
	validateLocationId,
	//formatPhone,
} from "@components/form/utils/validators";
import { salePercent } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import {
	generateDateRegexp,
	generateDateString,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import type { CommonCreateEditFormProps } from "@components/modals/common-forms/create-edit-request-form.types";
import { RequestModalCheckbox } from "@components/modals/common-forms/request-modal-checkbox";
import { RequestModalEntitySwitcher } from "@components/modals/common-forms/request-modal-entity-switcher";
import {
	RequestModalAdsInput,
	RequestModalDistrictInput,
	RequestModalFinanceAccountPaymentFrom,
	RequestModalFinanceContract,
	RequestModalFinanceResponsible,
	RequestModalMetroStationInput,
	RequestModalSalePercentInput,
	RequestModalSaleTypeInput,
	RequestModalTextInput,
	RequestModalTypeInput,
	RequestModalUserInput,
	RequestModalWorkInput,
} from "@components/modals/common-forms/request-modal-input";
import { RequestModalFinancePaymentStatus } from "@components/modals/common-forms/request-modal-input/request-modal-finance-payment-status";
import { StatusLabel } from "@components/modals/common-forms/status-label";
import { getStatusLabelType } from "@components/modals/common-forms/status-label/api/status-label";
import cn from "classnames";
import classNames from "classnames";
import { format } from "date-fns";
import type React from "react";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { WatchPrevReuqstModalButton } from "../watch-prev-request";
import { RequestModalGuaranteeSwitcher } from "./request-modal-guarantee-switcher";

import { UISizes } from "@/global-types/mods.types";
import { OrganisationsInputRequest } from "@common/organisations-input/organisations-input-request";
import { validateDateForCreateEditRequest } from "@components/create-status-button/validateDateForCreateEditRequest";
import { RequestModalLocationInput } from "@components/modals/common-forms/request-modal-input/request-modal-location-input";
import useDebounce from "@hooks/useDebounce";

export const CommonCreateEditForm = ({ modalView, requestData, mutators }: Partial<CommonCreateEditFormProps>) => {
	const defaultRowStyle = {
		margin: 0,
		padding: "1px",
		gap: "16px",
		flexFlow: "row nowrap",
	};

	const defaultColStyle = {
		padding: 0,
		flex: 1,
	};

	const {
		data: createStates,
		isLoading: isCreateStatesLoading,
		isSuccess: isCreateStatesSuccess,
	} = useRequestsPresetsStatesForCreateQuery();
	const profile = useUserProfileInfo();
	const [fetchContactRequest, { data: prevContactRequests }] = coreApi.endpoints.clientContactRequests.useLazyQuery();
	const [fetchOrganisationsFeed, { isLoading: isLoadingCompanies }] = coreApi.endpoints.companiesFeed.useLazyQuery();
	const dispatch = useAppDispatch();
	const [date, setDate] = useState(new Date());

	const [ref, isVisible, setIsVisible] = useComponentVisible(undefined, undefined, undefined, ["input", "button", "calendar"]);
	const [dateRef] = useComponentVisible(undefined, undefined, undefined, ["input", "button", "calendar"]);

	const [changeState, { reset }] = useRequestsChangeStateMutation({
		fixedCacheKey: "changeRequestsState",
	});
	const legalEntityField = useField(CreateRequestNames.isLegalEntity).input.value;
	const params = useParams();
	const [isInvalid, setIsInvalid] = useState(false);

	const [companiesData, setCompaniesData] = useState<Company[]>([]);

	const { data: user } = useProfileQuery();
	const form = useForm();

	const [inputDateValue, setInputDateValue] = useState(requestData?.wait_until || getDate());

	const phoneField = useField(CreateRequestNames.phone);
	const saleTypeField = useField(CreateRequestNames.saleType);
	const statusField = useField(CreateRequestNames.status);

	const urIdValue = form.getState().values.urId;
	const organizationField = useField(CreateRequestNames.organization);
	const urInnField = useField(CreateRequestNames.urInn);

	const waitUntilField = useField("waitUntil").input.value;
	const [stateAllReq, setStateAllReq] = useState(false);

	const visibilityReqBlock = cn(styles["create-request-modal__fields-container-row_client-requests"], {
		[styles["create-request-modal__fields-container-row_client-requests-all"]]: stateAllReq,
	});

	const debouncedValueOrg = useDebounce<string>(organizationField.input.value, 500);
	const debouncedValueInn = useDebounce<string>(urInnField.input.value, 500);

	const fetchCompanies = async () => {
		const response = await fetchOrganisationsFeed({
			title: debouncedValueOrg,
			inn: debouncedValueInn,
		});

		setCompaniesData([...(response?.data?.data || [])]);
	};

	useEffect(() => {
		if (phoneField.meta.valid && !phoneField.meta.active && phoneField.meta.modified) {
			// старый запрос для получения данных по контакту /api/requests/?phone=%2B79999999999&per_page=400
			// fetchPrevRequest({
			//   phone: phoneField?.input?.value?.match(/\d+/g)?.join(''),
			//   perPage: 400,
			// })
			fetchContactRequest({
				phone: phoneField?.input?.value?.match(/\d+/g)?.join(""),
				is_ur: legalEntityField ? 1 : 0,
			});
		}
	}, [phoneField.meta.valid, phoneField.meta.active, phoneField.meta.modified, legalEntityField]);

	useEffect(() => {
		if (urInnField.meta.valid && urInnField.meta.modified && urInnField.input.value.length > 0) {
			void fetchCompanies();
		}
	}, [debouncedValueInn]);

	useEffect(() => {
		if (urIdValue) {
			return;
		}
		if (organizationField.meta.valid && organizationField.meta.modified && organizationField.input.value.length >= 3) {
			void fetchCompanies();
		}
	}, [debouncedValueOrg]);

	useEffect(() => {
		setStateAllReq(false);
		if (prevContactRequests?.data?.requests?.length) {
			mutators.setUserInfo(prevContactRequests.data.requests[0]);
		}
		if (prevContactRequests?.data?.company) {
			mutators.setUrInfo(prevContactRequests.data);
		}
		if (prevContactRequests?.data?.phones?.length) {
			mutators.changePhone(filterUniquePhonesNumber(prevContactRequests.data.phones, phoneField?.input?.value));
		}
	}, [prevContactRequests]);

	useEffect(() => {
		if (modalView !== "edit") {
			setInputDateValue(waitUntilField);
		}
	}, [modalView, statusField.input.value]);

	useEffect(() => {
		const {
			inputDateValue: validatedInputDateValue,
			isInvalid: validatedIsInvalid,
			date: validatedDate,
		} = validateDateForCreateEditRequest(inputDateValue);

		setInputDateValue(validatedInputDateValue);
		setIsInvalid(validatedIsInvalid);
		setDate(validatedDate);
		!validatedIsInvalid && form.resetFieldState("waitUntil");

		if (modalView !== "edit") {
			form.change("waitUntil", validatedInputDateValue);
		}
	}, [inputDateValue]);

	useEffect(() => {
		setInputDateValue(requestData?.wait_until || getDate());
	}, [requestData?.wait_until]);

	const dispatchingToRequestsIndexStore = (data: RequestsChangeStateApiResponse) => {
		dispatch(
			coreApi.util.updateQueryData("requestsIndex", {}, (draftIndex) => {
				if (draftIndex) {
					const targetRequestData = draftIndex.data?.find((item) => String(item.id) === String(params.requestId)) || {};

					Object.assign(targetRequestData, data?.data);
				} else {
					return draftIndex;
				}
			}),
		);

		dispatch(
			coreApi.util.updateQueryData("requestsShow", { requestId: Number(params.requestId) || 0 }, (draft) => {
				draft?.data ? Object.assign(draft.data, data?.data) : undefined;
			}),
		);
	};

	useEffect(() => {
		// сбрасываем значение в инпуте в прежнее если пользователь не сохранил изменения даты "Клиент ждет"
		if (!form.getFieldState("waitUntil")?.value && modalView === "edit") {
			setInputDateValue(requestData?.wait_until || getDate());
			form.change("waitUntil", requestData?.wait_until || getDate());
			return;
		}
		if (!isVisible && modalView === "edit" && form.getFieldState("waitUntil")?.value) {
			if (form.getFieldState("waitUntil")?.value !== inputDateValue) {
				setInputDateValue(requestData?.wait_until || getDate());
			}
		}
	}, [form.getFieldState("waitUntil")?.value, isVisible]);

	const handleChangeState = async () => {
		form.change("waitUntil", inputDateValue);
		if (params.requestId) {
			const data = {
				requestId: Number(params.requestId),
				requestsRequestChangeState: {
					state_id: requestData?.state_id,
					wait_until: inputDateValue,
				},
			};
			try {
				const response = await changeState(data);
				const dataResponse = "data" in response ? response.data : {};
				form.change("status", requestData?.state_id);
				setIsVisible(false);
				if ("data" in response) dispatchingToRequestsIndexStore(dataResponse);
			} catch (_) {
				reset();
			} finally {
				reset();
			}
		}
	};

	return (
		<>
			<div className={styles["create-request-modal__form-container"]}>
				<Title as={"h5"}>О заявке</Title>
				<div className={styles["create-request-modal__fields-container"]}>
					<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
						<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
							<Field<CreateRequestModalFormTypes["typeId"]>
								name={CreateRequestNames.typeId}
								type={"select"}
								label={CreateRequestLabels.typeId}
								enableSearch={false}
								component={RequestModalTypeInput}
							/>
						</Col>
						<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
							<Field<CreateRequestModalFormTypes["dispatcher"]>
								name={CreateRequestNames.dispatcher}
								disabled
								type={"select"}
								label={CreateRequestLabels.dispatcher}
								component={RequestModalUserInput}
							/>
						</Col>
					</Row>
					<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
						<Col style={defaultColStyle} xs={5} xl={5} xxl={5}>
							<Field<CreateRequestModalFormTypes["typeOfWork"]>
								name={CreateRequestNames.typeOfWork}
								label={CreateRequestLabels.typeOfWork}
								acceptPathAsTitle
								component={RequestModalWorkInput}
								type={"select"}
							/>
						</Col>
						<Col style={defaultColStyle} xs={7} xl={7} xxl={7}>
							<Field<CreateRequestModalFormTypes["obj"]>
								name={CreateRequestNames.obj}
								type={"text"}
								validate={composeValidators(objectOnlySpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
								parse={composeParse(objectLengthParser, checkStartsWithSpacesParser)}
								label={CreateRequestLabels.obj}
								component={RequestModalTextInput}
							/>
						</Col>
						<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
							<Field<CreateRequestModalFormTypes["location_id"]>
								name={CreateRequestNames.location_id}
								type={"select"}
								validate={validateLocationId}
								label={CreateRequestLabels.location_id}
								enableSearch={false}
								component={RequestModalLocationInput}
							/>
						</Col>
					</Row>
					<div className={styles["create-request-modal__form-container"]}>
						<Title as={"h5"}>О клиенте</Title>
						<div className={styles["create-request-modal__fields-container"]}>
							<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={3} xl={3} xxl={3}>
									<Field<CreateRequestModalFormTypes["isLegalEntity"]>
										name={CreateRequestNames.isLegalEntity}
										type={"checkbox"}
										label={CreateRequestLabels.isLegalEntity}
										showLabel
										component={RequestModalEntitySwitcher}
									/>
								</Col>
								{!legalEntityField && (
									<Col style={defaultColStyle} xs={9} xl={9} xxl={9}>
										<Field<CreateRequestModalFormTypes["clientFio"]>
											name={CreateRequestNames.clientFio}
											type={"text"}
											validate={composeValidators(clientFioSpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
											label={CreateRequestLabels.clientFio}
											parse={composeParse(fioLengthParser, checkStartsWithSpacesParser)}
											component={RequestModalTextInput}
										/>
									</Col>
								)}
								{legalEntityField && (
									<Col style={defaultColStyle} xs={5} xl={5} xxl={5}>
										<Field<CreateRequestModalFormTypes["organization"]>
											name={CreateRequestNames.organization}
											type={"text"}
											validate={composeValidators(objectSpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
											parse={composeParse(checkStartsWithSpacesParser)}
											label={CreateRequestLabels.organization}
											component={OrganisationsInputRequest}
											isLoading={isLoadingCompanies}
											options={companiesData}
											fetch={fetchCompanies}
										/>
									</Col>
								)}
								{legalEntityField && (
									<Col style={defaultColStyle} xs={2.2} xl={2.2} xxl={2.2}>
										<Field<CreateRequestModalFormTypes["urInn"]>
											name={CreateRequestNames.urInn}
											type={"text"}
											withRightButton={profile?.permissions?.CAN_REQUEST_COMPANY_ADD_INN}
											validate={composeValidators(innValidator)}
											label={CreateRequestLabels.inn}
											maxLength={12}
											disabled={!profile?.permissions?.CAN_REQUEST_COMPANY_ADD_INN}
											onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
												handleInnOnPaste(e, form, "urInn");
											}}
											component={RequestModalTextInput}
											parse={normalizeInn as (value: string | undefined) => string}
											fetch={fetchCompanies}
										/>
									</Col>
								)}
								{legalEntityField && (
									<Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
										<Field<CreateRequestModalFormTypes["legalEntityClientFio"]>
											name={CreateRequestNames.legalEntityClientFio}
											type={"text"}
											validate={composeValidators(clientFioSpecialSymbolsValidator)}
											label={CreateRequestLabels.legalEntityClientFio}
											component={RequestModalTextInput}
										/>
									</Col>
								)}
							</Row>
							<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["phone"]>
										name={CreateRequestNames.phone}
										type={"text"}
										disabled={modalView !== "create" ? !requestData?.abilities?.phone : false}
										validate={composeValidators(phoneValidator)}
										label={CreateRequestLabels.phone}
										component={RequestModalTextInput}
										onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
											handlePhoneOnPaste(e, form, "phone");
										}}
										parse={normalizePhone as (value: string) => string}
									/>
								</Col>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["phone2"]>
										name={CreateRequestNames.phone2}
										type={"text"}
										disabled={modalView !== "create" ? !requestData?.abilities?.phone : false}
										label={CreateRequestLabels.phone2}
										validate={composeValidators(phoneValidator)}
										component={RequestModalTextInput}
										onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
											handlePhoneOnPaste(e, form, "phone2");
										}}
										parse={normalizePhone as (value: string) => string}
									/>
								</Col>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["phone3"]>
										name={CreateRequestNames.phone3}
										type={"text"}
										validate={composeValidators(phoneValidator)}
										label={CreateRequestLabels.phone3}
										onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
											handlePhoneOnPaste(e, form, "phone3");
										}}
										disabled={modalView !== "create" ? !requestData?.abilities?.phone : false}
										component={RequestModalTextInput}
										parse={normalizePhone as (value: string) => string}
									/>
								</Col>
							</Row>
							{prevContactRequests?.data?.requests?.length ? (
								<div className={styles["create-request-modal__form-container"]}>
									<Title as={"h5"}>Прошлые заявки клиента</Title>
									<div className={visibilityReqBlock}>
										{prevContactRequests.data.requests.map((item) => {
											const isRequestInBlackList = item.type_id === 2;

											return (
												<Fragment key={item?.id}>
													{item?.id && item?.fullcode && (
														<WatchPrevReuqstModalButton
															isRequestInBlackList={isRequestInBlackList}
															key={item?.id}
															fullcode={item?.fullcode}
															requestId={String(item?.id)}
														/>
													)}
												</Fragment>
											);
										})}
									</div>
									{prevContactRequests.data?.requests?.length >= 10 && !stateAllReq && (
										<Button
											className={styles["button-see-all"]}
											btnType={"button"}
											type={ButtonTypes.secondary}
											size={UISizes.medium}
											onClick={() => setStateAllReq(true)}
										>
											Показать все
										</Button>
									)}
								</div>
							) : null}
							<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
									<Field<CreateRequestModalFormTypes["metroStation"]>
										name={CreateRequestNames.metroStation}
										type={"select"}
										label={CreateRequestLabels.metroStation}
										component={RequestModalMetroStationInput}
									/>
								</Col>
								<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
									<Field<CreateRequestModalFormTypes["districtId"]>
										name={CreateRequestNames.districtId}
										type={"select"}
										disabled={modalView !== "create" ? !requestData?.abilities?.address : false}
										label={CreateRequestLabels.districtId}
										component={RequestModalDistrictInput}
									/>
								</Col>
							</Row>
							<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["street"]>
										name={CreateRequestNames.street}
										type={"text"}
										validate={composeValidators(streetSpecialSymbolsValidator)}
										parse={composeParse(streetLengthParser, checkStartsWithSpacesParser)}
										label={CreateRequestLabels.street}
										disabled={modalView !== "create" ? !requestData?.abilities?.address : false}
										component={AddressInput}
										fromBound={"street"}
										toBound={"flat"}
										locations={[
											{
												region_fias_id: SPB_REGION_FIAS,
											},
										]}
										itemsFormatter={getStreet}
										keyForInput={"street"}
										onChange={mutators.changeStreet}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["house"]>
										name={CreateRequestNames.house}
										parse={composeParse(houseLengthParser)}
										type={"text"}
										disabled={modalView !== "create" ? !requestData?.abilities?.address : false}
										validate={composeValidators(housingSpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
										label={CreateRequestLabels.house}
										component={RequestModalTextInput}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["housing"]>
										name={CreateRequestNames.housing}
										parse={composeParse(houseLengthParser)}
										type={"text"}
										disabled={modalView !== "create" ? !requestData?.abilities?.address : false}
										validate={composeValidators(housingSpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
										label={CreateRequestLabels.housing}
										component={RequestModalTextInput}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["entrance"]>
										name={CreateRequestNames.entrance}
										validate={composeValidators(housingSpecialSymbolsValidator, firstSymbolCantBeSpaceValidator)}
										type={"text"}
										disabled={modalView !== "create" ? !requestData?.abilities?.address : false}
										parse={composeParse(entranceLengthParser)}
										label={CreateRequestLabels.entrance}
										component={RequestModalTextInput}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["apartment"]>
										name={CreateRequestNames.apartment}
										type={"text"}
										disabled={
											modalView === "edit"
												? !requestData?.abilities?.flat
												: !profile?.permissions?.CAN_REQUEST_EDIT_FLAT
										}
										validate={composeValidators(mustBeNumber, firstSymbolCantBeSpaceValidator)}
										parse={composeParse(apartmentLengthParser)}
										label={CreateRequestLabels.apartment}
										component={RequestModalTextInput}
									/>
								</Col>
							</Row>
						</div>
						<div className={styles["create-request-modal__form-container"]}>
							<Title as={"h5"}>Реклама</Title>
							<div className={styles["create-request-modal__fields-container"]}>
								<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
									<Col style={defaultColStyle} xs={12} xl={12} xxl={12}>
										<Field<CreateRequestModalFormTypes["ads"], HTMLInputElement>
											name={CreateRequestNames.ads}
											type={"select"}
											acceptPathAsTitle
											disabled={
												// !requestData?.abilities?.advert раньше смотрело на это, еще есть такое !profile?.permissions?.CAN_REQUEST_CHANGE_ADVERT
												modalView === "edit" ? !requestData?.abilities?.advert : false
											}
											component={RequestModalAdsInput}
										/>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames(styles["create-request-modal__form-container"], styles["create-request-modal__status"])}>
				<div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
					<Title as={"h5"}>Статус</Title>{" "}
					<StatusLabel type={getStatusLabelType(statusField.input.value).type}>
						{getStatusLabelType(statusField.input.value).title}
					</StatusLabel>
					{modalView !== "create" && (
						<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
							<Col style={defaultColStyle} xs={8} xl={8} xxl={8}>
								<Row style={defaultRowStyle} align={"center"} justify={"between"} gutterWidth={16}>
									{modalView === "duplicate" && (
										<Field<CreateRequestModalFormTypes["hasGuarantee"]>
											name={CreateRequestNames.hasGuarantee}
											type={"checkbox"}
											label={CreateRequestLabels.hasGuarantee}
											showLabel
											component={RequestModalGuaranteeSwitcher}
										/>
									)}
								</Row>
							</Col>
						</Row>
					)}
				</div>
				<div className={styles["statuses-container"]}>
					{modalView !== "edit" && (
						<>
							{!isCreateStatesLoading && isCreateStatesSuccess ? (
								Array.isArray(createStates?.data) &&
								createStates?.data.length &&
								createStates?.data.map((createStatus) => {
									return <CreateRequestStatusButton key={createStatus.id} state={createStatus} />;
								})
							) : (
								<div className={styles["statuses-container__loader"]}>
									<LoadingSpinner />
								</div>
							)}
						</>
					)}
				</div>
				<div className={styles["statuses-container"]}>
					{!!requestData?.available_states?.length &&
						modalView === "edit" &&
						requestData?.available_states
							?.filter((item) => !item.is_refusal)
							.map((item) => <StatusButton key={item.id} statusId={requestData?.state_id} state={item} />)}
					{modalView === "edit" && !!requestData?.available_states?.filter((item) => item.is_refusal).length && (
						<RefusalStatusButton
							states={requestData?.available_states.filter((item) => item.is_refusal)} //@ts-ignore
							mutateStatus={mutators}
						/>
					)}
				</div>
				<div className={styles["statuses-container"]}>
					{((requestData?.state_name === "Клиент ждёт" && requestData?.state_id === 21 && modalView === "edit") ||
						(modalView !== "edit" && form.getState().values?.status === 21)) && (
						<>
							<Col style={{ width: "300px" }} xs={3} xl={3} xxl={3}>
								<div ref={dateRef} className={styles["statuses-popup"]}>
									<InputDefault
										width={"full-w"}
										id={"calendar"}
										type={"text"}
										withRightButton={true}
										labelText={`${CreateRequestLabels.waitUntil}:`}
										state={`${form.getState()?.submitErrors?.waitUntil ? "error" : "default"}`}
										mask={generateDateString()}
										inputProps={{
											value: inputDateValue,
											onChange: (e: React.FormEvent<HTMLInputElement>) => setInputDateValue(e.currentTarget.value),
											onFocus: () => setIsVisible(true),
										}}
										renderDropdownMenu={() => (
											<div ref={ref} className={styles["calendar-container"]}>
												<Calendar
													className={styles.calendar}
													open={isVisible}
													selectedTime={format(date, "HH:mm")}
													showTimeInput
													popperPlacement={"bottom"}
													minDate={new Date()}
													selected={date}
													withoutTriggerButton
													popperModifiers={[
														{
															name: "offset",
															options: {
																offset: [0, 20],
															},
														},
													]}
													onChange={(date) => date && setInputDateValue(format(date, "dd.MM.yyyy HH:mm"))}
													timeActionButton={
														modalView === "edit"
															? () => {
																	return (
																		<Button
																			className={styles["status-button"]}
																			onClick={() => void handleChangeState()}
																			disabled={isInvalid}
																		>
																			Сохранить
																		</Button>
																	);
																}
															: undefined
													}
												/>
											</div>
										)}
									/>
								</div>
							</Col>
						</>
					)}
					<Field<CreateRequestModalFormTypes["isControlledAdmin"]>
						name={CreateRequestNames.isControlledAdmin}
						type={"checkbox"}
						label={CreateRequestLabels.isControlledAdmin}
						component={RequestModalCheckbox}
						disabled={modalView !== "create" ? !requestData?.abilities?.controlled_admin : false}
					/>
					<Field<CreateRequestModalFormTypes["isControlledDispatcher"]>
						name={CreateRequestNames.isControlledDispatcher}
						type={"checkbox"}
						label={CreateRequestLabels.isControlledDispatcher}
						disabled={modalView !== "create" ? !requestData?.abilities?.controlled_dispatcher : false}
						component={RequestModalCheckbox}
					/>
				</div>
			</div>
			<div className={styles["create-request-modal__form-container"]}>
				<Title as={"h5"}>Финансы</Title>
				<div className={styles["create-request-modal__fields-container"]}>
					{!legalEntityField && (
						<Row style={defaultRowStyle} align={"center"} gutterWidth={16}>
							<Col style={defaultColStyle} xs={8} xl={8} xxl={8}>
								<Field<CreateRequestModalFormTypes["saleType"]>
									name={CreateRequestNames.saleType}
									label={CreateRequestLabels.saleType}
									enableSearch={false}
									component={RequestModalSaleTypeInput}
								/>
							</Col>
							<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
								{saleTypeField.input.value === 0 || !saleTypeField.input.value ? (
									<Field<CreateRequestModalFormTypes["sale"]>
										name={CreateRequestNames.sale}
										type={"text"}
										validate={composeValidators(
											mustBeNumber,
											firstSymbolCantBeSpaceValidator,
											percentValidator(saleTypeField.input.value),
										)}
										parse={discountParser}
										label={CreateRequestLabels.saleSumm}
										component={RequestModalTextInput}
									/>
								) : (
									<Field<CreateRequestModalFormTypes["sale"]>
										name={CreateRequestNames.sale}
										type={"text"}
										validate={composeValidators(
											mustBeNumber,
											firstSymbolCantBeSpaceValidator,
											percentValidator(saleTypeField.input.value),
										)}
										enableSearch={false}
										options={salePercent}
										parse={discountParser}
										label={CreateRequestLabels.salePercent}
										component={RequestModalSalePercentInput}
									/>
								)}
							</Col>
							<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
								<Field<CreateRequestModalFormTypes["preferentialRequest"]>
									name={CreateRequestNames.preferentialRequest}
									type={"checkbox"}
									label={CreateRequestLabels.preferentialRequest}
									component={RequestModalCheckbox}
								/>
							</Col>
						</Row>
					)}
					{legalEntityField && (
						<>
							<Row style={defaultRowStyle} align={"center"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["preferentialRequest"]>
										name={CreateRequestNames.preferentialRequest}
										type={"checkbox"}
										label={CreateRequestLabels.preferentialRequest}
										component={RequestModalCheckbox}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["creditRequest"]>
										name={CreateRequestNames.creditRequest}
										type={"checkbox"}
										label={CreateRequestLabels.creditRequest}
										component={RequestModalCheckbox}
									/>
								</Col>
								<Col style={defaultColStyle} xs={2} xl={2} xxl={2}>
									<Field<CreateRequestModalFormTypes["ndsRequest"]>
										name={CreateRequestNames.ndsRequest}
										type={"checkbox"}
										label={CreateRequestLabels.ndsRequest}
										component={RequestModalCheckbox}
									/>
								</Col>
							</Row>
							<Row style={defaultRowStyle} align={"center"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["contract"]>
										name={CreateRequestNames.contract}
										label={CreateRequestLabels.contract}
										enableSearch={false}
										component={RequestModalFinanceContract}
									/>
								</Col>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["paymentStatus"]>
										name={CreateRequestNames.paymentStatus}
										enableSearch={false}
										label={CreateRequestLabels.paymentStatus}
										component={RequestModalFinancePaymentStatus}
									/>
								</Col>
								<Col style={defaultColStyle} xs={4} xl={4} xxl={4}>
									<Field<CreateRequestModalFormTypes["responsible"]>
										name={CreateRequestNames.responsible}
										label={CreateRequestLabels.responsible}
										isGroupSelectable={true}
										disableGroupsWithoutChilds={true}
										component={RequestModalFinanceResponsible}
									/>
								</Col>
							</Row>
							<Row style={defaultRowStyle} align={"center"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={3} xl={3} xxl={3}>
									<Field<CreateRequestModalFormTypes["accountPaymentNumber"]>
										name={CreateRequestNames.accountPaymentNumber}
										label={CreateRequestLabels.accountPaymentNumber}
										component={RequestModalTextInput}
										onChange={(e: React.FormEvent<HTMLInputElement>) => {
											mutators.changeAccountNumber(e.currentTarget.value);
										}}
									/>
								</Col>
								<Col style={defaultColStyle} xs={3} xl={3} xxl={3}>
									<Field<CreateRequestModalFormTypes["accountPaymentFrom"]>
										name={CreateRequestNames.accountPaymentFrom}
										label={CreateRequestLabels.accountPaymentFrom}
										mask={generateDateRegexp({ separator: "." })}
										component={RequestModalFinanceAccountPaymentFrom}
									/>
								</Col>
								<Col style={defaultColStyle} xs={3} xl={3} xxl={3}>
									<Field<CreateRequestModalFormTypes["summ"]>
										name={CreateRequestNames.summ}
										label={CreateRequestLabels.summ}
										component={RequestModalTextInput}
										onChange={(e: React.FormEvent<HTMLInputElement>) => {
											mutators.changeSum(e.currentTarget.value);
										}}
									/>
								</Col>
								<Col style={defaultColStyle} xs={3} xl={3} xxl={3}>
									<Field<CreateRequestModalFormTypes["prepayment"]>
										name={CreateRequestNames.prepayment}
										label={CreateRequestLabels.prepayment}
										disabled={!user?.data?.permissions?.CAN_REQUEST_CHANGE_BEFORE_SUMM}
										component={RequestModalTextInput}
										onChange={(e: React.FormEvent<HTMLInputElement>) => {
											mutators.changePrepay(Number(e.currentTarget.value));
										}}
									/>
								</Col>
							</Row>
							<Row style={defaultRowStyle} align={"center"} gutterWidth={16}>
								<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
									<Field<CreateRequestModalFormTypes["paymentOrder"]>
										name={CreateRequestNames.paymentOrder}
										label={CreateRequestLabels.paymentOrder}
										component={RequestModalTextInput}
										onChange={(e: React.FormEvent<HTMLInputElement>) => {
											mutators.changePaymentOrder(e.currentTarget.value);
										}}
									/>
								</Col>
								<Col style={defaultColStyle} xs={6} xl={6} xxl={6}>
									<Field<CreateRequestModalFormTypes["paymentOrderFrom"]>
										name={CreateRequestNames.paymentOrderFrom}
										label={CreateRequestLabels.paymentOrderFrom}
										mask={generateDateRegexp({ separator: "." })}
										component={RequestModalFinanceAccountPaymentFrom}
									/>
								</Col>
							</Row>
						</>
					)}
				</div>
			</div>
		</>
	);
};
