import { Title } from '@common/modal-container/modal-title'
import { SubTitle } from '@common/modal-container/modal-title/modal-subtitle'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import {
  ModalHeaderContentProps,
} from '@global-types/modal-header-content.types'


export const CreateRequestModalLeftContent = ({
  title,
  showBackButton,
  onHandleBackButton,
  subtitle,
}: Partial<ModalHeaderContentProps>) => {
  return (
    <>
      {showBackButton && <Button
        btnType={'button'}
        type={ButtonTypes.secondary}
        size={UISizes.medium}
        iconLeft={<i className={'icon icon-arrow-left'} />}
        onClick={onHandleBackButton}>
        Назад
      </Button>}
      <SubTitle>{subtitle}</SubTitle>
      <Title>{title}</Title>
    </>
  )
}
