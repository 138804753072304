import { createContext, useContext, useEffect, useState, type ReactNode } from "react";
import { useSharedWorker } from "../shared-worker";
import { useEventBus } from "../event-bus/event-bus";
import { SET_MASTER_TAB } from "../../event-bus/types/event-action.types";
import { nanoid } from "nanoid";
import { Helmet } from "react-helmet";
import { MasterTabInfoModal } from "../../components/master-tab-info-modal";

export interface MasterTabContext {
	isCurrentTabMasterTab: boolean;
}

const tabId = nanoid();

export const MasterTabContext = createContext<MasterTabContext>({
	isCurrentTabMasterTab: false,
});

export const useMasterTab = () => useContext(MasterTabContext);

export const MasterTabProvider = ({ children }: { children: ReactNode }) => {
	const { sendMessage } = useSharedWorker();
	const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

	const { on } = useEventBus();
	const [tabIsActive, setTabIsActive] = useState(false);
	const [masterTabId, setMasterTabId] = useState<string | null>(localStorage.getItem("masterTabId"));
	const [isCurrentTabMasterTab, setCurrentTabMasterTab] = useState(false);

	const handleModalClose = () => {
		setIsInfoModalOpen(false);
	};

	useEffect(() => {
		const unsubscribeOnSetMasterTab = on(SET_MASTER_TAB, ({ masterTabId }) => {
			setMasterTabId(masterTabId);
		});

		if (isCurrentTabMasterTab) {
			window.onunload = () => {
				window.localStorage.removeItem("masterTabId");
				window.localStorage.removeItem("sessionsState");
				window.localStorage.removeItem("phoner-incoming-call-machine");
				window.localStorage.removeItem("phoner-second-line-machine");
				sendMessage({
					eventName: SET_MASTER_TAB,
					eventPayload: { masterTabId: null },
				});
			};
		}
		return () => {
			unsubscribeOnSetMasterTab();
			if (isCurrentTabMasterTab) {
				window.onunload = null;
			}
		};
	}, [sendMessage, isCurrentTabMasterTab]);

	useEffect(() => {
		return () => {
			isCurrentTabMasterTab && localStorage.removeItem("masterTabId");
			isCurrentTabMasterTab && window.localStorage.removeItem("sessionsState");
			isCurrentTabMasterTab && window.localStorage.removeItem("phoner-incoming-call-machine");
			isCurrentTabMasterTab && window.localStorage.removeItem("phoner-second-line-machine");
		};
	}, [isCurrentTabMasterTab]);

	useEffect(() => {
		if (masterTabId === tabId) {
			setCurrentTabMasterTab(true);
			setIsInfoModalOpen(true);
		} else {
			setIsInfoModalOpen(false);
			setCurrentTabMasterTab(false);
		}
	}, [masterTabId]);

	useEffect(() => {
		if (!document.hidden) {
			setTabIsActive(true);
		}

		const handler = () => {
			if (!document.hidden) {
				setTabIsActive(true);
			} else {
				setTabIsActive(false);
			}
		};

		document.addEventListener("visibilitychange", handler);
		return () => {
			document.removeEventListener("visibilitychange", handler);
		};
	}, []);

	useEffect(() => {
		if (!masterTabId && tabIsActive) {
			localStorage.setItem("masterTabId", tabId);

			sendMessage({
				eventName: SET_MASTER_TAB,
				eventPayload: { masterTabId: tabId },
			});
			setMasterTabId(tabId);
		}
	}, [tabIsActive, masterTabId]);

	useEffect(() => {
		window.addEventListener("logout", () => {
			localStorage.removeItem("masterTabId");
		});
	}, []);

	return (
		<MasterTabContext.Provider value={{ isCurrentTabMasterTab }}>
			<>
				{isCurrentTabMasterTab && (
					<Helmet>
						<link rel="icon" href="/favicon-phoner.ico" />
						<title>Фонер</title>
					</Helmet>
				)}
				{children}
				{isCurrentTabMasterTab && isInfoModalOpen && <MasterTabInfoModal onClose={handleModalClose} />}
			</>
		</MasterTabContext.Provider>
	);
};
