import { useComponentVisible } from "@/hooks";
import { useRequestsChangeStateMutation } from "@/state/api";
import { useState } from "react";
import { useForm } from "react-final-form";
import { useParams } from "react-router";
import { Button } from "../button";
import { ButtonTypes } from "../button/button.types";
import styles from "./refusal-status-button.module.scss";
import type { IRefusalStatusButton } from "./refusal-status-button.types";

const RefusalStatusButton = ({ states }: IRefusalStatusButton) => {
	const form = useForm();
	const params = useParams();
	const [ref, isVisible, setIsVisible] = useComponentVisible();
	const [stateComment] = useState("");
	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const [commentRef, showComment, setShowComment] = useComponentVisible(undefined, undefined, undefined, [
		"input",
		"button",
		"refusal-status-popup",
	]);
	const [changeState] = useRequestsChangeStateMutation();

	const handleClick = async (id: number) => {
		if (params.requestId) {
			const data = {
				requestId: Number(params.requestId),
				requestsRequestChangeState: {
					state_id: id,
					state_comment: stateComment ? stateComment : undefined,
				},
			};
			const response = await changeState(data);
			const dataResponse = "data" in response ? response.data : {};
			form.change("status", dataResponse?.data?.state_id);
			setShowComment(false);
		}
	};

	return (
		<div style={{ position: "relative" }}>
			<div className={styles["refusal-container"]}>
				<Button
					className={isVisible ? styles["button-active"] : ""}
					type={ButtonTypes.secondary}
					onClick={() => setIsVisible(true)}
					iconRight={<i className="icon icon-cheveron-down" />}
				>
					Отказ
				</Button>
				{isVisible && (
					<ul ref={ref} className={styles["refusal-popup"]}>
						{states.map((item) => (
							<li key={item.id} className={`${styles["refusal-popup-item"]} `} onClick={() => handleClick(item.id)}>
								{item.title}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default RefusalStatusButton;
