import { createRef, type FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentCdrId, setCurrentIndex, setErrorTracks, setIsAudioPlaying, setPreventPlaying } from '@/state/slice'
import type { IAudioTrackPlayerProps } from './audio-track-player.types'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import './audio-track-player.scss'
import './audio-track-player-motion.scss'
import { Button } from '@/components/button'
import { ButtonTypes } from '@/components/button/button.types'
import { Portal } from '@/components'
import { UISizes } from '@/global-types/mods.types'
import { AnimatePresence, motion } from 'framer-motion'
import { addSnack } from '@/eventbus/events/snackbar'

export const AudioTrackPlayer: FC<IAudioTrackPlayerProps> = ({


// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
}): JSX.Element => {
  const audioTrackPlayerSpeed = localStorage.getItem('audioTrackPlayerSpeed')
  const audioTrackPlayerVolume = localStorage.getItem('audioTrackPlayerVolume')
  const initialSpeed = (): number => {
    if (audioTrackPlayerSpeed) return Number(audioTrackPlayerSpeed)
    return 1
  }
  const initialVolume = (): number => {
    if (audioTrackPlayerVolume) return Number(audioTrackPlayerVolume)
    return 0.5
  }
  const state = useSelector((state: any) => state.playlist)

  const [speed, setSpeed] = useState(initialSpeed)
  const [volume, setVolume] = useState(initialVolume)
  const [currentTrackCdrId, setCurrentTrackCdrId] = useState<string | null>(null)
  const [currentPlaylist, setCurrentPlaylist] = useState<string | null>(null)
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number | null>(null)
  const [checkSpeed] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const player = createRef<AudioPlayer>()

  const changeSpeed = () => {
    if (speed >= 2) {
      setSpeed(1)
    } else setSpeed(speed + 0.5)
  }

  const dispatch = useDispatch()
  const removeCurrentTrack = () => {
    dispatch(setCurrentCdrId(null))
    dispatch(setCurrentIndex(null))
    dispatch(setIsAudioPlaying(false))
    dispatch(setPreventPlaying(true))
    setCurrentTrackCdrId(null)
  }

  const changeAudioTrack = (point: number) => {
    if (currentTrackCdrId && typeof currentTrackIndex === 'number' && typeof currentPlaylist === 'string') {
      const futureTrack = state?.playlists?.[currentPlaylist]?.[currentTrackIndex + point]?.cdr_id
      const maxLength = currentPlaylist ? state?.playlists?.[currentPlaylist].length : 0
      if ((point === 1 && currentTrackIndex + 1 <= maxLength) || (point === -1 && currentTrackIndex - 1 >= 0)) {
        dispatch(setCurrentCdrId(futureTrack))
        dispatch(setCurrentIndex(currentTrackIndex + point))
      } else {
        dispatch(setCurrentCdrId(currentTrackCdrId))
      }
    }
  }
  const isError = (): void => {
    addSnack({
      type: 'error',
      eventName: 'fetch-call-records-error',
      text: 'Не удалось загрузить запись звонка',
      withAction: false,
      // actionButtonText: 'Повторить',
    })
  }

  useEffect(() => {
    audioTrackPlayerSpeed ? setSpeed(Number(audioTrackPlayerSpeed)) : setSpeed(1)
    audioTrackPlayerVolume ? setVolume(Number(audioTrackPlayerVolume)) : setVolume(0.5)
  }, [])

  useEffect(() => {
    if (state.currentCdrId) {
      const trackIndex = state.currentIndex
      const playlist = state.currentPlaylist
      const currentTrack = state?.playlists?.[playlist]?.[trackIndex]
      setCurrentPlaylist(state.currentPlaylist)
      setCurrentTrackIndex(state.currentIndex)
      setFileName(
        `${currentTrack?.call_time?.split(' ')?.[0]}-${currentTrack?.call_time?.split(' ')?.[1]}-${currentTrack?.caller?.type}-${
          currentTrack?.caller?.phone
        }-${currentTrack?.called?.type}-${currentTrack.called?.phone}.mp3`,
      )
    }
  }, [state.currentCdrId])

  useEffect(() => {
    localStorage.setItem('audioTrackPlayerSpeed', String(speed))
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.playbackRate = Number(speed)) : undefined
  }, [speed, checkSpeed])

  useEffect(() => {
    localStorage.setItem('audioTrackPlayerVolume', String(volume))
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.volume = Number(volume)) : undefined
  }, [volume])

  useEffect(() => {
    setSpeed(initialSpeed)
    const audio = player?.current?.audio?.current
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.playbackRate = Number(audioTrackPlayerSpeed)) : undefined
    setVolume(initialVolume)
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    audio ? (audio.volume = Number(volume)) : undefined
  }, [currentTrackIndex])

  useEffect(() => {
    const playStop = state.preventPlaying
    if (state?.currentCdrId && state.currentPlaylist && !playStop) {
      setCurrentTrackCdrId(state.currentCdrId)
    }
  }, [state?.currentCdrId])

  useEffect(() => {
    if (state?.isAudioPlaying) {
      player?.current?.audio?.current?.play()
    } else {
      player?.current?.audio?.current?.pause()
    }
  }, [state?.isAudioPlaying])


  return (
    <Portal selector="#root">
      <AnimatePresence>
        {currentTrackCdrId && (
          <motion.div
            initial={{ opacity: 0, x: '-50%', y: -50 }}
            animate={{ opacity: 1, x: '-50%', y: 18 }}
            exit={{
              opacity: 0,
              y: -50,
            }}
            transition={{
              y: { duration: 0.16 },
            }}
            className={'motion-container'}>
            <div className={'audio-player-wrapper'} id="main-audio-player">
              <AudioPlayer
                showSkipControls
                onClickPrevious={() => changeAudioTrack(-1)}
                onClickNext={() => changeAudioTrack(1)}
                onEnded={() => {
                  changeAudioTrack(1)
                }}
                onPlaying={() => dispatch(setIsAudioPlaying(true))}
                onPause={() => dispatch(setIsAudioPlaying(false))}
                onVolumeChange={() => setVolume(Number(player?.current?.audio.current?.volume.toFixed(2)))}
                onError={() => {
                  dispatch(setErrorTracks(String(currentTrackCdrId)))
                  isError()
                  changeAudioTrack(1)
                }}
                ref={player}
                src={`/api/records/${currentTrackCdrId}/listen`}
                volume={volume}
                defaultDuration={'00:00'}
                autoPlay={true}
                showJumpControls={false}
                showDownloadProgress={false}
                showFilledVolume={true}
                customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION, RHAP_UI.CURRENT_TIME]}
                customAdditionalControls={[
                  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
<button className="speed-button" onClick={() => changeSpeed()}>
                    х{speed}
                  </button>,
                  // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
<a
                    rel="noreferrer"
                    download={fileName}
                    href={`/api/records/${Number(currentTrackCdrId)}/listen`}
                    className="download-button">
                    <i>
                      <svg
                        style={{
                          pointerEvents: 'none',
                        }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.6668 7.33335L8.00016 10.6667L5.3335 7.33335H7.3335V2.66669H8.66683V7.33335H10.6668ZM2.66683 12H13.3335V7.33335H14.6668V12C14.6668 12.7354 14.0688 13.3334 13.3335 13.3334H2.66683C1.9315 13.3334 1.3335 12.7354 1.3335 12V7.33335H2.66683V12Z"
                          fill="#4B4B4B"
                        />
                      </svg>
                    </i>
                  </a>,
                ]}
                customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
                customIcons={{
                  play: <i className={'icon-play'} />,
                  pause: <i className={'icon-pause'} />,
                  volume: <i className={'icon-volume'} />,
                  volumeMute: <i className={'icon-volume-mute'} />,
                  previous: (
                    <i key={'prev'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M10.6665 4.66797L5.99984 8.0013L10.6665 11.3346V4.66797ZM5.99984 8.0013V4.66797H4.6665V11.3346H5.99984V8.0013Z"
                          fill="#C3C3C3"
                        />
                      </svg>
                    </i>
                  ),
                  next: (
                    <i key={'next'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M4.6665 4.66797V11.3346L9.33317 8.0013L4.6665 4.66797ZM10.6665 11.3346V4.66797H9.33317V11.3346H10.6665Z"
                          fill="#C3C3C3"
                        />
                      </svg>
                    </i>
                  ),
                }}
              />
              <Button
                className="audio-player-close-button"
                btnType={'button'}
                iconLeft={<i className={'icon icon-close'} />}
                type={ButtonTypes.secondaryUncolored}
                size={UISizes.large}
                onClick={() => removeCurrentTrack()}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>

  )
}
