import { createContext, type ReactNode, useContext, useEffect } from "react";
import { useInterpret } from "@xstate/react";
import type { ActorRefFrom } from "xstate";
import {
	phonerSecondLineMachine,
	phonerTreyMachine,
	phonerIncomingCallMachine,
	phonerSnackControlMachine,
	searchMachine,
	phonerMenuMachine,
	phonerSettingsMachine,
	phonerDraggingMachine,
	phonerConnectionMachine,
} from "../machines";
import { useMasterTab } from "../../shared/contexts/master-tab/master-tab";

interface GlobalStateContextType {
	fastSearchService: ActorRefFrom<typeof searchMachine>;
	phonerMenuService: ActorRefFrom<typeof phonerMenuMachine>;
	phonerTreyService: ActorRefFrom<typeof phonerTreyMachine>;
	phonerIncomingCallService: ActorRefFrom<typeof phonerIncomingCallMachine>;
	phonerSecondLineService: ActorRefFrom<typeof phonerSecondLineMachine>;
	phonerSnackControlService: ActorRefFrom<typeof phonerSnackControlMachine>;
	phonerSettingsService: ActorRefFrom<typeof phonerSettingsMachine>;
	phonerDraggingService: ActorRefFrom<typeof phonerDraggingMachine>;
	phonerConnectionService: ActorRefFrom<typeof phonerConnectionMachine>;
}

export const GlobalPhonerStateContext = createContext({} as GlobalStateContextType);
export const useGlobalPhonerState = () => useContext(GlobalPhonerStateContext);

export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
	const fastSearchService = useInterpret(searchMachine, { devTools: true });
	const phonerMenuService = useInterpret(phonerMenuMachine, { devTools: true });
	const phonerTreyService = useInterpret(phonerTreyMachine, { devTools: true });
	const phonerIncomingCallService = useInterpret(phonerIncomingCallMachine, { devTools: true });
	const phonerSecondLineService = useInterpret(phonerSecondLineMachine, { devTools: true });
	const phonerSnackControlService = useInterpret(phonerSnackControlMachine, { devTools: true });
	const phonerSettingsService = useInterpret(phonerSettingsMachine, { devTools: true });
	const phonerDraggingService = useInterpret(phonerDraggingMachine, { devTools: true });
	const phonerConnectionService = useInterpret(phonerConnectionMachine, { devTools: true });
	const { isCurrentTabMasterTab } = useMasterTab();

	useEffect(() => {
		if (!!phonerConnectionService && isCurrentTabMasterTab) {
			phonerConnectionService.onTransition(() => {
				try {
					const stateToPersist = phonerConnectionService.getSnapshot();
					localStorage.setItem("phoner-connection-machine", JSON.stringify(stateToPersist));
				} catch (e) {
					console.error(e);
				}
			});

			return () => {
				!!phonerConnectionService && isCurrentTabMasterTab && localStorage.removeItem("phoner-connection-machine");
			};
		}
	}, [phonerConnectionService, isCurrentTabMasterTab]);

	useEffect(() => {
		if (!!phonerIncomingCallService && isCurrentTabMasterTab) {
			phonerIncomingCallService.onTransition(() => {
				try {
					const stateToPersist = phonerIncomingCallService.getSnapshot();

					localStorage.setItem("phoner-incoming-call-machine", JSON.stringify(stateToPersist));
				} catch (e) {
					console.error(e);
				}
			});

			return () => {
				!!phonerIncomingCallService && isCurrentTabMasterTab && localStorage.removeItem("phoner-incoming-call-machine");
			};
		}
	}, [phonerIncomingCallService, isCurrentTabMasterTab]);

	useEffect(() => {
		if (!!phonerSecondLineService && isCurrentTabMasterTab) {
			phonerSecondLineService.onTransition(() => {
				try {
					localStorage.setItem("phoner-second-line-machine", JSON.stringify(phonerSecondLineService.getSnapshot()));
				} catch (e) {
					console.error(e);
				}
			});

			return () => {
				!!phonerIncomingCallService && isCurrentTabMasterTab && localStorage.removeItem("phoner-incoming-call-machine");
			};
		}
	}, [phonerSecondLineService, isCurrentTabMasterTab]);

	useEffect(() => {
		if (phonerDraggingService) {
			phonerDraggingService.onTransition(() => {
				try {
					localStorage.setItem("phoner-dragging-state", JSON.stringify(phonerDraggingService.getSnapshot()));
				} catch (e) {
					console.error(e);
				}
			});
		}
	}, [phonerDraggingService]);

	return (
		<GlobalPhonerStateContext.Provider
			value={{
				fastSearchService,
				phonerMenuService,
				phonerTreyService,
				phonerIncomingCallService,
				phonerSecondLineService,
				phonerSnackControlService,
				phonerSettingsService,
				phonerDraggingService,
				phonerConnectionService,
			}}
		>
			{children}
		</GlobalPhonerStateContext.Provider>
	);
};
