import type { ReactNode } from "react";
import styles from "./second-line.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { DraggableArea } from "../../../shared/components/draggable-area";
import { useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";
interface SecondLineProps {
	topSlot?: ReactNode | null;
	bottomSlot?: ReactNode | null;
}
export const SecondLine = ({ topSlot = null, bottomSlot = null }: SecondLineProps) => {
	const { phonerSecondLineService } = useGlobalPhonerState();
	const { phonerIncomingCallService } = useGlobalPhonerState();
	const [statePhonerIncomingCall] = useActor(phonerIncomingCallService);
	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const { sessionsDTO } = useSessionContext();
	const currentPhone = statePhonerIncomingCall?.context?.currentCall?.phoneNumber;

	const twoLineConnected = statePhonerSecondLine.matches("two lines connected");
	const isAllLinesConnected = sessionsDTO.every((session) => session.isConnected);
	const showSuccessConnectionModal = statePhonerSecondLine.matches("show success connection modal");

	return (
		<div className={styles["second-line-widget-container"]}>
			<AnimatePresence>
				<DraggableArea>
					{currentPhone && !twoLineConnected && !showSuccessConnectionModal && (
						<motion.div
							className={styles["blur-top-slot"]}
							key="second-line-top-slot-wrapper"
							exit={{ y: 38, opacity: 0 }}
							transition={{ duration: 0.3 }}
						>
							<div className={styles["top-slot"]}>{topSlot}</div>
						</motion.div>
					)}

					{isAllLinesConnected && !twoLineConnected && !showSuccessConnectionModal && (
						<motion.div key="second-line-bottom-slot-wrapper" exit={{ y: -8, opacity: 0 }} transition={{ duration: 0.3 }}>
							<div className={styles["bottom-slot"]}>{bottomSlot}</div>
						</motion.div>
					)}
				</DraggableArea>
			</AnimatePresence>
		</div>
	);
};
