export type FormatedDuration = {
  hours: number;
  minutes: number;
  seconds: number;
}

export const formatCallDuration = (duration: number | null): FormatedDuration => {
  if(!duration) return {hours: 0, minutes: 0, seconds: 0};

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor((duration % 3600) % 60);

  return {hours, minutes, seconds};

}