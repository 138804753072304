import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import { useEffect, useState } from 'react'
import { useSaleTypesFeedQuery } from '@/state/api'
import { CreateRequestNames } from '@constants/create-request'
import { MultiSelect } from '@common/multi-select'
import { useField, useForm } from 'react-final-form'
import { findItem } from '@/utils/find-item'

export const RequestModalSaleTypeInput = <T,>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  enableSearch,
  ...props
}: RequestModalTextInputProps<T>) => {
  const { data: serverData, isLoading } = useSaleTypesFeedQuery()
  const [initialValue, setInitialValue] = useState<any>(undefined)

  const field = useField(input?.name)?.input?.value
  const form = useForm()
  useEffect(() => {
    if (field === '') {
      form.change('sale', undefined)
    }
    if (!isLoading && serverData?.data) {
      setInitialValue(
        findItem<any>({
          id: field,
          arr: serverData?.data as any[],
        }),
      )
    }
  }, [field, isLoading])

  return (
    <>
      <MultiSelect
        enableSearch={enableSearch}
        isDataLoading={isLoading}
        width={'full-w'}
        isDataNested={false}
        initialSelectedItems={initialValue}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={serverData?.data || []}
        inputId={CreateRequestNames.saleType}
        onChange={(items) => {
          items?.length ? input.onChange(items[0].id) : input.onChange(undefined)
        }}
        externalInputProps={input}
        externalInputMetaProps={meta}
      />
    </>
  )
}
