import AddressInput from "@/components/common/address-input/address-input";
import { SPB_REGION_FIAS } from "@/constants/address-constants";
import { useFiltersState } from "@/contexts/filters-state";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { addSnack } from "@/eventbus/events/snackbar";
import {
	type FiltersIndexResource,
	type RequestsFilterUpdate,
	useDistrictsFeedQuery,
	useFiltersFeedQuery,
	useFiltersUpdateMutation,
	useMastersFeedQuery,
	useProfileQuery,
	useReklamasFeedQuery,
	useRequestsPresetsCashlessTypesQuery,
	useRequestsPresetsContractTypesQuery,
	useRequestsPresetsDateRangeTypesQuery,
	useRequestsPresetsInstallmentTypesQuery,
	useRequestsPresetsStatesQuery,
	useRequestsPresetsTypesQuery,
	useUsersFeedQuery,
	useWorksFeedQuery,
} from "@/state/api";
import { getStreet } from "@/utils/addressFormatters";
import { parseJsonBoolean } from "@/utils/parse-json-boolean";
import { OrganisationsInput } from "@common/organisations-input/organisations-input";
import { Title } from "@common/title";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { Typography } from "@components/common";
import {
	composeValidators,
	datesRangeValidator,
	handleInnOnPaste,
	handlePhoneOnPaste,
	innValidator,
	normalizeInn,
	normalizePhone,
	phoneValidator,
	validateDateRange,
} from "@components/form/utils/validators";
import { FilterCheckbox } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-checkbox/filter-checkbox";
import { FilterDeleteTemplate } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-delete-template/filter-delete-template";
import { FilterPeriod } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period";
import {
	dateRangesByFastFilter,
	fastFiltersTemplates,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import { FilterStringWithQueryInput } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-states-input/filter-states-input";
import { FilterTextInput } from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-text-input/filter-text-input";
import { generateDateRangeRegexp } from "@components/headers/requests-header/request-header-filters/filter-inputs/mask-regexp";
import { FilterLabels } from "@components/headers/requests-header/request-header-filters/utils/filter-labels";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import { CreateFilterTemplateModal } from "@components/modals/create-filter-template";
import { UISizes } from "@global-types/mods.types";
import type { AnyObject } from "@global-types/utils-type";
import { isEmptyObject } from "@utils/is-empty-object";
import type { FormApi } from "final-form";
import { isEqual } from "lodash";
import type React from "react";
import { type FC, useEffect, useRef, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useMatch } from "react-router-dom";
import styles from "./request-header-filters.module.scss";
import type { RequestHeaderFiltersTypes } from "./request-header-filters.types";

export const RequestHeaderFilters: FC<RequestHeaderFiltersTypes> = ({
	isFetching,
	resetPageOnFilterChange = () => {
		return;
	},
}) => {
	const { filters, setFilters, initialState: defaultState, setSkipGetRequests, setTemplateFilters } = useFiltersState();
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [openCreateFilterTemplate, setOpenCreateFilterTemplate] = useState<boolean>(false);
	const filtersLoading = false;
	const filterLS = localStorage.getItem("filters");

	const [initialState, setInitialState] = useState<FilterTypes | AnyObject>(filterLS ? parseJsonBoolean(JSON.parse(filterLS)) : filters);
	const [isUpdatingTemplate, setIsUpdatingTemplate] = useState<boolean>(false);

	const [
		templateUpdate,
		{
			data: templateUpdateData,
			isLoading: isTemplateUpdateLoading,
			isSuccess: isTemplateUpdateSuccess,
			isError: isTemplateUpdateError,
		},
	] = useFiltersUpdateMutation();

	useEffect(() => {
		if (formRef.current) {
			formRef.current.restart(filters);
		}
		resetPageOnFilterChange({ resetLocalStoragePage: false });
	}, [filters]);

	const handleReset = (form: FormApi<FilterTypes>) => {
		form?.reset();
		form?.restart(defaultState);
		setFilters(defaultState);
		resetPageOnFilterChange({ resetLocalStoragePage: true });
	};

	const handleSubmit = (values: FilterTypes) => {
		const { page, ...rest } = values;
		resetPageOnFilterChange({ resetLocalStoragePage: true });
		if (rest.codeNumber || rest.phone) {
			if (rest.codeNumber) {
				setFilters({ codeNumber: rest.codeNumber, page: 1, perPage: filters.perPage });
			} else {
				setFilters({ phone: rest.phone, page: 1, perPage: filters.perPage });
			}
		} else {
			if (rest.dateFrom && rest.dateTo && !rest.dateRangeType) {
				// Если выбран период, но не указан тип периода, указываем тип по дефолту "дата приема"
				setFilters({ ...rest, dateRangeType: "by_receipt", page: 1, perPage: filters.perPage });
			} else {
				setFilters({ ...rest, page: 1, perPage: filters.perPage });
			}
		}
	};

	const editTemplate = (template: FiltersIndexResource, filtersData: object) => {
		template.id &&
			templateUpdate({
				filterId: template.id,
				requestsFilterUpdate: {
					data: filtersData,
					title: template.title,
				} as RequestsFilterUpdate,
			});
	};

	// Проверка изменен ли шаблон или нет
	const checkTemplateChanges = (templateData: Record<string, any> | undefined, filtersData: Record<string, any>) => {
		if (!templateData) {
			return false;
		}
		const { template, ...rest } = filtersData;
		if (isEqual(templateData.data, rest)) {
			return true;
		}

		return false;
	};

	useEffect(() => {
		setIsUpdatingTemplate(true);
		if (isTemplateUpdateSuccess) {
			addSnack({
				type: "default",
				eventName: "update-filter-template-success",
				text: "Шаблон успешно сохранен",
			});

			const template = templateUpdateData?.data;

			setInitialState({ ...template, template: { ...templateUpdateData } });
		}
	}, [isTemplateUpdateError, isTemplateUpdateSuccess]);

	const formRef: React.MutableRefObject<null | FormApi<FilterTypes, Partial<FilterTypes>>> = useRef(null);
	const { data: profile } = useProfileQuery();

	const match = useMatch("requests");
	const { handleSetRequestFullcodeId } = useFullCodeRequest();

	const isFormNotValid =
		formRef.current?.getState().hasValidationErrors ||
		!formRef.current?.getState().modified ||
		filtersLoading ||
		!Object.keys(formRef.current?.getState().values).length;

	const keyDownHandler = (event: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter" && !isFormNotValid && !openCreateFilterTemplate) {
			event.preventDefault();
			setSkipGetRequests?.(false);

			match && handleSetRequestFullcodeId("");
			match && formRef.current && handleSubmit(formRef.current?.getState().values);
			setOpenFilter(false);
		}
	};
	useEffect(() => {
		if (openFilter) {
			document.addEventListener("keydown", keyDownHandler);
		} else {
			document.removeEventListener("keydown", keyDownHandler);
		}
		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, [match, isFormNotValid, openCreateFilterTemplate, openFilter]);

	const checkItemsInArr = (arr: (number | string)[] | null | undefined) => arr && arr.length > 0;

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialState}
				mutators={{
					onPeriodChange: (args, state, utils) => {
						if (!args[0]) {
							utils.changeValue(state, "dateFrom", () => "");
							utils.changeValue(state, "dateTo", () => "");
							utils.changeValue(state, "period", () => undefined);
						} else {
							if (typeof args[0] === "object") {
								const dateString = args[0].currentTarget.value;
								utils.changeValue(state, "period", () => dateString);
								if (dateString && !dateString.includes("_")) {
									const periodDataFrom = dateString.split("-")[0].trim();
									const periodDataTo = dateString.split("-")[1].trim();
									utils.changeValue(state, "dateFrom", () => periodDataFrom || null);
									utils.changeValue(state, "dateTo", () => periodDataTo || null);
									utils.changeValue(state, "fastFilters", () => undefined);
								}
							} else {
								// @ts-ignore
								const fastFilters = state.formState?.values?.fastFilters;
								// @ts-ignore
								if (dateRangesByFastFilter[fastFilters] !== args[0]) {
									utils.changeValue(state, "fastFilters", () => undefined);
								}
								const periodDataFrom = args[0].split("-")[0];
								const periodDataTo = args[0].split("-")[1];
								utils.changeValue(state, "dateFrom", () => periodDataFrom);
								utils.changeValue(state, "dateTo", () => periodDataTo);
								utils.changeValue(state, "period", () => args[0]);
							}
						}
					},
					changeStreet: (args, state, utils) => {
						if (args.length) {
							if (!args[0]) {
								utils.changeValue(state, "street", () => "");
								utils.changeValue(state, "house", () => "");
								utils.changeValue(state, "flat", () => "");
								utils.changeValue(state, "korp", () => "");
							} else {
								const addressData = args[0];
								if (addressData.data) {
									utils.changeValue(state, "street", () => addressData.data?.street);
									addressData.data?.block && utils.changeValue(state, "korp", () => addressData.data?.block);
									addressData.data?.house && utils.changeValue(state, "house", () => addressData.data?.house);
									addressData.data?.flat && utils.changeValue(state, "flat", () => addressData.data?.flat);
								} else {
									utils.changeValue(state, "street", () => addressData);
								}
							}
						}
					},
					changeHouse: (args, state, utils) => {
						if (args.length) {
							if (!args[0]) {
								utils.changeValue(state, "house", () => "");
								utils.changeValue(state, "korp", () => "");
							} else {
								if (args[0].data) {
									utils.changeValue(state, "house", () => args[0].data?.house);
									if (args[0].data?.block) {
										utils.changeValue(state, "korp", () => args[0].data?.block);
									}
								} else {
									utils.changeValue(state, "house", () => args[0]);
								}
							}
						}
					},
				}}
			>
				{({ form, values: { templateId: filterFormStateTemplateId, ...rest } }) => {
					formRef.current = form;
					return (
						<>
							<FormSpy
								subscription={{
									values: true,
									initialValues: true,
									modified: true,
								}}
								onChange={({ values }) => {
									if (values[FilterNames.template] && values[FilterNames.template].data) {
										setIsUpdatingTemplate(false);
										setInitialState({
											...values[FilterNames.template].data,
											[FilterNames.template]: values[FilterNames.template],
											templateId: values[FilterNames.template].id,
										});
									} else {
										setInitialState({
											...values,
											templateId: undefined,
										});
									}
								}}
							/>
							<div className={styles["template-filters-container"]}>
								<div className={styles["template-filters-container_controls"]} onKeyDown={(event) => keyDownHandler(event)}>
									<span className={styles.filter_button}>
										<Button
											btnType={"button"}
											type={ButtonTypes.secondary}
											iconRight={<i className={openFilter ? "icon-cheveron-up" : "icon-cheveron-down"} />}
											onClick={() => setOpenFilter((prev) => !prev)}
										>
											{!openFilter ? "Все фильтры" : "Скрыть фильтры"}
										</Button>
									</span>
									<Field<FilterTypes[FilterNames.codeNumber], HTMLInputElement>
										name={FilterNames.codeNumber}
										type={"text"}
										label={FilterLabels.codeNumber}
										mask={"#9999999"}
										component={FilterTextInput}
										parse={(value) => (value ? (String(value).replace(/\s/g, "_") as unknown as number) : value)}
									/>
									<Field<FilterTypes[FilterNames.phone]>
										name={FilterNames.phone}
										type={"text"}
										label={FilterLabels.phone}
										validate={composeValidators(phoneValidator)}
										component={FilterTextInput}
										parse={normalizePhone}
										onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
											handlePhoneOnPaste(e, form, "phone");
										}}
									/>
									<Field<FilterTypes[FilterNames.template]>
										name={FilterNames.template}
										label={FilterLabels.template}
										type={"select"}
										useQuery={useFiltersFeedQuery}
										component={FilterStringWithQueryInput}
									/>
								</div>
								<Col xs={4} md={"content"} xl={"content"}>
									<Row gutterWidth={8}>
										<Col>
											<Button
												disabled={filtersLoading || isFetching || isEqual(defaultState, parseJsonBoolean(filters))}
												btnType={"button"}
												type={ButtonTypes.secondary}
												onClick={() => {
													handleSetRequestFullcodeId("");
													setSkipGetRequests?.(false);
													setOpenFilter(false);
													handleReset(form);
												}}
											>
												Сбросить
											</Button>
										</Col>
										<Col>
											<Button
												btnType={"submit"}
												disabled={
													form.getState().hasValidationErrors ||
													!form.getState().modified ||
													filtersLoading ||
													isEqual(defaultState, form.getState().values)
												}
												type={ButtonTypes.primary}
												onClick={() => {
													setSkipGetRequests?.(false);
													handleSetRequestFullcodeId("");
													form.submit();
													setOpenFilter(false);
												}}
											>
												Применить
											</Button>
										</Col>
									</Row>
								</Col>
							</div>
							{openFilter && (
								<>
									<div className={styles["modal-content-wrapper"]} onClick={() => setOpenFilter((prev) => !prev)} />
									<div className={styles["content-wrapper"]}>
										<div className={styles["advanced-filter__container"]}>
											<div className={styles["advanced-filter__date-filters__container"]}>
												<Title as={"h5"}>Дата</Title>
												<div className={styles["date-filters__inputs"]}>
													<div className={styles["date-selection__input"]}>
														<Field<FilterTypes[FilterNames.dateRangeType]>
															name={FilterNames.dateRangeType}
															type={"select"}
															label={FilterLabels.dateSelection}
															useQuery={useRequestsPresetsDateRangeTypesQuery}
															// @ts-ignore
															enableSearch={false}
															component={FilterStringWithQueryInput}
														/>
													</div>
													<div className={styles["date-period__input"]}>
														<Field<FilterTypes[FilterNames.period]>
															name={FilterNames.period}
															type={"text"}
															label={FilterLabels.period}
															validate={composeValidators(datesRangeValidator, validateDateRange)}
															mask={generateDateRangeRegexp({ separator: "." })}
															component={FilterPeriod}
															onChange={form.mutators.onPeriodChange}
														/>
													</div>

													<div className={styles["fast-filters__input"]}>
														<Field<FilterTypes[FilterNames.fastFilters]>
															name={FilterNames.fastFilters}
															type={"select"}
															label={FilterLabels.fastFilters}
															enableSearch={false}
															// useQuery={useRequestsPresetsDateRangeTypesQuery}
															// @ts-ignore
															options={fastFiltersTemplates}
															component={FilterStringWithQueryInput}
														/>
													</div>
												</div>
											</div>
											<div className={styles["advanced-filter__general-filters__container"]}>
												<div className={styles["left-container"]}>
													<div className={styles["general-filters"]}>
														<Title as={"h5"}>Общие фильтры</Title>
														<div className={styles["general-filters__items"]}>
															<div className={styles["general-filters__items-left"]}>
																<div className={styles["general-filters__items-left__item"]}>
																	{" "}
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.district}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.district]>
																			name={FilterNames.district}
																			type={"select"}
																			useQuery={useDistrictsFeedQuery}
																			isDataNested={true}
																			debounceSearchTime={500}
																			component={FilterStringWithQueryInput}
																			dropdownMenuItemWithCheckbox={true}
																			isGroupSelectable={false}
																			dropdownMenuContainerWidth={241}
																			maxLenSelectedItemTitle={8}
																			dropdownMenuSelectionVariant={"multiple-select"}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.street}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes["street"]>
																			name={FilterNames.street}
																			type={"text"}
																			component={AddressInput}
																			fromBound={"street"}
																			toBound={"flat"}
																			locations={[
																				{
																					region_fias_id: SPB_REGION_FIAS,
																				},
																			]}
																			dropdownClassname={styles["address-dropdown"]}
																			itemsFormatter={getStreet}
																			keyForInput={"street"}
																			onChange={form.mutators.changeStreet}
																			onInputItemChange={(districtId: number) => {
																				form.change("district", [districtId]);
																			}}
																		/>
																	</div>
																</div>

																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.house}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.house]>
																			name={FilterNames.house}
																			type={"text"}
																			component={FilterTextInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.housing}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.housing]>
																			name={FilterNames.housing}
																			type={"text"}
																			component={FilterTextInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.entrance}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.entrance]>
																			name={FilterNames.entrance}
																			type={"text"}
																			component={FilterTextInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.apartment}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.apartment]>
																			name={FilterNames.apartment}
																			type={"text"}
																			component={FilterTextInput}
																		/>
																	</div>
																</div>

																<div className={styles["general-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.status}: `}
																	</Typography>
																	<div className={styles["general-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.status]>
																			name={FilterNames.status}
																			type={"select"}
																			useQuery={useRequestsPresetsStatesQuery}
																			dropdownMenuItemWithCheckbox={true}
																			maxLenSelectedItemTitle={10}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
															</div>
															<div className={styles["general-filters__items-right"]}>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.ads}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.ads]>
																			name={FilterNames.ads}
																			type={"select"}
																			useQuery={useReklamasFeedQuery}
																			isDataNested
																			filterByQuery
																			dropdownMenuItemWithCheckbox={true}
																			dropdownMenuContainerWidth={337}
																			debounceSearchTime={500}
																			maxLenSelectedItemTitle={19}
																			isGroupSelectable={false}
																			component={FilterStringWithQueryInput}
																			dropdownMenuSelectionVariant={"multiple-select"}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.type}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.type]>
																			name={FilterNames.type}
																			type={"select"}
																			useQuery={useRequestsPresetsTypesQuery}
																			enableSearch={false}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.typeOfWork}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.typeOfWork]>
																			name={FilterNames.typeOfWork}
																			type={"select"}
																			useQuery={useWorksFeedQuery}
																			isDataNested
																			debounceSearchTime={500}
																			isGroupSelectable={false}
																			component={FilterStringWithQueryInput}
																			dropdownMenuItemWithCheckbox={true}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			maxLenSelectedItemTitle={19}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.workerSetter}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.workerSetter]>
																			withInnerItems={true}
																			name={FilterNames.workerSetter}
																			type={"select"}
																			useQuery={useUsersFeedQuery}
																			dropdownMenuItemWithCheckbox={true}
																			isGroupSelectable={false}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			debounceSearchTime={500}
																			isDataNested
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.dispatcher}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.dispatcher]>
																			name={FilterNames.dispatcher}
																			type={"select"}
																			useQuery={useUsersFeedQuery}
																			dropdownMenuItemWithCheckbox={true}
																			disableGroupsWithoutChilds={true}
																			isDataNested
																			isGroupSelectable={false}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			debounceSearchTime={500}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["general-filters__items-right__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.worker}: `}
																	</Typography>
																	<div className={styles["general-filters__items-right__item-field"]}>
																		<Field<FilterTypes[FilterNames.worker]>
																			name={FilterNames.worker}
																			type={"select"}
																			useQuery={useMastersFeedQuery}
																			isDataNested={false}
																			component={FilterStringWithQueryInput}
																			debounceSearchTime={500}
																			dropdownMenuItemWithCheckbox={true}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			maxLenSelectedItemTitle={19}
																		/>
																	</div>
																</div>
																{initialState?.is_installment &&
																profile?.data?.permissions?.CAN_REQUEST_INSTALLMENT ? (
																	<div className={styles["general-filters__items-right__item"]}>
																		<Typography as={"paragraph"} decoration={"none"} tag={4}>
																			{`${FilterLabels.installmentStatus}: `}
																		</Typography>
																		<div className={styles["general-filters__items-right__item-field"]}>
																			<Field<FilterTypes[FilterNames.installments_state]>
																				name={FilterNames.installments_state}
																				type={"select"}
																				useQuery={useRequestsPresetsInstallmentTypesQuery}
																				component={FilterStringWithQueryInput}
																				enableSearch={false}
																				dropdownMenuItemWithCheckbox={true}
																			/>
																		</div>
																	</div>
																) : null}
															</div>
														</div>
														<div className={styles["general-filters__controls"]}>
															<Field<FilterTypes[FilterNames.notGivenWorker]>
																name={FilterNames.notGivenWorker}
																type={"checkbox"}
																label={FilterLabels.notGivenWorker}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.hasNoMaster]>
																name={FilterNames.hasNoMaster}
																type={"checkbox"}
																label={FilterLabels.hasNoMaster}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.onControl]>
																name={FilterNames.onControl}
																type={"checkbox"}
																label={FilterLabels.onControl}
																component={FilterCheckbox}
															/>

															<Field<FilterTypes[FilterNames.withWorkRecords]>
																name={FilterNames.withWorkRecords}
																type={"checkbox"}
																label={FilterLabels.withWorkRecords}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.notSaved]>
																name={FilterNames.notSaved}
																type={"checkbox"}
																label={FilterLabels.notSaved}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.duplicate]>
																name={FilterNames.duplicate}
																type={"checkbox"}
																label={FilterLabels.duplicate}
																component={FilterCheckbox}
															/>

															{profile?.data?.permissions?.CAN_REQUEST_INSTALLMENT ? (
																<Field<FilterTypes[FilterNames.is_installment]>
																	name={FilterNames.is_installment}
																	type={"checkbox"}
																	label={FilterLabels.is_installment}
																	component={FilterCheckbox}
																/>
															) : null}
														</div>
													</div>
													<div className={styles["legal-entity-filters"]}>
														<Title as={"h5"}>Для юр.лиц</Title>
														<div className={styles["legal-entity-filters__items"]}>
															<div className={styles["legal-entity-filters__items-left"]}>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.beznalNum}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.beznalNum], HTMLInputElement>
																			name={FilterNames.beznalNum}
																			type={"number"}
																			component={FilterTextInput}
																			onKeyPress={(event: any) => {
																				if (
																					event.key === "e" ||
																					event.key === "+" ||
																					event.key === "E" ||
																					event.key === "-" ||
																					event.key === "," ||
																					event.key === "."
																				) {
																					event.preventDefault();
																				}
																			}}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.organisation}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.organisation]>
																			dropdownClassname={styles["organization-dropdown"]}
																			name={FilterNames.organisation}
																			type={"text"}
																			component={OrganisationsInput}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.inn}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.inn], HTMLInputElement>
																			name={FilterNames.inn}
																			type={"text"}
																			component={FilterTextInput}
																			maxLength={12}
																			validate={composeValidators(innValidator)}
																			parse={
																				normalizeInn as (
																					value: string | null | undefined,
																				) => string | null | undefined
																			}
																			onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
																				handleInnOnPaste(e, form, "ur_inn");
																			}}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.responsible}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.responsible]>
																			name={FilterNames.responsible}
																			disableGroupsWithoutChilds={true}
																			type={"select"}
																			isGroupSelectable={false}
																			useQuery={useUsersFeedQuery}
																			debounceSearchTime={500}
																			dropdownMenuItemWithCheckbox={true}
																			dropdownMenuSelectionVariant={"multiple-select"}
																			isDataNested
																			withRightButton={false}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.contract}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.contract]>
																			name={FilterNames.contract}
																			type={"select"}
																			useQuery={useRequestsPresetsContractTypesQuery}
																			enableSearch={false}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.paymentStatus}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.paymentStatus]>
																			name={FilterNames.paymentStatus}
																			type={"select"}
																			enableSearch={false}
																			useQuery={useRequestsPresetsCashlessTypesQuery}
																			component={FilterStringWithQueryInput}
																		/>
																	</div>
																</div>
																<div className={styles["legal-entity-filters__items-left__item"]}>
																	<Typography as={"paragraph"} decoration={"none"} tag={4}>
																		{`${FilterLabels.beznal}: `}
																	</Typography>
																	<div className={styles["legal-entity-filters__items-left__item-field"]}>
																		<Field<FilterTypes[FilterNames.beznal]>
																			name={FilterNames.beznal}
																			type={"text"}
																			component={FilterTextInput}
																		/>
																	</div>
																</div>
															</div>
														</div>
														<div className={styles["legal-entity-filters__controls"]}>
															<Field<FilterTypes[FilterNames.beznalOnline]>
																name={FilterNames.beznalOnline}
																type={"checkbox"}
																label={FilterLabels.beznalOnline}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.beznalByAccount]>
																name={FilterNames.beznalByAccount}
																type={"checkbox"}
																label={FilterLabels.beznalByAccount}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.isCredit]>
																name={FilterNames.isCredit}
																type={"checkbox"}
																label={FilterLabels.isCredit}
																component={FilterCheckbox}
															/>
															<Field<FilterTypes[FilterNames.isEntity]>
																name={FilterNames.isEntity}
																type={"checkbox"}
																label={FilterLabels.isEntity}
																component={FilterCheckbox}
																checked={
																	form.getState().values[FilterNames.isEntity] ||
																	form.getState().values[FilterNames.beznalNum] ||
																	form.getState().values[FilterNames.organisation] ||
																	form.getState().values[FilterNames.inn] ||
																	form.getState().values[FilterNames.contract] ||
																	form.getState().values[FilterNames.paymentStatus] ||
																	form.getState().values[FilterNames.beznal] ||
																	checkItemsInArr(form.getState().values[FilterNames.responsible]) ||
																	Number(form.getState().values[FilterNames.paymentStatus]) === 0
																}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className={styles["right-container"]}>
											<div className={styles["right-container__instruction"]}>
												<Title as={"h5"}>Использование шаблона фильтров</Title>
												<ul>
													<li>
														<Typography as={"caption"} decoration={"none"} tag={5}>
															Для создания шаблона — установите нужные фильтры и нажмите «Создать шаблон».
														</Typography>
													</li>
													<li>
														<Typography as={"caption"} decoration={"none"} tag={5}>
															Выбрать созданный шаблон можно в поле «Шаблон».
														</Typography>
													</li>
													<li>
														<Typography as={"caption"} decoration={"none"} tag={5}>
															Для удаления — выберите шаблон и нажмите «Удалить шаблон».
														</Typography>
													</li>
													<li>
														<Typography as={"caption"} decoration={"none"} tag={5}>
															Для редактирования — измените нужные фильтры и нажмите «Сохранить шаблон».
														</Typography>
													</li>
												</ul>
											</div>
											<div className={styles["right-container__controls-btns"]}>
												{!form.getState().values.template && (
													<Button
														btnType={"button"}
														type={ButtonTypes.primary}
														size={UISizes.medium}
														disabled={
															(Object.keys(rest).length === 1 && Object.keys(rest)[0] === "page") ||
															isEqual(defaultState, form.getState().values) ||
															filtersLoading ||
															!!isEmptyObject(filters) ||
															checkTemplateChanges(form.getState().values.template, form.getState().values)
														}
														onClick={() => {
															const { fastFilters, template, templateId, ...rest } = form.getState().values;
															setTemplateFilters(rest);
															setOpenCreateFilterTemplate(true);
														}}
													>
														Создать шаблон
													</Button>
												)}
												{form.getState().values.template && (
													<Button
														btnType={"button"}
														type={ButtonTypes.primary}
														size={UISizes.medium}
														disabled={
															isEqual(defaultState, rest) ||
															checkTemplateChanges(form.getState().values.template, rest) ||
															isTemplateUpdateLoading ||
															isUpdatingTemplate
														}
														onClick={() => {
															const { fastFilters, template, templateId, ...rest } = form.getState().values;
															setTemplateFilters(rest);
															if (template) {
																editTemplate(template, rest);
																setIsUpdatingTemplate(true);
																form.submit();
															}
														}}
													>
														Сохранить шаблон
													</Button>
												)}
												<FilterDeleteTemplate
													resetInitialState={() => {
														setInitialState({});
														handleReset(form);
													}}
												/>
											</div>
											{openCreateFilterTemplate && (
												<CreateFilterTemplateModal
													onClose={() => setOpenCreateFilterTemplate(false)}
													title={"Создание шаблона"}
													closeOnBlackoutClick
												/>
											)}
											{window!.enableFormLogger === true && (
												<div
													style={{
														display: "flex",
														height: "400px",
														width: "400px",
														overflowY: "auto",
													}}
												>
													<pre>
														{
															// @ts-ignore
															JSON.stringify(form.getState().values, 0, 2)
														}
													</pre>
												</div>
											)}
										</div>
									</div>
								</>
							)}
						</>
					);
				}}
			</Form>
		</>
	);
};
