export const prepareFIO = (fio: string, isShowToMaster?: boolean) => {
  const preparedFio = fio
    .split(' ')
    .map(
      (word, idx) => `${idx === 0 ? word + ' ' : word ? `${word[0]}.` : ''} `,
    )
    .join('')

  return `${preparedFio} ${isShowToMaster ? '' : '[Не видeн мастеру]'}`
}
