import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { ModalContainer, Title } from "@/components/common";
import { ModalBody, ModalHeader } from "@/components/common/modal-container";
import { clearSnacks } from "@/eventbus/events/snackbar";
import {
	useDeleteDispComplaintsStoreMutation,
	useDeleteMastersComplaintsStoreMutation,
	useRequestsComplaintsIndexQuery,
	useRequestsShowQuery,
} from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import { okkCommentForm } from "@components/modals/okk-comment-modal/okk-comment-form";
import { REQUESTS } from "@constants/routes";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useMatch } from "react-router-dom";
import { CreateRequestModalLeftContent } from "../create-request-modal/left-content";
import styles from "./okk-comment-modal.module.scss";
import type { IWorkAnalysis, OkkSteps } from "./okk-comment.types";
import OkkConfirm from "./okk-components/okk-confirm";
import OkkTable from "./okk-components/okk-table";
import OkkCommon from "./okk-steps/okk-common";
import OkkDispatcher from "./okk-steps/okk-dispatcher";
import OkkMaster from "./okk-steps/okk-master";

const OkkCommentModal = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { data, isLoading: isRequestShowQueryLoading } = useRequestsShowQuery({
		requestId: Number(params.cardId ?? params.requestId),
	});

	const profile = useUserProfileInfo();
	const [currentStep, setCurrentStep] = useState<OkkSteps>("common");
	const [removeConfirmData, setRemoveConfirm] = useState<any>({});
	const [showExitConfirm, setShowExitConfirm] = useState(false);

	const [dispatcherToEdit, setDispatcherToEdit] = useState();
	const [masterToEdit, setMasterToEdit] = useState();

	const [deleteMaster, { isLoading: masterDeleteLoading }] = useDeleteMastersComplaintsStoreMutation();
	const [deleteDispatcher, { isLoading: dispatcherDeleteLoading }] = useDeleteDispComplaintsStoreMutation();

	const { data: complaintsData } = useRequestsComplaintsIndexQuery({
    requestId: Number(params.cardId ?? params.requestId),
	});

	const [okkCommentFormState, setOkkCommentFormState] = useState(okkCommentForm);
	const remove = (data: IWorkAnalysis) => {
		setRemoveConfirm(data);
	};


	const removeDispatchersWork = (data: IWorkAnalysis) => {
		deleteDispatcher({
			dispatcherId: Number(data?.disp_id),
			requestId: Number(params.cardId ?? params.requestId),
			complaintId: data.id,
		});
		setRemoveConfirm({});
	};

	const removeMastersWork = (data: IWorkAnalysis) => {
		deleteMaster({
			masterId: Number(data?.master_id),
			requestId: Number(params.cardId ?? params.requestId),
			complaintId: data.id,
		});
		setRemoveConfirm({});
	};

	const filters = localStorage.getItem("filters") ? JSON.parse(String(localStorage.getItem("filters"))) : null;

	const matchFeed = useMatch("requests/feed/okk-comment/:requestId");

	const isFeedPage = matchFeed;
	const createDispatcherAnalysisBtnDisabled =
		!profile?.permissions?.CAN_PREMIUM_DISPATCHER || !(profile?.id !== data?.data?.dispatcher?.id);

	return (
		<ModalContainer
			onClose={() => setShowExitConfirm(true)}
			showBlackout
			closeOnBlackoutClick
			className={classNames(styles["modal-container"], {
				[styles["okk-confirm-modal"]]: !!removeConfirmData.id || showExitConfirm,
			})}
		>
			{!removeConfirmData.id && (
				<div
					style={{
						display: showExitConfirm ? "none" : "initial",
						position: showExitConfirm ? "absolute" : "initial",
					}}
				>
					<ModalHeader
						showRightContent
						showLeftContent
						contentLeft={
							<CreateRequestModalLeftContent
								showBackButton={currentStep !== "common"}
								title={
									currentStep === "common"
										? `Анализ ОКК #${data?.data?.fullcode ? data.data.fullcode : ""}`
										: currentStep === "dispatcher"
											? "Анализ работы диспетчера"
											: "Анализ работы мастера"
								}
								onHandleBackButton={() => setCurrentStep("common")}
							/>
						}
						showCloseButton
						onClose={() => setShowExitConfirm(true)}
					/>
					<ModalBody className={currentStep !== "common" ? styles["okk-modal-body-negative"] : styles["okk-modal-body"]}>
						<div className={styles["okk-modal-content"]}>
							{currentStep === "common" && (
								<>
									<div className={styles["okk-table-container"]}>
										<Title as={"h5"}>Анализ диспетчера</Title>
										{dispatcherDeleteLoading && <LoadingSpinner />}
										{!!complaintsData?.data?.dispatcher_complaints?.length && !dispatcherDeleteLoading && (
											<OkkTable
												removeItem={remove}
												items={complaintsData?.data?.dispatcher_complaints}
												setEditItem={(data: any) => {
													setDispatcherToEdit(data);
													setCurrentStep("dispatcher");
												}}
											/>
										)}
										<Button
											btnType="button"
											disabled={createDispatcherAnalysisBtnDisabled}
											type={ButtonTypes.secondary}
											onClick={() => {
												setDispatcherToEdit(undefined);
												setCurrentStep("dispatcher");
											}}
										>
											Добавить анализ работы диспетчера
										</Button>
									</div>
									<div className={styles["okk-table-container"]}>
										<Title as={"h5"}>Анализ мастера</Title>
										{masterDeleteLoading && <LoadingSpinner />}
										{!!complaintsData?.data?.master_complaints?.length && !masterDeleteLoading && (
											<OkkTable
												removeItem={remove}
												items={complaintsData?.data?.master_complaints}
												setEditItem={(data: any) => {
													setMasterToEdit(data);
													setCurrentStep("master");
												}}
											/>
										)}
										<Button
											btnType="button"
											type={ButtonTypes.secondary}
											onClick={() => {
												setMasterToEdit(undefined);
												setCurrentStep("master");
											}}
										>
											Добавить анализ работы мастера
										</Button>
									</div>
								</>
							)}
							{currentStep === "common" && (
								<OkkCommon
									okkCommentFormState={okkCommentFormState}
									setOkkCommentFormState={setOkkCommentFormState}
									isRequestShowQueryLoading={isRequestShowQueryLoading}
									masterOnRequest={data?.data?.approved_master ? data?.data?.approved_master : undefined}
									dispatcherOnRequest={data ? data?.data?.dispatcher : undefined}
								/>
							)}

							{currentStep === "dispatcher" && (
								<OkkDispatcher
									isRequestShowQueryLoading={isRequestShowQueryLoading}
									dispatcherOnRequest={data ? data?.data?.dispatcher : undefined}
									id={dispatcherToEdit}
									onAdd={() => setCurrentStep("common")}
								/>
							)}

							{currentStep === "master" && (
								<OkkMaster
									isRequestShowQueryLoading={isRequestShowQueryLoading}
									masterOnRequest={data?.data?.approved_master ? data?.data?.approved_master : undefined}
									id={masterToEdit}
									onAdd={() => setCurrentStep("common")}
								/>
							)}
						</div>
					</ModalBody>
				</div>
			)}
			{removeConfirmData.id && !showExitConfirm && (
				<OkkConfirm
					text={`Вы уверены, что хотите удалить категорию ${removeConfirmData.type_title} для ${
						removeConfirmData.disp_fio || removeConfirmData.master_fio
					}
        от ${removeConfirmData.date}?`}
					confirmButtonText={"Удалить"}
					cancelButtonText={"Оставить"}
					onCancel={() => setRemoveConfirm({})}
					onConfirm={removeConfirmData?.disp_id ? removeDispatchersWork : removeMastersWork}
					data={removeConfirmData}
				/>
			)}
			{showExitConfirm && (
				<OkkConfirm
					text={"Закрыв окно, вы потеряете введенные данные"}
					confirmButtonText={"Да, закрыть оценку"}
					cancelButtonText={"Нет, вернуться"}
					onCancel={() => setShowExitConfirm(false)}
					onConfirm={() => {
						isFeedPage
							? navigate({
									pathname: REQUESTS,
									search: new URLSearchParams(filters).toString(),
								})
							: navigate("../");
						clearSnacks();
					}}
				/>
			)}
		</ModalContainer>
	);
};

export default OkkCommentModal;
