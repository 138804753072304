import { ModalHeaderProps } from './modal-header.types'
import styles from './modal-header.module.scss'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import React from 'react'

export const ModalHeader = ({
  onClose,
  showCloseButton = false,
  showRightContent,
  showLeftContent,
  contentRight,
  contentLeft,
  className = '',
}: Partial<ModalHeaderProps>) => {
  return (
    <div className={`${styles['modal-header-container']} ${className}`}>
      <div className={styles['modal-header-content']}>
        {showLeftContent && (
          <div className={styles['modal-header-leftside']}>{contentLeft}</div>
        )}
        {showRightContent && (
          <div className={styles['modal-header-rightside']}>{contentRight}</div>
        )}
      </div>
      {showCloseButton && (
        <div className={styles['modal-header-close-button']}>
          <Button
            btnType={'button'}
            type={ButtonTypes.secondaryUncolored}
            size={UISizes.medium}
            iconLeft={<i className={'icon icon-close'} />}
            onClick={onClose}
          />
        </div>
      )}
    </div>
  )
}
