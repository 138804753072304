import { createContext, useContext, useEffect, useState } from 'react'

type FullCodeRequestContextType = {
  requestFullcodeId: string | null
  handleSetRequestFullcodeId: (fullcodeId: string | null) => void
}

export const FullCodeRequestContext = createContext<FullCodeRequestContextType>(
  {
    requestFullcodeId: null,
    handleSetRequestFullcodeId: () => {},
  },
)

export const useFullCodeRequest = () => useContext(FullCodeRequestContext)

export const FullCodeRequestProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const [requestFullcodeId, setRequestFullcodeId] = useState<
    FullCodeRequestContextType['requestFullcodeId']
  >(localStorage.getItem('requestFullcodeId'))

  const handleSetRequestFullcodeId: FullCodeRequestContextType['handleSetRequestFullcodeId'] =
    (id: string | null) => {
      localStorage.setItem('requestFullcodeId', String(id))
      setRequestFullcodeId(String(id))
    }

  useEffect(() => {
    const requestFullcodeId = localStorage.getItem('requestFullcodeId')
    if (requestFullcodeId) {
      setRequestFullcodeId(requestFullcodeId)
    }
  }, [localStorage.getItem('requestFullcodeId')])

  return (
    <FullCodeRequestContext.Provider
      value={{
        requestFullcodeId,
        handleSetRequestFullcodeId,
      }}>
      {children}
    </FullCodeRequestContext.Provider>
  )
}
