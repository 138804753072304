import { InputContainer } from '@components/common'
import { InputMessageProps } from '@components/input-message/input-message.types'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import styles from './input-message.module.scss'
import React from 'react'

export const InputMessage = ({
  state = 'default',
  value,
  onChange = () => {},
  onFocus,
  onKeyDown,
  onBlur,
  placeholder = 'Ваш комментарий',
  onReset,
  className = '',
  id,
  disabled = false,
  btnType = 'submit',
}: Partial<InputMessageProps>) => {
  return (
    <div className={styles['input-message-container']}>
      <InputContainer
        state={state}
        value={value}
        showChildren={true}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className={styles['input-message']}
        id={id}
        disabled={disabled}
        onKeyDown={onKeyDown}
      >
        <div className={styles['input-message-button']}>
          <Button
            btnType={btnType}
            disabled={disabled}
            type={ButtonTypes.secondary}
            size={UISizes.medium}
            iconLeft={<i className={'icon icon-send'} />}
            onClick={() => {}}
          />
        </div>
      </InputContainer>
    </div>
  )
}
