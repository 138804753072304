/*eslint-disable*/
import { createMachine } from 'xstate'

type SnackControlType = 'audio' | 'mic' | null

export const phonerSnackControlMachine = createMachine(
  {
    id: 'snackbar control',
    initial: 'idle',
    context: {
      isShowing: false,
      snackControlType: null as SnackControlType,
    },
    states: {
      idle: {
        on: {
          show: {
            target: 'show snackbar',
            actions: {
              type: 'showSnackbar',
              params: {},
            },
          },
        },
      },
      'show snackbar': {
        after: {
          '3000': [
            {
              target: '#snackbar control.idle',
              cond: 'isNotShowing',
              actions: [
                {
                  type: 'resetSnackControlType',
                  params: {},
                },
              ],
              meta: {},
            },
            {
              internal: false,
            },
          ],
        },
        on: {
          'audio up': {
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: true,
          },
          'audio down': {
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: true,
          },
          'mic up': {
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: true,
          },
          'mic down': {
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: true,
          },
          'hide snackbar': {
            target: 'show snackbar',
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: false,
          },
          show: {
            target: 'show snackbar',
            actions: {
              type: 'showSnackbar',
              params: {},
            },
            internal: false,
          },
        },
      },
    },
    schema: {
      events: {} as
        | { type: 'show' }
        | { type: 'audio up' }
        | { type: 'audio down' }
        | { type: 'mic up' }
        | { type: 'mic down' }
        | { type: 'hide snackbar' },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
    //tsTypes: {},
  },
  {
    actions: {
      showSnackbar: (context, event) => {
        if (event.type === 'show') {
          return (context.isShowing = true)
        }

        if (event.type === 'audio up' || event.type === 'audio down') {
          context.snackControlType = 'audio'
          return (context.isShowing = true)
        }

        if (event.type === 'mic up' || event.type === 'mic down') {
          context.snackControlType = 'mic'
          return (context.isShowing = true)
        }

        if (event.type === 'hide snackbar') {
          return (context.isShowing = false)
        }
      },
      resetSnackControlType: (context, event) => {
        return (context.snackControlType = null)
      },
    },
    services: {},
    guards: {
      isNotShowing: (context, event) => {
        return !context.isShowing
      },
    },
    delays: {},
  },
)
