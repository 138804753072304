

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useGlobalPhonerState } from '../../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import { useSharedWorker } from '../../../contexts/shared-worker'
import { UPDATE_PHONER_POSITION } from '../../../event-bus/types/event-action.types'

type IDraggableWrapper =  {
    children: JSX.Element
    bounds?:{[key:string]: number},
    handle:string,
    axis:string;
    position: {x:number, y:number},   
}
export const DraggableWrapper = ({children, bounds, handle, axis, }:IDraggableWrapper) => {
    const { phonerDraggingService } = useGlobalPhonerState()
    const [statePhonerDragging, setPhonerDraggingEvents] = useActor(phonerDraggingService)
    const position = statePhonerDragging.context.position;
    const { sendMessage } = useSharedWorker()

    const onDrag = (e:DraggableEvent, data:DraggableData) => {
        const shiftX = data.x;
        const shiftY = data.y;
        sendMessage({eventName: UPDATE_PHONER_POSITION, eventPayload: {x:shiftX, y:shiftY}})
        setPhonerDraggingEvents({type: 'positionChanged', value:{x:shiftX, y:shiftY}})
    }
    const onDragStart = (e:DraggableEvent, data:DraggableData) => {
        setPhonerDraggingEvents('draggingStarted')
    }

    const onDragEnd = (e:DraggableEvent, data:DraggableData) => {
        setPhonerDraggingEvents('draggingEnd')
       
    }

    return (
        <Draggable
        bounds={bounds}
        handle={handle}
        position={position}
        onDrag={onDrag}
        onStart={onDragStart}
        onStop={onDragEnd}
        >
        {children}
        </Draggable>
    )
}