import { Button, Icon } from "../../../index";
import type React from "react";

type ArrowLeftIconProps = {
	handleArrowLeftIcon: () => void;
	isArrowLeftIconDisabled: boolean;
};
export const ArrowLeftIcon = ({ handleArrowLeftIcon, isArrowLeftIconDisabled }: ArrowLeftIconProps) => {
	return (
		<Button
			disabled={isArrowLeftIconDisabled}
			onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				handleArrowLeftIcon();
			}}
			size="m"
			variant="secondary-uncolored"
			width="icon"
			renderIcon={<Icon icon={"icon-cheveron-left"} />}
		/>
	);
};
