import { useActor } from "@xstate/react";
import { type ClipboardEvent, useEffect, useState } from "react";
import { useFormMachine } from "../../../../app/machines/form-factory-machine/useFormMachine";
import { useGlobalPhonerState } from "../../../../app/providers/xstate-provider";
import type { InputStateType } from "../../../../shared/ui-kit/input/input.types";
import { InputFieldMask } from "../../../phone-field-mask/input-mask/ui/input-mask";

export const Search = () => {
	const { fastSearchService, phonerTreyService } = useGlobalPhonerState();
	const [state, send] = useActor(fastSearchService);

	const [, sendPhonerTreyEvent] = useActor(phonerTreyService);
	const { getError, onBlur, onChange } = useFormMachine({ state, send });

	const [inputState, setInputState] = useState<InputStateType>("default");
	const [inputLength, setInputLength] = useState(0);
	const [inputValue, setInputValue] = useState(state.context.data["fast-search"] || "");
	const [previousLastChar, setPreviousLastChar] = useState("");

	useEffect(() => {
		if (!state.context.data["fast-search"]) {
			setInputValue("");
			setInputLength(0);
		}
	}, [state.context.data["fast-search"]]);

	const handleInputState = (value: InputStateType) => {
		value === "active" || value === "filled"
			? sendPhonerTreyEvent({ type: "active input", isActiveInput: true })
			: sendPhonerTreyEvent({ type: "active input", isActiveInput: false });

		setInputState(value);
		onBlur(state.context.data["fast-search"], "fast-search");
	};

	const handleChange = (value: string) => {
		const formattedNumericInput = value.replace(/\D/g, "");

		const lastCurrentChar = formattedNumericInput[formattedNumericInput.length - 1] || "";

		const regex = /^\+/;
		const numberIsNotInternal = !regex.test(value);
		const isNumberInternal = regex.test(value);

		if (formattedNumericInput[0] === "0" || !formattedNumericInput) {
			setInputValue("");
			setInputLength(0);
			onChange("", "fast-search");
			return;
		}

		if (formattedNumericInput.length <= 5 && numberIsNotInternal) {
			setInputValue(formattedNumericInput);
			setInputLength(formattedNumericInput.length);
			onChange(formattedNumericInput, "fast-search");
			return;
		}

		const isStartTapMobileNumberWithEight = formattedNumericInput[0] === "8";

		const isStartTapCityNumber = formattedNumericInput[0] === "8" && formattedNumericInput[1] === "8";

		const isPhoneNumberChange = formattedNumericInput.length === 4 && formattedNumericInput[0] === "7";

		const shortFormattedNumber = formattedNumericInput.length === 3 && formattedNumericInput[0] === "7";

		if (isStartTapMobileNumberWithEight) {
			setInputValue(formattedNumericInput.slice(1));
			setInputLength(formattedNumericInput.length);
			onChange(formattedNumericInput, "fast-search");
			setPreviousLastChar(lastCurrentChar);
			return;
		}

		if (!isStartTapMobileNumberWithEight) {
			const trimmedInputValue = value.trimStart();

			setInputValue(trimmedInputValue);
			setInputLength(trimmedInputValue.length);

			onChange(formattedNumericInput, "fast-search");
			setPreviousLastChar(lastCurrentChar);

			if (formattedNumericInput.length === 3 && isNumberInternal) {
				setInputValue(formattedNumericInput.slice(1) + previousLastChar);
				setInputLength(formattedNumericInput.length);
				setPreviousLastChar(lastCurrentChar);

				onChange(formattedNumericInput.slice(1) + previousLastChar, "fast-search");
			}
			return;
		}

		if (isStartTapCityNumber) {
			setInputValue(formattedNumericInput.slice(1));
			setInputLength(formattedNumericInput.length);
			onChange(formattedNumericInput, "fast-search");
			setPreviousLastChar(lastCurrentChar);
			return;
		}
		if (isPhoneNumberChange && isNumberInternal) {
			setInputValue(formattedNumericInput.slice(1));
			setInputLength(formattedNumericInput.length - 1);
			onChange(formattedNumericInput, "fast-search");
			setPreviousLastChar(lastCurrentChar);

			return;
		}

		if (shortFormattedNumber) {
			setInputValue(formattedNumericInput.slice(1) + previousLastChar);
			setInputLength(formattedNumericInput.length);
			setPreviousLastChar(lastCurrentChar);
			onChange(formattedNumericInput, "fast-search");

			return;
		}

		setInputValue(value.slice(1));
		setInputLength(value.length);
		onChange(value, "fast-search");
		setPreviousLastChar(lastCurrentChar);
	};

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const data = e.clipboardData.getData("text");
		const formattedNumericInput = data.replace(/\D/g, "");

		if (formattedNumericInput.length === 10) {
			const formattedPhoneNumber = `7${e.clipboardData.getData("text")}`;
			handleChange(formattedPhoneNumber);
			return;
		}

		handleChange(formattedNumericInput);
	};

	useEffect(() => {
		handleChange(state.context.data["fast-search"] || "");
	}, []);

	return (
		<>
			<InputFieldMask
				placeholder="Введите номер"
				mask={inputLength >= 5 ? "+7(999) 999-9999" : ""}
				id={"fast-search"}
				inputValue={inputValue}
				inputState={inputState}
				onPaste={handlePaste}
				handleChange={handleChange}
				handleInputState={handleInputState}
				errorMessage={getError("fast-search")}
			/>
		</>
	);
};
