import { InstallmentButtonSwitch, InstallmentStatuses } from '@/constants/installment'
import { ModalProps } from '@/global-types/modal.types'
import { InfoInstallmentCreate } from '@/state/api'
import { To } from 'react-router'

export type InstallmentModalProps = Partial<ModalProps> & {
  onClosePath?: To
  subtitle?: string
  title: string
}

export enum MethodProps {
  post = 'POST',
  put = 'PUT',
}

export interface LastSubmittedFormStateProps {
  values: InfoInstallmentCreate | null
  method: MethodProps | null
}

export interface InstallmentButtonSwitchProps {
  [InstallmentButtonSwitch.history]: boolean
  [InstallmentButtonSwitch.journal]: boolean
  [InstallmentButtonSwitch.plan]: boolean
}

export type StatusProps = 10 | 20 | 30
export interface InstallmentStatusProps {
  status: StatusProps
  statusName: string
}
