import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'
import cn from 'clsx'
import styles from './hot-keys-item.module.scss'

interface HotKeysItemProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  leftSlot?: ReactNode
  rightSlot?: ReactNode
  className?: string | undefined
}

export const HotKeysItem = ({
  leftSlot = <></>,
  rightSlot = <></>,
  className = '',
  ...props
}: HotKeysItemProps) => {
  const itemClassNames = cn(styles['item-container'], className)

  return (
    <div className={itemClassNames} {...props}>
      {leftSlot}
      {rightSlot}
    </div>
  )
}
