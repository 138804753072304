export const WatchPrevRequestData = {
    master: 'Мастер:',
    request: 'Заявка:',
    request_type: 'Тип заявки:',
    accepted: 'Принято:',
    phone: 'Телефон:',
    work_type: 'Вид работ:',
    object: 'Объект:',
    reaklama_name: 'Реклама:',
    fio: 'ФИО:',
    face: 'Лицо:',
    district: 'Район:',
    street: 'Улица:',
    house: 'Дом:',
    korp: 'Корпус:',
    apt: 'Кв.:',
    entrence: 'Подъезд:',
}