export const SET_MASTER_TAB = "set_master_tab";
export const SET_INCOMING_CALL_INFO = "set_incoming_call_info";
export const CHANGE_AUDIO_VOLUME = "change_audio_volume";
export const DECLINE_INCOMING_CALL = "decline_incoming_call";
export const ACCEPT_INCOMING_CALL = "accept_incoming_call";
export const UPDATE_SESSION_STATUS = "update_session_status";
export const DECLINE_CURRENT_CALL = "decline_current_call";
export const SET_MUTE_MICROPHONE = "set_mute_microphone";
export const SET_UNMUTE_MICROPHONE = "set_unmute_microphone";
export const SET_SESSION_HOLD = "set_session_hold";
export const SET_SESSION_UNHOLD = "set_session_unhold";
export const SET_OUTGOING_CALL_INFO = "set_outgoing_call_info";
export const TOGGLE_SESSION_HOLD_STATE = "toggle_session_hold_state";
export const START_SECOND_LINE = "start_second_line";
export const SECOND_INCOMING_CALL = "second_incoming_call";
export const SECOND_LINE_CALL_ANSWERED = "second_line_call_answered";
export const DECLINE_SECOND_INCOMING_CALL = "decline_second_incoming_call";
export const DECLINE_SECOND_LINE_CALL = "decline_second_line_call";
export const START_CONNECTING_TWO_LINES = "start_connecting_two_lines";
export const SUCCESS_TWO_LINES_CONNECTION = "success_two_lines_connection";
export const UPDATE_PHONER_POSITION = "update_phoner_position";
export const TRANSFER_TO_AUTOINFORMER = "start_transfer_to_autoinformer";
