import { FilterPeriodDropdownMenuProps } from '@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/dropdown-menu/filter-period-dropdown-menu.types'
import cn from 'classnames'
import styles from './filter-period-dropdown-menu.module.scss'
import { Calendar } from '@/components'

export const FilterPeriodDropdownMenu = ({
  children,
  isOpen = false,
  startDate,
  endDate,
  handleSetStartDate,
  handleSetEndDate,
  handleCloseDropDownMenu,
}: FilterPeriodDropdownMenuProps) => {
  const dropdownWrapperClasses = cn(styles.wrapper, {
    [styles['is-hidden']]: !isOpen,
    [styles['is-visible']]: isOpen,
  })

  return (
    <div
      className={`${dropdownWrapperClasses} react-datepicker-ignore-onclickoutside`}
    >
      <Calendar
        open={isOpen}
        onClickOutside={() => handleCloseDropDownMenu()}
        className={styles['without-bs']}
        selected={startDate}
        withoutTriggerButton
        onChange={(date) => handleSetStartDate(date)}
      />
      <div className={styles.calendar__divider}></div>
      <Calendar
        open={isOpen}
        withoutTriggerButton
        onClickOutside={() => handleCloseDropDownMenu()}
        className={styles['without-bs']}
        selected={endDate}
        onChange={(date) => handleSetEndDate(date)}
      />
    </div>
  )
}
