import { useState, useCallback, useEffect, useMemo } from "react";
import "./dropdown.scss";

import { useComponentVisible, usePrevious } from "@/hooks";
import type { DropdownProps } from "./dropdown.types";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";

export const Dropdown = ({
	className,
	trigger,
	header,
	body,
	footer,
	props,
	onClose,
	onOpen,
	isVisible,
	shouldCloseOnSelect = true,
	offsetCorrection = [20, 0],
	triggerClassName = "",
}: DropdownProps) => {
	const [ref, open, setOpen] = useComponentVisible<HTMLDivElement>(isVisible);
	const prevOpen = usePrevious(open);

	const onTriggerClick = useCallback(() => {
		setOpen((prev) => !prev);
	}, [setOpen]);

	useEffect(() => {
		if (open !== undefined && open !== prevOpen) {
			if (open) {
				!!onOpen && onOpen();
			} else {
				!!onClose && onClose();
			}
		}
	}, [open, prevOpen, onOpen, onClose]);

	useEffect(() => {
		if (isVisible !== undefined) {
			if (isVisible !== open) {
				setOpen(isVisible);
			}
		}
		// deps не менять! должен быть только isVisible иначе все к хуям ломается
	}, [isVisible]);

	const onContentLeave = useCallback(() => setOpen(false), [setOpen]);

	const content = useMemo(
		() => (
			<>
				{header && <div className={"dropdown-block-header"}>{header}</div>}
				<div className={"dropdown-block-body"} onClick={() => shouldCloseOnSelect && onContentLeave()}>
					{body}
				</div>
				{footer && <div className={"dropdown-block-footer"}>{footer}</div>}
			</>
		),
		[body, footer, header, onContentLeave, shouldCloseOnSelect],
	);

	const dropdown = useMemo(() => {
		return (
			<div ref={ref} className={`dropdown-block-content ${className ? className : ""}`} {...props}>
				{content}
			</div>
		);
	}, [ref, className, props, content]);

	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

	const offset = useMemo(
		() => ({
			name: "offset",
			options: {
				offset: () => {
					return offsetCorrection;
				},
			},
		}),
		[],
	);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: "arrow", options: { element: arrowElement } }, offset],
	});

	return useMemo(
		() => (
			<>
				<div className={triggerClassName} ref={setReferenceElement} onClick={onTriggerClick}>
					{trigger}
				</div>

				{open &&
					ReactDOM.createPortal(
						<div
							ref={setPopperElement}
							className={`dropdown-block-wrapper ${open ? "open" : ""} `}
							style={styles.popper}
							{...attributes.popper}
						>
							{dropdown}
							<div ref={setArrowElement} style={styles.arrow} />
						</div>,
						document.querySelector("#root") as HTMLElement,
					)}
			</>
		),
		[attributes.popper, dropdown, onTriggerClick, open, styles.arrow, styles.popper, trigger],
	);
};
