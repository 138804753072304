/*eslint-disable*/
import { createMachine } from "xstate";
import type { CallInfo } from "../types/call-related-types.types";
import type { RTCSession } from "jssip/lib/RTCSession";

const initialState = {
  currentCall: null as CallInfo,
};

const prepareInitialState = () => {
  const persistState = JSON.parse(
    localStorage.getItem("phoner-incoming-call-machine") || "null"
  );
  if (!persistState) return "idle";

  if (typeof persistState.value === "object") {
    const stateKey = Object.keys(persistState.value)[0];
    return `${stateKey}`;
  }
  return persistState.value;
};

const prepareInitialContext = () => {
  const persistState = JSON.parse(
    localStorage.getItem("phoner-incoming-call-machine") || "null"
  );
  if (!persistState) return initialState;

  return { ...persistState.context };
};
export const phonerCurrentCallMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGMCuAndYB2AXABMgIYA2JAxFrGAWpjraSfkQGa5jqED222YyAiQCW-WAG0ADAF1EoAA7dYw3MN5yQAD0QAWAEwAaEAE9EARgAcegHQA2WzoCcjgOxmAzBdcvJZgL5+RnRYeIRM1sIQJGDkosjcALaiUGFkUrJIIIrKquqZ2gg6to52ek6OFpKOerYArB4uRqYIZo7u1rU6kt16FrZmknp6jgFBGCGMZBFRMdyouPFJ2CnEaTIa2Spq2BoFtoYmiBYWZtbuLu7uDo627rXuZrWjIMEMqSTWq8xYyGDCAG6QchEZC-eS4dIbJRbPKgAqtHR2NzHSQuIaSHS1RxNcyDWrWJwWc56LHDGrPV6hL6fJj4H5-QEQcgQAQifiQzKbXI7fLmRyI2zIypovQYrE4lp6FwlCwXc5dPTuHTnJ6BF7jN7UymTCgs5BssAchTQ7m7czK9pK851EmyrwSgbnazOIlo0nVWwUjVU8La97kTjobjoI1ZE3bM0tC1nZUuG21O3Yw4ICouAkVc53Iq2bruL30H1TP1fcisYQkDhcABGxnw8gAFrxDetOeHYVpEJd8VYTkr7oLlWZbA7BqdM55JMVHI8XC58xN3p9vTrPrx+IJkuRYKhQXAJC3jTkI7yWmZHtYBm1jnczDoujoJW4bJ1ureXCdHPURmri77l4v4j4AQOCZRsSAgUMuWPOFzDPNNLBcepbzvKpLEfW5rAxV9PF6KU43nTU-wLFdAPXEDyASeYwHwJJkEgtseRgyUHGdONahcHQvFnewJVlU5pVfLxbxfT0f3-LVxN9NdgKBWAAHcVGQet8ANfcMkPGFGI7SV2OsPo3BdbweOTWdERfXM6nY+pVTGYiAMkotaV4fAwKZKhUASZt1LDI923hM98UvTwiXqFCH2TPpJGsYYekcSRZUnewCMLD5fymMsK04fAcCIKtoiZKsQQAa3wXBuEIZd6N8rS9mVDoimOELkIxEcpWsASMU4oZil6ZKSIcj4MsrbLsFy-LyEK5ASrKiriPEMxvKgvzEAcdpOlsRqbzC3jBXazirk6Lorj6PrFxBVR-iIcjNFgXArrAaw2ErAAKWpugASnINKPnOgF7ogg8fM0yMzAOZpQdO6lfsu67bvux72E4V6Pq+gbHo3GHIHmxaGMjKUoosLFrTii5ONuB0fDMxV3FcbpimOGz1Ts6l6yIWBsswYNKBodBjCq4GT3cbo9N6DxMSuDiHmHZMzxuM45UqKcZznMTmfCVn2cDLniGwX4SH500T1qMHzEQiw9IV9bEOGPNnmwbgWXgTJvqharIwAWiuCV3cQjpuh6PoBiGapIfCSJoldgWmKVNN9lqCVRVsDoaecS4yilVxGe+mkyDpAQGUgSPDaY9iHQeU5E7RdwSTFqVQ6LAai+g7TejTHsxf7SWhwlOPMK6Xx7C8IXJCztHs9I4Dkib5aU0qAkVSqTvlRNhA3HaLDRzaTEqj0evUrH6TBEL1s3ZPUHOJja1jYTd8k2aVxEQ3pUzDlYYVdshcJLVxzc+c1zp5qogT8gUqjBS2veXid49pEicJTDiZQ95Lm-oNcsw0cp5WPhpYu2lyh9w2nFW0t9JytVOLKS4dp6huFHsg9GF1-oALxgMAkegELHFvMUNEjQZaITTIMS4-JhI3FqNQz+6s2YcyDOgBhZ9BRRXFrePEiYHSWHNh1ScrRNoBACEAA */
    id: "current call",
    initial: prepareInitialState(),
    context: prepareInitialContext(),
    states: {
      idle: {
        on: {
          "incoming call": {
            target: "call received",
            actions: {
              type: "setIncomingCallInfo",
              params: {},
            },
          },
          "outcoming call": {
            target: "current call",
            actions: {
              type: "setOutcomingCallInfo",
              params: {},
            },
          },
        },
        after: {
          1000: {
            actions: {
              type: "resetCurrentCallInfo",
            },
          },
        },
      },
      "call received": {
        on: {
          accept: {
            target: "current call",
          },
          decline: {
            target: "delay activated",
          },
        },
      },
      "delay activated": {
        after: {
          0: {
            target: "activated",
          },
        },
      },
      "current call": {
        initial: "connecting",
        states: {
          connecting: {
            on: {
              success: {
                target: "connected",
              },
            },
          },
          connected: {
            entry: [],
            on: {
              "switch lines": {
                actions: {
                  type: "setIncomingCallInfo",
                  params: {},
                },
                internal: true,
              },
            },
          },
          "filter enabled": {
            on: {
              "back to current": [
                {
                  target: "connected",
                },
              ],
              decline: {
                target: "#current call.delay activated",
              },
            },
          },
        },
        on: {
          decline: {
            target: "delay activated",
          },
          "filter by phone": {
            target: ".filter enabled",
            internal: true,
          },
        },
      },
      activated: {
        after: {
          "0": [
            {
              target: "#current call.idle",
            },
            {
              internal: false,
            },
          ],
        },
      },
    },
    on: {
      "reset current call after connect lines": {
        target: "#current call.idle",
        actions: {
          type: "resetCurrentCallInfo",
        },
      },
      "clear current phone after update session": {
        actions: {
          type: "clearCurrentPhone",
        },
      },
      decline: {
        target: "delay activated",
      },
      setNewIncomingCallInfo: {
        actions: {
          type: "setNewCallInfo",
        },
      },
      backToIncomingCall: {
        target: "#current call.call received",
        actions: {
          type: "backToIncomingCall",
        },
      },
    },
    schema: {
      events: {} as
        | {
            type: "incoming call";
            callInfo: CallInfo;
          }
        | { type: "backToIncomingCall"; callInfo: CallInfo }
        | { type: "accept" }
        | { type: "decline" }
        | { type: "filter by phone" }
        | { type: "success" }
        | { type: "back to current" }
        | {
            type: "outcoming call";
            callInfo: CallInfo;
            makeCall: (phoneNumber: string) => void;
          }
        | {
            type: "switch lines";
            callInfo: CallInfo;
            session: RTCSession | null;
          }
        | {
            type: "reset current call after connect lines";
          }
        | {
            type: "clear current phone after update session";
          }
        | {
            type: "setNewIncomingCallInfo";
            callInfo: CallInfo;
          },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      setIncomingCallInfo: (context, event) => {
        if (event.type === "incoming call" || event.type === "switch lines") {
          context.currentCall = event?.callInfo;
        }
      },
      backToIncomingCall: (context, event) => {
        if (event.type === "backToIncomingCall") {
          context.currentCall = event?.callInfo;
        }
      },
      setOutcomingCallInfo: (context, event) => {
        if (event.type === "outcoming call") {
          if (context.currentCall) {
            context.isOnHold = false;
            context.currentCall = event?.callInfo;
          } else {
            context.currentCall = event?.callInfo;
          }
          if (event.callInfo?.phoneNumber) {
            event.makeCall(event.callInfo?.phoneNumber);
          }
        }
      },

      resetCurrentCallInfo: (context) => {
        context.currentCall = null;
      },
      clearCurrentPhone: (context) => {
        if (context.currentCall) {
          context.currentCall.currentPhone = "";
        }
      },
      setNewCallInfo: (context, event) => {
        if (event.type === "setNewIncomingCallInfo") {
          context.currentCall = event.callInfo;
        }
      },
    },
    services: {},
    guards: {},
    delays: {},
  }
);
