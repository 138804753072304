import { useFullCodeRequest } from "@/contexts/scroll-pos";
import type { ScrollPositionSaverProps } from "@components/scroll-position-saver/scroll-position-saver.types";
import { useEffect } from "react";

export const ScrollPositionSaver = ({ children, mRef, isFetching }: ScrollPositionSaverProps) => {
	const { requestFullcodeId } = useFullCodeRequest();

	useEffect(() => {
		if (mRef?.current && requestFullcodeId) {
			const el = mRef.current.querySelector(`[data-rfc="${String(requestFullcodeId)}"]`) as Element;

			el?.scrollIntoView({
				behavior: "auto",
				block: "center",
				inline: "nearest",
			});
		}
	}, [requestFullcodeId, mRef?.current, isFetching]);

	return <>{children}</>;
};
