import { MessageBoxProps } from '@common/chat/message-box/message-box.types'
import styles from './message-box.module.scss'
import { useState } from 'react'

export const MessageBox = ({ children, isQualityControl, ...props }: MessageBoxProps) => {
  const [showActionButtons, setShowActionButtons] = useState<boolean>(false)

  return (
    <div
      {...props}
      style={{ alignItems: showActionButtons || isQualityControl ? 'initial' : 'flex-end' }}
      onMouseEnter={() => !isQualityControl  && setShowActionButtons(true)}
      onMouseLeave={() => !isQualityControl  && setShowActionButtons(false)}
      className={styles['message-box-container']}
    >
      {typeof children === 'function'
        ? children({ showActionButtons })
        : children}
    </div>
  )
}
