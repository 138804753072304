import { createContext, useContext, type ReactElement } from "react";
import type { EventBusMappedEvents } from "../../event-bus/types/event-bus.types";
import { Eventbus, type IEventBus } from "../../event-bus/event-bus";

export const EventBusContext = createContext<IEventBus<EventBusMappedEvents>>(Eventbus<EventBusMappedEvents>());

export const useEventBus = () => useContext(EventBusContext);

export const EventBusProvider = ({ children }: { children: ReactElement }) => {
	const eventBusInstance = Eventbus<EventBusMappedEvents>();

	return <EventBusContext.Provider value={eventBusInstance}>{children}</EventBusContext.Provider>;
};
