import {
  LogCardProps
} from '@common/log-card-container/logcard-container.types'

import styles from './logcard-container.module.scss'

export const LogCardContainer = ({leftContent, rightContent} : Partial<LogCardProps>) => {
  return <div className={styles['logcard-container']}>
    <div className={styles['logcard-container_left-content']}>{leftContent}</div>
    <div className={styles['logcard-container_divider']}></div>
    <div className={styles['logcard-container_right-content']}>{rightContent}</div>
  </div>
}
