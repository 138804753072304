import { Typography } from '@/components/common'
import { InstallmentPlanColWidth, InstallmentScheduleServerData } from '@/constants/installment'
import tableStyle from '@common/table-container/table.module.scss'

export const InstallmentPlanTableHeader = ({table}: any): JSX.Element => {
  return (
    <table className={tableStyle['table-container']} style={{ display: 'table', paddingRight: '0', marginTop: '12px' }}>
      <thead className={tableStyle['table-header']} style={{ justifyContent: 'flex-start', width: '100%' }}>
        <tr className={tableStyle['table-row']}>
        {table.getHeaderGroups().map((headerG: any) =>
            headerG.headers.map((header: any) => {
              return (
                <th
                  className={tableStyle['table-col']}
                  style={{ maxWidth: header.column.columnDef.size ? `${header.column.columnDef.maxSize}px` : `calc (100% / 3)` }}>
                  {' '}
                  <Typography as={'caption'} decoration={'none'} tag={4} weight={1} className={tableStyle['table-col-item']}>
                    {header.column.columnDef.header}
                  </Typography>{' '}
                </th>
              )
            }),
          )}
        </tr>
      </thead>
    </table>
  )
}
