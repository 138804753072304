import { useActor } from "@xstate/react";
import { type ClipboardEvent, useEffect, useState } from "react";
import { useFormMachine } from "../../../../../app/machines/form-factory-machine/useFormMachine";
import { useGlobalPhonerState } from "../../../../../app/providers/xstate-provider";
import type { InputStateType } from "../../../../../shared/ui-kit/input/input.types";
import { InputFieldMask } from "../../../../phone-field-mask";

export const Search = () => {
	const { fastSearchService } = useGlobalPhonerState();
	const [state, send] = useActor(fastSearchService);
	const { getError, onBlur, onChange } = useFormMachine({ state, send });

	const [inputState, setInputState] = useState<InputStateType>("default");
	const [inputLength, setInputLength] = useState(0);
	const [inputValue, setInputValue] = useState(state.context.data["fast-search"] || "");
	const [previousLastChar, setPreviousLastChar] = useState("");

	const handleInputState = (value: InputStateType) => {
		setInputState(value);
		onBlur(state.context.data["fast-search"], "fast-search");
	};
	useEffect(() => {
		if (!state.context.data["fast-search"]) {
			setInputValue("");
			setInputLength(0);
		}
	}, [state.context.data["fast-search"]]);
	const handleChange = (value: string) => {
		const FORMATTED_NUMERIC_INPUT = value.replace(/\D/g, "");
		const LAST_CURRENT_CHAR = FORMATTED_NUMERIC_INPUT[FORMATTED_NUMERIC_INPUT.length - 1] || "";
		const regex = /^\+/;
		const numberIsNotInternal = !regex.test(value);
		const isNumberInternal = regex.test(value);

		if (FORMATTED_NUMERIC_INPUT[0] === "0" || !FORMATTED_NUMERIC_INPUT) {
			setInputValue("");
			setInputLength(0);
			onChange("", "fast-search");
			return;
		}

		if (FORMATTED_NUMERIC_INPUT.length <= 5 && numberIsNotInternal) {
			setInputValue(FORMATTED_NUMERIC_INPUT);
			setInputLength(FORMATTED_NUMERIC_INPUT.length);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			return;
		}

		const isStartTapMobileNumberWithEight = FORMATTED_NUMERIC_INPUT[0] === "8";
		const isStartTapCityNumber = FORMATTED_NUMERIC_INPUT[0] === "8" && FORMATTED_NUMERIC_INPUT[1] === "8";
		const isPhoneNumberChange = FORMATTED_NUMERIC_INPUT.length === 4 && FORMATTED_NUMERIC_INPUT[0] === "7";
		const shortFormattedNumber = FORMATTED_NUMERIC_INPUT.length === 3 && FORMATTED_NUMERIC_INPUT[0] === "7";

		if (isStartTapMobileNumberWithEight) {
			setInputValue(FORMATTED_NUMERIC_INPUT.slice(1));
			setInputLength(FORMATTED_NUMERIC_INPUT.length);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			setPreviousLastChar(LAST_CURRENT_CHAR);
			return;
		}
		if (!isStartTapMobileNumberWithEight) {
			const trimmedInputValue = value.trimStart();

			setInputValue(trimmedInputValue);
			setInputLength(trimmedInputValue.length);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			setPreviousLastChar(LAST_CURRENT_CHAR);

			if (FORMATTED_NUMERIC_INPUT.length === 3 && isNumberInternal) {
				setInputValue(FORMATTED_NUMERIC_INPUT.slice(1) + previousLastChar);
				setInputLength(FORMATTED_NUMERIC_INPUT.length);
				setPreviousLastChar(LAST_CURRENT_CHAR);
				onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			}
			return;
		}

		if (isStartTapCityNumber) {
			setInputValue(FORMATTED_NUMERIC_INPUT.slice(1));
			setInputLength(FORMATTED_NUMERIC_INPUT.length);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			setPreviousLastChar(LAST_CURRENT_CHAR);
			return;
		}
		if (isPhoneNumberChange && isNumberInternal) {
			setInputValue(FORMATTED_NUMERIC_INPUT.slice(1));
			setInputLength(FORMATTED_NUMERIC_INPUT.length - 1);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");
			setPreviousLastChar(LAST_CURRENT_CHAR);

			return;
		}

		if (shortFormattedNumber) {
			setInputValue(FORMATTED_NUMERIC_INPUT.slice(1) + previousLastChar);
			setInputLength(FORMATTED_NUMERIC_INPUT.length);
			setPreviousLastChar(LAST_CURRENT_CHAR);
			onChange(FORMATTED_NUMERIC_INPUT, "fast-search");

			return;
		}

		setInputValue(value.slice(1));
		setInputLength(value.length);
		onChange(value, "fast-search");
		setPreviousLastChar(LAST_CURRENT_CHAR);
	};

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		const data = e.clipboardData.getData("text");
		const formattedNumericInput = data.replace(/\D/g, "");

		if (formattedNumericInput.length === 10) {
			const formattedPhoneNumber = `7${e.clipboardData.getData("text")}`;
			handleChange(formattedPhoneNumber);
			return;
		}

		handleChange(formattedNumericInput);
	};

	useEffect(() => {
		handleChange(state.context.data["fast-search"] || "");
	}, []);

	return (
		<>
			<InputFieldMask
				placeholder="Введите номер"
				mask={inputLength >= 5 ? "+7(999) 999-9999" : ""}
				id={"fast-search"}
				onPaste={handlePaste}
				inputValue={inputValue}
				inputState={inputState}
				handleChange={handleChange}
				handleInputState={handleInputState}
				errorMessage={getError("fast-search")}
			/>
		</>
	);
};
