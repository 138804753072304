import styles from './title.module.scss'
import { TitleProps } from './title.types'
import { createElement } from 'react'
import cn from 'clsx'

type GetClassNameType = {
  as: string
  weight?: number
}

function getClassName({ as, weight }: GetClassNameType) {
  return `title-${as}${as === 'h3' ? `-weight-${weight}` : ''}`
}

export const Title = ({
  as,
  weight,
  children,
  className,
  ...props
}: TitleProps) => {
  const elementClasses = getClassName({ as, weight })

  const elementClassNames = cn(styles[elementClasses], className)

  return createElement(as, { className: elementClassNames, ...props }, children)
}
