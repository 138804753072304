import React from 'react'
import { DropdownMenuItemProps } from '@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item/dropdown-menu-item.types'
import { DropdownMenuItemSingleSelect } from '@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item/single-select'
import { DropdownMenuItemMultipleSelect } from '@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item/multiple-select'

export const DropdownMenuItem = <Item extends { [key: string]: any }>({
  style,
  dataIndex,
  rowVirtualizer,
  isDataNested,
  variant,
  withCheckbox,
  item,
  isActive,
  itemProps,
  children,
  selectItem,
  selectedItems,
  handleCollapseNestedItems,
  openedNestedItems,
  id,
  disableGroupsWithoutChilds,
}: DropdownMenuItemProps<Item>) => {
  if (variant === 'single-select') {
    return (
      <DropdownMenuItemSingleSelect<Item>
        style={style}
        dataIndex={dataIndex}
        rowVirtualizer={rowVirtualizer}
        disableGroupsWithoutChilds={disableGroupsWithoutChilds}
        selectItem={selectItem}
        id={id}
        selectedItems={selectedItems}
        itemProps={itemProps}
        isActive={isActive}
        item={item}
        isDataNested={isDataNested}
        handleCollapseNestedItems={handleCollapseNestedItems}
        openedNestedItems={openedNestedItems}>
        {children}
      </DropdownMenuItemSingleSelect>
    )
  }

  if (variant === 'multiple-select') {
    return (
      <DropdownMenuItemMultipleSelect
        style={style}
        dataIndex={dataIndex}
        rowVirtualizer={rowVirtualizer}
        disableGroupsWithoutChilds={disableGroupsWithoutChilds}
        selectedItems={selectedItems}
        id={id}
        selectItem={selectItem}
        itemProps={itemProps}
        isActive={isActive}
        item={item}
        isDataNested={isDataNested}
        withCheckbox={withCheckbox}
        handleCollapseNestedItems={handleCollapseNestedItems}
        openedNestedItems={openedNestedItems}>
        {children}
      </DropdownMenuItemMultipleSelect>
    )
  }

  return <></>
}
