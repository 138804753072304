import { ContentLoader } from "@/components";
import { MasterCardContent } from "@/components/modals/master-card-modal/master-card-content/master-card-content";
import { addSnack } from "@/eventbus/events/snackbar";
import { useMasterCardQuery } from "@/state/api/coreApi";
import { ModalBody, ModalHeader } from "@common/modal-container";
import { ModalContainer, Typography } from "@components/common";
import { MasterCardBlock, type MasterCardType } from "@constants/master-card";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { MasterCardHeaderLeftContent } from "./master-card-header/left-content/index";
import { MasterCardHeaderRightContent } from "./master-card-header/right-content/index";
import styles from "./master-card-modal.module.scss";
import type { MasterCardModalProps } from "./master-card-modal.types";

export const MasterCardModal = ({ onClosePath, closeOnBlackoutClick, subtitle, onHandleBackButton }: MasterCardModalProps) => {
	const navigate = useNavigate();
	const { masterId } = useParams();
	const location = useLocation();

	const {
		data: requestDataMaster,
		isLoading,
		isError,
	} = useMasterCardQuery({
		masterId,
	});

	useEffect(() => {
		if (isError) {
			addSnack({
				type: "error",
				eventName: "add-call-error",
				text: "Не удалось загрузить данные мастера.",
				withAction: false,
			});
		}
	}, [isError]);

	const columnHelper = createColumnHelper<any>();

	const columnsTypeWork: any = [
		columnHelper.accessor("path", {
			size: 932,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Работы
				</Typography>
			),
			cell: (info) => info.getValue().replace(/-&gt;/g, " -> "),
		}),
		columnHelper.accessor((row) => row.rating, {
			id: "rating",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Рейтинг
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
	];

	const columnsRequests: any = [
		columnHelper.accessor("name", {
			size: 676,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Статус заявок
				</Typography>
			),
			cell: (info) => info.getValue(),
			footer: () => <strong>ВСЕГО</strong>,
		}),
		columnHelper.accessor((row) => row.count, {
			id: "count",
			size: 416,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Количество заявок
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
	];

	const columnsRequestsToday: any = [
		columnHelper.accessor("name", {
			size: 676,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Районы
				</Typography>
			),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor((row) => row.count, {
			id: "count",
			size: 416,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Количество заявок
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
	];

	const columnsConditions: any = [
		columnHelper.accessor("type_name", {
			size: 144,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Наименование
				</Typography>
			),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor((row) => row.date, {
			id: "date",
			size: 144,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.user_fio, {
			id: "user_fio",
			size: 258,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Кто назначил
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.text, {
			id: "text",
			size: 258,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Описание
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.request?.fullcode, {
			id: "fullcode",
			size: 96,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Заявка
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.summ, {
			id: "summ",
			size: 96,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Сумма
				</Typography>
			),
			cell: (info) => (info.row.original.summ !== 0 ? info.renderValue() : "-"),
		}),
		columnHelper.accessor((row) => row.payed, {
			id: "payed",
			size: 96,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Погашен
				</Typography>
			),
			cell: (info) => (info.row.original.payed ? <i className={"icon icon-check"} /> : "-"),
		}),
	];

	const columnsMasterStatus: any = [
		columnHelper.accessor("user_name", {
			size: 932,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Пользователь
				</Typography>
			),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor((row) => row.created_at, {
			id: "created_at",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Дата действия
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.action, {
			id: "action",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Действие
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.condition_name, {
			id: "condition_name",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Состояние
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.date_from, {
			id: "date_from",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Начало состояния
				</Typography>
			),
			cell: (info) => info.renderValue(),
		}),
		columnHelper.accessor((row) => row.date_to, {
			id: "date_to",
			size: 160,
			header: () => (
				<Typography as={"caption"} decoration={"none"} tag={4} weight={1}>
					Конец состояния
				</Typography>
			),
			cell: (info) => (info.row.original.date_to !== null ? info.renderValue() : "-"),
		}),
	];

	return (
		<>
			<ModalContainer
				zIndex={3}
				className={styles["master-card-modal"]}
				onClose={
					onClosePath
						? () => {
								navigate(onClosePath, { state: location.state });
							}
						: () => {
								navigate("/", { state: location.state });
							}
				}
				showBlackout
				closeOnBlackoutClick={closeOnBlackoutClick}
			>
				<ModalHeader
					showRightContent
					showLeftContent
					contentLeft={
						<MasterCardHeaderLeftContent
							showBackButton={false}
							title={requestDataMaster?.data?.fio}
							subtitle={subtitle}
							onHandleBackButton={onHandleBackButton}
						/>
					}
					contentRight={<MasterCardHeaderRightContent workedAt={requestDataMaster?.data?.worked_at} />}
					showCloseButton
					onClose={
						onClosePath
							? () => {
									navigate(onClosePath, { state: location.state });
								}
							: () => {
									navigate("../", { state: location.state });
								}
					}
				/>
				<ModalBody>
					{isLoading ? (
						<ContentLoader />
					) : (
						<div className={styles["master-card-modal-content"]}>
							<MasterCardContent
								title={MasterCardBlock[0] as MasterCardType}
								info={requestDataMaster?.data}
								column={columnsTypeWork}
							/>
							{(!!requestDataMaster?.data?.districts?.length ||
								!!requestDataMaster?.data?.comment ||
								!!requestDataMaster?.data?.comment) && (
								<MasterCardContent title={MasterCardBlock[1] as MasterCardType} info={requestDataMaster?.data} />
							)}
							<MasterCardContent
								title={MasterCardBlock[2] as MasterCardType}
								info={requestDataMaster?.data}
								column={columnsRequests}
								additionalColumn={columnsRequestsToday}
							/>
							<MasterCardContent
								title={MasterCardBlock[3] as MasterCardType}
								info={requestDataMaster?.data}
								column={columnsConditions}
							/>
							<MasterCardContent
								title={MasterCardBlock[4] as MasterCardType}
								info={requestDataMaster?.data}
								column={columnsMasterStatus}
							/>
						</div>
					)}
				</ModalBody>
			</ModalContainer>
		</>
	);
};
