import { Panel } from '../../../shared'
import { VolumeSettings } from '../../../features/phoner-settings/volume-settings'
import { MicrophoneSettings } from '../../../features/phoner-settings/microphone-settings'

import { useGlobalPhonerState } from '../../../app/providers/xstate-provider'
import { useActor } from '@xstate/react'
import styles from './phoner-settings.module.scss'
export const PhonerSettings = () => {
  const { phonerMenuService } = useGlobalPhonerState()
  const [, send] = useActor(phonerMenuService)

  return (
    <Panel className={styles.container}>
      <Panel.Header
        className={styles.header}
        withBackButton
        handleClickBackButton={() => send('back')}
        title={'Настройки аудио'}
      />
      <Panel.Body className={styles['top-slot']}>
        <VolumeSettings />
        {/* <MicrophoneSettings /> */}
      </Panel.Body>
    </Panel>
  )
}
