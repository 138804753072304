import { Checkbox } from '@components/form/checkbox'
import { getInputState } from '@components/form/utils/getFieldState'
import { RequestModalCheckboxTypes } from '@components/modals/common-forms/request-modal-checkbox/request-modal-checkbox.types'

export const RequestModalCheckbox = <T extends boolean>({
  label,
  input,
  meta,
  ...props
}: RequestModalCheckboxTypes<T>) => {
  return (
    <Checkbox
      showLabel
      state={getInputState(meta, input)}
      label={label || ''}
      {...input}
      {...props}
    />
  )
}
