import { InstallmentServerData } from '@/constants/installment'

export const prepareInstallmentDataCreateRequest = (formValues: any) => {
  return {
    [InstallmentServerData.agreement]: formValues?.[InstallmentServerData.agreement]
      ? String(formValues?.[InstallmentServerData.agreement])
      : null,
    [InstallmentServerData.contract_date]: formValues?.[InstallmentServerData.contract_date]
      ? String(formValues?.[InstallmentServerData.contract_date])
      : null,
    [InstallmentServerData.date_begin]: formValues?.[InstallmentServerData.date_begin]
      ? String(formValues?.[InstallmentServerData.date_begin])
      : null,
    [InstallmentServerData.period]: formValues?.[InstallmentServerData.period] ? Number(formValues?.[InstallmentServerData.period]) : null,
  }
}
