import React, { useEffect, useState } from "react";
import { isNull } from "lodash";
import { addSnack } from "@/eventbus/events/snackbar";

import { ContentDivider } from "@/components/common";
import type { AutoanswerContentPropsType } from "./autoanswer-content.types";

import { useDeleteAnswerPhoneMutation, coreApi, type StatisticsIndexApiResponse, type ResoursecStatisticAnswerPhones } from "@/state/api";

import styles from "./autoanswer-content.module.scss";
import { AudioTrackPlayerAutoanswer } from "@/components/audio-track/audio-track-player-autoanswer";
import type { IDataAutoanswerProps } from "@/components/audio-track/audio-track-player-autoanswer/audio-track-player-autoanswer.types";
import type { AutoanswerPhoneType } from "../metrics-sidebar-modal.types";
import { useAppDispatch, useAppSelector } from "@/state/selector";

export const AutoanswerContent = ({ autoAnswer, isSuccess }: AutoanswerContentPropsType) => {
	const [removeAnswerPhone, { isLoading, isError }] = useDeleteAnswerPhoneMutation();
	const dispatch = useAppDispatch();
	const statisticsIndexStore = useAppSelector<Partial<StatisticsIndexApiResponse>>((state) => {
		const data = state.api.queries?.["statisticsIndex(undefined)"]?.data as StatisticsIndexApiResponse;
		if (data) return data;
		return {};
	});

	const [currAudioTrackIndex, setCurrAudioTrackIndex] = useState<number | null>(null);
	const [maxIdx, setMaxIdx] = useState<number>(0);
	const [audioTracks, setAudioTracks] = useState<IDataAutoanswerProps[]>([]);
	const [deletedAudioTracks, setDeletedAudioTracks] = useState<string>("");

	const getSessionStorageAudioList = (): string => {
		const sessionDeletedAudios = sessionStorage.getItem("deletedCdrId");
		const sessionDeletedAudiosStr = sessionDeletedAudios && JSON.parse(sessionDeletedAudios);
		if (sessionDeletedAudios) return sessionDeletedAudiosStr;
		return "";
	};

	const handleChangeCache = (cdrId: number): void => {
		const newSessionDeletedAudios = deletedAudioTracks ? `${String(deletedAudioTracks)}-${String(cdrId)}` : String(cdrId);
		dispatch(
			coreApi.util.updateQueryData("statisticsIndex", undefined, () => {
				const changeStore = { ...(statisticsIndexStore.data || {}) };
				const newAnswerPhones = changeStore?.answer_phone?.filter(
					(item: ResoursecStatisticAnswerPhones) => item.cdr_id !== Number(cdrId),
				);
				changeStore.answer_phone = newAnswerPhones;
				sessionStorage.removeItem("deletedCdrId");
				sessionStorage.setItem("deletedCdrId", JSON.stringify(newSessionDeletedAudios));

				return { data: changeStore };
			}),
		);
	};

	const handleRemove = (cdr_id?: number): void => {
		if (cdr_id) {
			removeAnswerPhone({ cdr_id: cdr_id })
				.unwrap()
				// .then(() => handleChangeCache(cdr_id))
				.catch((error) => console.error("Ошибка удаления записи автоответчика", error));
		}
	};

	const handleEndTrack = (): void => {
		setCurrAudioTrackIndex((prev) => {
			return typeof prev === "number" && !isNull(prev) && prev < maxIdx ? prev + 1 : prev;
		});
	};

	const handleSetCurrTrack = (index: number) => {
		setCurrAudioTrackIndex(index);
	};

	useEffect(() => {
		if (isSuccess) {
			setDeletedAudioTracks(getSessionStorageAudioList);
			if (autoAnswer && autoAnswer?.length > 0) {
				const filteredAutoanswer = autoAnswer.filter(
					(track: AutoanswerPhoneType) => !getSessionStorageAudioList().includes(String(track.cdr_id)),
				);
				setAudioTracks(
					filteredAutoanswer.map((track: AutoanswerPhoneType, index: number) => {
						return { ...track, index };
					}),
				);
			}
			autoAnswer && setMaxIdx(autoAnswer?.length - 1);
		}
	}, [isSuccess, autoAnswer]);

	useEffect(() => {
		if (isError) {
			addSnack({
				type: "error",
				eventName: "fetch-call-records-error",
				text: "Не удалось удалить запись",
				withAction: false,
			});
		}
	}, [isError]);

	return (
		<div className={styles["autoanswer-list"]}>
			{audioTracks?.map((item, index) => (
				<React.Fragment key={item?.cdr_id}>
					<AudioTrackPlayerAutoanswer
						data={item}
						currAudioTrackIndex={currAudioTrackIndex}
						autoPlay={currAudioTrackIndex === item.index}
						handleSetCurrTrack={handleSetCurrTrack}
						handleEndTrack={handleEndTrack}
						handleRemove={handleRemove}
						isLoading={isLoading}
					/>
					{audioTracks?.length && index < audioTracks?.length - 1 ? <ContentDivider /> : null}
				</React.Fragment>
			))}
		</div>
	);
};
