import { Title } from "@/components/common";
import { OLD_CMS_URL } from "@/constants/env";
import { AUTOANSWER, MISSED_CALLS } from "@/constants/routes";
import { useFiltersState } from "@/contexts/filters-state";
import { useCallbackCountQuery, useStatisticsIndexQuery } from "@/state/api";
import { useAppDispatch } from "@/state/selector";
import { useStatisticsOpenSelector } from "@/state/selector/options-selector";
import { toggleStatistics } from "@/state/slice/options-slice";
import { newWindowOpen } from "@/utils/new-window";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { SidebarContainer, SidebarItem, SidebarItemTextContent, Tooltip, Typography } from "@components/common";
import {
	endTimeDefault,
	formatDateRangeString,
	startTimeDefault,
} from "@components/headers/requests-header/request-header-filters/filter-inputs/filter-period/api/filter-period";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import type { MetricsSidebarProps } from "@components/metrics-sidebar/metrics-sidebar.types";
import { MetricsSidebarFields, checkEmptуСommonFilters, startedDateForClientWaiting } from "@constants/metrics-sidebar";
import { UISizes } from "@global-types/mods.types";
import { StatusRequestTypes } from "@global-types/status-request.types";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import cn from "classnames";
import { parse } from "date-fns";
import { useNavigate } from "react-router";
import { ContentLoader } from "../content-loader";
import styles from "./metrics-sidebar.module.scss";

const BASE_PATH = OLD_CMS_URL;

export const MetricsSidebar = ({ isFetching }: Partial<MetricsSidebarProps>) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isStatisticsOpen = useStatisticsOpenSelector();
	const { filters, setFilters, initialState } = useFiltersState();
	const { data, isLoading, refetch, isFetching: queryFetching } = useStatisticsIndexQuery();
	const reloadStatisticClasses = cn(styles["metrics-sidebar-top-button-wrapper"], {
		[styles["metrics-sidebar-top-button-wrapper-loading"]]: queryFetching,
	});
	const metrics = data?.data;
	const dubpercent =
		metrics?.requests_today?.dub_count && metrics?.requests_today?.request_count && metrics?.requests_today?.request_count !== 0
			? (metrics?.requests_today?.dub_count / metrics?.requests_today?.request_count) * 10 > 0.1
				? Math.floor((metrics?.requests_today?.dub_count / metrics?.requests_today?.request_count) * 10 * 10)
				: "< 1"
			: "0";

	const { currentData } = useCallbackCountQuery();

	const currentCount = currentData?.data;

	const btnClientWaitingActive =
		checkEmptуСommonFilters(filters) &&
		filters?.[FilterNames.status]?.[0] === StatusRequestTypes.ClientWaiting &&
		filters?.[FilterNames.dateStart] === startedDateForClientWaiting &&
		!filters?.[FilterNames.hasNoMaster] &&
		!filters?.[FilterNames.notGivenWorker];

	const btnHasNoMasterActive =
		checkEmptуСommonFilters(filters) &&
		filters?.[FilterNames.hasNoMaster] &&
		!filters?.[FilterNames.status]?.length &&
		!filters?.[FilterNames.notGivenWorker] &&
		!filters?.[FilterNames.dateStart] &&
		!filters?.[FilterNames.dateEnd] &&
		!filters?.[FilterNames.period];

	const btnNotTransferMasterActive =
		checkEmptуСommonFilters(filters) &&
		filters?.[FilterNames.status]?.[0] === StatusRequestTypes.ToExecute &&
		filters?.[FilterNames.notGivenWorker] &&
		!filters?.[FilterNames.hasNoMaster] &&
		!filters?.[FilterNames.dateStart] &&
		!filters?.[FilterNames.dateEnd] &&
		!filters?.[FilterNames.period];

	return (
		<>
			{isStatisticsOpen ? (
				<SidebarContainer>
					{isLoading && currentCount ? (
						<ContentLoader />
					) : (
						<>
							<div className={reloadStatisticClasses}>
								<Button
									btnType={"submit"}
									isLoading={queryFetching}
									type={queryFetching ? ButtonTypes.secondary : ButtonTypes.primary}
									iconLeft={<i className={"icon icon-repeat"} />}
									onClick={() => {
										refetch();
									}}
								>
									Обновить статистику
								</Button>
							</div>
							<div className={styles["metrics-sidebar-top"]}>
								<Typography
									as={"caption"}
									tag={4}
									weight={1}
									decoration={"none"}
									className={styles["metrics-sidebar-subtitle"]}
								>
									{MetricsSidebarFields.titleCommon}
								</Typography>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.applicationsToday}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_today?.request_count}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.applicationsYesterday}{" "}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_yesterday?.request_count}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.applicationsDuplicate}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_today?.dub_count ? metrics?.requests_today?.dub_count : "0"}
										</Typography>
										<Typography as={"paragraph"} tag={3} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{dubpercent && `(${dubpercent}%)`}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.currentRecord}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_top_record?.request_count}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={`${styles["metrics-sidebar-text-value"]} ${styles["text-value-interactive"]}`}
										>
											{metrics?.requests_top_record?.spb_count &&
												metrics?.requests_top_record?.msk_count &&
												`(${metrics?.requests_top_record?.spb_count}+${metrics?.requests_top_record?.msk_count})`}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.clientsWaiting}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_with_waiting_clients}
										</Typography>
									</SidebarItemTextContent>
									<Button
										type={ButtonTypes.secondaryUncolored}
										size={UISizes.medium}
										isActive={btnClientWaitingActive}
										disabled={isFetching}
										iconLeft={<i className={"icon icon-show"} />}
										onClick={() => {
											if (btnClientWaitingActive) {
												setFilters(initialState);
												return;
											}
											setFilters(() => {
												return {
													...initialState,
													[FilterNames.status]: [StatusRequestTypes.ClientWaiting],
													[FilterNames.dateStart]: startedDateForClientWaiting,
													[FilterNames.period]: formatDateRangeString({
														startDate: parse(startedDateForClientWaiting, "dd.MM.yyyy HH:mm", new Date()),
														startTime: startTimeDefault,
														endDate: new Date(),
														endTime: endTimeDefault,
													}),
												} as FilterTypes;
											});
										}}
									/>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.masterNotSelected}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_without_masters}
										</Typography>
									</SidebarItemTextContent>
									<Button
										type={ButtonTypes.secondaryUncolored}
										size={UISizes.medium}
										isActive={btnHasNoMasterActive}
										disabled={isFetching}
										iconLeft={<i className={"icon icon-show"} />}
										onClick={() => {
											if (btnHasNoMasterActive) {
												setFilters(initialState);
												return;
											}
											setFilters(() => {
												return {
													...initialState,
													[FilterNames.hasNoMaster]: true,
													[FilterNames.dateEnd]: undefined,
													[FilterNames.dateStart]: undefined,
													[FilterNames.period]: undefined,
												} as FilterTypes;
											});
										}}
									/>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.notPassedToMaster}{" "}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.requests_not_approved}{" "}
										</Typography>
									</SidebarItemTextContent>
									<Button
										type={ButtonTypes.secondaryUncolored}
										size={UISizes.medium}
										isActive={btnNotTransferMasterActive}
										disabled={isFetching}
										iconLeft={<i className={"icon icon-show"} />}
										onClick={() => {
											if (btnNotTransferMasterActive) {
												setFilters(initialState);
												return;
											}
											setFilters(() => {
												return {
													...initialState,
													[FilterNames.notGivenWorker]: true,
													[FilterNames.status]: [StatusRequestTypes.ToExecute],
													[FilterNames.dateEnd]: undefined,
													[FilterNames.dateStart]: undefined,
													[FilterNames.period]: undefined,
												} as FilterTypes;
											});
										}}
									/>
								</SidebarItem>
								<Typography
									as={"caption"}
									tag={4}
									weight={1}
									decoration={"none"}
									className={classNames(styles["metrics-sidebar-subtitle"], styles["metrics-sidebar-dispatchers"])}
								>
									{MetricsSidebarFields.titleDispatchers}
								</Typography>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.missedCalls}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											onClick={() => navigate(`${MISSED_CALLS}`)}
											className={`${styles["metrics-sidebar-text-value"]} ${styles["text-value-cursor"]}`}
										>
											{metrics?.missing_calls?.length}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<div
											className={styles["metrics-sidebar-callback"]}
											onClick={() => newWindowOpen(`${BASE_PATH}callbacks/opened`)}
										>
											<Typography
												as={"paragraph"}
												tag={4}
												decoration={"none"}
												className={styles["metrics-sidebar-text"]}
											>
												{MetricsSidebarFields.callBack}
											</Typography>
											<Typography
												as={"paragraph"}
												tag={3}
												decoration={"none"}
												className={styles["metrics-sidebar-text-value-count"]}
											>
												{currentCount?.count}
											</Typography>
										</div>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.autoanswer}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											onClick={() => navigate(`${AUTOANSWER}`)}
											className={`${styles["metrics-sidebar-text-value"]} ${styles["text-value-cursor"]}`}
										>
											{metrics?.answer_phone?.length}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.totalDispatchers}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.phone_statistics?.logged_in}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.notReceivingCalls}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{Array.isArray(metrics?.phone_statistics?.disp_not_take_call)
												? metrics?.phone_statistics?.disp_not_take_call?.length
												: 0}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<SidebarItem>
									<SidebarItemTextContent>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.freeDispatchers}
										</Typography>
										<Typography
											as={"paragraph"}
											tag={3}
											decoration={"none"}
											className={styles["metrics-sidebar-text-value"]}
										>
											{metrics?.phone_statistics?.available}
										</Typography>
									</SidebarItemTextContent>
								</SidebarItem>
								<div className={styles["metrics-sidebar-clients-in-line"]}>
									<div className={styles["metrics-sidebar-clients-in-line__title"]}>
										<Typography as={"paragraph"} tag={4} decoration={"none"} className={styles["metrics-sidebar-text"]}>
											{MetricsSidebarFields.clientsInLine}
										</Typography>
									</div>
									<div className={styles["metrics-sidebar-clients-in-line__value"]}>
										<Title as={"h4"} className={styles["metrics-sidebar-text-value"]}>
											{metrics?.phone_statistics?.callers}
										</Title>
									</div>
								</div>
								<Typography
									as={"caption"}
									tag={4}
									weight={1}
									decoration={"none"}
									className={classNames(styles["metrics-sidebar-subtitle"], styles["metrics-sidebar-dispatchers"])}
								>
									{MetricsSidebarFields.titleOneDispatcher}
								</Typography>
								<div className={styles["metrics-sidebar-dispatcher-container"]}>
									<div className={styles["metrics-sidebar-dispatcher-icons"]}>
										<Tooltip placement="bottom" show={true} key={nanoid()} anchorId="requests" text="Заявки">
											<div id="requests" className={styles["metrics-sidebar-dispatcher-icon"]}>
												<i className="icon icon-task" />
											</div>
										</Tooltip>
										<Tooltip placement="bottom" show={true} key={nanoid()} anchorId="outgoing" text="Исходящие">
											<div id="outgoing" className={styles["metrics-sidebar-dispatcher-icon"]}>
												<i className="icon icon-phone-outgoing" />
											</div>
										</Tooltip>
										<Tooltip placement="bottom" show={true} key={nanoid()} anchorId="incoming" text="Входящие">
											<div id="incoming" className={styles["metrics-sidebar-dispatcher-icon"]}>
												<i className="icon icon-phone-incoming" />
											</div>
										</Tooltip>
										<Tooltip placement="bottom" show={true} key={nanoid()} anchorId="totalcalls" text="Всего">
											<div id="totalcalls" className={styles["metrics-sidebar-dispatcher-icon"]}>
												<i className="icon icon-phone-call" />
											</div>
										</Tooltip>
									</div>
									<div className={styles["metrics-sidebar-dispatcher-line"]}>
										<Typography
											as={"paragraph"}
											tag={4}
											weight={1}
											decoration={"none"}
											className={styles["metrics-sidebar-dispatcher-subtitle"]}
										>
											Сегодня:
										</Typography>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-blue"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.today.request_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-red"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.today.outcome_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-yellow"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.today.income_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-green"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.today.total_count}
											</Typography>
										</div>
									</div>
									<div className={styles["metrics-sidebar-dispatcher-line"]}>
										<Typography
											as={"paragraph"}
											tag={4}
											weight={1}
											decoration={"none"}
											className={styles["metrics-sidebar-dispatcher-subtitle"]}
										>
											Месяц:
										</Typography>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-blue"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.month.request_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-red"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.month.outcome_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-yellow"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.month.income_count}
											</Typography>
										</div>
										<div
											className={classNames(
												styles["metrics-sidebar-dispatcher-data"],
												styles["metrics-sidebar-dispatcher-green"],
											)}
										>
											<Typography as={"caption"} tag={4} weight={2} decoration={"none"}>
												{metrics?.dispatcher?.month.total_count}
											</Typography>
										</div>
									</div>
								</div>
							</div>
							<div className={styles["metrics-bottom-container"]}>
								<button onClick={() => dispatch(toggleStatistics(false))} className={styles["metrics-sidebar-tray-button"]}>
									Свернуть <i className="icon icon-cheveron-right" />
								</button>
							</div>
						</>
					)}
				</SidebarContainer>
			) : (
				<div className={styles["metrics-sidebar-button-container"]}>
					<Tooltip placement="left" show={true} key={nanoid()} anchorId="statistics-button" text="Статистика">
						<button
							id="statistics-button"
							onClick={() => dispatch(toggleStatistics(true))}
							className={styles["metrics-sidebar-button"]}
						>
							<i className="icon icon-cheveron-left" />
						</button>
					</Tooltip>
				</div>
			)}
		</>
	);
};
