import { ModalContainer, Typography } from '@components/common'
import { ModalBlackout, ModalBody, ModalHeader } from '@common/modal-container'
import { RequestHistoryModalProps } from '@components/modals/request-history/request-history.types'
import { RequestHistoryModalLeftContent } from './left-content'
import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { useNavigate, useParams } from 'react-router-dom'
import { TableRequestHistory } from '@/pages/request-page/log-card/left-content/tables'
import styles from './request-history.module.scss'
import { REQUESTS } from '@constants/routes'
import { clearReklamaAndObjectText } from '@utils/clear-text'

export const RequestHistoryModal = ({
  onClose,
  onClosePath,
  title,
  subtitle,
  requestId,
  closeOnBlackoutClick,
}: Partial<RequestHistoryModalProps>) => {
  // делаем запрос

  const columnHelper = createColumnHelper<any>()
  const filters = localStorage.getItem('filters') ? JSON.parse(String(localStorage.getItem('filters'))) : null
  const { fullcodeId } = useParams()
  const columnsHistory = [
    //указываем ключи объекта первым аргументом
    columnHelper.accessor('date', {
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Дата и время
        </Typography>
      ),
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.user_fio, {
      id: 'user_fio',
      cell: (info) => info.renderValue(),
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          ФИО
        </Typography>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Действие
        </Typography>
      ),
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('field', {
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Поле{' '}
        </Typography>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('before', {
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Было{' '}
        </Typography>
      ),
      cell: (info) => {
        return clearReklamaAndObjectText(info.renderValue())
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('after', {
      header: () => (
        <Typography as={'caption'} decoration={'none'} tag={4} weight={1}>
          Стало
        </Typography>
      ),
      cell: (info) => {
        return clearReklamaAndObjectText(info.renderValue())
      },
      footer: (info) => info.column.id,
    }),
  ]

  const navigate = useNavigate()

  return (
    <>
      <ModalContainer
        onClose={
          onClose
            ? onClose
            : onClosePath
            ? () =>
                navigate({
                  pathname: REQUESTS,
                  search: new URLSearchParams(filters).toString(),
                })
            : () => navigate('/')
        }
        showBlackout
        closeOnBlackoutClick={closeOnBlackoutClick}
        className={styles['request-history-modal']}>
        <ModalHeader
          showLeftContent
          contentLeft={<RequestHistoryModalLeftContent title={title} subtitle={fullcodeId ? `Заявка #${fullcodeId}` : ''} />}
          showCloseButton
          onClose={
            onClose
              ? onClose
              : onClosePath
              ? () =>
                  navigate({
                    pathname: REQUESTS,
                    search: new URLSearchParams(filters).toString(),
                  })
              : () => navigate('/')
          }
        />
        <ModalBody>
          <TableRequestHistory columns={columnsHistory} />
        </ModalBody>
      </ModalContainer>
    </>
  )
}
