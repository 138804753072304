import type { FC } from 'react'
import type { TooltipProps } from './tooltip.types'

import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { usePopper } from 'react-popper'

import styles from './tooltip.module.scss'
import classNames from 'classnames'
import { Typography } from '@components/common'

export const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  anchorId,
  placement,
  show,
  className,
  tooltipOffset = [0, 10],
  tooltipContent,
  externalHoverId = '',
}: TooltipProps) => {
  const [reference, setReference] = useState<HTMLDivElement | null>(null)
  const [arrow, setArrow] = useState<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>(null)

  const [hoveredTooltip, setHoveredTooltip] = useState<string>(
    externalHoverId || '',
  )

  const { styles: tooltipStyle, attributes } = usePopper(reference, tooltip, {
    placement: placement ? placement : 'bottom',
    strategy: 'fixed',
    modifiers: [
      { name: 'arrow', options: { element: arrow, padding: 20 } },
      {
        name: 'offset',
        options: {
          offset: tooltipOffset,
        },
      },
    ],
  })

  useEffect(() => {
    externalHoverId !== ''
      ? setHoveredTooltip(externalHoverId)
      : setHoveredTooltip('')
  }, [externalHoverId])

  return (
    <>
      <div
        onMouseEnter={() => (externalHoverId ? () => {} : setHoveredTooltip(anchorId))}
        onMouseLeave={() => (externalHoverId ? () => {} : setHoveredTooltip(''))}
        onScroll={() => setHoveredTooltip('')}
        onWheel={() => setHoveredTooltip('')}
        ref={setReference}
        className={classNames(styles['tooltip-container'], className)}>
        {children}
      </div>
      {show && anchorId === hoveredTooltip ? (
        <div
          ref={setTooltip}
          className={styles['tooltip-wrapper']}
          style={tooltipStyle.popper}
          {...attributes.popper}>
          <div className={styles['tooltip-wrapper-content']}>
            {text && (
              <Typography
                as={'paragraph'}
                tag={4}
                decoration={'none'}
                className={styles['tooltip-text-value']}>
                {text}
              </Typography>
            )}
            {tooltipContent ? tooltipContent : null}
          </div>
          <div
            ref={setArrow}
            className={styles.arrow}
            style={tooltipStyle.arrow}
            data-popper-arrow
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
