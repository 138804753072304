import styles from './label-container.module.scss'
import { LabelContainerProps } from '@common/label-container/label-conatiner.types'
import { Typography } from '@components/common'
import cn from 'classnames'

export const LabelContainer = ({
  children,
  showLabel,
  labelSide,
  label,
  localId,
  handleShowError = () => {},
  ...props
}: Partial<LabelContainerProps>) => {
  const labelContainerClasses = cn(styles['label-container'], {
    [styles['label-top']]: labelSide === 'top',
    [styles['label-left']]: labelSide === 'left',
  })

  return (
    <div
      className={labelContainerClasses}
      onMouseLeave={() => handleShowError('')}
      onMouseEnter={() => handleShowError(String(label))}>
      {showLabel && (
        <label htmlFor={localId} className={styles['label-wrapper']}>
          <Typography
            className={styles.label}
            as={'paragraph'}
            tag={4}
            decoration={'none'}>
            {label}:
          </Typography>
        </label>
      )}
      {children}
    </div>
  )
}
