import styles from './modal-blackout.module.scss'


export const ModalBlackout = ({ onClose }: { onClose?: () => void }) => {
  return (
    <div
      onClick={onClose}
      className={styles['modal-container-blackout']}></div>
  )
}
