import { useActor } from "@xstate/react";
import type { FC } from "react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { IncomingCall } from "../../../entities/incoming-call";
import { AcceptIncomingCall } from "../../../features/incoming-call/accept-call";
import { DeclineIncomingCall } from "../../../features/incoming-call/decline-call";
import { IncomingCallName } from "../../../shared/components";
import { SessionStatus, useSessionContext } from "../../../shared/contexts/tab-session-context/tab-session-context";
import { convertPhone } from "../../../shared/utils/convert-phone";

export type TIncomingCallWidget = {
	withDivider?: boolean;
};

export const IncomingCallWidget: FC<TIncomingCallWidget> = ({ withDivider = true }) => {
	const { phonerDraggingService } = useGlobalPhonerState();
	const { sessionsDTO } = useSessionContext();
	const currentSession = sessionsDTO.filter((session) => session.status === SessionStatus.RINGING)?.[0];
	const currentCallPhoneNumber = currentSession?.phoneNumber || "";
	const [statePhonerDragging] = useActor(phonerDraggingService);
	const incomingCallWidgetPosition = statePhonerDragging.context.animationTransitionConfig.animationDirection;

	return (
		<IncomingCall
			withDivider={withDivider}
			middleSlot={
				<>
					<IncomingCallName name={convertPhone(currentCallPhoneNumber)} />
					<DeclineIncomingCall />
					<AcceptIncomingCall />
				</>
			}
			incomingCallWidgetPosition={incomingCallWidgetPosition}
		/>
	);
};
