import { Button } from "@/components/button"
import { ButtonTypes } from "@/components/button/button.types"
import { Title } from "@/components/common"
import styles from './okk-components.module.scss'

const OkkConfirm = ({ data, onCancel, onConfirm, text, confirmButtonText, cancelButtonText }: any) => {
  return (
    <div className={styles['okk-confirm-container']}>
      <Title as={'h5'}>{text}</Title>
      <div className={styles['okk-confirm-button-container']}>
        <Button type={ButtonTypes.secondaryUncolored} onClick={onCancel}>
          {cancelButtonText}
        </Button>
        <Button
          type={ButtonTypes.dangerUncolored}
          onClick={() => onConfirm(data)}
        >
          {confirmButtonText}
        </Button>
      </div>
    </div>
  )
}

export default OkkConfirm