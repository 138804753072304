import { FC } from 'react'
import { Button, Icon, Typography } from '../../../../shared'
import styles from './error-plug.module.scss'

export type ErrorPlugProps = {
  onRetry?: () => void,
}

export const ErrorPlug: FC<ErrorPlugProps> = ({onRetry}) => {
  return (
    <>
      <div className={styles.container}>
      <div className={styles['wrapper']}>
        <Icon icon={'icon-bxs-x-circle'} />
        <div className={styles['text-wrapper']}>
          <Typography as={'caption'} weight={600} style={{ color: 'var(--phoner-gray-0)' }} decoration="none" tag={4}>
            Не удалось загрузить список звонков
          </Typography>
          <Typography as={'caption'} weight={400} style={{ color: 'var(--phoner-gray-500)' }} decoration="none" tag={5}>
            Попробуйте повторить попытку или обратитесь в поддержку
          </Typography>
        </div>
      </div>
      <Button variant={'primary'} onClick={onRetry}>
        Повторить попытку
      </Button>
      </div>
    </>
  )
}
