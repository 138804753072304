export enum StatusRequestTypes {
  ToExecute = 0,
  InProgress = 1,
  Diagnostics = 2,
  Completed = 3,
  RejectedOther = 4,
  NoCall = 5,
  ReturnToClient = 8,
  OrderError = 15,
  ClientThinking = 17,
  CouldNotCall = 19,
  MasterCompleted = 20,
  ClientWaiting = 21,
  RejectedConsultation = 22,
  RejectedFixedSelf = 23,
  RejectedPriceCheck = 24,
  RejectedExpensive = 25,
  RejectedOtherCompany = 26,
  RejectedOtherMaster = 27,
  WaitingForParts = 28,
  Estimate = 29,
  EstimateProvided = 30,
  RejectedToWorkshop = 31,
  RejectedDisposal = 32,
  Accepted = 33,
  WarrantyRepair = 34,
  Warranty = 35,
  ReturnCompleted = 36,
  AwaitingFullPayment = 37,
}
