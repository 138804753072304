export const validateOnlyNumbers = (value: string) => {
  const re = /^[0-9\b]+$/

  return re.test(value) || value === ''
}

export const validateOnlyNumbersWithComma = (str: string) => {
  if (str.length && typeof str === 'string') return str.replace(/[^0-9.,]/g, '')
  return str
}
