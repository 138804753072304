import { Badge } from "@/components/common/badge";
import { Typography } from "@/components/common/typography";
import type { QualityControlIndexResource } from "@/state/api";
import { formatCommentTime } from "@common/request-card/request-card-content/request-card-content";
import classNames from "classnames";
import { Fragment } from "react";
import styles from "../../request-card.module.scss";
import type { IRequestComment, IRequestOzokComment } from "./request-card-comment.types";

export const isOKKOrTMMCommentType = (comment: unknown): comment is QualityControlIndexResource => {
	if (!comment) return false;
	return typeof comment === "object" && "answer_groups" in comment && typeof comment.answer_groups === "object";
};
export const RequestCardComment = ({ comment }: IRequestComment | IRequestOzokComment) => {
	return (
		<>
			<div className={styles[`request-card-okk-${isOKKOrTMMCommentType(comment) ? "column" : "row"}`]}>
				<div className={styles["request-card-okk-left"]} style={{ width: "100%" }}>
					<Typography className={styles["request-card-okk-text"]} as={"paragraph"} decoration={"none"} weight={2} tag={4}>
						{isOKKOrTMMCommentType(comment) ? formatCommentTime(comment.date_add) : formatCommentTime(comment.date)}
					</Typography>
					<Typography
						className={classNames(styles["request-card-okk-text"])}
						as={"paragraph"}
						decoration={"none"}
						weight={2}
						tag={4}
					>
						<span>{comment.user_fio}</span>&nbsp;
						<span>[{isOKKOrTMMCommentType(comment) ? comment.request_state : comment.state_name}]:</span>
					</Typography>
					<div style={{ display: "contents" }}>
						<Typography
							as={"paragraph"}
							decoration={"none"}
							weight={2}
							tag={4}
							style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
						>
							{isOKKOrTMMCommentType(comment) ? comment.comment : comment.text}
						</Typography>
					</div>
				</div>
				{isOKKOrTMMCommentType(comment) &&
					Array.isArray(comment.answer_groups) &&
					comment.answer_groups.length > 0 &&
					comment.answer_groups.map((group) => {
						return (
							<div key={`${group.group.name}+${comment.date_add}`} className={styles["request-card-okk-row"]}>
								<div className={styles["request-card-okk-left"]}>
									<Typography
										className={styles["request-card-okk-text"]}
										as={"paragraph"}
										decoration={"none"}
										weight={2}
										tag={4}
									>
										{formatCommentTime(comment.date_add)}
									</Typography>
									<Typography
										className={styles["request-card-okk-text"]}
										as={"paragraph"}
										weight={2}
										decoration={"none"}
										tag={4}
									>
										{`Результаты опроса [${group.group.name}]:`}
									</Typography>
								</div>
								<div className={styles["okk-badge-container"]}>
									{Array.isArray(group.answers) &&
										group.answers.length > 0 &&
										group.answers.map((item) => (
											<Fragment key={item.id}>
												{item.title ? (
													<Badge
														isQuiz={true}
														key={item.id}
														className={classNames(styles["okk-badge"], {
															[styles["okk-badge-success"]]: item.status === "success",
															[styles["okk-badge-default"]]: item.status === "unknown",
															[styles["okk-badge-danger"]]: item.status === "danger",
														})}
													>
														{item.title}
													</Badge>
												) : null}
											</Fragment>
										))}
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};
