import type { StatusLabelProps } from "@components/modals/common-forms/status-label/status-label.types";

const generalType = [
	{
		id: 0,
		title: "К исполнению",
	},
	{
		id: 1,
		title: "В работе",
	},
	{
		id: 8,
		title: "Возврат клиенту",
	},
	{
		id: 17,
		title: "Клиент думает",
	},
	{
		id: 19,
		title: "Не дозвонился",
	},
	{
		id: 20,
		title: "Мастер выполнил",
	},
	{
		id: 21,
		title: "Клиент ждёт",
	},
	{
		id: 28,
		title: "Ждет детали",
	},
	{
		id: 33,
		title: "Принял",
	},
	{
		id: 34,
		title: "Ремонт по гарантии",
	},
	{
		id: 37,
		title: "Ожидает полной оплаты",
	},
	{
		id: 30,
		title: "Составлена смета",
	},
] as const;

const finalType = [
	{
		id: 2,
		title: "Диагностика",
	},
	{
		id: 3,
		title: "Выполнено",
	},
	{
		id: 5,
		title: "Не дозвон",
	},
	{
		id: 15,
		title: "Неправильно оформлен",
	},
	{
		id: 29,
		title: "Смета",
	},
] as const;

export const cancelType = [
	{
		id: 22,
		title: "Отказ (консультация)",
	},
	{
		id: 23,
		title: "Отказ (сам починил)",
	},
	{
		id: 24,
		title: "Отказ (узнавали цены)",
	},
	{
		id: 25,
		title: "Отказ (дорого)",
	},
	{
		id: 31,
		title: "Отказ (в мастерскую)",
	},
	{
		id: 32,
		title: "Отказ (утилизация)",
	},
	{
		id: 26,
		title: "Отказ (обратились в др. фирму)",
	},
	{
		id: 27,
		title: "Отказ (пришел другой мастер)",
	},
	{
		id: 4,
		title: "Отказ (другое)",
	},
] as const;

type GetStatusLabelTypeReturnType = {
	title: string;
	type: StatusLabelProps["type"];
};

export const getStatusLabelType = (typeId?: number): GetStatusLabelTypeReturnType => {
	const general = generalType.find((item: any) => item.id === typeId);
	const final = finalType.find((item: any) => item.id === typeId);
	const cancel = cancelType.find((item: any) => item.id === typeId);

	if (general) {
		return {
			type: "general",
			title: general.title,
		};
	}
	if (final) {
		return {
			type: "final",
			title: final.title,
		};
	}
	if (cancel) {
		return {
			type: "cancel",
			title: cancel.title,
		};
	}

	return {
		type: "general",
		title: generalType[0].title,
	};
};
