import { useRequestsCommentsAllIndexQuery } from '@/state/api'
import { useParams } from 'react-router'
import styles from './request-comments.module.scss'
import NoCommetsImage from '@/assets/svg/NoData.svg'
import { NoData } from '@/components/common'
import {
  MessageDateDivider,
  MessageBox,
  Message,
} from '@/components/common/chat'
import { Avatar } from '@/components/common/chat/avatar'
import { format, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import DefaultAvatar from './default-avatar.png'

const QualityControlComments = ({ id }: { id?: number }) => {
  const { requestId } = useParams()
  const { data } = useRequestsCommentsAllIndexQuery({
    requestId: Number(requestId) || Number(id),
  })

  return (
    <div className={styles['okk-container-request']}>
      {data?.data?.quality_controls &&
      data?.data?.quality_controls?.length > 0 ? (
        data?.data?.quality_controls.map((item) => {
          return (
            <>
              <MessageDateDivider
                date={
                  item.date_add &&
                  format(
                    parse(item.date_add, 'dd.MM.yyyy HH:mm:ss', new Date()),
                    'dd MMMM',
                    {
                      locale: ru,
                    },
                  )
                }
              />
              <MessageBox isQualityControl>
                <div className={styles['message-container']}>
                  <Avatar src={DefaultAvatar} />
                  <Message
                    isQualityControls
                    badges={item.answer_groups}
                    showActionButtons={false}
                    text={item.comment}
                    time={
                      item.date_add
                        ? format(
                            parse(
                              item.date_add,
                              'dd.MM.yyyy HH:mm:ss',
                              new Date(),
                            ),
                            'HH:mm',
                          )
                        : ''
                    }
                    label={`${item.type} • ${item.user_fio} • ${item.request_state}`}
                  />
                </div>
              </MessageBox>
            </>
          )
        })
      ) : (
        <NoData
          src={NoCommetsImage}
          alt="No comments"
          title="Комментарии отсутствуют"
        />
      )}
    </div>
  )
}

export default QualityControlComments
