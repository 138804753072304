import React, { useEffect, useRef, useState } from "react";
import { Button } from "@components/button";
import { ButtonTypes } from "@components/button/button.types";
import { UISizes } from "@global-types/mods.types";
import { Dropdown } from "@components/dropdown";
import styles from "./calendar-header-dropdown.module.scss";
import {
	CalendarHeaderDropdownProps,
	ContentProps,
	TriggerProps,
} from "@components/calendar/header/header-dropdown/calendar-header-dropdown.types";
import { nanoid } from "@reduxjs/toolkit";
import { Title } from "@common/title";

export const CalendarDropdown = ({ buttonText, options, onHandleChange, optionsType }: CalendarHeaderDropdownProps) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [triggerButtonWidth, setTriggerButtonWith] = useState<number>();
	const triggerButtonRef = useRef<HTMLButtonElement | null>(null);

	useEffect(() => {
		if (triggerButtonRef && triggerButtonRef.current) {
			setTriggerButtonWith(triggerButtonRef.current?.getBoundingClientRect().width);
		}
	}, [triggerButtonRef.current]);

	const onDropDownItemClick = (opt: number | string) => {
		optionsType === "months" ? onHandleChange(options.indexOf(opt as never)) : onHandleChange(Number(opt));
	};

	const trigger = ({ buttonText }: TriggerProps) => (
		<Button
			ref={triggerButtonRef}
			isActive={false}
			btnType={"button"}
			type={ButtonTypes.secondaryUncolored}
			size={UISizes.small}
			iconRight={!isVisible ? <i className={"icon icon-cheveron-down"}></i> : <i className={"icon icon-cheveron-up"}></i>}
			onClick={() => {}}
		>
			<Title as={"h5"}>{buttonText}</Title>
		</Button>
	);

	const content = ({ options }: ContentProps) => {
		return (
			<div
				style={{
					width: optionsType === "years" ? `${triggerButtonWidth}px` : "max-content",
				}}
				className={`${styles["calendar-dropdown-list-wrapper"]} react-datepicker-ignore-onclickoutside`}
			>
				<div className={`${styles["calendar-dropdown-list"]} react-datepicker-ignore-onclickoutside`}>
					{options.map((opt: number | string) => {
						return (
							<div
								key={opt}
								onClick={(e) => onDropDownItemClick(opt)}
								className={`${styles["calendar-dropdown-list__item"]} react-datepicker-ignore-onclickoutside`}
							>
								{opt}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<Dropdown
			className={"react-datepicker-ignore-onclickoutside"}
			offsetCorrection={[0, 0]}
			isVisible={isVisible}
			trigger={trigger({ buttonText })}
			body={content({ options })}
			onClose={() => setIsVisible(false)}
			onOpen={() => setIsVisible(true)}
		/>
	);
};
