import { Button } from '../../../ui-kit/button'
import { Icon } from '../../../ui-kit/icon'
import React from 'react'

type MicrophoneIconButtonProps = {
  handleMicrophone: () => void
  isMuted: boolean
  isDisabled: boolean
}
export const MicrophoneIconButton = ({
  handleMicrophone,
  isMuted,
  isDisabled,
}: MicrophoneIconButtonProps) => {
  return (
    <Button
      disabled={isDisabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        handleMicrophone()
      }}
      size="m"
      variant={isMuted ? 'primary' : 'secondary'}
      width="icon"
      renderIcon={
        <Icon
          icon={isMuted ? 'icon-bx-microphone-off' : 'icon-bx-microphone-on'}
        />
      }
    />
  )
}
