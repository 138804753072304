import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import { District, useDistrictsFeedQuery } from '@/state/api'
import { addSnack } from '@/eventbus/events/snackbar'
import { MultiSelect } from '@common/multi-select'
import { CreateRequestNames } from '@constants/create-request'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'

export const RequestModalDistrictInput = <T extends District[]>({
  input,
  meta,
  label,
  type,
  acceptPathAsTitle,
  labelSide = 'left',
  inputChildren,
  ...props
}: RequestModalTextInputProps<T>) => {
  const [initialValue, setInitialValue] = useState<District[] | undefined>(undefined)

  const { data: serverData, isLoading, isError, error, refetch } = useDistrictsFeedQuery()

  const handleRefetchDistrics = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-districts-error',
        text: `Не удалось загрузить районы.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchDistrics(),
      })
    }
  }, [isError])

  const districtId = useField(input?.name)?.input?.value

  useEffect(() => {
    if (districtId && !isLoading && serverData?.data) {
      setInitialValue(
        findItem<District>({
          id: districtId,
          arr: serverData?.data,
        }),
      )
    }
  }, [districtId, isLoading])

  return (
    <>
      <MultiSelect<District>
        isDataLoading={isLoading}
        width={'full-w'}
        isDataNested={true}
        inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={serverData?.data || []}
        inputId={CreateRequestNames.districtId}
        onChange={(items) => (items?.length ? input.onChange(items[0].id) : input.onChange(undefined))}
        initialSelectedItems={initialValue}
        externalInputProps={input}
        externalInputMetaProps={meta}
      />
    </>
  )
}
