import { forwardRef } from "react";
import ReactDatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "./react-datepicker-styles.scss";
import ru from "date-fns/locale/ru";
import { Button } from "@/components/button";
import { CalendarButtonProps, CalendarProps } from "./calendar.types";
import { ButtonTypes } from "@/components/button/button.types";
import { CalendarHeader } from "@components/calendar/header/calendar-header";
import { UISizes } from "@global-types/mods.types";
import { formatSelectedDate } from "@components/calendar/api/calendar";

registerLocale("ru", ru);
setDefaultLocale("ru");

const CalendarButton = forwardRef(({ className, onClick, selectedDate }: CalendarButtonProps, ref) => (
	<Button
		className={`line-content-date-picker-button ${className ? className : ""}`}
		type={ButtonTypes.secondary}
		ref={ref}
		size={UISizes.medium}
		onClick={onClick}
		iconLeft={<i className="icon icon-calendar" />}
	>
		{selectedDate}
	</Button>
));

export const Calendar = forwardRef(
	(
		{
			className,
			dateFormat = "dd.MM.yyyy",
			timeFormat = "HH:mm",
			shouldCloseOnSelect = true,
			selected = new Date(),
			withoutTriggerButton = false,
			onChange,
			customInput,
			showTimeInput,
			selectedTime,
			showTimeSelect = true,
			timeActionButton,
			...rest
		}: CalendarProps,
		ref,
	) => {
		return (
			<div className={`date-picker ${className ? className : ""}`}>
				<ReactDatePicker
					ref={ref as any}
					selected={selected}
					onChange={onChange}
					dateFormat={dateFormat}
					timeFormat={timeFormat}
					timeIntervals={15}
					customTimeInput={
						timeActionButton && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									gap: "4px",
									width: "100%",
								}}
							>
								<div
									style={{
										display: "flex",
										justifySelf: "flex-end",
									}}
								>
									{timeActionButton()}
								</div>
							</div>
						)
					}
					customInput={
						customInput ? (
							customInput
						) : withoutTriggerButton ? (
							<></>
						) : (
							<CalendarButton
								selectedDate={formatSelectedDate(selected, {
									year: "2-digit",
									month: "2-digit",
									day: "2-digit",
								})}
							/>
						)
					}
					renderCustomHeader={(props) => <CalendarHeader selected={selected} {...props} />}
					showTimeInput={showTimeInput}
					showTimeSelect={showTimeSelect}
					timeCaption="Время"
					shouldCloseOnSelect={shouldCloseOnSelect}
					locale={"ru"}
					{...rest}
				/>
			</div>
		);
	},
);

/*Будет какая то задача с календарем где надо будет исключать все даты до
 сегодняшнего дня, вот пропс кот надо будет добавить */
/* // excludeDateIntervals={[
        //   {
        //     start: new Date('2000-01-01'),
        //     end: subDays(new Date(), 1),
        //   },
        // ]}
*/
