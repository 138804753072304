import { useRequestsShowQuery } from '@/state/api'
import { ChangeEvent, FC } from 'react'
import { useParams } from 'react-router'
import styles from './files-header.module.scss'
import { FileHeaderProps } from './files-header.types'
import { clearSnacks } from '@/eventbus/events/snackbar'
import { checkFileSizeAndNotify } from '@utils/checkFileSizeAndNotify'

export const FilesHeader = ({ addFile }: FileHeaderProps): JSX.Element => {
  const { requestId } = useParams()
  const { data: requestData } = useRequestsShowQuery({
    requestId: Number(requestId),
  })

  const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearSnacks()
    if (!e.target.files) return
    if (e.target.files) {
      const files: File[] = []
      //чтобы можно было выбрать сразу несколько файлов
      for (let i = 0; i < e.target.files.length; i++) {
        let fileSize = 0
        const file = e.target.files[i]
        fileSize += file.size / 1024 / 1024
        if (!checkFileSizeAndNotify(fileSize, `Размер одного файла не должен превышать 50 Мб`, 50)) {
          e.target.value = ''
          return
        }
        files.push(file)
      }
      const filesSize = files.reduce((acc, current) => acc + current.size / 1024 / 1024, 0)

      if (!checkFileSizeAndNotify(filesSize, `Превышен максимальный размер добавляемых файлов за один раз (100 МБ)`)) {
        e.target.value = ''
        return
      }

      const attachments = new FormData()
      for (const file of files) {
        attachments.append('attachments[]', file)
      }
      addFile(attachments, Number(requestId))
      e.target.value = ''
    }
  }

  return (
    <div className={styles.files_header}>
      <label
        style={{ fontSize: '14px' }}
        className={`${styles.files_header_btn} ${!requestData?.data?.abilities?.update ? styles.files_header_btn_disabled : ''}`}>
        <input onChange={handlerChange} type="file" multiple disabled={!requestData?.data?.abilities?.update} />
        <span className={styles.files_header_btn_content}>
          <i className={'icon-add-file'} /> Добавить файл
        </span>
      </label>
    </div>
  )
}
