import { ReactNode } from 'react'
import styles from './snack-control-container.module.scss'
type SnackControlContainerProps = {
  children?: ReactNode
}
export const SnackControlContainer = ({
  children,
}: SnackControlContainerProps) => {
  return <div className={styles.container}>{children}</div>
}
