import { RequestCardHeaderContainer, Title, Typography } from "@components/common";
import type { RequestCardHeaderProps } from "@components/request-card/request-card.types";

import { Tooltip } from "@common/tooltip";

import { RequestCardHeaderInfoItem } from "@common/request-card";
import styles from "../request-card.module.scss";
import { RequestInfoBlock } from "@constants/request";
import { useNavigate } from "react-router-dom";
import { FEED_EDIT_REQUEST, FEED_DUPLICATION_OF_REQUEST } from "@constants/routes";
import { shortFio } from "@/utils/short-fio";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { useEffect } from "react";
import cn from "classnames";
import { RequestCardActionButton } from "@/components/common/request-card/request-card-actions";
import { nanoid } from "@reduxjs/toolkit";
import { DuplicateActions } from "@/components/duplicate-actions";
import { addSnack } from "@/eventbus/events/snackbar";
import { useRequestsDuplicationForInstallmentMutation } from "@/state/api";

export const RequestCardHeader = ({ onHandleSetPage, title, requestId, card, requestStatus }: Partial<RequestCardHeaderProps>) => {
	const navigate = useNavigate();

	const { handleSetRequestFullcodeId } = useFullCodeRequest();

	const lineHeight = "18px";

	const checkSubStringInStateName = card?.state_name?.includes("Отказ");

	let cardStateName = card?.state_name;
	let cardStateNameSubString: string | undefined = undefined;

	if (checkSubStringInStateName) {
		cardStateName = card?.state_name?.substring(0, 5);
		cardStateNameSubString = card?.state_name?.substring(6);
	}

	const [duplicateRequestForInstallment, { isError: isDuplicateRequestForInstallmentError }] =
		useRequestsDuplicationForInstallmentMutation();

	const handleDuplicateForInstallment = () => {
		duplicateRequestForInstallment({ requestId: card?.id ?? 0 });
	};

	useEffect(() => {
		if (isDuplicateRequestForInstallmentError) {
			addSnack({
				type: "error",
				eventName: "duplicate-request-error",
				text: `Не удалось дублировать заявку для рассрочки ${card?.fullcode ? `#${card?.fullcode}` : ""}`,
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleDuplicateForInstallment(),
			});
		}
	}, [isDuplicateRequestForInstallmentError]);

	return (
		<RequestCardHeaderContainer
			requestStatus={requestStatus}
			card={card}
			headerTitleContent={
				<>
					<Title className={cn(styles["request-card-title"])} as={"h3"} weight={1}>
						{title}
					</Title>
					{<i className={"icon icon-cheveron-right"} />}
				</>
			}
			headerInfoContent={
				<>
					<div className={styles["request-card-status-item"]}>
						{card?.state_name !== null && (
							<RequestCardHeaderInfoItem className={styles.header}>
								<Typography
									className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
									as={"caption"}
									decoration={"none"}
									tag={5}
								>
									{RequestInfoBlock.status}
								</Typography>

								<div
									style={{
										display: "flex",
										flexDirection: "column",
										marginRight: "-12px",
									}}
								>
									<Title as={"h6"} style={{ lineHeight }}>
										{cardStateName}
									</Title>

									{checkSubStringInStateName && (
										<Title
											as={"h6"}
											style={{
												lineHeight: checkSubStringInStateName ? lineHeight : undefined,
											}}
											className={styles["state-name"]}
										>
											{cardStateNameSubString}
										</Title>
									)}

									{card?.wait_until && (
										<Title as={"h6"} style={{ lineHeight }} className={styles["state-name"]}>
											{card?.wait_until}
										</Title>
									)}
								</div>
								<Tooltip
									anchorId={"request-card-comments-btn"}
									key={nanoid()}
									text={`Изм. ${card?.state_changed}` || ""}
									show={!!card?.state_changed}
									tooltipOffset={[0, 7]}
									placement={"bottom"}
								>
									<i className={"icon icon-history icon-low"} style={{ marginLeft: "6px", color: "#737373" }} />
								</Tooltip>
							</RequestCardHeaderInfoItem>
						)}
					</div>
					<div className={styles["creation-date"]}>
						{card?.dispatcher?.fio !== null && (
							<RequestCardHeaderInfoItem className={styles.header}>
								<Typography
									className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
									decoration={"none"}
									as={"caption"}
									weight={2}
									tag={5}
								>
									{RequestInfoBlock.requestCreated}
								</Typography>
								<Title as={"h6"} className={`${styles["request-card-text-dispatcher"]}`}>
									{shortFio(card?.dispatcher?.fio)}
								</Title>
							</RequestCardHeaderInfoItem>
						)}

						{card?.date_add !== null && (
							<RequestCardHeaderInfoItem className={styles.header}>
								<Typography
									className={`${styles["request-card-text"]} ${styles["request-card-text--no-wrap"]}`}
									decoration={"none"}
									as={"caption"}
									weight={2}
									tag={5}
								>
									{RequestInfoBlock.creationDate}
								</Typography>
								<Title as={"h6"}>
									{card?.date_add
										? card?.date_add.length === 10
											? card?.date_add
											: card?.date_add.substring(0, 10)
										: ""}
								</Title>
							</RequestCardHeaderInfoItem>
						)}
					</div>
				</>
			}
			headerActionButtons={
				<>
					<RequestCardActionButton
						icon={"icon-edit-1"}
						buttonType={"secondaryUncolored"}
						disabled={!card?.id || !card.abilities?.update}
						handleClick={() => {
							handleSetRequestFullcodeId(String(card?.fullcode));
							navigate(FEED_EDIT_REQUEST(String(card?.id), requestId));
							onHandleSetPage?.();
						}}
						tooltipAnchorId={"request-card-edit-btn"}
						tooltipText={"Редактировать"}
					/>
					<DuplicateActions
						canDuplicate={card?.abilities?.dublication}
						canDuplicateForInstallment={card?.abilities?.installment?.duplicate_for_installment}
						onDuplicate={() => {
							handleSetRequestFullcodeId("");
							navigate(FEED_DUPLICATION_OF_REQUEST(String(card?.id), String(requestId)));
							onHandleSetPage?.();
						}}
						onDuplicateForInstallment={() => handleDuplicateForInstallment()}
					/>
					{/* <RequestCardActionButton
            icon={'icon-copy'}
            disabled={!card?.id || !card.abilities?.dublication}
            buttonType={'secondaryUncolored'}
            handleClick={() => {
              handleSetRequestFullcodeId('')
              navigate(FEED_DUPLICATION_OF_REQUEST(String(card?.id), String(requestId)))
              onHandleSetPage && onHandleSetPage()
            }}
            tooltipAnchorId={'request-card-duplicate-btn'}
            tooltipText={'Дублировать заявку'}
          /> */}
				</>
			}
		/>
	);
};
