export type FindItemTypes<Item> = {
  id: number
  arr: Item[]
  filterChildsOnly?: boolean
}

export const isObjectInMap = <Item extends { [key: string]: any }>(
  map: Map<Item, Item>,
  id: number,
  title: string,
) => {
  let found = false

  map.forEach((value) => {
    if (value?.id === id && value?.title === title) {
      found = true
    }
  })

  return found
}
export const deleteObjectIfInMap = <Item extends { [key: string]: any }>(
  map: Map<Item, Item>,
  id: number,
  title: string,
) => {
  map.forEach((value) => {
    if (value?.id === id && value?.title === title) {
      map.delete(value)
    }
  })
}

export const getFlatArray = <Item extends { [key: string]: any }>(
  items: Item[],
): Item[] => {
  let children: Item[] = []

  return items
    .map((m) => {
      if (m?.items && m?.items?.length) {
        children = [...children, ...m?.items]
      }
      return m
    })
    .concat(children.length ? getFlatArray(children) : children)
}

export const findItem = <Item extends { [key: string]: any }>({
  id,
  arr,
  filterChildsOnly,
}: FindItemTypes<Item>) => {
  return filterChildsOnly
    ? getFlatArray<Item>(arr)
        //.filter((item) => !Object.hasOwn(item, 'items'))
        .filter((item) => item?.id === id)
    : getFlatArray<Item>(arr).filter((item) => item?.id === id)
}
