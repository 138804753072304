import { PhoneActionsDropdown } from "@/components";
import { REQUEST_PAGE_BY_REQUEST_ID } from "@/constants/routes";
import { useComponentVisible } from "@/hooks";
import type { RequestResource } from "@/state/api/coreApi";
import { useUserProfileInfo } from "@/state/selector";
import { clearReklamaAndObjectText, clearText } from "@/utils/clear-text";
import { countMasters } from "@/utils/count-masters";
import { newWindowOpen } from "@/utils/new-window";
import { shortFio } from "@/utils/short-fio";
import { InfoCardContainer } from "@common/info-card-container";
import { InfoCardContent } from "@common/info-card-container/info-card-content";
import InfoCardItem, { InfoCardItemContent, InfoCardItemLink } from "@common/info-card-item";
import { RequestCardFinanceDropdown } from "@common/request-card/request-card-content";
import { ContentFrame, Title, Typography } from "@components/common";
import { StatusLabel } from "@components/modals/common-forms/status-label";
import { getStatusLabelType } from "@components/modals/common-forms/status-label/api/status-label";
import { OLD_CMS_URL } from "@constants/env";
import { RequestInfoBlock } from "@constants/request";
import classNames from "classnames";
import cn from "classnames";
import type React from "react";
import type { FC } from "react";
import { useNavigate } from "react-router";
import MastersPopup from "../request-card/content/masters-popup/masters-popup";
import MastersPopupItem from "../request-card/content/masters-popup/masters-popup-item";
import styles from "./info-card.module.scss";

export const InfoCard: FC<{ info: RequestResource }> = ({ info }): React.ReactNode => {
	const phones = [info?.phone, info?.phone2, info?.phone3];
	const navigate = useNavigate();

	const [ref, open, setOpen] = useComponentVisible(undefined, undefined, undefined, ["button", "icon"]);

	const profile = useUserProfileInfo();

	const isRequestInBlackList = info?.type_id === 2;

	const pointRequestCode = info?.point_request_link && /request_code=R(\d+)/.exec(info?.point_request_link);

	const formatDateCallVisit = (val: string) => {
		const splittedVal = val.split(" ");

		return `${splittedVal[0]} в ${splittedVal[1]}`;
	};

	return (
		<InfoCardContainer>
			<div className={styles["info-card-header"]}>
				<Title as={"h4"}>Информация</Title>
				{isRequestInBlackList && (
					<ContentFrame className={styles["black-list-label"]} bgColor={"var(--validation-error-main)"}>
						<Typography className={styles["request-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
							Черный список
						</Typography>
					</ContentFrame>
				)}
			</div>
			<InfoCardContent>
				<InfoCardItem>
					<Title as={"h5"}>Общая</Title>
					{info.dispatcher?.fio !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.requestCreated}
							</Typography>
							<Typography className={styles["request-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{shortFio(info?.dispatcher?.fio)}
							</Typography>
						</InfoCardItemContent>
					)}
					{info.date_add !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.creationDate}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{info.date_add}
							</Typography>
						</InfoCardItemContent>
					)}
					{info?.work !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.typeOfWork}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{clearReklamaAndObjectText(info?.work?.path)}
							</Typography>
						</InfoCardItemContent>
					)}
					{info?.obj !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.object}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{clearText(info?.obj)}
							</Typography>
						</InfoCardItemContent>
					)}
					{info?.is_guarantee && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.hasGuarantee}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								По гарантии
							</Typography>
						</InfoCardItemContent>
					)}
					{info?.duplicated_of && Array.isArray(info?.duplicated_of) && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.dublicateBefore}
							</Typography>
							<div>
								{info?.duplicated_of?.map(({ id, fullcode }) => (
									<Typography
										key={id}
										className={styles["text-value-interactive"]}
										as={"paragraph"}
										decoration={"none"}
										tag={4}
										onClick={() => {
											info?.duplicates ? navigate(REQUEST_PAGE_BY_REQUEST_ID(String(id))) : {};
										}}
									>
										{fullcode}
									</Typography>
								))}
							</div>
						</InfoCardItemContent>
					)}
					{info?.duplicates && !!info?.duplicates?.length && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.dublicateFrom}
							</Typography>
							<div className={styles.dublicates}>
								{info?.duplicates?.map(({ id, fullcode }) => (
									<Typography
										key={id}
										className={styles["text-value-interactive"] as string}
										as={"paragraph"}
										decoration={"none"}
										tag={4}
										onClick={() => {
											info?.duplicates ? navigate(REQUEST_PAGE_BY_REQUEST_ID(String(id))) : {};
										}}
									>
										{fullcode}
									</Typography>
								))}
							</div>
						</InfoCardItemContent>
					)}
					{info?.state_name !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.requestStatus}
							</Typography>
							<div
								className={cn(styles["status-label-info"], {
									[styles["status-label-info-column"]]: !!info?.wait_until,
								})}
							>
								<div className={styles["status-label"]}>
									<StatusLabel type={getStatusLabelType(info.state_id).type}>
										{getStatusLabelType(info.state_id).title}
									</StatusLabel>
									{info?.wait_until && (
										<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
											{`Ожидание до: ${info?.wait_until}`}
										</Typography>
									)}
								</div>
								{info?.state_changed !== null && (
									<Typography
										as={"paragraph"}
										decoration={"none"}
										tag={4}
										className={`${styles["info-card-span-value"]} ${styles["info-card-span-value--block"]}`}
									>
										(Изменен: {info?.state_changed})
									</Typography>
								)}
							</div>
							{/*<Typography
                className={styles['info-card-text-value']}
                as={'paragraph'}
                decoration={'none'}
                tag={4}>
                {info?.state_name}
                {info?.state_changed !== null && (
                  <span
                    className={`${styles['info-card-span-value']} ${styles['info-card-span-value--block']}`}>
                    (Изменен: {info?.state_changed})
                  </span>
                )}
              </Typography>*/}
						</InfoCardItemContent>
					)}
					{(info?.tofirm !== null || info?.tomaster_summ !== null || info?.detal !== null) && !!profile?.available_menu?.fin && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.price}
							</Typography>
							<RequestCardFinanceDropdown isDefaultOpen card={info} />
						</InfoCardItemContent>
					)}
				</InfoCardItem>
				{info?.point_request_link && (
					<InfoCardItemContent>
						<Typography className={styles["request-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
							{RequestInfoBlock.pointDirection}
						</Typography>
						{pointRequestCode && (
							<div className={styles["info-card-point"]}>
								<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
									В мастерскую
								</Typography>
								<a
									target={"_blank"}
									className={styles["text-value-interactive"]}
									href={`${info?.point_request_link}`}
									rel="noreferrer"
								>
									{pointRequestCode[1]}
								</a>
							</div>
						)}
					</InfoCardItemContent>
				)}
				{info.ur_org !== null && (
					<InfoCardItemContent>
						<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
							{RequestInfoBlock.responsible}
						</Typography>
						<Typography
							className={info?.responsible?.fio ? styles["info-card-text-value"] : styles["info-card-text"]}
							as={"paragraph"}
							decoration={"none"}
							tag={4}
						>
							{info?.responsible?.fio ?? "—"}
						</Typography>
					</InfoCardItemContent>
				)}
				<InfoCardItem>
					<Title as={"h5"}>О клиенте</Title>
					{(info.ur_org || info.ur_fio) && info.ur_id ? (
						<>
							<InfoCardItemContent>
								<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
									{RequestInfoBlock.organization}
								</Typography>
								<InfoCardItemLink href={"#"}>
									<Typography
										className={styles["text-value-interactive"]}
										as={"paragraph"}
										decoration={"none"}
										tag={3}
										onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/companies/detail/${info?.ur_id}`, "_parent")}
									>
										{clearText(info?.ur_org)}
									</Typography>
								</InfoCardItemLink>
							</InfoCardItemContent>
							<InfoCardItemContent>
								<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
									{RequestInfoBlock.inn}
								</Typography>
								<Typography
									className={info?.ur_inn ? styles["info-card-text-value"] : styles["info-card-text"]}
									as={"paragraph"}
									decoration={"none"}
									tag={4}
								>
									{info?.ur_inn ?? "—"}
								</Typography>
							</InfoCardItemContent>
							<InfoCardItemContent>
								<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
									{RequestInfoBlock.contactPerson}
								</Typography>
								<Typography
									className={styles["text-value-interactive"]}
									as={"paragraph"}
									decoration={"none"}
									tag={3}
									onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/contacts/detail/${info?.client_id}`, "_parent")}
								>
									{info?.ur_fio}
								</Typography>
							</InfoCardItemContent>
						</>
					) : info?.client_fio !== null ? (
						<>
							<InfoCardItemContent>
								<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
									{RequestInfoBlock.client}
								</Typography>
								<Typography
									className={styles["text-value-interactive"]}
									as={"paragraph"}
									decoration={"none"}
									tag={3}
									onClick={() => newWindowOpen(`${OLD_CMS_URL}modules/contacts/detail/${info?.client_id}`, "_parent")}
								>
									{info?.client_fio}
								</Typography>
							</InfoCardItemContent>
						</>
					) : null}
					{phones.length && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.phones}
							</Typography>
							<div className={styles["info-card-link-container"]}>
								<PhoneActionsDropdown
									fullcodeId={info?.fullcode}
									requestId={Number(info?.id)}
									phones={phones}
									clientId={info?.client_id || 0}
								/>
							</div>
						</InfoCardItemContent>
					)}
					{info?.full_address !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.address}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{clearText(info?.full_address)}
							</Typography>
						</InfoCardItemContent>
					)}
					<InfoCardItemContent>
						<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
							{RequestInfoBlock.metroStation}
						</Typography>
						<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
							{clearText(info?.metro_path) || "–"}
						</Typography>
					</InfoCardItemContent>
					{info?.reklama_name !== null && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.advertising}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{clearReklamaAndObjectText(info?.reklama_name?.split("-").join("->"))}
							</Typography>
						</InfoCardItemContent>
					)}
				</InfoCardItem>
				<InfoCardItem>
					<Title as={"h5"}>О мастере</Title>
					<InfoCardItemContent>
						<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
							{RequestInfoBlock.master}
						</Typography>
						{!info?.auto_selection && info?.masters?.length === 0 && (
							<Typography
								style={{ cursor: "unset" }}
								className={`${styles["text-value-manual"]} ${
									info.state_id === 1 ? styles["text-value-manual-disabled"] : ""
								}`}
								as={"paragraph"}
								decoration={"none"}
								tag={4}
							>
								Необходим ручной подбор мастера
							</Typography>
						)}
						{info?.auto_selection && info?.masters?.length === 0 && (
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								Идёт определение мастера...
							</Typography>
						)}
						{!!info?.masters?.length && (
							<>
								{info?.approved_master?.fio ? (
									<MastersPopupItem
										requestId={info.id}
										fullcodeId={info.fullcode}
										showDelivery={false}
										master={info.approved_master}
										requestStatus={info.state_id}
									/>
								) : (
									<div className={styles["masters-container"]}>
										<Typography
											className={styles["text-value-interactive"]}
											as={"paragraph"}
											decoration={"none"}
											tag={4}
											onClick={() => setOpen(!open)}
										>
											Доставка в приложение мастерам {countMasters(info?.masters)}
											{open ? (
												<i
													onClick={() => {
														setOpen(!open);
													}}
													className={classNames(styles["cheveron-icon"], "icon icon-cheveron-up")}
												/>
											) : (
												<i
													onClick={() => {
														setOpen(!open);
													}}
													className={classNames(styles["cheveron-icon"], "icon icon-cheveron-down")}
												/>
											)}
										</Typography>
										{open && info?.masters && (
											<div className={styles["masters-popup-wrapper"]}>
												<MastersPopup
													showApproveMaster={!info?.approved_master?.fio}
													className={styles["masters-popup"]}
													ref={ref}
													masters={info.masters}
													requestId={info.id}
													fullcodeId={info.fullcode}
													requestStatus={info?.state_id}
												/>
											</div>
										)}
									</div>
								)}
							</>
						)}
					</InfoCardItemContent>
					{info?.date_call && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.callScheduled}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{info?.date_call ? `${formatDateCallVisit(info?.date_call)}` : ""}
								{info?.date_call && <span className={styles["info-card-span-value"]}>(МСК)</span>}
							</Typography>
						</InfoCardItemContent>
					)}
					{info?.date_visit && (
						<InfoCardItemContent>
							<Typography className={styles["info-card-text"]} as={"paragraph"} decoration={"none"} tag={4}>
								{RequestInfoBlock.visitScheduled}
							</Typography>
							<Typography className={styles["info-card-text-value"]} as={"paragraph"} decoration={"none"} tag={4}>
								{info?.date_visit ? `${formatDateCallVisit(info?.date_visit)}` : ""}
								{info?.date_visit && <span className={styles["info-card-span-value"]}>(МСК)</span>}
							</Typography>
						</InfoCardItemContent>
					)}
				</InfoCardItem>
			</InfoCardContent>
		</InfoCardContainer>
	);
};
