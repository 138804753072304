import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import {
  AdvertisingFeedResource,
  useReklamasFeedQuery,
  UserFeedResource,
  WorkFeedResource,
} from '@/state/api'
import { addSnack, clearSnacks } from '@/eventbus/events/snackbar'
import { CreateRequestNames } from '@constants/create-request'
import { MultiSelect } from '@common/multi-select'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'
import { setRecursivelyParentId } from '@utils/set-recursively-parent-id'

export const RequestModalAdsInput = <T extends WorkFeedResource[]>({
  input,
  meta,
  // label,
  labelSide = 'left',
  acceptPathAsTitle,
  inputChildren,
  disabled,
  ...props
}: RequestModalTextInputProps<T>) => {
  const [initialValue, setInitialValue] = useState<
    AdvertisingFeedResource[] | undefined
  >(undefined)

  const {
    data: serverData,
    isLoading,
    isError,
    error,
    refetch,
  } = useReklamasFeedQuery()

  const handleRefetchAds = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-ads-error',
        text: `Не удалось загрузить список реклам.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchAds(),
      })
    }
  }, [isError])

  const adsId = useField(input?.name)?.input?.value

  useEffect(() => {
    if (adsId && serverData?.data && !isLoading) {
      setInitialValue(
        findItem<AdvertisingFeedResource>({
          id: adsId,
          arr: serverData?.data.map((item) =>
            setRecursivelyParentId<WorkFeedResource>(item, item.id),
          ),
        }),
      )
    }
  }, [adsId, isLoading])

  return (
    <>
      <MultiSelect<AdvertisingFeedResource>
        acceptPathAsTitle={acceptPathAsTitle}
        isDataLoading={isLoading}
        width={'full-w'}
        disabled={disabled}
        isDataNested={true}
        // inputLabelText={`${label}: `}
        dropdownMenuSelectionVariant={'single-select'}
        data={
          Array.isArray(serverData?.data)
            ? serverData?.data.map((item) =>
                setRecursivelyParentId(item, item.id),
              )
            : []
        }
        inputId={CreateRequestNames.ads}
        onChange={(items) => {
          items?.length
            ? input.onChange(items[0].id)
            : input.onChange(undefined)
        }}
        externalInputProps={input}
        externalInputMetaProps={meta}
        initialSelectedItems={initialValue}
      />
    </>
  )
}
