import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { LoadingSpinner } from "@/components/button/loading-spinner";
import { UISizes } from "@/global-types/mods.types";
import type { Company } from "@/state/api";
import classNames from "classnames";
import Downshift from "downshift";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-final-form";
import { InputErrorContainer } from "../input-error-container";
import { Label } from "../multi-select/common/label";
import styles from "./organisations-input.module.scss";

export const OrganisationsInputRequest = ({
	fetch,
	isLoading,
	options,
	className,
	name,
	label,
	error,
	disabled,
	placeholder = "",

	keyForInput,
	dropdownClassname,
	input,
	meta,
}: any) => {
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [hoveredTooltip, setHoveredTooltip] = useState<string>("");

	const form = useForm();
	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else if (options) {
			setIsOpenMenu(true);
		}
	}, [options]);

	const handleSetHoveredTooltip = (val: string) => {
		setHoveredTooltip(val);
	};
	const handleSelection = (selection: Company) => {
		form.change("urId", selection.id);
		input?.onChange?.(selection.title);
		form.change("urInn", selection.inn);
		setIsOpenMenu(false);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			input.value && fetch && fetch();
		}
	};

	return (
		<InputErrorContainer anchorId={hoveredTooltip} errorMessage={meta.error}>
			<Downshift
				isOpen={isOpenMenu}
				onInputValueChange={(e) => {
					form.change("urId", undefined);
					input?.onChange?.(e);
					if (!e) {
						input?.onChange?.("");
					}
				}}
				inputValue={input.value}
				onSelect={(selection) => handleSelection(selection)}
				itemToString={(item) => (item?.data && keyForInput ? item.data[keyForInput] : item)}
				stateReducer={(_, changes) => {
					if (changes.type === Downshift.stateChangeTypes.blurInput) {
						setIsOpenMenu(false);
						return {
							...changes,
							inputValue: input.value,
							isOpen: false,
						};
					}
					if (changes.type === Downshift.stateChangeTypes.mouseUp) {
						setIsOpenMenu(false);
						return {
							...changes,
							inputValue: input.value,
							isOpen: false,
						};
					}
					return changes;
				}}
			>
				{({ getInputProps, getItemProps, getMenuProps, highlightedIndex, getRootProps, isOpen, selectHighlightedItem }) => (
					<div className={classNames(styles["address-container"], className)}>
						<div {...getRootProps({}, { suppressRefError: true })}>
							<div className={styles["address-input-container"]}>
								{label && <Label htmlFor={name || input?.name} labelText={label} />}
								<div className={styles["address-input-with-button"]}>
									<input
										onMouseEnter={() => handleSetHoveredTooltip("1")}
										onMouseLeave={() => handleSetHoveredTooltip("")}
										{...getInputProps({
											autoComplete: "nope",
											placeholder: placeholder,
											onKeyDown: (event) => {
												handleKeyDown(event);
												switch (event.key) {
													case "Tab": {
														if (isOpen) {
															event.preventDefault();
															options?.length && highlightedIndex !== null && selectHighlightedItem();
														}
														break;
													}
													default:
														break;
												}
											},
										})}
										name={name || input?.name}
										className={classNames(styles["address-input"], {
											[styles["address-input-filled"]]: !!input.value,
											[styles["address-input-error"]]:
												(!!meta.submitFailed && !!error) ||
												(!!meta.submitFailed && !!meta.error) ||
												(!!meta.submitFailed && !input.value),
										})}
										disabled={disabled}
									/>
									{!!input.value && (
										<Button
											btnType={"button"}
											className={styles["address-input-remove-button"]}
											type={ButtonTypes.secondaryUncolored}
											size={UISizes.medium}
											iconLeft={<i className={"icon icon-close"} />}
											onClick={() => {
												input?.onChange?.("");
												form.change("urId", undefined);
											}}
										/>
									)}
								</div>
							</div>
						</div>
						{isLoading && (
							<div {...getMenuProps()} className={classNames(styles["address-dropdown"], dropdownClassname)}>
								<LoadingSpinner />
							</div>
						)}
						{!isLoading && isOpen && options?.length === 0 && (
							<div {...getMenuProps()} className={classNames(styles["address-dropdown"], dropdownClassname)}>
								Не найдено совпадений
							</div>
						)}
						{!isLoading && isOpen && options?.length > 0 && (
							<div className={styles.wrapper}>
								<div {...getMenuProps()} className={classNames(styles["dropdown-container"], dropdownClassname)}>
									{options.map((item: Company, index: number) => (
										<div
											key={item.id}
											className={styles["list-item"]}
											{...getItemProps({
												key: item.id,
												index,
												item: item,
											})}
										>
											{item.title ?? "-"}
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				)}
			</Downshift>
		</InputErrorContainer>
	);
};
