import { useState } from 'react'


export const useButtonSwitch = (initialState: 0 | 1) => {

  const [activeButton, setActiveButton] = useState(initialState)

  const toggleActiveButton = () => {
    setActiveButton(activeButton === 0 ? 1 : 0)
  }

  return {activeButton, toggleActiveButton}
}
