import { Button, Icon, Panel } from '../../../index'
import styles from './panel-menu.module.scss'
import { ReactNode } from 'react'
import { IconsType } from '../../../types/icons-types'

type PanelMenuProps = {
  menuItemsSlot: ReactNode
}

type PanelMenuItemProps = {
  onClick: () => void
  title: string
  icon: keyof IconsType
  disabled?: boolean
}

export const PanelMenu = ({ menuItemsSlot = <></> }: PanelMenuProps) => {
  return (
    <Panel className={styles.container}>
      <Panel.Body>{menuItemsSlot}</Panel.Body>
    </Panel>
  )
}

PanelMenu.Item = ({ onClick, title, icon, disabled }: PanelMenuItemProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      size="m"
      variant="secondary-uncolored"
      width="full-w"
      justifyContent={'start'}
      iconPlacement={'left'}
      renderIcon={<Icon icon={icon} />}>
      {title}
    </Button>
  )
}
