import { SidebarMenuItemProps } from '@common/sidebar-menu/sidebar-menu.types'
import styles from '../sidebar-menu.module.scss'
import cn from 'classnames'

export const SidebarMenuItem = ({
  icon,
  text,
  onClick,
  isActive,
  isOpened,
}: Partial<SidebarMenuItemProps>) => {
  const sidebarMenuItemClasses = cn(styles['sidebar-menu_item'], {
    [styles['sidebar-menu_item__active']]: isActive,
    [styles['sidebar-menu_item__opened']]: isOpened,
  })

  return (
    <div
      aria-selected={isActive}
      className={sidebarMenuItemClasses}
      onClick={onClick}
    >
      {icon}
      {isOpened && text}
    </div>
  )
}
