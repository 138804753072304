import { Button, Icon } from "../../../shared";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { useEffect } from "react";

export const ToggleMenuButton = () => {
	const { phonerMenuService, phonerTreyService, phonerSecondLineService } = useGlobalPhonerState();
	const [menuState, send] = useActor(phonerMenuService);
	const [, sendPhonerTreyEvent] = useActor(phonerTreyService);
	const isMenuOpened = menuState.matches("menu opened");

	const [statePhonerSecondLine] = useActor(phonerSecondLineService);
	const hasIncomingCallAtCurrent = statePhonerSecondLine.matches("second call received");

	useEffect(() => {
		if (!hasIncomingCallAtCurrent) return;

		send("close menu");
		sendPhonerTreyEvent("close menu");
	}, [hasIncomingCallAtCurrent]);

	const handleToggleMenu = () => {
		if (isMenuOpened) {
			send("close menu");
			sendPhonerTreyEvent("close menu");
		} else {
			send("open menu");
			sendPhonerTreyEvent("open menu");
		}
	};

	return (
		<Button
			onClick={handleToggleMenu}
			size="m"
			variant="secondary"
			width="icon"
			disabled={hasIncomingCallAtCurrent}
			renderIcon={<Icon icon={isMenuOpened ? "icon-cheveron-down" : "icon-dots-vertical-rounded"} />}
		/>
	);
};
