import { Badge, ModalContainer, Title, Tooltip, Typography } from "@/components/common";
import Tag from "@/components/common/tag/tag";
import classNames from "classnames";
import styles from "./masters-popup.module.scss";

import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { REQEST_MASTER_CARD_BY_ID } from "@/constants/routes";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { addSnack } from "@/eventbus/events/snackbar";
import { useMasterCallMutation, useRequestsMasterRemoveMutation } from "@/state/api";
import { useRequestsMastersApproveMutation } from "@/state/api/coreApi";
import { UISizes } from "@global-types/mods.types";
import { nanoid } from "@reduxjs/toolkit";
import { shortFio } from "@utils/short-fio";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import type { IMastersPopupItem } from "./masters-popup.types";

import { PhonerEvents, type PhonerEventsT } from "@/phoner";
import { useProfileQuery } from "@/state/api";

import { INCLUDE_PHONER } from "@/constants/env";
import { convertPhone } from "@/utils/convert-phone";

const MastersPopupItem = ({ master, showDelivery = true, requestId, fullcodeId, requestStatus, showApproveMaster }: IMastersPopupItem) => {
	const navigate = useNavigate();
	const params = useParams();
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [removeMaster] = useRequestsMasterRemoveMutation();
	const { data: user } = useProfileQuery();
	const [callToMaster, { isError: isCallError, originalArgs, isSuccess: isCallSuccess }] = useMasterCallMutation();

	const handleRemove = () => {
		if (requestId && master.id) {
			removeMaster({ requestId, masterId: master.id });
		}
		setConfirmOpen(false);
	};

	const isPhonerAvailable = user?.data?.inner_phone !== "999" && user?.data?.permissions?.CAN_USE_PHONER && INCLUDE_PHONER;

	const handleCallToMaster = (phone: string) => {
		if (!isPhonerAvailable) {
			if (phone) {
				callToMaster({
					masterId: Number(master.id),
					requestMasterCallCall: {
						phone,
					},
				});
			}
			return;
		}

		const event = new CustomEvent<PhonerEventsT.MakeCallEventT>(PhonerEvents.MAKE_CALL_EVENT_NAME, {
			detail: {
				entityType: "request",
				app: "basa",
				entityId: requestId || 0,
				calledPhone: phone || "",
				calledType: "master",
				calledId: master.id || 0,
				callerId: user?.data?.id || 0,
				callerPhone: user?.data?.inner_phone || "",
				callerType: "user",
			},
		});
		window.dispatchEvent(event);
	};

	const [approveMaster, { isLoading: isApproveMasterLoading }] = useRequestsMastersApproveMutation();

	const { handleSetRequestFullcodeId } = useFullCodeRequest();

	const handleApproveMasterOnRequest = () => {
		if (requestId && master.id) {
			approveMaster({
				requestId,
				masterId: master.id,
				requestsMastersApprove: {
					requestId,
					masterId: master.id,
				},
			});
		}
		setConfirmOpen(false);
	};
	const masterPhoneFormat = convertPhone(master.phone_mobile as string);
	const masterPhoneFormatAddon = convertPhone(master.phone_mobile_addon as string);

	useEffect(() => {
		if (isCallError) {
			addSnack({
				type: "error",
				eventName: "fetch-requests-error",
				text: "Не удалось позвонить.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleCallToMaster(originalArgs?.requestMasterCallCall?.phone ?? ""),
			});
		}
		if (isCallSuccess) {
			addSnack({
				type: "default",
				eventName: "fetch-requests-error",
				text: "Ожидайте, звонок скоро начнется.",
			});
		}
	}, [isCallError, isCallSuccess]);

	return (
		<div>
			<li className={styles["masters-popup-item"]}>
				<div className={styles["masters-popup-item-top"]}>
					<div className={styles["fio-status-container"]}>
						<div
							className={classNames(
								styles["masters-popup-status"],
								{ [styles["masters-popup-status-online"]]: !!master.is_online },
								{
									[styles["masters-popup-status-offline"]]: master.is_online === false,
								},
								{
									[styles["masters-popup-status-vacation"]]: master.is_online === null,
								},
							)}
						/>
						<Tooltip
							show
							anchorId={`master-fio_${master.id}`}
							key={nanoid()}
							text={master?.fio}
							tooltipOffset={[0, 15]}
							placement={"top"}
						>
							<Typography
								className={styles["masters-popup-name"]}
								as={"paragraph"}
								decoration={"none"}
								tag={4}
								onClick={() => navigate(REQEST_MASTER_CARD_BY_ID(String(master.id)))}
							>
								{shortFio(master?.fio)}
							</Typography>
						</Tooltip>
					</div>
					<div className={styles["masters-popup-action-btns"]}>
						<div className={styles.labels}>
							{master?.vassal && <Badge className={styles["master-badge"]}>Главный</Badge>}
							{showDelivery && (
								<Tag
									type={master.appointed?.sended_to_app ? "success" : "inactive"}
									className={styles["masters-popup-tag"]}
								>
									<Typography as={"caption"} decoration={"none"} tag={5}>
										{master.appointed?.sended_to_app ? "Доставлено" : "Доставка"}
									</Typography>
								</Tag>
							)}
						</div>
						<div className={styles.btns}>
							{showApproveMaster && (
								<Tooltip
									show
									anchorId={`deliver_${master.id}`}
									key={nanoid()}
									text={"Передать"}
									tooltipOffset={[0, 15]}
									placement={"bottom"}
								>
									<Button
										isLoading={isApproveMasterLoading}
										size={UISizes.medium}
										iconLeft={<i className="icon icon-user-check" />}
										onClick={() => {
											handleApproveMasterOnRequest();
											handleSetRequestFullcodeId(String(fullcodeId));
										}}
										type={ButtonTypes.primary}
									/>
								</Tooltip>
							)}
							<Tooltip
								show
								anchorId={`send_${master.id}`}
								key={nanoid()}
								text={"Отправить сообщение"}
								tooltipOffset={[0, 15]}
								placement={"bottom"}
							>
								<button
									onClick={() => {
										navigate(
											params.requestId
												? `message/${master.id}/${fullcodeId}`
												: master.id && !requestId && fullcodeId
													? `message/${master.id}/${fullcodeId}`
													: `/requests/feed/${requestId}/message/${master.id}/${fullcodeId}`,
										);
									}}
									className={styles["masters-popup-button"]}
								>
									<i className="icon icon-chat" />
								</button>
							</Tooltip>
							{requestStatus === 0 && (
								<Tooltip
									show
									anchorId={`remove_${master.id}`}
									key={nanoid()}
									text={"Убрать мастера"}
									tooltipOffset={[0, 15]}
									placement={"bottom"}
								>
									<button
										id={`remove_${master.id}`}
										onClick={() => setConfirmOpen(true)}
										className={classNames(styles["masters-popup-button"], styles["masters-popup-button_remove"])}
									>
										<i className="icon icon-delete" />
									</button>
								</Tooltip>
							)}
						</div>
					</div>
				</div>
				<div className={styles["masters-popup-item-bottom"]}>
					{!!master.phone_mobile && (
						<Typography
							onClick={() => {
								handleCallToMaster(String(master.phone_mobile));
							}}
							className={styles["masters-popup-phone"]}
							as={"caption"}
							decoration={"none"}
							tag={5}
						>
							{masterPhoneFormat}
						</Typography>
					)}

					{!!master.phone_mobile_addon && (
						<Typography
							onClick={() => {
								handleCallToMaster(String(master.phone_mobile_addon));
							}}
							className={styles["masters-popup-phone"]}
							as={"caption"}
							decoration={"none"}
							tag={5}
						>
							{masterPhoneFormatAddon}
						</Typography>
					)}
				</div>
				{confirmOpen && (
					<ModalContainer className={styles["confirm-modal"]} showBlackout>
						<Title as="h5">{`Вы хотите убрать мастера ${master.fio} из списка доставки?`}</Title>
						<div className={styles["confirm-modal-buttons"]}>
							<Button
								className="master-popup-button"
								onClick={() => setConfirmOpen(false)}
								type={ButtonTypes.secondaryUncolored}
							>
								Оставить
							</Button>
							<Button
								className="master-popup-button"
								onClick={() => {
									handleRemove();
								}}
								type={ButtonTypes.dangerUncolored}
							>
								Убрать
							</Button>
						</div>
					</ModalContainer>
				)}
			</li>
			{master?.vassal && (
				<MastersPopupItem
					requestStatus={requestStatus}
					master={master?.vassal}
					fullcodeId={fullcodeId}
					requestId={requestId}
					showDelivery={showDelivery}
					showApproveMaster={showApproveMaster}
				/>
			)}
		</div>
	);
};

export default MastersPopupItem;
