import { addSnack } from '@/eventbus/events/snackbar'

export const checkFileSizeAndNotify = (filesSize: number, message: string, maxSizeInMB = 100) => {
  //maxSizeInMB = 100mb maximum volume of selected files at one time

  if (filesSize >= maxSizeInMB) {
    addSnack({
      type: 'error',
      eventName: 'add-files-error',
      text: message,
      withAction: false,
    })
    return false
  }
  return true
}
