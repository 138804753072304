import { ModalContainer } from '../../components'
import styles from './info-modal.module.scss'
import { Button, Typography } from '../..'
import { FC } from 'react'

interface IMasterTabInfoModal {
  isOpen?: boolean
  onClose: () => void
}

export const MasterTabInfoModal: FC<IMasterTabInfoModal> = ({ isOpen, onClose }) => {
  return (
    <ModalContainer className={styles.wrapper} showBlackout closeOnBlackoutClick>
      <h1 className={styles.title}>Внимание</h1>
      <Typography as={'paragraph'} decoration="none" tag={3}>
        <>
          Это вкладка "База. Линия" является главной, из нее устанавливается связь с телефонией и проходят звонки. Она отвечает за
          соединение с телефонией и через нее проходят все звонки. Управление фонером может осуществляться через другие вкладки.
          <br />
          Пожалуйста, не закрывайте данную вкладку до конца рабочего процесса. Также, просьба обратить внимание, что на главной вкладке
          отличается заголовок и иконка. Это сделано для того, чтобы можно было отличить её от других вкладок.
        </>
      </Typography>
      <Button onClick={onClose} size="m" variant="primary" width="full-w">
        Понятно
      </Button>
    </ModalContainer>
  )
}
