import { useQuery } from "react-query";

export interface ProfileResponse {
  data: {
    phone: number;
    password: string;
  };
}

export const useProfile = () => {
  const results = document?.cookie?.match(/session_id=(.+?)(;|$)/);
  const cookie = results?.[1];

  const getProfile = async (signal?: AbortSignal) => {
    const response = await fetch(
      `${import.meta.env.REACT_APP_PHONER_BACK}/api/v1/profile`,
      {
        headers: { Authorization: `Bearer ${cookie}` },
        signal: signal,
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result = await response.json();
    return result;
  };

  const queryRes = useQuery<ProfileResponse, Error>({
    queryFn: ({ signal }) => getProfile(signal),
    queryKey: ["profile"],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });

  return { ...queryRes };
};
