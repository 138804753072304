import React, { FC } from "react";
import { getInputState } from "@components/form/utils/getFieldState";
import { Input, InputMessage } from "@/components";
import { BasicInputValues } from "@global-types/utils-type";
import { LabelContainer, Typography } from "@common/index";
import { RequestModalTextInputProps } from "@components/modals/common-forms/request-modal-input/request-modal-text-input.types";
import styles from "../push-message-modal.module.scss";
import { useForm } from "react-final-form";

export const PushMessageInput = <T extends BasicInputValues>({
	input,
	meta,
	label,
	showMaxlengthValue,
	...props
}: RequestModalTextInputProps<T>) => {

	const form = useForm();
	const submitByEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			form.submit();
		}
	};
	return (
		<div className={styles["push-message-container"]}>
			<InputMessage
				onKeyDown={submitByEnter}
				state={getInputState(meta, input)}
				showChildren={true}
				placeholder={"Ваше сообщение"}
				{...input}
				{...props}
			/>
			{showMaxlengthValue && (
				<Typography as={"caption"} decoration={"none"} tag={5}>
					{input.value && typeof input.value === "string"
						? `Максимум ${input.value?.length} / 140 символов`
						: `Максимум 140 символов`}{" "}
				</Typography>
			)}
		</div>
	);
};
