import { LabelProps } from './label.types'
import styles from './label.module.scss'
import { Typography } from '@/components/common'

export const Label = ({ labelText = '', htmlFor = '' }: LabelProps) => {
  return (
    <label className={styles.label} htmlFor={htmlFor}>
      {labelText && (
        <Typography as={'paragraph'} tag={4} decoration={'none'}>
          {labelText}
        </Typography>
      )}
    </label>
  )
}
