import { isEqual } from "lodash";
import { apiSlice as api } from "./api";
import { InstallmentScheduleServerData, InstallmentServerData } from "@/constants/installment";

export const addTagTypes = [
	"BlackListInfo",
	"Announcement",
	"Attachments",
	"Reklamas Filter",
	"Request Attachments",
	"Flash Message",
	"Calls",
	"Call Types",
	"Call",
	"Companies",
	"Districts",
	"Previous Requests",
	"Failures",
	"Dispatcher Complaints",
	"Master Complaints",
	"Requests Complaints",
	"Login",
	"Master Complaints",
	"Masters",
	"Master Message Categories",
	"Master Selection",
	"Subway stations",
	"Office Types",
	"Partners",
	"Pause",
	"Profile",
	"Quality Controls",
	"Call Records",
	"Advertising Types",
	"Filters",
	"Message Master",
	"Request Masters",
	"Requests",
	"Planer",
	"Request Comments",
	"Request Logs",
	"Request Presets",
	"Request Installment",
	"Sale Types",
	"Statistics",
	"Addresses",
	"Users",
	"Works",
	"Callback Count",
	"Request show",
	"Request Location",
] as const;
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			announcementsShowOnModule: build.query<AnnouncementsShowOnModuleApiResponse, AnnouncementsShowOnModuleApiArg>({
				query: (queryArg) => ({
					url: `/api/announcements/module/${queryArg.moduleId}`,
				}),
				providesTags: ["Announcement"],
			}),
			announcementsRead: build.mutation<AnnouncementsReadApiResponse, AnnouncementsReadApiArg>({
				query: (queryArg) => ({
					url: `/api/announcements/module/${queryArg.moduleId}/read`,
					method: "POST",
					body: queryArg.requestsAnnouncementRead,
				}),
				invalidatesTags: ["Announcement"],
			}),
			attachmentsDestroy: build.mutation<AttachmentsDestroyApiResponse, AttachmentsDestroyApiArg>({
				query: (queryArg) => ({
					url: `/api/attachments/${queryArg.attachmentId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Attachments", "Request Attachments"],
			}),
			attachmentsImage: build.query<AttachmentsImageApiResponse, AttachmentsImageApiArg>({
				query: (queryArg) => ({
					url: `/api/attachments/image`,
					params: { path: queryArg.path },
				}),
				providesTags: ["Attachments", "Request Attachments"],
			}),
			attachmentsDownload: build.mutation<AttachmentsDownloadApiResponse, AttachmentsDownloadApiArg>({
				query: (queryArg) => ({
					url: `/api/attachments/${queryArg.attachmentId}/download`,
					method: "GET",
					responseHandler: async (response) => response.blob().toString(),
				}),
				invalidatesTags: ["Attachments", "Request Attachments"],
			}),
			basemailFlashGetFlash: build.query<BasemailFlashGetFlashApiResponse, BasemailFlashGetFlashApiArg>({
				query: () => ({ url: `/api/basemail/flash` }),
				providesTags: ["Flash Message"],
			}),
			callsStore: build.mutation<CallsStoreApiResponse, CallsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/calls`,
					method: "POST",
					body: queryArg.requestsCallsStore,
				}),
				invalidatesTags: ["Calls"],
			}),
			callTypesFeed: build.query<CallTypesFeedApiResponse, CallTypesFeedApiArg>({
				query: () => ({ url: `/api/call_types/feed` }),
				providesTags: ["Call Types"],
			}),
			clientCall: build.mutation<ClientCallApiResponse, ClientCallApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/client_call`,
					method: "POST",
					body: queryArg.requestMasterCallCall,
				}),
				invalidatesTags: ["Call"],
			}),
			missedClientCall: build.mutation<ClientCallApiResponse, ClientMissedCallApiArg>({
				query: (queryArg) => ({
					url: `/api/calls/make`,
					method: "POST",
					body: queryArg.requestMasterCallCall,
				}),
				invalidatesTags: ["Call"],
			}),
			companiesFeed: build.query<CompaniesFeedApiResponse, CompaniesFeedApiArg>({
				query: (queryArg) => ({
					url: `/api/companies/feed`,
					params: { title: queryArg.title, inn: queryArg.inn },
				}),
				providesTags: ["Companies"],
				keepUnusedDataFor: 0,
			}),
			dispetcherCard: build.query<DispetcherCardApiResponse, DispetcherCardApiArg>({
				query: (queryArg) => ({
					// url: `/dispatchers/${queryArg.dispatcherCardId}/show`
					url: `/api/users/${queryArg.dispatcherId}/dispatcher/card`,
				}),
				keepUnusedDataFor: 600,
				providesTags: ["Masters"],
			}),
			dispatchersDispatcherComplaintsFeed: build.query<
				DispatchersDispatcherComplaintsFeedApiResponse,
				DispatchersDispatcherComplaintsFeedApiArg
			>({
				query: () => ({ url: `/api/dispatchers/dispatcher_complaints/feed` }),
				providesTags: ["Dispatcher Complaints"],
			}),
			districtsFeed: build.query<DistrictsFeedApiResponse, DistrictsFeedApiArg>({
				query: () => ({ url: `/api/districts/feed` }),
				providesTags: ["Districts"],
				keepUnusedDataFor: 120,
			}),
			ssoLogin: build.mutation<SsoLoginApiResponse, SsoLoginApiArg>({
				query: (queryArg) => ({
					url: `/api/sso/login`,
					method: "POST",
					body: queryArg.requestsAuthLogin,
				}),
				invalidatesTags: ["Login"],
			}),
			masterCall: build.mutation<MasterCallApiResponse, MasterCallApiArg>({
				query: (queryArg) => ({
					url: `/api/masters/${queryArg.masterId}/call`,
					method: "POST",
					body: queryArg.requestMasterCallCall,
				}),
				invalidatesTags: ["Call"],
			}),
			mastersMasterComplaintsFeed: build.query<MastersMasterComplaintsFeedApiResponse, MastersMasterComplaintsFeedApiArg>({
				query: () => ({ url: `/api/masters/master_complaints/feed` }),
				providesTags: ["Master Complaints"],
			}),
			mastersFeed: build.query<MastersFeedApiResponse, MastersFeedApiArg>({
				query: () => ({ url: `/api/masters/feed` }),
				providesTags: ["Masters"],
				keepUnusedDataFor: 120,
			}),
			mastersMessagesCategoriesFeed: build.query<MastersMessagesCategoriesFeedApiResponse, MastersMessagesCategoriesFeedApiArg>({
				query: () => ({ url: `/api/masters/master_messages_categories/feed` }),
				providesTags: ["Master Message Categories"],
			}),
			masterSelectionsIndex: build.query<MasterSelectionsIndexApiResponse, MasterSelectionsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/master_selections`,
					params: {
						type: queryArg.type,
						request_id: queryArg.requestId,
						work_id: queryArg.workId,
						district_id: queryArg.districtId,
					},
				}),
				providesTags: ["Master Selection"],
			}),
			masterSelectionsWorksIndex: build.query<MasterSelectionsWorksIndexApiResponse, MasterSelectionsWorksIndexApiArg>({
				query: () => ({ url: `/api/master_selections/works` }),
				providesTags: ["Master Selection"],
			}),
			masterSelectionsWorksMastersIndex: build.query<
				MasterSelectionsWorksMastersIndexApiResponse,
				MasterSelectionsWorksMastersIndexApiArg
			>({
				query: (queryArg) => ({
					url: `/api/master_selections/works/${queryArg.workId}/masters`,
				}),
				providesTags: ["Master Selection"],
			}),
			masterCard: build.query<MasterCardApiResponse, MasterCardApiArg>({
				query: (queryArg) => ({
					url: `/api/masters/${queryArg.masterId}`,
				}),
				providesTags: ["Masters"],
			}),
			metroStationsFeed: build.query<MetroStationsFeedApiResponse, MetroStationsFeedApiArg>({
				query: () => ({ url: `/api/metro_stations/feed` }),
				providesTags: ["Subway stations"],
				keepUnusedDataFor: 600,
			}),
			officesFeed: build.query<OfficesFeedApiResponse, OfficesFeedApiArg>({
				query: () => ({ url: `/api/offices/feed` }),
				providesTags: ["Office Types"],
			}),
			partnersFeed: build.query<PartnersFeedApiResponse, PartnersFeedApiArg>({
				query: () => ({ url: `/api/partners/feed` }),
				providesTags: ["Partners"],
			}),
			pauseSet: build.mutation<PauseSetApiResponse, PauseSetApiArg>({
				query: () => ({ url: `/api/pause/set`, method: "POST" }),
				invalidatesTags: ["Pause"],
			}),
			pauseUnset: build.mutation<PauseUnsetApiResponse, PauseUnsetApiArg>({
				query: () => ({ url: `/api/pause/unset`, method: "POST" }),
				invalidatesTags: ["Pause"],
			}),
			pauseStatus: build.query<PauseStatusApiResponse, PauseStatusApiArg>({
				query: () => ({ url: `/api/pause/status` }),
				providesTags: ["Pause"],
			}),
			profile: build.query<ProfileApiResponse, ProfileApiArg>({
				query: () => ({ url: `/api/profile` }),
				providesTags: ["Profile"],
			}),
			profileLogout: build.mutation<ProfileLogoutApiResponse, ProfileLogoutApiArg>({
				query: () => ({ url: `/api/profile/logout`, method: "POST" }),
				invalidatesTags: ["Profile"],
			}),
			qualityControlsPresetsDenyReasons: build.query<
				QualityControlsPresetsDenyReasonsApiResponse,
				QualityControlsPresetsDenyReasonsApiArg
			>({
				query: () => ({ url: `/api/quality_controls/presets/deny_reasons` }),
				providesTags: ["Quality Controls"],
			}),
			qualityControlsPresetsMasterGrades: build.query<
				QualityControlsPresetsMasterGradesApiResponse,
				QualityControlsPresetsMasterGradesApiArg
			>({
				query: () => ({ url: `/api/quality_controls/presets/master_grades` }),
				providesTags: ["Quality Controls"],
			}),
			qualityControlsQuestionGroupsIndex: build.query<
				QualityControlsQuestionGroupsIndexApiResponse,
				QualityControlsQuestionGroupsIndexApiArg
			>({
				query: (queryArg) => ({
					url: `/api/quality_controls/question_groups`,
					params: { type: queryArg.type },
				}),
				providesTags: ["Quality Controls"],
			}),
			qualityControlsViolationsFeed: build.query<QualityControlsViolationsFeedApiResponse, QualityControlsViolationsFeedApiArg>({
				query: (queryArg) => ({
					url: `/api/quality_controls/violations/feed`,
					params: { type: queryArg.type },
				}),
				providesTags: ["Quality Controls"],
			}),
			recordsDownload: build.query<RecordsDownloadApiResponse, RecordsDownloadApiArg>({
				query: (queryArg) => ({
					url: `/api/records/${queryArg.cdrId}/download`,
				}),
				providesTags: ["Call Records"],
			}),
			recordsListen: build.query<RecordsListenApiResponse, RecordsListenApiArg>({
				query: (queryArg) => ({
					url: `/api/records/${queryArg.cdrId}/listen`,
				}),
				providesTags: ["Call Records"],
			}),
			reklamasFeed: build.query<ReklamasFeedApiResponse, ReklamasFeedApiArg>({
				query: () => ({ url: `/api/reklamas/feed` }),
				providesTags: ["Advertising Types"],
				keepUnusedDataFor: 300,
			}),
			reklamasFilterByName: build.query<ReklamasFeedApiResponse, ReklamasFilterApiArg>({
				query: (queryArg) => ({
					url: `/api/reklamas/?name=${queryArg?.name}`,
				}),
				providesTags: ["Reklamas Filter"],
			}),
			requestsComplaintsIndex: build.query<RequestsComplaintsIndexApiResponse, RequestsComplaintsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/complaints`,
				}),
				providesTags: ["Dispatcher Complaints", "Master Complaints"],
			}),
			requestsDispComplaintsIndex: build.query<RequestsDispComplaintsIndexApiResponse, RequestsDispComplaintsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/dispatchers/${queryArg.dispatcherId}/complaints`,
				}),
				providesTags: ["Dispatcher Complaints"],
			}),
			requestsDispComplaintsStore: build.mutation<RequestsDispComplaintsStoreApiResponse, RequestsDispComplaintsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/dispatchers/${queryArg.dispatcherId}/complaints`,
					method: "POST",
					body: queryArg.requestsRequestDispatchersComplaintsStore,
				}),
				invalidatesTags: ["Dispatcher Complaints", "Requests Complaints"],
			}),
			updateDispComplaintsStore: build.mutation<RequestsDispComplaintsStoreApiResponse, UpdateDispComplaintsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/dispatchers/${queryArg.dispatcherId}/complaints/${queryArg.complaintId}`,
					method: "PUT",
					body: queryArg.requestsRequestDispatchersComplaintsStore,
				}),
				invalidatesTags: ["Dispatcher Complaints", "Requests Complaints"],
			}),
			deleteDispComplaintsStore: build.mutation<RequestsDispComplaintsStoreApiResponse, DeleteDispComplaintsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/dispatchers/${queryArg.dispatcherId}/complaints/${queryArg.complaintId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Dispatcher Complaints", "Requests Complaints"],
			}),
			filtersFeed: build.query<FiltersFeedApiResponse, FiltersFeedApiArg>({
				query: () => ({ url: `/api/filters/feed` }),
				providesTags: ["Filters"],
			}),
			filtersStore: build.mutation<FiltersStoreApiResponse, FiltersStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/filters`,
					method: "POST",
					body: queryArg.requestsFilterStore,
				}),
				invalidatesTags: ["Filters"],
			}),
			filtersShow: build.query<FiltersShowApiResponse, FiltersShowApiArg>({
				query: (queryArg) => ({ url: `/api/filters/${queryArg.filterId}` }),
				providesTags: ["Filters"],
			}),
			filtersUpdate: build.mutation<FiltersUpdateApiResponse, FiltersUpdateApiArg>({
				query: (queryArg) => ({
					url: `/api/filters/${queryArg.filterId}`,
					method: "PUT",
					body: queryArg.requestsFilterUpdate,
				}),
				invalidatesTags: ["Filters"],
			}),
			filtersDestroy: build.mutation<FiltersDestroyApiResponse, FiltersDestroyApiArg>({
				query: (queryArg) => ({
					url: `/api/filters/${queryArg.filterId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Filters"],
			}),
			requestsMastersComplaintsIndex: build.query<RequestsMastersComplaintsIndexApiResponse, RequestsMastersComplaintsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/complaints`,
				}),
				providesTags: ["Requests Complaints"],
			}),
			requestsMastersComplaintsStore: build.mutation<RequestsMastersComplaintsStoreApiResponse, RequestsMastersComplaintsStoreApiArg>(
				{
					query: (queryArg) => ({
						url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/complaints`,
						method: "POST",
						body: queryArg.requestsRequestMastersComplaintsStore,
					}),
					invalidatesTags: ["Master Complaints", "Requests Complaints"],
				},
			),
			updateMastersComplaintsStore: build.mutation<RequestsMastersComplaintsStoreApiResponse, UpdateMastersComplaintsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/complaints/${queryArg.complaintId}`,
					method: "PUT",
					body: queryArg.requestsRequestMastersComplaintsStore,
				}),
				invalidatesTags: ["Master Complaints", "Requests Complaints"],
			}),
			deleteMastersComplaintsStore: build.mutation<RequestsMastersComplaintsStoreApiResponse, DeleteMastersComplaintsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/complaints/${queryArg.complaintId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Master Complaints", "Requests Complaints"],
			}),
			sendMasterMessage: build.mutation<SendMasterMessageApiResponse, SendMasterMessageApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/send_master_message`,
					method: "POST",
					body: queryArg.masterMessageRequestStore,
				}),
				invalidatesTags: ["Message Master"],
			}),
			requestsMastersStore: build.mutation<RequestsMastersStoreApiResponse, RequestsMastersStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters`,
					method: "POST",
					body: queryArg.requestsRequestMasterStore,
				}),
				invalidatesTags: ["Request show"],
			}),
			requestsSendSMSMessageToClientStore: build.mutation<SendSmsMessageToClientApiResponse, SendSmsMessageToClientApiArg>({
				query: (queryArg) => ({
					url: `/api/send_sms`,
					method: "POST",
					params: {
						phone: queryArg.phone,
					},
					body: queryArg.smsMessageToClientRequestStore,
				}),
			}),
			requestsMastersApprove: build.mutation<RequestsMastersApproveApiResponse, RequestsMastersApproveApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}/approve`,
					method: "PUT",
					body: queryArg.requestsMastersApprove,
				}),
				invalidatesTags: ["Request show", "Requests"],
			}),
			requestsMasterRemove: build.mutation<RequestsMastersStoreApiResponse, RequestsMasterRemoveApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/masters/${queryArg.masterId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Request show", "Requests"],
			}),
			requestsPlannerIndex: build.query<RequestsPlannerIndexApiResponse, RequestsPlannerIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/planner`,
				}),
				providesTags: ["Planer"],
				keepUnusedDataFor: 180,
			}),
			requestsQualityControlsIndex: build.query<RequestsQualityControlsIndexApiResponse, RequestsQualityControlsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/quality_controls`,
				}),
				providesTags: ["Quality Controls"],
			}),
			requestsQualityControlsStore: build.mutation<RequestsQualityControlsStoreApiResponse, RequestsQualityControlsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/quality_controls`,
					method: "POST",
					body: queryArg.requestsRequestQualityControlStore,
				}),
				invalidatesTags: ["Quality Controls", "Requests", "Request Comments"],
			}),
			requestsAttachmentsIndex: build.query<RequestsAttachmentsIndexApiResponse, RequestsAttachmentsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/attachments`,
				}),
				providesTags: ["Request Attachments"],
				keepUnusedDataFor: 120,
			}),
			requestsAttachmentsStore: build.mutation<RequestsAttachmentsStoreApiResponse, RequestsAttachmentsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/attachments`,
					method: "POST",
					body: queryArg.requestsAttachmentStore,
				}),
				invalidatesTags: ["Request Attachments"],
			}),
			requestsCommentsIndex: build.query<RequestsCommentsIndexApiResponse, RequestsCommentsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/comments`,
				}),
				providesTags: ["Request Comments"],
			}),
			requestsCommentsStore: build.mutation<RequestsCommentsStoreApiResponse, RequestsCommentsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/comments`,
					method: "POST",
					body: queryArg.requestsRequestCommentStore,
				}),
				invalidatesTags: ["Request Comments", "Requests"],
			}),
			requestsCommentsUpdate: build.mutation<RequestsCommentsUpdateApiResponse, RequestsCommentsUpdateApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/comments/${queryArg.commentId}`,
					method: "PUT",
					body: queryArg.requestsRequestCommentUpdate,
				}),
				invalidatesTags: ["Request Comments", "Requests"],
			}),
			requestsCommentsDestroy: build.mutation<RequestsCommentsDestroyApiResponse, RequestsCommentsDestroyApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/comments/${queryArg.commentId}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Request Comments", "Requests"],
			}),
			requestsLogsIndex: build.query<RequestsLogsIndexApiResponse, RequestsLogsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/logs`,
				}),
				providesTags: ["Request Logs"],
			}),
			requestsPresetsDateRangeTypes: build.query<RequestsPresetsDateRangeTypesApiResponse, RequestsPresetsDateRangeTypesApiArg>({
				query: () => ({ url: `/api/requests/presets/date_range_types` }),
				providesTags: ["Request Presets"],
				keepUnusedDataFor: 600,
			}),
			requestsPresetsStates: build.query<RequestsPresetsStatesApiResponse, RequestsPresetsStatesApiArg>({
				query: () => ({ url: `/api/requests/presets/states` }),
				providesTags: ["Request Presets"],
				keepUnusedDataFor: 600,
			}),
			requestsPresetsStatesForCreate: build.query<RequestsPresetsStatesApiResponse, RequestsPresetsStatesApiArg>({
				query: () => ({ url: `/api/requests/presets/states_for_create` }),
				providesTags: ["Request Presets"],
			}),
			requestsPresetsTypes: build.query<RequestsPresetsTypesApiResponse, RequestsPresetsTypesApiArg>({
				query: () => ({ url: `/api/requests/presets/types` }),
				providesTags: ["Request Presets"],
			}),
			requestsPresetsPartnerTypes: build.query<RequestsPresetsPartnerTypesApiResponse, RequestsPresetsPartnerTypesApiArg>({
				query: () => ({ url: `/api/requests/presets/partner_types` }),
				providesTags: ["Request Presets"],
			}),
			requestsPresetsContractTypes: build.query<RequestsPresetsContractTypesApiResponse, RequestsPresetsContractTypesApiArg>({
				query: () => ({ url: `/api/requests/presets/contract_types` }),
				providesTags: ["Request Presets"],
			}),
			requestsPresetsCashlessTypes: build.query<RequestsPresetsCashlessTypesApiResponse, RequestsPresetsCashlessTypesApiArg>({
				query: () => ({ url: `/api/requests/presets/cashless_states` }),
				providesTags: ["Request Presets"],
			}),
			requestsPresetsInstallmentTypes: build.query<RequestsPresetsInstallmentTypesApiResponse, RequestsPresetsInstallmentTypesApiArg>(
				{
					query: () => ({ url: `/api/requests/presets/installments` }),
					providesTags: ["Request Presets"],
				},
			),
			requestsRecordsIndex: build.query<RequestsRecordsIndexApiResponse, RequestsRecordsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/records`,
				}),
				providesTags: ["Call Records"],
				keepUnusedDataFor: 600,
			}),
			clientPreviousRequests: build.query<RequestsIndexApiResponse, RequestsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/?phone=%2B${queryArg?.phone?.replace("_", "")}&per_page=${queryArg?.perPage}`,
				}),
				providesTags: ["Previous Requests"],
			}),
			clientContactRequests: build.query<RequestsIndexContactApiResponse, RequestsIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/contact?phone=%2B${queryArg?.phone?.replace("_", "")}&is_ur=${queryArg.is_ur}`,
				}),
				providesTags: ["Previous Requests"],
			}),
			requestsIndex: build.query<RequestsIndexApiResponse, RequestsIndexApiArg>({
				query: (queryArg) => {
					return {
						url: `/api/requests/index`,
						method: "POST",
						body: {
							page: queryArg.page,
							per_page: queryArg.perPage,
							id: queryArg.id,
							date_range_type: queryArg.dateRangeType,
							date_from: queryArg.dateFrom,
							date_to: queryArg.dateTo,
							code_number: queryArg.codeNumber ? queryArg.codeNumber : undefined,
							phone: queryArg?.phone?.replace(/_/gi, ""),
							work: queryArg.work ?? [],
							state: queryArg.state ?? [],
							district: queryArg.district ?? [],
							street: queryArg.street ? String(queryArg.street) : undefined,
							entrance: queryArg.entrance ? String(queryArg.entrance) : undefined,
							house: queryArg.house ? String(queryArg.house) : undefined,
							korp: queryArg.korp ? String(queryArg.korp) : undefined,
							flat: queryArg.flat ? String(queryArg.flat) : undefined,
							organization: queryArg.organization,
							responsible: queryArg.responsible ?? undefined,
							announced: queryArg.announced ?? [],
							dispatcher: queryArg.dispatcher ?? [],
							master: queryArg.master ?? [],
							advertising: queryArg.advertising ?? [],
							type: typeof queryArg.type === "number" ? [queryArg.type] : [],
							partner_type: queryArg.partnerType ? [queryArg.partnerType] : [],
							partner: queryArg.partner ? [queryArg.partner] : [],
							contract_type: queryArg.contractType ? [queryArg.contractType] : undefined,
							cashless_state: typeof queryArg.cashlessState === "number" ? [queryArg.cashlessState] : undefined,
							is_legal_entity: queryArg.isLegalEntity,
							not_transferred_to_master: queryArg.notTransferredToMaster,
							is_dublicate: queryArg.isDublicate,
							has_comments: queryArg.hasComments,
							has_attachment: queryArg.hasAttachment,
							is_controlled_dispatcher: queryArg.isControlledDispatcher,
							has_order_executions: queryArg.hasOrderExecutions,
							is_credit: queryArg.isCredit,
							is_cashless_payment: queryArg.beznalByAccount,
							is_online_payment: queryArg.beznalOnline,
							has_no_master: queryArg.hasNoMaster,
							client_waiting: queryArg.clientWaiting,
							is_temp: queryArg.isTemp,
							beznal_num: queryArg.beznal_num,
							beznal: queryArg.beznal,
							inn: queryArg.ur_inn,
							is_installment: queryArg.is_installment,
							installments_state: queryArg.is_installment ? queryArg.installments_state : undefined,
						},
					};
				},
				providesTags: ["Requests"],
				keepUnusedDataFor: 600,
				serializeQueryArgs: ({ endpointName }) => {
					return endpointName;
				},

				forceRefetch({ currentArg, previousArg }) {
					return !isEqual(currentArg, previousArg);
				},
			}),
			requestsStore: build.mutation<RequestsStoreApiResponse, RequestsStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests`,
					method: "POST",
					body: queryArg.requestsRequestStore,
				}),
				invalidatesTags: ["Requests"],
			}),
			requestsDuplication: build.mutation<RequestsDuplicationApiResponse, RequestsDuplicationApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/duplication`,
					method: "POST",
					body: queryArg.requestsRequestDuplication,
				}),
				invalidatesTags: ["Requests"],
			}),
			requestsDuplicationForInstallment: build.mutation<
				RequestsDuplicationForInstallmentApiResponse,
				RequestsDuplicationForInstallmentApiArg
			>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/installment/duplication_for_installment`,
					method: "POST",
				}),
				invalidatesTags: (result, error) => (error ? [] : ["Requests"]),
			}),
			requestsChangeState: build.mutation<RequestsChangeStateApiResponse, RequestsChangeStateApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/change_state`,
					method: "PUT",
					body: queryArg.requestsRequestChangeState,
				}),
				invalidatesTags: ["Request show", "Requests"],
			}),
			requestsShow: build.query<RequestsShowApiResponse, RequestsShowApiArg>({
				query: (queryArg) => ({ url: `/api/requests/${queryArg.requestId}` }),
				providesTags: ["Request show"],
				keepUnusedDataFor: 0,
			}),
			requestsCommentsAllIndex: build.query<RequestsCommentsAllIndexApiResponse, RequestsCommentsAllIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/comments_all`,
				}),
				providesTags: ["Request Comments"],
			}),
			requestsUpdate: build.mutation<RequestsUpdateApiResponse, RequestsUpdateApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}`,
					method: "PUT",
					body: queryArg.updateRequest,
				}),
				invalidatesTags: ["Requests", "Request show"],
			}),
			requestsInstallment: build.query<RequestsInstallmentIndexApiResponse, RequestsInstallmentIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/installment`,
					invalidatesTags: ["Request Installment"],
				}),
				providesTags: ["Request Installment"],
				keepUnusedDataFor: 120,
			}),
			requestsChangeInstallment: build.mutation<any, any>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/installment`,
					method: queryArg.method,
					body: queryArg.values,
				}),
				invalidatesTags: ["Request Installment"],
			}),
			requestsInstallmentLogsJournal: build.query<RequestInstallmentLogsJournalResponse, RequestsInstallmentIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/installment/logs?per_page=1000`,
				}),
				providesTags: ["Request Installment"],
				keepUnusedDataFor: 120,
			}),
			requestsInstallmentPaymentHistory: build.query<RequestInstallmentLogsJournalResponse, RequestsInstallmentIndexApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/payment`,
				}),
				providesTags: ["Request Installment"],
				keepUnusedDataFor: 120,
			}),
			requestsInstallmentCreatePayment: build.mutation<any, any>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/payment`,
					method: "POST",
					body: queryArg.values,
				}),
				providesTags: ["Request Installment"],
				invalidatesTags: ["Request Installment"],
			}),
			requestsInstallmentDeletePayment: build.mutation<any, any>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.requestId}/payment/${queryArg.currentId}`,
					method: "DELETE",
				}),
				providesTags: ["Request Installment"],
				invalidatesTags: ["Request Installment"],
			}),
			saleTypesFeed: build.query<SaleTypesFeedApiResponse, SaleTypesFeedApiArg>({
				query: () => ({ url: `/api/sale/feed` }),
				providesTags: ["Sale Types"],
				keepUnusedDataFor: 600,
			}),
			statisticsIndex: build.query<StatisticsIndexApiResponse, StatisticsIndexApiArg>({
				query: () => ({ url: `/api/statistics` }),
				providesTags: ["Statistics"],
				keepUnusedDataFor: 30,
			}),
			deleteAnswerPhone: build.mutation<RequestsDeleteAnswerPhoneApiResponse, DeleteAnswerPhoneApiArg>({
				query: (queryArg) => ({
					url: `/api/statistics/answer_phone/${queryArg.cdr_id}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Statistics"],
			}),
			deleteMissingCall: build.mutation<RequestsDeleteMissingCallApiResponse, DeleteMissingCallApiArg>({
				query: (queryArg) => ({
					url: `/api/statistics/missing_calls/${queryArg.id}`,
					method: "DELETE",
				}),
				invalidatesTags: ["Statistics"],
			}),
			tootipsAddressesFeed: build.mutation<TootipsAddressesFeedApiResponse, TootipsAddressesFeedApiArg>({
				query: (queryArg) => ({
					url: `/api/tooltip/addresses/feed`,
					method: "POST",
					body: queryArg,
				}),
			}),
			streetsFeed: build.query<StreetsFeedApiResponse, StreetsFeedFeedApiArg>({
				query: (queryArg) => ({
					url: `/api/streets/feed`,
					method: "GET",
					params: {
						name: queryArg.name,
					},
				}),
				keepUnusedDataFor: 600,
			}),
			requestsBlackListStore: build.mutation<RequestsBlackListStoreApiResponse, RequestsBlackListStoreApiArg>({
				query: (queryArg) => ({
					url: `/api/requests/${queryArg.request_id}/blacklist`,
					method: "POST",
					params: {
						request_id: queryArg.request_id,
					},
					body: queryArg.requestsBlackListsStore,
				}),
				invalidatesTags: ["BlackListInfo"],
			}),
			requestsRemovePhoneFromBlackListStore: build.mutation<
				RequestsRemovePhoneFromABlackListStoreApiResponse,
				RequestsRemovePhoneFromBlackListStoreApiArg
			>({
				query: (queryArg) => ({
					url: `/api/blacklist/${queryArg.blacklist_id}`,
					method: "DELETE",
				}),
				invalidatesTags: ["BlackListInfo"],
			}),
			requestsBlackListInfo: build.query<RequestsBlackListApiResponse, RequestsBlackListApiArg>({
				query: (queryArg) => ({
					url: `/api/blacklist`,
					method: "GET",
					params: {
						phone: queryArg.phone,
					},
				}),
				providesTags: ["BlackListInfo"],
			}),
			usersFeed: build.query<UsersFeedApiResponse, UsersFeedApiArg>({
				query: () => ({ url: `/api/users/feed` }),
				providesTags: ["Users"],
				keepUnusedDataFor: 300,
			}),
			worksFeed: build.query<WorksFeedApiResponse, WorksFeedApiArg>({
				query: () => ({ url: `/api/works/feed` }),
				providesTags: ["Works"],
				keepUnusedDataFor: 600,
			}),
			callbackCount: build.query<CallbackCountApiResponse, CallbackCountApiArg>({
				query: () => ({ url: `/api/callbacks/opened_count` }),
				providesTags: ["Callback Count"],
			}),
		}),
		overrideExisting: false,
	});
export { injectedRtkApi as coreApi };

export type CallbackCountApiResponse = /** status 200 Ok */ {
	data?: CallbackCountResource;
};
export type CallbackCountApiArg = void;
export type CallbackCountResource = {
	count?: number;
};

export type AnnouncementsShowOnModuleApiResponse = /** status 200 Ok */ {
	data?: AnnouncementShowOnModuleResource[];
};
export type RequestsChangeStateRequest = {
	state_id?: number;
	state_comment?: string;
	wait_until?: string;
};
export type RequestsChangeStateApiResponse = /** status 204 Успешно выполнено */ {
	data?: RequestsChangeStateApiDataResponse;
};
export type RequestsChangeStateApiDataResponse = {
	available_states?: RequestStateResource[];
	id?: number;
	state_changed?: string;
	state_comment?: string;
	state_id?: number;
	state_name?: string;
};
export type RequestsChangeStateApiArg = {
	/** Request ID */
	requestId: number;
	/** Запрос на изменение статуса заявки */
	requestsRequestChangeState: RequestsChangeStateRequest;
};
export type AnnouncementsShowOnModuleApiArg = {
	/** ID модуля */
	moduleId: number;
};
export type AnnouncementsReadApiResponse = unknown;
export type AnnouncementsReadApiArg = {
	/** ID модуля */
	moduleId: number;
	/** Добавление флага прочитанности для Информирования от пользователя в модуле */
	requestsAnnouncementRead: RequestForTheInstallationOfReadingFlagsForApplications;
};
export type AttachmentsDestroyApiResponse = unknown;
export type AttachmentsDestroyApiArg = {
	/** Attachment ID */
	attachmentId: number;
};
export type AttachmentsImageApiResponse = unknown;
export type AttachmentsImageApiArg = {
	/** В path передается относительный путь к файлу вложения */
	path: string;
};
export type AttachmentsDownloadApiResponse = Blob;
export type AttachmentsDownloadApiArg = {
	/** Attachment ID */
	attachmentId: number;
};
export type BasemailFlashGetFlashApiResponse = /** status 200 Ok */ {
	data?: BasemailFlashResource;
};
export type BasemailFlashGetFlashApiArg = void;
export type CallsStoreApiResponse = /** status 200 Ok */ {
	data?: CallResource;
};
export type CallsStoreApiArg = {
	/** Запрос на создание звонка */
	requestsCallsStore: RequestsCallStore;
};
export type CallTypesFeedApiResponse = /** status 200 Ok */ {
	data?: CallTypeFeedResource[];
};
export type CallTypesFeedApiArg = void;
export type ClientCallApiResponse = /** status 204 No Content */ undefined;
export type ClientCallApiArg = {
	/** Request ID */
	requestId: number;
	/** Запрос на звонок клиенту */
	requestMasterCallCall: MasterCall;
};
export type ClientMissedCallApiArg = {
	/** Запрос на звонок клиенту */
	requestMasterCallCall: MasterCall;
};
export type CompaniesFeedApiResponse = /** status 200 Ok */ {
	data?: Company[];
};
export type CompaniesFeedApiArg = {
	/** Search query string */
	title?: string;
	inn?: string;
};
export type DistrictsFeedApiResponse = /** status 200 Ok */ {
	data?: District[];
};
export type DistrictsFeedApiArg = void;

// Карточка диспетчера

export interface DispetcherCardApiArg {
	dispatcherId: string | null;
}

export interface DispetcherCardApiResponse {
	data: DispetcherCardResponseData;
}

export interface DispetcherCardResponseData {
	id: number;
	fio: string;
	state: DispetcherCardResponseState[];
	complaint: DispetcherCardResponseComplaint[];
}

export interface DispetcherCardResponseComplaint {
	id: number;
	request_id: number;
	type: number;
	date: string;
	disp_fio: string;
	user_fio: string;
	text: string;
	request: {
		fullcode: string;
		id: number;
	};
	payed: string;
	payed_id: number;
	payed_fio: null;
	payed_date: null;
	summ: number;
}

export interface DispetcherCardResponseState {
	year: number;
	month: number;
	stats: {
		reject: number;
		done: number;
		worked: number;
		total: number;
		diag: number;
	};
}

export type SsoLoginApiResponse = /** status 204 Ok */ undefined;
export type SsoLoginApiArg = {
	/** Запрос на авторизацию по параметрам */
	requestsAuthLogin: RequestsAuthLogin;
};
export type MasterCallApiResponse = /** status 204 No Content */ undefined;
export type MasterCallApiArg = {
	/** Master ID */
	masterId: number;
	/** Запрос на звонок мастеру */
	requestMasterCallCall: MasterCall;
};
export type MastersMasterComplaintsFeedApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type MastersMasterComplaintsFeedApiArg = void;
export type MastersFeedApiResponse = /** status 200 Ok */ {
	data?: MasterFeedResource[];
};
export type MastersFeedApiArg = void;
/** Праматеры для получения карточки мастера */
export type MasterCardApiArg = {
	/** Master ID */
	masterId?: string;
};
/** Ответ с данными картчоки мастера */
export type MasterCardApiResponse = {
	data?: MasterCardData;
};
/** Данные картчоки мастера */
export type MasterCardData = {
	id?: number;
	fio?: string;
	birthday?: string | null;
	worked_at?: string;
	evaluations?: unknown;
	districts?: WorkResourcesRequestIndex[];
	active_day_off?: string | null;
	works?: WorksType[];
	master_limit?: {
		available?: boolean;
		day_limit?: string;
		fio?: string;
		fio_color?: string | null;
		id?: number;
		is_online?: boolean | null;
		tags?: {
			color: string;
			title: string;
		}[];
		total_limit?: string;
	};
	phone_mobile?: string;
	phone_mobile_addon?: string;
	code?: number | null;
	rating?: number;
	req_limit?: number;
	comment?: string;
	comment2?: string;
	deleted_at?: string | null;
	last_report_date?: string;
	next_report_date?: string;
	last_month_complaints?: ComplaintsType[];
	last_half_year_conditions?: ConditionsType[];
	requests_states?: ReqestsStatesType[];
	districts_states_today?: ReqestsStatesType[];
};
export type ComplaintsType = {
	request_id?: number;
	request: {
		id: number;
		fullcode: string;
		state_id: number;
		state_name: string;
		work_id: number;
		work_name: string;
	};
	text?: string;
	date?: string;
	type?: number;
	type_name?: string;
	summ?: number;
	payed?: boolean;
	user_id?: number;
	user_fio?: string;
	payed_id?: number;
	payed_fio?: string | null;
	payed_date?: string | null;
};
export type ConditionsType = {
	action?: string;
	condition_name?: string;
	created_at?: string;
	date_from?: string;
	date_to?: string | null;
	user_name?: string;
};
export type ReqestsStatesType = {
	count?: number;
	id?: number;
	name?: string;
};
export type WorksType = {
	id?: number;
	title?: string;
	path?: string;
	rating?: number;
	base?: number;
};
export type MastersMessagesCategoriesFeedApiResponse = /** status 200 Ok */ {
	data?: MasterMessageCategory[];
};
export type RequestCommentsAllIndexResource = {
	data?: {
		quality_controls?: QualityControlIndexResource[];
		comments?: RequestCommentIndexResource[];
	};
};
export type RequestsCommentsAllIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsCommentsAllIndexApiResponse = /** status 200 Ok */ RequestCommentsAllIndexResource;
export type MastersMessagesCategoriesFeedApiArg = void;
export type MasterSelectionsIndexApiResponse = /** status 200 Ok */ {
	data?: MasterSelectionResource[];
};
export type MasterSelectionsIndexApiArg = {
	/** Master selection type */
	type: MasterSelectionType;
	/** Request ID */
	requestId?: number;
	/** Work ID */
	workId?: number;
	/** District ID */
	districtId?: number;
};
export type MasterSelectionsWorksIndexApiResponse = /** status 200 Ok */ {
	data?: ResourcesMasterSelectionWorkIndex[];
};
export type MasterSelectionsWorksIndexApiArg = void;
export type MasterSelectionsWorksMastersIndexApiResponse = /** status 200 Ok */ {
	data?: MasterSelectionResource[];
};
export type MasterSelectionsWorksMastersIndexApiArg = {
	/** ID вида работ */
	workId: number;
};
export type MetroStationsFeedApiResponse = /** status 200 Ok */ {
	data?: MetroStationFeedResource[];
};
export type MetroStationsFeedApiArg = void;
export type OfficesFeedApiResponse = /** status 200 Ok */ {
	data?: OfficesFeedResource[];
};
export type OfficesFeedApiArg = void;
export type PartnersFeedApiResponse = /** status 200 Ok */ {
	data?: PartnerFeedResource[];
};
export type PartnersFeedApiArg = void;
export type PauseSetApiResponse = /** status 200 Ok */ {
	data?: PauseStatus;
};
export type PauseSetApiArg = void;
export type PauseUnsetApiResponse = /** status 200 Ok */ {
	data?: PauseStatus;
};
export type PauseUnsetApiArg = void;
export type PauseStatusApiResponse = /** status 200 Ok */ {
	data?: PauseStatus;
};
export type PauseStatusApiArg = void;
export type ProfileApiResponse = /** status 200 Ok */ {
	data?: ProfileResource;
};
export type ProfileApiArg = void;
export type ProfileLogoutApiResponse =
	/** status 200 Ok */
	| {
			data?: {
				redirect_url?: string;
			};
	  }
	| /** status 204 Успешно выполнено */ undefined;
export type ProfileLogoutApiArg = void;
export type QualityControlsPresetsDenyReasonsApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type QualityControlsPresetsDenyReasonsApiArg = void;
export type QualityControlsPresetsMasterGradesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type QualityControlsPresetsMasterGradesApiArg = void;
export type QualityControlsQuestionGroupsIndexApiResponse = /** status 200 Ok */ {
	data?: ResourceOfAGroupOfIssuesOnQualityAssessment[];
};
export type QualityControlsQuestionGroupsIndexApiArg = {
	/** Тип оценки качества */
	type?: TypeOfQualityAssessment;
};
export type QualityControlsViolationsFeedApiResponse = /** status 200 Ok */ {
	data?: ViolationFeedResource[];
};
export type QualityControlsViolationsFeedApiArg = {
	/** Тип нарушевшего (1 - Мастер, 2 - Диспетчер) */
	type: QualityControlPersonType;
};
export type RecordsDownloadApiResponse = unknown;
export type RecordsDownloadApiArg = {
	/** Record ID */
	cdrId: number;
};
export type RecordsListenApiResponse = unknown;
export type RecordsListenApiArg = {
	/** Record ID */
	cdrId: number;
};
export type ReklamasFeedApiResponse = /** status 200 Ok */ {
	data?: AdvertisingFeedResource[];
};
export type ReklamasFeedApiArg = void;
export type ReklamasFilterApiArg = {
	name: string;
};
export type RequestsDispComplaintsIndexApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestDispatcherComplaintShow[];
};
export type RequestsDispComplaintsIndexApiArg = {
	/** Request ID */
	requestId: number;
	/** Dispatcher ID */
	dispatcherId: number;
};
export type RequestsDeleteAnswerPhoneApiResponse = {
	data?: ResourcesStatisticIndex;
};
export type RequestsDeleteMissingCallApiResponse = {
	data?: ResourcesStatisticIndex;
};
export type RequestsDispComplaintsStoreApiResponse = /** status 201 Ok */ {
	data?: ResourcesRequestDispatcherComplaintShow;
};
export type RequestsDispComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Dispatcher ID */
	dispatcherId: number;
	/** Запрос на создание новой оценки диспетчера в заявке */
	requestsRequestDispatchersComplaintsStore: RequestCommentStoreRequest;
};
export type UpdateDispComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Dispatcher ID */
	dispatcherId: number;
	complaintId: number;
	/** Запрос на создание новой оценки диспетчера в заявке */
	requestsRequestDispatchersComplaintsStore: RequestCommentStoreRequest;
};
export type DeleteAnswerPhoneApiArg = { cdr_id: number };
export type DeleteMissingCallApiArg = { id: number };
export type DeleteDispComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Dispatcher ID */
	dispatcherId: number;
	complaintId: number;
	/** Запрос на создание новой оценки диспетчера в заявке */
};
export type FiltersFeedApiResponse = /** status 200 Ok */ {
	data?: FiltersIndexResource[];
};
export type FiltersFeedApiArg = void;
export type FiltersStoreApiResponse = /** status 200 Ok */ {
	id?: number;
	title?: string;
	data?: FiltersIndexResource;
};
export type FiltersStoreApiArg = {
	/** Запрос на создание нового фильтра */
	requestsFilterStore: RequestsFilterStore;
};
export type FiltersShowApiResponse = /** status 200 Ok */ FiltersIndexResource;
export type FiltersShowApiArg = {
	/** ID фильтра */
	filterId: number;
};
export type FiltersUpdateApiResponse = {
	id: number;
	title: string;
	data: any;
};
export type FiltersUpdateApiArg = {
	/** Filter ID */
	filterId: number;
	/** Запрос на редактирование фильтра */
	requestsFilterUpdate: RequestsFilterUpdate;
};
export type FiltersDestroyApiResponse = unknown;
export type FiltersDestroyApiArg = {
	/** Filter ID */
	filterId: number;
};
export type RequestsMastersComplaintsIndexApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestMasterComplaintShow[];
};
export type RequestsMastersComplaintsIndexApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
};
export type DispatchersDispatcherComplaintsFeedApiArg = void;
export type RequestsMastersComplaintsStoreApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestMasterComplaintShow;
};
export type RequestsMastersComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
	/** Запрос на создание новой оценки мастера в заявке */
	requestsRequestMastersComplaintsStore: RequestCommentStoreRequest2;
};
export type UpdateMastersComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
	complaintId: number;
	/** Запрос на создание новой оценки мастера в заявке */
	requestsRequestMastersComplaintsStore: RequestCommentStoreRequest2;
};
export type DeleteMastersComplaintsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
	complaintId: number;
	/** Запрос на создание новой оценки мастера в заявке */
};
export type SendMasterMessageApiResponse = /** status 200 Ok */ ResourcesRequestMasterMasterMessageShow;
export type SendMasterMessageApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
	/** Запрос на создание нового сообщения мастеру */
	masterMessageRequestStore: MasterMessageStore;
};

export type SendSmsMessageToClientApiResponse = undefined;
export type SendSmsMessageToClientApiArg = {
	phone: string;
	smsMessageToClientRequestStore: {
		text: string;
		phone: string;
		request_id: number;
	};
};
export type RequestsMastersStoreApiResponse = /** status 204 Успешно выполнено */ undefined;
export type RequestsMastersStoreApiArg = {
	/** ID заявки */
	requestId: number;
	/** Запрос на создание новой заявки */
	requestsRequestMasterStore: RequestsRequestMasterStore;
};
export type RequestsPlannerIndexApiResponse = /** status 200 Ok */ {
	data?: PlannerIndexResource[];
};
export type RequestsPlannerIndexApiArg = {
	/** ID заявки */
	requestId: number;
};
export type RequestsMasterRemoveApiArg = {
	/** ID заявки */
	requestId: number;
	masterId: number;
};
export type RequestsQualityControlsIndexApiResponse = /** status 200 Ok */ {
	data?: QualityControlIndexResource[];
};
export type RequestsQualityControlsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsQualityControlsStoreApiResponse = /** status 200 Ok */ {
	data?: QualityControlIndexResource;
};
export type RequestsQualityControlsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Запрос на создание новой заявки */
	requestsRequestQualityControlStore: QualityControlStoreRequest;
};
export type RequestsAttachmentsIndexApiResponse = /** status 200 Ok */ {
	data?: AttachmentIndexResource[];
};
export type RequestsAttachmentsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsInstallmentIndexApiResponse = /** status 200 Ok */ {
	data?: InfoAboutInstallment;
};

export interface Schedule {
	[InstallmentScheduleServerData.num]: number;
	[InstallmentScheduleServerData.date]: string;
	[InstallmentScheduleServerData.pay_date]: string;
	[InstallmentScheduleServerData.type]: string;
	[InstallmentScheduleServerData.period_status]: string;
	[InstallmentScheduleServerData.is_passed]: boolean;
	[InstallmentScheduleServerData.dept]: number;
	[InstallmentScheduleServerData.fee]: number;
}

export interface InfoInstallmentCreate {
	[InstallmentServerData.id]?: number;
	[InstallmentServerData.agreement]: string;
	[InstallmentServerData.date_begin]: string;
	[InstallmentServerData.contract_date]: string;
	[InstallmentServerData.period]: number;
}

export interface InfoAboutInstallment extends InfoInstallmentCreate {
	[InstallmentServerData.summ]: number;
	[InstallmentServerData.fee]: number;
	[InstallmentServerData.dept]: number;
	[InstallmentServerData.min_payment]: number;
	[InstallmentServerData.status]: "past" | "future" | "current";
	[InstallmentServerData.schedule]: Schedule[];
}

export type RequestsInstallmentIndexApiArg = {
	/** Request ID */
	requestId: string;
};

export interface RequestInstallmentLogsJournalResponse {
	data: DataInstallmentLogs[];
	links: LinksInstallmentLogs;
	meta: MetaInstallmentLogs;
}

export interface DataInstallmentLogs {
	id: number;
	type: string;
	text: string;
	created_at: string;
	user: UserInstallmentLogs;
}

export interface UserInstallmentLogs {
	id: number;
	fio: string;
}

export interface LinksInstallmentLogs {
	first: string;
	last: string;
	prev: string;
	next: string;
}

export interface MetaInstallmentLogs {
	current_page: number;
	from: number;
	last_page: number;
	links: LinkInstallmentLogs[];
	path: string;
	per_page: number;
	to: number;
	total: number;
}

export interface LinkInstallmentLogs {
	url: string;
	label: string;
	active: boolean;
}

export type RequestsAttachmentsStoreApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestIndex;
};
export type RequestsAttachmentsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Загрузка вложений к заявке */
	requestsAttachmentStore: FormData;
};
export type RequestsCommentsIndexApiResponse = /** status 200 Ok */ {
	data?: RequestCommentIndexResource[];
};
export type RequestsCommentsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsCommentsStoreApiResponse = /** status 200 Ok */ {
	data?: RequestComment;
};
export type RequestsCommentsStoreApiArg = {
	/** Request ID */
	requestId: number;
	/** Запрос на создание нового коментария заявки */
	requestsRequestCommentStore: RequestCommentStoreRequest3;
};
export type RequestsCommentsUpdateApiResponse = /** status 200 Ok */ {
	data?: RequestComment;
};
export type RequestsCommentsUpdateApiArg = {
	/** Request ID */
	requestId: number;
	/** Request Comment ID */
	commentId: number;
	/** Запрос на обновление коментария заявки */
	requestsRequestCommentUpdate: RequestCommentUpdateRequest;
};
export type RequestsCommentsDestroyApiResponse = unknown;
export type RequestsCommentsDestroyApiArg = {
	/** Request ID */
	requestId: number;
	/** Request Comment ID */
	commentId: number;
};
export type RequestsLogsIndexApiResponse = /** status 200 Ok */ {
	data?: RequestLogIndexResource[];
	links?: Links;
	meta?: Meta;
};
export type RequestsLogsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsPresetsDateRangeTypesApiResponse = /** status 200 Ok */ {
	data?: DateRangeType[];
};
export type RequestsPresetsDateRangeTypesApiArg = void;
export type RequestsPresetsStatesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsStatesApiArg = void;
export type RequestsPresetsTypesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsTypesApiArg = void;
export type RequestsPresetsPartnerTypesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsPartnerTypesApiArg = void;
export type RequestsPresetsContractTypesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsContractTypesApiArg = void;
export type RequestsPresetsCashlessTypesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsCashlessTypesApiArg = void;
export type RequestsPresetsInstallmentTypesApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type RequestsPresetsInstallmentTypesApiArg = void;
export type RequestsRecordsIndexApiResponse = /** status 200 Ok */ {
	data?: RequestRecordResource[] | RequestAutoanswerResource[];
};
export type RequestsRecordsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsIndexApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestIndex[];
	links?: Links;
	meta?: Meta;
};
export type RequestsIndexContactApiResponse = /** status 200 Ok */ {
	data?: RequestContactResource;
};
export type RequestContactResource = {
	company?: {
		ur_org?: string;
		ur_fio?: string;
	};
	emails?: {
		email: string;
		type: string;
		created_at: string;
	}[];
	phones?: PhonesElement[];
	requests?: {
		id: number;
		fullcode: string;
		type_id: number;
		fio: string;
		phone: string;
		phone2: null | string;
		phone3: null | string;
		adress: RequestAddressResource;
	}[];
};
export type PhonesElement = {
	id: null | number;
	phone: string;
	type: string;
	created_at: string;
};
export type RequestsIndexApiArg = {
	/** Номер страницы */
	page?: number;
	/** Кол-во элементов на странице */
	perPage?: number;
	/** Фильтрация по ID */
	id?: number[] | null;
	/** Фильтрация временному периоду (тип периода) */
	dateRangeType?: RequestFilterDateRangeType;
	/** Фильтрация временному периоду (дата 'От') */
	dateFrom?: string | null;
	/** Фильтрация временному периоду (дата 'До') */
	dateTo?: string | null;
	/** Фильтрация по номеру заявки */
	codeNumber?: number;
	template?: FiltersStoreApiResponse;
	/** Фильтрация по номеру телефона */
	phone?: string;
	/** Фильтрация по виду работ */
	work?: number[] | null;
	/** Фильтрация по состоянию */
	state?: number[] | null;
	/** Фильтрация по району */
	district?: number[] | null;
	/** Фильтрация по названию улицы */
	street?: string;
	/** Фильтрация по номеру дома */
	house?: string;
	/** Фильтрация по номеру корпуса */
	korp?: string;
	/** Фильтрация по номеру квартиры */
	flat?: string;
	/** Фильтрация по названию организации */
	organization?: string;
	/** Фильтрация по ответственному */
	responsible?: number[] | null;
	/** Фильтрация по назначившим мастера пользователям */
	announced?: number[] | null;
	/** Фильтрация по диспетчеру */
	dispatcher?: number[] | null;
	/** Фильтрация по мастерам */
	master?: any[] | null;
	beznal?: string | null;
	beznalOnline?: boolean | null;
	beznalByAccount?: boolean | null;
	period?: string | null;
	entrance?: number | null;
	/** Фильтрация по рекламе */
	advertising?: number[] | null;
	/** Фильтрация по типам */
	type?: RequestType[] | null;
	/** Фильтрация по 'Заявкам от' */
	partnerType?: RequestPartnerType[] | null;
	/** Фильтрация по партнерам */
	partner?: number[] | null;
	/** Фильтрация по типу договора */
	contractType?: RequestContractType[] | null;
	/** Фильтрация по состоянию безнала */
	cashlessState?: RequestCachelessState[] | null;
	/** Фильтрация 'Юридическое лицо' */
	isLegalEntity?: boolean | null;
	/** Фильтрация 'Не передан мастеру' */
	notTransferredToMaster?: boolean | null;
	/** Фильтрация 'Дубль заявки' */
	isDublicate?: boolean | null;
	/** Фильтрация 'С комментариями' */
	hasComments?: boolean | null;
	/** Фильтрация 'С файлами' */
	hasAttachment?: boolean | null;
	/** Фильтрация 'На контроле' */
	isControlledDispatcher?: boolean | null;
	/** Фильтрация 'Содержат записи работы' */
	hasOrderExecutions?: boolean | null;
	/** Фильтрация 'Кредитная заявка' */
	isCredit?: boolean | null;
	/** Фильтрация 'Рассрочка' */
	is_installment?: boolean | null;
	installments_state?: number;
	/** Фильтрация 'Мастер не подобран' */
	hasNoMaster?: boolean | null;
	/** Фильтрация 'Клиент ждет' */
	clientWaiting?: boolean | null;
	/** Фильтрация 'Не сохраненные' */
	isTemp?: boolean | null;
	fastFilters?: string | null;
	beznal_num?: number;
	/** Фильтрация по 'ИНН' */
	ur_inn?: string | null;
	is_ur?: number;
};
export type RequestsStoreApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestIndex;
};
export type RequestsStoreApiArg = {
	/** Запрос на создание новой заявки */
	requestsRequestStore: FormData | RequestsRequestStore;
};
export type RequestsDuplicationApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestIndex;
};
export type RequestsDuplicationForInstallmentApiResponse = /** status 200 Ok */ {
	data?: ResourcesRequestIndex;
};
export type RequestsDuplicationApiArg = {
	/** Request ID */
	requestId: number;
	/** Запрос на дублирование заявки */
	requestsRequestDuplication: FormData | RequestsRequestStore2;
};
export type RequestsDuplicationForInstallmentApiArg = {
	/** Request ID */
	requestId: number;
};
export type RequestsShowApiResponse = /** status 200 Ok */ {
	dispatcher: any;
	masters: any;
	data?: RequestResource;
};
export type RequestsShowApiArg = {
	/** ID заявки */
	requestId: number;
};
export type RequestsUpdateApiResponse = { data?: RequestResource };
export type RequestsUpdateApiArg = {
	/** Request ID */
	requestId: number;
	/** Request Body Description */
	updateRequest: RequestsUpdateRequest;
};
export type SaleTypesFeedApiResponse = /** status 200 Ok */ {
	data?: {
		id?: string;
		title?: string;
	}[];
};
export type SaleTypesFeedApiArg = void;
export type StatisticsIndexApiResponse = /** status 200 Ok */ {
	data?: ResourcesStatisticIndex;
};
export type StatisticsIndexApiArg = void;
export type TootipsAddressesFeedApiResponse = /** status 200 Ok */ {
	data?: {
		value?: string;
		unrestricted_value?: string;
		data?: object;
	}[];
};

export type StreetsFeedApiResponse = /** status 200 Ok */ {
	data: {
		id: number;
		title?: string;
		district_id?: number;
	}[];
};

export type StreetsFeedFeedApiArg = {
	name?: string;
};

export type RequestsRemovePhoneFromABlackListStoreApiResponse = unknown;
export type RequestsRemovePhoneFromBlackListStoreApiArg = {
	blacklist_id: number;
};
export type RequestsBlackListApiResponse = /** status 200 Ok */ {
	data:
		| [
				{
					id: number;
					fullcode: string;
					phone: string;
					enabled: string;
					description: string;
					user_id: number;
				},
		  ]
		| [];
};

export type RequestsBlackListApiArg = {
	phone?: string;
};

export type RequestsBlackListStoreApiResponse = unknown;
export type RequestsBlackListStoreApiArg = {
	request_id: number;
	requestsBlackListsStore: {
		phone: string;
	};
};

export type DadataBound = {
	value: string;
};
export type TootipsAddressesFeedApiArg = {
	/** Адресс введенный пользователем */
	address_query: string;
	from_bound?: DadataBound;
	to_bound?: DadataBound;
	locations?: any[];
	count?: number;
};
export type UsersFeedApiResponse = /** status 200 Ok */ {
	data?: UserGroupResource[];
};
export type UsersFeedApiArg = void;
export type WorksFeedApiResponse = /** status 200 Ok */ {
	data?: WorkFeedResource[];
};
export type WorksFeedApiArg = void;
export type AnnouncementShowOnModuleResource = {
	id?: number;
	title?: string;
	created_at?: string;
	module?: {
		id?: number;
		title?: string;
		link?: string;
	};
	groups?: {
		id?: number;
		name?: string;
		deleted?: boolean;
		allow_delete?: boolean;
		allow_edit?: boolean;
		is_admin?: boolean;
	}[];
};
export type RequestForTheInstallationOfReadingFlagsForApplications = {
	announcements_id?: number[];
};
export type BasemailFlashResource = {
	topic_id?: number;
	title?: string;
	text?: string;
	date?: string;
};
export type DispatchersDispatcherComplaintsFeedApiResponse = /** status 200 Ok */ {
	data?: TypeSelectableEnum[];
};
export type CallResource = {
	id?: number;
	date?: string;
	comment?: string;
	type_id?: number;
	type_name?: string;
	work_id?: number;
	work_name?: string;
	disp_id?: number;
	disp_fio?: number;
};
export type RequestsCallStore = {
	type_id: number;
	comment?: string;
	work_id?: number;
};
export type CallTypeFeedResource = {
	id?: number;
	title?: string;
};
export type MasterCall = {
	phone: string;
};
export type Company = {
	id?: number;
	title?: string;
	inn?: string | null;
};
export type District = {
	id?: number;
	title?: string;
	itemTitle?: string;
	items?: District[];
};
export type RequestsAuthLogin = {
	uid: number;
	token: string;
	phone: string;
	point: number;
};
export type TypeSelectableEnum = {
	id?: string | number;
	title?: string;
};
export type MasterFeedResource = {
	id?: number;
	title?: string;
};
export type MasterMessageCategory = {
	id?: number;
	title?: string;
};
export type MasterSelectionResource = {
	id?: number;
	fio?: string;
	fio_color?: string;
	is_online?: boolean;
	rating?: number;
	common_limit?: {
		description?: string;
		title?: string;
		style?: string;
		showed?: boolean;
	};
	day_limit?: string;
	condition?: {
		description?: string;
		title?: string;
		style?: string;
	} | null;
	available?: boolean;
	tags?: {
		title?: string;
		color?: string;
	}[];
};
export type MasterSelectionType = "optimal" | "approach";
export type ResourcesMasterSelectionWorkIndex = {
	id?: number;
	path?: string;
};
export type MetroStationFeedResource = {
	id?: number;
	title?: string;
	items?: MetroStationFeedResource[];
};
export type OfficesFeedResource = {
	id?: number;
	title?: string;
};
export type PartnerFeedResource = {
	id?: number;
	title?: string;
};
export type PauseStatus = {
	is_paused?: boolean;
};
export type ProfileResource = {
	id?: number;
	title?: string;
	location_id?: number;
	inner_phone?: string;
	permissions?: {
		CAN_REQUEST_VIEW_ALL_REQUESTS?: boolean;
		CAN_REQUEST_ADD?: boolean;
		CAN_REQUEST_EDIT?: boolean;
		CAN_REQUEST_CHANGE_BEFORE_SUMM?: boolean;
		CAN_REQUEST_CHANGE_ADVERT?: boolean;
		CAN_REQUEST_EDIT_FLAT?: boolean;
		CAN_REQUEST_SELECT_ANY_MASTER?: boolean;
		CAN_REQUEST_PAYMENT_VIEW?: boolean;
		CAN_REQUEST_PAYMENT_CREATE?: boolean;
		CAN_OKK?: boolean;
		CAN_OKK_ADD?: boolean;
		CAN_OKK_ADD_TELEMARKETING?: boolean;
		CAN_PREMIUM_DISPATCHER?: boolean;
		CAN_PREMIUM_MASTER?: boolean;
		CAN_REQUEST_COMPANY_ADD_INN?: boolean;
		CAN_REQUEST_INSTALLMENT?: boolean;
		CAN_USE_PHONER?: boolean;
	};
	available_menu?: {
		request?: boolean;
		master?: boolean;
		contacts?: boolean;
		statistic?: boolean;
		fin?: boolean;
		report?: boolean;
		constructor?: boolean;
		settings?: boolean;
		call?: boolean;
		repair?: boolean;
		test?: boolean;
		dotest?: boolean;
		bonus?: boolean;
		timetable?: boolean;
		blacklist?: boolean;
		phone_conformity?: boolean;
		master_messages?: boolean;
		okk?: boolean;
		telemarketing?: boolean;
		newstat?: boolean;
		sms?: boolean;
		callbacks?: boolean;
		master_rejects?: boolean;
		announcement?: boolean;
		mail?: boolean;
		ticket?: boolean;
		wiretapping?: boolean;
	};
};
export type QualityAssessmentResource = {
	id?: number;
	text?: string;
	title?: string;
	is_special?: boolean;
};
export type ResourceOfAGroupOfIssuesOnQualityAssessment = {
	id?: number;
	name?: string;
	questions?: QualityAssessmentResource[];
};
export type TypeOfQualityAssessment = "okk" | "telemarketing";
export type ViolationFeedResource = {
	id?: string;
	title?: string;
};
export type QualityControlPersonType = 1 | 2;
export type AdvertisingFeedResource = {
	id?: number;
	title?: string;
	items?: AdvertisingFeedResource[];
};
export type ResourcesRequestDispatcherComplaintShow = {
	id?: number;
	type?: number;
	type_title?: string;
	date?: string;
	disp_id?: number;
	disp_fio?: string;
	user_id?: number;
	user_fio?: string;
	text?: string;
	summ?: number;
	payed?: boolean;
};
export type RequestCommentStoreRequest = {
	type?: number;
	text?: string;
	summ?: number;
	payed?: boolean;
};
export type FiltersIndexResource = {
	id?: number;
	title?: string;
	data?: object;
};
export type RequestsFilterStore = {
	title: string;
	data: object;
};
export type RequestsFilterUpdate = {
	title?: string;
	data?: object;
};
export type ResourcesRequestMasterComplaintShow = {
	id?: number;
	type?: number;
	type_title?: string;
	date?: string;
	master_id?: number;
	master_fio?: string;
	user_id?: number;
	user_fio?: string;
	text?: string;
	summ?: number;
	payed?: boolean;
};
export type RequestCommentStoreRequest2 = {
	type?: number;
	text?: string;
	summ?: number;
	payed?: boolean;
};
export type ResourcesRequestMasterMasterMessageShow = {
	id?: number;
	text?: string;
	from_fio?: string;
	from_uid?: number;
	request_id?: number;
	request_num?: number;
	add_date?: string;
	category_id?: number;
};
export type MasterMessageStore = {
	message: string;
	category: number;
};
export type RequestsRequestMasterStore = {
	id: number[];
};
export type MasterResourceInPlannerList = {
	id?: number;
	fio?: string;
};
export type PlannerIndexResource = {
	id?: number;
	master?: MasterResourceInPlannerList;
	date?: string;
	date_to?: string;
	comment?: string;
};
export type QualityControlAnswerResource = {
	group: {
		id: number;
		name: string;
	};
	answers: [
		{
			id: number;
			title: string;
			status: string;
		},
	];
};
export type QualityControlIndexResource = {
	id?: number;
	date_add?: string;
	type?: "OKK" | "\u0422\u0435\u043B\u0435\u043C\u0430\u0440\u043A\u0435\u0442\u0438\u043D\u0433";
	user_fio?: string;
	request_state?: string;
	comment?: string;
	answer_groups?: QualityControlAnswerResource[];
};
export type QualityControlDenyReason =
	| "deny_01"
	| "deny_02"
	| "deny_03"
	| "deny_04"
	| "deny_05"
	| "deny_06"
	| "deny_07"
	| "deny_08"
	| "deny_09"
	| "deny_10"
	| "deny_11"
	| "deny_12"
	| "deny_13"
	| "deny_14"
	| "deny_15"
	| "deny_16";
export type QualityControlMasterGrade = 0 | 1 | 2 | 3 | 4 | 5;
export type QualityControlStatus = "closed" | "unclosed";
export type QualityControlStoreRequest = {
	type: TypeOfQualityAssessment;
	deny_reason?: QualityControlDenyReason;
	dispatcher_id?: number;
	request_dispatcher_rating?: string[];
	master_id?: number;
	request_master_rating?: string[];
	request_master_grade?: QualityControlMasterGrade;
	control?: boolean;
	control_date?: string;
	refund_refusal?: boolean;
	status?: boolean;
	comment: string;
	comment_adm?: string;
	answers?: any[];
};
export type AttachmentIndexResource = {
	id?: number;
	name?: string;
	path?: string;
	path_thumbnail?: string;
	size?: string;
	type?: string;
	can_del?: boolean;
};
export type ResourcesRequestIndexDispatcher = {
	id?: number;
	fio?: string;
};
export type ResourcesRequestIndexResponsible = {
	id?: number;
	fio?: string;
};
export type ResourcesRequestMasterAppointedShow = {
	appointed_date?: string;
	appointed_id?: number;
	appointed_fio?: string;
	sended_to_app?: boolean;
};
export type ResourcesRequestMasterShow = {
	id?: number;
	fio?: string;
	phone_mobile?: string;
	phone_mobile_addon?: string;
	appointed?: ResourcesRequestMasterAppointedShow;
	is_online?: boolean | null;
	vassal?: ResourcesRequestMasterShow;
};
export type RequestComment = {
	id?: number;
	date?: string;
	user_id?: number;
	user_fio?: string;
	text?: string;
	state_name?: string;
	state_id?: number;
	show_to_master?: boolean;
	type?: string;
	updated_at?: string;
	abilities?: {
		delete?: boolean;
		update?: boolean;
	};

	author: {
		fio: string;
		group: {
			name: string;
			background_color: string;
		};
		id: number;
	};
};
export type RecordsCallPersonResource = {
	comment_add?: boolean;
	update?: boolean;
	dublication?: boolean;
	flat?: boolean;
	premium?: boolean;
	user_add?: boolean;
	phone?: boolean;
	address?: boolean;
	advert?: boolean;
	non_cash?: boolean;
	controlled_admin?: boolean;
	controlled_dispatcher?: boolean;
	master_message?: boolean;
	premium_master?: boolean;
	select_any_master?: boolean;
	installment?: {
		view?: boolean;
		create?: boolean;
		update?: boolean;
		delete?: boolean;
		fin_info?: boolean;
		duplicate_for_installment?: boolean;
		payments?: {
			view?: boolean;
			create?: boolean;
			update?: boolean;
			delete?: boolean;
		};
		journal?: {
			view: boolean;
		};
		history?: {
			view: boolean;
		};
	};
};
export type ResourcesRequestFinBlock = {
	fromClient?: number;
	discount?: number;
	fromClientWithDiscount?: number;
	detal?: number;
	transport?: number;
	detalToFirm?: number;
	masterForWork?: number;
	master_total?: number;
	workInFirm?: number;
	totalInFirm?: number;
	rec_sale_type?: number;
	sale_value?: number;
	payment_type_caption?: string;
};
export type RequestStateResource = {
	id: number;
	title?: string;
	is_need_set_date?: boolean;
	is_need_set_comment?: boolean;
	is_refusal?: boolean;
	is_final?: boolean;
};
export type RequestAddressResource = {
	district_id?: number | null;
	district_name?: string | null;
	street?: string | null;
	korp?: string | null;
	house?: string | null;
	entrance?: string | null;
	flat?: string | null;
	metro_id?: number | null;
};

export type WorkResourcesRequestIndex = {
	id: number;
	items: WorkResourcesRequestIndex[];
	title: string;
	path: string;
};
export type DuplicatedOfIndex = { id: number; fullcode: string };
export type ResourcesRequestIndex = {
	location_id?: number;
	id?: number;
	temp?: boolean;
	point_request_link?: string;
	type_id?: number;
	fullcode?: string;
	date_add?: string;
	date_call?: string | null;
	date_visit?: string | null;
	dispatcher?: ResourcesRequestIndexDispatcher;
	available_states?: RequestStateResource[];
	state_id?: number;
	state_name?: string;
	state_changed?: string;
	state_comment?: string;
	is_guarantee?: boolean;
	duplicates?: DuplicatedOfIndex[];
	duplicated_of?: DuplicatedOfIndex[] | DuplicatedOfIndex | null;
	work_id?: number;
	work_name?: string;
	work?: WorkResourcesRequestIndex;
	obj?: string | null;
	masters?: ResourcesRequestMasterShow[];
	approved_master?: ResourcesRequestMasterShow;
	responsible?: ResourcesRequestIndexResponsible;
	client_id?: number;
	client_fio?: string;
	ur_id?: number;
	ur_org?: string;
	ur_fio?: string;
	ur_inn?: string | null;
	phone?: string | null;
	phone2?: number | null;
	phone3?: number | null;
	full_address?: string;
	wait_until?: string;
	address?: RequestAddressResource;
	metro_path?: string;
	reklama_name?: string;
	comments_count?: number;
	masters_count?: number;
	last_three_comments?: RequestComment[];
	tofirm?: number;
	tomaster_summ?: number;
	detal?: number;
	is_online_payment?: boolean;
	auto_selection?: boolean;
	fin_master_report_id?: number;
	fin_block?: ResourcesRequestFinBlock;
	quality_controls?: QualityControlIndexResource[];
	abilities?: RecordsCallPersonResource;
};
export type RequestAttachmentStoreRequest = {
	"attachments[]"?: any[];
};

export type RequestsMastersApproveApiResponse = unknown;
export type RequestsMastersApproveApiArg = {
	/** Request ID */
	requestId: number;
	/** Master ID */
	masterId: number;
	requestsMastersApprove: {
		masterId: number;
		requestId: number;
	};
};

export type RequestCommentIndexResource = {
	date?: string;
	comments?: RequestComment[];
};

export type RequestCommentStoreRequest3 = {
	text: string;
	show_to_master: boolean;
};
export type RequestCommentUpdateRequest = {
	text: string;
	show_to_master?: boolean;
};
export type RequestLogIndexResource = {
	id?: number;
	user_fio?: string;
	date?: string;
	action?: string;
	field?: string;
	before?: string;
	after?: string;
};
export type Links = {
	first?: string;
	last?: string;
	prev?: string;
	next?: string;
};
export type Meta = {
	current_page?: number;
	from?: number;
	last_page?: number;
	links?: {
		url?: string;
		label?: string;
		active?: boolean;
	}[];
	path?: string;
	per_page?: number;
	to?: number;
	total?: number;
};
export type DateRangeType = {
	id?: string;
	title?: string;
};
export type RecordsCallPersonResource2 = {
	name?: string;
	type?:
		| "\u041A\u043B\u0438\u0435\u043D\u0442"
		| "\u041C\u0430\u0441\u0442\u0435\u0440"
		| "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A";
	phone?: string;
};
export type RequestRecordResource = {
	cdr_id: number;
	call_time: string;
	caller: RecordsCallPersonResource2;
	called: RecordsCallPersonResource2;
	duration: number;
	index?: number;
};
export type RequestAutoanswerResource = {
	cdr_id: number;
	time: string;
	filepath: string;
	phones: string[];
	duration: number;
};

export type RequestFilterDateRangeType = "by_receipt" | "by_visit" | "by_status_change" | "by_master_assign";
export type RequestType = 0 | 1 | 2;
export type RequestPartnerType = 0 | 1 | 2;
export type RequestContractType =
	| "\u041D\u0435\u0442"
	| "\u0420\u0430\u0437\u043E\u0432\u044B\u0439"
	| "\u0413\u043E\u0434\u043E\u0432\u043E\u0439";
export type RequestCachelessState = 0 | 1 | 2;
export type RequestsRequestStore = {
	client_fio: string;
	phone: string | null;
	phone2?: number | null;
	phone3?: number | null;
	address_street: string;
	address_house: string;
	address_korp?: string | null;
	address_entrance?: string;
	address_flat?: string | null;
	address_district_id: number;
	reklama_id: number;
	obj: string | null;
	work_id: number;
	roistat_code?: number;
	metro_id?: number;
	is_controlled_admin?: boolean;
	pay_type?: number;
	auto_selection?: boolean;
	is_guarantee?: boolean;
	is_direct?: boolean;
	is_controlled_dispatcher?: boolean;
	is_privileges?: boolean;
	before_summ?: number;
	guarantee_till?: string | null;
	isur?: boolean;
	ur_id?: number;
	ur_org?: string;
	ur_fio?: string;
	beznal_date?: number;
	beznal_num?: number;
	beznal_summ?: number;
	beznal_plat_num?: string;
	beznal_plat_date?: string;
	contract_type?: string;
	nds?: boolean;
	type_id?: number;
	beznal_state_id?: number;
	disp_payed?: boolean;
	wait_til_date?: number;
	partner_type_id?: number;
	partner_id?: number;
	partner_who_accept?: string | null;
	partner_user_id?: number;
	is_credit?: boolean;
	attachments?: Blob[];
	user_add_id?: number;
	selected_masters?: number[];
	rec_sale_type?: number;
	sale_value?: number;
	location_id?: number;
	inn?: string;
	location: number;
};
export type RequestsRequestStore2 = RequestsRequestStore & {
	client_fio?: string;
	phone?: string | null;
	phone2?: number | null;
	phone3?: number | null;
	address_street?: string;
	address_house?: string;
	address_korp?: string | null;
	address_entrance?: string;
	address_flat?: string | null;
	address_district_id?: number;
	reklama_id?: number;
	obj?: string | null;
	work_id?: number;
	roistat_code?: number;
	metro_id?: number;
	is_controlled_admin?: boolean;
	pay_type?: number;
	auto_selection?: boolean;
	is_guarantee?: boolean;
	is_direct?: boolean;
	is_controlled_dispatcher?: boolean;
	is_privileges?: boolean;
	before_summ?: number;
	guarantee_till?: string | null;
	isur?: boolean;
	ur_id?: number;
	ur_org?: string;
	ur_fio?: string;
	beznal_date?: number;
	beznal_num?: number;
	beznal_summ?: number;
	beznal_plat_num?: string;
	beznal_plat_date?: string;
	contract_type?: string;
	responsible_id?: number;
	nds?: boolean;
	type_id?: number;
	beznal_state_id?: number;
	disp_payed?: boolean;
	wait_til_date?: number;
	partner_type_id?: number;
	partner_id?: number;
	partner_who_accept?: string | null;
	partner_user_id?: number;
	is_credit?: boolean;
	attachments?: Blob[];
	user_add_id?: number;
	selected_masters?: number[];
	rec_sale_type?: number;
	sale_value?: number;
	location_id?: number;
};
export type RequestResource = {
	location_id?: number;
	last_three_comments?: RequestComment[];
	comment?: {
		text: string;
		show_to_master: boolean;
	};
	attachments?: any[];
	point_request_link?: string;
	id?: number;
	temp?: boolean;
	fullcode?: string;
	user_add_id?: number;
	address_entrance?: string;
	date_add?: string;
	date_visit?: string;
	date_call?: string;
	dispatcher?: ResourcesRequestIndexDispatcher;
	state_id?: number;
	state_name?: string;
	state_changed?: string;
	work_id?: number;
	work_name?: string;
	wait_until?: string;
	obj?: string | null;
	masters?: ResourcesRequestMasterShow[];
	approved_master?: ResourcesRequestMasterShow;
	has_no_approved_master_label?: string | null;
	client_id?: number;
	client_fio?: string;
	ur_id?: number;
	ur_org?: string;
	ur_fio?: string;
	responsible?: ResourcesRequestIndexResponsible;
	phone?: string | null;
	phone2?: number | null;
	phone3?: number | null;
	full_address?: string;
	address_street?: string;
	address_house?: string;
	address_korp?: string;
	address_flat?: string;
	address_district_id?: string;
	partner_type_id?: number;
	metro_path?: string;
	metro_id?: number;
	reklama_name?: string;
	reklama_id?: number;
	is_guarantee?: boolean;
	duplicates?: DuplicatedOfIndex[];
	duplicated_of?: DuplicatedOfIndex;
	is_controlled_dispatcher?: boolean;
	is_controlled_admin?: boolean;
	tofirm?: number;
	tomaster_summ?: number;
	detal?: number;
	is_online_payment?: boolean;
	auto_selection?: boolean;
	fin_master_report_id?: number;
	type_id?: number;
	is_privileges?: boolean;
	work?: WorkResourcesRequestIndex;
	location_name?: string;
	partner_user_id?: number;
	fin_block?: {
		fromClient?: number;
		discount?: number;
		fromClientWithDiscount?: number;
		detal?: number;
		transport?: number;
		detalToFirm?: number;
		masterForWork?: number;
		workInFirm?: number;
		totalInFirm?: number;
		location_id?: number;
	};
	abilities?: RecordsCallPersonResource;
	sale_value?: number;
	rec_sale_type?: number;

	contract_type?: string;
	beznal_state_id?: number;
	responsible_id?: number;
	beznal_num?: number;
	beznal_date?: string;
	beznal_summ?: number;
	before_summ?: number;
	beznal_plat_num?: string;
	beznal_plat_date?: string;
	is_credit?: boolean;
	nds?: boolean;
	ur_inn?: string;
};
export type RequestsUpdateRequest = {
	roistat_code?: number;
	auto_selection?: boolean;
	is_direct?: boolean;
	is_guarantee?: boolean;
	phone?: string;
	phone2?: string;
	phone3?: string;
	state_id?: number;
	client_fio?: string;
	address_district_id?: number;
	address_street?: string;
	address_house?: string;
	address_korp?: string;
	address_entrance?: string;
	address_flat?: string;
	descr?: string;
	obj?: string;
	work_id?: number;
	is_controlled_admin?: boolean;
	pay_type?: number;
	is_controlled_dispatcher?: boolean;
	is_privileges?: boolean;
	before_summ?: number;
	guarantee_till?: number;
	reklama_id?: number;
	beznal_date?: number;
	beznal_num?: number;
	beznal_summ?: number;
	beznal_plat_num?: string;
	beznal_plat_date?: string;
	contract_type?: string;
	responsible_id?: number | null;
	nds?: boolean;
	type_id?: number;
	isur?: boolean;
	ur_id?: number;
	ur_org?: string;
	ur_fio?: string;
	beznal_state_id?: number;
	disp_payed?: boolean;
	wait_til_date?: number;
	partner_type_id?: number;
	partner_id?: number;
	partner_who_accept?: string;
	partner_user_id?: number;
	metro_id?: number;
	is_credit?: boolean;
	rec_sale_type?: number;
	sale_value?: number;
	location_id?: number;
	user_add_id?: number;
};

export type DispatcherTodayStatistics = {
	income_count: number;
	outcome_count: number;
	request_count: number;
	total_count: number;
};

export type DispatcheMonthStatistics = {
	income_count: number;
	outcome_count: number;
	request_count: number;
	total_count: number;
};
export type ResoursecStatisticAnswerPhones = {
	cdr_id: number;
	duration: number;
	filepath: string;
	phones: string[];
	time: string;
};
export type ResourcesStatisticIndex = {
	requests_today?: {
		request_count?: number;
		dub_count?: number;
		spb_count?: number;
		msk_count?: number;
	};
	requests_yesterday?: {
		request_count?: number;
		dub_count?: number;
		spb_count?: number;
		msk_count?: number;
	};
	requests_top_record?: {
		request_count?: number;
		spb_count?: number;
		msk_count?: number;
		date?: string;
	};
	requests_not_approved?: string;
	requests_without_masters?: string;
	requests_with_waiting_clients?: string;
	missing_calls?: {
		id?: number;
		group_id?: number;
		src?: string;
		time?: string;
	}[];
	answer_phone?: ResoursecStatisticAnswerPhones[];
	phone_statistics?: {
		logged_in?: number;
		disp_not_take_call?: string[];
		available?: number;
		callers?: number;
	};
	dispatcher?: {
		today: DispatcherTodayStatistics;
		month: DispatcheMonthStatistics;
	};
};
export type UserFeedResource = {
	id?: number;
	title?: string;
};
export type UserGroupResource = {
	id?: number;
	title?: string;
	items?: UserFeedResource[];
};
export type WorkFeedResource = {
	id?: number;
	title?: string;
	items?: WorkFeedResource[];
};
export type ResourcesRequestComplaintsIndex = {
	id?: number;
	master_complaints?: ResourcesRequestMasterComplaintShow[];
	dispatcher_complaints?: ResourcesRequestDispatcherComplaintShow[];
};
export type RequestsComplaintsIndexApiResponse = {
	data?: ResourcesRequestComplaintsIndex;
};
export type RequestsComplaintsIndexApiArg = {
	/** Request ID */
	requestId: number;
};
export const {
	useAnnouncementsShowOnModuleQuery,
	useAnnouncementsReadMutation,
	useAttachmentsDestroyMutation,
	useRequestsMastersApproveMutation,
	useAttachmentsImageQuery,
	useAttachmentsDownloadMutation,
	useBasemailFlashGetFlashQuery,
	useCallsStoreMutation,
	useCallTypesFeedQuery,
	useClientCallMutation,
	useMissedClientCallMutation,
	useCompaniesFeedQuery,
	useDistrictsFeedQuery,
	useDispetcherCardQuery,
	useSsoLoginMutation,
	useMasterCallMutation,
	useMastersMasterComplaintsFeedQuery,
	useMastersFeedQuery,
	useMastersMessagesCategoriesFeedQuery,
	useMasterSelectionsIndexQuery,
	useMasterSelectionsWorksIndexQuery,
	useMasterSelectionsWorksMastersIndexQuery,
	useMasterCardQuery,
	useMetroStationsFeedQuery,
	useOfficesFeedQuery,
	usePartnersFeedQuery,
	usePauseSetMutation,
	usePauseUnsetMutation,
	usePauseStatusQuery,
	useProfileQuery,
	useProfileLogoutMutation,
	useQualityControlsPresetsDenyReasonsQuery,
	useQualityControlsPresetsMasterGradesQuery,
	useQualityControlsQuestionGroupsIndexQuery,
	useQualityControlsViolationsFeedQuery,
	useRecordsDownloadQuery,
	useRecordsListenQuery,
	useReklamasFeedQuery,
	useLazyReklamasFilterByNameQuery,
	useRequestsDispComplaintsIndexQuery,
	useRequestsComplaintsIndexQuery,
	useRequestsDispComplaintsStoreMutation,
	useUpdateDispComplaintsStoreMutation,
	useDeleteDispComplaintsStoreMutation,
	useFiltersFeedQuery,
	useFiltersStoreMutation,
	useFiltersShowQuery,
	useFiltersUpdateMutation,
	useFiltersDestroyMutation,
	useRequestsMastersComplaintsIndexQuery,
	useRequestsMastersComplaintsStoreMutation,
	useUpdateMastersComplaintsStoreMutation,
	useDeleteMastersComplaintsStoreMutation,
	useSendMasterMessageMutation,
	useRequestsMastersStoreMutation,
	useRequestsSendSMSMessageToClientStoreMutation,
	useRequestsPlannerIndexQuery,
	useRequestsQualityControlsIndexQuery,
	useRequestsQualityControlsStoreMutation,
	useRequestsAttachmentsIndexQuery,
	useRequestsAttachmentsStoreMutation,
	useRequestsCommentsIndexQuery,
	useRequestsCommentsStoreMutation,
	useRequestsCommentsUpdateMutation,
	useRequestsCommentsDestroyMutation,
	useRequestsLogsIndexQuery,
	useRequestsPresetsDateRangeTypesQuery,
	useRequestsPresetsStatesQuery,
	useRequestsPresetsStatesForCreateQuery,
	useRequestsPresetsTypesQuery,
	useRequestsPresetsPartnerTypesQuery,
	useRequestsPresetsContractTypesQuery,
	useRequestsPresetsCashlessTypesQuery,
	useRequestsPresetsInstallmentTypesQuery,
	useRequestsRecordsIndexQuery,
	useRequestsIndexQuery,
	useRequestsStoreMutation,
	useRequestsDuplicationMutation,
	useRequestsDuplicationForInstallmentMutation,
	useRequestsShowQuery,
	useRequestsCommentsAllIndexQuery,
	useRequestsUpdateMutation,
	useRequestsMasterRemoveMutation,
	useRequestsInstallmentQuery,
	useSaleTypesFeedQuery,
	useStatisticsIndexQuery,
	useTootipsAddressesFeedMutation,
	useStreetsFeedQuery,
	useRequestsBlackListInfoQuery,
	useRequestsBlackListStoreMutation,
	useRequestsRemovePhoneFromBlackListStoreMutation,
	useClientPreviousRequestsQuery,
	useUsersFeedQuery,
	useDispatchersDispatcherComplaintsFeedQuery,
	useWorksFeedQuery,
	useRequestsChangeStateMutation,
	useDeleteAnswerPhoneMutation,
	useDeleteMissingCallMutation,
	useCallbackCountQuery,
	useClientContactRequestsQuery,
	useRequestsChangeInstallmentMutation,
	useRequestsInstallmentLogsJournalQuery,
	useRequestsInstallmentPaymentHistoryQuery,
	useRequestsInstallmentCreatePaymentMutation,
	useRequestsInstallmentDeletePaymentMutation,
	useRequestsLocationQuery,
} = injectedRtkApi;
