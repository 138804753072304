import styles from './header.module.scss'
import { FC } from 'react'
import { HeaderTypes } from '@common/header/header.types'

export const Header: FC<HeaderTypes> = ({ bottomSection, topSection }) => {
  return (
    <div className={styles.header}>
      {topSection && (
        <div className={styles.header_top_section}>{topSection}</div>
      )}
      {bottomSection && (
        <div className={styles.header_bottom_section}>{bottomSection}</div>
      )}
    </div>
  )
}
