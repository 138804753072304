import {
  RequestCardContainerProps
} from '@common/request-card/request-card.types'
import styles from '../request-card.module.scss'

export const RequestCardContainer = ({children}: Partial<RequestCardContainerProps>) => {
  return (
    <div className={styles['request-card']}>{children}</div>
  )
}
