import { NoDataProps } from './no-data.interface'
import styles from './no-data.module.scss'
export const NoData = ({ src, alt, title }: NoDataProps) => {
  return (
    <div className={styles['no-data']}>
      <img src={src} alt={alt} />
      <span>{title}</span>
    </div>
  )
}
