import { ComponentProps, ElementType, ReactNode } from 'react'
import {
  UIComponentButtonGutterSchema,
  UIComponentButtonSizeSchema,
  UIComponentButtonWidthSchema,
  UIComponentContentJustify,
  UIComponentVariantSchema,
} from '../../types/styles.types'
import { MutableRefProp } from '../../types/mutable-ref.types'

export type ButtonOwnUITypeSchema = 'primary-uncolored' | 'secondary-uncolored'

export type ButtonOwnProps<E extends ElementType = ElementType> = {
  children?: ReactNode
  as?: E
  size?: keyof Pick<UIComponentButtonSizeSchema, 'm'>
  variant: UIComponentVariantSchema | ButtonOwnUITypeSchema
  justifyContent?: UIComponentContentJustify
  width?: keyof UIComponentButtonWidthSchema
  gutter?: keyof Pick<UIComponentButtonGutterSchema, 'm'>
  isActive?: boolean
  renderIcon?: ReactNode
  iconPlacement?: 'left' | 'right'
}

export const defaultButtonElement = 'button'

export type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof ButtonOwnProps> &
  MutableRefProp<any>
