import { ModalContainer } from "@/components/common";
import { useNavigate, useParams } from "react-router";
import { InstallmentModalProps } from "./installment.types";
import { ModalHeader, ModalBody } from "@/components/common/modal-container";
import { ContentLoader } from "@/components";
import { InstallmentHeaderLeftContent } from "./installment-header/installment-left";
import { InstallmentInfoTabs } from "./installment-content/installment-info/installment-info-tabs";
import { useEffect, useState } from "react";
import style from "./installment.module.scss";
import { InstallmentAddPayment } from "./installment-content/installment-add-payment/installment-add-payment";
import { useRequestsIndexQuery, useRequestsInstallmentQuery, useRequestsShowQuery } from "@/state/api";
import { useUserProfileInfo } from "@/state/selector";
import { InstallmentPlan } from "./installment-content/installment-plan/installment-plan";
import { InstallmentJournal } from "./installment-content/installment-journal/installment-journal";
import modalBodyStyle from "@components/common/modal-container/modal-body/modal-body.module.scss";
import { InstallmentInfoForm } from "./installment-content/installment-info";
import { InstallmentButtonSwitchState, InstallmentButtonSwitch, InstallmentServerData } from "@/constants/installment";
import { InstallmentHistory } from "./installment-content/installment-history";

export const InstallmentModal = ({ onClosePath, closeOnBlackoutClick, title, onHandleBackButton }: InstallmentModalProps) => {
	const navigate = useNavigate();
	const { fullcodeId, requestId } = useParams();
	const { data, isLoading } = useRequestsInstallmentQuery({ requestId: requestId ?? "" });
	const [buttonSwitch, setButtonSwitch] = useState<InstallmentButtonSwitchState>("plan");
	const [schedule, setSchedule] = useState(data?.data?.schedule);
	const [installmentData, setInstallmentData] = useState(data?.data);
	const { data: request } = useRequestsShowQuery({
		requestId: Number(requestId),
	});
	const requestInfo = request?.data || {};

	const canViewHistory = !!requestInfo.abilities?.installment?.history?.view;
	const canViewJournal = !!requestInfo.abilities?.installment?.journal?.view;
	const canViewPlan = !!requestInfo.abilities?.installment?.payments?.view;
	const canDeletePayments = !!requestInfo.abilities?.installment?.payments?.delete;
	const canCreatePayment = !!requestInfo.abilities?.installment?.payments?.create;
	const canCreateInstallment = !!requestInfo.abilities?.installment?.create;
	const canEditInstallment = !!requestInfo.abilities?.installment?.update;
	const canViewInstallment = !!requestInfo.abilities?.installment?.view;
	const canViewFinInfo = !!requestInfo.abilities?.installment?.fin_info;

	useEffect(() => {
		setInstallmentData(data?.data);
		setSchedule(data?.data?.schedule);
	}, [data]);

	useEffect(() => {
		if (canViewPlan) {
			setButtonSwitch("plan");
			return;
		}

		if (canViewHistory) {
			setButtonSwitch("history");
			return;
		}

		if (canViewJournal) {
			setButtonSwitch("journal");
			return;
		}
	}, [requestInfo]);

	return (
		<>
			<ModalContainer
				className={style["installment-modal"]}
				onClose={
					onClosePath
						? () => {
								navigate(onClosePath);
							}
						: () => {
								navigate("/");
							}
				}
				showBlackout
				closeOnBlackoutClick={closeOnBlackoutClick}
			>
				<ModalHeader
					showRightContent
					showLeftContent
					contentLeft={
						<InstallmentHeaderLeftContent
							showBackButton={false}
							subtitle={`Заявка #${fullcodeId ? fullcodeId : ""}`}
							title={title}
							onHandleBackButton={onHandleBackButton}
						/>
					}
					showCloseButton
					onClose={
						onClosePath
							? () => {
									navigate(onClosePath);
								}
							: () => {
									navigate("../");
								}
					}
				/>
				<ModalBody className={modalBodyStyle["modal-body-container-installment"]}>
					{isLoading ? (
						<ContentLoader />
					) : (
						<div className={style["installment-modal_body"]}>
							<InstallmentInfoForm
								data={installmentData}
								canView={canViewInstallment}
								canEdit={canEditInstallment}
								canCreate={canCreateInstallment}
								canViewFinInfo={canViewFinInfo}
								fullcodeId={fullcodeId}
								requestId={requestId}
							/>
							{!!(installmentData && (canViewHistory || canViewJournal || canViewPlan)) ? (
								<>
									<div className={style[`installment-modal_body_table`]}>
										<div className={style[`tabs-wrapper`]}>
											<InstallmentInfoTabs
												buttonSwitch={buttonSwitch}
												withHistory={canViewHistory}
												withJournal={canViewJournal}
												withPlan={canViewPlan}
												setButtonSwitch={setButtonSwitch}
											/>
											<InstallmentAddPayment
												dept={installmentData?.[InstallmentServerData.dept]}
												havePermission={canCreatePayment}
											/>
										</div>
										{buttonSwitch === InstallmentButtonSwitch.plan && canViewPlan ? (
											<InstallmentPlan canViewFinInfo={canViewFinInfo} data={schedule || []} />
										) : null}
										{buttonSwitch === InstallmentButtonSwitch.history && canViewHistory ? (
											<InstallmentHistory
												requestId={requestId}
												canDelete={canDeletePayments}
												canViewFinInfo={canViewFinInfo}
											/>
										) : null}
										{buttonSwitch === InstallmentButtonSwitch.journal && canViewJournal ? (
											<InstallmentJournal requestId={requestId} />
										) : null}
									</div>
								</>
							) : null}
						</div>
					)}
				</ModalBody>
			</ModalContainer>
		</>
	);
};
