import { useNavigate } from 'react-router'
import styles from './quality-popup.module.scss'
import React from 'react'
import {
  LINK_OKK_COMMENT,
  LINK_OKK_EMPLOYEE,
  LINK_OKK_TELE,
  OKK_COMMENT,
  OKK_EMPLOYEE,
  OKK_TELE,
} from '@/constants/routes'
import { IQualityPopup } from './quality-props.types'
import cn from 'classnames'
import { useFullCodeRequest } from '@/contexts/scroll-pos'

const QualityPopup = React.forwardRef<HTMLDivElement, IQualityPopup>(
  ({ card, isRequestPage, className = '' }, ref) => {
    const navigate = useNavigate()
    const qualityPopupClasses = cn(styles['quality-popup'], className)
    const { requestFullcodeId, handleSetRequestFullcodeId } =
      useFullCodeRequest()

    return (
      <div ref={ref} className={qualityPopupClasses}>
        <ul className={styles['quality-menu']}>
          <li
            className={styles['quality-menu-item']}
            onClick={() => {
              card.id &&
                navigate(
                  isRequestPage ? OKK_EMPLOYEE : LINK_OKK_EMPLOYEE(card.id),
                )
              handleSetRequestFullcodeId(String(card?.fullcode))
            }}>
            Анализ работы сотрудников
          </li>
          <li
            onClick={() => {
              card.id &&
                navigate(
                  isRequestPage ? OKK_COMMENT : LINK_OKK_COMMENT(card.id),
                )
              handleSetRequestFullcodeId(String(card?.fullcode))
            }}
            className={styles['quality-menu-item']}>
            Анализ ОКК
          </li>
          <li
            className={styles['quality-menu-item']}
            onClick={() => {
              card.id &&
                navigate(isRequestPage ? OKK_TELE : LINK_OKK_TELE(card.id))
              handleSetRequestFullcodeId(String(card?.fullcode))
            }}>
            Анализ Телемаркетинга
          </li>
        </ul>
      </div>
    )
  },
)

export default QualityPopup
