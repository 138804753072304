import { Title } from '@common/modal-container/modal-title'
import { SubTitle } from '@common/modal-container/modal-title/modal-subtitle'
import { ModalHeaderContentProps } from '@global-types/modal-header-content.types'

export const MasterCardHeaderLeftContent = ({
  title,
  subtitle,
}: Partial<ModalHeaderContentProps>) => {
  return (
    <>
      <SubTitle>{subtitle}</SubTitle>
      <Title>{title}</Title>
    </>
  )
}
