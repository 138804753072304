import { Button, Icon } from '../../../shared'
import { useState } from 'react'

export const ControlMicrophoneButton = () => {
  const [isMicMuted, setIsMicMuted] = useState<boolean>(false)
  const handleControlMicrophoneButton = () => {
    if (isMicMuted) {
      setIsMicMuted(false)
    } else {
      setIsMicMuted(true)
    }
  }

  return (
    <Button
      onClick={handleControlMicrophoneButton}
      size="m"
      variant={'secondary-uncolored'}
      width="icon"
      renderIcon={
        <Icon
          icon={isMicMuted ? 'icon-bx-microphone-off' : 'icon-bx-microphone-on'}
        />
      }
    />
  )
}
