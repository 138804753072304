import { ButtonSwitchProps } from './button-switch.types'
import styles from './button-switch.module.scss'
import React from 'react'

export const ButtonSwitchContainer = ({
  label,
  showLabel,
  children,
}: ButtonSwitchProps) => {
  return (
    <div className={styles['button-switch-wrapper']}>
      {showLabel && (
        <div className={styles['button-switch-label-wrapper']}>
          <p className={styles['button-switch-label']}>{label}:</p>
        </div>
      )}
      <div className={styles['button-switch-container']}>{children}</div>
    </div>
  )
}
