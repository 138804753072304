import { createSlice } from "@reduxjs/toolkit";
import { windowsInitialState } from "./initial-state";

const windowsSlice = createSlice({
  initialState: { ...windowsInitialState },
  name: "windows",
  reducers: {},
});

export const { reducer: windowsReducer } = windowsSlice;
