import type { DispetcherCardContentPropsType } from "./dispetcher-card-content.types";
import { RequestsStatistic } from "./requests-statistic";
import { WorkControl } from "./work-control";

export const DispetcherCardContent = ({ work, statistic }: DispetcherCardContentPropsType) => {
	return (
		<>
			<RequestsStatistic statistic={statistic} title={"Статистика заявок"} />
			<WorkControl work={work} title={"Контроль работы (последние 30 дней)"} />
		</>
	);
};
