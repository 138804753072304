import cn from "clsx";
import { useId } from "react";
import { Tooltip } from "../../../../index";
import styles from "./input-error-container.module.scss";
import type { InputErrorContainerProps } from "./input-error-container.types";
export const InputErrorContainer = ({
	children,
	errorMessage = "",
	anchorId = "",
	className,
	placement = "center",
	tooltipOffset = [0, 15],
}: InputErrorContainerProps) => {
	const inputErrorContainer = cn(styles["input-error-container"], className);

	const tooltipPlacement = cn(styles["input-error-container-placement"], {
		[styles[`input-error-container-placement--${placement}`] as string]: true,
	});

	const keyId = useId();

	return (
		<div className={inputErrorContainer}>
			{children}
			{anchorId !== "" && !!errorMessage && (
				<div className={tooltipPlacement}>
					<Tooltip
						externalHoverId={anchorId}
						show
						anchorId={anchorId}
						key={keyId}
						text={errorMessage}
						tooltipOffset={tooltipOffset}
						placement={"bottom"}
					/>
				</div>
			)}
		</div>
	);
};
