import { createContext, type ReactNode, useContext, useEffect, useState } from "react";
import { HotkeysProvider } from "react-hotkeys-hook";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { useActor } from "@xstate/react";
import { usePhoner } from "../phoner/phoner";
import { debounce } from "lodash";
interface HotKeysContextType {
	value?: unknown;
}
export const HotKeysContext = createContext({} as HotKeysContextType);

export const useHotKeys = () => useContext(HotKeysContext);
export const HotKeysProvider = ({ children }: { children: ReactNode }) => {
	const { phonerSettingsService } = useGlobalPhonerState();
	const [statePhonerSettings] = useActor(phonerSettingsService);
	const audioVolume = statePhonerSettings?.context?.audioVolume;

	const [audioValue] = useState<number>(audioVolume);
	const { handleVolumeChange } = usePhoner();

	useEffect(() => {
		const handler = debounce(() => {
			handleVolumeChange(audioValue);
		}, 250);

		handler();

		return () => handler.cancel();
	}, [audioValue]);

	return (
		<HotKeysContext.Provider value={{}}>
			<HotkeysProvider>{children}</HotkeysProvider>
		</HotKeysContext.Provider>
	);
};
