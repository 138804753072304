export const InstallmentServerData = {
  id: 'id',
  agreement: 'agreement',
  period: 'period',
  summ: 'summ',
  fee: 'fee',
  dept: 'dept',
  min_payment: 'min_payment',
  date_begin: 'date_begin',
  contract_date: 'contract_date',
  status: 'state',
  status_name: 'state_name',
  schedule: 'schedule',
} as const

export const InstallmentScheduleServerData = {
  num: 'num',
  date: 'date',
  dept: 'dept',
  pay_date: 'pay_date',
  fee: 'fee',
  type: 'type',
  period_status: 'period_status',
  is_passed: 'is_passed',
} as const

export const InstallmentFields = {
  contractNumber: 'Номер договора:',
  dateOfCommencementOfInstallment: 'Дата начала рассрочки:',
  dateOfSigningTheContract: 'Дата подписания договора:',
  installmentPeriod: 'Период рассрочки:',
  installmentAmount: 'Сумма рассрочки:',
  minimumInstallment: 'Минимальный взнос:',
  paid: 'Оплачено:',
  balance: 'Остаток:',
} as const

export const InstallmentHistoryServerData = {
  data_of_payment: 'created_at',
  payment_summ: 'summ',
  payment_type: 'payment_type',
} as const
export const InstallmentPlanColWidth = {
  [InstallmentScheduleServerData.num]: 53,
  other: 207,
} as const

export const InstallmentJournalServerData = {
  id: 'id',
  date: 'date',
  message: 'message',
} as const

export const InstallmentJournalColWidth = {
  id: 53,
  date: 207,
  message: 207,
} as const

export const InstallmentButtonSwitch = {
  plan: 'plan',
  history: 'history',
  journal: 'journal',
} as const

export type InstallmentButtonSwitchState = 'plan' | 'history' | 'journal'

export const InstallmentButtonSwitchLabel = {
  plan: 'План рассрочки',
  history: 'История оплат',
  journal: 'Журнал изменений',
} as const

export const InstallmentStatuses = {
  inProgress: 'inProgress',
  paid: 'paid',
  overdue: 'overdue',
} as const
export const InstallmentStatusNames = {
  [InstallmentStatuses.inProgress]: 'В процеcсе оплаты',
  [InstallmentStatuses.paid]: 'Оплачено',
  [InstallmentStatuses.overdue]: 'Просрочено',
} as const

export const InstallmentHistoryColWidth = {
  id: 53,
  date: 207,
  message: 207,
} as const
