import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { ModalHeaderContentProps } from '@global-types/modal-header-content.types'
import { useFormState } from 'react-final-form'
import { useState } from 'react'
import { ConfirmActionModal } from '@components/modals/create-request-modal/confirm-action-modal'

export const CreateRequestModalRightContent = ({
  modalView,
  onHandleRightAction,
  isRightActionButtonDisabled,
  isLoading,
}: Partial<ModalHeaderContentProps>) => {
  const formState = useFormState()
  // const [isConfirmActionModalOpened, setIsConfirmActionModalOpened] =
  //   useState<boolean>(false)

  return (
    <>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        btnType={'button'}
        type={ButtonTypes.primary}
        size={UISizes.medium}
        onClick={isLoading ? () => {} : onHandleRightAction}>
        {modalView === 'edit' ? 'Сохранить заявку' : 'Создать заявку'}
      </Button>
      {/*{isConfirmActionModalOpened && (*/}
      {/*  <ConfirmActionModal*/}
      {/*    isLoading={isLoading}*/}
      {/*    text={`Вы уверены, что хотите сохранить изменения ?`}*/}
      {/*    confirmButtonText={'Подтвердить'}*/}
      {/*    cancelButtonText={'Отмена'}*/}
      {/*    onCancel={() => setIsConfirmActionModalOpened(false)}*/}
      {/*    onConfirm={() => {*/}
      {/*      onHandleRightAction && onHandleRightAction()*/}
      {/*      setIsConfirmActionModalOpened(false)*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  )
}
