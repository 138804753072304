export const CreateRequestLabels = {
  request: 'Заявка',
  typeId: 'Тип заявки',
  dispatcher: 'Принято',
  typeOfWork: 'Вид работ',
  obj: 'Объект',
  ads: 'Реклама',
  isLegalEntity: 'Лицо',
  organization: 'Организация',
  legalEntityClientFio: 'Контактное лицо',
  clientFio: 'ФИО',
  phone: 'Телефон',
  phone2: 'Телефон 2',
  phone3: 'Телефон 3',
  districtId: 'Район',
  house: 'Дом:',
  housing: 'Корпус',
  street: 'Улица:',
  entrance: 'Подъезд',
  apartment: 'Кв',
  metroStation: 'Метро',
  status: 'Статус',
  isControlledDispatcher: 'На контроле диспетчеров',
  isControlledAdmin: 'На контроле администрации',
  preferentialRequest: 'Льготная заявка',
  creditRequest: 'Кредитная заявка',
  ndsRequest: 'НДС',
  hasGuarantee: 'Гарантия',
  autoSelection: 'Автоподбор',
  selectedMasters: 'Подобрать мастера',
  saleType: 'Рекомендованная скидка',
  saleSumm: 'Сумма',
  salePercent: 'Процент',
  contract: 'Договор',
  paymentStatus: 'Состояние оплаты',
  responsible: 'Ответственный',
  accountPaymentNumber: 'Номер счёта',
  summ: 'На сумму',
  prepayment: 'Предоплата',
  accountPaymentFrom: 'От',
  paymentOrder: 'Платёжное поручение',
  paymentOrderFrom: 'От',
  waitUntil: 'Ожидает до',
  inn: 'ИНН',
  location_id:'Местоположение'
} as const

export const CreateRequestNames = {
  comment: 'comment',
  waitUntil: 'waitUntil',
  request: 'request',
  typeId: 'typeId',
  dispatcher: 'dispatcher',
  typeOfWork: 'typeOfWork',
  obj: 'obj',
  ads: 'ads',
  isLegalEntity: 'isLegalEntity',
  legalEntityClientFio: 'legalEntityClientFio',
  organization: 'organization',
  clientFio: 'clientFio',
  phone: 'phone',
  phone2: 'phone2',
  phone3: 'phone3',
  districtId: 'districtId',
  house: 'house',
  housing: 'housing',
  street: 'street',
  entrance: 'entrance',
  apartment: 'apartment',
  metroStation: 'metroStation',
  status: 'status',
  isControlledDispatcher: 'isControlledDispatcher',
  isControlledAdmin: 'isControlledAdmin',
  preferentialRequest: 'preferentialRequest',
  creditRequest: 'creditRequest',
  ndsRequest: 'ndsRequest',
  hasGuarantee: 'hasGuarantee',
  autoSelection: 'autoSelection',
  selectedMasters: 'selectedMasters',
  masterSelectionFilter: 'masterSelectionFilter',
  saleType: 'saleType',
  sale: 'sale',
  contract: 'contract',
  paymentStatus: 'paymentStatus',
  responsible: 'responsible',
  accountPaymentNumber: 'accountPaymentNumber',
  summ: 'summ',
  prepayment: 'prepayment',
  accountPaymentFrom: 'accountPaymentFrom',
  paymentOrder: 'paymentOrder',
  paymentOrderFrom: 'paymentOrderFrom',
  attachments: 'attachments',
  urInn: 'urInn',
  location_id: 'location_id'
} as const
