import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { DropdownMenuItem } from '@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item'
import { TreeRecursiveProps } from '@common/multi-select/multi-select/dropdown-menu-virtualized/dropdown-menu-item/tree-recursive/tree-recursive.types'
import styles from './tree-recursive.module.scss'
import { isEqual } from 'lodash'
import { isObjectInMap } from '@utils/find-item'

export const TreeRecursive = <Item extends { [key: string]: any }>({
  disableGroupsWithoutChilds,
  isGroupSelectable,
  items,
  dropdownMenuItemVariant,
  dropdownMenuItemWithCheckbox = false,
  isDataNested,
  getItemProps,
  highlightedIndex,
  isOpen,
  selectItem,
  selectedItems,
  handleCollapseNestedItems,
  openedNestedItems,
}: TreeRecursiveProps<Item>) => {
  if (!Array.isArray(items)) return <></>

  return (
    <>
      {items.map((item, index) => {
        const hasItems =
          item !== undefined && item !== null && Object.hasOwn(item, 'items')

        if (!hasItems || item.items.length === 0) {
          const itemProps = {
            ...getItemProps({
              item,
              key: item?.id,
              index: item?.id,
              id: item?.id,
            }),
          }

          return (
            <DropdownMenuItem<Item>
              disableGroupsWithoutChilds={
                disableGroupsWithoutChilds ? item.items?.length === 0 : false
              }
              key={nanoid()}
              selectItem={selectItem}
              selectedItems={selectedItems}
              itemProps={itemProps}
              item={item}
              isDataNested={isDataNested}
              isActive={isObjectInMap(selectedItems, item.id, item.title)}
              variant={dropdownMenuItemVariant}
              withCheckbox={dropdownMenuItemWithCheckbox}
              handleCollapseNestedItems={handleCollapseNestedItems}
              openedNestedItems={openedNestedItems}
            />
          )
        }

        if (hasItems && item.items.length > 0) {
          const itemProps = {
            ...getItemProps({
              item,
              key: item.id,
              index: item.id,
              id: item.id,
            }),
          }
          const { items } = item
          /*          const isIncludesAll =
            Array.from(selectedItems.values()).filter((sItem) =>
              items.includes(sItem),
            ).length === items.length ||
            Array.from(selectedItems.values())[0]?.id === item?.id
          const isActive = isGroupSelectable
            ? isObjectInMap(selectedItems, item.id, item.title)
            : isIncludesAll*/

          const isActive = isObjectInMap(selectedItems, item.id, item.title)

          return (
            <DropdownMenuItem<Item>
              disableGroupsWithoutChilds={
                disableGroupsWithoutChilds ? item.items?.length === 0 : false
              }
              key={nanoid()}
              selectItem={selectItem}
              selectedItems={selectedItems}
              itemProps={itemProps}
              item={item}
              isDataNested={isDataNested}
              isActive={isActive}
              variant={dropdownMenuItemVariant}
              withCheckbox={dropdownMenuItemWithCheckbox}
              handleCollapseNestedItems={handleCollapseNestedItems}
              openedNestedItems={openedNestedItems}>
              <div className={styles['tree-recursive-children__container']}>
                <TreeRecursive<Item>
                  disableGroupsWithoutChilds={disableGroupsWithoutChilds}
                  isGroupSelectable={isGroupSelectable}
                  key={nanoid()}
                  selectItem={selectItem}
                  selectedItems={selectedItems}
                  isOpen={isOpen}
                  highlightedIndex={highlightedIndex}
                  items={item.items}
                  dropdownMenuItemVariant={dropdownMenuItemVariant}
                  dropdownMenuItemWithCheckbox={dropdownMenuItemWithCheckbox}
                  getItemProps={getItemProps}
                  isDataNested={isDataNested}
                  handleCollapseNestedItems={handleCollapseNestedItems}
                  openedNestedItems={openedNestedItems}
                />
              </div>
            </DropdownMenuItem>
          )
        }
      })}
    </>
  )
}
