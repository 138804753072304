import { HoldCallIconButton } from "../../../../shared/components";
import { usePhoner } from "../../../../shared/contexts/phoner/phoner";
import { useEventBus } from "../../../../shared/contexts/event-bus/event-bus";
import { SessionStatus, useSessionContext } from "../../../../shared/contexts/tab-session-context/tab-session-context";
import { useSharedWorker } from "../../../../shared/contexts/shared-worker";
import { useMasterTab } from "../../../../shared/contexts/master-tab/master-tab";
import { useEffect } from "react";
import { SET_SESSION_HOLD, SET_SESSION_UNHOLD } from "../../../../shared/event-bus/types/event-action.types";

export const ToggleCallOnHold = () => {
	const { on } = useEventBus();

	const { setSessionUnHold, setSessionHold, sessions, unmuteMicro } = usePhoner();
	const { sessionsDTO, setSessionsDTO } = useSessionContext();
	const { sendMessage } = useSharedWorker();
	const { isCurrentTabMasterTab } = useMasterTab();

	const currentSessionState = sessionsDTO?.filter((session) => session.status !== SessionStatus.RINGING)?.[0];
	const isReadyToSecondCall = sessionsDTO.every((session) => session.isConnected);
	const isCurrentSessionOnHold = currentSessionState?.status === SessionStatus.HOLD;

	const handleToggleCall = () => {
		if (isCurrentTabMasterTab) {
			if (isCurrentSessionOnHold) {
				setSessionUnHold();
				sendMessage({ eventName: SET_SESSION_UNHOLD, eventPayload: { sessionId: currentSessionState?.sessionId } });
				unmuteMicro(currentSessionState?.sessionId || "");
			} else {
				setSessionHold();
				sendMessage({ eventName: SET_SESSION_HOLD, eventPayload: { sessionId: currentSessionState?.sessionId } });
			}
		} else {
			if (isCurrentSessionOnHold) {
				sendMessage({ eventName: SET_SESSION_UNHOLD, eventPayload: { sessionId: currentSessionState?.sessionId } });
			} else {
				sendMessage({ eventName: SET_SESSION_HOLD, eventPayload: { sessionId: currentSessionState?.sessionId } });
			}
		}

		setSessionsDTO((prevSessions) =>
			prevSessions.map((contextSession) =>
				contextSession.sessionId === currentSessionState?.sessionId
					? {
							...contextSession,
							status: isCurrentSessionOnHold ? SessionStatus.STARTED_CONVERSATION : SessionStatus.HOLD,
							isMuted: !isCurrentSessionOnHold,
						}
					: contextSession,
			),
		);
	};

	useEffect(() => {
		const unsubscribeSetSessionOnHold = on(SET_SESSION_HOLD, () => {
			if (isCurrentTabMasterTab) {
				setSessionHold();
			}

			setSessionsDTO((prevSessions) =>
				prevSessions.map((contextSession) =>
					contextSession.sessionId === currentSessionState?.sessionId
						? { ...contextSession, status: SessionStatus.HOLD, isMuted: true }
						: contextSession,
				),
			);
		});

		const unsubscribeSetSessionOnUnhold = on(SET_SESSION_UNHOLD, () => {
			if (isCurrentTabMasterTab) {
				setSessionUnHold();
				unmuteMicro(currentSessionState?.sessionId || "");
			}

			setSessionsDTO((prevSessions) =>
				prevSessions.map((contextSession) =>
					contextSession.sessionId === currentSessionState?.sessionId
						? { ...contextSession, status: SessionStatus.STARTED_CONVERSATION, isMuted: false }
						: contextSession,
				),
			);
		});

		return () => {
			unsubscribeSetSessionOnHold();
			unsubscribeSetSessionOnUnhold();
		};
	}, [isCurrentTabMasterTab, sessions, sessionsDTO]);

	return (
		<>
			{currentSessionState?.isConnected ? (
				<HoldCallIconButton
					handleCallOnHold={handleToggleCall}
					isCallOnHold={isCurrentSessionOnHold}
					isDisabled={!isReadyToSecondCall}
				/>
			) : null}
		</>
	);
};
