import React from 'react'
import { Icon } from '../../../ui-kit/icon'
import { Button } from '../../../ui-kit/button'
import cn from 'classnames'

type TransferCallToAutoInformerButtonProps = {
  onClick: () => void
  disabled: boolean
  className?:string
}

export const ReferCallToAutoInformerButton = ({ onClick, disabled, className = '' }: TransferCallToAutoInformerButtonProps) => {
  const referButtonStyles = cn(className)

  return (
    <Button
      className={referButtonStyles}
      disabled={disabled}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onClick()
      }}
      size="m"
      variant="danger"
      width="icon"
      renderIcon={<Icon icon={'icon-voicemail'} />}
    />
  )
}
