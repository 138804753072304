import type { FC } from "react";
import type {
	IAddSnackbarOptions,
	IAddDefaultSnackbarOptions,
	IAddErrorSnackbarOptions,
	IAddLoadingSnackbarOptions,
	IUpdateSnackbarOptions,
} from "@/eventbus/channels/snackbar/snackbar.types";

import { useEffect, useState, useCallback, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { snackbarEventChannel } from "@/eventbus/channels";
import { Portal } from "@/components";
import { Snackbar } from "./";

import styles from "./snackbar-container.module.scss";
import classnames from "classnames";

// Поведение описано в документации компонента: https://fekcl.notion.site/Snackbar-e3f39cf9c14e4af18c011faee2fd9a0b#e0afadc95acf4371b6f4cd038852b650
export const SnackbarContainer: FC = () => {
	// "очередь" снеков
	const snacks = useRef<IAddSnackbarOptions[]>([]);
	// Текущий снек, стейт для перерендура
	const [currentSnack, setCurrentSnack] = useState<IAddSnackbarOptions | null>(null);
	// Реф для использования внутри хука useCallback
	const currentSnackRef = useRef<IAddSnackbarOptions | null>(null);
	// Реф на таймер
	const currentSnackTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

	// Сброс таймера
	const resetTimeout = useCallback(() => {
		clearTimeout(currentSnackTimerRef.current);
		currentSnackTimerRef.current = undefined;
	}, []);

	// Для очистки устарешших снеков
	const filterAllDefaultAndErrors = useCallback(() => {
		return snacks.current.filter((el) => el.type !== "default" && el.type !== "error");
	}, []);

	// Моментальное удаление
	const currentSnackInstantUnmount = useCallback(() => {
		// Убираем элемент из очереди
		snacks.current = snacks.current.filter((el) => el.eventName !== currentSnackRef.current?.eventName);
		// Очищаем таймаут
		resetTimeout();

		// Если снеков нет, ставим в null
		if (snacks.current.length === 0) {
			setCurrentSnack(null);
		} else {
			// Возвращаем загрузку
			if (snacks.current[0]?.type === "loading") {
				setCurrentSnack(snacks.current[0]);
				return;
			}
			snacks.current = filterAllDefaultAndErrors();
			// Убираем снек
			setCurrentSnack(null);
		}
	}, []);

	// Устанвка теймера на удаление
	const currentSnackUnmountTimer = useCallback(<T extends IAddSnackbarOptions>(options: T) => {
		currentSnackTimerRef.current = setTimeout(
			currentSnackInstantUnmount,
			options.withAction && options.timer
				? // 160 - время анимации появления, 400 - погрешность хука useTimer
					options.timer + 160 + 400
				: options.delay,
		);
	}, []);

	// Установка delay и timer, если их нет и добавление удаления при действиях
	const updateOptions = useCallback(<T extends IAddSnackbarOptions>(options: T) => {
		const remove = (fn?: () => void) => () => {
			snacks.current = snacks.current.filter((el) => el.eventName !== options.eventName);
			currentSnackInstantUnmount();
			fn?.();
		};

		const copy = {
			handleClose: options.handleClose,
			handleClick: options.handleClick,
		};
		options.handleClose = remove(copy.handleClose);
		options.handleClick = remove(copy.handleClick);

		return {
			...options,
			delay: (options.delay !== undefined ? options.delay : 2600) + 160,
			timer: options.timer !== undefined ? options.timer : 5000,
		};
	}, []);

	const addDefault = useCallback((options: IAddDefaultSnackbarOptions) => {
		// Перебиваем все default и error
		snacks.current = [...filterAllDefaultAndErrors(), options];

		resetTimeout();
		setCurrentSnack(options);
		currentSnackUnmountTimer(options);
	}, []);

	const addError = useCallback((options: IAddErrorSnackbarOptions) => {
		// Перебиваем все default и error
		snacks.current = [...filterAllDefaultAndErrors(), options];

		resetTimeout();
		setCurrentSnack(options);
	}, []);

	const addLoading = useCallback((options: IAddLoadingSnackbarOptions) => {
		// Перебиваем все default и error
		snacks.current = [options, ...filterAllDefaultAndErrors()];

		resetTimeout();
		setCurrentSnack(options);
	}, []);

	// Контроллер добавления
	const onAddSnack = useCallback((options: IAddDefaultSnackbarOptions | IAddErrorSnackbarOptions | IAddLoadingSnackbarOptions) => {
		// если такой евент уже есть, второй раз добавить нельзя
		if (snacks.current.findIndex((el) => el.eventName === options.eventName) > -1) {
			return;
		}

		if (options.type === "default") addDefault(updateOptions(options));
		else if (options.type === "error") addError(updateOptions(options));
		else if (options.type === "loading") addLoading(updateOptions(options));
	}, []);

	const onClearSnacks = useCallback(() => {
		snacks.current = [];
		setCurrentSnack(null);
		return;
	}, []);

	const onUpdateLoadingSnack = useCallback((options: IUpdateSnackbarOptions) => {
		// Ищем евент
		const idx = snacks.current.findIndex((el) => el.eventName === options.eventName);

		// Если это не загрузка, ничего не делаем
		if (idx < 0 || snacks.current[idx]?.type !== "loading") return;

		let newOptions = {
			// Достаем изначальные параметры
			...snacks.current[idx],
			// Если пришли новые
			...(options.options
				? // Естанавливаем новые
					options.options
				: // Иначе, достаем параметры из переданных в изначальной конфигурации
					(snacks.current[idx] as IAddLoadingSnackbarOptions)[options.newStatus]),
		};

		newOptions = updateOptions(newOptions as IAddSnackbarOptions);

		if (snacks.current[idx]) {
			// Обнавляем в массиве
			snacks.current[idx] = newOptions as IAddSnackbarOptions;
		}

		// Выводим
		setCurrentSnack(snacks.current[idx] || null);

		// Если это success, добавляем таймер на удаление
		if (newOptions.type === "default") {
			resetTimeout();
			currentSnackUnmountTimer(newOptions as IAddSnackbarOptions);
		}
	}, []);

	useEffect(() => {
		const onAddSnackListner = snackbarEventChannel.on("addSnack", onAddSnack);
		const onClearSnacksListner = snackbarEventChannel.on("clearSnacks", onClearSnacks);
		const onUpdateLoadingSnackListner = snackbarEventChannel.on("updateLoadingSnack", onUpdateLoadingSnack);

		return () => {
			onAddSnackListner.off();
			onUpdateLoadingSnackListner.off();
			onClearSnacksListner.off();
		};
	}, []);

	useEffect(() => {
		currentSnackRef.current = currentSnack;
	}, [currentSnack]);

	return (
		<Portal selector="#portal">
			<AnimatePresence>
				{snacks.current.length > 0 && (
					<motion.div
						// layout
						initial={{ opacity: 0, y: 0, x: "-50%" }}
						animate={{ opacity: 1, y: -40, x: "-50%" }}
						exit={{
							opacity: 0,
							y: 0,
						}}
						transition={{
							// duration: 0.14,
							y: { duration: 0.16 },
						}}
						className={classnames(styles.container)}
					>
						{currentSnack && (
							<Snackbar timerTime={currentSnack.timer ? currentSnack.timer / 1000 : undefined} {...currentSnack} />
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</Portal>
	);
};
