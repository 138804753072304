import { forwardRef } from 'react'
import MastersPopupItem from './masters-popup-item'
import styles from './masters-popup.module.scss'
import { IMastersPopup } from './masters-popup.types'
import classNames from 'classnames'

const MastersPopup = forwardRef<any, IMastersPopup>(
  (
    {
      masters,
      requestId,
      fullcodeId,
      requestStatus,
      className,
      showApproveMaster,
    },
    ref,
  ) => {
    return (
      <div ref={ref} className={classNames(styles['masters-popup'], className)}>
        <ul className={styles['masters-popup-list']}>
          {masters.map((master) => (
            <MastersPopupItem
              showApproveMaster={showApproveMaster}
              requestId={requestId}
              fullcodeId={fullcodeId}
              master={master}
              requestStatus={requestStatus}
              key={master.id}
            />
          ))}
        </ul>
      </div>
    )
  },
)

export default MastersPopup
