import { Timer } from "./timer";
import type { TimerCircleSize, TimerCircleSizeSchemeType, TimerWrapperProps } from "./timer.types";
import { useEffect, useState } from "react";

const TimerCircleSizeScheme: TimerCircleSizeSchemeType = {
	s: 22,
	m: 32,
	l: 42,
};

export const TimerWrapper = ({
	maxTime = 5000,
	timerTime = 5000,
	size = "small",
	trackWidth = 1.5,
	trackColor,
	indicatorWidth = 1.5,
	indicatorColor,
	indicatorCap = "round",
}: TimerWrapperProps) => {
	const [initialTime, setInitialTime] = useState<number>(maxTime);
	const [progress, setProgress] = useState(0);
	const circleSize: TimerCircleSize = TimerCircleSizeScheme[size] || 0;
	const circumference = Math.PI * circleSize;

	const center = circleSize / 2;
	const radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
	const dashArray = 2 * Math.PI * radius;

	const dashOffset = dashArray * (1 - progress);

	useEffect(() => {
		setProgress(timerTime / initialTime);
	}, [timerTime, initialTime]);

	useEffect(() => {
		setInitialTime(maxTime);
	}, [maxTime]);

	return (
		<Timer
			isOn={timerTime !== 0}
			size={size}
			circumference={circumference}
			timerTime={Math.round(timerTime / 1000)}
			dashOffset={dashOffset}
			dashArray={dashArray}
			progress={progress}
			trackWidth={trackWidth}
			trackColor={trackColor}
			indicatorWidth={indicatorWidth}
			indicatorColor={indicatorColor}
			indicatorCap={indicatorCap}
			center={center}
			radius={radius}
		/>
	);
};
