import { Button } from '@/components/button'
import { MultiSelectProps } from '@/components/common/multi-select/multi-select/multi-select.types'
import { HandleUpload } from '@/components/files/files-header/files-header.types'
import { clearSnacks } from '@/eventbus/events/snackbar'
import { AttachmentIndexResource, coreApi, useAttachmentsDestroyMutation, useRequestsAttachmentsStoreMutation } from '@/state/api'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FieldInputProps, FieldMetaState, useFormState } from 'react-final-form'
import { useParams } from 'react-router'
import styles from './request-modal-attachmentts.module.scss'
import { useAppDispatch } from '@/state/selector'
import { addSnack } from '@/eventbus/events/snackbar'
import { checkFileSizeAndNotify } from '@utils/checkFileSizeAndNotify'

// biome-ignore lint/correctness/noUnusedVariables: <explanation>
type RequestModalFileInputProps<T extends AttachmentIndexResource[]> = FieldRenderProps<T, HTMLElement> & {
	input: FieldInputProps<T, HTMLElement>;
	meta: FieldMetaState<T>;
	label?: string;
	labelSide?: "top" | "left";
	enableMultiRowSelection?: boolean;
	withInnerItems?: boolean;
	enableSearch?: boolean;
	isDataNested?: MultiSelectProps["isDataNested"];
	dropdownMenuSelectionVariant?: MultiSelectProps["dropdownMenuSelectionVariant"];
	dropdownMenuItemWithCheckbox?: MultiSelectProps["dropdownMenuItemWithCheckbox"];
	isGroupSelectable?: boolean;
	debounceSearchTime?: number;
	modalView?: string;
	isLoading: boolean;
};

export const RequestModalFileInput: React.FC<any> = ({
	isLoading,
	input,
	modalView,
	//@ts-ignore
	onChange,
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [files, setFiles] = useState<AttachmentIndexResource[]>([]);
	const [originFiles, setOriginFiles] = useState<AttachmentIndexResource[]>([]);
	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const [createFrmAttachments, setAttachments] = useState<File[]>([]);
	const { requestId } = useParams();
	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const [lastSubmittedState, setLastSubmittedState] = useState<FormData>();
	const [addFile] = useRequestsAttachmentsStoreMutation();
	const [deleteFile] = useAttachmentsDestroyMutation();
	const dispatch = useAppDispatch();
	const formState = useFormState();

	const attachments = new FormData();

	const handleButtonClick = () => {
		fileInputRef.current?.click();
	};

	const handleChangeCache = (files: AttachmentIndexResource[]) => {
		dispatch(
			coreApi.util.updateQueryData("requestsShow", { requestId: Number(requestId) || 0 }, (draft) => {
				if (draft.data) {
					draft.data.attachments = files;
				}
				return draft;
			}),
		);
	};

	const handleDelete = (index: number, file?: AttachmentIndexResource) => {
		if (!file) {
			setAttachments((prev) => [...prev.filter((_, idx) => idx !== index)]);
		} else if ((!file?.id && modalView !== "edit") || file.can_del) {
			setFiles((prev) => [...prev.filter((_, idx) => idx !== index)]);
			// handleChangeCache([...files.filter((_, idx) => idx !== index)]);
			file?.name && attachments.delete(file?.name);
			onChange([...files.filter((_, idx) => idx !== index)]);
		}
	};

	const handleDeleteOrigin = (index: number, file?: AttachmentIndexResource) => {
		if (!file?.id) return;

		if (modalView === "edit") {
			deleteFile({ attachmentId: file.id }).then(() => {
				// setOriginFiles((prev) => [...prev.filter((_, idx) => idx !== index)])
				onChange([...originFiles.filter((_, idx) => idx !== index)], "origin");
				// handleChangeCache([...originFiles.filter((_: any, idx: number) => idx !== index)]);
				// onChange([...input.value.filter((_: any, idx: number) => idx !== index)])
			});
		} else {
			// setOriginFiles((prev) => [...prev.filter((_, idx) => idx !== index)])
			onChange([...originFiles.filter((_, idx) => idx !== index)], "origin");
			// handleChangeCache([...originFiles.filter((_: any, idx: number) => idx !== index)]);
		}
	};

	const handleUploadFile: HandleUpload = (attachments, request_id) => {
		clearSnacks();
		setLastSubmittedState(attachments);
		addFile({
			requestsAttachmentStore: attachments,
			requestId: Number(request_id),
		}).then((res) => {
			if ("data" in res) {
				setOriginFiles((p) => [...p, ...(res.data.data as AttachmentIndexResource[])]);
				// handleChangeCache([...originFiles, ...(res.data.data as AttachmentIndexResource[])]);
				attachments.delete("attachments[]");
				setFiles([]);
			}
		});
	};

	const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
		clearSnacks();
		const filesFormData: File[] = [];
		if (!e.target.files) return;

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i]
      let fileSize = 0
      fileSize = file?.size / 1024 / 1024
      if (!checkFileSizeAndNotify(fileSize, `Размер одного файла не должен превышать 50 Мб`, 50)) {
        e.target.value = ''
        return
      }

      filesFormData.push(file)
    }
    e.target.value = ''

		const newFilesSize = filesFormData.reduce((acc, current) => acc + current.size / 1024 / 1024, 0);

		const filesSize = files.reduce((acc, current) => acc + Number.parseInt(current.size ?? "") / 1024 / 1024, 0);

    if (!checkFileSizeAndNotify(newFilesSize + filesSize, `Превышен максимальный размер добавляемых файлов за один раз (100 МБ)`)) {
      e.target.value = ''
      return
    }

		for (const file of filesFormData) {
			attachments.append("attachments[]", file);
		}

		if (modalView === "create") {
			onChange([...files, ...filesFormData]);
			return;
		}
		if (modalView === "edit") {
			// onChange(attachments)
			handleUploadFile(attachments, Number(requestId));
			return;
		}
		if (modalView === "duplicate") {
			onChange([...files, ...filesFormData]);
			return;
		}
	};

	useEffect(() => {
		setOriginFiles(formState?.values?.attachmentsOrigin);
	}, [formState?.values?.attachmentsOrigin]);

	useEffect(() => {
		setFiles(input.value);
	}, [input.value]);

	return (
		<div>
			<Button btnType={"submit"} isLoading={isLoading} disabled={isLoading} onClick={handleButtonClick}>
				Добавить файл
			</Button>
			<div className={styles.files_header}>
				<label style={{ fontSize: "14px" }} className={styles.files_header_btn}>
					<input ref={fileInputRef} onChange={handlerChange} type="file" multiple disabled={false} />
				</label>
			</div>
			<ul className={styles.filesList}>
				{originFiles.map((file, index) => (
					<li className={styles.fileItem} key={`${file.name}${index}`}>
						<span>{file.name}</span>
						{file?.can_del || modalView === "duplicate" || !file?.path_thumbnail ? (
							<button className={styles.fileItem_closeBtn} onClick={() => handleDeleteOrigin(index, file)}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
									<path
										d="M11.7765 11.7765C12.0745 11.4786 12.0745 10.9955 11.7765 10.6975L9.07901 8L11.7765 5.30248C12.0745 5.00452 12.0745 4.52143 11.7765 4.22347C11.4786 3.92551 10.9955 3.92551 10.6975 4.22347L8 6.92099L5.30248 4.22347C5.00452 3.92551 4.52143 3.92551 4.22347 4.22347C3.92551 4.52143 3.92551 5.00452 4.22347 5.30248L6.92099 8L4.22347 10.6975C3.92551 10.9955 3.92551 11.4786 4.22347 11.7765C4.52143 12.0745 5.00452 12.0745 5.30248 11.7765L8 9.07901L10.6975 11.7765C10.9955 12.0745 11.4786 12.0745 11.7765 11.7765Z"
										fill="#4B4B4B"
									/>
								</svg>
							</button>
						) : null}
					</li>
				))}
				{files.map((file, index) => (
					<li className={styles.fileItem} key={`${file.name}${index}`}>
						<span>{file.name}</span>
						{file?.can_del || modalView === "duplicate" || !file?.path_thumbnail ? (
							<button className={styles.fileItem_closeBtn} onClick={() => handleDelete(index, file)}>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
									<path
										d="M11.7765 11.7765C12.0745 11.4786 12.0745 10.9955 11.7765 10.6975L9.07901 8L11.7765 5.30248C12.0745 5.00452 12.0745 4.52143 11.7765 4.22347C11.4786 3.92551 10.9955 3.92551 10.6975 4.22347L8 6.92099L5.30248 4.22347C5.00452 3.92551 4.52143 3.92551 4.22347 4.22347C3.92551 4.52143 3.92551 5.00452 4.22347 5.30248L6.92099 8L4.22347 10.6975C3.92551 10.9955 3.92551 11.4786 4.22347 11.7765C4.52143 12.0745 5.00452 12.0745 5.30248 11.7765L8 9.07901L10.6975 11.7765C10.9955 12.0745 11.4786 12.0745 11.7765 11.7765Z"
										fill="#4B4B4B"
									/>
								</svg>
							</button>
						) : null}
					</li>
				))}
			</ul>
		</div>
	);
};
