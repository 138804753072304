import type { MessageProps } from "@common/chat/message/message.types";
import { Badge, Typography } from "@components/common";
import cn from "classnames";
import classNames from "classnames";
import { Fragment } from "react";
import styles from "./message.module.scss";

export const Message = ({
	bgColor,
	time,
	label,
	text,
	showActionButtons,
	updatedAt,
	badges,
	isQualityControls,
	isShort,
	showToMaster,
	status,
	...props
}: Partial<MessageProps>) => {
	const messageClasses = cn(styles["message-container"], {
		[styles["message-container--hide-from-master"]]: showToMaster !== undefined ? !showToMaster : false,
		[styles["message-container__action-btns-visible"]]: showActionButtons,
		[styles["message-container-quality-controls"]]: isQualityControls,
		[styles["message-container-short"]]: isShort,
	});

	return (
		<div {...props} className={messageClasses} style={{ backgroundColor: bgColor ? bgColor : "" }}>
			<div className={styles["message-container_header"]}>
				<Typography className={styles["message-container_header__label"]} as={"caption"} decoration={"none"} tag={5}>
					{label}
					{" • "}
					{status}
				</Typography>
				<Typography className={styles["message-container_header__time"]} as={"caption"} decoration={"none"} tag={5}>
					{updatedAt ? `Ред. ${updatedAt}` : time}
				</Typography>
			</div>
			<div className={styles["message-container_content"]}>
				<Typography className={styles["message-container_content__text"]} as={"paragraph"} decoration={"none"} tag={4}>
					{text}
				</Typography>
			</div>
			{Array.isArray(badges) &&
				badges.length > 0 &&
				badges.map((group) => (
					<div className={styles["quality-container"]} key={group.group.id}>
						<Typography className={styles["message-container_header__label"]} as={"caption"} decoration={"none"} tag={5}>
							{`Результаты опроса [${group.group.name}]:`}
						</Typography>
						<div className={styles["okk-badge-container"]}>
							{Array.isArray(group.answers) &&
								group.answers.map((item) => (
									<Fragment key={item.id}>
										{item.title ? (
											<Badge
												key={item.id}
												className={classNames(styles["okk-badge"], {
													[styles["okk-badge-success"]]: item.status === "success",
													[styles["okk-badge-default"]]: item.status === "unknown",
													[styles["okk-badge-danger"]]: item.status === "danger",
												})}
											>
												{item.title}
											</Badge>
										) : null}
									</Fragment>
								))}
						</div>
					</div>
				))}
		</div>
	);
};
