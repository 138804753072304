import { add, format, set } from 'date-fns'

export const getDate = () => {
  const date = format(
    add(
      set(new Date(), {
        hours: 16,
        minutes: 0,
      }),
      { days: 1 },
    ),
    'dd.MM.yyyy HH:mm',
  )

  return date
}
