import { ContentLoader, ScrollPositionSaver } from "@/components";
import Pagination from "@/components/pagination/pagination";
import { useFiltersState } from "@/contexts/filters-state";
import { useFullCodeRequest } from "@/contexts/scroll-pos";
import { addSnack } from "@/eventbus/events/snackbar";
import { type RequestsIndexApiArg, type ResourcesRequestIndex, useRequestsIndexQuery } from "@/state/api";
import { ContentContainer } from "@components/common";
import { FilterNames } from "@components/headers/requests-header/request-header-filters/utils/filter-names";
import type { FilterTypes } from "@components/headers/requests-header/request-header-filters/utils/filter-types";
import { RequestsHeader } from "@components/headers/requests-header/requests-header";
import { MetricsSidebar } from "@components/metrics-sidebar";
import { RequestsCardsList } from "@components/request-cards-list/request-cards-list";
import type { ResetPageOnFilterChange } from "@global-types/reset-page.types";
import type { AnyObject } from "@global-types/utils-type";
import { removeValuesFromObjectByKey } from "@utils/remove-values-from-object-by-key";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Outlet, useNavigate } from "react-router-dom";

import styles from "./app.module.scss";

function AppLayout() {
	const { filters, setFilters } = useFiltersState();

	const hideScrollbar = false;
	const [page, setPage] = useState(filters.page || 1);
	const [perPage, setPerPage] = useState(filters.perPage || 25);
	const [editedItem] = useState<number | null>(null);
	// biome-ignore lint/correctness/noUnusedVariables: <explanation>
	const [data, setData] = useState<ResourcesRequestIndex[]>([]);
	const parentRef = React.useRef<HTMLDivElement>(null);

	const [localFilters, setLocalFilters] = useState<FilterTypes | AnyObject>({});
	const { handleSetRequestFullcodeId } = useFullCodeRequest();
	const rF = removeValuesFromObjectByKey<FilterTypes | AnyObject, RequestsIndexApiArg>(filters, [FilterNames.template]);

	useEffect(() => {
		setFilters((prev) => ({ ...prev, page, perPage }));
	}, [page, perPage]);

	useEffect(() => {
		if (filters.page === 1) {
			setPage(1);
		}
		if (filters.perPage === 25) {
			setPerPage(filters.perPage);
		}
	}, [filters.page, filters.perPage]);

	const {
		data: serverData,
		error,
		isFetching,
		refetch,
		isError: isFetchRequestsError,
	} = useRequestsIndexQuery({
		...rF,
		page: filters.page,
		perPage: filters.perPage,
		work: rF.work ? (Array.isArray(rF.work) ? rF.work : [rF.work]) : [],
		state: Array.isArray(rF.state) ? rF.state : typeof rF.state === "number" ? [rF.state] : [],
		district: rF.district ? (Array.isArray(rF.district) ? rF.district : [rF.district]) : [],
		responsible: rF.responsible ? (Array.isArray(rF.responsible) ? rF.responsible : [rF.responsible]) : [],
		announced: rF.announced ? (Array.isArray(rF.announced) ? rF.announced : [rF.announced]) : [],
		master: rF.master ? (Array.isArray(rF.master) ? rF.master : [rF.master]) : [],
		dispatcher: rF.dispatcher ? (Array.isArray(rF.dispatcher) ? rF.dispatcher : [rF.dispatcher]) : [],
		advertising: rF.advertising ? (Array.isArray(rF.advertising) ? rF.advertising : [rF.advertising]) : [],
	});

	const handleRefetchRequests = () => {
		refetch();
	};
	useEffect(() => {
		if (isFetchRequestsError) {
			console.error("Ошибка загрузки данных", error);
			addSnack({
				type: "error",
				eventName: "fetch-requests-error",
				text: "Не удалось загрузить заявки. Попробуйте еще раз.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => handleRefetchRequests(),
			});
		}
	}, [isFetchRequestsError]);

	useEffect(() => {
		if (!isFetching) {
			if (serverData?.data && serverData.meta) {
				const currentPage = serverData.meta.current_page;
				const data = serverData.data.map((item) => ({
					...item,
					page: currentPage,
				}));
				if (isEqual(filters, localFilters)) {
					setData((prev) => [...prev, ...data]);
				} else {
					const data = serverData.data.map((item) => ({
						...item,
						page: currentPage,
					}));
					if (page === 1) {
						setData(data);
					}
					if (page > 1) {
						const updatedItem = serverData.data
							.map((item) => ({
								...item,
								page: currentPage,
							}))
							.find((item) => item.id === editedItem);
						setData((prev) => {
							return prev.map((item) => (item.id === editedItem && updatedItem ? { ...updatedItem } : item));
						});
					}

					setLocalFilters(filters);
				}
			}
		}
	}, [filters, isFetching, localFilters, serverData]);

	useEffect(() => {
		localStorage.setItem("filters", JSON.stringify({ ...filters, phone: filters?.phone?.replace(/_/gi, "") }));
	}, [filters]);

	const resetPageOnFilterChange = ({ resetLocalStoragePage = false }: ResetPageOnFilterChange) => {
		resetLocalStoragePage && localStorage.removeItem("filters");
	};
	const navigate = useNavigate();
	const initialValues = {
		masterSelectionFilter: "optimal",
	};

	useEffect(() => {
		const navigationType = (window.performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming).type;

		const isPageReload = navigationType === "reload";

		window.onbeforeunload = () => {
			localStorage.removeItem("requestFullcodeId");
		};

		if (!isPageReload) {
			localStorage.removeItem("filters");
		}
	}, []);

	return (
		<div className={styles["requests-page__container"]}>
			<Form
				onSubmit={() => {
					navigate("../");
				}}
				initialValues={initialValues}
			>
				{() => (
					<>
						<Outlet />
					</>
				)}
			</Form>
			<RequestsHeader resetPageOnFilterChange={resetPageOnFilterChange} isFetching={isFetching} />
			<ContentContainer>
				<div className={styles["requests-page__content-wrapper"]}>
					<ScrollPositionSaver isFetching={isFetching} page={filters.page} mRef={parentRef} containerId={"requests-page"}>
						<div
							ref={parentRef}
							className={`${styles["requests-page__cards-list"]} ${
								hideScrollbar ? styles["requests-page__cards-list-hide-scrollbar"] : ""
							}`}
						>
							<div
								style={{
									width: "100%",
									position: "relative",
								}}
							>
								<div className={`${styles["requests-list-container"]} ${isFetching ? styles["request-is-loading"] : ""}`}>
									{serverData?.data && (
										<RequestsCardsList filters={filters} localFilters={localFilters} items={serverData?.data} />
									)}
									{isFetching && !serverData?.data && <ContentLoader />}
								</div>
							</div>
						</div>
						<MetricsSidebar isFetching={isFetching} />
					</ScrollPositionSaver>
				</div>
			</ContentContainer>
			{serverData?.meta && (
				<Pagination
					totalOnPage={Number(serverData.data?.length) || 0}
					total={Number(serverData.meta.total)}
					current={filters.page}
					perPage={!isFetching ? Number(serverData.meta.per_page) : perPage}
					setPage={(page) => {
						handleSetRequestFullcodeId("");
						setPage(page);
					}}
					lastPage={Number(serverData.meta.last_page)}
					setPerPage={setPerPage}
					containerId={"requests-page"}
					isFetching={isFetching}
				/>
			)}
		</div>
	);
}

export default AppLayout;
