import { Button } from "@/components/button";
import "./error-indicator.css";

const ErrorIndicator = ({ reloadAfterError }: any) => {
	return (
		<div className="error-indicator">
			<h1 className="text-warning text-center">Ой!</h1>
			<span className="text-center  d-block">Кажется что то пошло не так</span>
			<span className="text-center d-block">Попробуйте нажать кнопку обновить или перезагрузите страницу</span>

			<Button href="/" className="mt-5 mx-auto" onClick={reloadAfterError}>
				На главную
			</Button>
		</div>
	);
};

export default ErrorIndicator;
