import { Input } from "@/components/form/input";
import { Radio } from "@/components/form/radio";
import type React from "react";
import type { IOkkQuestion } from "../okk-comment.types";
import styles from "./okk-components.module.scss";

const Question = ({ question, answer, changeAnswer }: IOkkQuestion) => {
	const handleChange = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const value = Number(e.currentTarget.value);
		if (question.id) {
			if (answer?.answer_type === value) {
				changeAnswer(question.id, undefined, false, "");
				return;
			}
			changeAnswer(question.id, value, false, "");
		}
	};

	const handleInputChange = (value: string) => {
		if (question.id) {
			if (!value) {
				changeAnswer(question.id, undefined, false, "");
			} else {
				changeAnswer(question.id, 1, true, value);
			}
		}
	};

	return (
		<li className={styles.question}>
			<p className={styles["question-text"]}>{question.text}</p>
			<div className={styles["question-controls"]}>
				{!question.is_special && (
					<>
						<Radio
							name={`yes_${question.id}`}
							id={`yes_${question.id}`}
							label="Да"
							value={"1"}
							onClick={(e) => handleChange(e)}
							checked={answer?.answer_type === 1}
						/>
						<Radio
							name={`no_${question.id}`}
							id={`no_${question.id}`}
							label="Нет"
							checked={answer?.answer_type === 2}
							value={"2"}
							onClick={(e) => handleChange(e)}
						/>
					</>
				)}
				{question.is_special && (
					<div className={styles["input-container"]}>
						<Input
							value={answer?.special_value}
							onChange={(e) =>
								// @ts-ignore
								!!e && handleInputChange(e.currentTarget.value)
							}
							placeholder="Сумма"
							size={5}
						/>
					</div>
				)}
				<Radio
					name={`unknown_${question.id}`}
					id={`unknown_${question.id}`}
					label="Не помню"
					checked={answer?.answer_type === 3}
					value={"3"}
					onClick={(e) => handleChange(e)}
				/>
			</div>
		</li>
	);
};

export default Question;
