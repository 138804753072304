import { createContext, useContext } from 'react'

type TabsContextProps = {
  activeTab: unknown
  setActive: (value: unknown) => void
}

export const TabsContext = createContext<TabsContextProps>({
  activeTab: '',
  setActive: () => {},
})
export const useTabsContext = () => useContext(TabsContext)
