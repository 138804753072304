import type { RequestResource } from "@/state/api";
import type { ModalView } from "@global-types/modal-view.types";

export const prepareFormDataCreateRequest = (formValues: any) => {
  const data = new FormData();

  if (formValues?.attachments.length) {
    for (const fileFormData of formValues.attachments) {
      data.append("attachments[]", fileFormData);
    }
  }

  if (formValues?.attachmentsOrigin.length) {
    for (const fileFormData of formValues.attachmentsOrigin) {
      data.append("attachments_origin[]", fileFormData.id);
    }
  }

  formValues.saleType && data.append("rec_sale_type", formValues.saleType);
  const saleValue =
    formValues.sale !== null && formValues.sale !== undefined
      ? String(formValues.sale)
      : "0";
  data.append("sale_value", saleValue);
  formValues.clientFio && data.append("client_fio", formValues.clientFio);
  data.append("phone", formValues.phone?.replace(/_/g, ""));
  formValues.phone2 &&
    data.append("phone2", formValues.phone2?.replace(/_/g, ""));
  formValues.phone3 &&
    data.append("phone3", formValues.phone3?.replace(/_/g, ""));
  formValues.street && data.append("address_street", formValues.street);
  formValues.house && data.append("address_house", formValues.house);
  formValues.location_id && data.append("location_id", formValues.location_id);
  formValues.housing && data.append("address_korp", formValues.housing);
  formValues.apartment && data.append("address_flat", formValues.apartment);
  formValues.districtId &&
    data.append("address_district_id", formValues.districtId);
  formValues.ads && data.append("reklama_id", formValues.ads);
  data.append("descr", ".");
  formValues.obj && data.append("obj", formValues.obj);
  formValues.typeOfWork && data.append("work_id", formValues.typeOfWork);
  data.append("auto_selection", formValues.autoSelection ? "1" : "0");
  //data.append('is_direct', undefined)
  data.append("is_guarantee", formValues.hasGuarantee ? "1" : "0");

  formValues.preferentialRequest &&
    data.append("is_privileges", formValues.preferentialRequest);

  formValues?.metroStation &&
    data.append(
      "metro_id",
      formValues?.metroStation && formValues?.metroStation !== 0
        ? formValues?.metroStation
        : undefined
    );

  formValues.isControlledDispatcher &&
    data.append("is_controlled_dispatcher", formValues.isControlledDispatcher);
  formValues.isControlledAdmin &&
    data.append("is_controlled_admin", formValues.isControlledAdmin);
  //data.append('guarantee_till', undefined)
  formValues.isLegalEntity && data.append("isur", formValues.isLegalEntity);
  formValues.organization && data.append("ur_org", formValues.organization);
  formValues.legalEntityClientFio &&
    data.append("ur_fio", formValues.legalEntityClientFio);
  formValues.urId && data.append("ur_id", formValues.urId);
  // data.append('partner_id', undefined)
  data.append("type_id", formValues.typeId);
  // data.append('user_add_id', undefined)
  // data.append('attachments[]', formValues.attachments || [])
  formValues.entrance && data.append("address_entrance", formValues.entrance);
  if (formValues?.commonSelectedMasters.length) {
    formValues?.commonSelectedMasters.map((item: any) =>
      data.append("selected_masters[]", JSON.stringify(item.id))
    );
  }
  data.append("state_id", formValues.status);

  formValues.contract && data.append("contract_type", formValues.contract);
  formValues.paymentStatus &&
    data.append("beznal_state_id", formValues.paymentStatus);
  formValues.responsible &&
    data.append("responsible_id", formValues.responsible);
  formValues.accountPaymentNumber &&
    data.append("beznal_num", formValues.accountPaymentNumber);
  formValues.accountPaymentFrom &&
    data.append("beznal_date", formValues.accountPaymentFrom);
  formValues.summ && data.append("beznal_summ", formValues.summ);
  if (
    formValues.prepayment ||
    typeof formValues?.prepayment === "number" ||
    typeof formValues?.prepayment === "string"
  ) {
    data.append("before_summ", String(formValues.prepayment));
  }
  formValues.paymentOrder &&
    data.append("beznal_plat_num", formValues.paymentOrder);
  formValues.paymentOrderFrom &&
    data.append("beznal_plat_date", formValues.paymentOrderFrom);
  formValues.creditRequest &&
    data.append("is_credit", formValues.creditRequest ? "1" : "0");
  formValues.ndsRequest &&
    data.append("nds", formValues.ndsRequest ? "1" : "0");
  formValues?.stateComment &&
    data.append("state_comment", formValues?.stateComment);
  formValues?.waitUntil && data.append("wait_until", formValues?.waitUntil);
  formValues?.urInn && data.append("ur_inn", formValues?.urInn);
  if (formValues.comment) {
    data.append("comment[text]", formValues.comment);
    data.append("comment[show_to_master]", formValues.showToMaster ? "1" : "0");
  }

  return data;
};

export const prepareFormDataEditRequest = (
  data: RequestResource,
  modalView?: Omit<ModalView, "manual-selection">,
  lastModifiedFormState?: string | null
) => {
  const initialValues = {
    showToMaster: true,
    isLegalEntity: !!data.ur_org,
    organization: data.ur_org,
    hasGuarantee: data.is_guarantee,
    masterSelectionFilter: "optimal",
    clientFio: data.client_fio,
    phone: data.phone !== null ? data.phone : "",
    street: data.address_street,
    phone2: data.phone2 !== null ? data.phone2 : "",
    phone3: data.phone3 !== null ? data.phone3 : "",
    autoSelection: data.auto_selection,
    request: data?.partner_type_id ? data?.partner_type_id : 0,
    legalEntityClientFio: data?.ur_fio ? data?.ur_fio : "",
    typeId: data?.type_id,
    typeOfWork: data.work?.id ? data?.work?.id : undefined,
    districtId: data.address_district_id,
    house: data.address_house,
    housing: data?.address_korp,
    entrance: data?.address_entrance,
    apartment: data?.address_flat,
    metroStation: data.metro_id !== 0 ? data.metro_id : 0,
    status: modalView === "duplicate" ? 0 : Number(data?.state_id),
    preferentialRequest: data?.is_privileges,
    isControlledDispatcher: data.is_controlled_dispatcher,
    isControlledAdmin: data.is_controlled_admin,
    ads: data.reklama_id,
    obj: data?.obj,
    selectedMasters: data?.approved_master
      ? [data?.approved_master]
      : data?.masters,
    commonSelectedMasters: data?.approved_master
      ? [data?.approved_master]
      : data?.masters,
    dispatcher: data?.dispatcher,
    saleType: modalView === "create" ? 1 : Number(data?.rec_sale_type),
    sale: data?.sale_value,
    urId: data?.ur_id,
    urInn: data?.ur_inn,
    contract: data?.contract_type,
    paymentStatus: data?.beznal_state_id,
    responsible: data?.responsible?.id,
    accountPaymentNumber: data?.beznal_num,
    accountPaymentFrom: data?.beznal_date
      ? data?.beznal_date.split("/").join(".")
      : null,
    summ: data?.beznal_summ,
    prepayment: data?.before_summ,
    paymentOrder: data?.beznal_plat_num,
    paymentOrderFrom: data?.beznal_plat_date
      ? data?.beznal_plat_date.split("/").join(".")
      : null,
    creditRequest: data?.is_credit,
    ndsRequest: data?.nds,
    attachments: [],
    attachmentsOrigin: data?.attachments,
    location_id: data.location_id
  };

  return lastModifiedFormState
    ? { ...initialValues, ...JSON.parse(lastModifiedFormState) }
    : initialValues;
};

export const prepareFormDataUpdateRequest = (formValues: any) => {
  const dataToUpdate = {
    location_id: formValues.location_id,
    client_fio: formValues.clientFio || undefined,
    phone: formValues.phone?.replace(/_/g, ""),
    phone2: formValues.phone2?.replace(/_/g, "") ?? null,
    phone3: formValues.phone3?.replace(/_/g, "") ?? null,
    address_street: formValues.street,
    address_house: formValues.house,
    address_korp: formValues.housing,
    address_flat: formValues.apartment ?? undefined,
    address_district_id: formValues.districtId,
    reklama_id: formValues.ads || undefined,
    obj: formValues.obj ? formValues.obj : null,
    work_id: formValues.typeOfWork,
    auto_selection: formValues.autoSelection,
    is_guarantee: formValues.hasGuarantee,
    is_privileges: formValues.preferentialRequest,
    metro_id:
      formValues?.metroStation && formValues?.metroStation !== 0
        ? formValues?.metroStation
        : 0,
    is_controlled_dispatcher: formValues.isControlledDispatcher,
    is_controlled_admin: formValues.isControlledAdmin,
    type_id: formValues.typeId,
    isur: formValues.isLegalEntity,
    ur_org: formValues.organization || undefined,
    ur_fio: formValues.legalEntityClientFio || undefined,
    ur_id: formValues.urId || undefined,
    user_add_id: formValues.dispatcher.id,
    address_entrance: formValues.entrance,
    selected_masters: formValues.commonSelectedMasters?.map(
      (item: any) => item.id
    ),
    state_id: formValues.status,
    rec_sale_type: formValues.saleType,
    sale_value:
      formValues.sale !== null && formValues.sale !== undefined
        ? Number(formValues.sale)
        : 0,

    contract_type: formValues.contract || null,
    beznal_state_id: formValues.paymentStatus,
    responsible_id: formValues.responsible || null,
    beznal_num: formValues.accountPaymentNumber || 0,
    beznal_date: formValues.accountPaymentFrom || undefined,
    beznal_summ: formValues.summ || 0,
    before_summ:
      typeof formValues.prepayment === "number" ||
      typeof formValues.prepayment === "string"
        ? Number(formValues.prepayment)
        : undefined,
    beznal_plat_num: formValues.paymentOrder || " ",
    beznal_plat_date: formValues.paymentOrderFrom || undefined,
    is_credit: !!formValues.creditRequest,
    nds: !!formValues.ndsRequest,
    ur_inn: Number(formValues.urInn) || undefined,
    wait_until: formValues.waitUntil || undefined,
    comment: formValues.comment
      ? {
          text: formValues.comment,
          show_to_master: !!formValues.showToMaster,
        }
      : undefined,
  };

  const dataToUpdateWithoutPhoneWithSpecialChar = Object.fromEntries(
    Object.entries(dataToUpdate).filter((arr) =>
      arr[0]?.startsWith("phone") && !arr[1]?.includes("*")
        ? arr
        : !arr[0]?.startsWith("phone")
    )
  );

  const filteredData = Object.fromEntries(
    Object.entries(dataToUpdateWithoutPhoneWithSpecialChar).filter((arr) =>
      // arr[0].startsWith('phone') ? arr : arr[1] !== undefined && arr[1] !== '',
      arr[0].startsWith("phone")
        ? arr
        : arr[1] !== undefined &&
          (arr[0].startsWith("ur_fio")
            ? arr[1] !== undefined && arr[1] !== ""
            : arr) &&
          (arr[0].startsWith("ur_org")
            ? arr[1] !== undefined && arr[1] !== ""
            : arr)
    )
  );

  return filteredData;
};
