import { PartnerFeedResource, TypeSelectableEnum } from '@/state/api'

type SelectOptionsPresetsType = TypeSelectableEnum[] | PartnerFeedResource[]

export const prepareSelectPresets = (responseData?: {
  data?: SelectOptionsPresetsType
}) =>
  responseData &&
  responseData.data &&
  responseData.data.map((item) => ({
    ...item,
    data: item.id,
  }))
