import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  ExpandedState,
  getExpandedRowModel,
  getFilteredRowModel,
  FilterFn,
  Cell,
} from '@tanstack/react-table'
import React, { useEffect, useReducer, useRef, useState } from 'react'

import { TableProps } from './table.types'
import { TableBody } from '@common/table-container/table-body'
import { TableHeader } from '@common/table-container/table-header'
import { TableRow } from '@common/table-container/table-row'
import { TableContainer } from '@common/table-container/table-container'
import { TableCol, TableColItem, Typography } from '@components/common'
import { TableRowItem } from '@common/table-container/table-row/table-row-item'

import { AnyObject } from '@global-types/utils-type'
import { rankItem } from '@tanstack/match-sorter-utils'
import { useField, useForm } from 'react-final-form'
import { CreateRequestNames } from '@constants/create-request'
import styles from '../selection-of-masters.module.scss'
import { REQEST_MASTER_CARD_BY_ID } from '@constants/routes'
import { useLocation, useNavigate } from 'react-router'
import { logout } from '@/state/slice'

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export const TableSelectionOfMasters = ({
                                          masterSelectionFilter,
                                          modalView,
                                          data,
                                          columns,
                                          pageSize = 10,
                                          pageIndex = 0,
                                          externalRowSelection,
                                          setExternalRowSelection,
                                          enableMultiRowSelection = false,
                                          enableRowSelection = false,
                                          enableSubRowSelection = false,
                                          globalFilter = null,
                                          setGlobalFilter,
                                          onSelectedRowsOriginalChange,
                                          rowSelectionOriginal,
                                        }: TableProps) => {
  const navigate = useNavigate()
  const rerender = useReducer(() => ({}), {})[1]

  const commonSelectedMasters = useField('commonSelectedMasters')
  const form = useForm()
  const [tableData, setTableData] = useState(data)
  const refreshData = (data: any[]) => setTableData(() => [...data])
  const [rowSelection, setRowSelection] = useState({})
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: pageIndex,
    pageSize: pageSize,
  })

  useEffect(() => {
    refreshData(data)
  }, [data])

  useEffect(() => {
    if (pagination.pageSize !== pageSize || pagination.pageIndex !== pageIndex) {
      setPagination({ pageIndex, pageSize })
    }
  }, [pageIndex, pageSize, pagination.pageIndex, pagination.pageSize])

  const selectedMastersField =
    useField(CreateRequestNames.selectedMasters).input.value ||
    rowSelectionOriginal
  const location = useLocation()

  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const table = useReactTable({
    data: tableData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      rowSelection: externalRowSelection ? externalRowSelection : rowSelection,
      pagination,
      expanded,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onExpandedChange: setExpanded,
    getSubRows: (row: AnyObject) => row.items,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setExternalRowSelection ? setExternalRowSelection : setRowSelection,
    onStateChange: (e) => {
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    debugTable: false,
    enableMultiRowSelection,
    enableRowSelection,
    enableSubRowSelection,
  })

  useEffect(() => {
    const selectedMastersIds = [...commonSelectedMasters.input.value].map((item: any) => item.id)
    let result = {}
    data.map((item: any, idx: number) => {
      selectedMastersIds?.includes(item.id)
        ? (result = {
          ...result,
          [idx]: true,
        })
        : (result = { ...result, [idx]: false })
    })
    setRowSelection(result)
  }, [data])
  const selectedRows = table.getSelectedRowModel().flatRows.map((row) => {
    return {
      id: row.original.id,
      fio: row.original.fio,
    }
  })

  useEffect(() => {
    form.change('commonSelectedMasters', [...new Set([...commonSelectedMasters?.input?.value])])
  }, [selectedRows?.length])

  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().flatRows.map((row) => {
      return {
        id: row.original.id,
        fio: row.original.fio,
      }
    })

    onSelectedRowsOriginalChange &&
    onSelectedRowsOriginalChange(
      table.getSelectedRowModel().flatRows.map((item) => {
        return item.original
      }),
    )

    const reducedSelectedMastersField =
      modalView === 'create' || modalView === 'manual-selection'
        ? {}
        : commonSelectedMasters.input.value?.reduce((prev: any, curr: any) => {
          return {
            ...prev,
            [curr.id]: curr,
          }
        }, {})
    const result = selectedRows.map((row) => {
      return reducedSelectedMastersField[row.id]
        ? reducedSelectedMastersField[row.id]
        : row
    })

    form.change(`commonSelectedMasters`, [
      ...new Set([...commonSelectedMasters?.input?.value, ...result]),
    ])
  }, [rowSelection, masterSelectionFilter])

  const handleNavigateToMasterCard = (cell: Cell<any, unknown>) => {
    if (!cell.id.includes('checkbox')) {
      navigate(REQEST_MASTER_CARD_BY_ID(String(cell.row.original?.id)), { state: location.state })
    }
  }

  return (
    <TableContainer>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableCol
                  style={
                    {
                      '--table-col-item-width': `${header.column.getSize()}px`,
                    } as React.CSSProperties
                  }
                  key={header.id}>
                  {header.isPlaceholder ? null : (
                    <TableColItem>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </TableColItem>
                  )}
                </TableCol>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {!tableData?.length ? (
          <TableRow>
            <TableRowItem>Подходящих мастеров не найдено</TableRowItem>
          </TableRow>
        ) : (
          <>
            {table.getRowModel().rows.map((row) => {
              return (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableRowItem
                        style={
                          {
                            '--table-row-item-width': `${cell.column.getSize()}px`,
                          } as React.CSSProperties
                        }
                        key={cell.id}>
                        {cell?.column.id === 'dayLimit' ? (
                          <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
                        ) : (
                          <Typography
                            className={styles['selection-work-type-modal__master']}
                            as={'caption'}
                            style={{ color: cell.row.original?.fio_color ?? '#0F0F0F', cursor: 'pointer' }}
                            decoration={'none'}
                            tag={4}
                            weight={2}
                            onClick={() => handleNavigateToMasterCard(cell)}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Typography>
                        )}
                      </TableRowItem>
                    )
                  })}
                </TableRow>
              )
            })}
          </>
        )}
      </TableBody>
    </TableContainer>
  )
}
