import type { FC } from 'react'
import styles from './request-page.module.scss'
import { InfoCard } from '@components/info-card'
import { RequestPageLogCard } from '@pages/request-page/log-card'
import { ContentContainer } from '@common/content-container/content-container'
import { RequestInfoHeader } from '@components/headers/request-info-header/request-info-header'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useRequestsShowQuery } from '@/state/api'
import { ContentLoader } from '@/components'
import { Form } from 'react-final-form'
// biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
const RequestPage: FC = (): JSX.Element => {
  const { requestId } = useParams()
  const { data, isFetching } = useRequestsShowQuery({
    requestId: Number(requestId),
  })
  const info = data?.data || {}
  const initialValues = {
    masterSelectionFilter: 'optimal',
  }
  const navigate = useNavigate()

  return (
    <div className={styles['request-page']}>
      <Form
        onSubmit={() => {
          navigate(-1)
        }}
        initialValues={initialValues}>
        {() => (
          <>
            <Outlet />
          </>
        )}
      </Form>
      {isFetching && !data ? (
        <ContentLoader />
      ) : (
        <>
          <RequestInfoHeader info={info} />
          <ContentContainer className={styles['request-page-content']}>
            <InfoCard info={info} />
            <RequestPageLogCard />
          </ContentContainer>
        </>
      )}
    </div>
  )
}

export default RequestPage
