import { Input } from '../../../shared'
import { ChangeEvent, MutableRefObject } from 'react'
import { InputStateType } from '../../../shared/ui-kit/input/input.types'

type FastSearchInputProps = {
  inputValue: string
  inputState: InputStateType
  handleChange: (inputValue: string) => void
  handleInputState: (state: InputStateType) => void
  placeholder?: string
  errorMessage?: string
  maxLength?: number
  inputRef?: MutableRefObject<any>
  id: string
}
export const SearchInput = ({
  inputValue,
  inputState,
  handleChange,
  // placeholder = 'ФИО, телефон, должность',
  placeholder = 'Введите номер',
  handleInputState,
  errorMessage = '',
  inputRef,
  maxLength = 999,
  id,
}: FastSearchInputProps) => {
  return (
    <Input
      maxLength={maxLength}
      inputRef={inputRef}
      errorMessageTooltipOffset={[0, 25]}
      placeholder={placeholder}
      value={inputValue}
      state={inputState}
      errorMessage={errorMessage}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.target.value ? handleChange(e.target.value) : handleChange('')
      }}
      onBlur={(e) => {
        e.target.value ? handleInputState('filled') : handleInputState('default')
      }}
      onFocus={() => {
        handleInputState('active')
      }}
      onReset={() => {
        handleChange('')
        handleInputState('default')
      }}
      width={'full-w'}
      id={id}
      labelText={''}
      type={'text'}
      withRightButton={false}
      withResetButton
    />
  )
}
