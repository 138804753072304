import { PhoneActionsDropdownListProps } from '@components/phone-actions-dropdown/phone-ations-dropdown.types'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Typography } from '@components/common'
import styles from './phone-actions-dropdown.module.scss'
import React, { useState } from 'react'

export const PhoneActionsDropdownList = ({
  handleCall = () => {},
  handleSMS = () => {},
  handleAddToBlackList = () => {},
  handleRemoveFromBlackList = () => {},
  isBlackListInfoByPhoneLoading,
  isPhoneInBlackList,
  isLoading = false,
  isRemoveFromBlackListLoading,
  isAddToBlackListLoading,
}: PhoneActionsDropdownListProps) => {
  return (
    <>
      <Button
        disabled={isPhoneInBlackList || isBlackListInfoByPhoneLoading}
        type={ButtonTypes.primary}
        size={UISizes.medium}
        onClick={() => handleCall()}
        iconLeft={<i className={`icon icon-phone`} />}>
        <Typography as={'paragraph'} decoration={'none'} tag={4}>
          Позвонить
        </Typography>
      </Button>
      <Button
        type={ButtonTypes.primaryUncolored}
        size={UISizes.medium}
        onClick={() => handleSMS()}
        iconLeft={<i className={`icon icon-chat`} />}>
        <Typography className={styles['text-value-interactive']} as={'paragraph'} decoration={'none'} tag={4}>
          Отправить SMS
        </Typography>
      </Button>
      <Button
        disabled={isBlackListInfoByPhoneLoading}
        isLoading={isPhoneInBlackList ? isRemoveFromBlackListLoading : isAddToBlackListLoading}
        type={ButtonTypes.dangerUncolored}
        size={UISizes.medium}
        onClick={() => (isPhoneInBlackList ? handleRemoveFromBlackList() : handleAddToBlackList())}
        iconLeft={<i className={`icon icon-request-nodata`} />}>
        <Typography className={styles['text-value-danger']} as={'paragraph'} decoration={'none'} tag={4}>
          {isPhoneInBlackList ? 'Убрать из черного списка' : 'В черный список'}
        </Typography>
      </Button>
    </>
  )
}
