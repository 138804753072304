import { useActor } from "@xstate/react";
import { useGlobalPhonerState } from "../../../app/providers/xstate-provider";
import { PanelTray, Phoner } from "../../../entities";
import { GetTopSlot } from "../utils/getTopSlot";
import { GetRightSlot } from "../utils/getRightSlot";
import { GetMiddleSlot } from "../utils/getMiddleSlot";
import { ToggleMenuButton } from "../../../features/toggle-menu";
import { motion, AnimatePresence } from "framer-motion";
import { animationVariants } from "../constants/index";
import styles from "./phoner-widget.module.scss";
import { usePhoner } from "../../../shared/contexts/phoner/phoner";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useEventBus } from "../../../shared/contexts/event-bus/event-bus";
import {
	SET_INCOMING_CALL_INFO,
	SET_OUTGOING_CALL_INFO,
	START_SECOND_LINE,
	UPDATE_PHONER_POSITION,
} from "../../../shared/event-bus/types/event-action.types";
import { useMasterTab } from "../../../shared/contexts/master-tab/master-tab";
import { type SessionDTO, useSessionContext, SessionStatus } from "../../../shared/contexts/tab-session-context/tab-session-context";
import { nanoid } from "nanoid";
import { useFormMachine } from "../../../app/machines/form-factory-machine/useFormMachine";
import { formatPhoneNumber } from "../../../features/fast-search/call/call";
import { GetBottomSlot } from "../utils/getBottomSlot";

const DEFAULT_WIDGET_WIDTH = 150;
const DEFAULT_WIDGET_HEIGHT = 80;

export const PhonerWidget = () => {
	const {
		phonerTreyService,
		phonerIncomingCallService,
		fastSearchService,
		phonerSecondLineService,
		phonerMenuService,
		phonerDraggingService,
		phonerConnectionService,
	} = useGlobalPhonerState();

	const [statePhonerTrey, sendPhonerTreyEvent] = useActor(phonerTreyService);
	const [statePhonerIncomingCall, sendPhonerIncomingCallEvents] = useActor(phonerIncomingCallService);

	const showIncomingCall = statePhonerIncomingCall.matches("call received");
	const showCurrentCall = statePhonerIncomingCall.matches("current call");
	const isShowPanelTrey = statePhonerTrey.matches("idle") && !showCurrentCall && !showIncomingCall;

	const isShowPhonerWidget = !statePhonerTrey.matches("idle") && !showCurrentCall;

	const { isCurrentTabMasterTab } = useMasterTab();
	const { sessions, setSessionHold, currentActiveWidget } = usePhoner();

	const [menuState, sendMenuService] = useActor(phonerMenuService);
	const [fastSearchState, fastSearchSendEvents] = useActor(fastSearchService);
	const { makeCall, isConnected } = usePhoner();
	const { on } = useEventBus();
	const { setSessionsDTO, sessionsDTO } = useSessionContext();
	const [, sendPhonerSecondLineEvents] = useActor(phonerSecondLineService);

	const [statePhonerDragging, setPhonerDraggingEvents] = useActor(phonerDraggingService);

	const { resetField } = useFormMachine({
		state: fastSearchState,
		send: fastSearchSendEvents,
	});

	const { animationDirection } = statePhonerDragging.context.animationTransitionConfig;

	const phonerWidgetRef = useRef<HTMLDivElement>(null);

	const handleResize = () => {
		if (!window.innerWidth || !window.innerHeight) return;

		const isPhonerOutsideWindow =
			window.innerWidth < statePhonerDragging.context.position.x + (currentActiveWidget?.clientWidth ?? DEFAULT_WIDGET_WIDTH) / 2;

		const isTopViewportBoundaryReached =
			window.innerHeight - (currentActiveWidget?.clientHeight ?? DEFAULT_WIDGET_HEIGHT) <
			Math.abs(statePhonerDragging.context.position.y);

		if (isPhonerOutsideWindow) {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: {
					x: window.innerWidth - (currentActiveWidget?.clientWidth ?? DEFAULT_WIDGET_WIDTH) / 2,
					y: statePhonerDragging.context.position.y,
				},
			});
		} else {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: {
					x: statePhonerDragging.context.position.x,
					y: statePhonerDragging.context.position.y,
				},
			});
		}

		if (isTopViewportBoundaryReached) {
			setPhonerDraggingEvents({
				type: "positionChanged",
				value: {
					y: -(window.innerHeight - (currentActiveWidget?.clientHeight ?? DEFAULT_WIDGET_HEIGHT)),
					x: statePhonerDragging.context.position.x,
				},
			});
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [statePhonerDragging.context.position]);

	useLayoutEffect(() => {
		handleResize();
	}, []);

	useEffect(() => {
		if (currentActiveWidget && (isShowPhonerWidget || showIncomingCall)) {
			const isLeftViewportBoundaryReached = statePhonerDragging.context.position.x < currentActiveWidget.clientWidth / 2;

			const isRightViewportBoundaryReached =
				window.innerWidth - currentActiveWidget.clientWidth / 2 < statePhonerDragging.context.position.x;

			const isTopViewportBoundaryReached =
				window.innerHeight - currentActiveWidget.clientHeight < Math.abs(statePhonerDragging.context.position.y);

			if (isLeftViewportBoundaryReached) {
				setPhonerDraggingEvents({
					type: "positionChanged",
					value: {
						x: currentActiveWidget.clientWidth / 2,
						y: statePhonerDragging.context.position.y,
					},
				});
			}

			if (isRightViewportBoundaryReached) {
				setPhonerDraggingEvents({
					type: "positionChanged",
					value: {
						x: window.innerWidth - currentActiveWidget.clientWidth / 2,
						y: statePhonerDragging.context.position.y,
					},
				});
			}

			if (isTopViewportBoundaryReached) {
				setPhonerDraggingEvents({
					type: "positionChanged",
					value: {
						y: -(window.innerHeight - currentActiveWidget.clientHeight - 8),
						x: statePhonerDragging.context.position.x,
					},
				});
			}
		}
	}, [isShowPhonerWidget, currentActiveWidget, showIncomingCall]);

	useEffect(() => {
		const unsubscribeOnUpdatePhonerPosition = on(UPDATE_PHONER_POSITION, ({ x, y }) => {
			if (currentActiveWidget) {
				const isLeftViewportBoundaryReached = x < currentActiveWidget.clientWidth / 2;
				const isRightViewportBoundaryReached = window.innerWidth - currentActiveWidget.clientWidth / 2 < x;
				const isTopViewportBoundaryReached = window.innerHeight - currentActiveWidget.clientHeight < Math.abs(y);

				if (isLeftViewportBoundaryReached) {
					setPhonerDraggingEvents({
						type: "positionChanged",
						value: { x: currentActiveWidget.clientWidth / 2, y },
					});
					return;
				}

				if (isRightViewportBoundaryReached) {
					setPhonerDraggingEvents({
						type: "positionChanged",
						value: {
							x: window.innerWidth - currentActiveWidget.clientWidth / 2,
							y,
						},
					});
					return;
				}

				if (isTopViewportBoundaryReached) {
					setPhonerDraggingEvents({
						type: "positionChanged",
						value: {
							y: -(window.innerHeight - currentActiveWidget.clientHeight - 8),
							x,
						},
					});
					return;
				}

				setPhonerDraggingEvents({ type: "positionChanged", value: { x, y } });
			}
		});

		return () => unsubscribeOnUpdatePhonerPosition();
	}, [currentActiveWidget]);

	const [phonerConnectionState] = useActor(phonerConnectionService);
	const isConnectionEstablished = phonerConnectionState.context.isConnectionEstablished;

	useEffect(() => {
		const unsubscribeOnSetIncomingCallInfo = on(SET_INCOMING_CALL_INFO, (payload: SessionDTO) => {
			setSessionsDTO((prev) => [...prev, payload]);

			sendPhonerIncomingCallEvents({
				type: "incoming call",
				callInfo: {
					id: payload.sessionId || "",
					phoneNumber: payload.phoneNumber,
				},
			});
		});

		return () => unsubscribeOnSetIncomingCallInfo();
	}, [setSessionsDTO]);

	useEffect(() => {
		const unsubscribeStartSecondLine = on(START_SECOND_LINE, (payload: SessionDTO) => {
			if (!isConnectionEstablished) return;

			if (isCurrentTabMasterTab) {
				setSessionHold();
				setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
				sendPhonerSecondLineEvents({
					type: "start second line",
					callInfo: {
						id: nanoid(),
						phoneNumber: payload.phoneNumber,
						currentPhone: statePhonerIncomingCall?.context?.currentCall?.phoneNumber,
					},
					makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
				});
				resetField("", "fast-search");
			} else {
				sendPhonerSecondLineEvents({
					type: "start second line",
					callInfo: {
						id: nanoid(),
						phoneNumber: payload.phoneNumber,
						currentPhone: statePhonerIncomingCall?.context?.currentCall?.phoneNumber,
					},
					makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
				});

				if (!payload.sessionId) {
					setSessionsDTO((prev) => prev.map((session) => ({ ...session, status: SessionStatus.HOLD })));
					return;
				}

				setSessionsDTO((prev) => {
					return [...prev, { ...payload }];
				});
				resetField("", "fast-search");
			}
		});

		return () => unsubscribeStartSecondLine();
	}, [isCurrentTabMasterTab, sessions, isConnected]);

	useEffect(() => {
		const unsubscribeOnSetOutGoingCallInfo = on(SET_OUTGOING_CALL_INFO, (payload: SessionDTO) => {
			if (!isConnectionEstablished) return;

			if (isCurrentTabMasterTab) {
				sendPhonerIncomingCallEvents({
					type: "outcoming call",
					callInfo: {
						id: nanoid(),
						// phoneNumber: fastSearchState.context.data['fast-search'] || sessionsDTO.phoneNumber,
						phoneNumber: payload.phoneNumber,
					},
					makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
				});
				resetField("", "fast-search");
				return;
			}

			sendPhonerIncomingCallEvents({
				type: "outcoming call",
				callInfo: {
					id: payload.sessionId || "",
					phoneNumber: payload.phoneNumber,
				},
				makeCall: (phoneNumber) => makeCall(formatPhoneNumber(phoneNumber)),
			});
			resetField("", "fast-search");

			if (!payload.sessionId) return;

			setSessionsDTO((prev) => {
				return [...prev, { ...payload }];
			});
		});

		return () => unsubscribeOnSetOutGoingCallInfo();
	}, [isCurrentTabMasterTab, sessions, sessionsDTO, isConnected]);

	const isMenuOpened = menuState.matches("menu opened");

	useEffect(() => {
		if (isMenuOpened) {
			sendMenuService("close menu");
			sendPhonerTreyEvent("close menu");
		}
	}, [localStorage.getItem("phonerPosition")]);

	return (
		<AnimatePresence initial={false}>
			{isShowPanelTrey && (
				<motion.div className={styles["panel-trey"]} key="panelTrey">
					<PanelTray handleClick={() => sendPhonerTreyEvent({ type: "click", isHovered: true })} />
				</motion.div>
			)}
			{(isShowPhonerWidget || showIncomingCall) && (
				<motion.div
					className={styles.phoner}
					key="phoner"
					initial={"initial"}
					animate={"animate"}
					exit={"exit"}
					transition={{ duration: 0.16 }}
					variants={animationVariants.phoner}
					onMouseEnter={() => sendPhonerTreyEvent({ type: "hover", isHovered: true })}
					onMouseLeave={() => sendPhonerTreyEvent({ type: "hover", isHovered: false })}
				>
					<div ref={phonerWidgetRef}>
						<Phoner
							topSlot={animationDirection === "top" ? <GetTopSlot isFastSearchWidget /> : <></>}
							rightSlot={<GetRightSlot />}
							middleSlot={<GetMiddleSlot />}
							leftSlot={<ToggleMenuButton />}
							bottomSlot={animationDirection === "bottom" ? <GetBottomSlot isFastSearchWidget /> : <></>}
							className={styles["search-bar-wrapper"]}
							isShowDraggableTrack={true}
						/>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
