import { AudioTracks, TabsSwitcher } from "@/components";
import { Files } from "@components/files";
import styles from "@pages/request-page/log-card/request-page-logcard.module.scss";
import type { LogCardRightContentProps } from "@pages/request-page/log-card/right-content/logcard-right-content.types";
import { useState } from "react";

export const LogCardRightContent = ({}: Partial<LogCardRightContentProps>) => {
	const [active, setActive] = useState(0);
	const handleClick = (id: number) => {
		setActive(id);
	};

	return (
		<div className={styles["request-page-logcard-content"]}>
			<TabsSwitcher
				onChange={(id) => handleClick(id)}
				tabs={[
					{ id: 0, text: "Записи звонков" },
					{ id: 1, text: "Файлы" },
				]}
				activeTab={active}
			/>

			{active === 0 ? (
				<>
					<AudioTracks className={styles["request-page-logcard-content_audio-container"]} />
				</>
			) : (
				<Files className={styles["request-page-logcard-content_file-container"]} />
			)}
		</div>
	);
};
