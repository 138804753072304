import type { AlertModalProps } from './alert-modal.types'
import { ModalContainer } from '@components/common'
import {  ModalHeader } from '@common/modal-container'
import { AlertModalLeftContent } from './left-content'
import styles from './alert-modal.module.scss'
import { Checkbox } from '@components/form/checkbox'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import {  useNavigate } from 'react-router-dom'
import { ContentLoader } from '@/components/content-loader'
import { clearSnacks } from '@/eventbus/events/snackbar'

export const AlertModal = ({
  title,
  subtitle,
  onHandleBackButton,
  showBackButton,
  showCheckbox,
  closeOnBlackoutClick = true,
  onClick,
  isLoading,
  conentLoading,
}: Partial<AlertModalProps>) => {
  const navigate = useNavigate()

  return (
    <>
      <ModalContainer
        onClose={() => {
          navigate(-1)
          clearSnacks()
        }}
        showBlackout
        closeOnBlackoutClick={closeOnBlackoutClick}
        className={styles['alert-modal']}>
        <ModalHeader
          className={styles['alert-modal__header']}
          showLeftContent
          contentLeft={
            <AlertModalLeftContent
              showBackButton={showBackButton}
              title={title}
              subtitle={subtitle}
              onHandleBackButton={onHandleBackButton}
            />
          }
          onClose={() => {
            navigate(-1)
            clearSnacks()
          }}
        />
        {/*<ModalBody>{children}</ModalBody>*/}
        {conentLoading ? (
          <ContentLoader />
        ) : (
          <div className={styles['alert-modal__footer']}>
            {showCheckbox && <Checkbox state={'default'} showLabel label={'Удалить для всех'} />}

            <div className={styles['alert-modal__footer-action-buttons']}>
              <Button
                btnType={'button'}
                type={ButtonTypes.secondaryUncolored}
                size={UISizes.medium}
                onClick={() => {
                  navigate(-1)
                  clearSnacks()
                }}>
                Оставить
              </Button>
              <Button
                isLoading={isLoading}
                btnType={'button'}
                type={ButtonTypes.dangerUncolored}
                size={UISizes.medium}
                onClick={() => onClick()}>
                Удалить
              </Button>
            </div>
          </div>
        )}
      </ModalContainer>
    </>
  )
}
