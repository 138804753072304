import cn from "classnames";
import { forwardRef, useId } from "react";
import InputMask from "react-input-mask";
import styles from "./input.module.scss";
import type { InputProps } from "./input.types";

export const Input = forwardRef<HTMLInputElement, InputProps<any>>(
	({ className = "", type = "text", withRightButton, mRef, mask, readonly, ...otherProps }, ref) => {
		const inputClasses = cn(styles.input, {
			[styles["with-right-button"]]: withRightButton,
		});
		const reactId = useId();
		const localId = otherProps.id && otherProps.id !== "" ? otherProps.id : reactId;

		return mask ? (
			<InputMask
				mask={mask}
				value={otherProps.value !== null && otherProps.value !== undefined ? otherProps.value : ""}
				// maskChar={otherProps.maskChar}
				alwaysShowMask={otherProps.alwaysShowMask}
				onChange={otherProps.onChange}
				onFocus={(e) => otherProps.onFocus?.(e)}
				onBlur={(e) => otherProps.onBlur?.(e)}
				onPaste={otherProps.onPaste}
				disabled={otherProps.disabled}
				id={localId}
				placeholder={otherProps.placeholder}
				className={`${styles.input}`}
			>
				<input ref={ref || mRef} autoComplete={otherProps.autoComplete} />
			</InputMask>
		) : (
			<input
				autoComplete={"off"}
				readOnly={readonly}
				ref={ref || mRef}
				className={`${inputClasses} ${className ? className : ""}`}
				type={type}
				{...otherProps}
			/>
		);
	},
);
