import { PhonesElement } from '@/state/api'
import { uniqBy } from 'lodash'

export const filterUniquePhonesNumber = (
  arrWithObj?: PhonesElement[],
  currentPhone?: string,
) => {
  if (arrWithObj && arrWithObj.length > 1 && currentPhone) {
    const uniqArr = uniqBy(arrWithObj, 'phone').filter(
      (item) => item.phone !== currentPhone,
    )

    return uniqArr
  }
}
