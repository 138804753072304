import { type RequestRecordResource, useRequestsRecordsIndexQuery } from "@/state/api";
import { type DetailedHTMLProps, type HTMLAttributes, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ContentDivider, NoData } from "../common";
import { ContentLoader } from "../content-loader";
import NoAudioImage from "@/assets/svg/phone-nodata.svg";

import { addSnack } from "@/eventbus/events/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { addPlaylistForRequest } from "@/state/slice";
import { AudioTrackCallRecording } from "./audio-track-call-recording";

type AudioTracksProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
interface IRequestRecordResource extends RequestRecordResource {
	index: number;
	data: any;
}

export const AudioTracks = ({ className }: AudioTracksProps) => {
	const { requestId } = useParams();
	const [maxIdx, setMaxIdx] = useState<number>(0);

	const {
		data: audioTrackData,
		isLoading,
		isSuccess,
		isError,
		refetch,
	} = useRequestsRecordsIndexQuery({
		requestId: Number(requestId),
	});

	const dispatch = useDispatch();
	const state = useSelector((state: any) => state.playlist);
	const callRecordsListRef = useRef<HTMLDivElement>(null);
	const [currentTrackIndex, setCurrentTrackIndex] = useState<number | null>(null);
	const [audioTracks, setAudioTracks] = useState<IRequestRecordResource[]>([]);

	const refetchAudioTracks = () => {
		refetch();
	};

	const isDivider = (index: number): boolean => {
		if (!audioTracks?.length) return false;

		const isLastTrack = index === maxIdx;
		if (typeof currentTrackIndex === "number") {
			const isActiveTrack = currentTrackIndex === index;
			const isPrevTrack = currentTrackIndex === index + 1;
			if (isActiveTrack || isPrevTrack) return false;
		}
		if (!isLastTrack && !state.currentCdrId) return true;
		if (isLastTrack) return false;
		return true;
	};

	useEffect(() => {
		if (isSuccess) {
			if (audioTrackData?.data && audioTrackData?.data?.length > 0) {
				const newAudioArray = audioTrackData.data.map((track: any, index: number) => {
					return { ...track, index };
				});
				setAudioTracks(newAudioArray);
				dispatch(addPlaylistForRequest({ requestId, newAudioArray }));
				audioTrackData?.data && setMaxIdx(audioTrackData?.data?.length - 1);
			}
		}
	}, [isSuccess]);

	useEffect(() => {
		setCurrentTrackIndex(state.currentIndex);
	}, [state, state.currentIndex, state.currentCdrId]);

	useEffect(() => {
		if (isError) {
			addSnack({
				type: "error",
				eventName: "fetch-call-records-error",
				text: "Не удалось загрузить записи звонков.",
				withAction: true,
				actionButtonText: "Повторить",
				handleClick: () => refetchAudioTracks(),
			});
		}
	}, [isError]);

	return (
		<div ref={callRecordsListRef} className={className}>
			{isLoading ? (
				<ContentLoader />
			) : (
				<>
					{audioTracks &&
						isSuccess &&
						audioTracks?.length > 0 &&
						audioTracks.map((item, index) => {
							return (
								<div key={item?.cdr_id} style={{ width: "100%" }}>
									<AudioTrackCallRecording data={item} reqId={requestId} />
									{isDivider(index) ? <ContentDivider margin={"-1px 0px auto"} /> : null}
								</div>
							);
						})}
					{isSuccess && !audioTracks?.length && (
						<NoData src={NoAudioImage} alt="Нет записей звонков" title="Нет записей звонков" />
					)}
					{isError && <NoData src={NoAudioImage} alt="Нет записей звонков" title="Нет записей звонков" />}
				</>
			)}
		</div>
	);
};
