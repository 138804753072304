import { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import React, { useEffect, useState } from 'react'
import { useWorksFeedQuery, WorkFeedResource } from '@/state/api'
import { addSnack } from '@/eventbus/events/snackbar'
import { MultiSelect } from '@common/multi-select'
import { CreateRequestNames } from '@constants/create-request'
import { useField } from 'react-final-form'
import { findItem } from '@utils/find-item'
import { setRecursivelyParentId } from '@utils/set-recursively-parent-id'

export const RequestModalWorkInput = <T extends number | undefined>({
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  acceptPathAsTitle,
  disabled,
  enableMultiRowSelection = false,
  ...props
}: RequestModalTextInputProps<T>) => {
  const workTypeId = useField(input?.name).input.value

  const [initialValue, setInitialValue] = useState<
    WorkFeedResource[] | undefined
  >(workTypeId)

  const {
    data: serverData,
    isLoading,
    isError,
    error,
    refetch,
  } = useWorksFeedQuery()

  const handleRefetchWorks = () => {
    refetch()
  }

  useEffect(() => {
    if (isError) {
      addSnack({
        type: 'error',
        eventName: 'fetch-works-error',
        text: `Не удалось загрузить список видов работ.`,
        withAction: true,
        actionButtonText: 'Повторить',
        handleClick: () => handleRefetchWorks(),
      })
    }
  }, [isError])

  useEffect(() => {
    if (workTypeId && serverData?.data && !isLoading) {
      setInitialValue(
        findItem<WorkFeedResource>({
          id: workTypeId,
          arr: serverData?.data.map((item) =>
            setRecursivelyParentId<WorkFeedResource>(item, item.id),
          ),
        }),
      )
    }
  }, [workTypeId, isLoading])

  return (
    <MultiSelect<WorkFeedResource>
      acceptPathAsTitle={acceptPathAsTitle}
      isDataLoading={isLoading}
      disabled={disabled}
      width={'full-w'}
      isDataNested={true}
      inputLabelText={`${label}: `}
      dropdownMenuSelectionVariant={'single-select'}
      data={
        Array.isArray(serverData?.data)
          ? serverData?.data.map((item) =>
              setRecursivelyParentId(item, item.id),
            )
          : []
      }
      inputId={CreateRequestNames.typeOfWork}
      initialSelectedItems={initialValue}
      externalInputProps={input}
      onChange={(items) => {
        items?.length ? input.onChange(items[0].id) : input.onChange(undefined)
      }}
      externalInputMetaProps={meta}
    />
  )
}
