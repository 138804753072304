import { Typography } from '@/components/common'
import { InstallmentJournalColWidth } from '@/constants/installment'
import tableStyle from '@common/table-container/table.module.scss'

export const InstallmentJournalTableHeader = (): JSX.Element => {
  return (
    <table className={tableStyle['table-container']} style={{ display: 'table', paddingRight: '0', marginTop: '12px' }}>
      <thead className={tableStyle['table-header']} style={{ justifyContent: 'flex-start', width: '100%' }}>
        <tr className={tableStyle['table-row']}>
          <th className={tableStyle['table-col']}>
            <Typography as={'caption'} decoration={'none'} tag={4} weight={1} className={tableStyle['table-col-item']}>
              Дата
            </Typography>{' '}
          </th>
          <th className={tableStyle['table-col']}>
            <Typography as={'caption'} decoration={'none'} tag={4} weight={1} className={tableStyle['table-col-item']}>
              Сообщение
            </Typography>{' '}
          </th>
        </tr>
      </thead>
    </table>
  )
}
