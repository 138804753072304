import type { IUseTimer } from './hooks.types'

import { useEffect, useState } from 'react'

export const useTimer = ({ timerTime, timerDiff = 100 }: IUseTimer) => {
  const [isStarted, setIsStarted] = useState(false)
  const [time, setTimer] = useState(timerTime)

  const handleToggleTimer = () => {
    setIsStarted(!isStarted)
  }

  const refreshTimer = () => {
    setIsStarted(false)
    setTimer(timerTime)
  }

  useEffect(() => {
    setTimer(timerTime)
  }, [timerTime])

  const handleStopTimer = () => {
    setIsStarted(false)
    setTimer(timerTime)
  }

  const handleSetTimer = (time: number) => {
    setTimer(time)
    setIsStarted(true)
  }

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined

    if (isStarted) {
      timerId = setInterval(
        () =>
          setTimer((prev) => {
            const newValue = prev - timerDiff

            if (newValue < 0) {
              clearInterval(timerId)
              setIsStarted(false)
            }

            return newValue
          }),
        timerDiff,
      )
    } else {
      clearInterval(timerId)
    }

    return () => {
      clearInterval(timerId)
    }
  }, [isStarted, time, timerDiff])

  return {
    time,
    handleToggleTimer,
    handleStopTimer,
    handleSetTimer,
    refreshTimer,
  }
}
