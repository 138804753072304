import { Button } from "@/components/button";
import { ButtonTypes } from "@/components/button/button.types";
import { ModalContainer } from "@/components/common";
import { ModalBody, ModalHeader } from "@/components/common/modal-container";
import { clearSnacks } from "@/eventbus/events/snackbar";
import { useDeleteMastersComplaintsStoreMutation, useRequestsComplaintsIndexQuery, useRequestsShowQuery } from "@/state/api";
import { okkTeleCommentForm } from "@components/modals/okk-comment-modal/okk-comment-form";
import { REQUESTS } from "@constants/routes";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useMatch } from "react-router-dom";
import { CreateRequestModalLeftContent } from "../create-request-modal/left-content";
import type { IWorkAnalysis } from "../okk-comment-modal/okk-comment.types";
import OkkConfirm from "../okk-comment-modal/okk-components/okk-confirm";
import OkkTable from "../okk-comment-modal/okk-components/okk-table";
import OkkMaster from "../okk-comment-modal/okk-steps/okk-master";
import OkkTeleCommon from "./okk-tele-steps/okk-tele-common";
import styles from "./okk-tele.module.scss";

const OkkTele = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { data: complaintsData } = useRequestsComplaintsIndexQuery({
		requestId: Number(params.cardId ?? params.requestId),
	});
	const { data, isLoading: isRequestShowQueryLoading } = useRequestsShowQuery({
		requestId: Number(params.cardId ?? params.requestId),
	});
	const [deleteMaster] = useDeleteMastersComplaintsStoreMutation();
	const [removeConfirmData, setRemoveConfirm] = useState<any>({});

	const [currentStep, setCurrentStep] = useState<"common" | "master">("common");
	const [masterToEdit, setMasterToEdit] = useState();
	const [showExitConfirm, setShowExitConfirm] = useState(false);
	const [okkTeleCommentFormState, setOkkTeleCommentFormState] = useState(okkTeleCommentForm);

	const removeMastersWork = (data: IWorkAnalysis) => {
		deleteMaster({
			masterId: Number(data.master_id),
			requestId: Number(params.cardId ?? params.requestId),
			complaintId: data.id,
		});
		setRemoveConfirm({});
	};

	const remove = (data: IWorkAnalysis) => {
		setRemoveConfirm(data);
	};

	const filters = localStorage.getItem("filters") ? JSON.parse(String(localStorage.getItem("filters"))) : null;

	const matchFeed = useMatch("requests/feed/okk-tele/:requestId");

	const isFeedPage = matchFeed;

	return (
		<ModalContainer
			onClose={() => setShowExitConfirm(true)}
			showBlackout
			closeOnBlackoutClick
			className={classNames(styles["modal-container"], {
				[styles["okk-confirm-modal"]]: !!removeConfirmData.id || showExitConfirm,
			})}
		>
			{!removeConfirmData.id && (
				<div
					style={{
						display: showExitConfirm ? "none" : "initial",
						position: showExitConfirm ? "absolute" : "initial",
					}}
				>
					<ModalHeader
						showRightContent
						showLeftContent
						contentLeft={
							<CreateRequestModalLeftContent
								showBackButton={currentStep !== "common"}
								title={
									currentStep === "master"
										? "Анализ работы мастера"
										: `Анализ Телемаркетинга #${data?.data?.fullcode ? data.data.fullcode : ""}`
								}
								onHandleBackButton={() => setCurrentStep("common")}
							/>
						}
						showCloseButton
						onClose={() => setShowExitConfirm(true)}
					/>
					<ModalBody className={currentStep !== "master" ? styles["okk-modal-body"] : styles["okk-modal-body-negative"]}>
						<div className={styles["okk-modal-content"]}>
							{!!complaintsData?.data?.master_complaints?.length && (
								<OkkTable
									title="Анализ мастера"
									className={currentStep !== "common" ? styles.hidden : ""}
									removeItem={remove}
									items={complaintsData?.data?.master_complaints}
									setEditItem={(data: any) => {
										setMasterToEdit(data);
										setCurrentStep("master");
									}}
								/>
							)}
							{currentStep === "common" && (
								<Button
									btnType="button"
									type={ButtonTypes.secondary}
									onClick={() => {
										setMasterToEdit(undefined);
										setCurrentStep("master");
									}}
								>
									Добавить анализ работы мастера
								</Button>
							)}
							{currentStep === "common" && (
								<OkkTeleCommon
									okkTeleCommentFormState={okkTeleCommentFormState}
									setOkkTeleCommentFormState={setOkkTeleCommentFormState}
									isRequestShowQueryLoading={isRequestShowQueryLoading}
									masterOnRequest={data?.data?.approved_master ? data?.data?.approved_master : undefined}
								/>
							)}
							{currentStep === "master" && (
								<OkkMaster
									isRequestShowQueryLoading={isRequestShowQueryLoading}
									masterOnRequest={data?.data?.approved_master ? data?.data?.approved_master : undefined}
									id={masterToEdit}
									onAdd={() => setCurrentStep("common")}
								/>
							)}
						</div>
					</ModalBody>
				</div>
			)}
			{removeConfirmData.id && !showExitConfirm && (
				<OkkConfirm
					text={`Вы уверены, что хотите удалить категорию ${removeConfirmData.type_title} для ${removeConfirmData.master_fio}
        от ${removeConfirmData.date}?`}
					confirmButtonText={"Удалить"}
					cancelButtonText={"Оставить"}
					onCancel={() => setRemoveConfirm({})}
					onConfirm={removeMastersWork}
					data={removeConfirmData}
				/>
			)}
			{showExitConfirm && (
				<OkkConfirm
					text={"Закрыв окно, вы потеряете введенные данные"}
					confirmButtonText={"Да, закрыть оценку"}
					cancelButtonText={"Нет, вернуться"}
					onCancel={() => setShowExitConfirm(false)}
					onConfirm={() => {
						isFeedPage
							? navigate({
									pathname: REQUESTS,
									search: new URLSearchParams(filters).toString(),
								})
							: navigate("../");
						clearSnacks();
					}}
				/>
			)}
		</ModalContainer>
	);
};

export default OkkTele;
