import type React from 'react'
import { useState } from 'react'
import { getInputState } from '@components/form/utils/getFieldState'
import type { BasicInputValues } from '@global-types/utils-type'
import type { RequestModalTextInputProps } from '@components/modals/common-forms/request-modal-input/request-modal-text-input.types'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { Button } from '@components/button'
import { InputErrorContainer } from '@common/input-error-container'
import { InputDefault } from '@common/multi-select'
import { useForm } from 'react-final-form'

export const RequestModalTextInput = <T extends BasicInputValues>({
  fetch,
  input,
  meta,
  label,
  labelSide = 'left',
  inputChildren,
  disabled,
  textError = '',
  withRightButton = true,
  ...props
}: RequestModalTextInputProps<T>) => {
  const [hoveredTooltip, setHoveredTooltip] = useState<string>('')
  const form = useForm()

  const handleSetHoveredTooltip = (val: string) => {
    setHoveredTooltip(val)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      input.value && fetch && fetch()
    }
  }

  return (
    <InputErrorContainer anchorId={hoveredTooltip} errorMessage={!disabled && (meta.error || textError)}>
      <InputDefault
        disabled={disabled}
        width={'full-w'}
        id={input.name}
        type={'text'}
        withRightButton={withRightButton}
        labelText={label?.includes(':') ? `${label} ` : label ? `${label}: ` : ''}
        state={getInputState(meta, input, textError)}
        handleShowError={handleSetHoveredTooltip}
        {...input}
        {...meta}
        {...props}
        onKeyDown={handleKeyDown}
        renderRightButton={
          input.value !== ''
            ? () => (
                <Button
                  btnType={'button'}
                  disabled={disabled}
                  type={ButtonTypes.secondaryUncolored}
                  size={UISizes.medium}
                  iconLeft={<i className={'icon icon-close'} />}
                  onClick={() => {
                    input?.onChange?.(undefined)
                    form.change('urInn', undefined)
                  }}
                />
              )
            : undefined
        }
      />
    </InputErrorContainer>
  )
}
