import { InstallmentButtonSwitch, InstallmentButtonSwitchLabel, InstallmentButtonSwitchState } from '@/constants/installment'
import { ButtonSwitchContainer } from '@common/index'
import { Button } from '@components/button'
import { ButtonTypes } from '@components/button/button.types'
import { UISizes } from '@global-types/mods.types'
import { ElementType } from 'react'

export interface InstallmentInfoTabsProps {
  showLabel?: boolean
  label?: string
  setButtonSwitch: (arg: InstallmentButtonSwitchState) => void
  buttonSwitch: InstallmentButtonSwitchState
  withHistory?: boolean
  withJournal?: boolean
  withPlan?: boolean
}

export const InstallmentInfoTabs = <T extends ElementType = typeof ButtonSwitchContainer>({
  showLabel = false,
  label = '',
  setButtonSwitch = () => {},
  buttonSwitch = InstallmentButtonSwitch.plan,
  withHistory,
  withJournal,
  withPlan,
}: InstallmentInfoTabsProps) => {
  return (
    <ButtonSwitchContainer showLabel={showLabel} label={label}>
      {withPlan ? (
        <Button
          isActive={buttonSwitch === InstallmentButtonSwitch.plan}
          btnType={'button'}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          onClick={() => setButtonSwitch(InstallmentButtonSwitch.plan)}>
          {InstallmentButtonSwitchLabel.plan}
        </Button>
      ) : null}
      {withHistory ? (
        <Button
          isActive={buttonSwitch === InstallmentButtonSwitch.history}
          btnType={'button'}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          onClick={() => setButtonSwitch(InstallmentButtonSwitch.history)}>
          {InstallmentButtonSwitchLabel.history}
        </Button>
      ) : null}
      {withJournal ? (
        <Button
          isActive={buttonSwitch === InstallmentButtonSwitch.journal}
          btnType={'button'}
          type={ButtonTypes.secondary}
          size={UISizes.medium}
          onClick={() => setButtonSwitch(InstallmentButtonSwitch.journal)}>
          {InstallmentButtonSwitchLabel.journal}
        </Button>
      ) : null}
    </ButtonSwitchContainer>
  )
}
